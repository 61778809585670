import React, { InputHTMLAttributes, forwardRef } from "react";
import styles from "./styles.module.scss"

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
    label?: string;
}
const InputBase = ({label, ...rest}: InputProps) => {

    return(
        <div className={styles.container}>
            {label && <p>{label}</p>}
            <input 
                {...rest}
            />
        </div>
    )
}

export const Input = forwardRef(InputBase);