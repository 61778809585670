import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FiDownload } from 'react-icons/fi';

import translate from '../libs/i18n';
import styles from './LibraryListItem.module.scss';
import useOutsideClick from '../libs/useOutsideClick';
import Api from '../libs/Api';

import ListSelector from './ListSelector';

function LibraryListItem({ presentations, fabrics, print }) {
  const language = useSelector(state => state.settings.language);
  const presentationSelectorRef = useRef();

  const history = useHistory();

  const [selectedPresentation, setSelectedPresentation] = useState([]);
  const [presentationSelector, setPresentationSelector] = useState(false);
  const [presentationSection, setPresentationSection] = useState(
    'presentation'
  );
  const [selectedFabrics, setSelectedFabrics] = useState([]);
  const [lastPresentation, setLastPresentation] = useState();

  const changePresentationSelector = state => {
    if (!state) {
      setPresentationSelector(true);
    } else {
      setPresentationSelector(false);
    }
  };

  useOutsideClick(presentationSelectorRef, () => {
    setPresentationSection('presentation');
    changePresentationSelector(presentationSelector);
    setSelectedPresentation([]);
    setSelectedFabrics([]);
  });

  useEffect(() => {
    if (
      selectedPresentation.length &&
      !selectedPresentation.includes(
        presentation => presentation.id === lastPresentation
      )
    ) {
      if (selectedPresentation.length === 1) {
        setLastPresentation(selectedPresentation[0]);
      } else {
        const newPresentation = selectedPresentation.filter(
          presentation => presentation.id !== lastPresentation.id
        );
        setSelectedPresentation(newPresentation);
      }
    }

    if (selectedPresentation.length) {
      setPresentationSection('fabric');
    }
  }, [lastPresentation, selectedPresentation]);

  const includePrintPresentation = () => {
    setPresentationSection('presentation');
    setPresentationSelector(false);
    selectedFabrics.forEach(fabric => {
      const formData = new FormData();
      formData.append('fabric', fabric.id);
      formData.append('print', print.id);
      Api.createProduct(formData).then(product => {
        const productId = new FormData();
        productId.append('product_id', product.id);
        Api.createSelectedPrint(productId).then(selectedPrint => {
          Api.addSelectedPrintToPresentation(
            selectedPresentation[0].id,
            selectedPrint.id
          )
            .then(() => {
              toast(
                `${translate(
                  'successAddingProductToPresentation',
                  language
                )} : ${print.code}-${fabric.name}`,
                {
                  type: 'success'
                }
              );
            })
            .catch(() => {
              toast(
                `${translate('failAddingProductToPresentation', language)}: ${
                  print.code
                }-${fabric.name}`,
                {
                  type: 'error'
                }
              );
            })
            .finally(() => {
              setSelectedPresentation([]);
              setSelectedFabrics([]);
            });
        });
      });
    });
  };

  return (
    <li className={styles.printListItem}>
      <Link to={`/print/${print.id}`} className={styles.name}>
        <div className={styles.presentation}>
          {print.image || print.image_url ? (
            <img
              className={styles.image}
              src={print.image || print.image_url}
              alt={print.code}
              onError="this.style.display = 'none'"
            />
          ) : (
            <div className={styles.imageReplace} />
          )}

          <span>{print.name}</span>
        </div>
      </Link>
      <Link className={styles.code} to={`/print/${print.id}`}>
        {print.code}
      </Link>
      <ul className={styles.tagsContainer}>
        {/* {tags.map(tag => (
          <li>{tag}</li>
        ))} */}
      </ul>

      {presentations && (
        <div className={styles.actionsContainer}>
          <div className={styles.presentationSelector}>
            <button
              type="button"
              className={`${styles.defaultSelectInput}`}
              onClick={e => {
                e.stopPropagation();
                setPresentationSelector(true);
              }}
            >
              {!selectedPresentation.length
                ? translate('choosePresentation', language)
                : selectedPresentation[0].name}
            </button>

            {presentationSelector && (
              <div
                className={styles.selectorPosition}
                ref={presentationSelectorRef}
              >
                <div className={styles.steps}>
                  <button
                    type="button"
                    className={
                      presentationSection === 'presentation' && styles.active
                    }
                    onClick={() => setPresentationSection('presentation')}
                  >
                    Apresentação
                  </button>{' '}
                  <span className={styles.separator}>{'>'}</span>
                  <button
                    type="button"
                    onClick={() => setPresentationSection('fabric')}
                    className={
                      presentationSection === 'fabric' && styles.active
                    }
                  >
                    Tecido
                  </button>
                </div>
                <div className={styles.list}>
                  {presentationSection === 'presentation' ? (
                    <ListSelector
                      label={translate('mainPresentations', language)}
                      selectLabel={translate('choose', language)}
                      items={presentations}
                      onSelect={setSelectedPresentation}
                      values={selectedPresentation}
                      multiple
                      fullWidth
                      search
                      action={() => history.push('/create-presentation')}
                      actionText={translate('createPresentation', language)}
                    />
                  ) : (
                    <ListSelector
                      label={translate('applyFixedFabric', language)}
                      selectLabel={translate('choose', language)}
                      items={fabrics}
                      onSelect={setSelectedFabrics}
                      values={selectedFabrics}
                      multiple
                      action={includePrintPresentation}
                      actionText={translate('save', language)}
                      fullWidth
                      search
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          {print.psd_original && 
          <a title='Faça download do psd!' href={print.psd_original} className={styles.download} 
                  onClick={(e) => {
                      e.stopPropagation();
                      }}>
                    <FiDownload size={32}/>
          </a>
        }
        </div>
      )}
    </li>
  );
}

LibraryListItem.propTypes = {
  print: PropTypes.shape.isRequired,
  presentations: PropTypes.arrayOf(PropTypes.shape),
  fabrics: PropTypes.arrayOf(PropTypes.shape)
};

LibraryListItem.defaultProps = {
  presentations: [],
  fabrics: []
};

export default LibraryListItem;
