const initialState = [];

const reducer = (state = initialState, action) => {
  let newPrints;
  switch (action.type) {
    case '@prints/SET':
      return [...action.prints];

    case '@prints/ADD':
      return [...state, ...action.prints];

    case '@prints/REMOVE':
      newPrints = state.filter(
        print => parseInt(print.id, 10) !== parseInt(action.id, 10)
      );
      return [...newPrints];

    case '@prints/UPDATE':
      newPrints = state.filter(
        print => parseInt(print.id, 10) !== parseInt(action.print.id, 10)
      );
      return [...newPrints, action.print];

    case '@prints/CLEAR':
      return [];

    default:
      return state;
  }
};

export default reducer;
