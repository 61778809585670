import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import { Search } from '../Search/index.jsx';
import { ListItems } from '../ListItems';
import { AgentForm } from '../AgentForm';
import { useHistory } from 'react-router-dom';

export function Agents() {
    const [searchAgent, setSearchAgent] = useState('');
    const language = useSelector(state => state.settings.language);
    const [agentsFormModal, setAgentsFormModal] = useState(false);
    const history = useHistory();

    const agentes = [
        {
            id: 1, 
            name: 'vinicius'
        },
        {
            id: 2, 
            name: 'paulo'
        },
        {
            id: 3, 
            name: 'wallace'
        },
    ]
    
    return (
        <div className={styles.container}>
            <div className={styles.filterContainer}>
                <div className={styles.searchAgentContainer}>
                    <Search 
                    value={searchAgent}
                    placeholder={translate('searchFor', language)}
                    onChange={event => setSearchAgent(event.currentTarget.value)}
                    />
                </div>
                <button
                    className={styles.defaultRoundedActionButton}
                    type="button"
                    onClick={() => { setAgentsFormModal(!agentsFormModal) }}>
                    {translate('addNewAgent', language)}
                </button>
            </div>

            {agentes.map(agente => (
                <ListItems id={agente.id} name={agente.name} />
            ))}

            {agentsFormModal &&
            (
                <AgentForm closeForm={() => { setAgentsFormModal(!agentsFormModal) }}/>
            )
            }
        </div>
    )
}