import React from 'react';
import PropTypes from 'prop-types';

function Grid({ width, height, active, color, activeColor }) {
  return (
    <svg width={width} height={height} viewBox="0 0 51 51">
      <rect
        width="51"
        height="51"
        fill="#FFF"
        stroke={active ? activeColor : color}
        rx={5}
      />
      <path
        fill={active ? activeColor : color}
        d="M20 32a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm8 0a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm8 0a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm-16-9a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm8 0a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm8 0a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm-16-9a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm8 0a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4zm8 0a1 1 0 011 1v5a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5a1 1 0 011-1h4z"
      />
    </svg>
  );
}

Grid.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string
};

Grid.defaultProps = {
  width: 41,
  height: 41,
  color: '#9BA7B7',
  activeColor: '#00C4C5',
  active: false
};

export default React.memo(Grid);
