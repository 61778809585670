import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import moment from 'moment';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import styles from './Library.module.scss';

import LibraryListItem from '../components/LibraryListItem';
import PrintGridItem from '../components/PrintGridItem';
import DateRange from '../components/DateRange';
import CheckInput from '../components/CheckInput';
import TagsSelectors from '../components/TagsSelectors';
import Loading from '../components/Loading';
import FiltersModal from '../components/FiltersModal';

import ListIcon from '../assets/icons/List';
import GridIcon from '../assets/icons/Grid';

import {
  setInitialDateFilter,
  setFinalDateFilter,
  setReservedFilter,
  setStrikedFilter,
  setIsPantoneFilter,
  setExclusiveFilter,
  setNoExclusiveFilter,
  setDigitalFilter,
  setCylinderFilter,
  setDraftsFilter,
  setColorVariationsFilter,
  setPendingReviewFilter,
  setSelectedCollectionsFilter,
  setSelectedClientsFilter,
  setSelectedDesignersFilter,
  setSelectedArtFinalistsFilter,
  setSelectedProveniencesFilter,
  setSelectedTagsFilter,
  setFilter,
  setIsOriginFilter,
  setIsTwinFilter,
  setIsColorVariantFilter
} from '../store/actions/libraryFilters';
import { setQuery } from '../store/actions/searchLibrary';
import { MasonryGrid } from '../components/MasonryGrid';

function Library() {
  const dispatch = useDispatch();

  const language = useSelector(state => state.settings.language);
  const searchQuery = useSelector(state => state.searchLibrary.query);
  const user = useSelector(state => state.user);
  const {
    initialDate,
    finalDate,
    reserved,
    striked,
    isOrigin,
    isTwin,
    isColorVariant,
    exclusive,
    noExclusive,
    isPantone,
    digital,
    cylinder,
    drafts,
    colorVariations,
    pendingReview,
    selectedCollections,
    selectedClients,
    selectedDesigners,
    selectedArtFinalists,
    selectedProveniences,
    selectedTags,
    filter
  } = useSelector(state => state.libraryFilters);

  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [designers, setDesigner] = useState([]);
  const [artFinalists, setArtFinalists] = useState([]);
  const [tags, setTags] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const [prints, setPrints] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [filtersModal, setFiltersModal] = useState(false);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [updatedPage, setUpdatedPage] = useState();
  const [counter, setCounter] = useState();

  const [display, setDisplay] = useState('grid');

  const [filteredCounter, setFilteredCounter] = useState();
  const [loading, setLoading] = useState(false);
  const [clearAllFilters, setClearAllFilters] = useState(false);

  const [reloadPrints, setReloadPrints] = useState(true);
  const [orderByValue, setOrderByValue] = useState("-id");

  const listRef = useRef();
  const gridRef = useRef();
  const filterTimer = useRef();

  useEffect(() => {
    Api.getCollections().then(res => {
      setCollections(res);
    });
    Api.getClients().then(res => {
      setClients(res);
    });
    Api.getDesigners().then(res => {
      setDesigner(res);
    });
    Api.getArtFinalists().then(res => {
      setArtFinalists(res);
    });
    Api.getTags().then(res => {
      setTags(res);
    });
    Api.getPresentationsMini()
      .then(res => {
        setPresentations(res);
      })
      .catch();
    Api.getFabrics()
      .then(res => {
        setFabrics(res.results);
      })
      .catch();
  }, [dispatch]);

  const getPrintsWithoutFilter = () => {
    setLoading(true);
    Api.getLibrary(`?page=${page - 1}&order_by=${orderByValue}`)
      .then(res => {
        setCounter(res.count);
        let numberOfPages = parseInt(res.count / 30, 10);
        if (res.count % 30) {
          numberOfPages += 1;
        }
        setLastPage(numberOfPages);
        if (res && res.results) {
          setPrints(res.results);
        }
        setLoading(false);
        Api.getFavoritePrints(`?page=0`).then(favoritePrints => {
          const favoritedPrints = res.results.map(p =>
            favoritePrints?.result?.includes(p.id)
              ? { ...p, isFavorite: true }
              : { ...p, isFavorite: false }
          );
          setPrints(favoritedPrints);
        });
      })
      .catch(() => {
        setPrints([]);
        setPage(1);
        setReloadPrints(true);
      })
      .finally(() => {
        setReloadPrints(false);
        setLoading(false);
      });
  };

  const getFilteredPrints = () => {
    setLoading(true);
    Api.getLibrary(`?page=${page - 1}${filter}&order_by=${orderByValue}`)
      .then(res => {
        setFilteredCounter(res.count);

        if (res && res.results) {
          setPrints(res.results);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
        }
        setLoading(false);
        Api.getFavoritePrints(`?page=0`).then(favoritePrints => {
          const favoritedPrints = res.results.map(p =>
            favoritePrints?.result?.includes(p.id)
              ? { ...p, isFavorite: true }
              : { ...p, isFavorite: false }
          );
          setPrints(favoritedPrints);
        });
      })
      .catch(() => {
        setPrints([]);
      })
      .finally(() => {
        setLoading(false);
        setReloadPrints(false);
      });
  };

  useEffect(() => {
    if (filterTimer.current) {
      clearTimeout(filterTimer.current);
    }
    filterTimer.current = setTimeout(() => {
      setReloadPrints(true);
    }, 2200);
  }, [initialDate, finalDate, searchQuery, orderByValue]);

  useEffect(() => {
    if (filterTimer.current) {
      clearTimeout(filterTimer.current);
    }
    filterTimer.current = setTimeout(() => {
      setReloadPrints(true);
    }, 1000);
  }, [searchQuery, orderByValue]);

  useEffect(() => {
    if (reloadPrints) {
      if (!filter) {
        getPrintsWithoutFilter();
        window.scrollTo(0, 0);
      } else {
        getFilteredPrints();
        window.scrollTo(0, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadPrints]);

  useEffect(() => {
    setPage(1);
    setUpdatedPage();
  }, [filter]);

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setReloadPrints(true);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setReloadPrints(true);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  const dispatchInitialDate = useCallback(
    value => {
      dispatch(setInitialDateFilter(value));
    },
    [dispatch]
  );

  const dispatchFinalDate = useCallback(
    value => {
      dispatch(setFinalDateFilter(value));
    },
    [dispatch]
  );

  const dispatchReserved = useCallback(
    value => {
      dispatch(setReservedFilter(value));
    },
    [dispatch]
  );

  const dispatchStriked = useCallback(
    value => {
      dispatch(setStrikedFilter(value))
    },
    [dispatch]
  );

  const dispatchIsOrigin = useCallback(
    value => {
      dispatch(setIsOriginFilter(value))
    },
    [dispatch]
  );

  const dispatchIsTwin = useCallback(
    value => {
      dispatch(setIsTwinFilter(value))
    },
    [dispatch]
  );

  const dispatchIsColorVariant = useCallback(
    value => {
      dispatch(setIsColorVariantFilter(value))
    },
    [dispatch]
  );

  const dispatchPantone = useCallback(
    value => {
      dispatch(setIsPantoneFilter(value))
    },
    [dispatch]
  );

  const dispatchExclusive = useCallback(
    value => {
      dispatch(setExclusiveFilter(value));
    },
    [dispatch]
  );

  const dispatchNoExclusive = useCallback(
    value => {
      dispatch(setNoExclusiveFilter(value));
    },
    [dispatch]
  );

  const dispatchDigital = useCallback(
    value => {
      dispatch(setDigitalFilter(value));
    },
    [dispatch]
  );

  const dispatchCylinder = useCallback(
    value => {
      dispatch(setCylinderFilter(value));
    },
    [dispatch]
  );

  const dispatchDrafts = useCallback(
    value => {
      dispatch(setDraftsFilter(value));
    },
    [dispatch]
  );

  const dispatchColorVariations = useCallback(
    value => {
      dispatch(setColorVariationsFilter(value));
    },
    [dispatch]
  );

  const dispatchPendingReview = useCallback(
    value => {
      dispatch(setPendingReviewFilter(value));
    },
    [dispatch]
  );

  const dispatchSelectedCollections = useCallback(
    value => {
      dispatch(setSelectedCollectionsFilter(value));
    },
    [dispatch]
  );

  const dispatchSelectedClients = useCallback(
    value => {
      dispatch(setSelectedClientsFilter(value));
    },
    [dispatch]
  );

  const dispatchSelectedDesigners = useCallback(
    value => {
      dispatch(setSelectedDesignersFilter(value));
    },
    [dispatch]
  );

  const dispatchSelectedArtFinalists = useCallback(
    value => {
      dispatch(setSelectedArtFinalistsFilter(value));
    },
    [dispatch]
  );

  const dispatchSelectedProveniences = useCallback(
    value => {
      dispatch(setSelectedProveniencesFilter(value));
    },
    [dispatch]
  );

  const dispatchSelectedTags = useCallback(
    value => {
      dispatch(setSelectedTagsFilter(value));
    },
    [dispatch]
  );

  const dispatchFilter = useCallback(
    value => {
      dispatch(setFilter(value));
    },
    [dispatch]
  );

  useEffect(() => {
    let query = '';
    function concat(filters) {
      query += `&${filters}`;
    }

    if (reserved) {
      concat('reserve=true');
    }

    if (striked) {
      concat('strike=true');
    }

    if(isPantone) {
      concat('pantone=true');
    }

    if (exclusive) {
      concat('exclusivity=true');
    }

    if (noExclusive) {
      concat('exclusivity=false');
    }

    if (colorVariations) {
      concat('color=true');
    }

    if (isOrigin) {
      concat('origin=true');
    }

    if (isTwin) {
      concat('twin=true');
    }

    if (digital && cylinder) {
      concat('type=BOT');
    } else if (digital) {
      concat('type=DIG');
    } else if (cylinder) {
      concat('type=CYL');
    }

    if (drafts) {
      concat('status=SKE');
    }

    if (pendingReview) {
      concat('already_reviewed=false');
    }

    if (searchQuery) {
      concat(`search=${searchQuery}`);
    }

    if (selectedCollections && selectedCollections.length) {
      let collectionsQuery = 'collection=';
      for (let i = 0; i < selectedCollections.length; i += 1) {
        if (i === selectedCollections.length - 1) {
          collectionsQuery += selectedCollections[i].id;
        } else {
          collectionsQuery += `${selectedCollections[i].id},`;
        }
      }
      concat(collectionsQuery);
    }

    if (selectedClients && selectedClients.length) {
      let clientsQuery = 'owner=';
      for (let i = 0; i < selectedClients.length; i += 1) {
        if (i === selectedClients.length - 1) {
          clientsQuery += selectedClients[i].id;
        } else {
          clientsQuery += `${selectedClients[i].id},`;
        }
      }
      concat(clientsQuery);
    }

    if (selectedArtFinalists && selectedArtFinalists.length) {
      let artFinalistsQuery = 'art_finisher=';
      for (let i = 0; i < selectedArtFinalists.length; i += 1) {
        if (i === selectedArtFinalists.length - 1) {
          artFinalistsQuery += selectedArtFinalists[i].id;
        } else {
          artFinalistsQuery += `${selectedArtFinalists[i].id},`;
        }
      }
      concat(artFinalistsQuery);
    }

    if (selectedDesigners && selectedDesigners.length) {
      let designersQuery = 'designer=';
      for (let i = 0; i < selectedDesigners.length; i += 1) {
        if (i === selectedDesigners.length - 1) {
          designersQuery += selectedDesigners[i].id;
        } else {
          designersQuery += `${selectedDesigners[i].id},`;
        }
      }
      concat(designersQuery);
    }

    if (selectedProveniences && selectedProveniences.length) {
      let proveniencesQuery = 'provenience=';
      for (let i = 0; i < selectedProveniences.length; i += 1) {
        if (i === selectedProveniences.length - 1) {
          proveniencesQuery += selectedProveniences[i].value;
        } else {
          proveniencesQuery += `${selectedProveniences[i].value},`;
        }
      }
      concat(proveniencesQuery);
    }

    if (selectedTags && selectedTags.length) {
      let tagsQuery = 'tags=';
      for (let i = 0; i < selectedTags.length; i += 1) {
        if (i === selectedTags.length - 1) {
          tagsQuery += selectedTags[i].id;
        } else {
          tagsQuery += `${selectedTags[i].id},`;
        }
      }
      concat(tagsQuery);
    }
    if (initialDate) {
      concat(`init_date=${moment(initialDate).format('MM-DD-YYYY')}`);
    }
    if (finalDate) {
      concat(`final_date=${moment(finalDate).format('MM-DD-YYYY')}`);
    }
    if (query === '?') {
      dispatchFilter('');
    } else {
      dispatchFilter(query);
    }
  }, [
    striked,
    isOrigin,
    isTwin,
    reserved,
    exclusive,
    noExclusive,
    isPantone,
    digital,
    cylinder,
    drafts,
    selectedTags,
    selectedCollections,
    selectedClients,
    selectedArtFinalists,
    selectedProveniences,
    initialDate,
    finalDate,
    colorVariations,
    selectedDesigners,
    pendingReview,
    searchQuery,
    dispatchFilter
  ]);

  const handleClearFilters = () => {
    setLoading(true);
    setClearAllFilters(true);
    dispatchReserved(false);
    dispatchStriked(false);
    dispatchPantone(false);
    dispatchIsOrigin(false);
    dispatchIsTwin(false);

    dispatchExclusive(false);

    dispatchNoExclusive(false);

    dispatchDigital(false);

    dispatchCylinder(false);

    dispatchDrafts(false);

    dispatchColorVariations(false);

    dispatchPendingReview(false);

    dispatchSelectedTags([]);
    dispatchSelectedCollections([]);
    dispatchSelectedClients([]);
    dispatchSelectedArtFinalists([]);
    dispatchSelectedDesigners([]);
    dispatchSelectedProveniences([]);

    dispatch(setQuery(''));

    setTimeout(() => {
      setFiltersModal(false);
      setReloadPrints(true);
    }, 2000);
  };

  const searchPrints = () => {
    setFiltersModal(false);
    setReloadPrints(true);
  };

  const favoritePrint = printId => {
    const updatedPrints = [...prints];
    const printIndex = updatedPrints.findIndex(up => up.id === printId);
    updatedPrints[printIndex].isFavorite = true;
    setPrints(updatedPrints);

    Api.staffFavoritePrint(printId, user.id)
      .then(() => {
        toast(translate('successFavoritingPrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorFavoritingPrint', language), {
          type: 'error'
        });
        updatedPrints[printIndex].isFavorite = false;
        setPrints(updatedPrints);
      });
  };

  const removeFavoritePrint = printId => {
    const updatedPrints = [...prints];
    const printIndex = updatedPrints.findIndex(up => up.id === printId);
    updatedPrints[printIndex].isFavorite = false;
    setPrints(updatedPrints);

    Api.removeFavoritePrint(printId)
      .then(() => {
        toast(translate('successRemovingFavoritePrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegPrint', language), {
          type: 'error'
        });
        updatedPrints[printIndex].isFavorite = true;
        setPrints(updatedPrints);
      });
  };

  const renderedList = useMemo(() => {
    const printsArray = prints;
    return (
      <ul className={styles.printsList} ref={listRef}>
        {page === 1 && loading && prints.length > 0 && <Loading />}
        {printsArray &&
          printsArray.map(print => (
            <LibraryListItem
              print={print}
              key={`${print.id}-${print.code}`}
              presentations={presentations}
              fabrics={fabrics}
            />
          ))}
      </ul>
    );
  }, [fabrics, loading, page, presentations, prints]);

  const renderedGrid = useMemo(() => {
    const printsArray = prints;
    return (
      <> 
        {printsArray && printsArray.length > 0 ? (
          <div className={styles.printsGrid}>
            <MasonryGrid>
              {printsArray && printsArray.map((print) => {
                      return(
                        <PrintGridItem
                          key={`${print.id}-${print.code}`}
                          id={print.id}
                          image={print.image_url || print.image}
                          code={print.code}
                          name={print.name}
                          psd_original={print.psd_original}
                          exclusivity={print.exclusivity}
                          reserved={print.reserved}
                          striked={print.striked}
                          pendingReview={'already_reviewed' in print && !print.already_reviewed}
                          type={print.type}
                          presentations={presentations}
                          fabrics={fabrics}
                          backgroundColor={
                            print.flat_background && print.flat_background_color
                              ? print.flat_background_color
                              : false
                          }
                          pantone={print.is_pantone ? print.pantone_color : false}
                          newTab
                          customizableBackground={print.flat_background}
                          newDesignPending={print.new_design_pending}
                          favorite={
                            print.isFavorite
                              ? () => removeFavoritePrint(print.id)
                              : () => favoritePrint(print.id)
                          }
                          isLeProvenience={print.provenience == 'LE'}
                          isMatteriaProvenience={print.provenience == 'MAT'}
                          isFavorite={print.isFavorite}
                        />
                      );
                  })}
            </MasonryGrid>
          </div>
        ) : (
          <div className={styles.emptyPrintList}>
            {translate('printNotFound', language)}
          </div>
        )}
      </>
    );
  }, [fabrics, presentations, prints]);

  return (
    <div className={styles.library}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          {translate('library', language).toUpperCase()}
        </h2>
        <Link to="/create-print" className={styles.actionButton}>
          {translate('registerPrint', language)}
        </Link>
      </div>
      <div className={`${styles.filtersContainer}`}>
        <div className={styles.topFilters}>
          <div className={styles.filters}>
            <div className={styles.dateFilter}>
              <DateRange
                selectInitialDate={dispatchInitialDate}
                selectFinalDate={dispatchFinalDate}
                clearDateRange={clearAllFilters}
                setClearDateRange={setClearAllFilters}
                startInitialDate={initialDate}
                startFinalDate={finalDate}
              />
            </div>
            <button
              type="button"
              className={styles.filtersButton}
              onClick={() => setFiltersModal(true)}
            >
              {translate('filters', language)}
            </button>
            <select 
              className={styles.listContent}
              onChange={(e) => setOrderByValue(e?.currentTarget?.value)}
            >
              <option value="-id">Mais recentes</option>
              <option value="id">Mais antigas</option>
              <option value="random">Aleatório</option>
              <option value="alphabetic">Alfabética</option>
              <option value="numeric">Numérica</option>
            </select>
            <div className={styles.filtersList}>
              {selectedTags.map(s => `${s.name}; `)}
              {selectedCollections.map(s => `${s.name}; `)}
              {selectedClients.map(s => `${s.name}; `)}
              {selectedDesigners.map(s => `${s.name}; `)}
              {selectedArtFinalists.map(s => `${s.name}; `)}
              {selectedProveniences.map(s => `${s.name}; `)}
              {reserved && `${translate('reserved', language)}; `}
              {striked && `${translate('striked', language)}; `}
              {isPantone && `${translate('pantone', language)}; `}
              {exclusive && `${translate('exclusive', language)}; `}
              {noExclusive && `${translate('notExclusive', language)}; `}
              {digital && `${translate('digital', language)}; `}
              {cylinder && `${translate('cylinder', language)}; `}
              {drafts && `${translate('drafts', language)}; `}
              {colorVariations &&
                `${translate('seeColorVariations', language)}; `}
              {pendingReview &&
                `${translate('seeOnlyPendingReview', language)}; `}
              
              {isOrigin && `${translate('origin', language)}; `}
              {isTwin && `${translate('twin', language)}; `}
            </div>
            {filter && (
              <button
                className={styles.clearFilters}
                type="button"
                onClick={handleClearFilters}
              >
                {translate('clearFilters', language)}
              </button>
            )}
          </div>
          <div className={styles.display}>
            <button type="button" onClick={() => setDisplay('list')}>
              <ListIcon active={display === 'list'} />
            </button>
            <button type="button" onClick={() => setDisplay('grid')}>
              <GridIcon active={display === 'grid'} />
            </button>
          </div>
        </div>
      </div>
      {filter && filteredCounter > 0 && (
        <div className={styles.counterContainer}>
          <span>{filteredCounter}</span>
        </div>
      )}
      {!filter && counter > 0 && (
        <div className={styles.counterContainer}>
          <span>{counter}</span>
        </div>
      )}
      {display === 'list' ? renderedList : renderedGrid}
      {(counter || filteredCounter) && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setReloadPrints(true);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setReloadPrints(true);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
      {filtersModal && (
        <FiltersModal title={translate('filters', language)}>
          <TagsSelectors
            selectLabel={translate('add', language)}
            collections={collections}
            tags={tags}
            clients={clients}
            designers={designers}
            artFinalists={artFinalists}
            startSelectedCollections={selectedCollections}
            startSelectedClients={selectedClients}
            startSelectedDesigners={selectedDesigners}
            startSelectedArtFinalists={selectedArtFinalists}
            startSelectedProveniences={selectedProveniences}
            startSelectedTags={selectedTags}
            selectTags={dispatchSelectedTags}
            selectCollections={dispatchSelectedCollections}
            selectClients={dispatchSelectedClients}
            selectArtFinalists={dispatchSelectedArtFinalists}
            selectDesigners={dispatchSelectedDesigners}
            selectProveniences={dispatchSelectedProveniences}
            setClearFilters={setClearAllFilters}
            complete
          />
          <div className={styles.checks}>
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('reserved', language)}
                onChange={dispatchReserved}
                value={reserved}
              />
              <CheckInput
                topMenu
                text={translate('striked', language)}
                onChange={dispatchStriked}
                value={striked}
              />
              <CheckInput
                topMenu
                text={translate('pantone', language)}
                onChange={dispatchPantone}
                value={isPantone}
              />
              <CheckInput
                topMenu
                text={translate('exclusive', language)}
                onChange={dispatchExclusive}
                value={exclusive}
              />
              <CheckInput
                topMenu
                text={translate('notExclusive', language)}
                onChange={dispatchNoExclusive}
                value={noExclusive}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('digital', language)}
                onChange={dispatchDigital}
                value={digital}
              />
              <CheckInput
                topMenu
                text={translate('cylinder', language)}
                onChange={dispatchCylinder}
                value={cylinder}
              />
              <CheckInput
                topMenu
                text={translate('original', language)}
                onChange={dispatchIsOrigin}
                value={isOrigin}
              />
              <CheckInput
                topMenu
                text={translate('twin', language)}
                onChange={dispatchIsTwin}
                value={isTwin}
              />
              <div />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('drafts', language)}
                onChange={dispatchDrafts}
                value={drafts}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('seeColorVariations', language)}
                onChange={dispatchColorVariations}
                value={colorVariations}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('seeOnlyPendingReview', language)}
                onChange={dispatchPendingReview}
                value={pendingReview}
              />
            </div>
            <div className={styles.filterActions}>
              <button
                className={styles.clearLibraryFilters}
                type="button"
                onClick={handleClearFilters}
              >
                {translate('clearFilters', language)}
              </button>
              <button
                className={`${styles.defaultActionButton} ${styles.showPrints}`}
                type="button"
                onClick={searchPrints}
              >
                {translate('showPrints', language)}
              </button>
            </div>
          </div>
        </FiltersModal>
      )}

      {loading && <Loading fixed />}
    </div>
  );
}

export default Library;
