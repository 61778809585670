import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './PurchaseSetExclusivity.module.scss';
import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';
import Api from '../libs/Api';

import ListSelector from './ListSelector';
import CheckInput from './CheckInput';
import CloseIcon from '../assets/icons/Close';

export default function PurchaseSetExclusivity({
  print,
  exclusivityPurchase,
  onClose,
  strike
}) {
  const { id: presentationId } = useParams();
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const exclusivitySelectorRef = useRef();
  const locationsSelectorRef = useRef();

  const exclusivities = [
    {
      id: 1,
      value: 'INF',
      name: translate('eternal', language),
      exclusivity: 'eternal'
    },
    {
      id: 2,
      value: 'MON',
      name: translate('worldwide', language),
      exclusivity: 'twoYears'
    },
    {
      id: 3,
      value: 'CON',
      name: translate('continental', language),
      exclusivity: 'twoYears'
    },
    {
      id: 4,
      value: 'NAT',
      name: translate('national', language),
      exclusivity: 'oneYearTwoMonths'
    },
    {
      id: 5,
      value: 'REG',
      name: translate('regional', language),
      exclusivity: 'oneYearTwoMonths'
    },
    { id: 6, value: 'MKT', name: translate('laEstampaMarketing', language) }
  ];

  const continents = [
    { id: 1, value: 'AF', name: translate('africa', language) },
    {
      id: 2,
      value: 'AM',
      name: translate('america', language)
    },
    { id: 3, value: 'AN', name: translate('antarctica', language) },
    { id: 4, value: 'EU', name: translate('europe', language) },
    { id: 5, value: 'AS', name: translate('asia', language) },
    { id: 6, value: 'OC', name: translate('oceania', language) }
  ];
  const [selectedExclusivity, setSelectedExclusivity] = useState();
  const [exclusivitySelector, setExclusivitySelector] = useState(false);
  const [locationsSelector, setLocationsSelector] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [exclusivityList, setExclusivityList] = useState([]);
  const [drawingExclusivity, setDrawingExclusivity] = useState(false);
  const [colorVariationExclusivity, setColorVariationExclusivity] = useState(
    false
  );
  const [client, setClient] = useState();
  const [idCode, setIdCode] = useState();

  useEffect(() => {
    setExclusivitySelector(false);
  }, [selectedExclusivity]);

  useOutsideClick(exclusivitySelectorRef, () => {
    setExclusivitySelector(false);
  });

  useEffect(() => {
    setLocationsSelector(false);
  }, [selectedLocations]);

  useOutsideClick(locationsSelectorRef, () => {
    setLocationsSelector(false);
  });

  useEffect(() => {
    if (strike) {
      const savedExclusivity = {
        exclusivity: {
          id: 2,
          value: 'PIL',
          name: translate('worldwide', language),
          exclusivity: 'threeMonths'
        },
        exclusivity_int: [],
        exclusivity_reg: [],
        exclusivity_cont: [],
        exclusivity_var: 'DES',
        seller: user.id,
        buyer: client || '',
        print: print ? print.id : '',
        idCode: idCode || ''
      };
      setExclusivityList([savedExclusivity]);
    }
    Api.getRegion().then(res => {
      setRegions(res);
    });
    Api.getCountries().then(res => {
      setCountries(res);
    });
    if (presentationId) {
      Api.getPresentation(presentationId).then(res => {
        setClient(res.client);
      });
    }
  }, [client, idCode, language, presentationId, print, strike, user.id]);

  const getLocationList = useMemo(() => {
    if (selectedExclusivity) {
      switch (selectedExclusivity.value) {
        case 'CON':
          return continents;
        case 'NAT':
          return countries;
        case 'REG':
          return regions;
        default:
          return [];
      }
    } else {
      return [];
    }
  }, [continents, countries, regions, selectedExclusivity]);

  const getLabel = useMemo(() => {
    if (selectedExclusivity) {
      switch (selectedExclusivity.value) {
        case 'CON':
          return translate('continental', language);
        case 'NAT':
          return translate('country', language);
        case 'REG':
          return translate('states', language);
        default:
          return [];
      }
    } else {
      return [];
    }
  }, [language, selectedExclusivity]);

  const getExclusivityVar = () => {
    if (colorVariationExclusivity && drawingExclusivity) {
      return 'DES';
    }
    if (colorVariationExclusivity) {
      return 'COR';
    }
    if (drawingExclusivity) {
      return 'DES';
    }
    return '';
  };

  const getExclusivityInt = () => {
    if (selectedExclusivity.value === 'NAT') {
      return selectedLocations;
    }
    return [];
  };

  const getExclusivityReg = () => {
    if (selectedExclusivity.value === 'REG') {
      return selectedLocations;
    }
    return [];
  };

  const getExclusivityCont = () => {
    if (selectedExclusivity.value === 'CON') {
      return selectedLocations;
    }
    return [];
  };

  const addOtherExclusivity = () => {
    if (selectedExclusivity) {
      const savedExclusivity = {
        exclusivity: selectedExclusivity,
        exclusivity_int: getExclusivityInt(),
        exclusivity_reg: getExclusivityReg(),
        exclusivity_cont: getExclusivityCont(),
        exclusivity_var: getExclusivityVar(),
        seller: user.id,
        buyer: client || '',
        print: print ? print.id : '',
        idCode: idCode || ''
      };
      setSelectedExclusivity();
      setSelectedLocations([]);
      const updatedExclusivityList = [...exclusivityList];
      updatedExclusivityList.push(savedExclusivity);
      setExclusivityList(updatedExclusivityList);
    }
  };

  const renderExclusivityListItemCountries = value => {
    let countryNames = '';

    countries.forEach(country => {
      value.exclusivity_int.forEach(v => {
        if (v.id === country.id) {
          countryNames += `${country.name}; `;
        }
      });
    });

    return countryNames;
  };

  const renderExclusivityListItemRegions = value => {
    let regionNames = '';

    regions.forEach(region => {
      value.exclusivity_reg.forEach(v => {
        if (v.id === region.id) {
          regionNames += `${region.name}; `;
        }
      });
    });

    return regionNames;
  };

  const removeExclusivity = value => {
    let updatedExclusivityList = [...exclusivityList];
    updatedExclusivityList = updatedExclusivityList.filter(
      ex => ex.exclusivity !== value
    );
    setExclusivityList(updatedExclusivityList);
  };

  const purchaseExclusivity = () => {
    if (selectedExclusivity) {
      const lastExclusivity = {
        exclusivity: selectedExclusivity,
        exclusivity_int: getExclusivityInt(),
        exclusivity_reg: getExclusivityReg(),
        exclusivity_cont: getExclusivityCont(),
        seller: user.id,
        buyer: client || '',
        print: print ? print.id : '',
        exclusivity_var: getExclusivityVar(),
        idCode: idCode || ''
      };
      exclusivityPurchase([...exclusivityList, lastExclusivity]);
    } else {
      exclusivityPurchase([...exclusivityList]);
    }
  };

  return (
    <div className={styles.content}>
      {onClose && (
        <button type="button" onClick={onClose} className={styles.close}>
          <CloseIcon />
        </button>
      )}
      {print && (
        <>
          <h3>{print.print_name}</h3>
          <h2>{print.print_code}</h2>
          <hr className={styles.title} />
        </>
      )}

      <ul className={styles.exclusivityList}>
        {exclusivityList.map(item => (
          <li>
            <div className={styles.name}>{item.exclusivity.name}</div>
            {item.exclusivity_int.length > 0 && (
              <div className={styles.locations}>
                {renderExclusivityListItemCountries(item)}
              </div>
            )}
            {item.exclusivity_reg.length > 0 && (
              <div className={styles.locations}>
                {renderExclusivityListItemRegions(item)}
              </div>
            )}

            {strike && item.exclusivity && item.exclusivity.exclusivity && (
              <div className={styles.exclusivityTime}>
                <span>{translate(item.exclusivity.exclusivity, language)}</span>{' '}
                {item.exclusivity.value !== 'INF' &&
                  translate('ofExclusivity', language)}
              </div>
            )}

            {!strike && (
              <button
                type="button"
                className={styles.delete}
                onClick={() => removeExclusivity(item.exclusivity)}
              >
                {translate('delete', language)}
              </button>
            )}
          </li>
        ))}
      </ul>

      {!strike && (
        <div className={styles.selectorContainer} ref={exclusivitySelectorRef}>
          <button
            type="button"
            className={`${styles.defaultSelectInput}`}
            onClick={() => setExclusivitySelector(true)}
          >
            {selectedExclusivity
              ? selectedExclusivity.name
              : translate('selectExclusivity', language)}
          </button>
          {exclusivitySelector && (
            <span>
              <ListSelector
                items={exclusivities}
                onSelect={setSelectedExclusivity}
                value={selectedExclusivity}
                selectLabel={translate('choose', language)}
                fullWidth
              />
            </span>
          )}
        </div>
      )}

      {selectedExclusivity && selectedExclusivity.exclusivity && (
        <div className={styles.exclusivityTime}>
          <span>{translate(selectedExclusivity.exclusivity, language)}</span>{' '}
          {selectedExclusivity.value !== 'INF' &&
            translate('ofExclusivity', language)}
        </div>
      )}
      {!strike && (
        <>
          <p>{translate('typesOfExclusivity', language)}:</p>
          <hr className={styles.type} />
          <div className={styles.checkContainer}>
            <CheckInput
              text={translate('drawing', language)}
              value={drawingExclusivity}
              onChange={setDrawingExclusivity}
            />
            <CheckInput
              text={translate('colorVariation', language)}
              value={colorVariationExclusivity}
              onChange={setColorVariationExclusivity}
            />
          </div>
          <hr className={styles.type} />
          {selectedExclusivity &&
            selectedExclusivity.value !== 'INF' &&
            selectedExclusivity.value !== 'MON' &&
            selectedExclusivity.value !== 'MKT' && (
              <>
                <p>{getLabel}:</p>
                <div
                  className={styles.selectorContainer}
                  ref={locationsSelectorRef}
                >
                  <button
                    type="button"
                    className={`${styles.defaultSelectInput}`}
                    onClick={() => setLocationsSelector(true)}
                  >
                    {selectedLocations.length
                      ? selectedLocations.map(location => `${location.name}; `)
                      : getLabel}
                  </button>

                  {locationsSelector && (
                    <span>
                      <ListSelector
                        items={getLocationList}
                        onSelect={setSelectedLocations}
                        values={selectedLocations}
                        multiple
                        selectLabel={translate('choose', language)}
                        fullWidth
                        search
                      />
                    </span>
                  )}
                </div>
              </>
            )}
          {selectedExclusivity && (
            <button
              className={styles.addExclusivity}
              type="button"
              onClick={addOtherExclusivity}
            >
              <div>{translate('addAnotherExclusivity', language)}</div>
              <div className={styles.plusSignal}>+</div>
            </button>
          )}
        </>
      )}

      {strike && (
        <div className={styles.idCode}>
          <p>
            {translate('writeIDCode', language)}{' '}
            <span>({translate('ifExists', language)})</span>
          </p>
          <input
            type="text"
            className={styles.defaultTextInput}
            placeholder="Ex: L28477"
            value={idCode}
            onChange={e => setIdCode(e.currentTarget.value)}
          />
        </div>
      )}

      <div className={styles.actionsContainer}>
        <button
          className={styles.defaultActionButton}
          type="button"
          onClick={purchaseExclusivity}
          disabled={!colorVariationExclusivity && !drawingExclusivity}
        >
          {print
            ? translate('addToCart', language)
            : translate('save', language)}
        </button>
      </div>
    </div>
  );
}

PurchaseSetExclusivity.propTypes = {
  print: PropTypes.shape.isRequired,
  exclusivityPurchase: PropTypes.func.isRequired,
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  strike: PropTypes.bool
};

PurchaseSetExclusivity.defaultProps = {
  onClose: false,
  strike: false
};
