import React from 'react';
import { useSelector } from 'react-redux';

import styles from './ClientLogout.module.scss';
import text from '../libs/i18n';

export default function ClientLogout() {
  const language = useSelector(state => state.settings.language);

  return (
    <div className={styles.logout}>
      <h2>{text('thankYou', language)}</h2>
    </div>
  );
}
