import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from './FavoriteAttendances.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';

import SearchIcon from '../assets/icons/Search';
import AttendanceGridItem from '../components/AttendanceGridItem';
import ConfirmationModal from '../components/ConfirmationModal';
import FiltersModal from '../components/FiltersModal';
import TagsSelectors from '../components/TagsSelectors';
import DateRange from '../components/DateRange';
import Loading from '../components/Loading';

function FavoriteAttendances() {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const [selectedAttendances, setSelectedAttendances] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [filtersModal, setFiltersModal] = useState(false);
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [reloadAttendances, setReloadAttendances] = useState(false);
  const [searchQueryTimeout, setSearchQueryTimeout] = useState(null);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [updatedPage, setUpdatedPage] = useState();
  const [counter, setCounter] = useState();
  const [filteredCounter] = useState();

  useEffect(() => {
    Api.getClients().then(res => {
      setClients(res);
    });
    Api.getCustomerProfiles().then(res => {
      setProfiles(res);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (filter) {
      setInitialLoad(true);
      Api.getFavoriteAttendances().then(favoriteAttendances => {
        if (favoriteAttendances.length) {
          Api.getAttendances(
            `ids=${favoriteAttendances.toString()}&${filter}&page=${page - 1}`
          )
            .then(res => {
              // setFilteredCounter(res.count);
              setAttendances(res.result.map(p => ({ ...p, isFavorite: true })));
              let numberOfPages = parseInt(res.count / 30, 10);
              if (res.count % 30) {
                numberOfPages += 1;
              }
              setLastPage(numberOfPages);
            })
            .finally(() => {
              setLoading(false);
              setInitialLoad(false);
            });
        } else {
          setLoading(false);
          setInitialLoad(false);
        }
      });
    } else {
      setInitialLoad(true);
      Api.getFavoriteAttendances().then(favoriteAttendances => {
        if (favoriteAttendances.length) {
          Api.getAttendances(
            `ids=${favoriteAttendances.toString()}&page=${page - 1}`
          )
            .then(res => {
              setCounter(res.count);
              let numberOfPages = parseInt(res.count / 30, 10);
              if (res.count % 30) {
                numberOfPages += 1;
              }
              setLastPage(numberOfPages);
              setAttendances(res.result.map(p => ({ ...p, isFavorite: true })));
            })
            .catch()
            .finally(() => {
              setLoading(false);
              setInitialLoad(false);
            });
        } else {
          setLoading(false);
          setInitialLoad(false);
        }
      });
    }
  }, [page]);

  const getAttendancesWithoutFilter = () => {
    setLoading(true);
    Api.getFavoriteAttendances().then(favoriteAttendances => {
      if (favoriteAttendances.length) {
        Api.getAttendances(
          `ids=${favoriteAttendances.toString()}&page=${page - 1}`
        )
          .then(res => {
            setCounter(res.count);
            let numberOfPages = parseInt(res.count / 30, 10);
            if (res.count % 30) {
              numberOfPages += 1;
            }
            setLastPage(numberOfPages);
            setAttendances(res.result.map(p => ({ ...p, isFavorite: true })));
          })
          .catch()
          .finally(() => {
            setLoading(false);
            setInitialLoad(false);
          });
      } else {
        setLoading(false);
        setInitialLoad(false);
      }
    });
  };

  const getFilteredAttendances = () => {
    setLoading(true);
    Api.getFavoriteAttendances().then(favoriteAttendances => {
      if (favoriteAttendances.length) {
        Api.getAttendances(
          `ids=${favoriteAttendances.toString()}&${filter}&page=${page - 1}`
        )
          .then(res => {
            // setFilteredCounter(res.count);
            setAttendances(res.result.map(p => ({ ...p, isFavorite: true })));
            let numberOfPages = parseInt(res.count / 30, 10);
            if (res.count % 30) {
              numberOfPages += 1;
            }
            setLastPage(numberOfPages);
          })
          .finally(() => {
            setLoading(false);
            setInitialLoad(false);
          });
      } else {
        setLoading(false);
        setInitialLoad(false);
      }
    });
  };

  useEffect(() => {
    if (reloadAttendances) {
      if (!filter) {
        getAttendancesWithoutFilter();
      } else {
        getFilteredAttendances();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadAttendances]);

  useEffect(() => {
    let query = '';
    function concat(filters) {
      if (query) {
        query += `&${filters}`;
      } else {
        query += filters;
      }
    }
    if (selectedClients && selectedClients.length) {
      let clientsQuery = 'client_id=';

      clientsQuery += selectedClients.map(sc => sc.id).toString();

      concat(clientsQuery);
    }

    if (selectedProfiles && selectedProfiles.length) {
      let profilesQuery = 'customer_profile=';

      profilesQuery += selectedProfiles.map(sp => sp.id).toString();

      concat(profilesQuery);
    }

    if (initialDate) {
      concat(`init_date=${moment(initialDate).format('MM-DD-YYYY')}`);
    }
    if (finalDate) {
      concat(`final_date=${moment(finalDate).format('MM-DD-YYYY')}`);
    }

    if (searchQuery.length) {
      concat(`search=${searchQuery}`);
    }

    if (query === '?') {
      setFilter('');
    } else if (query !== '?' && searchQuery.length) {
      setSearchQueryTimeout(setTimeout(() => setFilter(query), 1000));
    } else {
      setFilter(query);
    }
  }, [searchQuery, selectedClients, initialDate, finalDate, selectedProfiles]);

  useEffect(() => {
    if (searchQueryTimeout) {
      clearTimeout(searchQueryTimeout);
    }
    setSearchQueryTimeout(
      setTimeout(() => {
        setReloadAttendances(true);
      }, 1000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  const renderMissingCardSpaces = useMemo(() => {
    if (attendances.length) {
      const numberOfEmptyCards = attendances.length % 4;
      const emptyCards = [];

      for (let i = 1; i <= numberOfEmptyCards; i += 1) {
        emptyCards.push(i);
      }

      return emptyCards.map(i => <div key={i} className={styles.emptyCard} />);
    }
    return false;
  }, [attendances]);

  const duplicateAttendances = useCallback(() => {
    setLoading(true);
    const duplicateRequests = [];

    selectedAttendances.forEach(attendance => {
      const deleteRequest = Api.duplicateAttendance(attendance);
      duplicateRequests.push(deleteRequest);
    });
    axios
      .all(duplicateRequests)
      .then(() => {
        Api.getFavoriteAttendances().then(favoriteAttendances => {
          if (favoriteAttendances.length) {
            Api.getAttendances(
              `ids=${favoriteAttendances.toString()}&page=${page - 1}`
            )
              .then(res => {
                // setFilteredCounter(res.count);
                setAttendances(
                  res.result.map(p => ({ ...p, isFavorite: true }))
                );
                let numberOfPages = parseInt(res.count / 30, 10);
                if (res.count % 30) {
                  numberOfPages += 1;
                }
                setLastPage(numberOfPages);
              })
              .finally(() => {
                setLoading(false);
                setInitialLoad(false);
              });
          } else {
            setLoading(false);
            setInitialLoad(false);
          }
        });
        setSelectedAttendances([]);
      })
      .catch(() => {
        toast(translate('failDuplicateAttendances', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language, page, selectedAttendances]);

  const deleteAttendances = () => {
    setLoading(true);
    setConfirmationModal(false);
    const deleteRequests = [];

    selectedAttendances.forEach(attendance => {
      const deleteRequest = Api.deleteAttendance(attendance);
      deleteRequests.push(deleteRequest);
    });
    axios
      .all(deleteRequests)
      .then(() => {
        Api.getFavoriteAttendances().then(favoriteAttendances => {
          if (favoriteAttendances.length) {
            Api.getAttendances(
              `ids=${favoriteAttendances.toString()}&page=${page - 1}`
            )
              .then(res => {
                // setFilteredCounter(res.count);
                setAttendances(
                  res.result.map(p => ({ ...p, isFavorite: true }))
                );
                let numberOfPages = parseInt(res.count / 30, 10);
                if (res.count % 30) {
                  numberOfPages += 1;
                }
                setLastPage(numberOfPages);
              })
              .finally(() => {
                setLoading(false);
                setInitialLoad(false);
              });
          } else {
            setLoading(false);
            setInitialLoad(false);
          }
        });
        setSelectedAttendances([]);
      })
      .catch(() => {
        toast(translate('failDeletingAttendances', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSelectAttendance = (id, selected) => {
    let currentSelectedAttendances = [...selectedAttendances];
    const containsAttendance = currentSelectedAttendances.find(
      attendance => attendance === id
    );
    if (!selected && containsAttendance) {
      currentSelectedAttendances = currentSelectedAttendances.filter(
        attendance => attendance !== id
      );
    } else if (selected && !containsAttendance) {
      currentSelectedAttendances = [...selectedAttendances, id];
    }
    setSelectedAttendances(currentSelectedAttendances);
  };

  const handleClearFilters = () => {
    setLoading(true);
    setClearAllFilters(true);
    setSearchQuery('');
    setSelectedClients([]);
    setSelectedProfiles([]);
    setInitialDate();
    setFinalDate();
    setFilter('');
    setTimeout(() => {
      setClearAllFilters(false);
    }, 500);

    setFiltersModal(false);
  };

  const searchAttendances = () => {
    setFiltersModal(false);
    setReloadAttendances(true);
  };

  const favoriteAttendance = attendanceId => {
    const updatedAttendances = [...attendances];
    const attendanceIndex = updatedAttendances.findIndex(
      att => att.id === attendanceId
    );
    updatedAttendances[attendanceIndex].isFavorite = true;
    setAttendances(updatedAttendances);

    Api.staffFavoriteAttendance(attendanceId, user.id)
      .then(() => {
        toast(translate('successFavoritingAttendance', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorFavoritingAttendance', language), {
          type: 'error'
        });
        updatedAttendances[attendanceIndex].isFavorite = false;
        setAttendances(updatedAttendances);
      });
  };

  const removeFavoriteAttendance = attendanceId => {
    const updatedAttendances = [...attendances];
    const attendanceIndex = updatedAttendances.findIndex(
      up => up.id === attendanceId
    );
    const currentAttendance = updatedAttendances[attendanceIndex];
    updatedAttendances.splice(attendanceIndex, 1);
    setAttendances(updatedAttendances);

    Api.removeFavoriteAttendance(attendanceId)
      .then(() => {
        toast(translate('successRemovingFavoriteAttendance', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegAttendance', language), {
          type: 'error'
        });
        updatedAttendances.splice(attendanceIndex, 0, currentAttendance);
        setAttendances(updatedAttendances);
      });
  };

  return (
    <div className={styles.favoriteAttendances}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          <span className={styles.title}>
            {translate('attendances', language).toUpperCase()}
          </span>
        </h2>
      </div>
      <div className={styles.content}>
        <div className={styles.filters}>
          <div className={styles.left}>
            <div className={styles.inputContainer}>
              <SearchIcon color="#a3b3c7" />
              <input
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className={styles.exclusivityFilter}
                placeholder={translate('searchAttendance', language)}
              />
            </div>
            <button
              type="button"
              className={styles.filtersButton}
              onClick={() => setFiltersModal(true)}
            >
              {translate('filters', language)}
            </button>
          </div>
        </div>

        <div className={styles.attendancesGrid}>
          {attendances.map(attendance => (
            <AttendanceGridItem
              key={`${attendance.name}-${attendance.id}`}
              attendance={attendance}
              onSelect={onSelectAttendance}
              favorite={
                attendance.isFavorite
                  ? () => removeFavoriteAttendance(attendance.id)
                  : () => favoriteAttendance(attendance.id)
              }
              isFavorite={attendance.isFavorite}
            />
          ))}
          {renderMissingCardSpaces}
        </div>
        {loading && <Loading fixed={!initialLoad} />}
      </div>
      {confirmationModal && (
        <ConfirmationModal
          message={translate('confirmAttendancesDelete', language)}
          confirmText={translate('yes', language)}
          cancelText={translate('no', language)}
          onConfirm={deleteAttendances}
          onCancel={() => setConfirmationModal(false)}
          deleteWarning
        />
      )}
      {(counter || filteredCounter) && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
      <div
        className={`${
          styles.actionsMenuContainer
        } ${selectedAttendances.length > 0 && styles.actionsMenuActive}`}
      >
        <div className={styles.actionsMenu}>
          <div className={styles.left}>
            <div>
              {`${selectedAttendances.length} ${
                selectedAttendances.length > 1
                  ? translate('selectedMultiple', language)
                  : translate('selectedOne', language)
              }`}
            </div>
            <span className={styles.separator}>|</span>
            <button
              onClick={() => {}}
              disabled={!selectedAttendances.length}
              type="button"
              className={styles.duplicate}
            >
              {translate('duplicate', language)}
            </button>
          </div>
          <button
            onClick={() => setConfirmationModal(true)}
            disabled={!selectedAttendances.length}
            type="button"
            className={styles.delete}
          >
            {translate('delete', language)}
          </button>
        </div>
      </div>
      <div
        className={`${
          styles.actionsMenuContainer
        } ${selectedAttendances.length > 0 && styles.actionsMenuActive}`}
      >
        <div className={styles.actionsMenu}>
          <div className={styles.left}>
            <div>
              {`${selectedAttendances.length} ${
                selectedAttendances.length > 1
                  ? translate('selectedMultiple', language)
                  : translate('selectedOne', language)
              }`}
            </div>
            <span className={styles.separator}>|</span>
            <button
              onClick={duplicateAttendances}
              disabled={!selectedAttendances.length}
              type="button"
              className={styles.duplicate}
            >
              {translate('duplicate', language)}
            </button>
          </div>
          <button
            onClick={() => setConfirmationModal(true)}
            disabled={!selectedAttendances.length}
            type="button"
            className={styles.delete}
          >
            {translate('delete', language)}
          </button>
        </div>
      </div>
      {filtersModal && (
        <FiltersModal
          title={translate('filters', language)}
          fixedHeight
          fullWidth
        >
          <div className={styles.tagsSelectorsContainer}>
            <TagsSelectors
              selectLabel={translate('add', language)}
              clients={clients}
              selectClients={setSelectedClients}
              profiles={profiles}
              selectProfiles={setSelectedProfiles}
              setClearFilters={setClearAllFilters}
              startSelectedClients={selectedClients}
              startSelectedProfiles={selectedProfiles}
            />
          </div>
          <div className={styles.datesContainer}>
            <div className={styles.dateFilter}>
              <p className={styles.sectionLabel}>
                {translate('period', language)}
              </p>
              <DateRange
                selectInitialDate={setInitialDate}
                selectFinalDate={setFinalDate}
                clearDateRange={clearAllFilters}
                setClearDateRange={setClearAllFilters}
                startInitialDate={initialDate}
                startFinalDate={finalDate}
              />
            </div>
            <div />
          </div>

          <div className={styles.filterActions}>
            <button
              className={styles.clearAttendancesFilters}
              type="button"
              onClick={handleClearFilters}
            >
              {translate('clearFilters', language)}
            </button>
            <button
              className={`${styles.defaultActionButton} ${styles.showAttendances}`}
              type="button"
              onClick={searchAttendances}
            >
              {translate('showAttendances', language)}
            </button>
          </div>
        </FiltersModal>
      )}
    </div>
  );
}

export default FavoriteAttendances;
