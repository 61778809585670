import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styles from './MyOrders.module.scss';
import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';
import DateRange from './DateRange';
import ListSelector from './ListSelector';
import Loading from './Loading';
import CloseIcon from '../assets/icons/Close';
import { Link } from 'react-router-dom';
import { AiFillDelete, AiFillPrinter } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { formatDate } from '../utils/formatDate';
import { useHistory } from "react-router-dom";
import Api from '../libs/Api';
import { HiDotsVertical } from "react-icons/hi";
import { AiFillFilePdf, AiOutlineReload } from "react-icons/ai";
import { Popover } from './Popover';
import ConfirmationModal from './ConfirmationModal';
import moment from 'moment';
import { OrderDeliveryStatus } from './OrderDeliveryStatus';

function MyOrders({
  orders,
  client,
  supplier,
  setSupplier,
  setClient,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
  // hasNextPage,
  // onClickLoadMore,
  loadingMoreOrders,
  clients,
  suppliers,
  status,
  productionType,
  updateOrders,
  isManagerLec,
  isAgentLec,
  setCEFilter,
  setOrderNumberFilter,
  clearFilters,
}) {
  const language = useSelector(state => state.settings.language);
  const [section, setSection] = useState('OPEN');
  const [clientsSelector, setClientsSelector] = useState('closed');
  const [supplierSelector, setSupplierSelector] = useState('closed');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedProductionType, setSelectedProductionType] = useState();
  const [productionTypeSelector, setProductionTypeSelector] = useState('closed');
  const [agentsSelector, setAgentsSelector] = useState('closed');
  const [selectedAgent, setSelectedAgent] = useState();
  const [isAlertOrderFina, setIsAlertOrderFina] = useState(false);
  const [isAlertRefreshExcel, setIsAlertRefreshExcel] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const loadMoreRef = useRef();
  const clientSelectorRef = useRef();
  const productionTypeSelectorRef = useRef();
  const agentsSelectorRef = useRef();
  const supplierSelectorRef = useRef();
  const orderInputRef = useRef();
  const ceInputRef = useRef();
  const history = useHistory();

  const productionTypeOptions = [
    {
      id: 1,
      value: 'PRODUCAO',
      name: 'production'
    },
    {
      id: 2,
      value: 'PILOTO',
      name: 'strike'
    },
    {
      id: 3,
      value: 'HANGER',
      name: 'hanger'
    },
    {
      id: 4,
      value: 'RESERVA',
      name: 'reserve'
    },
    {
      id: 5,
      value: 'PRIORIDADE',
      name: 'priority'
    }
  ];

  useEffect(() => {
    updateOrders();
  }, [selectedOrder]);

  useOutsideClick(clientSelectorRef, () => {
    setClientsSelector('closed');
  });

  useOutsideClick(supplierSelectorRef, () => {
    setSupplierSelector('closed');
  });

  useOutsideClick(clientSelectorRef, () => {
    setProductionTypeSelector('closed');
  });

  useOutsideClick(agentsSelectorRef, () => {
    setAgentsSelector('closed');
  })

  useEffect(() => {
    status(section);
  }, [section, status]);

  useEffect(() => {
    if (selectedProductionType) {
      productionType(selectedProductionType.value);
    } else {
      productionType('');
    }
  }, [selectedProductionType, productionType]);

  async function handleConfirmationPrintModal() {
    const parsedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.000ZZ')
    try {
      await Api.updateOrder(selectedOrder?.id, {
        status: "FINA",
        date_delivered: parsedDate, 
        date_sent: parsedDate,
      });
      window.open(`etiqueta/${selectedOrder?.id}`, "_blank");
      setSection("FINA");
    } catch {

    } finally {
      setIsAlertOrderFina(false);
    }
  }

  async function handleFinishOrder() {
    try {
      const parsedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.000ZZ')
      await Api.updateOrder(selectedOrder?.id, {
        status: "FINA",
        date_sent: parsedDate,
      });
      setSection("FINA");
    } catch {

    } finally {
      setIsFinishModalOpen(false);
    }
  }

  async function handleDeleteOrder() {
    try {
      await Api.updateOrder(selectedOrder.id, { status: "INACTIVE" });
      updateOrders();
      toast(translate('deleteOrderMessage', language), {
        type: 'success'
      });
    } catch {
      toast(translate('deleteOrderErrorMessage', language), {
        type: 'error'
      });
    } finally {
      setIsDeleteModalOpen(false);
    }
    
  }

  async function handleConfirmationRefreshExcel() {
    try {
      setIsLoading(true);
      await Api.getRefreshExcel(selectedOrder?.id);
      toast(translate('refreshExcelSuccessMessageToast', language), {
        type: 'success'
      });
    } catch {
      toast(translate('refreshExcelWaitMessageToast', language), {
        type: 'warning'
      });
    } finally {
      setIsAlertRefreshExcel(false);
      setIsLoading(false);
    }
  }

  async function handleSubmitFilterInputs() {
    const orderNumberFilterValue = orderInputRef?.current?.value
    const ceFilterValue = ceInputRef?.current?.value
    setCEFilter(ceFilterValue ?? '')
    setOrderNumberFilter(orderNumberFilterValue ?? '')
  }

  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmationModal 
          title='Deseja deletar o pedido?'
          message='Ao confirmar, o pedido será deletado!'
          confirmText='Sim'
          cancelText='Não'
          onConfirm={handleDeleteOrder}
          onCancel={() => setIsDeleteModalOpen(false)}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
      {isFinishModalOpen && (
        <ConfirmationModal 
          title='Deseja finalizar o pedido?'
          message='Ao confirmar, o pedido será considerado como finalizado!'
          confirmText='Sim'
          cancelText='Não!'
          onConfirm={handleFinishOrder}
          onCancel={() => setIsFinishModalOpen(false)}
          onClose={() => setIsFinishModalOpen(false)}
        />
      )}
      {isAlertOrderFina && (
        <ConfirmationModal 
          title='Deseja finalizar o pedido?'
          message='Ao confirmar, o pedido será considerado como finalizado e enviado!'
          confirmText='Sim'
          cancelText='Não, emitir sem finalizar!'
          onConfirm={handleConfirmationPrintModal}
          onCancel={() => {
            window.open(`etiqueta/${selectedOrder?.id}`, "_blank");
            setIsAlertOrderFina(false);
          }}
          onClose={() => setIsAlertOrderFina(false)}
        />
      )}
      {isAlertRefreshExcel && (
        <ConfirmationModal 
          title={translate('refreshExcelTitle', language)}
          message={translate('refreshExcelDescription', language)}
          confirmText={translate('refreshExcelConfirmButton', language)}
          cancelText={translate('refreshExcelCancelButton', language)}
          onConfirm={handleConfirmationRefreshExcel}
          onCancel={() => setIsAlertRefreshExcel(false)}
          onClose={() => setIsAlertRefreshExcel(false)}
          isLoading={isLoading}
        />
      )}
      <div className={styles.myOrders}>
        <div className={styles.filterOrdersWrapper}>
          <div className={styles.filterOrders}>
            <div className={styles.dateRangeWrapper}>
              <DateRange
                selectInitialDate={setInitialDate}
                selectFinalDate={setFinalDate}
                startInitialDate={initialDate}
                startFinalDate={finalDate}
              />
            </div>
            <form
              onSubmit={(e) => {
                handleSubmitFilterInputs()
                e.preventDefault()
              }} 
              className={styles.filterInputContainer}
            >
              <input 
                ref={orderInputRef}
                className={styles.inputHeight}
                placeholder={translate('orders', language)}
              />
              <input 
                ref={ceInputRef}
                className={styles.inputHeight}
                placeholder='CE'
              />
              <button type='submit' hidden></button>
            </form>
            <div className={styles.listSelectorClientWrapper}>
              <div className={styles.clientNameSection} ref={clientSelectorRef}>
                <button
                  id=""
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                  onClick={() => setClientsSelector('opened')}
                >
                  {client ? client.name : translate('client', language)}
                  {client && (
                    <button
                      type="button"
                      onClick={e => {
                        e.stopPropagation();
                        setClient();
                      }}
                      className={styles.clearClient}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </button>
                {clientsSelector === 'opened' && (
                  <span>
                    <ListSelector
                      label={translate('clients', language)}
                      selectLabel={translate('choose', language)}
                      items={clients}
                      onSelect={setClient}
                      value={client}
                      search
                      fullWidth
                    />
                  </span>
                )}
              </div>
            </div>
            <div className={styles.listSelectorClientWrapper}>
              <div className={styles.clientNameSection} ref={supplierSelectorRef}>
                <button
                  id=""
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                  onClick={() => setSupplierSelector('opened')}
                >
                  {supplier ? supplier.name : translate('supplier', language)}
                  {supplier && (
                    <button
                      type="button"
                      onClick={e => {
                        e.stopPropagation();
                        setSupplier();
                      }}
                      className={styles.clearClient}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </button>
                {supplierSelector === 'opened' && (
                  <span>
                    <ListSelector
                      label={translate('supplier', language)}
                      selectLabel={translate('choose', language)}
                      items={suppliers}
                      onSelect={setSupplier}
                      value={supplier}
                      search
                      fullWidth
                    />
                  </span>
                )}
              </div>
            </div>
            <div className={styles.listSelectorClientWrapper}>
              <div
                className={styles.clientNameSection}
                ref={productionTypeSelectorRef}
              >
                <button
                  id=""
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                  onClick={() => setProductionTypeSelector('opened')}
                >
                  {selectedProductionType
                    ? selectedProductionType.name
                    : translate('typeOfProduction', language)}
                  {selectedProductionType && (
                    <button
                      type="button"
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedProductionType();
                      }}
                      className={styles.clearClient}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </button>
                {productionTypeSelector === 'opened' && (
                  <span>
                    <ListSelector
                      label={translate('typeOfProduction', language)}
                      selectLabel={translate('choose', language)}
                      items={productionTypeOptions}
                      onSelect={setSelectedProductionType}
                      value={selectedProductionType}
                      fullWidth
                    />
                  </span>
                )}
              </div>
              </div>
              <div className={styles.listSelectorClientWrapper}>
                <div
                  className={styles.clientNameSection}
                  ref={productionTypeSelectorRef}
                >
                  <button
                    id=""
                    type="button"
                    className={`${styles.defaultSelectInput} ${styles.selectInput} ${styles.resetFilter}`}
                    onClick={() => {
                      if(ceInputRef?.current) {
                        ceInputRef.current.value = '';
                      }
                      if(orderInputRef?.current) {
                        orderInputRef.current.value = '';
                      }
                      setSelectedProductionType('')
                      clearFilters()
                    }}
                  >
                    {translate('clearFilters', language)}
                  </button>
                </div>
              </div>
                {isManagerLec && 
                  <div className={styles.listSelectorClientWrapper}>
                    <div
                      className={styles.clientNameSection}
                      ref={agentsSelectorRef}
                    >
                      <button
                        id=""
                        type="button"
                        className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                        onClick={() => setAgentsSelector('opened')}
                      >
                        {selectedAgent
                          ? selectedAgent.name
                          : translate('agents', language)}
                        {selectedAgent && (
                          <button
                            type="button"
                            onClick={e => {
                              e.stopPropagation();
                              setSelectedAgent();
                            }}
                            className={styles.clearClient}
                          >
                            <CloseIcon />
                          </button>
                        )}
                      </button>
                      {agentsSelector === 'opened' && (
                        <span>
                          <ListSelector
                            label={translate('agents', language)}
                            selectLabel={translate('choose', language)}
                            items={productionTypeOptions}
                            onSelect={setSelectedAgent}
                            value={selectedAgent}
                            fullWidth
                          />
                        </span>
                      )}
                    </div>
                </div>
              }
          </div>
        </div>
        <div className={styles.sections}>
          <button
            type="button"
            className={section === 'OPEN' && styles.active}
            onClick={() => setSection('OPEN')}
          >
            {translate('orderApproval', language)}
          </button>
          <button
            type="button"
            className={section === 'REV' && styles.active}
            onClick={() => setSection('REV')}
          >
            {translate('orderRevision', language)}
          </button>
          <button
            type="button"
            className={section === 'PROD' && styles.active}
            onClick={() => setSection('PROD')}
          >
            {translate('orderInProgress', language)}
          </button>
          <button
            type="button"
            className={section === 'FINA' && styles.active}
            onClick={() => setSection('FINA')}
          >
            {translate('orderFinished', language)}
          </button>
        </div>
        {orders && orders.length ? (
          orders.map((order, index) => {
            const orderKey = `order-${order.id}-${index}`;
            return (
              <div key={orderKey} className={styles.orderItem}>
                <div className={styles.clientName}>
                  {order.status === 'REP' && (
                    <span className={styles.disapprovedIcon} />
                  )}
                  {order.client_name}
                </div>
                <div
                  className={`${styles.orderDescription} ${
                    styles[order.status]
                  }`}
                >
                  {order && order.status && (
                    <>
                      <span
                        className={`${
                          order.status === 'REP' ? styles.disapproved : ''
                        } ${styles.descriptionInfo}`}
                      >
                        <span className={styles.orderItens}>{formatDate(order.date)}</span>
                        <span className={styles.orderNumber}>No: {order.order_number}</span>
                        <span className={styles.orderItens}>{order.itens}</span>
                        <div className={styles.statusInfo}>
                          {translate(`descriptionInfo${order.status}`, language)}
                          {order.status === "FINA" && (
                            <span className={styles.statusDate}>Enviado: {formatDate(order?.date_sent)}</span>
                          )}
                        </div>
                      </span>
                      
                    </>
                  )}

                </div>
                <div className={styles.actionSection}>
                  {section === 'OPEN' && (
                    <button
                      className={`${styles.defaultRoundedActionButton} ${styles.approve}`}
                      type="button"
                      onClick={() => history.push(`order/${order.id}`)}
                    >
                      {isAgentLec ? translate('effective', language) : translate('approve', language)}
                    </button>
                  )}
                  {section === 'PROD' && (
                    <button
                      className={`${styles.defaultRoundedActionButton} ${styles.finish}`}
                      type="button"
                      onClick={() => {
                        setSelectedOrder(order);
                        setIsFinishModalOpen(true);
                      }}
                    >
                    {translate('finishButton', language)}
                  </button>
                  )}
                  {(section === 'REV' || section === 'PROD') && (
                    (
                        <OrderDeliveryStatus 
                          status={order.status_delivery}
                          expirated_prints={order.expirated_prints}
                        />
                    )
                  )}
                  <button
                    type="button"
                    className={styles.seeDetails}
                    onClick={() => history.push(`order/${order.id}`)}
                  >
                    <span>{translate('seeDetails', language)}</span>
                  </button>
                  {/* <button
                    type="button"
                    className={styles.seeOptions}
                    onClick={() => setSeeOptions(!seeOptions)}
                  >
                    <HiDotsVertical color="black" size={30} />
                    {seeOptions && (
                      <section>
                         <Link 
                            to={`etiqueta/${order.id}`}
                            className={styles.seeDetails} 
                            target="_blank"
                          >
                              <AiFillPrinter color="black" size={30}/>
                          </Link>
                          <a
                            className={styles.seeDetails} 
                            target="_blank"
                            onClick={() => Api.getExcel(order.id)}
                          > 
                            <RiFileExcel2Fill  color="black" size={30} />
                          </a>
                      </section>
                    )}
                  </button> */}
                  <Popover icon={<HiDotsVertical color="black" size={30} />}>
                   
                      <Link 
                        to={`pdf/${order.id}`}
                        className={styles.seeOptions} 
                        target="_blank"
                      >
                          <AiFillFilePdf color="black" size={30}/>
                      </Link>
                      {section !== "OPEN" && (
                        <>
                          <div
                            style={{
                              position: 'relative'
                            }}
                          >
                            <a
                              className={styles.seeOptions} 
                              target="_blank"
                              onClick={() => Api.getExcel(order.id)}
                            > 
                              <RiFileExcel2Fill  color="black" size={30} />
                            </a>

                            <a
                              className={styles.refreshExcel} 
                              target="_blank"
                              onClick={() => {
                                setSelectedOrder(order)
                                setIsAlertRefreshExcel(true)
                              }}
                            >
                              <AiOutlineReload color="black" size={15} />
                            </a>
                          </div>
                          <Link 
                            // to={`etiqueta/${order.id}`}
                            onClick={(e) => {
                              setSelectedOrder(order);
                              if(section === "FINA") {
                                window.open(`etiqueta/${order.id}`, "_blank");
                                e.preventDefault();
                                return
                              }
                              setIsAlertOrderFina(true);
                              e.preventDefault();
                            }}
                            className={styles.seeOptions} 
                          >
                              <AiFillPrinter color="black" size={30}/>
                          </Link>
                        </>
                      )}
                      {section !== "FINA" && (
                        <Link 
                          // to={`etiqueta/${order.id}`}
                          onClick={(e) => {
                            setSelectedOrder(order);
                            setIsDeleteModalOpen(true);
                            e.preventDefault();
                          }}
                          className={styles.seeOptions} 
                        >
                            <AiFillDelete color="red" size={30}/>
                        </Link>
                      )}
                  </Popover>
                 
                </div>
              </div>
            );
          })
        ) : (
          <>
            {!loadingMoreOrders && (
              <div className={styles.noOrders}>
                {translate('noOrders', language)}
              </div>
            )}
          </>
        )}
      </div>
      {loadingMoreOrders && <Loading line />}
      {/* {selectedOrder && (
        <OrderCart
          currentOrder={selectedOrder}
          close={() => setSelectedOrder(false)}
        />
      )} */}
    </>
  );
}

export default MyOrders;

MyOrders.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      client_name: PropTypes.string,
      date: PropTypes.string,
      city: PropTypes.any,
      total_price: PropTypes.string,
      status: PropTypes.string,
      currency: PropTypes.any,
      customer_meeting: PropTypes.any,
      client: PropTypes.number
    })
  ).isRequired,
  client: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  setClient: PropTypes.func.isRequired,
  setInitialDate: PropTypes.func.isRequired,
  setFinalDate: PropTypes.func.isRequired,
  // onClickLoadMore: PropTypes.func.isRequired,
  loadingMoreOrders: PropTypes.bool.isRequired,
  // hasNextPage: PropTypes.bool.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape).isRequired,
  status: PropTypes.func.isRequired,
  productionType: PropTypes.func.isRequired,
  updateOrders: PropTypes.func.isRequired
};
