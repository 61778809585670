import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss"
import { Input } from "../Input";
import { Select } from "../Select";
import Api from "../../../libs/Api";
import translate from '../../../libs/i18n';
import { useSelector } from "react-redux";
interface OrderListSearchProps { 
  handleFilterData?: any;
  filter?: any;
  setFilter?: any;
  handleClearFilterData?: any;
}

export interface SearchParams {
  order?: string;
  supplier_number?: string;
  print_code?: string;
  type?: string;
  attendance_fabric_supplier?: string;
  fabric_name?: string;
  service?: string;
}

const typeArr = [
  {
    id: 1,
    value: "PRODUCAO",
    label: "PRODUÇÃO"
  },
  {
    id: 2,
    value: "PILOTO",
    label: "PILOTO"
  },
  {
    id: 3,
    value: "HANGER",
    label: "HANGER"
  },
];

export function FilterComponent({ handleFilterData, handleClearFilterData, filter, setFilter, ...rest }: OrderListSearchProps) {
  // const { searchOrder } = useOrder();
  //@ts-ignore
  const language = useSelector(state => state.settings.language);
  const [supplier, setSupplier] = useState();
  const [order, setOrder] = useState("");
  const [supplierNumber, setSupplierNumber] = useState("");
  const [printCode, setPrintCode] = useState("");
  const [type, setType] = useState();
  const [supplierFabric, setSupplierFabric] = useState();
  const [fabric, setFabric] = useState();
  const [service, setService] = useState();
  const [serviceOptions, setServiceOptions] = useState([]);
  const [supplierFabrics, setSupplierFabrics] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const handleSearch = async () => {
    handleFilterData();
  }

  const handleCleanSerch = async () => {
    setSupplier(undefined);
    setOrder("");
    setSupplierNumber("");
    setPrintCode("");
    setType(undefined);
    setSupplierFabric(undefined);
    setFabric(undefined);
    setService(undefined);
    handleClearFilterData();
  }
  useEffect(() => {
    let query = '';
    function concat(filters: any) {
      query += `&${filters}`;
    }
    if (supplier) {
      concat(`supplier_id=${supplier}`);
    }
    if (order) {
      concat(`order=${order}`);
    }
    if (supplierNumber) {
      concat(`supplier_number=${supplierNumber}`);
    }
    if (printCode) {
      concat(`print_code=${printCode}`);
    }
    if (type) {
      concat(`po_type=${type}`);
    }
    if (supplierFabric) {
      concat(`supplier_fabric=${supplierFabric}`);
    }
    if (fabric) {
      concat(`fabric=${fabric}`);
    }
    if (service) {
      concat(`service=${service}`);
    }
    if (query === '?') {
      setFilter('');
    } else {
      setFilter(query);
    }
  }, 
  [
    supplier, 
    order, 
    supplierNumber, 
    printCode,
    type,
    supplierFabric,
    fabric,
    service
  ]);
  useEffect(() => {
    Api.getServices().then(res => {
      //@ts-ignore
      const formattedArr = res?.map(service => {
        return {
          id: service.id,
          value: service.id,
          label: service.name,
        }
      })
      //@ts-ignore
      setServiceOptions(formattedArr)
    })
    Api.getSupplierFabric().then((res) => {
      if(res) {
        
        //@ts-ignore
        const formattedData = res?.map((supplier) => ({
          label: supplier.name || '',
          value: supplier.id || '',
          id: supplier.supplier || '',
          fabric_id: supplier.fabric || ''
        }));
        //@ts-ignore
        setSupplierFabrics(formattedData);
      }
    })
    Api.getFabrics().then(res => {
      if(res?.results) {
        //@ts-ignore
        const formattedData = res?.results?.map((fabric) => ({
          label: fabric.name || '',
          value: fabric.id || '',
          id: fabric.id || ''
        }));
        setFabrics(formattedData);
      }
    });
    Api.getSupplier('type=s,b').then((res) => {
      if(res?.results) {
        //@ts-ignore
        const formattedData = res?.results?.map((supplier) => ({
          label: supplier.name || '',
          value: supplier.id || '',
          id: supplier.id || ''
        }));
        setSuppliers(formattedData);
      }
    })
  }, [])
  return (
    <div 
        className={styles.container}
    >
        <form 
          onSubmit={handleSearch}
        >
          <Select
            label={translate("supplier", language)}
            placeholder={translate("selectSupplier", language)}
            value={supplier}
            onChange={value => setSupplier(value)}
            options={suppliers}
            //@ts-ignore
            filterOption={(input, option) => option?.label?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase())}
            allowClear
          />
          <Input
            label={translate("orderNumber", language)}
            name="order"
            placeholder=""
            value={order}
            onChange={e => setOrder(e.currentTarget.value)}
          />
          <Input
            label={translate("supplierNumber", language)}
            name="supplier_number"
            placeholder="CE00000"
            value={supplierNumber}
            onChange={e => setSupplierNumber(e.currentTarget.value)}
          />
          <Input  
            label="Design"
            name="print_code"
            placeholder="L0000"
            value={printCode}
            onChange={e => setPrintCode(e.currentTarget.value)}
          />
          <Select 
            label={translate("type", language)}
            options={typeArr}
            value={type}
            onChange={value => setType(value)}
            placeholder={translate("selectType", language)}
            allowClear

          />
          <Select 
            label={translate("supplierFabric", language)}
            options={supplierFabrics}
            value={supplierFabric}
            onChange={value => setSupplierFabric(value)}
            placeholder="Select a Supplier fabric"
            //@ts-ignore
            filterOption={(input, option) => option?.label?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase())}
            allowClear
          />
          <Select
            label={translate("fabric", language)}
            options={fabrics}
            value={fabric}
            onChange={value => setFabric(value)}
            placeholder={translate("selectFabric", language)}
            //@ts-ignore
            filterOption={(input, option) => option?.label?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase())}
            allowClear
          />
          <Select 
            label={translate("service", language)}
            options={serviceOptions}
            value={service}
            onChange={value => setService(value)}
            placeholder={translate("selectService", language)}
            //@ts-ignore
            filterOption={(input, option) => option?.label?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase())}
            allowClear
          />
          <button
            className={styles.defaultRoundedActionButton}
            type="button"
            onClick={handleFilterData}
          >
            Search
          </button>
          <button
            type="button"
            onClick={handleCleanSerch}
            className={styles.defaultRoundedActionButton}
          >
            X
          </button>
        </form>
    </div>
  );
}