import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import translate from '../libs/i18n';
import styles from './SectionDivisor.module.scss';

function SectionDivisor({ title, big, addAction, fileLoader }) {
  const language = useSelector(state => state.settings.language);

  return (
    <>
      <div className={styles.container}>
        <p className={`${styles.title} ${big ? styles.big : ''}`}>{title}</p>
        {addAction && (
          <button className={styles.add} type="button" onClick={addAction}>
            {translate('add', language)}
          </button>
        )}
        {fileLoader && (
          <div className={styles.add}>
            {translate('add', language)}
            <input
              type="file"
              onChange={e => fileLoader(Array.from(e.currentTarget.files)[0])}
            />
          </div>
        )}
      </div>

      <hr className={styles.line} />
    </>
  );
}

SectionDivisor.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  big: PropTypes.bool,
  addAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  fileLoader: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

SectionDivisor.defaultProps = {
  title: false,
  big: false,
  addAction: false,
  fileLoader: false
};

export default React.memo(SectionDivisor);
