import React, { SelectHTMLAttributes, forwardRef } from "react";
import styles from "./styles.module.scss"
import { Select as AntdSelect, SelectProps} from 'antd';
interface InputProps extends SelectProps{
    label?: string;
    options: any[];
}
const SelectBase = ({label, options, ...rest}: InputProps) => {

    return(
        <div className={styles.container}>
            {label && <p>{label}</p>}
            <AntdSelect 
                showSearch
                options={options}
                {...rest}
            >
                {options?.map(op => <option value={op.value}>{op.title}</option>)}
            </AntdSelect>
        </div>
    )
}

export const Select = forwardRef(SelectBase);