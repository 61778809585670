import React from 'react';
import { useSelector } from 'react-redux';

import styles from './CreateAttendance.module.scss';
import translate from '../libs/i18n';

import AttendanceForm from '../components/AttendanceForm';

function CreateAttendance() {
  const language = useSelector(state => state.settings.language);

  return (
    <div className={styles.createAttendance}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          {translate('registerAttendance', language).toUpperCase()}
        </h2>
      </div>
      <div className={styles.form}>
        <AttendanceForm />
      </div>
    </div>
  );
}

export default CreateAttendance;
