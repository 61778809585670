import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import CheckInput from './CheckInput';
import EditIcon from '../assets/icons/Edit';
import FavoriteIcon from '../assets/icons/Heart';

import styles from './AttendanceGridItem.module.scss';

export default function Attendance({
  attendance,
  onSelect,
  favorite,
  isFavorite
}) {
  const history = useHistory();
  const [checked, setChecked] = useState(false);

  return (
    <div className={styles.attendanceCard}>
      <div className={styles.check}>
        <CheckInput
          card
          value={checked}
          onChange={value => {
            setChecked(value);
            onSelect(attendance.id, value);
          }}
          id={attendance.id}
        />
      </div>
      <button
        type="button"
        className={styles.editAttendance}
        onClick={() => history.push(`/edit-attendance/${attendance.id}`)}
      >
        <EditIcon />
      </button>
      <Link to={`/attendances/${attendance.id}`} className={styles.cardLink}>
        <div className={styles.printGridItem}>
          {attendance.cover_url ? (
            <div
              className={styles.imageContainer}
              style={
                attendance.backgroundColor
                  ? { backgroundColor: attendance.backgroundColor }
                  : {}
              }
            >
              <img
                className={styles.image}
                src={attendance.cover_url}
                alt={attendance.id}
              />
              {favorite && (
                <button
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    favorite();
                  }}
                  type="button"
                  className={styles.favorite}
                >
                  <FavoriteIcon active={isFavorite} />
                </button>
              )}
            </div>
          ) : (
            <div className={styles.imageReplace}>
              {favorite && (
                <button
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    favorite();
                  }}
                  type="button"
                  className={styles.favorite}
                >
                  <FavoriteIcon active={isFavorite} />
                </button>
              )}
            </div>
          )}

          <div className={styles.info}>
            <div className={styles.name}>{attendance.name}</div>
            <hr />
          </div>
        </div>
      </Link>
    </div>
  );
}

Attendance.propTypes = {
  onSelect: PropTypes.func.isRequired,
  attendance: PropTypes.oneOfType([PropTypes.shape, PropTypes.bool]),
  favorite: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isFavorite: PropTypes.bool
};

Attendance.defaultProps = {
  attendance: false,
  favorite: () => {},
  isFavorite: false
};
