const initialState = {};

const reducer = (state = initialState, action) => {
  let newProducts;
  switch (action.type) {
    case '@loggedClient/REGISTER':
      return {
        ...state,
        ...action.loggedClient
      };
    case '@loggedClient/UNREGISTER':
      return {};
    case '@loggedClient/SET_PRESENTATION':
      return {
        ...state,
        presentation: action.presentation
      };
    case '@loggedClient/SET_PRINTS':
      return {
        ...state,
        prints: action.prints
      };
    case '@loggedClient/SET_CONCEPT':
      return {
        ...state,
        concept: action.concept
      };
    case '@loggedClient/SET_TAGS':
      return {
        ...state,
        tags: action.tags
      };
    case '@loggedClient/SET_DOLLS':
      return {
        ...state,
        dolls: action.dolls
      };
    case '@loggedClient/SET_FABRICS':
      return {
        ...state,
        fabrics: action.fabrics
      };
    case '@loggedClient/SET_PRODUCTS':
      return [...action.prints];

    case '@loggedClient/ADD_PRODUCT':
      return [...state, ...action.prints];

    case '@loggedClient/REMOVE_PRODUCT':
      newProducts = state.filter(
        product => parseInt(product.product_id, 10) !== parseInt(action.id, 10)
      );
      return [...newProducts];
    default:
      return state;
  }
};

export default reducer;
