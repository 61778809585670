import React , { createContext, useContext, useEffect, useRef, useState } from 'react';
const PdfTagContext = createContext(null);
export function PdfTagProvider({ children })  {
    const selectedPrintsRef = useRef();
    const [selectedPrintsContext, setSelectedPrintsContext] = useState(() => {
      const storagedPrint = localStorage.getItem('@Match:selectedPrints');
      if (storagedPrint) {
       return JSON.parse(storagedPrint);
      }
      return [];
    });
    useEffect(() => {
      selectedPrintsRef.current = selectedPrintsContext;
    })
    const selectedPrintsPreviousValue = selectedPrintsRef.current ?? selectedPrintsContext;
    useEffect(() => {
      if (selectedPrintsPreviousValue !== selectedPrintsContext) {
        localStorage.setItem('@Match:selectedPrints', JSON.stringify(selectedPrintsContext))
      }
    }, [selectedPrintsContext, selectedPrintsPreviousValue])
    return (
        <PdfTagContext.Provider 
              value={{
                selectedPrintsContext,
                setSelectedPrintsContext, 
              }}>
            {children}
        </PdfTagContext.Provider>
    )
}


export function usePDFTag() {
    const context = useContext(PdfTagContext);
    return context
}