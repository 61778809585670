import React from 'react';
import PropTypes from 'prop-types';

function Share({ width, height, color }) {
  return (
    <svg width={width} height={height} viewBox="0 0 13 17">
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.704 1.922a.8.8 0 01.1 1.594l-.1.006H1.926a.792.792 0 00-.786.693l-.007.1v8.76c0 .403.303.737.694.785l.099.007h8.76a.794.794 0 00.786-.694l.006-.099V8.296a.8.8 0 011.594-.1l.006.1v4.778a2.393 2.393 0 01-2.229 2.387l-.164.006h-8.76a2.393 2.393 0 01-2.386-2.23l-.006-.163v-8.76c0-1.266.984-2.302 2.23-2.386l.163-.006h4.778zm7.963-2.389h.016l.072.005-.088-.005a.8.8 0 01.14.013l.042.008c.026.006.05.013.075.021l.032.012.06.027.044.023c.019.01.037.022.055.033l.034.025c.025.02.05.04.073.063l.008.007-.008-.007a.84.84 0 01.084.094l-.074-.084a.802.802 0 01.134.177l.022.041.025.059.015.041.018.066.007.036c.003.014.006.03.007.045l.004.03.002.037v4.81a.8.8 0 01-1.593.1l-.006-.1V2.264L6.472 9.658a.8.8 0 01-1.209-1.041l.078-.09 7.393-7.395-2.846.001a.8.8 0 01-.794-.7l-.006-.1c0-.408.305-.744.7-.793l.1-.007h4.778z"
      />
    </svg>
  );
}

Share.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Share.defaultProps = {
  width: 18,
  height: 18,
  color: 'white'
};

export default React.memo(Share);
