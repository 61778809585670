import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './LightBox.module.scss';
import useOutsideClick from '../libs/useOutsideClick';

export default function LightBox({ image, backgroundColor, close }) {
  const imageRef = useRef();

  useOutsideClick(imageRef, () => {
    close();
  });

  return (
    <div className={`${styles.container}`}>
      <iframe
        id="my_iframe"
        title="force_download"
        style={{ display: 'none' }}
      />
      <div
        className={styles.imageContainer}
        style={{ backgroundColor: backgroundColor || '#FFFFFF' }}
      >
        <img
          ref={imageRef}
          className={styles.image}
          onError={() => {
            document.getElementById('my_iframe').src = image;
            setTimeout(() => {
              close();
            }, 1000);
          }}
          src={image}
          alt="lightbox"
        />
      </div>
    </div>
  );
}

LightBox.propTypes = {
  image: PropTypes.string.isRequired,
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  close: PropTypes.func.isRequired
};

LightBox.defaultProps = {
  backgroundColor: false
};
