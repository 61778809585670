import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useOutsideClick from '../libs/useOutsideClick';
import styles from './AddNewMenu.module.scss';
import PlusIcon from '../assets/icons/Plus';
import text from '../libs/i18n';

export const AddNewMenu = ({isAgentLec, isExtDesigner}) => {
  const language = useSelector(state => state.settings.language);

  const [inputState, setInputState] = useState('closed');

  const ref = useRef();

  const changeInputState = state => {
    if (state === 'closed') {
      setInputState('opened');
    } else {
      setInputState('closed');
    }
  };

  useOutsideClick(ref, () => {
    changeInputState(inputState);
  });

  return (
    <div className={styles.addNew}>
      <button
        type="button"
        className={styles.plusIcon}
        onClick={() => changeInputState(inputState)}
      >
        <PlusIcon />
      </button>
      {inputState === 'opened' && (
        <div className={styles.optionsContainer} ref={ref}>
          <ul>
            {isAgentLec && 
              (
                <>
                  <li>
                    <Link
                      to="/create-attendance"
                      onClick={() => setInputState('closed')}
                    >
                      {text('attendance', language)}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/register-client"
                      onClick={() => setInputState('closed')}
                    >
                      {text('client', language)}
                    </Link>
                  </li>
                </>
              )
            }
            {isExtDesigner && 
            (
              <>
              </>
            )

            }
            {!isExtDesigner && !isAgentLec && 
            (
              <>
                  <li>
                      <Link to="/create-print" onClick={() => setInputState('closed')}>
                        {text('pattern', language)}
                      </Link>
                  </li>
                  <li>
                    <Link
                      to="/briefings/?create"
                      onClick={() => setInputState('closed')}
                    >
                      {text('briefing', language)}
                    </Link>
                  </li>
                    <li>
                      <Link
                        to="/create-presentation"
                        onClick={() => setInputState('closed')}
                      >
                        {text('presentation', language)}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/create-attendance"
                        onClick={() => setInputState('closed')}
                      >
                        {text('attendance', language)}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/register-client"
                        onClick={() => setInputState('closed')}
                      >
                        {text('client', language)}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/register-designer"
                        onClick={() => setInputState('closed')}
                      >
                        {text('externalDesigner', language)}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/register-user-client"
                        onClick={() => setInputState('closed')}
                      >
                        {text('userClient', language)}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/create-pantone"
                        onClick={() => setInputState('closed')}
                      >
                        Pantone
                      </Link>
                  </li>
                  <li>
                    <Link
                      to="/qualities"
                      onClick={() => setInputState('closed')}
                    >
                      {
                      'Qualidades'
                      //text('fabrics', language)
                      }
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/suppliers"
                      onClick={() => setInputState('closed')}
                    >
                      {
                      'Fornecedores'
                      //text('fabrics', language)
                      }
                    </Link>
                  </li>
                </>
            )
            }
          </ul>
        </div>
      )}
    </div>
  );
};

export default React.memo(AddNewMenu);
