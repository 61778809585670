import React, { memo, useMemo, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import translate from '../libs/i18n';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';
import styles from './PresentationGridItem.module.scss';
import CheckInput from './CheckInput';
import ListSelector from './ListSelector';
import EditIcon from '../assets/icons/Edit';
import FavoriteIcon from '../assets/icons/Heart';
import ShoppingCartIcon from '../assets/icons/ShoppingCart';

function PrintGridItem({
  id,
  image,
  name,
  type,
  onSelect,
  attendances,
  fromAttendance,
  selectable,
  client,
  mini,
  favorite,
  isFavorite,
  whenDragging,
  clients,
  onClickCart
}) {
  const language = useSelector(state => state.settings.language);
  const history = useHistory();
  const attendanceSelectorRef = useRef();
  const [checked, setChecked] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState();
  const [attendanceSelector, setAttendanceSelector] = useState(false); 

  const changeAttendanceSelector = state => {
    if (!state) {
      setAttendanceSelector(true);
    } else {
      setAttendanceSelector(false);
    }
  };



  useOutsideClick(attendanceSelectorRef, () => {
    changeAttendanceSelector(attendanceSelector);
    setSelectedAttendance();
  });



  useEffect(() => {
    if (selectedAttendance) {
      Api.includeAttendancePresentation({
        meeting_id: id,
        meeting_box_id: selectedAttendance.id
      })
        .then(() => {
          toast(
            `${translate('successAddingPresentationToAttendance', language)}`,
            {
              type: 'success'
            }
          );
        })
        .catch(() => {
          toast(
            `${translate('failAddingPresentationToAttendance', language)}`,
            {
              type: 'error'
            }
          );
        });
      setAttendanceSelector(false);
      setSelectedAttendance();
    }
  }, [id, language, selectedAttendance]);
  
  const getImagesGrid = useMemo(() => {
    return (
      <div 
        className={styles.imagesGrid}
      >
        
        {image && <img src={image} alt="print" />}
        <button className={styles.shopingcart}
              onClick={(e) => {
                e.stopPropagation();   
                e.preventDefault();
                onClickCart();
              }}
              >
                <ShoppingCartIcon color={'#9FAAB8'}/>
        </button>
        {favorite && (
          <button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              favorite();
            }}
            type="button"
            className={styles.favorite}
          >
            <FavoriteIcon active={isFavorite} />
          </button>
        )}
      </div>
    );
  }, [image, favorite, isFavorite]);

  return (
    <>
{/* //     <ConfirmationModal
//               title={translate('modalClientTitle', language)}
//               confirmText={translate('confirmText', language)}
//               cancelText={translate('cancelText', language)}
//               onConfirm={() => {}}
//               onCancel={() => setConfirmationModal(false)}
//               deleteWarning
//             >
//             <div className={styles.clientSelector}>
//             <button
//               type="button"
//               className={`${styles.defaultSelectInput}`}
//               onClick={e => {
//                 e.stopPropagation();
//                 setAttendanceSelector(true);
//               }}
//             >
//               {translate('modalClientTitle', language)}
//             </button>
//           </div>
//           {attendanceSelector && (
//             <span
//               className={styles.selectorClientPosition}
//               ref={attendanceSelectorRef}
//             >
//               <div className={styles.clientList}>
//                 <ListSelector
//                   selectLabel={translate('choose', language)}
//                   items={attendances}
//                   onSelect={setSelectedAttendance}
//                   value={selectedAttendance}
//                   fullWidth
//                   search
//                   action={() => history.push('/create-attendance')}
//                   actionText={translate('createAttendance', language)}
//                 />
//               </div>
              
//             </span>
//             )}
// </ConfirmationModal>) */}
    <div className={`${styles.card} ${mini ? styles.small : ''}`}>
      {attendances && attendances.length > 0 && (
        <div className={styles.attendanceSelector}>
          <button
            type="button"
            className={`${styles.defaultSelectInput}`}
            onClick={e => {
              e.stopPropagation();
              setAttendanceSelector(true);
            }}
          >
            {translate('chooseAttendance', language)}
          </button>
          {attendanceSelector && (
            <span
              className={styles.selectorPosition}
              ref={attendanceSelectorRef}
            >
              <div className={styles.list}>
                <ListSelector
                  selectLabel={translate('choose', language)}
                  items={attendances}
                  onSelect={setSelectedAttendance}
                  value={selectedAttendance}
                  fullWidth
                  search
                  action={() => history.push('/create-attendance')}
                  actionText={translate('createAttendance', language)}
                />
              </div>
            </span>
          )}
        </div>
      )}
      {!client && (
        <div className={styles.check}>
          <CheckInput
            card
            value={checked}
            onChange={value => {
              setChecked(value);
              if (selectable) {
                onSelect(
                  {
                    id,
                    name,
                    image
                  },
                  value
                );
              } else {
                onSelect(id, value);
              }
            }}
            id={id}
          />
        </div>
      )}

      {!selectable && !client && (
        <button
          type="button"
          className={styles.editPresentation}
          onClick={() => history.push(`/edit-presentation/${id}`)}
        >
          <EditIcon />
        </button>
      )}
        <div className={styles.presentationGridItem}>
          <a
            href={ client ? `/client/${fromAttendance}/presentations/${id}/concept` : fromAttendance
                ? `/attendances/${fromAttendance}/presentations/${id}`
                : `/presentations/${id}`}
            className={styles.cardLink}
          >
            {getImagesGrid}
          </a>
          <div 
            className={`${styles.info} ${mini && styles.mini}`}
            onMouseDown={() => whenDragging(true)}
            onMouseUp={() => setTimeout(() => whenDragging(false), 2000)}
          >
            <div className={`${styles.name} ${mini && styles.mini}`}>
              {name}
            </div>
            <div className={styles.tags}>
              <div className={`${styles.tag} ${mini && styles.mini}`}>
                {type === 'COL' && translate('collecion', language)}
                {type === 'ID' && translate('id', language)}
                {type === 'LE' && translate('leCollection', language)}
                {type === 'MAT' && 'Matteria'}
              </div>
            </div>
          </div>
        </div>
      
      
    </div>
    </>
  );
}

PrintGridItem.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  attendances: PropTypes.arrayOf(PropTypes.shape),
  clients: PropTypes.arrayOf(PropTypes.shape),
  mini: PropTypes.bool,
  fromAttendance: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  selectable: PropTypes.bool.isRequired,
  client: PropTypes.oneOfType([PropTypes.shape, PropTypes.bool]),
  favorite: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isFavorite: PropTypes.bool
};

PrintGridItem.defaultProps = {
  name: '',
  image: '',
  type: 'COL',
  attendances: [],
  clients: [],
  fromAttendance: false,
  client: false,
  mini: false,
  favorite: false,
  isFavorite: false
};

export default memo(PrintGridItem);
