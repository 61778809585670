import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import translate from '../libs/i18n';
import styles from './PrintListItem.module.scss';
import useOutsideClick from '../libs/useOutsideClick';
import Api from '../libs/Api';
import uploadFile from '../libs/dropboxUpload';

import FileSelector from './FileSelector';
import DownloadIcon from '../assets/icons/Download';
import ListSelector from './ListSelector';
import Loading from './Loading';
import { uploadNewFile } from '../libs/s3Upload';

function PrintListItem({ artFinalists, actions, print }) {
  const language = useSelector(state => state.settings.language);
  const artFinalistSelectorRef = useRef();

  const [psdFinalistArt, setPsdFinalistArt] = useState([]);
  const [savingProgress, setSavingProgress] = useState(false);
  const [selectedArtFinalist, setSelectedArtFinalist] = useState();
  const [artFinalistSelector, setArtFinalistSelector] = useState('closed');
  const [savingOption, setSavingOption] = useState(false);
  const [savedArtFinalist, setSavedArtFinalist] = useState();

  const changeArtFinalistSelector = state => {
    if (state === 'closed') {
      setArtFinalistSelector('opened');
    } else {
      setArtFinalistSelector('closed');
    }
  };

  useOutsideClick(artFinalistSelectorRef, () => {
    changeArtFinalistSelector(artFinalistSelector);
  });

  const calcProgress = progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setSavingProgress(percentCompleted);
  };

  const progressConfig = {
    onUploadProgress(progressEvent) {
      calcProgress(progressEvent);
    }
  };

  useEffect(() => {
    (async function changePrintFiles() {
      if (psdFinalistArt.length) {
        setSavingProgress(true);
        const file = psdFinalistArt[0];
        setPsdFinalistArt([]);
        try {
          await uploadNewFile(
            `${print.code}.${psdFinalistArt[0].name.split('.')[1]}`,
            file,
            `${process.env.REACT_APP_PSD_FINAL_PATH}/${print.code}`,
          );
          await Api.psdFileSuccess({
            print_id: print.id,
            client_id: '',
            usedDraw_id: '',
            user_id: '',
            path: `${process.env.REACT_APP_PSD_FINAL_PATH}/${print.code}.${psdFinalistArt[0].name.split('.')[1]}`,
            file_field: 'psd_final'
          });
          toast(translate('successSendingFile', language), {
            type: 'success'
          });
        } catch {
          toast(translate('failSendingFile', language), {
            type: 'error'
          });
        } finally {
          setSavingProgress(false);
        }
      }
    })();
  }, [language, print, progressConfig, psdFinalistArt]);

  useEffect(() => {
    if (selectedArtFinalist) {
      setSavingOption(true);
      setSavedArtFinalist(null);
    } else {
      setSavingOption(null);
    }
    setArtFinalistSelector('closed');
  }, [selectedArtFinalist]);

  const saveArtFinalist = async () => {
    const formData = new FormData();
    const artFinalist = selectedArtFinalist;

    formData.append('art_finisher', selectedArtFinalist.id);
    try {
      await Api.updatePrint(print.id, formData);
      setSavedArtFinalist(artFinalist);
      setSelectedArtFinalist(null);
    } catch {
      toast(translate('failUpdatingPrint', language), {
        type: 'error'
      });
    }
  };

  return (
    <>
      <li className={styles.printListItem}>
        <Link to={`/print/${print.id}`} className={styles.name}>
          <div className={styles.presentation}>
            {print.image || print.image_url ? (
              <img
                className={styles.image}
                src={print.image || print.image_url}
                alt={print.code}
                onError="this.style.display = 'none'"
              />
            ) : (
              <div className={styles.imageReplace} />
            )}

            <span>{print.name}</span>
          </div>
        </Link>
        <Link className={styles.code} to={`/print/${print.id}`}>
          {print.code}
        </Link>
        <ul className={styles.tagsContainer}>
          {/* {tags.map(tag => (
          <li>{tag}</li>
        ))} */}
        </ul>

        {actions && (
          <div className={styles.actionsContainer}>
            <FileSelector
              files={psdFinalistArt}
              onSelect={setPsdFinalistArt}
              icon
            />

            <a
              href={print.psd_original}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.download}
            >
              <DownloadIcon />
            </a>
            <span className={styles.separator} />
            <div className={styles.artFinalistSelector}>
              <button
                id="collection"
                type="button"
                className={`${styles.defaultSelectInput} ${
                  actions ? styles.noBorder : ''
                }`}
                onClick={() => setArtFinalistSelector('opened')}
              >
                {(savedArtFinalist && savedArtFinalist.name) ||
                  (selectedArtFinalist && selectedArtFinalist.name) ||
                  print.art_finisher_name ||
                  translate('chooseArtFinalist', language)}
              </button>
              {savingOption && (
                <button
                  type="button"
                  className={`${styles.defaultActionButton} ${styles.save}`}
                  onClick={e => {
                    e.stopPropagation();
                    saveArtFinalist();
                  }}
                >
                  {translate('save', language)}
                </button>
              )}

              {artFinalistSelector === 'opened' && (
                <span
                  className={styles.selectorPosition}
                  ref={artFinalistSelectorRef}
                >
                  <ListSelector
                    selectLabel={translate('choose', language)}
                    items={artFinalists}
                    onSelect={setSelectedArtFinalist}
                    value={
                      savedArtFinalist ||
                      selectedArtFinalist ||
                      (print.art_finisher_id
                        ? { id: print.art_finisher_id, name: print.name }
                        : null)
                    }
                    search
                  />
                </span>
              )}
            </div>
          </div>
        )}
      </li>
      {savingProgress && <Loading fixed />}
    </>
  );
}

PrintListItem.propTypes = {
  print: PropTypes.shape.isRequired,
  artFinalists: PropTypes.arrayOf(PropTypes.shape),
  actions: PropTypes.bool
};

PrintListItem.defaultProps = {
  artFinalists: [],
  actions: false
};

export default PrintListItem;
