import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './FormPresentations.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';

import SearchIcon from '../assets/icons/Search';
import PresentationGridItem from './PresentationGridItem';

import Loading from './Loading';

function FormPresentations({ applyPresentations }) {
  const language = useSelector(state => state.settings.language);

  const [selectedPresentations, setSelectedPresentations] = useState([]);
  const [myPresentations, setMyPresentations] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryTimeout, setSearchQueryTimeout] = useState(null);
  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [updatedPage, setUpdatedPage] = useState();
  const [counter, setCounter] = useState();
  const [filteredCounter, setFilteredCounter] = useState();

  useEffect(() => {
    setLoading(true);
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (filter) {
      setInitialLoad(true);
      setLoading(true);
      Api.getMyPresentations(`${filter}&page=${page - 1}`)
        .then(res => {
          setFilteredCounter(res.count);
          setMyPresentations(res.result);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
        })
        .finally(() => {
          setLoading(false);
          setInitialLoad(false);
        });
    } else {
      setInitialLoad(true);
      Api.getMyPresentations(`page=${page - 1}`)
        .then(res => {
          setMyPresentations(res.result);
          setCounter(res.count);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
        })
        .catch()
        .finally(() => {
          setLoading(false);
          setInitialLoad(false);
        });
    }
  }, [filter, page]);

  useEffect(() => {
    if (searchQueryTimeout) {
      clearTimeout(searchQueryTimeout);
    }

    let query = '';
    function concat(filters) {
      if (query) {
        query += `&${filters}`;
      } else {
        query += filters;
      }
    }

    if (searchQuery.length) {
      concat(`name=${searchQuery}`);
    }

    if (query === '?') {
      setFilter('');
    } else if (query !== '?' && searchQuery.length) {
      setSearchQueryTimeout(setTimeout(() => setFilter(query), 1000));
    } else {
      setFilter(query);
    }
  }, [searchQuery]);

  const onSelectPresentation = (presentation, selected) => {
    let currentSelectedPresentations = [...selectedPresentations];
    const containsPresentation = currentSelectedPresentations.find(
      p => p.id === presentation.id
    );
    if (!selected && containsPresentation) {
      currentSelectedPresentations = currentSelectedPresentations.filter(
        p => p.id !== presentation.id
      );
    } else if (selected && !containsPresentation) {
      currentSelectedPresentations = [...selectedPresentations, presentation];
    }
    setSelectedPresentations(currentSelectedPresentations);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  return (
    <div className={styles.presentations}>
      <h2>{translate('selectOneOrMorePresentations', language)}</h2>
      <div className={styles.content}>
        <div className={styles.filters}>
          <div className={styles.left}>
            <div className={styles.inputContainer}>
              <SearchIcon color="#a3b3c7" />
              <input
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className={styles.exclusivityFilter}
                placeholder={translate('searchPresentation', language)}
              />
            </div>
          </div>
          <div className={styles.right}>
            <button
              type="button"
              className={styles.defaultActionButton}
              onClick={() => applyPresentations(selectedPresentations)}
            >
              {translate('apply', language)}
            </button>
          </div>
        </div>
        <div className={styles.presentationsGrid}>
          {myPresentations.map(presentation => (
            <PresentationGridItem
              name={presentation.name}
              image={presentation.print_image_url}
              onSelect={onSelectPresentation}
              id={presentation.id}
              type={presentation.type}
              small
              selectable
              mini
            />
          ))}
        </div>
        {loading && <Loading fixed={!initialLoad} />}
      </div>
      {(counter || filteredCounter) && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
    </div>
  );
}

export default FormPresentations;

FormPresentations.propTypes = {
  applyPresentations: PropTypes.func.isRequired
};
