const initialState = [];

const reducer = (state = initialState, action) => {
  let newPresentations;
  switch (action.type) {
    case '@presentations/SET':
      return [...action.presentations];

    case '@presentations/ADD':
      return [...state, ...action.presentations];

    case '@presentations/REMOVE':
      newPresentations = state.filter(
        presentation =>
          parseInt(presentation.id, 10) !== parseInt(action.id, 10)
      );
      return [...newPresentations];

    case '@presentations/UPDATE':
      newPresentations = state.filter(
        presentation =>
          parseInt(presentation.id, 10) !== parseInt(action.presentation.id, 10)
      );
      return [...newPresentations, action.presentation];

    case '@presentations/CLEAR':
      return [];

    default:
      return state;
  }
};

export default reducer;
