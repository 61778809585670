import React from 'react';
import { useSelector } from 'react-redux';

import translate from '../libs/i18n';
import styles from './CreatePrint.module.scss';

import CreatePrintForm from '../components/CreatePrintForm';

export default function CreatePrint() {
  const language = useSelector(state => state.settings.language);

  return (
    <>
      <div className={styles.createPrint}>
        <div className={styles.header}>
          <h2 className={styles.sectionTitle}>
            {translate('printRegistration', language).toUpperCase()}
          </h2>
        </div>
        <div className={styles.formContainer}>
          <CreatePrintForm />
        </div>
      </div>
    </>
  );
}
