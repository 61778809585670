/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import { ChromePicker as ColorPicker } from 'react-color';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import PropTypes from 'prop-types';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';
import { setTags } from '../store/actions/tags';
import { setCollections } from '../store/actions/collections';
import uploadFile from '../libs/dropboxUpload';

import styles from './CreatePrintForm.module.scss';

import ListSelector from './ListSelector';
import FileSelector from './FileSelector';
import TagsSelectors from './TagsSelectors';
import SectionDivisor from './SectionDivisor';
import UploadProgress from './UploadProgress';
import FormModal from './FormModal';
import CheckInput from './CheckInput';
import CreateCollectionForm from './CreateCollectionForm';
import Loading from './Loading';
import PrintDefaultImage from '../assets/images/newprint.jpg';
import { uploadNewFile } from '../libs/s3Upload';

function CreatePrintForm({ chromatic, twin, original, closeModal, chromaticLength = 0}) {
  const [language, user] = useSelector(state => [
    state.settings.language,
    state.user
  ]);
  const tags = useSelector(state => state.tags);
  const collections = useSelector(state => state.collections);

  const history = useHistory();

  const dispatch = useDispatch();

  const collectionsSelectorRef = useRef();
  const provenienceSelectorRef = useRef();
  const coordinatorsSelectorRef = useRef();
  const designerSelectorRef = useRef();

  const [name, setName] = useState(original.name);
  const [requiredName, setRequiredName] = useState(false);
  const [colorVariantCode, setColorVariantCode] = useState(`${user.code ?? "DGZ"}${chromaticLength + 1}`);
  const [colorVariation, setColorVariation] = useState(false);
  const [widthVariation, setWidthVariation] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [searching, setSearching] = useState(false);
  const [originals, setOriginals] = useState([]);
  const [selectedOriginal, setSelectedOriginal] = useState();
  const [inputState, setInputState] = useState(false);
  const [originalColoringVariation] = useState();
  const [
    requiredOriginalColoringVariation,
    setRequiredOriginalColoringVariation
  ] = useState(false);
  const [finished, setFinished] = useState(false);
  const [cylinder, setCylinder] = useState(false);
  const [digital, setDigital] = useState(false);
  const [collectionsSelector, setCollectionsSelector] = useState('closed');
  const [selectedCollection, setSelectedCollection] = useState();
  const [requiredCollection, setRequiredCollection] = useState(false);
  const [createCollectionModal, setCreateCollectionModal] = useState(false);
  const [selectedProvenience, setSelectedProvenience] = useState();
  const [provenienceSelector, setProvenienceSelector] = useState('closed');
  const [requiredProvenience, setRequiredProvenience] = useState(false);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinatorsSelector, setCoordinatorsSelector] = useState('closed');
  const [selectedCoordinator, setSelectedCoordinator] = useState();
  const [requiredCoordinator, setRequiredCoordinator] = useState(false);
  const [imACoordinator, setImACoordinator] = useState(false);
  const [designers, setDesigners] = useState([]);
  const [designersSelector, setDesignersSelector] = useState('closed');
  const [selectedDesigner, setSelectedDesigner] = useState();
  const [requiredDesigner, setRequiredDesigner] = useState(false);


  const [rapportWidth, setRapportWidth] = useState(original.jpeg_width);
  const [rapportHeight, setRapportHeight] = useState(original.jpeg_height);
  const [requiredRapport, setRequiredRapport] = useState(false);
  const [selectedTags, setSeletedTags] = useState([]);
  const [requiredType, setRequiredType] = useState(false);
  const [jpgPrint, setJpgPrint] = useState([]);
  const [requiredJpgPrint, setRequiredJpgPrint] = useState(false);
  const [psdPrint, setPsdPrint] = useState([]);
  const [requiredPsdPrint, setRequiredPsdPrint] = useState(false);
  const [jpgVisualReferences] = useState([]);
  const [extDesigners, setExtDesigners] = useState([]);
  
  const [jpgUsedDrawings, setJpgUsedDrawings] = useState([]);
  const [savingProgress, setSavingProgress] = useState(false);
  const [savingMessage, setSavingMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState();
  const [clearPreviewsUploadSection, setClearPreviewsUploadSection] = useState(
    false
  );
  const [hasBackground, setHasBackground] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [briefings, setBriefings] = useState([]);
  const [briefingSelector, setBriefingSelector] = useState(false);
  const [selectedBriefings, setSelectedBriefings] = useState([]);

  const CancelToken = useRef(false);
  const source = useRef(false);
  const briefingSelectorRef = useRef();

  const ref = useRef();

  useOutsideClick(briefingSelectorRef, () => {
    if (!briefingSelector) {
      setBriefingSelector(true);
    } else {
      setBriefingSelector(false);
    }
  });

  useEffect(() => {
    if (original) {
      setSelectedCollection({
        id: original.collection_id,
        name: original.collection_name
      });
    }
  }, [original]);

  useEffect(() => {
    setRequiredName(false);
  }, [name]);

  useEffect(() => {
    setRequiredOriginalColoringVariation(false);
  }, [originalColoringVariation]);

  useEffect(() => {
    setCollectionsSelector('closed');
    setRequiredCollection(false);
  }, [selectedCollection]);

  useEffect(() => {
    setRequiredRapport(false);
  }, [rapportWidth]);

  useEffect(() => {
    setRequiredRapport(false);
  }, [rapportHeight]);

  useEffect(() => {
    setProvenienceSelector('closed');
    setRequiredProvenience(false);
  }, [selectedProvenience]);

  useEffect(() => {
    setCoordinatorsSelector('closed');
    setRequiredCoordinator(false);
  }, [selectedCoordinator, imACoordinator]);

  useEffect(() => {
    setDesignersSelector('closed');
    setRequiredDesigner(false);
  }, [selectedDesigner]);

  useEffect(() => {
    setRequiredJpgPrint(false);
  }, [jpgPrint]);

  useEffect(() => {
    setRequiredPsdPrint(false);
  }, [psdPrint]);

  useEffect(() => {
    setRequiredType(false);
  }, [cylinder, digital]);

  useEffect(() => {
    setLoading(true);

    Api.getAllBriefings().then(res => {
      setBriefings(res.map(b => ({ name: b.text, id: b.id })));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    (async function loadCollections() {
      try {
        const getCollections = Api.getCollections();
        const getCoordinators = Api.getCoordinators();
        const getTags = Api.getTags();
        const getDesigners = Api.getDesigners();
        const getExtDesigners = Api.getExtDesigners();

        await axios
          .all([getCollections, getCoordinators, getTags, getDesigners, getExtDesigners])
          .then(
            axios.spread((...responses) => {
              dispatch(setCollections(responses[0]));
              setCoordinators(responses[1]);
              dispatch(setTags(responses[2]));
              setDesigners(responses[3]);
              setExtDesigners(responses[4]);
            })
          );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, [dispatch]);

  const changeCollectionSelector = state => {
    if (state === 'closed') {
      setCollectionsSelector('opened');
    } else {
      setCollectionsSelector('closed');
    }
  };

  const changeProvenienceSelector = state => {
    if (state === 'closed') {
      setProvenienceSelector('opened');
    } else {
      setProvenienceSelector('closed');
    }
  };

  const changeCoordinatorsSelector = state => {
    if (state === 'closed') {
      setCoordinatorsSelector('opened');
    } else {
      setCoordinatorsSelector('closed');
    }
  };

  const changeDesignerSelector = state => {
    if (state === 'closed') {
      setDesignersSelector('opened');
    } else {
      setDesignersSelector('closed');
    }
  };

  useOutsideClick(collectionsSelectorRef, () => {
    changeCollectionSelector(collectionsSelector);
  });

  useOutsideClick(provenienceSelectorRef, () => {
    changeProvenienceSelector(provenienceSelector);
  });

  useOutsideClick(coordinatorsSelectorRef, () => {
    changeCoordinatorsSelector(coordinatorsSelector);
  });

  useOutsideClick(designerSelectorRef, () => {
    changeDesignerSelector(designersSelector);
  });

  const openCreateCollection = () => {
    setCreateCollectionModal(true);
    setCollectionsSelector('closed');
  };

  const createdCollection = async col => {
    setCreateCollectionModal(false);
    setLoading(true);
    const getCollections = await Api.getCollections();
    setCollections(getCollections);
    setSelectedCollection(col);
    setLoading(false);
  };

  const isFormValid = () => {
    let valid = true;

    if (!chromatic && !twin && !selectedCoordinator && !imACoordinator) {
      valid = false;
      setRequiredCoordinator(true);
    }

    if (!chromatic && !twin) {
      if (!origin && !imACoordinator && !selectedCoordinator) {
        valid = false;
        setRequiredCoordinator(true);
      }
    }

    if (!origin && !selectedDesigner) {
      valid = false;
      setRequiredDesigner(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        type: 'warning'
      });
    }

    return valid;
  };

  const calcProgress = progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setSavingProgress(percentCompleted);
  };

  const createPrint = async e => {
    e.preventDefault();
    function srcToFile(src, fileName, mimeType) {
      return fetch(src)
        .then(res => {
          return res.arrayBuffer();
        })
        .then(buf => {
          return new File([buf], fileName, { type: mimeType });
        });
    }
    if (isFormValid()) {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', name || 'Briefing');

      if (selectedCollection) {
        formData.append('collection', selectedCollection.id || '');
      }

      if (jpgPrint && jpgPrint[0]) {
        formData.append('image', jpgPrint[0]);
      } else {
        const imgFile = await srcToFile(
          PrintDefaultImage,
          'newPrint.jpg',
          'image/jpg'
        );
        formData.append('image', imgFile);
      }

      if(psdPrint && psdPrint[0]) {
        formData.append('psd_original', psdPrint[0]);
      }

      if (hasBackground && backgroundColor) {
        formData.append('flat_background', true);
        formData.append('flat_background_color', backgroundColor);
      }

      if (selectedDesigner) {
        formData.append('designer', selectedDesigner.id);
      }

      if (original && original.designer_id) {
        formData.append('designer', original.designer_id);
      }

      if (rapportWidth) {
        formData.append('jpeg_width', rapportWidth);
      }
      if (rapportHeight) {
        formData.append('jpeg_height', rapportHeight);
      }

      if (chromatic) {
        if (original.jpeg_width) {
          formData.append('jpeg_width', original.jpeg_width);
          formData.append('jpeg_height', original.jpeg_height);
        }

        const originTags = tags.filter(
          t =>
            t.category !== 'Cor' && original.tags.find(tag => tag.id === t.id)
        );
        const updatedTags = [...originTags, ...selectedTags];
        formData.append('tag_ids', updatedTags.map(t => t.id).join(','));
      } else if (selectedTags.length) {
        formData.append('tag_ids', selectedTags.map(t => t.id).join(','));
      }
      if (chromatic || twin) {
        formData.append('status', 'SKE');
        if(original.family_id) {
          formData.append('family', original.family_id);
        }
        if (original.owner_id) {
          formData.append('owner', original.owner_id);
        }

        formData.append('is_origin', false);

        if (original.type) {
          formData.append('type', original.type !== '[]' ? original.type : '');
        }
        if (chromatic) {
          formData.append('is_twin', false);
          formData.append('code', original.code);
          formData.append('color_variant', colorVariantCode);
          formData.append('name', name);
        }
        if (twin) {
          formData.append('is_twin', true);
        }
        if (widthVariation) {
          formData.append('is_widhth_variation', true)
        }

        formData.append('coordinator', original.coordinator_id || '');

        formData.append('origin', original.id);

        if (original.provenience) {
          formData.append('provenience', original.provenience || '');
        }
      } else {
        if (finished) {
          formData.append('status', 'APP');
        }

        formData.append('is_twin', false);
        if (cylinder && digital) {
          formData.append('type', 'BOT');
        } else if (cylinder) {
          formData.append('type', 'CYL');
        } else {
          formData.append('type', 'DIG');
        }

        if (imACoordinator) {
          formData.append('coordinator', user.id);
        } else {
          formData.append('coordinator', selectedCoordinator.id || '');
        }
        formData.append(
          'provenience',
          selectedProvenience ? selectedProvenience.value : ''
        );

        if (colorVariation) {
          formData.append('is_origin', false);
          if (selectedOriginal) {
            formData.append('origin', selectedOriginal.id);
          }
          if (colorVariantCode) {
            formData.append('color_variant', colorVariantCode);
          }
        } else {
          formData.append('is_origin', true);
          //Cria a familia
          const { id } = await Api.createFamilyPrint(formData.name);
          formData.append('family', id);
        }
      }

      const progressConfig = {
        onUploadProgress(progressEvent) {
          calcProgress(progressEvent);
        }
      };

      setSavingMessage('Criando estampa');
      Api.createPrint(formData, progressConfig)
        .then(async p => {
          if (jpgVisualReferences.length) {
            setSavingMessage('Salvando as referências visuais');

            jpgVisualReferences.forEach(async (jpg, index) => {
              const briefingFormData = new FormData();
              briefingFormData.append('print', p.id);
              briefingFormData.append('text', 'Visual References');
              briefingFormData.append('is_a_reference', true);
              //briefingFormData.append('images', jpg);

              const { id } = await Api.createPrintBriefing(
                briefingFormData
              );

              try {
                await uploadNewFile(
                  `${jpg.name}`,
                  jpg,
                  `briefing`,
                );

                await Api.psdFileSuccess({
                  usedDraw_id: id,
                  path: `briefing/${jpg.name}`,
                  file_field: 'print_briefing_image'
                });
              
                // if (
                //   !jpgUsedDrawings.length &&
                //   index === jpgVisualReferences.length - 1
                // ) {
                //   briefingFormData.append('last_briefing', true);
                // }
                
              } catch (err) {
                toast(translate('failSavingVisualReferences', language), {
                  type: 'error'
                });
                setSavingProgress(false);
              }
            });
          }

          if (jpgUsedDrawings.length) {
            setSavingMessage('Salvando os desenhos usados');
            //let requestArray = [];

            jpgUsedDrawings.forEach(async (jpg, index) => {
              const visualReferences = new FormData();
              visualReferences.append('print', p.id);
              visualReferences.append('text', 'Used Drawings');
              visualReferences.append('is_a_reference', false);
              //visualReferences.append('images', jpg);

              // if (index === jpgUsedDrawings.length - 1) {
              //   visualReferences.append('last_briefing', true);
              // }
              const res = await Api.createPrintBriefing(visualReferences)
              try {
                 await uploadNewFile(
                    `${jpg.name}`,
                    jpg,
                    `briefing`,
                  )
                  await Api.psdFileSuccess({
                    usedDraw_id: res.id,
                    path: `briefing/${jpg.name}`,
                    file_field: 'print_briefing_image'
                  })
              } catch(err) {
                toast(translate('failSavingUsedDrawings', language), {
                  type: 'error'
                });
                setSavingProgress(false);
              }
            });
              setSavingProgress(false);
            
          }

          if (selectedBriefings && selectedBriefings.length) {
            const briefingList = [];

            selectedBriefings.map(sB => briefingList.push(sB.id));

            await Api.addBriefingPrints({
              print: p.id,
              briefings: briefingList
            })
              .then()
              .catch(() => {
                toast(translate('failSavingBriefing', language), {
                  type: 'error'
                });
                setSavingProgress(false);
              });
          }
          setSavingProgress(false);
          if (chromatic || twin) {
            closeModal();
          } else {
            history.push(`/print/${p.id}`);
          }
        })
        .catch(() => {
          toast(translate('failCreatingPrint', language), {
            type: 'error'
          });

          setSavingProgress(false);
          setLoading(false);
          if(chromatic || twin) {
            closeModal();
          }
          
        });
    }
  };

  useEffect(() => {
    setOriginals([]);
  }, [inputState, searchQuery]);

  useOutsideClick(ref, () => {
    if (inputState === true) {
      setInputState(false);
    }
  });

  const searchPrints = () => {
    clearTimeout(timer);
    if (searchQuery) {
      const newTimer = setTimeout(() => {
        setSearching(true);
        CancelToken.current = axios.CancelToken;
        source.current = CancelToken.current.source();

        Api.search(searchQuery).then(
          res => {
            setOriginals(res.results);
            setSearching(false);
          },
          {
            cancelToken: source.current.token
          }
        );
      }, [1000]);
      setTimer(newTimer);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      searchPrints();
      if (source.current) {
        source.current.cancel();
      }
    } else {
      setSearching(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const onSelectJpgPrint = files => {
    if (files && files[0] && (files[0].size / (1024 * 1024)).toFixed(2) > 8) {
      toast(translate('fileSizeExceded', language), {
        type: 'error'
      });

      setJpgPrint([]);
      setClearPreviewsUploadSection(true);
    } else {
      setJpgPrint(files);
      setClearPreviewsUploadSection(false);
    }
  };

  return (
    <form className={styles.form} onSubmit={createPrint}>
      <div id="printNameSection" className={styles.simpleInputContainer}>
        <input
          className={`${styles.defaultTextInput} ${
            requiredName ? styles.error : ''
          }`}
          placeholder={translate('printName', language)}
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          name="name"
        />
        {requiredName && (
          <span className={styles.errorMessage}>
            {translate('requiredField', language)}
          </span>
        )}
      </div>
      {briefings && briefings.length > 0 && (
        <div className={styles.selectorContainer}>
          <button
            className={`${styles.defaultSelectInput}`}
            type="button"
            onClick={event => {
              event.stopPropagation();
              setBriefingSelector(!briefingSelector);
            }}
          >
            {!selectedBriefings.length
              ? translate('chooseBriefings', language)
              : selectedBriefings[0].name}
          </button>
          {briefingSelector && (
            <span className={styles.selectorPosition} ref={briefingSelectorRef}>
              <ListSelector
                selectLabel={translate('choose', language)}
                items={briefings}
                onSelect={setSelectedBriefings}
                values={selectedBriefings}
                multiple
                fullWidth
                search
                actionText={translate('save', language)}
              />
            </span>
          )}
        </div>
      )}
      {chromatic && (
        <div id="lCode" className={styles.simpleInputContainer}>
          <input
            className={`${styles.defaultTextInput} ${
              requiredName ? styles.error : ''
            }`}
            placeholder={translate('colorVariant', language)}
            value={colorVariantCode}
            onChange={e => setColorVariantCode(e.currentTarget.value)}
            name="colorVariant"
          />
          {requiredName && (
            <span className={styles.errorMessage}>
              {translate('requiredField', language)}
            </span>
          )}
        </div>
      )}
      <div
        id="collecClientSection"
        className={
          !chromatic && !twin
            ? styles.doubleInputContainer
            : styles.simpleInputContainer
        }
      >
        <div className={styles.leftInputGroup}>
          {!chromatic && !twin && (
            <p className={styles.sectionLabel}>
              {translate('collection', language)}
            </p>
          )}
          <div className={styles.selectorContainer}>
            <button
              id="collection"
              type="button"
              className={`${styles.defaultSelectInput} ${
                requiredCollection ? styles.error : ''
              }`}
              data-select={
                selectedCollection && selectedCollection.id ? null : 'noSelect'
              }
              onClick={() => setCollectionsSelector('opened')}
            >
              {selectedCollection && selectedCollection.id
                ? selectedCollection.name
                : translate('chooseCollection', language)}
            </button>
            {requiredCollection && (
              <span className={styles.errorMessage}>
                {translate('requiredField', language)}
              </span>
            )}
            {collectionsSelector === 'opened' && (
              <span ref={collectionsSelectorRef}>
                <ListSelector
                  label={translate('collections', language)}
                  selectLabel={translate('choose', language)}
                  items={collections}
                  onSelect={setSelectedCollection}
                  value={selectedCollection}
                  action={openCreateCollection}
                  actionText={translate('createCollection', language)}
                  search
                />
              </span>
            )}
          </div>
        </div>
        {twin && (
          <div id="rapportSection" className={styles.doubleInputContainer}>
            <div className={styles.leftInputGroup}>
              <p className={styles.sectionLabel}>Rapport</p>
              <div className={`${styles.selectorContainer} ${styles.rapport}`}>
                <div>
                  <span className={styles.rapportLabel}>
                    {translate('rapportWidth', language)}
                  </span>
                  <input
                    className={`${styles.defaultTextInput} ${
                      requiredRapport ? styles.error : ''
                    }`}
                    placeholder="Ex: 64.5"
                    value={rapportWidth}
                    type="number"
                    onChange={e => setRapportWidth(e.currentTarget.value)}
                    name="rapport"
                  />
                  {requiredRapport && (
                    <span className={styles.errorMessage}>
                      {translate('requiredField', language)}
                    </span>
                  )}
                </div>
                <span className={styles.x}>x</span>
                <div>
                  <span className={styles.rapportLabel}>
                    {translate('rapportHeight', language)}
                  </span>
                  <input
                    className={`${styles.defaultTextInput} ${
                      requiredRapport ? styles.error : ''
                    }`}
                    placeholder="Ex: 64.5"
                    value={rapportHeight}
                    type="number"
                    onChange={e => setRapportHeight(e.currentTarget.value)}
                    name="rapport"
                  />

                  {requiredRapport && (
                    <span className={styles.errorMessage}>
                      {translate('requiredField', language)}
                    </span>
                  )}
                </div>
                <span className={styles.cm}>cm</span>
              </div>
            </div>
            <div className={styles.rightInputGroup} />
          </div>
        )}
      </div>
      {!chromatic && !twin && (
        <>
          <div
            id="secondChecks"
            className={`
              ${styles.doubleInputContainer}
            `}
          >
            <div className={styles.leftInputGroup}>
              <CheckInput
                text={translate('original', language)}
                value={!colorVariation}
                onChange={value => setColorVariation(!value)}
              />
            </div>
            <div className={styles.rightInputGroup}>
              <CheckInput
                text={translate('colorVariation', language)}
                value={colorVariation}
                onChange={value => setColorVariation(value)}
              />
            </div>
          </div>
          {colorVariation && (
            <>
              <hr />
              <div id="colorVarSection" className={styles.doubleInputContainer}>
                <div className={styles.leftInputGroup}>
                  <p className={styles.hiddenSectionLabel}>
                    {translate('original', language)}
                  </p>
                  {selectedOriginal ? (
                    <>
                      <ul className={styles.originals}>
                        <li key={selectedOriginal.code}>
                          <img src={selectedOriginal.image} alt="print" />

                          <div className={styles.info}>
                            <div>{selectedOriginal.code}</div>
                            <div>{selectedOriginal.name}</div>
                          </div>
                        </li>
                      </ul>
                      <button
                        onClick={() => setSelectedOriginal()}
                        className={styles.removeOriginal}
                        type="button"
                      >
                        {translate('remove', language)}
                      </button>
                    </>
                  ) : (
                    <>
                      <input
                        className={`${styles.defaultTextInput} ${
                          requiredOriginalColoringVariation ? styles.error : ''
                        }`}
                        placeholder={translate(
                          'findOriginalColoring',
                          language
                        )}
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.currentTarget.value)}
                      />
                      {searching && translate('searching', language)}
                      <ul className={styles.originals}>
                        {searchQuery &&
                          originals.map(res => (
                            <li key={res.code}>
                              <button
                                type="button"
                                onClick={() => setSelectedOriginal(res)}
                              >
                                <img src={res.image} alt="print" />
                              </button>
                              <button
                                type="button"
                                onClick={() => setSelectedOriginal(res)}
                              >
                                <div className={styles.info}>
                                  <div>{res.code}</div>
                                  <div>{res.name}</div>
                                </div>
                              </button>
                            </li>
                          ))}
                      </ul>
                    </>
                  )}
                </div>
                <div
                  className={`${styles.rightInputGroup} ${styles.imgPreviewContainer}`}
                >
                  <p className={styles.hiddenSectionLabel}>
                    {translate('colorVariantCode', language)}
                  </p>
                  <input
                    className={`${styles.defaultTextInput}`}
                    placeholder={translate('colorVariantCode', language)}
                    value={colorVariantCode}
                    onChange={e => setColorVariantCode(e.currentTarget.value)}
                    name="colorVariantCode"
                  />
                </div>

                
              </div>
              <hr />
            </>
          )}

          <div
            id="thirdChecks"
            className={`
              ${styles.doubleInputContainer}
            `}
          >
            <div className={styles.leftInputGroup}>
              <CheckInput
                text={translate('notFinished', language)}
                onChange={value => setFinished(!value)}
                value={!finished}
              />
            </div>
            <div className={styles.rightInputGroup}>
              <CheckInput
                text={translate('finished', language)}
                onChange={setFinished}
                value={finished}
              />
            </div>
          </div>
          <div
            id="thirdChecks"
            className={`
              ${styles.doubleInputContainer}
            `}
          >
            <div className={styles.leftInputGroup}>
              <CheckInput
                text={translate('cylinder', language)}
                onChange={setCylinder}
                value={cylinder}
                error={requiredType}
              />
              <div
                className={`${styles.checkInputContainer} ${
                  requiredType ? styles.error : ''
                }`}
              />
            </div>
          </div>
          <div
            id="fourthChecks"
            className={`
              ${styles.doubleInputContainer}
            `}
          >
            <div className={styles.leftInputGroup}>
              <CheckInput
                text={translate('digital', language)}
                onChange={setDigital}
                value={digital}
                error={requiredType}
              />
            </div>
          </div>
          <div id="rapportSection" className={styles.doubleInputContainer}>
            <div className={styles.leftInputGroup}>
              <p className={styles.sectionLabel}>Rapport</p>
              <div className={`${styles.selectorContainer} ${styles.rapport}`}>
                <div>
                  <span className={styles.rapportLabel}>
                    {translate('rapportWidth', language)}
                  </span>
                  <input
                    className={`${styles.defaultTextInput} ${
                      requiredRapport ? styles.error : ''
                    }`}
                    placeholder="Ex: 64.5"
                    value={rapportWidth}
                    type="number"
                    onChange={e => setRapportWidth(e.currentTarget.value)}
                    name="rapport"
                  />
                  {requiredRapport && (
                    <span className={styles.errorMessage}>
                      {translate('requiredField', language)}
                    </span>
                  )}
                </div>

                <span className={styles.x}>x</span>
                <div>
                  <span className={styles.rapportLabel}>
                    {translate('rapportHeight', language)}
                  </span>
                  <input
                    className={`${styles.defaultTextInput} ${
                      requiredRapport ? styles.error : ''
                    }`}
                    placeholder="Ex: 64.5"
                    value={rapportHeight}
                    type="number"
                    onChange={e => setRapportHeight(e.currentTarget.value)}
                    name="rapport"
                  />
                </div>

                <span className={styles.cm}>cm</span>
                {requiredRapport && (
                  <span className={styles.errorMessage}>
                    {translate('requiredField', language)}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.rightInputGroup} />
          </div>
          <div id="rapportSection" className={styles.doubleInputContainer}>
            <div className={styles.leftInputGroup}>
              <p className={styles.sectionLabel}>
                {translate('chooseProvenience', language)}
              </p>
              <div className={styles.selectorContainer}>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${
                    requiredProvenience ? styles.error : ''
                  }`}
                  onClick={() => setProvenienceSelector('opened')}
                >
                  {selectedProvenience
                    ? selectedProvenience.name
                    : translate('chooseProvenience', language)}
                </button>
                {provenienceSelector === 'opened' && (
                  <span ref={provenienceSelectorRef}>
                    <ListSelector
                      selectLabel={translate('choose', language)}
                      items={[
                        { name: 'La Estampa Home', value: 'HOM', id: 1 },
                        { name: 'La Estampa/ARTS', value: 'ART', id: 2 },
                        { name: 'Internacional', value: 'INT', id: 3 },
                        {
                          name: 'Desenvolvimento Exclusivo',
                          value: 'EXC',
                          id: 4
                        },
                        { name: 'Estampa Cliente', value: 'CLI', id: 5 },
                        { name: 'Le Collection', value: 'LE', id: 6 },
                        { name: 'Matteria', value: 'MAT', id: 7 },
                        { name: 'Studio', value: 'STD', id: 8 },
                      ]}
                      onSelect={setSelectedProvenience}
                      value={selectedProvenience}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className={styles.rightInputGroup} />
          </div>
          <div
            id="fourthChecks"
            className={`
              ${styles.doubleInputContainer}
              ${styles.fourthChecks}
            `}
          >
            <div className={styles.leftInputGroup}>
              <p className={styles.sectionLabel}>
                {translate('coordinator', language)}
              </p>
              <div className={styles.selectorContainer}>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${
                    requiredCoordinator ? styles.error : ''
                  }`}
                  onClick={() => setCoordinatorsSelector('opened')}
                  disabled={imACoordinator}
                >
                  {imACoordinator && user.name}
                  {!imACoordinator &&
                    (selectedCoordinator
                      ? selectedCoordinator.name
                      : translate('chooseCoordinator', language))}
                </button>
                {coordinatorsSelector === 'opened' && (
                  <span ref={coordinatorsSelectorRef}>
                    <ListSelector
                      label={translate('coordinators', language)}
                      selectLabel={translate('choose', language)}
                      items={coordinators}
                      onSelect={setSelectedCoordinator}
                      value={selectedCoordinator}
                      search
                    />
                  </span>
                )}
                {user && user.role && user.role.includes('coordinator') && (
                  <div className={styles.coordinatorOption}>
                    <label
                      htmlFor="coordinator"
                      className={styles.checkmarkcontainer}
                    >
                      {translate('imACoordinator', language)}
                      <input
                        type="checkbox"
                        placeholder={translate('printRegistration', language)}
                        id="coordinator"
                        checked={imACoordinator}
                        onChange={() => setImACoordinator(!imACoordinator)}
                      />
                      <span className={styles.checkmark} />
                    </label>
                  </div>
                )}
                {requiredCoordinator && (
                  <span className={styles.errorMessage}>
                    {translate('requiredField', language)}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.rightInputGroup} />
          </div>
          <div
            id="fourthChecks"
            className={`
              ${styles.doubleInputContainer}
              ${styles.fourthChecks}
            `}
          >
            <div className={styles.leftInputGroup}>
              <p className={styles.sectionLabel}>
                {translate('designer', language)}
              </p>
              <div className={styles.selectorContainer}>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${
                    requiredDesigner ? styles.error : ''
                  }`}
                  onClick={() => setDesignersSelector('opened')}
                >
                  {selectedDesigner
                    ? selectedDesigner.name
                    : translate('chooseDesigner', language)}
                </button>
                {designersSelector === 'opened' && (
                  <span ref={designerSelectorRef}>
                    <ListSelector
                      label={translate('designers', language)}
                      selectLabel={translate('choose', language)}
                      items={[...designers, ...extDesigners]}
                      onSelect={setSelectedDesigner}
                      value={selectedDesigner}
                      search
                    />
                  </span>
                )}
                {requiredDesigner && (
                  <span className={styles.errorMessage}>
                    {translate('requiredField', language)}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.rightInputGroup} />
          </div>
        </>
      )}
      <SectionDivisor title={translate('chooseTheTags', language)} />
      <div id="firstTagsSection" className={styles.simpleInputContainer}>
        <TagsSelectors
          tags={tags}
          selectLabel={translate('add', language)}
          selectTags={setSeletedTags}
          chromatic={chromatic}
          startSelectedTags={selectedTags}
        />
      </div>
      {chromatic || twin ? (
        <>
          <div id="printUploadSection" className={styles.simpleInputContainer}>
            <span className={styles.sectionLabel}>
              <p>{translate('uploadPrint', language)}</p>
              <FileSelector
                files={jpgPrint}
                label={`${translate('jpgPrint', language)} (Max: 8MB)`}
                onSelect={onSelectJpgPrint}
                error={requiredJpgPrint}
                jpg
                fullWidth
                clearPreviews={clearPreviewsUploadSection}
              />
              {requiredJpgPrint && (
                <span className={styles.errorMessage}>
                  {translate('requiredField', language)}
                </span>
              )}
            </span>
          </div>
          <div
            id="refUploadSection"
            className={`${styles.simpleInputContainer} ${
              chromatic || twin ? styles.noSpace : ''
            }`}
          >
            <span className={`${styles.sectionLabel}`}>
              {!chromatic && !twin && <br />}
              <FileSelector
                files={psdPrint}
                label={translate('psdPrint', language)}
                onSelect={setPsdPrint}
                error={requiredPsdPrint}
                fullWidth
              />
              {requiredPsdPrint && (
                <span className={styles.errorMessage}>
                  {translate('requiredField', language)}
                </span>
              )}
            </span>
          </div>
        </>
      ) : (
        <div className={styles.doubleInputContainer}>
          <div className={styles.leftInputGroup}>
            <span className={styles.sectionLabel}>
              {`${translate('uploadPrint', language)}`}
              <FileSelector
                files={jpgPrint}
                label={`${translate('jpgPrint', language)} (Max: 8MB)`}
                onSelect={onSelectJpgPrint}
                error={requiredJpgPrint}
                jpg
                clearPreviews={clearPreviewsUploadSection}
                backgroundColor={backgroundColor}
              />
              {requiredJpgPrint && (
                <span className={styles.errorMessage}>
                  {translate('requiredField', language)}
                </span>
              )}
            </span>
          </div>
          <div className={styles.rightInputGroup}>
            <span className={styles.sectionLabel}>
              <br />
              <FileSelector
                files={psdPrint}
                label={translate('psdPrint', language)}
                onSelect={setPsdPrint}
                error={requiredPsdPrint}
              />
              {requiredPsdPrint && (
                <span className={styles.errorMessage}>
                  {translate('requiredField', language)}
                </span>
              )}
            </span>
          </div>
        </div>
      )}
      <CheckInput
        text={translate('backgroundColor', language)}
        value={hasBackground}
        onChange={value => setHasBackground(value)}
      />
      {twin && (
        <CheckInput
          text={translate('widthVariation', language)}
          value={widthVariation}
          onChange={value => setWidthVariation(value)}
        />
      )}
      <div className={styles.colorPickerContainer}>
        {hasBackground && (
          <ColorPicker
            disableAlpha
            color={backgroundColor}
            onChange={color => setBackgroundColor(color.hex)}
          />
        )}
      </div>

      {!chromatic && !twin && (
        <>
          {/* <div id="printUploadSection" className={styles.simpleInputContainer}>
            <span className={styles.sectionLabel}>
              <div>{translate('uploadVisualRef', language)} </div>
              <FileSelector
                files={jpgVisualReferences}
                label={`${translate('visualRef', language)} (Max: 50MB)`}
                onSelect={setJpgVisualReferences}
                multiple
                jpg
              />
            </span>
          </div> */}
          <div id="refUploadSection" className={styles.simpleInputContainer}>
            <span className={styles.sectionLabel}>
              <div>{translate('uploadUsedDrawings', language)} </div>
              <FileSelector
                files={jpgUsedDrawings}
                label={`${translate('visualRef', language)} (Max: 50MB)`}
                onSelect={setJpgUsedDrawings}
                multiple
                jpg
              />
            </span>
          </div>
        </>
      )}
      <hr />
      <button
        className={`${styles.defaultActionButton} ${styles.save}`}
        type="submit"
      >
        {translate('savePrint', language)}
      </button>
      {createCollectionModal && (
        <FormModal
          onCancel={() => setCreateCollectionModal(false)}
          title={translate('createCollectionTitle', language)}
        >
          <CreateCollectionForm onSuccess={createdCollection} />
        </FormModal>
      )}
      {/* {createClientModal && (
        <FormModal onCancel={() => setCreateClientModal(false)}>
          <CreateClientForm
            onSuccess={createdClient}
            title={translate('createClientTitle', language)}
          />
        </FormModal>
      )} */}
      {savingProgress && (
        <UploadProgress progress={savingProgress} text={savingMessage} />
      )}
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </form>
  );
}

CreatePrintForm.propTypes = {
  chromatic: PropTypes.bool,
  twin: PropTypes.bool,
  original: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  closeModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

CreatePrintForm.defaultProps = {
  chromatic: false,
  twin: false,
  original: false,
  closeModal: false
};

export default CreatePrintForm;
