/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';
import { setClients } from '../store/actions/clients';

import styles from './PresentationForm.module.scss';

import ListSelector from './ListSelector';
import SectionDivisor from './SectionDivisor';
import FormModal from './FormModal';
import FormDolls from './FormDolls';
import FormPrints from './FormPrints';
import FormBriefings from './FormBriefings';
import CreateClientForm from './CreateClientForm';
import Loading from './Loading';

function PresentationForm({ initialData }) {
  const history = useHistory();

  const [language] = useSelector(state => [state.settings.language]);
  const clients = useSelector(state => state.clients);

  const dispatch = useDispatch();

  const clientsSelectorRef = useRef();
  const typeSelectorRef = useRef();
  const clientProfileSelectorRef = useRef();
  const countrySelectorRef = useRef();
  const regionsSelectorRef = useRef();
  const qualitySelectorRef = useRef();

  const [name, setName] = useState('');
  const [requiredName, setRequiredName] = useState(false);

  const [clientsSelector, setClientsSelector] = useState('closed');
  const [createClientModal, setCreateClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [requiredClient] = useState(false);

  const [dollsModal, setDollsModal] = useState(false);
  const [selectedDoll, setSelectedDoll] = useState();

  const [typeSelector, setTypeSelector] = useState('closed');
  const [selectedType, setSelectedType] = useState();
  const [requiredType, setRequiredType] = useState(false);

  const [selectedDate, setSelectedDate] = useState();

  const [clientProfileSelector, setClientProfileSelector] = useState('closed');
  const [selectedClientProfiles, setSelectedClientProfiles] = useState([]);
  const [requiredClientProfiles, setRequiredClientProfiles] = useState(false);

  const [countrySelector, setCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [countries, setCountries] = useState([]);

  const [qualitySelector, setQualitySelector] = useState(false);
  const [qualities, setQualities] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState([]);

  const [regionsSelector, setRegionsSelector] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [regions, setRegions] = useState([]);

  const [locations, setLocations] = useState([]);

  const [briefing, setBriefing] = useState('');

  const [printsModal, setPrintsModal] = useState(false);
  const [briefingsModal, setBriefingsModal] = useState(false);

  const [selectedPrints, setSelectedPrints] = useState([]);
  const [selectedBriefings, setSelectedBriefings] = useState([]);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [clientProfileOptions, setClientProfileOptions] = useState([]);

  const types = useMemo(
    () => [
      { id: 1, name: 'Colleción', value: 'COL' },
      { id: 2, name: 'ID', value: 'ID' },
      { id: 3, name: 'Le Collection', value: 'LE' },
      { id: 4, name: 'Matteria', value: 'MAT' }
    ],
    []
  );

  useEffect(() => {
    if (
      initialData &&
      !loaded &&
      clientProfileOptions.length &&
      countries.length &&
      regions.length &&
      clientProfileOptions.length
    ) {
      setName(initialData.name);
      setSelectedClient(clients.find(c => c.id === initialData.client));
      setSelectedType(types.find(t => t.value === initialData.type));
      setBriefing(initialData.meeting_briefing);

      const initialClientProfiles = [];
      initialData.customer_profile.forEach(cp => {
        const profile = clientProfileOptions.find(opt => opt.id === cp);
        if (profile) {
          initialClientProfiles.push(profile);
        }
      });

      setSelectedClientProfiles(initialClientProfiles);

      let initialCountries = [];
      const initialRegions = [];
      const initialLocations = [];
      let selectableCountries = [...countries];

      initialData.meeting_location.forEach(loc => {
        const currentCountry = countries.find(country => loc === country.value);
        if (currentCountry) {
          initialCountries.push(currentCountry);
        }
        const currentRegion = regions.find(region => loc === region.value);
        if (currentRegion) {
          initialRegions.push(currentRegion);
        }
      });

      if (initialRegions.length) {
        initialCountries = initialCountries.filter(
          country => country.value !== 'BRA'
        );
        initialLocations.push({
          country: { id: 'BRA', name: 'Brasil', value: 'BRA' },
          regions: initialRegions
        });
      }

      initialCountries.forEach(country => {
        initialLocations.push({
          country,
          regions: []
        });

        selectableCountries = countries.filter(c => c.value !== country);
      });
      setLocations(initialLocations);
      setCountries(selectableCountries);
      setSelectedDate(new Date(moment(initialData.meeting_date)));
      setLoaded(true);
    }
  }, [initialData, clientProfileOptions, clients, types, countries, regions]);

  useEffect(() => {
    setRequiredName(false);
  }, [name]);

  useEffect(() => {
    setRequiredClientProfiles(false);
  }, [selectedClientProfiles]);

  useEffect(() => {
    setRequiredType(false);
  }, [selectedType]);

  useEffect(() => {
    setClientsSelector('closed');
    setCountrySelector(false);
  }, [selectedClient, selectedCountry]);

  useEffect(() => {
    Api.getCustomerProfiles().then(profiles => {
      setClientProfileOptions(profiles);
    });
    Api.getCountries().then(res => {
      const formattedCountries = res.map(country => ({
        id: country.id,
        name: country.name,
        value: country.id
      }));
      setCountries(formattedCountries);
    });
    Api.getRegions().then(res => {
      const formattedRegions = res.map(region => ({
        id: region.id,
        name: region.name,
        value: region.id
      }));
      setRegions(formattedRegions);
    });
    Api.getFabrics().then(res => {
      setQualities(res.results)
    })
  }, []);
  useEffect(() => {
    (async function loadClients() {
      try {
        const getClients = await Api.getClients();
        dispatch(setClients(getClients));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, [dispatch]);

  const addLocation = () => {
    const newLocation = {
      country: selectedCountry,
      regions: selectedRegions
    };
    const updatedLocations = [...locations];
    updatedLocations.push(newLocation);

    const updatedCountries = countries.filter(
      country => country.value !== selectedCountry.value
    );

    setLocations(updatedLocations);
    setCountries(updatedCountries);
    setSelectedCountry();
    setSelectedRegions([]);
  };

  const removeLocation = countryId => {
    const updatedCountries = [...countries];
    const removedLocation = locations.find(
      location => location.country.value === countryId
    ).country;

    updatedCountries.push(removedLocation);

    const updatedLocations = locations.filter(
      location => location.country.id !== countryId
    );

    setLocations(updatedLocations);

    const compare = (a, b) => {
      const countryA = a.value.toUpperCase();
      const countryB = b.value.toUpperCase();

      let comparison = 0;
      if (countryA > countryB) {
        comparison = 1;
      } else if (countryA < countryB) {
        comparison = -1;
      }
      return comparison;
    };

    updatedCountries.sort(compare);

    setCountries(updatedCountries);
  };

  const changeClientsSelector = state => {
    if (state === 'closed') {
      setClientsSelector('opened');
    } else {
      setClientsSelector('closed');
    }
  };

  useOutsideClick(clientsSelectorRef, () => {
    changeClientsSelector(clientsSelector);
  });

  useOutsideClick(countrySelectorRef, () => {
    setCountrySelector(!countrySelector);
  });

  useOutsideClick(regionsSelectorRef, () => {
    setRegionsSelector(!regionsSelector);
  });

  const changeQualitySelector = state => {
    if (state === 'closed') {
      setQualitySelector('opened');
    } else {
      setQualitySelector('closed');
    }
  };

  useOutsideClick(qualitySelectorRef, () => {
    changeQualitySelector(qualitySelector);
  });

  const changeTypeSelector = state => {
    if (state === 'closed') {
      setTypeSelector('opened');
    } else {
      setTypeSelector('closed');
    }
  };

  useOutsideClick(typeSelectorRef, () => {
    changeTypeSelector(typeSelector);
  });

  const changeClientProfileSelector = state => {
    if (state === 'closed') {
      setClientProfileSelector('opened');
    } else {
      setClientProfileSelector('closed');
    }
  };

  useOutsideClick(clientProfileSelectorRef, () => {
    changeClientProfileSelector(clientProfileSelector);
  });

  const openCreateClient = () => {
    setCreateClientModal(true);
    setClientsSelector('closed');
  };

  const createdClient = async cli => {
    setCreateClientModal(false);
    setLoading(true);
    const getClients = await Api.getClients();
    dispatch(setClients(getClients));
    setSelectedClient(cli);
    setLoading(false);
  };

  const isFormValid = () => {
    let valid = true;

    if (!name) {
      valid = false;
      setRequiredName(true);
    }

    if (!selectedType) {
      valid = false;
      setRequiredType(true);
    }

    if (!selectedClientProfiles.length) {
      valid = false;
      setRequiredClientProfiles(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        type: 'warning'
      });
    }

    return valid;
  };

  const createPresentation = e => {
    e.preventDefault();
    if (isFormValid()) {
      const clientProfileValues = selectedClientProfiles.map(
        profile => profile.id
      );
      setSaving(true);
      let newCountries = [];
      let newRegions = [];

      const updatedLocations = [...locations];
      if (selectedCountry) {
        const newLocation = {
          country: selectedCountry,
          regions: selectedRegions
        };
        updatedLocations.push(newLocation);
      }

      if (updatedLocations) {
        let formattedCountries = updatedLocations.map(loc => loc.country.value);
        if (formattedCountries.find(country => country === 'BRA')) {
          formattedCountries = formattedCountries.filter(
            country => country !== 'BRA'
          );
          formattedCountries = ['BRA', ...formattedCountries];
        }
        newCountries = formattedCountries;

        const formattedRegions = [];
        updatedLocations.forEach(loc => {
          formattedRegions.push(...loc.regions.map(reg => reg.value));
        });

        newRegions = formattedRegions;
      }

      if (initialData) {
        Api.updatePresentation(initialData.id, {
          name,
          client: selectedClient ? selectedClient.id : null,
          type: selectedType.value,
          meeting_date: moment(selectedDate).format('YYYY-MM-DD'),
          customer_profile: clientProfileValues,
          meeting_briefing: briefing,
          meeting_location: [...newCountries, ...newRegions],
          qualities: selectedQuality.map(quality => quality.id),
          dolly_id: selectedDoll ? selectedDoll.id : initialData.dolly_id
        })
          .then(() => {
            let registerPrints;
            let registerBriefings;
            if (selectedPrints.length) {
              registerPrints = Api.printToMeeting({
                meeting: initialData.id,
                prints: selectedPrints.map(sp => sp.id)
              });
            }
            if (selectedBriefings.length) {
              registerBriefings = Api.briefingToMeeting({
                meeting: initialData.id,
                briefings: selectedBriefings.map(sp => sp.id)
              });
            }
            axios.all([registerPrints, registerBriefings]).then(() => {
              toast(translate('successUpdatingPresentation', language), {
                type: 'success'
              });
              history.push('/presentations');
            });

            history.push(`/presentations/${initialData.id}`);
          })
          .catch(() => {
            toast(translate('failUpdatingPresentation', language), {
              type: 'error'
            });
          })
          .finally(() => {
            setSaving(false);
          });
      } else {
        const newPresentation = {
          name,
          client: selectedClient ? selectedClient.id : null,
          type: selectedType.value,
          meeting_date: moment(selectedDate).format('YYYY-MM-DD'),
          customer_profile: clientProfileValues,
          meeting_briefing: briefing,
          meeting_location: [...newCountries, ...newRegions],
          qualities: selectedQuality.map(quality => quality.id)
        };

        if (selectedDoll) {
          newPresentation.dolly_id = selectedDoll.id;
        }

        Api.createPresentation(newPresentation)
          .then(res => {
            let registerPrints;
            let registerBriefings;
            if (selectedPrints.length) {
              registerPrints = Api.printToMeeting({
                meeting: res.id,
                prints: selectedPrints.map(sp => sp.id)
              });
            }
            if (selectedBriefings.length) {
              registerBriefings = Api.briefingToMeeting({
                meeting: res.id,
                briefings: selectedBriefings.map(sp => sp.id)
              });
            }

            axios
              .all([registerPrints, registerBriefings])
              .then(() => {
                toast(translate('successCreatingPresentation', language), {
                  type: 'success'
                });
                history.push('/presentations');
              })
              .catch(() => {
                toast(translate('failCreatingPresentation', language), {
                  type: 'error'
                });
              });
          })
          .catch(() => {
            toast(translate('failCreatingPresentation', language), {
              type: 'error'
            });
          })
          .finally(() => {
            setSaving(false);
          });
      }
    }
  };

  const selectedProfiles = useMemo(() => {
    if (!selectedClientProfiles.length) {
      return translate('chooseClientProfile', language);
    }
    return selectedClientProfiles.map((profile, index) => {
      if (index === selectedClientProfiles.length - 1) {
        return profile.name;
      }
      return `${profile.name}, `;
    });
  }, [language, selectedClientProfiles]);

  const addSelectedDoll = doll => {
    setSelectedDoll(doll);
    setDollsModal(false);
  };

  const addSelectedPrints = prints => {
    const currentSelectedPrint = [...selectedPrints];

    prints.forEach(p => {
      if (!currentSelectedPrint.find(pres => pres.id === p.id)) {
        currentSelectedPrint.push(p);
      }
    });
    setSelectedPrints(currentSelectedPrint);
    setPrintsModal(false);
  };

  const removePrint = useCallback(
    printId => {
      const updatedPrints = selectedPrints.filter(p => p.id !== printId);
      setSelectedPrints(updatedPrints);
    },
    [selectedPrints]
  );

  const addSelectedBriefings = briefings => {
    const currentSelectedBriefings = [...selectedBriefings];
    briefings.forEach(brf => {
      if (!currentSelectedBriefings.find(b => b.id === brf.id)) {
        currentSelectedBriefings.push(brf);
      }
    });
    setSelectedBriefings(currentSelectedBriefings);
    setBriefingsModal(false);
  };

  const removeBriefing = useCallback(
    briefingId => {
      const updatedBriefings = selectedBriefings.filter(
        b => b.id !== briefingId
      );
      setSelectedBriefings(updatedBriefings);
    },
    [selectedBriefings]
  );

  return (
    <form className={styles.form} onSubmit={createPresentation}>
      <div className={styles.simpleInputContainer}>
        <input
          className={`${styles.defaultTextInput} ${
            requiredName ? styles.error : ''
          }`}
          placeholder={translate('presentationName', language)}
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          name="name"
        />
        {requiredName && (
          <span className={styles.errorMessage}>
            {translate('requiredField', language)}
          </span>
        )}
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <p className={styles.sectionLabel}>{translate('client', language)}</p>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={`${styles.defaultSelectInput}`}
              onClick={() => setClientsSelector('opened')}
            >
              {selectedClient
                ? selectedClient.name
                : translate('chooseClient', language)}
            </button>
            {clientsSelector === 'opened' && (
              <span ref={clientsSelectorRef}>
                <ListSelector
                  label={translate('clients', language)}
                  selectLabel={translate('choose', language)}
                  items={clients}
                  onSelect={setSelectedClient}
                  value={selectedClient}
                  action={openCreateClient}
                  actionText={translate('createClient', language)}
                  search
                />
              </span>
            )}
            {requiredClient && (
              <span className={styles.errorMessage}>
                {translate('requiredClient', language)}
              </span>
            )}
          </div>
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <p className={styles.sectionLabel}>{translate('type', language)}</p>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={`${styles.defaultSelectInput} ${
                requiredType ? styles.error : ''
              }`}
              onClick={() => setTypeSelector('opened')}
            >
              {selectedType
                ? selectedType.name
                : translate('chooseType', language)}
            </button>
            {typeSelector === 'opened' && (
              <span ref={typeSelectorRef}>
                <ListSelector
                  label={translate('type', language)}
                  selectLabel={translate('choose', language)}
                  items={types}
                  onSelect={setSelectedType}
                  value={selectedType}
                />
              </span>
            )}
            {requiredType && (
              <span className={styles.errorMessage}>
                {translate('requiredField', language)}
              </span>
            )}
          </div>
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      {(selectedType?.value == "LE" || selectedType?.value == "MAT") && (
        <div className={styles.doubleInputContainer}>
          <div className={styles.leftInputGroup}>
              <p className={styles.sectionLabel}>{translate('fabric', language)}</p>
              <div className={styles.selectorContainer}>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${
                    requiredType ? styles.error : ''
                  }`}
                  onClick={() => setQualitySelector('opened')}
                >
                  {translate('chooseFabric', language)}
                </button>
                {qualitySelector === 'opened' && (
                  <span ref={qualitySelectorRef}>
                     <ListSelector
                        label={translate('applyFixedFabric', language)}
                        selectLabel={translate('choose', language)}
                        items={qualities}
                        onSelect={setSelectedQuality}
                        values={selectedQuality}
                        multiple
                        action={() => setQualitySelector('closed')}
                        actionText={translate('save', language)}
                        fullWidth
                        search
                      />
                  </span>
                )}
                {selectedQuality && (
                  selectedQuality.map(quality => (
                    <span> {quality.name} </span>
                  ))
                )}
                {requiredType && (
                  <span className={styles.errorMessage}>
                    {translate('requiredField', language)}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.rightInputGroup} />
        </div>
      )}



      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <div className={styles.locations}>
            {locations.map(location => (
              <div key={location.country.value}>
                <div className={styles.location}>
                  <div>{location.country.name}</div>
                  {location.regions.length > 0 && (
                    <div>
                      {location.regions.map(region => `${region.name}; `)}
                    </div>
                  )}
                </div>

                <button
                  type="button"
                  className={styles.delete}
                  onClick={() => removeLocation(location.country.id)}
                >
                  {translate('remove', language)}
                </button>
              </div>
            ))}
          </div>
          <p className={styles.sectionLabel}>
            {translate('country', language)}
          </p>
          <div className={styles.locationSelectors}>
            <div className={styles.selectorContainer}>
              <button
                type="button"
                className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                onClick={() => setCountrySelector(true)}
              >
                {selectedCountry
                  ? selectedCountry.name
                  : translate('chooseCountry', language)}
              </button>

              {countrySelector && (
                <span ref={countrySelectorRef}>
                  <ListSelector
                    items={countries}
                    onSelect={setSelectedCountry}
                    selectLabel={translate('choose', language)}
                    value={selectedCountry}
                    search
                  />
                </span>
              )}
            </div>

            {selectedCountry && selectedCountry.value === 'BRA' && (
              <div className={styles.selectorContainer}>
                <p className={styles.sectionLabel}>
                  {translate('clientRegion', language)}
                </p>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                  onClick={() => setRegionsSelector(true)}
                >
                  {selectedRegions.length
                    ? selectedRegions.map(region => `${region.name}; `)
                    : translate('all', language)}
                </button>

                {regionsSelector && (
                  <span ref={regionsSelectorRef}>
                    <ListSelector
                      items={regions}
                      onSelect={setSelectedRegions}
                      selectLabel={translate('choose', language)}
                      multiple
                      values={selectedRegions}
                      search
                    />
                  </span>
                )}
              </div>
            )}
            {selectedCountry && (
              <button
                type="button"
                className={styles.addLocation}
                onClick={addLocation}
              >
                {translate('addLocation', language)}
              </button>
            )}
          </div>
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.simpleInputContainer}>
        <p className={styles.sectionLabel}>
          {translate('presentationDate', language)}
        </p>

        <div className={styles.presentationDate}>
          <DatePicker
            className={`${styles.dateInput}`}
            value={selectedDate}
            onChange={setSelectedDate}
            format="dd/MM/y"
            clearIcon={null}
            calendarIcon={null}
          />
          <div className={styles.group}>
            <div className={styles.numbers}>
              <span>
                {selectedDate ? moment(selectedDate).format('DD')[0] : 'D'}
              </span>
              <span>
                {selectedDate ? moment(selectedDate).format('DD')[1] : 'D'}
              </span>
            </div>
            <div>{translate('day', language)}</div>
          </div>
          <div className={styles.group}>
            <div className={styles.numbers}>
              <span>
                {selectedDate ? moment(selectedDate).format('MM')[0] : 'M'}
              </span>
              <span>
                {selectedDate ? moment(selectedDate).format('MM')[1] : 'M'}
              </span>
            </div>
            <div>{translate('month', language)}</div>
          </div>
          <div className={styles.group}>
            <div className={styles.numbers}>
              <span>
                {selectedDate
                  ? moment(selectedDate).format('Y')[2]
                  : `${language === 'en' ? 'Y' : 'A'} `}
              </span>
              <span>
                {selectedDate
                  ? moment(selectedDate).format('Y')[3]
                  : `${language === 'en' ? 'Y' : 'A'} `}
              </span>
            </div>
            <div>{translate('year', language)}</div>
          </div>
        </div>
      </div>
      <p className={styles.sectionLabel}>{translate('prints', language)}</p>
      <hr />

      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <button
            className={styles.addPrints}
            type="button"
            onClick={() => setPrintsModal(true)}
          >
            {translate('addPrints', language)}
          </button>
        </div>
        <div className={styles.rightInputGroup}>
          <button
            className={styles.addBriefings}
            type="button"
            onClick={() => setBriefingsModal(true)}
          >
            {translate('addBriefings', language)}
          </button>
        </div>
      </div>
      <ul className={styles.selectedPrints}>
        {selectedPrints.map(sp => (
          <li>
            <div
              className={styles.image}
              style={{
                backgroundColor: sp.flat_background_color || '#4b5768'
              }}
            >
              {sp.image_url && <img src={sp.image_url} alt="print" />}
            </div>
            <div>
              <strong>{sp.code}</strong>
              <button
                type="button"
                className={styles.delete}
                onClick={() => removePrint(sp.id)}
              >
                {translate('remove', language)}
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.briefingsGrid}>
        {selectedBriefings.map(br => (
          <div key={br.id} className={styles.briefingCard}>
            {!!br.cover_url && <img src={br.cover_url} alt={br.text} />}
            <div className={styles.info}>
              {br.new && (
                <span className={styles.new}>{translate('new', language)}</span>
              )}
              <span className={styles.label}>
                {translate('briefing', language)}
              </span>
              <span className={styles.title}>{br.text}</span>
              {/* <span className={styles.client}>{br.client_name}</span> */}
              <button
                type="button"
                className={styles.delete}
                onClick={() => removeBriefing(br.id)}
              >
                {translate('delete', language)}
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <p className={styles.sectionLabel}>
            {translate('clientProfile', language)}
          </p>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={`${styles.defaultSelectInput} ${
                requiredClientProfiles ? styles.error : ''
              }`}
              onClick={() => setClientProfileSelector('opened')}
            >
              {selectedProfiles}
            </button>
            {clientProfileSelector === 'opened' && (
              <span ref={clientProfileSelectorRef}>
                <ListSelector
                  items={clientProfileOptions}
                  onSelect={setSelectedClientProfiles}
                  selectLabel={translate('choose', language)}
                  multiple
                  values={selectedClientProfiles}
                  search
                />
              </span>
            )}
          </div>
          {requiredClientProfiles && (
            <span className={styles.errorMessage}>
              {translate('requiredField', language)}
            </span>
          )}
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={styles.choosePlayCardDoll}
              onClick={() => setDollsModal(true)}
            >
              {translate('playAndCardDoll', language)}
            </button>
          </div>
          {(initialData || selectedDoll) && (
            <div className={styles.dollContainer}>
              <div>
                <img
                  src={
                    selectedDoll
                      ? selectedDoll.image_dolly_url
                      : initialData.dolly
                  }
                  alt="boneca"
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.simpleInputContainer}>
        <p className={styles.sectionLabel}>{translate('briefing', language)}</p>
        <textarea
          rows="10"
          className={`${styles.defaultTextInput} ${styles.textArea}`}
          placeholder={translate('writeHereTheBriefing', language)}
          value={briefing}
          onChange={e => setBriefing(e.currentTarget.value)}
        />
      </div>
      <SectionDivisor />
      <button
        className={`${styles.defaultActionButton} ${styles.save}`}
        type="submit"
        disabled={saving}
      >
        {translate('saveChanges', language)}
      </button>
      {createClientModal && (
        <FormModal onCancel={() => setCreateClientModal(false)}>
          <CreateClientForm
            onSuccess={createdClient}
            title={translate('createClientTitle', language)}
          />
        </FormModal>
      )}
      {dollsModal && selectedClientProfiles.length > 0 && (
        <FormModal onCancel={() => setDollsModal(false)} spaced>
          <FormDolls
            profiles={selectedClientProfiles}
            applyDoll={addSelectedDoll}
          />
        </FormModal>
      )}
      {printsModal && (
        <FormModal onCancel={() => setPrintsModal(false)} spaced>
          <FormPrints applyPrints={addSelectedPrints} />
        </FormModal>
      )}
      {briefingsModal && (
        <FormModal onCancel={() => setBriefingsModal(false)} spaced>
          <FormBriefings
            applyBriefings={addSelectedBriefings}
            currentBriefings={selectedBriefings}
          />
        </FormModal>
      )}
      {(loading || saving) && <Loading />}
    </form>
  );
}

PresentationForm.propTypes = {
  initialData: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape])
};

PresentationForm.defaultProps = {
  initialData: false
};

export default PresentationForm;
