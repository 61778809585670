import React from 'react';
import BriefingForm from '../../components/BriefingForm';
import { useHistory } from 'react-router-dom';

export function CreateBriefing() {
  const history = useHistory();

  return (
        <>
          <BriefingForm close={() => { history.push('/briefings') }}/>
        </>
  )
}