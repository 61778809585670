import React from 'react';
import BriefingForm from '../../components/BriefingForm';
import { useRouteMatch, useHistory } from 'react-router-dom';

export function Briefing() {
    const { params } = useRouteMatch();
    const history = useHistory();
    
    return (
        <BriefingForm id={params.id} close={() => {history.push('/briefings')}} />
    )
}