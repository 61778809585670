import React from 'react';
import styles from './styles.module.scss';
import Arrow from '../../assets/icons/Arrow';

export function ListItems({id, name}) {
    return(
                <div 
                key={id}
                className={styles.listContent}
                >
                    <p>{name}</p>

                    <span className={styles.options}>
                        <span>
                            <Arrow />
                        </span>
                    </span>
                </div>
    )
}