import React, { useState } from 'react';
import styles from './styles.module.scss';
import TrashCanIcon from '../../assets/icons/TrashCan';
import translate from '../../libs/i18n';
import { useHistory } from 'react-router-dom';
import { useBriefing } from '../../hooks/useBriefing';
import ConfirmationModal from '../../components/ConfirmationModal'

export function BriefingCardItem ({id, cover_url, text, isNew, client_name, language, newBriefingCard}) {
    const history = useHistory();
    const [isDeleteModalOpen, setIsDeleteModal] = useState(false);
    const { deleteBriefing } = useBriefing();

    function handleDeleteBriefing(id) {
      deleteBriefing(id);
      setIsDeleteModal(false);
    }

    return (
        <>
          {isDeleteModalOpen && 
            (<ConfirmationModal
              title={translate('deleteBriefing', language)}
              message={translate('deleteBriefingMessage', language)}
              confirmText={translate('deleteConfirmText', language)}
              cancelText={translate('deleteCancelText', language)}
              onConfirm={() => handleDeleteBriefing(id)}
              onCancel={() => setIsDeleteModal(false)}
              deleteWarning
            />)
          }
          <div
              key={id}
              className={styles.briefingCard}
              type="button"
              onClick={() => {newBriefingCard ? history.push(`/create-briefing`) : history.push(`/briefings/${id}`)}}
              style={{backgroundColor: newBriefingCard ? '#3FC4C5' : '#c8cfd8'}}
            >
            {!newBriefingCard && (
               <button
               type="button"
               className={styles.delete}
               onClick={(e) => {
                 e.stopPropagation();
                 setIsDeleteModal(true);
               }}
              >
               <TrashCanIcon color="#fff" />
              </button>  
            )}
              {!!cover_url && (
                <img src={cover_url} alt={text} />
              )}
              <div className={styles.info}>
                {isNew && (
                  <span className={styles.new}>
                    {translate('new', language)}
                  </span>
                )}
                <span className={styles.label}>
                  {translate('briefing', language)}
                </span>
                {newBriefingCard ? <span className={styles.title}>{translate('createBriefing', language)}</span> : <span className={styles.title}>{text}</span>}
                <span className={styles.client}>{client_name}</span>
              </div>
          </div>
        </>
    )
}