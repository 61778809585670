const initialState = {
  initialDate: '',
  finalDate: '',
  isOrigin: false,
  isTwin: false,
  isColorVariant: false,
  reserved: false,
  striked: false,
  isPantone: false,
  approved: false,
  nonApproved: false,
  exclusive: false,
  noExclusive: false,
  digital: false,
  cylinder: false,
  drafts: false,
  colorVariations: false,
  pendingReview: false,
  selectedCollections: [],
  selectedClients: [],
  selectedDesigners: [],
  selectedArtFinalists: [],
  selectedProveniences: [],
  selectedTags: [],
  filter: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case '@libraryFilters/SET_INITIAL_DATE':
      return {
        ...state,
        initialDate: action.initialDate
      };
    case '@libraryFilters/SET_FINAL_DATE':
      return {
        ...state,
        finalDate: action.finalDate
      };
    case '@libraryFilters/SET_ISORIGIN':
      return {
        ...state,
        isOrigin: action.isOrigin
      };
    case '@libraryFilters/SET_ISTWIN':
      return {
        ...state,
        isTwin: action.isTwin
      };
    case '@libraryFilters/SET_ISCOLORVARIANT':
      return {
        ...state,
        isColorVariant: action.isColorVariant
      };
    case '@libraryFilters/SET_RESERVED':
      return {
        ...state,
        reserved: action.reserved
      };
    case '@libraryFilters/SET_STRIKED':
      return {
        ...state,
        striked: action.striked
      };
    case '@libraryFilters/SET_ISPANTONE':
        return {
          ...state,
          isPantone: action.isPantone
        };
    case '@libraryFilters/APPROVED':
      return {
        ...state,
        approved: action.approved
      };
    case '@libraryFilters/NON_APPROVED':
      return {
        ...state,
        nonApproved: action.nonApproved
        };
    case '@libraryFilters/SET_EXCLUSIVE':
      return {
        ...state,
        exclusive: action.exclusive
      };
    case '@libraryFilters/SET_NO_EXCLUSIVE':
      return {
        ...state,
        noExclusive: action.noExclusive
      };
    case '@libraryFilters/SET_DIGITAL':
      return {
        ...state,
        digital: action.digital
      };
    case '@libraryFilters/SET_CYLINDER':
      return {
        ...state,
        cylinder: action.cylinder
      };
    case '@libraryFilters/SET_DRAFTS':
      return {
        ...state,
        drafts: action.drafts
      };
    case '@libraryFilters/SET_COLOR_VARIATIONS':
      return {
        ...state,
        colorVariations: action.colorVariations
      };
    case '@libraryFilters/SET_PENDING_REVIEW':
      return {
        ...state,
        pendingReview: action.pendingReview
      };
    case '@libraryFilters/SET_SELECTED_COLLECTIONS':
      return {
        ...state,
        selectedCollections: action.selectedCollections
      };
    case '@libraryFilters/SET_SELECTED_CLIENTS':
      return {
        ...state,
        selectedClients: action.selectedClients
      };
    case '@libraryFilters/SET_SELECTED_DESIGNERS':
      return {
        ...state,
        selectedDesigners: action.selectedDesigners
      };
    case '@libraryFilters/SET_SELECTED_ART_FINALISTS':
      return {
        ...state,
        selectedArtFinalists: action.selectedArtFinalists
      };
    case '@libraryFilters/SET_SELECTED_PROVENIENCES':
      return {
        ...state,
        selectedProveniences: action.selectedProveniences
      };
    case '@libraryFilters/SET_SELECTED_TAGS':
      return {
        ...state,
        selectedTags: action.selectedTags
      };
    case '@libraryFilters/SET_FILTER':
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

export default reducer;
