import React, { useState, useEffect } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Svg,
  Rect,
} from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import Api from '../../libs/Api';
import QRCode from 'qrcode-generator';
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    color: 'black',
    maxWidth: '388px',
    maxHeight: '208px'
  },
   flex: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  section_column: {
    flexDirection: 'column',
    margin: 8,
    padding: 8,
    fontSize: 12
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 8,
    padding: 8,
    fontSize: 12,
    borderBottom: 1
  },
  client_alias_area: {
    flexDirection: 'row',
    marginLeft: 8,
    marginTop: 8,
    paddingBottom: 12,
    borderBottom: 1
  },
  print_code: {
    flexDirection: 'row',
    marginLeft: 8,
    marginTop: 8,
    paddingBottom: 12,
    borderBottom: 1
  },
  quality: {
    flexDirection: 'row',
    marginLeft: 8,
    marginTop: 8,
    paddingBottom: 12,
    borderBottom: 1
  },
  fabric_composition: {
    flexDirection: 'row',
    marginTop: 6,
    marginLeft: 8
  },
  fabric_width: {
    flexDirection: 'row',
    marginTop: 14,
    marginLeft: 8
  },
  heading: {
    fontWeight: 700,
    marginRight: '10px'
  },
  image: {
    width: '90px',
    height: '25px',
    marginRight: 8,
    marginLeft: 'auto'
  },
  text: {
    marginRight: 8
  },
  viewer: {
    width: window.innerWidth, // the pdf viewer will take up all of the width and height
    height: window.innerHeight
  }
});

// Create Document Component
export function Etiqueta() {
  const { id } = useParams();
  const [initialData, setInitialData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  async function loadData() {
    if (id === undefined) return;
    try {
      setIsLoading(true);
      const response = await Api.getOrder(id, '?order_by=true');
      setInitialData(response);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  const qrCode = (po) => {
    const formattedQrCodeValue = `${po?.print_code?.split(' ')[0]}-${po?.print_code?.split(' ')[1]}-${po?.print_id}`
    const qr = QRCode(0, 'L');
    qr.addData(formattedQrCodeValue);
    qr.make();
    const cellSize = 1;
    const margin = 4;
    const viewBox = `${-margin} ${-margin} ${qr.getModuleCount() * cellSize + margin * 2} ${qr.getModuleCount() * cellSize + margin * 2}`;
    return (
      <Svg width={120} height={120} viewBox={viewBox}>
        {Array.from({ length: qr.getModuleCount() }, (_, y) => {
          return Array.from({ length: qr.getModuleCount() }, (_, x) => {
            if (qr.isDark(x, y)) {
              return <Rect key={`${x}-${y}`} x={x * cellSize} y={y * cellSize} width={cellSize} height={cellSize} fill="black" />;
            } else {
              return null;
            }
          });
        })}
      </Svg>
    )
  }

  useEffect(() => {
    loadData();
  }, [id]);
  return isLoading ? (
    ''
  ) : (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document */}
      <Document>
        {/* render a single page */}
        {initialData?.productorder_set?.map(po => (
          <Page size="A4" style={styles.page}>
            <View style={styles.flex} key={po?.print_code}>
              {qrCode(po)}
              <Image
                src="https://laestampa-files.s3.amazonaws.com/logo-match.jpg"
                style={styles.image}
              />
            </View>
            <View style={styles.section_column} >
              <View style={styles.client_alias_area}>
                <Text>{`${initialData?.client_alias ?? ''}${
                  initialData?.area_alias ? ` - ${initialData?.area_alias}` : ''
                }`}</Text>
              </View>
              <View style={styles.print_code}>
                <Text style={styles.heading}>DESIGN NAME:</Text>
                <Text>{po?.print_code}</Text>
              </View>

              <View style={styles.quality}>
                <Text style={styles.heading}>FABRIC NAME:</Text>
                <Text>{po?.fabric_name}</Text>
              </View>

              <View style={styles.fabric_composition}>
                <Text style={styles.heading}>FABRIC COMPOSITION:</Text>
                <Text>{po?.fabric_mixture}</Text>
              </View>

              <View style={styles.fabric_width}>
                <Text style={styles.heading}>WIDTH:</Text>
                <Text style={styles.text}>{po?.fabric_width} cm</Text>

                <Text style={styles.heading}>WEIGHT:</Text>
                <Text style={styles.text}>{po?.fabric_grammage} g/m²</Text>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    </PDFViewer>
  );
}
