import React from "react";
import Tooltip from "../Tooltip";
import styles from "./styles.module.scss"

interface OrderDeliveryStatus {
    status: string;
    expirated_prints?: Array<string>;
}

export function OrderDeliveryStatus({status, expirated_prints}: OrderDeliveryStatus) {
    return(
        <Tooltip titles={expirated_prints ?? []}>
            
            <div className={`${styles[status]}`} />
        </Tooltip>
    )
}