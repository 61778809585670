import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import styles from './ClientHeader.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';
import {
  registerLoggedClient,
  unregisterLoggedClient
} from '../store/actions/loggedClient';
import { setLanguage } from '../store/actions/settings';

import Cart from './Cart';
import logoMatch from '../assets/images/logo_match_2.svg';
import ShoppingCartIcon from '../assets/icons/ShoppingCart';
import ArrowIcon from '../assets/icons/Arrow';

export const ClientHeader = () => {
  const { id, attendanceId } = useParams();
  const history = useHistory();

  const language = useSelector(state => state.settings.language);
  const client = useSelector(state => state.loggedClient);

  const dispatch = useDispatch();

  const [cart, setCart] = useState(false);

  useEffect(() => {
    if (attendanceId && client && client.meeting_box_id) {
      if (
        attendanceId !== 'undefined' &&
        Number(attendanceId) !== client.meeting_box_id
      ) {
        history.push(`/client/${attendanceId}/login`);
        dispatch(unregisterLoggedClient());
      }
    }
  }, [attendanceId]);

  useEffect(() => {
    if (!client || !client.token) {
      history.push(`/client/${attendanceId}/login`);
    }
  }, []);

  useEffect(() => {
    (async function loadData() {
      if (client.token) {
        const res = await Api.authClient(client.token);
        if (res && res.status === 200) {
          const { data } = res;
          dispatch(
            registerLoggedClient({
              ...data,
              token: client.token
            })
          );
        } else {
          dispatch(unregisterLoggedClient());
          history.push(`/client/${attendanceId}/login`);
        }
      } else {
        dispatch(unregisterLoggedClient());
        history.push(`/client/${attendanceId}/login`);
      }
    })();
  }, []);

  const logout = () => {
    dispatch(unregisterLoggedClient());
    history.push(`/client/logout`);
  };

  return (
    <>
      <header className={styles.clientHeader}>
        <NavLink to={`/client/${id}/`} className={styles.homeLink}>
          <img src={logoMatch} className={styles.logo} alt="blend." />
        </NavLink>
        <div className={styles.userOptions}>
          <div className={styles.languages}>
            <button
              type="button"
              className={`${styles.language} ${styles.pt} ${
                language === 'pt-br' ? styles.active : ''
              }`}
              onClick={() => dispatch(setLanguage('pt-br'))}
            >
              PT
            </button>
            <span className={styles.languageSeparator}>|</span>
            <button
              type="button"
              className={`${styles.language} ${styles.en} ${
                language === 'en' ? styles.active : ''
              }`}
              onClick={() => dispatch(setLanguage('en'))}
            >
              EN
            </button>
          </div>
          <ul className={styles.optionsMenu}>
            <li className={styles.optionsMenu__item}>
              <button
                type="button"
                className={styles.cart}
                onClick={() => setCart(true)}
              >
                <ShoppingCartIcon />
              </button>
            </li>
          </ul>
          <button className={styles.logout} type="button" onClick={logout}>
            {translate('logout', language)}
          </button>
        </div>
      </header>
      <div>
        {attendanceId && (
          <div className={styles.attendances}>
            <div className={styles.backIcon}>
              <ArrowIcon color="#fff" />
            </div>

            <NavLink to={`/client/${attendanceId}`}>
              {translate('attendances', language)}
            </NavLink>
          </div>
        )}
      </div>
      {cart && <Cart close={() => setCart(false)} />}
    </>
  );
};

export default ClientHeader;
