import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Api from '../libs/Api';
import translate from '../libs/i18n';

import Arrow from '../assets/icons/Arrow';
import Search from '../assets/icons/Search';
import MessageIcon from '../assets/icons/Message';
import Loading from './Loading';

import ClientForm from './ClientForm';
import Chat from './Chat';
import styles from './Clients.module.scss';

const Clients = ({ onlyRegister }) => {
  const language = useSelector(state => state.settings.language);
  const myClients = useSelector(state => state.user.my_clients);

  const [clientForm, setClientForm] = useState(onlyRegister);
  const [selectClientLocal, setSelectClientLocal] = useState(false);
  const [isNationalClient, setIsNationalClient] = useState(true);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [currentClientId, setCurrentClientId] = useState();
  const [currentClient, setCurrentClient] = useState();
  const [searchClient, setSearchClient] = useState('');
  const [searchClientTimeout, setSearchClientTimeout] = useState();
  const [isAscendingOrder, setIsAscendingOrder] = useState(true);

  const [counter, setCounter] = useState();
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [updatedPage, setUpdatedPage] = useState();
  const [clientChat, setClientChat] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState([]);

  useEffect(() => {
    if (!clientChat) {
      Api.getUnreadMessages().then(res => {
        setUnreadMessages(res);
      });
    }
  }, [clientChat]);

  useEffect(() => {
    if (currentClientId) {
      setLoading(true);

      Api.getClientById(currentClientId)
        .then(res => setCurrentClient(res))
        .finally(() => setLoading(false));
    }
  }, [currentClientId]);

  useEffect(() => {
    if (searchClientTimeout) {
      clearTimeout(searchClientTimeout);
    }

    setSearchClientTimeout(
      setTimeout(() => {
        setLoading(true);

        Api.searchClient(page - 1, searchClient, isAscendingOrder)
          .then(res => {
            setClients(res.result);
            setCounter(res.count);

            let numberOfPages = parseInt(res.count / 30, 10);

            if (res.count % 30) {
              numberOfPages += 1;
            }

            setLastPage(numberOfPages);
          })
          .finally(() => setLoading(false));
      }, 500)
    );
  }, [page, isAscendingOrder]);

  useEffect(() => {
    if (searchClientTimeout) {
      clearTimeout(searchClientTimeout);
    }

    setSearchClientTimeout(
      setTimeout(() => {
        setPage(1);
        setLoading(true);

        Api.searchClient(page - 1, searchClient, isAscendingOrder)
          .then(res => {
            setClients(res.result);
            setCounter(res.count);

            let numberOfPages = parseInt(res.count / 30, 10);

            if (res.count % 30) {
              numberOfPages += 1;
            }

            setLastPage(numberOfPages);
          })
          .finally(() => setLoading(false));
      }, 500)
    );
  }, [searchClient]);

  const openClientForm = useCallback(() => setClientForm(true), []);

  const closeClientForm = useCallback(() => {
    setLoading(false);
    setClientForm(false);
    setSelectClientLocal(false);
    setCurrentClientId(null);

    Api.searchClient(page - 1, searchClient, isAscendingOrder)
      .then(res => {
        setClients(res.result);
        setCounter(res.count);

        let numberOfPages = parseInt(res.count / 30, 10);

        if (res.count % 30) {
          numberOfPages += 1;
        }

        setLastPage(numberOfPages);
      })
      .finally(() => setLoading(false));
  }, [page, isAscendingOrder]);

  const goTop = () => window.scrollTo(0, 0);

  const openClientLocal = useCallback(() => setSelectClientLocal(true), []);

  const closeClientLocal = useCallback(() => setSelectClientLocal(false), []);

  const handleKeyDown = event => {
    if (event.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  return (
    <>
      <div className={styles.clients}>
        <div className={styles.header}>
          <div className={styles.searchClientWrapper}>
            <input
              className={styles.searchClient}
              type="search"
              value={searchClient}
              placeholder={translate('searchFor', language)}
              onChange={event => setSearchClient(event.currentTarget.value)}
            />
            <span className={styles.searchIcon}>
              <Search color="#4b5768" />
            </span>
          </div>
          <button
            className={styles.defaultRoundedActionButton}
            type="button"
            onClick={() => {
              if (selectClientLocal) {
                closeClientLocal();
              } else {
                openClientLocal();
              }
            }}
          >
            {translate('addNewClient', language)}
          </button>
          <ul
            className={`${styles.selectClientNationality}${
              selectClientLocal ? ` ${styles.selectClientNationalityShow}` : ''
            }`}
          >
            <li>
              <button
                type="button"
                onClick={() => {
                  setIsNationalClient(true);
                  openClientForm();
                }}
              >
                {translate('national', language)}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setIsNationalClient(false);
                  openClientForm();
                }}
              >
                {translate('international', language)}
              </button>
            </li>
          </ul>
        </div>
        <ul />
        <div className={styles.orderByWrapper}>
          <button
            className={styles.orderBy}
            type="button"
            onClick={() => {
              setIsAscendingOrder(!isAscendingOrder);
              setPage(1);
            }}
          >
            {translate('sortByDate', language)}
            <span
              className={isAscendingOrder ? styles.arrowDown : styles.arrowUp}
            />
          </button>
        </div>
        {!clientForm && clients && clients.length > 0 && (
          <section className={styles.clientsList}>
            {clients.map(client => (
              <button
                key={client.id}
                className={styles.clientItem}
                type="button"
                onClick={() => {
                  setCurrentClientId(client.id);
                  setClientForm(true);
                  goTop();
                }}
              >
                {client.name}
                <span className={styles.options}>
                  <span>
                    <Arrow />
                  </span>
                </span>
              </button>
            ))}
          </section>
        )}
        {!clientForm && counter && lastPage && (
          <div className={`${styles.footer}`}>
            <div className={`${styles.pagination}`}>
              <button
                disabled={page === 1}
                type="button"
                onClick={() => {
                  goTop();
                  setPage(page - 1);
                  setUpdatedPage('');
                }}
              >
                <span className={styles.backArrow} />
              </button>
              <input
                type="number"
                placeholder={page}
                value={updatedPage}
                onKeyDown={handleKeyDown}
                onChange={event =>
                  setUpdatedPage(parseInt(event.currentTarget.value, 10))
                }
                onBlur={looseFocus}
              />
              <span>de {lastPage}</span>
              <button
                type="button"
                disabled={page === lastPage}
                onClick={() => {
                  goTop();
                  setPage(page + 1);
                  setUpdatedPage('');
                }}
              >
                <span className={styles.nextArrow} />
              </button>
            </div>
            <button type="button" className={styles.upButton} onClick={goTop}>
              <span className={styles.upArrow} />
            </button>
          </div>
        )}
        {loading && (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        )}
      </div>
      {clientForm && (
        <ClientForm
          closeForm={!onlyRegister ? closeClientForm : null}
          isNational={isNationalClient}
          setIsNational={setIsNationalClient}
          client={!onlyRegister ? currentClient : null}
          onlyRegister={onlyRegister}
        />
      )}
      {clientChat && (
        <Chat client={clientChat} close={() => setClientChat(null)} />
      )}
    </>
  );
};

export default Clients;

Clients.propTypes = {
  onlyRegister: PropTypes.bool
};

Clients.defaultProps = {
  onlyRegister: false
};
