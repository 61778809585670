import React, { useState } from 'react';
import Loading from '../../components/Loading';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import { useSelector } from 'react-redux';
import Api from '../../libs/Api';
import { toast } from 'react-toastify';



export function CreateExternalDesigner() {
    const language = useSelector(state => state.settings.language);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [requiredField, setRequiredField] = useState(false);
    const [loading, setLoading] = useState(false);
    
  
    

    function clearInputValues() {
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    }


    async function handleCreateDesigner(e) {
        e.preventDefault();
        if(name == '') {
            setRequiredField(!requiredField);
            return
        } 
        if(email == '') {
            setRequiredField(!requiredField);
            return
        } 
        if(password == '') {
            setRequiredField(!requiredField);
            return
        }
        if(password != confirmPassword) {
            toast(translate('passwordDoesntMatch', language), {
              type: 'error'
            });
            return;
        }
        try {
            setLoading(true);
            const signupData = {
                name: name,
                email: email,
                client_user: 606,
                password,
                role: ['ext-designer']
            }
            await Api.createClientUser(signupData);
        } catch(err) {
            return toast(translate('errorCreatingExtDesigner', language), {
                type: 'error'
            });
        }

        toast(translate('successCreatingExtDesigner', language), {
                type: 'success'
        });
        setLoading(false)
        clearInputValues();
    }


    return(
        <>
            <div className={styles.header}>
                <h2 className={styles.sectionTitle}>
                <span className={styles.title}>
                    {'REGISTRAR DESIGNER'}
                </span>
                </h2>
            </div>
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <form onSubmit={handleCreateDesigner}>
                        <div className={styles.textInput}>
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Nome do usuario'}
                                value={name}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => {
                                    setName(e.target.value) 
                                    setRequiredField(false)
                                }}
                            />
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Email'}
                                value={email}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => { setEmail(e.target.value) 
                                                   setRequiredField(false)
                                }}
                            />
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Senha'} 
                                type="password"
                                value={password}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setRequiredField(false)
                                }}
                            />
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Confirmar senha'} 
                                type="password"
                                value={confirmPassword}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                    setRequiredField(false)
                                }}
                            />
                        </div>
                        <button 
                        className={styles.defaultActionButton}
                        type="submit"
                        >
                            Registrar Designer
                        </button>
                    </form>

                    {loading &&
                        (
                            <div className={styles.loadingContainer}>
                            <Loading />
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}