import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import copy from 'copy-to-clipboard';

import styles from './LinkCreation.module.scss';
import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';
import Api from '../libs/Api';
import { setClients } from '../store/actions/clients';

import ListSelector from '../components/ListSelector';
import FormModal from '../components/FormModal';
import CreateClientForm from '../components/CreateClientForm';

export default function LinkCreation() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const language = useSelector(state => state.settings.language);
  const clients = useSelector(state => state.clients);

  const clientSelectorRef = useRef();
  const cnpjSelectorRef = useRef();
  const countrySelectorRef = useRef();
  const regionsSelectorRef = useRef();
  const languageSelectorRef = useRef();
  const urlInput = useRef();

  const [selectedClient, setSelectedClient] = useState();
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [clientSelector, setClientSelector] = useState(false);
  const [cnpjSelector, setCnpjSelector] = useState(false);
  const [countrySelector, setCountrySelector] = useState(false);
  const [regionsSelector, setRegionsSelector] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [languageSelector, setLanguageSelector] = useState(false);
  const [createdLink, setCreatedLink] = useState();
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [attendanceClients, setAttendanceClients] = useState([]);

  const [requiredClient, setRequiredClient] = useState(false);
  const [requiredLocation, setRequiredLocation] = useState(false);

  const [createClientModal, setCreateClientModal] = useState(false);

  const [copiedUrl, setCopiedUrl] = useState(false);

  useEffect(() => {
    Api.getCountries().then(res => {
      const formattedCountries = res.map(country => ({
        id: country.id,
        name: country.name,
        value: country.id
      }));
      setCountries(formattedCountries);
    });
    Api.getRegions().then(res => {
      const formattedRegions = res.map(region => ({
        id: region.id,
        name: region.name,
        value: region.id
      }));
      setRegions(formattedRegions);
    });
    Api.getClients().then(res => {
      dispatch(setClients(res));
    });
    Api.getAttendanceClients(id).then(res => {
      setAttendanceClients(res);
    });
  }, [dispatch]);

  const uptateAttendanceClients = () => {
    Api.getAttendanceClients(id).then(res => {
      setAttendanceClients(res);
    });
  };

  useEffect(() => {
    setCountrySelector(false);
    setRequiredLocation(false);
  }, [selectedCountry]);

  useEffect(() => {
    setClientSelector(false);
    setCnpjSelector(false);
    setRequiredClient(false);
  }, [selectedClient]);

  useEffect(() => {
    setLanguageSelector(false);
  }, [selectedLanguage]);

  const changeClientSelector = state => {
    if (!state) {
      setClientSelector(true);
    } else {
      setClientSelector(false);
    }
  };

  useOutsideClick(clientSelectorRef, () => {
    changeClientSelector(regionsSelector);
  });

  const changeLanguageSelector = state => {
    if (!state) {
      setLanguageSelector(true);
    } else {
      setLanguageSelector(false);
    }
  };

  useOutsideClick(languageSelectorRef, () => {
    changeLanguageSelector(languageSelector);
  });

  const changeCnpjSelector = state => {
    if (!state) {
      setCnpjSelector(true);
    } else {
      setCnpjSelector(false);
    }
  };

  useOutsideClick(cnpjSelectorRef, () => {
    changeCnpjSelector(cnpjSelector);
  });

  const changeCountrySelector = state => {
    if (!state) {
      setCountrySelector(true);
    } else {
      setCountrySelector(false);
    }
  };

  useOutsideClick(countrySelectorRef, () => {
    changeCountrySelector(countrySelector);
  });

  const changeRegionsSelector = state => {
    if (!state) {
      setRegionsSelector(true);
    } else {
      setRegionsSelector(false);
    }
  };

  useOutsideClick(regionsSelectorRef, () => {
    changeRegionsSelector(regionsSelector);
  });

  const addLocation = () => {
    const newLocation = {
      country: selectedCountry,
      regions: selectedRegions
    };
    const updatedLocations = [...locations];
    updatedLocations.push(newLocation);

    const updatedCountries = countries.filter(
      country => country.value !== selectedCountry.value
    );

    setLocations(updatedLocations);
    setCountries(updatedCountries);
    setSelectedCountry();
    setSelectedRegions([]);
    return newLocation;
  };

  const removeLocation = countryId => {
    const updatedCountries = [...countries];
    const removedLocation = locations.find(
      location => location.country.value === countryId
    ).country;

    updatedCountries.push(removedLocation);

    const updatedLocations = locations.filter(
      location => location.country.id !== countryId
    );

    setLocations(updatedLocations);

    const compare = (a, b) => {
      const countryA = a.value.toUpperCase();
      const countryB = b.value.toUpperCase();

      let comparison = 0;
      if (countryA > countryB) {
        comparison = 1;
      } else if (countryA < countryB) {
        comparison = -1;
      }
      return comparison;
    };

    updatedCountries.sort(compare);

    setCountries(updatedCountries);
  };

  const isFormValid = () => {
    let valid = true;

    if (!selectedClient) {
      valid = false;
      setRequiredClient(true);
    }

    if (!locations.length && !selectedCountry) {
      valid = false;
      setRequiredLocation(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        position: 'top-right',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: 'warning'
      });
    }

    return valid;
  };

  const generateURL = () => {
    if (isFormValid()) {
      let currentCountries = [];
      let currentRegions = [];
      let currentLocation = [...locations];

      if (selectedCountry) {
        const lastLocation = addLocation();
        currentLocation = [...currentLocation, lastLocation];
      }

      currentLocation.forEach(location => {
        if (location.country.id === 'BRA' && location.regions.length) {
          currentRegions = [
            ...currentRegions,
            ...location.regions.map(region => region.id)
          ];
        } else {
          currentCountries = [...currentCountries, location.country.id];
        }
      });

      const data = {};
      data.meeting_box = id;
      data.meeting_client = selectedClient.id;
      data.language = selectedLanguage ? selectedLanguage.value : 'pt';

      if (currentCountries.length) {
        data.countries = [...currentCountries];
      }

      if (currentRegions.length) {
        data.regions = [...currentRegions];
      }

      Api.createAttendanceLink(data).then(res => {
        setCreatedLink({
          password: res.token
        });
        uptateAttendanceClients();
      });
    }
  };

  const copyUrl = () => {
    copy(urlInput.current.value);
    setCopiedUrl(true);
    setTimeout(() => {
      setCopiedUrl(false);
    }, 5000);
  };

  const openCreateClient = () => {
    setCreateClientModal(true);
    setClientSelector(false);
  };

  const createdClient = async cli => {
    setCreateClientModal(false);
    const getClients = await Api.getClients();
    dispatch(setClients(getClients));
    setSelectedClient(cli);
  };

  return (
    <>
      <div className={styles.createLink}>
        <div className={styles.header}>
          <h2 className={styles.sectionTitle}>
            {translate('createLink', language).toUpperCase()}
          </h2>
        </div>
        <form>
          <h3 className={styles.title}>
            {translate('attendanceUrlPassword', language).toUpperCase()}
          </h3>
          <div className={styles.clientSelectors}>
            <div className={styles.clientInputContainer}>
              <button
                type="button"
                className={`${styles.defaultSelectInput} ${
                  styles.clientInput
                } ${requiredClient ? styles.error : ''}`}
                onClick={() => setClientSelector(true)}
                disabled={createdLink}
              >
                {selectedClient
                  ? selectedClient.name
                  : translate('chooseClient', language)}
              </button>

              {clientSelector && (
                <span ref={clientSelectorRef}>
                  <ListSelector
                    items={clients}
                    onSelect={setSelectedClient}
                    selectLabel={translate('choose', language)}
                    value={selectedClient}
                    search
                    action={openCreateClient}
                    actionText={translate('createClient', language)}
                  />
                </span>
              )}
            </div>
            <div className={styles.clientInputContainer}>
              <button
                type="button"
                className={`${styles.defaultSelectInput} ${styles.clientInput}`}
                onClick={() => setCnpjSelector(true)}
                disabled={createdLink}
              >
                {selectedClient
                  ? selectedClient.cnpj
                  : translate('cnpj', language)}
              </button>

              {cnpjSelector && (
                <span ref={cnpjSelectorRef}>
                  <ListSelector
                    items={clients}
                    onSelect={setSelectedClient}
                    selectLabel={translate('choose', language)}
                    value={selectedClient}
                    search
                    customValue="cnpj"
                    action={openCreateClient}
                    actionText={translate('createClient', language)}
                  />
                </span>
              )}
            </div>
          </div>
          <span>{translate('clientCountry', language)}</span>
          <div className={styles.locations}>
            {locations.map(location => (
              <div key={location.country.value}>
                <div className={styles.location}>
                  <div>{location.country.name}</div>
                  {location.regions.length > 0 && (
                    <div>
                      {location.regions.map(region => `${region.name}; `)}
                    </div>
                  )}
                </div>
                {!createdLink && (
                  <button
                    type="button"
                    className={styles.delete}
                    onClick={() => removeLocation(location.country.id)}
                  >
                    {translate('remove', language)}
                  </button>
                )}
              </div>
            ))}
          </div>
          {!createdLink && (
            <>
              <div className={styles.locationSelectors}>
                <div className={styles.regionInputContainer}>
                  <button
                    type="button"
                    className={`${styles.defaultSelectInput} ${
                      styles.regionInput
                    } ${requiredLocation ? styles.error : ''}`}
                    onClick={() => setCountrySelector(true)}
                    disabled={createdLink}
                  >
                    {selectedCountry
                      ? selectedCountry.name
                      : translate('chooseCountry', language)}
                  </button>

                  {countrySelector && (
                    <span ref={countrySelectorRef}>
                      <ListSelector
                        items={countries}
                        onSelect={setSelectedCountry}
                        selectLabel={translate('choose', language)}
                        value={selectedCountry}
                        search
                      />
                    </span>
                  )}
                </div>

                {selectedCountry && selectedCountry.value === 'BRA' && (
                  <div className={styles.regionInputContainer}>
                    <span className={styles.regionLabel}>
                      {translate('clientRegion', language)}
                    </span>
                    <button
                      type="button"
                      className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                      onClick={() => setRegionsSelector(true)}
                      disabled={createdLink}
                    >
                      {selectedRegions.length
                        ? selectedRegions.map(region => `${region.name}; `)
                        : translate('all', language)}
                    </button>

                    {regionsSelector && (
                      <span ref={regionsSelectorRef}>
                        <ListSelector
                          items={regions}
                          onSelect={setSelectedRegions}
                          selectLabel={translate('choose', language)}
                          multiple
                          values={selectedRegions}
                          search
                        />
                      </span>
                    )}
                  </div>
                )}
              </div>
              {selectedCountry && (
                <button
                  type="button"
                  className={styles.addLocation}
                  onClick={addLocation}
                >
                  {translate('addLocation', language)}
                </button>
              )}
            </>
          )}
          {!createdLink && (
            <div className={styles.languages}>
              <span>{translate('presentationLanguage', language)}</span>
              <div className={styles.languagesInputContainer}>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.languagesInput}`}
                  onClick={() => setLanguageSelector(true)}
                  disabled={createdLink}
                >
                  {selectedLanguage
                    ? selectedLanguage.name
                    : translate('chooseLanguage', language)}
                </button>

                {languageSelector && (
                  <span ref={languageSelectorRef}>
                    <ListSelector
                      items={[
                        {
                          name: translate('portuguese', language),
                          value: 'pt',
                          id: 1
                        },
                        {
                          name: translate('english', language),
                          value: 'en',
                          id: 2
                        }
                      ]}
                      onSelect={setSelectedLanguage}
                      selectLabel={translate('choose', language)}
                      value={selectedLanguage}
                    />
                  </span>
                )}
              </div>
            </div>
          )}
          {createdLink ? (
            <>
              <div className={styles.passwordContainer}>
                {translate('password', language)}:{' '}
                <span className={styles.password}>{createdLink.password}</span>
              </div>
              <div className={styles.expiresContainer}>
                {translate('linkValidTime', language)}:{' '}
                <span className={styles.expires}>
                  {' '}
                  {translate('15days', language)}
                </span>
              </div>
              <input
                className={`${styles.defaultTextInput} ${styles.urlInput}`}
                type="text"
                value={`https://matchth.herokuapp.com/client/${id}`}
                ref={urlInput}
              />
              <button
                type="button"
                className={styles.defaultActionButton}
                onClick={copyUrl}
              >
                {translate('copyURL', language).toUpperCase()}
              </button>
              {copiedUrl && (
                <span className={styles.copied}>
                  {translate('copiedURL', language)}
                </span>
              )}
            </>
          ) : (
            <button
              type="button"
              className={styles.defaultActionButton}
              onClick={generateURL}
            >
              {translate('generateURL', language).toUpperCase()}
            </button>
          )}
          {attendanceClients.length > 0 && (
            <div className={styles.attendanceClients}>
              <div className={styles.title}>
                {translate('attendanceClients', language)}
              </div>
              <ul className={styles.clientList}>
                {attendanceClients.map(cl => (
                  <li className={styles.client}>
                    <img src={cl.client_image} alt={cl.client_name} />
                    <div className={styles.info}>
                      <div className={styles.name}>{cl.client_name}</div>
                      <div className={styles.date}>
                        {translate('receivedAt', language)}{' '}
                        {language === 'en'
                          ? moment(cl.date_creation).format('MM/DD/YYYY')
                          : moment(cl.date_creation).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </form>
      </div>
      {createClientModal && (
        <FormModal onCancel={() => setCreateClientModal(false)}>
          <CreateClientForm
            onSuccess={createdClient}
            title={translate('createClientTitle', language)}
          />
        </FormModal>
      )}
    </>
  );
}

LinkCreation.propTypes = {};
