import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../libs/Api';
import Loading from '../Loading';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import translate from '../../libs/i18n';
import ArrowIcon from '../../assets/icons/Arrow';
import ListSelector from '../ListSelector';



export function AgentForm({ closeForm, agentId }) {
    const [agentName, setAgentName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [vat, setVat] = useState('');
    const [company, setCompany] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countrySelector, setCountrySelector] = useState(false);
    const [countries, setCountries] = useState([]);
    const [companyAddress, setCompanyAddress] = useState('');
    const [profileImage, setProfileImage] = useState([]);
    const [filePreview, setFilePreview] = useState();
    const [loading, setLoading] = useState(false);
    const [profileImageFile, setProfileImageFile] = useState();

    const countrySelectorRef = useRef();


    const [language] = useSelector(state => [state.settings.language]);

    useEffect(() => {
        Api.getCountries().then(res => {
            const formattedCountries = res.map(country => ({
              id: country.id,
              name: country.name,
              value: country.id
            }));
            setCountries(formattedCountries);
          });
    }, []);


    const renderProfileImage = useCallback(() => {
        if (filePreview) {
          return <img src={filePreview.src} alt={filePreview.name} />;
        }
        if (profileImage && profileImage.length) {
          return <img src={profileImage} alt="Client Avatar" />;
        }
        if (agentName && agentName.length) {
          return (
            <span>
              {agentName
                .split(' ')
                .map(word => (word && word[0] ? word[0].toUpperCase() : ''))}
            </span>
          );
        }
        return null;
      }, [filePreview, profileImage, agentName]);

      const onSelectFile = event => {
        const file = event.currentTarget.files[0];
        let newFilePreview;
    
        const loadImagePreview = f => {
          const reader = new FileReader();
          try {
            reader.readAsDataURL(f);
            reader.onloadend = () => {
              newFilePreview = { name: f.name, src: reader.result };
    
              setFilePreview(newFilePreview);
            };
          } catch (err) {
            // console.error(err);
          }
        };
    
        loadImagePreview(file);
        setProfileImageFile(file);
      };

      return (
        <div className={styles.clientFormModal}>
            <header>
                {closeForm ? (
                <button
                    className={styles.closeButton}
                    type="button"
                    onClick={closeForm}
                >
                    <div className={styles.backIcon}>
                    <ArrowIcon color="white" />
                    </div>
                    {translate('clients', language)}
                </button>
                ) : (
                <Link className={styles.closeButton} to="/">
                    <div className={styles.backIcon}>
                    <ArrowIcon color="white" />
                    </div>
                    {translate('clients', language)}
                </Link>
                )}
            </header>
          <div className={styles.clientForm}>
            <form>
              <section>
                <label htmlFor="agentNameField">
                  {translate('agentName', language)}
                  <input
                    id="agentNameField"
                    name="agentNameField"
                    type="text"
                    value={agentName}
                    onChange={event => setAgentName(event.currentTarget.value)}
                  />
                </label>
                <label htmlFor="agentEmailField">
                  {translate('email', language)}
                  <input
                    id="agentEmailField"
                    name="agentEmailField"
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.currentTarget.value)}
                  />
                </label>
                <label htmlFor="agentPhoneField">
                  {translate('phone', language)}
                  <input
                    id="agentPhoneField"
                    name="agentPhoneField"
                    type="text"
                    value={phone}
                    onChange={event => setPhone(event.currentTarget.value)}
                  />
                </label>
                <label htmlFor="vatField">
                  {translate('vat', language)}
                  <input
                    id="vatField"
                    name="vatField"
                    value={vat}
                    onChange={event => setVat(event.currentTarget.value)}
                  />
                </label>
              </section>
              <section>
                <label htmlFor="agentCompanyNameField">
                  {translate('agentCompanyName', language)}
                  <input
                    id="agentCompanyNameField"
                    name="agentCompanyNameField"
                    type="text"
                    value={company}
                    onChange={event => setCompany(event.currentTarget.value)}
                  />
                </label>
                <label htmlFor="countryField">
                  {translate('country', language)}
                </label>
                <div className={styles.selectorFieldContainer}>
                  <div className={styles.locationSelectors}>
                    <button
                      type="button"
                      className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                      onClick={() => setCountrySelector(true)}
                    >
                      {selectedCountry
                        ? selectedCountry.name
                        : translate('chooseCountry', language)}
                    </button>
                    {countrySelector && (
                      <span ref={countrySelectorRef}>
                        <ListSelector
                          items={countries}
                          selectLabel={translate('choose', language)}
                          value={selectedCountry}
                          search
                          onSelect={setSelectedCountry}
                        />
                      </span>
                    )}
                  </div>
                </div>
    
                <label htmlFor="addressField">
                  {translate('address', language)}
                  <textarea
                    id="addressField"
                    name="addressField"
                    value={companyAddress}
                    onChange={event => setCompanyAddress(event.currentTarget.value)}
                  />
                </label>
              </section>
              <section>
                <label htmlFor="imagemPerfilField">
                  {translate('profileImage', language)}
                  <div className={styles.profileImageContainer}>
                    <div className={styles.imageContainer}>
                      <div className={styles.imagePlaceholder}>
                        {renderProfileImage()}
                      </div>
                      <input
                        id="imagemPerfilField"
                        name="imagemPerfilField"
                        type="file"
                        onChange={onSelectFile}
                      />
                    </div>
                    <div>
                      <span className={styles.imageInputInstructions}>
                        {translate('profileImageAdvice', language)}{' '}
                        {agentName
                          .split(' ')
                          .map(word =>
                            word && word[0] ? word[0].toUpperCase() : ''
                          )}
                      </span>
                      <button
                        className={styles.deleteImage}
                        type="button"
                        onClick={() => {
                          setProfileImage(null);
                          setFilePreview(null);
                        }}
                      >
                        {translate('delete', language)}
                      </button>
                    </div>
                  </div>
                </label>
                <hr />
              </section>
            </form>
            <button
              type="button"
              className={styles.defaultActionButton}
              onClick={() => { }}
            >
              {agentId
                ? translate('updateClient', language)
                : translate('registerClient', language)}
            </button>
          </div>
          {loading && (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          )}
        </div>
      );
}