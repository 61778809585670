import React, { useEffect, useRef, useState } from 'react';
import ListSelector from '../../components/ListSelector';
import Loading from '../../components/Loading';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import { useSelector } from 'react-redux';
import Api from '../../libs/Api';
import { toast } from 'react-toastify';
import useOutsideClick from '../../libs/useOutsideClick';
import { useParams } from 'react-router-dom';
import { enIE } from 'date-fns/locale';

export function CreateSupplier() {
    const language = useSelector(state => state.settings.language);
    const { id } = useParams();
    const [requiredField, setRequiredField] = useState(false);

    const [loading, setLoading] = useState(false);

    const countrySelectorRef = useRef();
    const typeSelectorRef = useRef();
    const [countries, setCountries] = useState([]);
    const [countrySelector, setCountrySelector] = useState(false);
    const [typeSelector, setTypeSelector] = useState(false);
    const [name, setName] = useState('');
    const [codSupplier, setCodSupplier] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [vat, setVat] = useState('');
    const [contact, setContact] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedType, setSelectedType] = useState();
    const [notes, setNotes] = useState();
    const types = [
        {
            id: 1,
            name: 'Fabric',
            value: 'F'
        },
        {
            id: 2,
            name: 'Service',
            value: 'S'
        },
        {
            id: 3,
            name: 'Both',
            value: 'B'
        }
    ]
    function clearInputValues() {
        setName('');
        setCodSupplier('');
        setEmail('');
        setType('');
        setCnpj('');
        setVat('');
        setContact('');
        setContactPhone('');
        setContactEmail('');
        setSelectedCountry('');
        setNotes('');
        setSelectedType();
    }
    useOutsideClick(countrySelectorRef, () => {
        setCountrySelector(!countrySelector);
    });
    useOutsideClick(typeSelectorRef, () => {
        setTypeSelector(!typeSelector);
    });
    async function handleCreateSupplier(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name)
        formData.append('COD_SUPPLIER', codSupplier)
        formData.append('email', email)
        formData.append('type', type)
        formData.append('cnpj', cnpj)
        formData.append('vat', vat)
        formData.append('contact', contact)
        formData.append('contact_phone', contactPhone)
        formData.append('contact_email', contactEmail)
        formData.append('country', selectedCountry)
        formData.append('notes', notes)
        try {
            if(id) {
                await Api.editSupplier(id, {
                    name: name,
                    "COD_SUPPLIER": codSupplier,
                    email: email,
                    type: selectedType?.value,
                    cnpj: cnpj,
                    vat: vat,
                    contact: contact,
                    "contact_phone": contactPhone,
                    "contact_email": contactEmail,
                    country: selectedCountry?.value,
                    notes: notes
                })
            }else {
                await Api.createSupplier({
                    name: name,
                    "COD_SUPPLIER": codSupplier,
                    email: email,
                    type: selectedType?.value,
                    cnpj: cnpj,
                    vat: vat,
                    contact: contact,
                    "contact_phone": contactPhone,
                    "contact_email": contactEmail,
                    country: selectedCountry?.value,
                    notes: notes
                })
            }
            return toast(id ? 'Sucesso ao editar o fornecedor!' : 'Sucesso ao criar o fornecedor!', {
                type: 'success'
            });
        } catch (error) {
            console.log(error?.response?.data?.error)
            if(error?.response?.data?.error) {
                return toast(error?.response?.data?.error, {
                    type: 'error'
                });
            } else {
                return toast('Erro ao cadastrar fornecedor, verifique os campos preenchidos', {
                    type: 'error'
                });
            }
        } finally {        
            setLoading(false);
            clearInputValues();
        }
    }
    useEffect(() => {
        if(id) {
            Api.getSpecificSupplier(id)
            .then(res => {
                setName(res?.name);
                setEmail(res?.email);
                setCodSupplier(res?.COD_SUPPLIER);
                setCnpj(res?.cnpj);
                setVat(res?.vat);
                setContactPhone(res?.contact_phone);
                setSelectedType(types?.filter(type => type.value === res?.type)[0]);
                setSelectedCountry(countries?.filter(country => country?.name === res?.country?.charAt(0)?.toUpperCase() + res?.country?.slice(1)?.toLowerCase())[0]);
            })
            .catch(() =>{})
        }
    }, [id, countries])
    
    useEffect(() => {
        setCountrySelector(false);
    }, [selectedCountry]);
    useEffect(() => {
        setTypeSelector(false);
    }, [selectedType]);

    useEffect(() => {
        Api.getCountries().then(res => {
            const formattedCountries = res.map(country => ({
                id: country.id,
                name: country.name,
                value: country.id
            }));
            setCountries(formattedCountries);
        });
    }, []);

    return (
        <>
            <div className={styles.header}>
                <h2 className={styles.sectionTitle}>
                    <span className={styles.title}>
                        {id ? 'EDITAR FORNECEDOR' :'REGISTRAR FORNECEDOR'}
                    </span>
                </h2>
            </div>
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <form onSubmit={handleCreateSupplier}>
                        <div className={styles.textInput}>
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {'Nome do Fornecedor'}
                            </strong>
                            <input
                                placeholder={'Nome do Fornecedor'}
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                             <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {'E-mail'}
                            </strong>
                            <input
                                placeholder={'E-mail'}
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                            <strong 
                                style={{
                                    marginBottom: '1rem'
                                }}
                            >
                                {'Código do Fornecedor'}
                            </strong>
                            <input
                                placeholder={'Código do Fornecedor'}
                                value={codSupplier}
                                onChange={(e) => { setCodSupplier(e.target.value) }}
                            />
                            <strong 
                                style={{
                                    marginBottom: '1rem'
                                }}
                            >
                                {'CNPJ'}
                            </strong>
                            <input
                                placeholder={'CNPJ'}
                                value={cnpj}
                                onChange={(e) => { setCnpj(e.target.value) }}
                            />
                            <strong 
                                style={{
                                    marginBottom: '1rem'
                                }}
                            >
                                {'VAT'}
                            </strong>
                            <input
                                placeholder={'VAT'}
                                value={vat}
                                onChange={(e) => { setVat(e.target.value) }}
                            />
                            <strong 
                                style={{
                                    marginBottom: '1rem'
                                }}
                            >
                                {'Telefone para Contato'}
                            </strong>
                            <input
                                placeholder={'Telefone para Contato'}
                                value={contactPhone}
                                onChange={(e) => { setContactPhone(e.target.value) }}
                            />
                            <div className={styles.divRelative}>
                                <strong 
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >
                                    {translate('chooseCountry', language)}
                                </strong>
                                <button
                                    type="button"
                                    className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                                    onClick={() => {
                                        setCountrySelector(true)
                                        setTypeSelector(false)
                                    }}
                                >
                                    {selectedCountry
                                        ? selectedCountry.name
                                        : translate('chooseCountry', language)}
                                </button>

                                {countrySelector && (
                                    <span ref={countrySelectorRef}>
                                        <ListSelector
                                            items={countries}
                                            onSelect={setSelectedCountry}
                                            selectLabel={translate('choose', language)}
                                            value={selectedCountry}
                                            search
                                        />
                                    </span>
                                )}
                            </div>
                            <div className={styles.divRelative}>
                                <strong 
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >
                                    {translate('chooseType', language)}
                                </strong>
                                <button
                                    type="button"
                                    className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                                    onClick={() => {
                                        setTypeSelector(true)
                                        setCountrySelector(false)
                                    }}
                                >
                                    {selectedType
                                        ? selectedType.name
                                        : translate('chooseType', language)}
                                </button>

                                {typeSelector && (
                                    <span ref={typeSelectorRef}>
                                        <ListSelector
                                            items={types}
                                            onSelect={setSelectedType}
                                            selectLabel={translate('choose', language)}
                                            value={selectedType}
                                        />
                                    </span>
                                )}
                            </div>
                        </div>
                        <button
                            className={styles.defaultActionButton}
                            type="submit"
                        >
                            {id ? 'Editar Fornecedor' : 'Registrar Fornecedor'}
                        </button>
                    </form>
                    {loading &&
                        (
                            <div className={styles.loadingContainer}>
                                <Loading />
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}