import React from 'react';
import PropTypes from 'prop-types';

function Download({ width, height, color }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 19">
      <path
        fill={color}
        fillRule="evenodd"
        d="M14 8.5c.436 0 .796.326.848.748l.007.107v6.8c0 1.362-1.083 2.467-2.44 2.55l-.165.005H1.75c-1.378 0-2.514-1.052-2.6-2.393l-.005-.162v-6.8a.855.855 0 011.703-.107l.007.107v6.8c0 .426.337.785.782.838L1.75 17h10.5c.461 0 .834-.327.888-.74l.007-.105v-6.8c0-.472.383-.855.855-.855zM7 0c.436 0 .796.326.848.748l.007.107-.001 9.967L9.967 8.77a.855.855 0 011.122-.06l.087.078c.301.31.32.79.06 1.122l-.078.087-3.5 3.4a.855.855 0 01-1.104.073l-.087-.073-3.5-3.4A.855.855 0 014.07 8.695l.088.075 1.986 1.929.001-9.844C6.145.383 6.528 0 7 0z"
      />
    </svg>
  );
}

Download.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Download.defaultProps = {
  width: 17,
  height: 19,
  color: '#9BA7B7'
};

export default React.memo(Download);
