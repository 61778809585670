import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import styles from './FormDolls.module.scss';

import SearchIcon from '../assets/icons/Search';
import CheckInput from './CheckInput';
import Loading from './Loading';

function FormDolls({ applyDoll, profiles }) {
  const language = useSelector(state => state.settings.language);

  const [searchQuery, setSearchQuery] = useState('');
  const [dolls, setDolls] = useState([]);
  const [selectedDoll, setSelectedDoll] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.getMiniDollsByProfile(profiles.map(p => p.id).toString())
      .then(res => {
        setDolls(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [profiles]);

  const filteredDolls = useMemo(() => {
    if (searchQuery) {
      return dolls.filter(doll =>
        doll.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return dolls;
  }, [searchQuery, dolls]);

  const renderedGrid = () => {
    const dollsArray = filteredDolls;
    const renderDollGridItem = doll => (
      <div
        className={`${styles.dollCard} ${
          doll.id === selectedDoll.id ? styles.selected : ''
        }`}
        key={doll.id}
      >
        <CheckInput
          value={doll.id === selectedDoll.id}
          onChange={value => {
            if (value) {
              setSelectedDoll(doll);
            }
          }}
        />
        <div className={styles.imageContainer}>
          <img src={doll.image_dolly_url} alt={doll.description} />
        </div>
      </div>
    );
    return (
      <>
        {dollsArray && dollsArray.length > 0 ? (
          <div className={styles.dollsGrid}>
            {dollsArray.map(doll => renderDollGridItem(doll))}
          </div>
        ) : (
          <div className={styles.emptyPrintList}>
            {translate('dollNotFound', language)}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.formDolls}>
      <div className={styles.header}>
        <h2>{translate('playAndCardDoll', language)}</h2>
        <div>{selectedDoll && selectedDoll.description}</div>
      </div>

      <div className={styles.filters}>
        <div className={styles.left}>
          <div className={styles.inputContainer}>
            <SearchIcon color="#a3b3c7" />
            <input
              type="search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.currentTarget.value)}
              className={styles.exclusivityFilter}
              placeholder={translate('searchFor', language)}
            />
          </div>
        </div>
        <div className={styles.right}>
          <button
            type="button"
            className={styles.defaultActionButton}
            onClick={() => applyDoll(selectedDoll)}
          >
            {translate('apply', language)}
          </button>
        </div>
      </div>
      {renderedGrid()}
      {loading && <Loading fixed />}
    </div>
  );
}

export default FormDolls;

FormDolls.propTypes = {
  applyDoll: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape).isRequired
};
