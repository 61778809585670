import React, { useState, useEffect, useRef } from 'react';
// import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import { useHistory } from "react-router-dom";
import Api from '../../libs/Api';
import SearchIcon from '../../assets/icons/Search';
import { FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';

function Suppliers() {
    const loadingMoreOrders = false;
    const history = useHistory();
    const language = useSelector(state => state.settings.language);
    const [suppliers, setSuppliers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredSuppliers = suppliers?.filter(quality => quality?.name?.toLocaleLowerCase()?.includes(searchQuery?.toLocaleLowerCase()))
    const getInitialData = async () => {
        try {
            const response = await Api.getSupplier("");
            setSuppliers(response.results);
        } catch {

        }
    }

    const sendTokenEmail = async(id) => {
        try {
            await Api.sendEmailSupplierToken(id);
            toast(translate('successEmailToken', language), {
                type: 'success'
            });
        } catch {
            toast(translate('erroEmailToken', language), {
                type: 'error'
            });
        }
    }
    useEffect(() => {
        getInitialData()
    }, [])
    return (
        <>
        <div className={styles.titleSection}>
            <div className={styles.flexContainer}>
                <h1>{translate("suppliers", language)}</h1>
                <div className={styles.searchInputContainer}>
                    <SearchIcon color="#a3b3c7" />
                    <input
                        type="search"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.currentTarget.value)}
                        className={`${styles.searchInput} ${styles.search}`}
                        placeholder={translate('search', language)}
                    />
                </div>
            </div>
            <button 
                className={styles.defaultRoundedActionButton}
                onClick={() => history.push('/register-supplier')}
            >
                {translate("newSupplier", language)}
            </button>
        </div>
        <div className={styles.myOrders}>
            {suppliers && suppliers?.length ? (
            filteredSuppliers.map((supplier, index) => {
                const qualityKey = `supplier-${supplier.id}-${index}`;
                return (
                    <div key={qualityKey} className={styles.orderItem}>
                        <div className={styles.clientName}>
                            {supplier.name}
                        </div>
                        <div className={styles.actionSection}>
                            <button
                                type="button"
                                className={styles.seeDetails}
                                onClick={() => history.push(`supplier/${supplier.id}`)}
                            >
                                <span>{translate('seeDetails', language)}</span>
                            </button>
                            {supplier?.type === "S" || supplier?.type === "B" && (
                                <button
                                    className={styles.email}
                                    onClick={() => {sendTokenEmail(supplier?.id)}}
                                >
                                    <FiMail />
                                </button>
                            )}

                        </div>
                    </div>
                );
            })
            ) : (
            <>
                {!loadingMoreOrders && (
                <div className={styles.noOrders}>
                    {translate('noOrders', language)}
                </div>
                )}
            </>
            )}
        </div>
        {loadingMoreOrders && <Loading line />}
        </>
    );
}

export default Suppliers;