import React, { useState } from 'react';

import Clients from '../components/Clients';

import styles from './CreatePrint.module.scss';

export default function RegisterClient() {
  const [isNationalClient, setIsNationalClient] = useState(true);

  return (
    <>
      <div className={styles.createPrint}>
        <Clients
          isNational={isNationalClient}
          setIsNational={setIsNationalClient}
          onlyRegister
        />
      </div>
    </>
  );
}
