export const pt = {
  // Login
  goodMorning: 'Bom Dia!',
  goodAfternoon: 'Boa Tarde!',
  goodNight: 'Boa Noite!',
  email: 'E-mail',
  emailPlaceholder: 'voce@email.com',
  password: 'Senha',
  confirmPassword: 'Confirmar Senha',
  passwordDoesntMatch: 'As senhas não são iguais',
  passwordPlaceholder: 'Senha de 8 caracteres ou mais',
  forgotPassword: 'Esqueci senha',
  enter: 'Entrar',
  invalidEmail: 'Email inválido',
  passwordRequired: 'É necessário entrar com a senha',
  wrongCredentials: 'Credenciais inválidas',
  unauthorizedUser: 'Usuário não autorizado',
  resetPassword: 'Recuperar Senha',
  errorCreateClientLogin: 'Error E-mail ou senha invalida! por favor tentar novamente.',
  succesCreateClientLogin: 'O Usuario do cliente foi criado com sucesso.',
  //Attendance
  savePosition: 'Salvar posição', 
  successSavePosition: 'As posições foram salva com sucesso',
  errorSavePosition: 'Falha ao salvar as posições',
  changePositionText: 'Mudar posições',
  //Create Fabric
  grammage: "Gramatura",
  fabricName: "Nome do tecido",
  suggPrice: "Preço de Compra",
  fabricWidth: "Largura do Tecido",
  fabricConstruction: "Construção",
  fabricCertification: "Certificação",
  fabricDescription: "Descrição do Tecido",
  fabricSupplier: "Fornecedores",
  selectSupplier: "Selecione o fornecedor",
  selectFabric: "Selecione o tecido",
  service: "Serviço",
  selectService: "Selecione o serviço",
  selectType: "Selecione o tipo",
  supplierFabricName: "Nome do tecido no fornecedor",
  supplierFabric: "Tecido no fornecedor",
  supplierFabricPrice: "Preço do tecido no fornecedor",
  uploadImageFabric: "Upload da imagem do tecido",
  uploadVideoFabric: "Upload do video do tecido",
  successEmailToken: "E-mail com o token de acesso enviado com sucesso!",
  erroEmailToken: "Erro ao enviar o email!",
  sampleCode: "Código amostra",
  productionCode: "Código produto acabado",
  saveFabric: "Registrar Tecido",
  editFabric: "Editar Tecido",
  errorCreatingSupplier: 'Error ao criar o fornecedor',


  // Main Navigation Menu
  myWork: 'Meu Trabalho',
  library: 'Acervo',
  presentations: 'Apresentações',
  searchPlaceholder: 'O que você está procurando?',
  pattern: 'Estampa',
  presentation: 'Apresentação',
  client: 'Cliente',
  registerPrint: 'Cadastrar estampa',
  printRegistration: 'Cadastramento de estampa',
  logout: 'Deslogar',
  favorites: 'Favoritos',
  favoritePrints: 'Estampas favoritas',
  favoritePresentations: 'Apresentaçoes favoritas',
  favoriteAttendances: 'Atendimentos favoritos',
  otherIdeas: 'Outras ideias',
  myRegister: 'Meu Cadastro',
  myProfile: 'Meu Perfil',
  name: 'Nome',
  function: 'Função',
  phone: 'Telefone',
  updateUser: 'Atualizar cadastro',
  coordinator: 'Coordenador de criação',
  'coord-style': 'Coordernador de estilo',
  'coord-seller': 'Gerente comercial',
  designer: 'Criação',
  style: 'Estilo',
  seller: 'Comercial',
  'art-finisher': 'Arte-Finalista',
  admin: 'Administrador',
  personalCodeWarning: 'O código de variação só pode ter 4 digitos.',
  successUpdatingUser: 'Usuário atualizado com sucesso',
  failUpdatingUser: 'Não foi possível atualizar o usuário',
  externalDesigner: 'Designer Externo',
  userClient: 'Usuário - Cliente',
  supplierItens: 'Itens por fornecedores',

  // Create Print
  printName: 'Nome da Estampa',
  briefing: 'Briefing',
  collection: 'Coleção',
  leCollection: 'Le Collection',
  chooseDesigner: 'Escolha o Designer',
  colorVariantCode: 'Código da variante de cor',
  collections: 'Coleções',
  chooseClient: 'Escolha o cliente',
  chooseCollection: 'Escolha a coleção',
  clients: 'Clientes',
  choose: 'Escolher',
  isNotRequired: 'Não é obrigatório',
  makePrintExclusive: 'Atenção: esse campo irá exclusivar a estampa',
  uniquePrint: 'Estampa única',
  original: 'Original',
  findOriginalColoring: 'Procure a coloração original',
  finished: 'Aprovada pelo coordenador de criação',
  digital: 'Digital',
  cylinder: 'Cilindro',
  chromaticVariation: 'Variação cromática',
  colorVariation: 'Variante de cor',
  notFinished: 'Não aprovada pelo coordenador de criação',
  chooseProvenience: 'Procedência',
  chooseExclusivityVar: 'Variante de exclusividade',
  coordinators: 'Coordenadores',
  chooseCoordinator: 'Escolha o coordenador',
  imACoordinator: 'Eu sou um coordenador',
  chooseTheTags: 'Escolha os TAGS',
  adjective: 'Adjetivo',
  theme: 'Tema',
  size: 'Tamanho',
  finalistArt: 'Arte finalista',
  techniques: 'Técnicas',
  colors: 'Cores',
  construction: 'Contrução',
  clientProfile: 'Perfil do cliente',
  source: 'Origem',
  origin: 'Procedência',
  uploadPrint: 'Upload da estampa',
  jpgPrint: 'Estampa em JPG',
  psdPrint: 'Estampa em PSD',
  uploadVisualRef: 'Upload de referências visuais',
  jpgVisualRef: 'Referências visuais JPG',
  uploadUsedDrawings: 'Upload de desenhos usados',
  savePrint: 'Salvar estampa',
  add: 'Adicionar',
  requiredField: 'Campo obrigatório',
  creatingPrint: 'Criando estampa',
  savingReferences: 'Salvando Referências',
  failCreatingPrint: 'Não foi possível cadastrar a estampa',
  failSavingVisualReferences: 'Não foi possível salvar as referências visuais',
  failSavingUsedDrawings: 'Não foi possível salvar os desenhos usados',
  failSavingBriefing: 'Não foi possível salvar o briefing',
  createCollection: 'Criar coleção',
  createClient: 'Cadastrar cliente',
  mainCollections: 'Principais coleções',
  requiredFields: 'Preencha todos os dados obrigatótios',
  rapportWidth: 'Largura',
  rapportHeight: 'Altura',
  visualRef: 'Referências Visuais',
  backgroundColor: 'Cor de fundo',
  widthVariation: 'Variação de Tamanho',
  messageWidthVariation: 'Sim, essa Estampa é uma Variação de Tamanho',
  //UTILS
  goBack: 'VOLTAR',
  // UPDATE PRINT
  register: 'Cadastro',
  comments: 'Comentários',
  lCode: 'Código L',
  print_color_variant: 'Variante de cor',
  requiredFinalistArt: 'Falta ser arte finalizada',
  archives: 'Arquivos',
  jpg: 'JPG',
  lastUpdate: 'Última modificação',
  psdDesigner: 'PSD Designer',
  psdFinalistArt: 'PSD Arte Finalista',
  psdFlirted: 'PSD Arte Finalista - Fletado',
  datasheet: 'Ficha Técnica',
  designers: 'Designers',
  artFinalists: 'Arte Finalistas',
  type: 'Tipo',
  tags: 'Tags',
  digitalCylinder: 'Digital/Cilindro',
  rapport: 'Dimensão do rapport',
  fabrics: 'Tecidos',
  qualities: 'Qualidades',
  newQuality: 'Nova qualidade',
  newSupplier: 'Novo fornecedor',
  errorCreatingFabric: 'Erro ao criar uma nova qualidade',
  errorCreatingExtDesigner: 'Erro ao criar um novo designer externo',
  errorCreatingClientUser: 'Erro ao criar um novo usuário',
  successCreatingClientUser: 'Sucesso ao criar um novo usuário',
  successCreatingFabric: 'Sucesso ao criar uma nova qualidade',
  successCreatingExtDesigner: 'Sucesso ao criar um novo designer externo',
  currentReservation: 'Reserva atual',
  history: 'Histórico',
  salesHistory: 'Histórico de Vendas',
  chromaticVariations: 'Variações Cromáticas',
  delete: 'Deletar',
  deleteArchive: 'Deletar arquivo',
  addImages: 'Adicionar imagens',
  addOtherTags: 'Adicione outros tags',
  briefingReferences: 'Referências do Briefing',
  usedDrawings: 'Desenhos Usados',
  addChromaticVariation: 'Adicionar variação',
  saveChange: 'Salvar alteração',
  failUpdatingPrint: 'Não foi possível atualizar a estampa',
  failUpdatingCollection: 'Não foi possível alterar a coleção',
  failDeletingImage: 'Não foi possível deletar a imagem',
  failUpdatingTags: 'Não foi possível atualiza as tags',
  chromaticRegister: 'Cadastrar Variação Cromática',
  failDeletingPrint: 'Não foi possível deletar a estampa',
  successDeletingPrint: 'Estampa deletada com sucesso',
  addTwin: 'Adicionar Gêmea',
  twinRegister: 'Cadastrar Gêmea',
  deleteImage: 'Deletar imagem',
  deleteImageMessage: 'Você tem certeza que deseja deletar esta image?',
  deleteConfirmText: 'Sim',
  deleteCancelText: 'Não',
  deletePrint: 'Deletar estampa',
  deletePrintMessage: 'Você tem certeza que deseja deletar a estampa',
  provenience: 'Procedência',
  exclusivityVar: 'Variante de exclusividade',
  cancel: 'Cancelar',
  color: 'Cor',
  drawing: 'Desenho',
  failUpdatingPrintName: 'Não foi possível atualizar o nome da estampa',
  failUpdatingDesigner: 'Não foi possível atualizar o designer',
  failUpdatingCoordinator: 'Não foi possível atualizar o coordenador',
  failUpdatingArtFinalist: 'Não foi possível atualizar o arte finalista',
  failUpdatingType: 'Não foi possível atualizar o tipo',
  failUpdatingRapport: 'Não foi possível atualizar o rapport',
  failUpdatingProvenience: 'Não foi possível atualizar a procedência',
  failUpdatingExclusivityVar:
    'Não foi possível atualizar a variavel de exclusividade',
  failUpdatingBriefing: 'Não foi possível atualiar o Briefing',
  successSendingFile: 'Arquivo salvo com sucesso',
  failSendingFile: 'Não foi possível enviar o arquivo',
  review: 'Revisão',
  reviewed: 'Revisada',
  nonReviewed: 'Não revisada',
  failUpdatingReview: 'Não foi possível atualizar a revisão',
  exclusivities: 'Exclusividades',
  unreportedCustomer: 'Cliente não informado',
  fileSizeExceded: 'Tamanho máximo do arquivo excedido',
  invalidFileFormat: 'Formato do arquivo inválido',
  successAddingPrintToBriefings: 'Estampa adicionada aos briefings com sucesso',
  errorAddingPrintToBriefings: 'Erro ao adicionar a estampa aos briefings',
  chooseBriefings: 'Escolha os briefings',
  printRatioWarning:
    'Para melhor funcionamento, a razão do rapport devem respeitar a razão do arquivo JPG da estampa.',
  previousVersions: 'Versões anteriores',
  editableBackground: 'Sim, o fundo é editavel',
  important: 'IMPORTANTE!',
  backgroundColoAdvice:
    'Para deixar a cor de fundo editável, é necessário que se suba um PNG com fundo transparente. Usar save for web no Photoshop.',
  seeCustomization: 'Ver Customização',
  failAddingPreviousVersion:
    'Não foi possível adicionar a versão anterior da estampa',

  // Create collection
  createCollectionTitle: 'Cadastrar Coleção',
  collectionName: 'Nome da Coleção',
  id: 'ID',
  colession: 'Coleccion',
  cnpj: 'CNPJ',
  state: 'Estado',
  states: 'Estados',
  selectState: 'Selecione o estado',
  otherCountry: 'Fora do Brasil',
  save: 'Salvar',
  successCreatingCollection: 'Coleção cadastrada com sucesso',
  failCreatingCollection: 'Não foi possível cadastrar a coleção',
  // Create Agent Form
  agentName: 'Nome do responsável / Agente',
  agentCompanyName: 'Nome da empresa',
  vat: 'VAT',
  CreateUserClient: 'Registrar Cliente',



  // Create client
  clientRegistration: 'Criação de cliente',
  registerClient: 'Cadastrar cliente',
  updateClient: 'Atualizar cliente',
  clientName: 'Nome do cliente',
  successCreatingClient: 'Cliente cadastrado com sucesso',
  failCreatingClient: 'Não foi possível cadastrar o cliente',
  successUpdatingClient: 'Cliente atualizado com sucesso',
  failUpdatingClient: 'Não foi possível atualizar o cliente',
  brandName: 'Nome da marca',
  companyName: 'Razão social',
  stateRegistration: 'Inscrição Estadual',
  priceRangeType: 'Tipo de faixa de preço',
  targetingA: 'Segmentação A',
  targetingB: 'Segmentação B',
  targetingC: 'Segmentação C',
  deliveryAddress: 'Endereço de entrega',
  sameAddressAsDelivery: 'Endereço igual ao da entrega',
  responsibleContact: 'Contato Responsável',
  responsibleEmail: 'E-mail do Responsável',
  responsiblePhone: 'Telefone do Responsável',
  phoneNumber: 'Telefone',
  instagram: 'Instagram',
  website: 'Website',
  notes: 'Notas',
  customerService: 'Atendimento do cliente',
  profileImage: 'Image de perfil',
  profileImageAdvice:
    'Se você não enviar uma foto, suas iniciais serão usadas:',
  addRegister: 'Adicionar outro cadastro',
  chooseSeller: 'Escolha o atendente',
  addNewClient: 'Adicionar novo cliente',
  sortByDate: 'Ordenar por data',
  sendInvite: 'Enviar convite',
  successSendingInvite: 'Convite enviado com sucesso',
  errorSendingInvite: 'Não foi possível enviar o convite',
  isClientLec: 'Cliente Matteria',
  clientSignUp: 'SignUp',
  effective: 'Efetivar',
  addClientArea: 'Adicionar área',

  // Presentation Cover
  uploadPresentationCover: 'Subir capa da apresentação',
  successSavingPresentationCover: 'Capa da apresentação salva com sucesso',
  failSavingPresentationCover: 'Falha ao salvar a capa da apresentação',

  // Library
  loadMorePrints: 'Carregar mais estampas',
  mainPresentations: 'Principais apresentações',
  applyFixedFabric: 'Aplicar tecido fixo em uma estampa',
  createPresentation: 'Criar apresentação',
  choosePresentation: 'Apresentação',
  successAddingProductToPresentation:
    'O produto foi adicionado na apresentação com sucesso',
  failAddingProductToPresentation:
    'Não foi possível adicionar o produto a apresentação',
  reserved: 'Reservado',
  striked: 'Strike',
  exclusive: 'Exclusiva',
  notExclusive: 'Não exclusiva',
  drafts: 'Rascunhos',
  pendingReview: 'Revisão Pendente',
  seeOnlyPendingReview: 'Somente pendentes de revisão',
  clearFilters: 'Reset',
  printNotFound: 'Estampa não encontrada',
  showPrints: 'Mostrar estampas',
  filters: 'Filtros',
  successFavoritingPrint: 'Estampa favoritada com sucesso',
  errorFavoritingPrint: 'Não foi possível favoritar a estampa',
  successRemovingFavoritePrint: 'Estampa removida dos favoritos com sucesso',
  errorRemovingFavoritegPrint:
    'Não foi possível remover a estampa dos favoritos',
  //
  characteristics: 'Caracteristicas',
  // My work APPROVALS
  approvals: 'Aprovações',
  myPrints: 'Minhas estampas',
  approve: 'Aprovar',
  failGettingMySketches: 'Não foi possível carregar seus rascunhos',
  failGettingMyPrints: 'Não foi possível carregar o seu trabalho',
  failGettingMyApprovedPrints:
    'Não foi possível carregar as estampas aprovadas',
  enterProject: 'Entrar no Projeto',
  approvementConfirmationTitle: 'Aprovação de estampa',
  approvementConfirmationMessage: 'Realmente deseja aprovar esta estampa?',
  approvementConfirmationOption: 'Aprovar',
  approvementCancelOption: 'Não',
  allPrintsApproved: 'Você não possui estampas pendentes de aprovação',
  approved: 'Aprovadas',
  nonApproved: 'Não Aprovadas',
  printApproved: 'Aprovada',
  createPrint: 'Criar estampa',
  deletePrintsConfirmation:
    'Tem certeza que deseja excluir as estampas selecionadas?',
  failDeletingPrints: 'Não foi possível excluir as estampas',

  uploadAPrint: 'Suba uma estampa :)',
  orders: 'Pedidos',
  estimatedDateDelivery: 'Prev. Entrega Fornecedor',
  agreedDateDelivery: 'Data de Entrega Acordada',
  supplierNumber: 'Pedido compra',
  supplier: 'Fornecedor',
  suppliers: 'Fornecedores',
  seeDetails: 'Ver Detalhes',

  // My work ART FINALIST
  chooseArtFinalist: 'Arte Finalista',
  allPrintsArtFinished: 'Você não possui estampas pendentes para finalizar',
  previousPrints: 'Estampas Anteriores',
  nextPrints: 'Próximas Estampas',

  // Search
  search: 'BUSCA',
  twins: 'Gêmeas',
  twin: 'Gêmea',
  searchFor: 'Procurar',

  // Register Pantone
  registerPantone: 'Cadastro de Pantone',
  pantoneName: 'Nome do Pantone',
  invalidHexColor: 'Código hexadecimal inválido',
  hexColorCode: 'Código da cor(hex)',
  savePantone: 'Salvar pantone',
  successCreatingPantone: 'Pantone criada com success',
  failCreatingPantone: 'Não foi possível criar a pantone',
  pantone: 'Pantone',

  // Register Presentation
  registerPresentation: 'Cadastro de apresentação',
  presentationName: 'Nome da apresentação',
  chooseType: 'Escolha o tipo',
  requiredClient: 'Escolha o cliente',
  chooseClientProfile: 'Escolha os perfis do cliente',
  presentationDate: 'Data da apresentação',
  writeHereTheBriefing: 'Escreve aqui o briefing',
  savePresentation: 'Salvar apresentação',
  day: 'Dia',
  month: 'Mês',
  year: 'Ano',
  women: 'Mulher',
  youngWomen: 'Mulher Jovem',
  youngMen: 'Homem Jovem',
  womenPluzSize: 'Mulher Plus Size',
  informalMen: 'Homem Informal',
  men: 'Homem',
  kids: 'Kids',
  baby: 'Baby',
  home: 'Linha Home',
  marketing: 'Marketing',
  swimwear: 'Swimwear',
  zoom: 'Zoom',
  playAndCardDoll: 'Boneca do play/card',
  dollNotFound: 'Boneca não encontrada',

  // Edit Presentation
  editPresentation: 'Editar Apresentação',
  saveChanges: 'Salvar alterações',
  successUpdatingPresentation: 'Apresentação atualizada com sucesso',
  failUpdatingPresentation: 'Não foi possível atualizar a apresentação',

  // TODO: Victor
  nina1: 'Niña 2 a 4 anos',
  nina2: 'Niña 5 a 6 anos',
  nina3: 'Niña 7 a 8 anos',

  baby1: 'Baby 3 a 6 meses',
  baby2: 'Baby 7 a 9 meses',

  teen1: 'Teen 9 a 11 anos',
  teen2: 'Teen 12 a 14 anos',

  teen: 'Teen',

  failCreatingPresentation: 'Não foi possível criar a apresentação',
  successCreatingPresentation: 'Apresentação criada com sucesso',

  // Presentations
  idLaunch: 'Lançamento ID',
  collecion: 'Colección',
  failDeletingPresentations: 'Não foi possível deletar as apresentações',
  searchPresentation: 'Procure uma apresentação',
  failDuplicatePresentations: 'Não foi possível duplicar as apresentações',
  duplicate: 'Duplicar',
  selectedMultiple: 'selecionados',
  selectedOne: 'selecionado',
  addBriefings: 'Subir briefings',
  selectOneOrMoreBriefings: 'Selecione um ou mais briefings',
  searchBriefing: 'Buscar briefing',
  successFavoritingPresentation: 'Apresentação favoritada com sucesso',
  errorFavoritingPresentation: 'Não foi possível favoritar a apresentação',
  successRemovingFavoritePresentation:
    'Apresentação removida dos favoritos com sucesso',
  errorRemovingFavoritegPresentation:
    'Não foi possível remover a apresentação dos favoritos',

  // Presentation
  concept: 'Conceito',
  prints: 'Estampas',
  share: 'Compartilhar',
  invite: 'Convidar',
  successSendingInvites: 'Convites enviados com sucesso',
  failSendingInvites: 'Não foi possível enviar os convites',
  addConcept: 'Adicionar conceito',
  image: 'Imagem',
  description: 'Descrição',
  successCreatingConcept: 'Conceito adicionado com sucesso',
  failCreatingConcept: 'Não foi possível adicionar o conceito',
  seeColorVariations: 'Ver variações de cores',
  seeOnlyOriginals: 'Ver somente originais',
  inviteDelete: 'Exclusão de convite',
  inviteDeletingMessage:
    'Tem certeza que deseja cancelar o convite dessa pessoa para essa apresentação?',
  yes: 'Sim',
  no: 'Não',
  failDeletingInvite: 'Não foi possívem canceltar o convite',
  edit: 'Editar',
  deleteFromPresentation: 'Deletar da apresentação',
  addFabrics: 'Adicione tecidos',
  addDescription: 'Adicionar descrição.',
  printFileSize: 'Dimensão do arquivo da estampa',

  selectedClientRequired:
    'Selecione um cliente para adicionar a estampa ao carrinho',
  thankYou: 'Obrigado',
  selectLocation: 'Informe a localização do cliente',

  // Link Creation
  createLink: 'Criação de link',
  attendanceUrlPassword: 'URL e Password do seu atendimento',
  generateURL: 'Gerar URL',
  copyURL: 'Copiar URL',
  copiedURL: 'URL copiada para a área de transferência',
  chooseRegions: 'Selecione a região',
  chooseCountry: 'Selecione o país',
  clientCountry: 'País do cliente',
  clientRegion: 'Região do cliente',
  all: 'Todas',
  remove: 'Remover',
  addLocation: 'Adicionar outra localidade',
  linkValidTime: 'Tempo de validade desse link',
  '15days': '15 dias',
  presentationLanguage: 'Idioma da apresentação',
  chooseLanguage: 'Escolha o idioma',
  portuguese: 'Português',
  english: 'Inglês',
  attendanceClients: 'Clientes que já tem esse atendimento',
  receivedAt: 'Recebeu no dia',

  // Client Presentation
  invalidPassword: 'Senha inválida',

  // Purchase
  scale: 'escala',
  rotate: 'rotacionar',
  luminosity: 'luminosidade',
  contrast: 'contraste',
  saturation: 'saturação',
  exclusivityAllColors: 'Exclusivar todas as cores',
  selectOneColor: 'Selecione uma cor',
  fabric: 'Tecido',
  selectOneFabric: 'Selecione um tecido',
  showFabrics: 'Ver tecidos',
  defineFabric: 'Definir tecido',
  reserve: 'Reservar',
  grammage: 'Gramatura',
  composition: 'Composição',
  width: 'Largura',
  yield: 'Rendimento',
  chooseFabric: 'Escolha o tecido',
  produce: 'Produzir',
  strike: 'Strike',
  hanger: 'Hanger',
  chooseAFabric: 'Escolha um tecido',
  production: 'Produção',
  chooseFabricFootage: 'Escolha a metragem do tecido',
  meters: 'Metros',
  next: 'Avançar',
  selectExclusivity: 'Selecione a exclusividade',
  exclusivityByContinent: 'Exclusividade por continente',
  regionalExclusivity: 'Exclusividade Regional',
  exclusivityCountry: 'Exclusividade por país',
  worldwideExclusivity: 'Exclusividade mundial',
  typesOfExclusivity: 'Tipos de exclusividade',
  exclusivityDuration: 'Duração da exclusividade',
  underAnalysis: 'Em análise',
  itIsAStrike: 'É um strike mesmo?',
  simple: 'Sample',
  writeTheCodeId: 'Escreva o código ID',
  ifExists: 'se existir',
  country: 'País',
  continent: 'Continente',
  southAmerica: 'América do sul',
  centralAmerica: 'América central',
  northAmerica: 'América do norte',
  antarctica: 'Antártida',
  europe: 'Europa',
  asia: 'Ásia',
  oceania: 'Oceania',
  america: 'América',
  africa: 'África',
  addAnotherExclusivity: 'Adicionar outra exclusividade',
  allStates: 'Todos',
  reset: 'Redefinir modificações',
  buyCustomizationAndDefineFabric: 'Comprar customização e definir tecido',
  eternal: 'Eterna',
  worldwide: 'Mundial',
  continental: 'Continental',
  national: 'Nacional',
  international: 'Internacional',
  regional: 'Regional',
  laEstampaMarketing: 'La Estampa Marketing',
  twoYears: '2 anos',
  threeMonths: '3 meses',
  oneYearTwoMonths: '1 ano e 2 meses',
  ofExclusivity: 'de exclusividade',
  observationSent: 'Observação enviada',
  observation: 'Observação',
  monitorColorVariation:
    'A cor da estampa pode mudar de acordo com o seu monitor',
  printFileDimension: 'Dimensão do arquivo da estampa',
  fastBuy: 'Compra rápida',
  customize: 'Customizar',
  someModification: 'Deseja alguma outra modificação? Escreva aqui.',

   //LE COLLECTION
   agents: 'Agentes',
   addNewAgent: 'Adicionar Novo Agente',
   addNewUserClient: 'Adicionar novo usuário',

  // Cart
  reserveProductionSummary: 'Resumo de produção de reservas',
  saveAsDraft: 'Salvar tudo como rascunho',
  continue: 'Continuar',
  printAddedToCart: 'Estampa adicionada no carrinho',
  opened: 'aberto',
  draft: 'rascunho',
  exclusivity: 'Exclusividade',
  historyExclusivity: 'Historico de Exclusividade',
  typeOfProduction: 'Tipo de produção',
  typeOfFabric: 'Tipo de tecido',
  colorVariant: 'Variante de cor',
  colorAndDrawing: 'Cor e desenho',
  addToCart: 'Adicionar ao carrinho',
  defineExclusivity: 'Definir exclusividade',
  selectProductionType: 'Escolha o tipo de produção',
  createdOrdersSuccess: 'Pedido criado com sucesso',
  updatedOrdersSuccess: 'Pedido atualizado com sucesso ',
  selectClientToShowSummary: 'Selecione um cliente para mostrar o seu resumo',
  selectPrintsToContinue: 'Selecione as estampas para continuar',
  OPEN: 'Aberto',
  FINA: 'Finalizado',
  PROD: 'Em andamento',
  DISP: 'Expedido',
  REP: 'Reprovado',
  APP: 'Aprovado',
  REV: 'Revisão',
  emptyClientSummary: 'Este cliente ainda não possui nenhum pedido',
  effectuate: 'Efetivar',
  PRODUCAO: 'produção',
  PILOTO: 'strike',
  HANGER: 'hanger',
  writeIDCode: 'Escreva o código ID',
  processedOrder: 'Pedido processado',
  goBackToPresentation:
    'Clique em qualquer lugar para voltar para a apresentação',
  setProductionMeters: 'Selecione a metragem para todos os tecidos em produção',
  setProductionFabric: 'Selecione o tecido para todos os tecidos em produção',
  setStrikeMeters: 'Selecione a metragem para todos os tecidos em strike',
  setStrikeFabric: 'Selecione o tecido para todos os tecidos em strike',
  currency: 'Moeda',
  price: 'Preço',
  discount: 'Desconto',
  pricePerMeter: 'Preço por metro',
  hangerValue: 'Valor total de hanger',
  strikeValue: 'Valor total de strike',
  productionValue: 'Valor total de produção',
  repetition: 'Repetição',
  simpleRepetition: 'Simple e repetição',
  totalValue: 'Valor Total',
  numberOfHanger: 'No. de hangers',
  numberOfStrike: 'No. de strikes',
  numberOfProduction: 'No. de produções',
  numberOfReserve: 'No. de reservas',
  presentationAddedToCart: 'Apresentação enviada para o carrinho com sucesso',
  failedPresentationAddedToCart: 'Não foi possível enviar a apresentação para o carrinho',
  selectedSupplier: 'Selecione o fornecedor',
  selectedService: 'Serviço',
  selectedTechnique: 'Tecnica',
  selectedSupplierFabric: 'F. Qualidade',

  //Modal Cliente Carrinho
  modalClientTitle: 'Por favor selecionar um cliente',
  confirmText: 'Ok',
  cancelText: 'Cancelar',

  // Sale
  saleTitle: 'Finalização do pedido de produção',
  paymentCondition: 'Condição de pagamento',
  paymentWay: 'Forma de pagamento',
  address: 'Endereço',
  city: 'Cidade',
  zipCode: 'CEP',
  postCode: 'Post code',
  subBrand: 'Submarca',
  deliveryPostCode: 'Post code de entrega',
  deliveryCountry: 'País de entrega',
  freight: 'Frete',
  deliveryWay: 'Forma de entrega',
  deliveryStart: 'Entrega de',
  deliveryEnd: 'Até',
  observations: 'Observações',
  sendEmailCopy: 'Mandar cópia de e-mail',
  send: 'Enviar',
  total: 'Total',
  failCreatingSale: 'Não foi possível realizar a venda',
  processedOrderSuccess: 'Pedido processado :)',
  goToPresentation: 'Clique em qualquer lugar para voltar para a apresentação.',
  selectClientArea: 'Selecione uma area de cliente',
  warningNotSelectedClientArea: 'Favor selecionar uma área do cliente.',

  // Search
  searching: 'Buscando',

  // Orders
  showOpenOrders: 'Vendas Pendentes',
  descriptionInfoFINA: 'Pedido finalizado',
  descriptionInfoOPEN: 'Em análise',
  descriptionInfoREP: 'Não aprovado',
  descriptionInfoPROD: 'Em produção',
  descriptionInfoDISP: 'Pedido expedido',
  descriptionInfoAPP: 'Pedido Aprovado',
  descriptionInfoREV: 'Em Revisão',
  loadMoreOrders: 'Carregar mais vendas',
  orderApproval: 'Aprovação',
  orderRevision: 'Em revisão',
  orderInProgress: 'Em andamento',
  orderFinished: 'Finalizado',
  dontApprove: 'Não aprovar',
  noOrders: 'Não foram encontrados pedidos',
  priority: 'Prioridade',
  reviewedProduct: 'Revisada?',
  allReviewed: 'Revisar Todas?',
  refreshExcelTitle: 'Deseja gerar novamente o Excel?',
  refreshExcelDescription: 'Ao confirmar, o excel será gerado novamente! Essa ação poderá levar alguns minutos, por favor espere!',
  refreshExcelConfirmButton: 'Sim',
  refreshExcelCancelButton: 'Não',
  refreshExcelSuccessMessageToast: 'Excel gerado novamente, pode ser que o arquivo demore uns minutos para ser atualizado!',
  deleteOrderMessage: 'Pedido deletado com sucesso!',
  deleteOrderErrorMessage: 'Erro ao deletar o pedido, por favor tente mais tarde!',
  refreshExcelWaitMessageToast: 'Espere 5 minutos para gerar o excel novamente.',
  finishButton: 'Finalizar',
  // Request password
  resetPasswordHere: 'Resete sua senha aqui',
  resetPasswordEmailSent: 'E-mail enviado para recuperar senha.',
  passwordChanged: 'Senha alterada com sucesso.',

  // Attendance
  attendances: 'Atendimentos',
  attendance: 'Atendimento',
  searchAttendance: 'Procure um atendimento',
  confirmAttendancesDelete:
    'Tem certeza que deseja excluir os atendimentos selecionadas?',
  failDuplicatingAttendances: 'Não foi possível duplicar os atendimentos',
  failDeletingAttendances: 'Não foi possível excluir os atendimentos',
  failCreatingAttendance: 'Não foi possível criar o atendimento',
  failUpdatingAttenance: 'Não foi possível atualizar o atendimento',
  showAttendances: 'Mostrar Atendimentos',
  successFavoritingAttendance: 'Atendimento favoritao com sucesso',
  errorFavoritingAttendance: 'Não foi possível favoritar o atendimento',
  successRemovingFavoriteAttendance:
    'Atendimento removido dos favoritos com sucesso',
  errorRemovingFavoritegAttendance:
    'Não foi possível remover o atendimento dos favoritos',
  uploadAttendenceCover: 'Subir capa do atendimento',
  failUpdatingAttendance: 'Falha ao alterar o atendimento',

  // Register Attendance
  registerAttendance: 'Cadastro de atendimento',
  attendanceName: 'Nome do atendimento',
  attendanceDate: 'Data do atendimento',
  saveAttendance: 'Salvar attendance',
  successCreatingAttendance: 'Atendimento criado com sucesso',
  successUpdatingAttendance: 'Atendimento atualizado com sucesso',
  editAttendance: 'Editar atendimento',
  chooseAttendance: 'Incluir em atendimento',
  createAttendance: 'Criar atendimento',
  successAddingPresentationToAttendance:
    'Apresentação adicionada ao atendimento com sucesso',
  failAddingPresentationToAttendance:
    'Não foi possível adicionar apresentação ao atendimento',
  confirmRemoveAttendancePresentation:
    'Tem certeza que deseja remover as apresentações selecionadas do atendimento?',
  selectOneOrMorePresentations: 'Selecione uma ou mais apresentações',
  selectOneOrMorePrints: 'Selecione uma ou mais estampas',
  apply: 'Aplicar',
  searchPrint: 'Buscar estampa',
  addPresentations: 'Subir apresentações',
  addPrints: 'Subir estampas',

  // Briefings
  briefings: 'Briefings',
  createBriefing: 'Criar Briefing',
  createNewCardBriefingText: 'Criar um novo briefing',
  registerBriefing: 'Cadastrar Briefing',
  sendBriefing: 'Enviar Briefing',
  writeBriefingTitle: 'Escreva aqui o nome do seu Briefing',
  writeBriefingDescription:'Adicione aqui uma descrição do briefing que você gostaria de passar para o seu CLIENTE. Este campo estará VISÍVEL para o cliente.',
  addVisualReferences: 'Adicione as suas referências visuais',
  dragImages: 'Arraste imagens aqui, ou clique no botão de subir imagens',
  uploadImages: 'Subir imagens',
  successCreatingBriefing: 'Briefing criado com sucesso',
  failCreatingBriefing: 'Não foi possível criar o Briefing',
  successUpdatingBriefing: 'Briefing atualizado com sucesso',
  updateBriefing: 'Atualizar Briefing',
  defineAsCover: 'Definir como capa',
  cover: 'Capa',
  withoutClient: 'Sem cliente',
  inviteDeletingBriefingMessage:
    'Tem certeza que deseja cancelar o convite dessa pessoa para esse briefing?',
  new: 'NOVO',
  showBriefings: 'Mostrar Briefings',
  period: 'Período',
  deleteBriefing: 'Deletar Briefing',
  deleteBriefingMessage: 'Você realmente deseja deletar este briefing?',
  writeBriefingTechDescription: 'Esse campo é dedicado à comunicação interna. Adicione aqui a descrição do briefing que você gostaria de passar para DESIGNER / MERCHANDISER. Este campo NÃO estará visível para o cliente.',
  // Print Approval Customization
  successCopyPrint: 'Estampa copiada com sucesso',
  errorCopyPrint: 'Erro ao copiar estamapa',
  successSavingPrint: 'Customização da estampa salva com sucesso',
  errorSavingPrint: 'Não foi possível salvar a customização da estampa',
  goBack: 'Voltar',

   //Print Details
   orderClient: 'Cliente: ',
   orderDate: 'Data do Pedido: ',
   orderNumber: 'No. Pedido: ',

  // Doll
  mask: 'Máscara',
  moveHorizontally: 'Mover na horizontal',
  moveVertically: 'Mover na vertical',


  //EXCLUSIVITY TYPES
  INF: 'Eterna',
  MON: 'Mundial',
  CON: 'Continental',
  INT: 'Internacional',
  NAT: 'Nacional',
  REG: 'Regional',
  VIN: 'Vintage',
  MKT: 'La Estampa Marketing',
  STD: 'Studio',

  //EXCLUSIVITY VAR
  DES: 'Desenho',
  COR: 'Cor'
};

export default pt;
