import React, { useState, useEffect, useRef, useCallback } from 'react';
import Loading from '../Loading';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import { useSelector } from 'react-redux';
import Api from '../../libs/Api';
import { toast } from 'react-toastify';
import ListSelector from '../ListSelector';
import useOutsideClick from '../../libs/useOutsideClick';
import { FiAlignJustify } from 'react-icons/fi';
import ArrowIcon from '../../assets/icons/Arrow';
// @ts-ignore
import { Link } from 'react-router-dom';

export interface ListClientProps {
    id: number;
    name: string;
    email: string;
    is_shared?: boolean;
}

// @ts-ignore
export function ModalCreateUser({ closeForm }) {
    const [clients, setClients] = useState<ListClientProps[]>([]);
    // @ts-ignore
    const language = useSelector(state => state.settings.language);
    // @ts-ignore
    const currentUser = useSelector(state => state.user);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [requiredField, setRequiredField] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientsSelector, setClientsSelector] = useState(false);
    const [selectedClient, setSelectedClient] = useState();
    const clientSelectorRef = useRef();

    useEffect(() => {
        Api.getClients().then((res) => {setClients(res)})
    }, []);

    useEffect(() => {
        setClientsSelector(false);
    }, [selectedClient]);
    
      useOutsideClick(clientSelectorRef, () => {
        setClientsSelector(false);
    });

    const clearClient = useCallback(() => {
        // setSelectedClient();
        setClientsSelector(false);
    }, []);
    
    function clearInputValues() {
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    }

    // @ts-ignore
    async function handleCreateUserClient(e) {
        e.preventDefault();
        if(name == '') {
            setRequiredField(!requiredField);
            return
        } 
        if(email == '') {
            setRequiredField(!requiredField);
            return
        } 
        if(selectedClient == '') {
            setRequiredField(!requiredField);
            return
        }
        if(password == '') {
            setRequiredField(!requiredField);
            return
        }
        if(password != confirmPassword) {
            toast(translate('passwordDoesntMatch', language), {
              type: 'error'
            });
            return;
        }
        try {
            setLoading(true);
            const signupData = {
                name: name,
                email: email,
                // @ts-ignore
                client_user: selectedClient.id,
                password,
                role: ['client-id', 'client-seller'],
                is_client: true,
            }
            const response = await Api.createClientUser(signupData);
        } catch(err) {
            setLoading(false);
            return toast(translate('errorCreatingClientUser', language), {
                type: 'error'
            });
        }

        toast(translate('successCreatingClientUser', language), {
                type: 'success'
        });
        setLoading(false)
        clearInputValues();
    }


    return(
        <div className={styles.clientFormModal}> 
            <div style={{padding: '20px'}} className={styles.header}>
                {closeForm ? (
                <button
                    className={styles.closeButton}
                    type="button"
                    onClick={closeForm}
                >
                    <div className={styles.backIcon}>
                    <ArrowIcon color="white" />
                    </div>
                    {translate('userClient', language)}
                </button>
                ) : (
                <Link className={styles.closeButton} to="/">
                    <div className={styles.backIcon}>
                    <ArrowIcon color="white" />
                    </div>
                    {translate('userClient', language)}
                </Link>
                )}
            </div>
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <form onSubmit={handleCreateUserClient}>
                        <div className={styles.textInput}>
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Nome do usuario'}
                                value={name}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => {
                                    setName(e.target.value) 
                                    setRequiredField(false)
                                }}
                            />
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Email'}
                                value={email}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => { 
                                    setEmail(e.target.value.toLocaleLowerCase()) 
                                    setRequiredField(false)
                                }}
                            />
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Senha'} 
                                type="password"
                                value={password}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setRequiredField(false)
                                }}
                            />
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <input 
                                placeholder={'Confirmar senha'} 
                                type="password"
                                value={confirmPassword}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                    setRequiredField(false)
                                }}
                            />
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            <button
                                id=""
                                type="button"
                                className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                                onClick={() => setClientsSelector(true)}
                            >
                                {selectedClient
                                // @ts-ignore
                                ? selectedClient.name
                                : translate('chooseClient', language)}
                            </button>

                            {clientsSelector === true && (
                                <span style={{position: 'relative'}}>
                                <ListSelector
                                    label={translate('clients', language)}
                                    selectLabel={translate('choose', language)}
                                    items={clients}
                                    onSelect={setSelectedClient}
                                    value={selectedClient}
                                    search
                                />
                                </span>
                            )}
                        </div>
                        <button 
                        className={styles.defaultActionButton}
                        type="submit"                    
                        >
                            {translate('CreateUserClient', language)} 
                        </button>
                    </form>

                    {loading &&
                        (
                            <div className={styles.loadingContainer}>
                            <Loading />
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

