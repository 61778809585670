import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import styles from './Presentations.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';

import SearchIcon from '../assets/icons/Search';
import PresentationGridItem from '../components/PresentationGridItem';
import ConfirmationModal from '../components/ConfirmationModal';
import TagsSelectors from '../components/TagsSelectors';

import Loading from '../components/Loading';
import ClientSelectorModal from '../components/ClientSelectorModal/ClientSelectorModal';
import ListSelector from '../components/ListSelector';
import { addProductsOnCart } from '../store/actions/carts';
import useOutsideClick from '../libs/useOutsideClick';

function Presentations() {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const [section, setSection] = useState('');
  const [selectedPresentations, setSelectedPresentations] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedClientToCart, setSelectedClientToCart] = useState();
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedProveniences, setSelectedProveniences] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [myPresentations, setMyPresentations] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [tags, setTags] = useState([]);
  const [filter, setFilter] = useState('');
  const [clientProfiles, setClientProfiles] = useState([]);
  const [attendances, setAttendances] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [updatedPage, setUpdatedPage] = useState();
  const [counter, setCounter] = useState();
  const [filteredCounter, setFilteredCounter] = useState();
  const [clientModal, setClientModal] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [idModal, setIdModal] = useState();
  const [clientSelector, setClientSelector] = useState(false);
  const clientSelectorRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    (async function loadData() {
      setLoading(true);
      try {
        const getCollections = Api.getCollections();
        const getClients = Api.getClients();
        const getTags = Api.getTags();
        const getClientProfiles = Api.getCustomerProfiles();
        const getAttendances = Api.getAttendances();

        await axios
          .all([
            getCollections,
            getClients,
            getTags,
            getClientProfiles,
            getAttendances
          ])
          .then(
            axios.spread(async (...responses) => {
              setCollections(responses[0]);
              setClients(responses[1]);
              setTags(responses[2]);
              setClientProfiles(responses[3]);
              setAttendances(responses[4].result);
            })
          );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        // setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (filter) {
      setInitialLoad(true);
      setLoading(true);
      Api.getMyPresentations(`${filter}&page=${page - 1}`)
        .then(res => {
          setFilteredCounter(res.count);
          setMyPresentations(res.result);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
          Api.getFavoritePresentations().then(favoritePresentations => {
            const favoritedPresentations = res.result.map(p =>
              favoritePresentations.includes(p.id)
                ? { ...p, isFavorite: true }
                : { ...p, isFavorite: false }
            );
            setMyPresentations(favoritedPresentations);
          });
        })
        .finally(() => {
          setLoading(false);
          setInitialLoad(false);
        });
    } else {
      setInitialLoad(true);
      Api.getMyPresentations(`page=${page - 1}`)
        .then(res => {
          setMyPresentations(res.result);
          setCounter(res.count);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
          Api.getFavoritePresentations().then(favoritePresentations => {
            const favoritedPresentations = res.result.map(p =>
              favoritePresentations.includes(p.id)
                ? { ...p, isFavorite: true }
                : { ...p, isFavorite: false }
            );
            setMyPresentations(favoritedPresentations);
          });
        })
        .catch()
        .finally(() => {
          setLoading(false);
          setInitialLoad(false);
        });
    }
  }, [filter, page]);

  useEffect(() => {
    // if (searchQueryTimeout) {
    //   clearTimeout(searchQueryTimeout);
    // }
    setPage(1);
    let query = '';
    function concat(filters) {
      if (query) {
        query += `&${filters}`;
      } else {
        query += filters;
      }
    }
    if (selectedCollections.length) {
      let collectionsQuery = 'collection=';
      for (let i = 0; i < selectedCollections.length; i += 1) {
        if (i === selectedCollections.length - 1) {
          collectionsQuery += selectedCollections[i].id;
        } else {
          collectionsQuery += `${selectedCollections[i].id},`;
        }
      }
      concat(collectionsQuery);
    }

    if (selectedClient) {
      const clientsQuery = `client=${selectedClient.id}`;

      concat(clientsQuery);
    }
    if (selectedProveniences.length) {
      let proveniencesQuery = 'provenience=';
      for (let i = 0; i < selectedProveniences.length; i += 1) {
        if (i === selectedProveniences.length - 1) {
          proveniencesQuery += selectedProveniences[i].value;
        } else {
          proveniencesQuery += `${selectedProveniences[i].value},`;
        }
      }
      concat(proveniencesQuery);
    }

    if (selectedTags.length) {
      let tagsQuery = 'tags=';
      for (let i = 0; i < selectedTags.length; i += 1) {
        if (i === selectedTags.length - 1) {
          tagsQuery += selectedTags[i].id;
        } else {
          tagsQuery += `${selectedTags[i].id},`;
        }
      }
      concat(tagsQuery);
    }

    if (selectedProfiles.length) {
      let tagsQuery = 'profile=';
      for (let i = 0; i < selectedProfiles.length; i += 1) {
        if (i === selectedProfiles.length - 1) {
          tagsQuery += selectedProfiles[i].id;
        } else {
          tagsQuery += `${selectedProfiles[i].id},`;
        }
      }
      concat(tagsQuery);
    }

    if (searchQuery.length) {
      if(searchQuery.startsWith("L")) {
        const splitSearchQuery = searchQuery.split("L");
        const hasNumbers = /^\d/.test(splitSearchQuery[1]);
        if(hasNumbers) {
          concat(`search=${searchQuery}`);
        } else {
          concat(`search=${searchQuery}`);
        }
      } else {
        concat(`search=${searchQuery}`);
      }
    }

    if (section.length) {
      concat(`type=${section}`);
    }

    if (query === '?') {
      setFilter('');
    } else {
      setFilter(query);
    }
  }, [
    selectedTags,
    selectedCollections,
    selectedClient,
    selectedProveniences,
    selectedProfiles,
    section
  ]);

  const openClientSelectModal = (presentation_id) => {
    setIdModal(presentation_id);
    setClientModal(true);
  }

  async function addPresentationToCart(client_id, presentation_id) {
    const formData = new FormData();
    const getPrints = await Api.getExclusiveMeetingPrints(presentation_id);
    try {
      getPrints.map(presentationPrint => {
          formData.append('print', presentationPrint.print_id);
          Api.createProduct(formData).then(async product => {
            const newProduct = {
              ...product,
              client: client_id,
              seller: user.id,
              action: null,
              meters: '',
              product_id: presentationPrint.product_id,
              print_id: presentationPrint.print_id,
              print_code: presentationPrint.print_code,
              print_color_variant: presentationPrint.print_color_variant,
              initialFabrics: []
              };
              dispatch(addProductsOnCart([newProduct]));
          })  
      });
      setClientModal(false);
      toast(translate('presentationAddedToCart', language), {
        type: 'success'
      });
    } catch {
      toast(translate('failedPresentationAddedToCart', language), {
        type: 'error'
      });
    } finally {
      setIsLoading2(false);
    }
  }

  const onSubmitQuerySearch = () => {
    setPage(1);
    let query = '';
    function concat(filters) {
      if (query) {
        query += `&${filters}`;
      } else {
        query += filters;
      }
    }
    if (searchQuery.length > 0) {
      if(searchQuery.startsWith("L")) {
        const splitSearchQuery = searchQuery.split("L");
        const hasNumbers = /^\d/.test(splitSearchQuery[1]);
        if(hasNumbers) {
          concat(`search=${searchQuery}`);
        } else {
          concat(`search=${searchQuery}`);
        }
      } else {
        concat(`search=${searchQuery}`);
      }
    }
    if (query === '?') {
      setFilter('');
    } else {
      setFilter(query);
    }
  }

  const onSelectPresentation = (id, selected) => {
    let currentSelectedPresentations = [...selectedPresentations];
    const containsPresentation = currentSelectedPresentations.find(
      presentation => presentation === id
    );
    if (!selected && containsPresentation) {
      currentSelectedPresentations = currentSelectedPresentations.filter(
        presentation => presentation !== id
      );
    } else if (selected && !containsPresentation) {
      currentSelectedPresentations = [...selectedPresentations, id];
    }
    setSelectedPresentations(currentSelectedPresentations);
  };

  const deletePresentations = () => {
    setLoading(true);
    setConfirmationModal(false);
    const deleteRequests = [];

    selectedPresentations.forEach(presentation => {
      const deleteRequest = Api.deletePresentation(presentation);
      deleteRequests.push(deleteRequest);
    });
    axios
      .all(deleteRequests)
      .then(() => {
        Api.getMyPresentations(`page=${page - 1}`).then(res => {
          setMyPresentations(res.result);
          setCounter(res.count);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
          Api.getFavoritePresentations().then(favoritePresentations => {
            const favoritedPresentations = res.result.map(p =>
              favoritePresentations.includes(p.id)
                ? { ...p, isFavorite: true }
                : { ...p, isFavorite: false }
            );
            setMyPresentations(favoritedPresentations);
          });
        });
        setSelectedPresentations([]);
      })
      .catch(() => {
        toast(translate('failDeletingPresentations', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const duplicatePresentations = () => {
    setLoading(true);
    const duplicateRequests = [];

    selectedPresentations.forEach(presentation => {
      const deleteRequest = Api.duplicatePresentation(presentation);
      duplicateRequests.push(deleteRequest);
    });
    axios
      .all(duplicateRequests)
      .then(() => {
        Api.getMyPresentations(`page=${page - 1}`).then(res => {
          setMyPresentations(res.result);
          setCounter(res.count);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
          Api.getFavoritePresentations().then(favoritePresentations => {
            const favoritedPresentations = res.result.map(p =>
              favoritePresentations.includes(p.id)
                ? { ...p, isFavorite: true }
                : { ...p, isFavorite: false }
            );
            setMyPresentations(favoritedPresentations);
          });
        });
        setSelectedPresentations([]);
      })
      .catch(() => {
        toast(translate('failDuplicatePresentations', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  const getSectionName = useMemo(() => {
    switch (section) {
      case '':
        return 'myWork';
      case 'ID':
        return 'idLaunch';
      case 'COL':
        return 'collecion';
      case 'LE':
        return 'leCollection';
      default:
        return 'myWork';
    }
  }, [section]);

  const favoritePresentation = presentationId => {
    const updatedPresentations = [...myPresentations];
    const presentationIndex = updatedPresentations.findIndex(
      up => up.id === presentationId
    );
    updatedPresentations[presentationIndex].isFavorite = true;
    setMyPresentations(updatedPresentations);

    Api.staffFavoritePresentation(presentationId, user.id)
      .then(() => {
        toast(translate('successFavoritingPresentation', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorFavoritingPresentation', language), {
          type: 'error'
        });
        updatedPresentations[presentationIndex].isFavorite = false;
        setMyPresentations(updatedPresentations);
      });
  };

  const removeFavoritePresentation = presentationId => {
    const updatedPresentations = [...myPresentations];
    const presentationIndex = updatedPresentations.findIndex(
      up => up.id === presentationId
    );
    updatedPresentations[presentationIndex].isFavorite = false;
    setMyPresentations(updatedPresentations);

    Api.removeFavoritePresentation(presentationId)
      .then(() => {
        toast(translate('successRemovingFavoritePresentation', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegPresentation', language), {
          type: 'error'
        });
        updatedPresentations[presentationIndex].isFavorite = true;
        setMyPresentations(updatedPresentations);
      });
  };

  const changeClientSelector = state => {
    if(!state) {
      setClientSelector(true);
    } else {
      setClientSelector(false);
    }
  }

  useOutsideClick(clientSelectorRef, () => {
    changeClientSelector(clientSelector);
  })


  return (
    <div className={styles.presentations}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          <span className={styles.title}>
            {translate('presentations', language).toUpperCase()}
          </span>
        </h2>
        <nav className={styles.sectionsMenuContainer}>
          <ul className={styles.sectionsMenu}>
            <div className={styles.sectionActive}>
              {translate(getSectionName, language)}
              <span className={styles.arrowDown} />
            </div>
            <li>
              <button
                className={section === '' ? styles.active : ''}
                type="button"
                onClick={() => setSection('')}
              >
                {translate('myWork', language)}
              </button>
            </li>
            <li>
              <button
                className={section === 'ID' ? styles.active : ''}
                type="button"
                onClick={() => setSection('ID')}
              >
                {translate('idLaunch', language)}
              </button>
            </li>
            <li>
              <button
                className={section === 'COL' ? styles.active : ''}
                type="button"
                onClick={() => setSection('COL')}
              >
                {translate('collecion', language)}
              </button>
            </li>
            <li>
              <button
                className={section === 'LE' ? styles.active : ''}
                type="button"
                onClick={() => setSection('LE')}
              >
                {translate('leCollection', language)}
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.content}>
        <div className={styles.filters}>
          <div className={styles.left}>
            <form 
              className={styles.inputContainer}
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitQuerySearch();
              }}
            >
              <SearchIcon color="#a3b3c7" />
              <input
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className={styles.exclusivityFilter}
                placeholder={translate('searchPresentation', language)}
              />
            </form>
          </div>
          <div className={styles.right}>
            <TagsSelectors
              selectLabel={translate('add', language)}
              tags={tags.filter(tag => tag.category === 'Tema')}
              selectTags={setSelectedTags}
              collections={collections}
              selectCollections={setSelectedCollections}
              clients={clients}
              selectClients={setSelectedClient}
              selectProveniences={setSelectedProveniences}
              profiles={clientProfiles}
              selectProfiles={setSelectedProfiles}
              uniqueClient
              complete
            />
          </div>
        </div>

        <div className={styles.presentationsGrid}>
          {myPresentations.map(presentation => (
            <PresentationGridItem
              name={presentation.name}
              image={presentation.print_image_url}
              onSelect={onSelectPresentation}
              id={presentation.id}
              type={presentation.type}
              attendances={attendances}
              clients={clients}
              favorite={
                presentation.isFavorite
                  ? () => removeFavoritePresentation(presentation.id)
                  : () => favoritePresentation(presentation.id)
              }
              isFavorite={presentation.isFavorite}
              onClickCart={() => openClientSelectModal(presentation?.id)}
            />
          ))}
        </div>
        {loading && <Loading fixed={!initialLoad} />}
      </div>
      {clientModal && (
        <ClientSelectorModal
          title={translate('modalClientTitle', language)}
          confirmText={isLoading2 ? <div className={styles.isLoading} /> : translate('confirmText', language)}
          onCancel={() => {
            setClientModal(false)
            setSelectedClient()
          }}
          onConfirm={() => {
            if(!selectedClientToCart) {
              alert('Favor selecionar um cliente')
              return
            }
            setIsLoading2(true);
            addPresentationToCart(selectedClientToCart?.id, idModal);
        }}
        >
          <div className={styles.clientSelector}>
            <button
                type="button"
                className={`${styles.defaultSelectInput}`}
                onClick={e => {
                  e.stopPropagation();
                  setClientSelector(true);
                }}
            >
              {selectedClientToCart ? selectedClientToCart?.name : translate('modalClientTitle', language)}
            </button>
            {clientSelector && 
              (
                <div 
                  ref={clientSelectorRef} 
                  className={styles.selectorClientPosition}
                >
                  <ListSelector
                    selectLabel={translate('choose', language)}
                    items={clients}
                    onSelect={setSelectedClientToCart}
                    value={selectedClientToCart}
                    search
                  />
                </div>
              )}
          </div>
        </ClientSelectorModal>
      )}
      {confirmationModal && (
        <ConfirmationModal
          message="Tem certeza que deseja excluir as apresentações selecionadas?"
          confirmText="Sim"
          cancelText="Não"
          onConfirm={deletePresentations}
          onCancel={() => setConfirmationModal(false)}
          deleteWarning
        />
      )}
      {(counter || filteredCounter) && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                goTop();
                setPage(page - 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                goTop();
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
      <div
        className={`${
          styles.actionsMenuContainer
        } ${selectedPresentations.length > 0 && styles.actionsMenuActive}`}
      >
        <div className={styles.actionsMenu}>
          <div className={styles.left}>
            <div>
              {`${selectedPresentations.length} ${
                selectedPresentations.length > 1
                  ? translate('selectedMultiple', language)
                  : translate('selectedOne', language)
              }`}
            </div>
            <span className={styles.separator}>|</span>
            <button
              onClick={duplicatePresentations}
              disabled={!selectedPresentations.length}
              type="button"
              className={styles.duplicate}
            >
              {translate('duplicate', language)}
            </button>
          </div>
          <button
            onClick={() => setConfirmationModal(true)}
            disabled={!selectedPresentations.length}
            type="button"
            className={styles.delete}
          >
            {translate('delete', language)}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Presentations;
