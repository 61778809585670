import React , { createContext, useContext, useEffect, useState } from 'react';
import Api from '../libs/Api';
import moment from 'moment';
import { toast } from 'react-toastify';


const BriefingContext = createContext(null);
export function BriefingProvider({ children })  {
    const [briefings, setBriefings] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryTimeout, setSearchQueryTimeout] = useState(null);
    const [filter, setFilter] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState();
    const [loading, setLoading] = useState(false);
    const [initialDate, setInitialDate] = useState();
    const [finalDate, setFinalDate] = useState();
    const [clearAllFilters, setClearAllFilters] = useState(false);
    const [filtersModal, setFiltersModal] = useState(false);
    const [reloadBriefings, setReloadBriefings] = useState(true);
  
    const [, setInitialLoad] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
  
    const [updatedPage, setUpdatedPage] = useState();
    const [counter, setCounter] = useState();
    const [filteredCounter, setFilteredCounter] = useState();
  
    useEffect(() => {
      Api.getClients().then(res => {
        setClients(res);
      });
    }, []);
  
    useEffect(() => {
      setLoading(true);
      if (filter) {
        setInitialLoad(true);
        Api.getBriefings(`?${filter}&page=${page - 1}`)
          .then(res => {
            setFilteredCounter(res.count);
            setBriefings(res.result);
            let numberOfPages = parseInt(res.count / 30, 10);
            if (res.count % 30) {
              numberOfPages += 1;
            }
            setLastPage(numberOfPages);
          })
          .finally(() => {
            setLoading(false);
            setInitialLoad(false);
          });
      } else {
        setInitialLoad(true);
        Api.getBriefings(`?page=${page - 1}`)
          .then(res => {
            setBriefings(res.result);
            setCounter(res.count);
            let numberOfPages = parseInt(res.count / 30, 10);
            if (res.count % 30) {
              numberOfPages += 1;
            }
            setLastPage(numberOfPages);
          })
          .catch()
          .finally(() => {
            setLoading(false);
            setInitialLoad(false);
          });
      }
    }, [filter, page]);
  
    useEffect(() => {
      if (searchQueryTimeout) {
        clearTimeout(searchQueryTimeout);
      }
  
      let query = '';
      function concat(filters) {
        query += `&${filters}`;
      }
      if (selectedClients && selectedClients.length) {
        let clientsQuery = 'client=';
        for (let i = 0; i < selectedClients.length; i += 1) {
          if (i === selectedClients.length - 1) {
            clientsQuery += selectedClients[i].id;
          } else {
            clientsQuery += `${selectedClients[i].id},`;
          }
        }
        concat(clientsQuery);
      }
  
      if (initialDate) {
        concat(`init_date=${moment(initialDate).format('MM-DD-YYYY')}`);
      }
      if (finalDate) {
        concat(`final_date=${moment(finalDate).format('MM-DD-YYYY')}`);
      }
  
      if (searchQuery.length) {
        concat(`search=${searchQuery}`);
      }
  
      if (query === '?') {
        setFilter('');
      } else if (query !== '?' && searchQuery.length) {
        setSearchQueryTimeout(setTimeout(() => setFilter(query), 1000));
      } else {
        setFilter(query);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDate, initialDate, searchQuery, selectedClients]);
  
    const handleClearFilters = () => {
      setLoading(true);
      setClearAllFilters(true);
      setSearchQuery('');
      setSelectedClients();
  
      setTimeout(() => {
        setFiltersModal(false);
        setReloadBriefings(true);
      }, 2000);
    };

    const nextPage = () => {
      if(Number(page) <= lastPage) {
        setPage(page + 1);
      }
    }

    const prevPage = () => {
      if(page > 0) {
        setPage(page - 1);
      }
    }

    const updatedPageInput = (num) => {
      setUpdatedPage(num);
    }

    const searchQueryInput = (search) => {
      setSearchQuery(search);
    }
  
    const getBriefingsWithoutFilter = () => {
      setLoading(true);
      
      Api.getBriefings(`?page=${page - 1}`)
        .then(res => {
          setCounter(res.count);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
          if (res && res.results) {
            setBriefings(res.results);
          }
          setLoading(false);
        })
        .catch(() => {
          setBriefings([]);
          setPage(1);
          setReloadBriefings(true);
        })
        .finally(() => {
          setReloadBriefings(false);
          setLoading(false);
        });
    };
  
    const getFilteredBriefings = () => {
      setLoading(true);
      Api.getBriefings(`?page=${page - 1}${filter}`)
        .then(res => {
          setFilteredCounter(res.count);
  
          if (res && res.results) {
            setBriefings(res.results);
            let numberOfPages = parseInt(res.count / 30, 10);
            if (res.count % 30) {
              numberOfPages += 1;
            }
            setLastPage(numberOfPages);
          }
          setLoading(false);
        })
        .catch(() => {
          setBriefings([]);
        })
        .finally(() => {
          setLoading(false);
          setReloadBriefings(false);
        });
    };
  
    useEffect(() => {
      if (reloadBriefings) {
        if (!filter) {
          getBriefingsWithoutFilter();
        } else {
          getFilteredBriefings();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, reloadBriefings]);
  
    const handleKeyDown = e => {
      if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
        setPage(updatedPage);
        setUpdatedPage();
      }
    };
    const looseFocus = () => {
      if (updatedPage > 0 && updatedPage <= lastPage) {
        setPage(updatedPage);
        setUpdatedPage();
      }
    };
  
    const goTop = () => {
      window.scrollTo(0, 200);
    };
  
    async function deleteBriefing(id) {
      try {
        const updatedBriefings = [...briefings];
        const getBriefingIndex = updatedBriefings.findIndex(briefing => briefing.id == id);
        if(getBriefingIndex >= 0) {
          updatedBriefings.splice(getBriefingIndex, 1);
          setBriefings(updatedBriefings);
          await Api.deleteBriefing(id);
        } else {
          throw Error() 
        }
      } catch {
        toast.error('Erro ao deletar o briefing');
      }
    }

    
    return (
        <BriefingContext.Provider 
        value={{briefings, 
                page, 
                lastPage, 
                counter, 
                filteredCounter, 
                loading, 
                updatedPage,
                searchQuery,
                clients,
                selectedClients,
                initialDate,
                finalDate,
                clearAllFilters,
                setFinalDate,
                setInitialDate,
                setSelectedClients,
                setClearAllFilters,
                goTop, 
                getBriefingsWithoutFilter, 
                getFilteredBriefings, 
                handleClearFilters, 
                deleteBriefing, 
                prevPage, 
                nextPage,
                updatedPageInput,
                searchQueryInput
              }}>
            {children}
        </BriefingContext.Provider>
    )
}


export function useBriefing() {
    const context = useContext(BriefingContext);

    return context
}