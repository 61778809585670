/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import styles from './ApprovedList.module.scss';
import Api from '../libs/Api';
import translate from '../libs/i18n';
import { setArtFinalists } from '../store/actions/artFinalists';

import PrintListItem from './PrintListItem';
import CheckInput from './CheckInput';
import Loading from './Loading';

function ApprovedList() {
  const language = useSelector(state => state.settings.language);
  const artFinalists = useSelector(state => state.artFinalists);

  const [myWork, setMyWork] = useState(true);
  const [prints, setPrints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState();
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [updatedPage, setUpdatedPage] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    Api.getArtFinalists()
      .then(res => {
        dispatch(setArtFinalists(res));
      })
      .catch(() => {
        toast(translate('failLoadingArtFinalists', language), {
          type: 'error'
        });
      });
  }, [dispatch, language]);

  useEffect(() => {
    setLoading(true);
    Api.getMyPrints(`?page=${page}${myWork ? '&just_mine=true' : ''}`)
      .then(myPrints => {
        setCounter(myPrints.count);

        const printsPerPage = 30;
        let pagesAmount = parseInt(myPrints.count / printsPerPage, 10);

        if (myPrints.count % printsPerPage) {
          pagesAmount += 1;
        }

        setLastPage(pagesAmount);

        if (myPrints && myPrints.results) {
          const myPrintsApp = myPrints.results.filter(p => p.status === 'APP');

          setPrints(myPrintsApp);
        }
      })
      .catch(() => {
        toast(translate('failGettingMyPrints', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language, myWork, page]);

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  return (
    <>
      <div className={styles.filter}>
        <CheckInput
          text={translate('myWork', language)}
          value={myWork}
          onChange={setMyWork}
        />
      </div>
      <ul id="printList" className={styles.printsList}>
        {prints && prints.length > 0 ? (
          <>
            {prints.map(print => (
              <PrintListItem
                key={`${print.id}-${print.code}`}
                print={print}
                artFinalists={artFinalists}
                actions
              />
            ))}
          </>
        ) : (
          <div className={styles.allPrintsArtFinished}>
            {translate('allPrintsArtFinished', language)}
          </div>
        )}
      </ul>
      {counter && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setUpdatedPage('');
                setPage(page - 1);
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </>
  );
}

export default ApprovedList;
