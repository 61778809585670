import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import useOutsideClick from '../libs/useOutsideClick';
import { setQuery } from '../store/actions/searchLibrary';

import styles from './Search.module.scss';

import SearchIcon from '../assets/icons/Search';
import CloseIcon from '../assets/icons/Close';
import text from '../libs/i18n';
import Api from '../libs/Api';

export const Search = () => {
  const language = useSelector(state => state.settings.language);
  const dispatch = useDispatch();

  const [inputState, setInputState] = useState('closed');
  const [searchQuery, setSearchQuery] = useState();
  const [results, setResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [isLibrary, setIsLibrary] = useState(false);

  const ref = useRef();
  const cancelToken = useRef();
  const source = useRef();
  const searchTimer = useRef();

  useEffect(() => {
    if (window.location.pathname.includes('/library')) {
      setIsLibrary(true);
    } else {
      setIsLibrary(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    setResults([]);
  }, [inputState, searchQuery]);

  useOutsideClick(ref, () => {
    if (inputState === 'opened') {
      setInputState('close');
    }
  });

  const changeInputState = state => {
    if (state === 'closed') {
      setInputState('opened');
    } else {
      setInputState('closed');
      setSearchQuery('');
    }
  };

  const searchPrints = (e) => {
    e.preventDefault();
    const loadFoundPrints = () => {
      cancelToken.current = axios.CancelToken;
      source.current = cancelToken.current.source();
        if (isLibrary) {
          dispatch(setQuery(searchQuery));
          setResults([]);
        } else {
          setSearching(true);
          if (searchQuery) {
            Api.search(searchQuery, { cancelToken: source.current.token }).then(
              res => {
                if (res && res.results) {
                  setResults(res.results);
                  setSearching(false);
                }
              }
            );
          } else {
            setSearching(false);
            setResults([]);
          }
        }
    };
    if (source.current) {
      source.current.cancel();
    }
    loadFoundPrints();
  };

  return (
    <div className={styles.search}>
      <button
        type="button"
        className={styles.searchIcon}
        onClick={() => changeInputState(inputState)}
      >
        <SearchIcon />
      </button>
      {inputState === 'opened' && (
        <>
          <form onSubmit={searchPrints} className={styles.inputContainer}>
            <SearchIcon color="#202730" />
            <input
              placeholder={text('searchPlaceholder', language)}
              ref={ref}
              value={searchQuery}
              onChange={e => setSearchQuery(e.currentTarget.value)}
            />
            {searching && (
              <span className={styles.message}>
                {text('searching', language)}...
              </span>
            )}
            <button
              type="button"
              className={styles.closeIcon}
              onClick={() => changeInputState(inputState)}
            >
              <CloseIcon color="#202730" />
            </button>
          </form>
          <ul>
            {results.map(res => (
              <li key={res.code}>
                <Link to={`/search/${res.id}`} className={styles.cardLink}>
                  <img src={res.image} alt="print" />
                  <div className={styles.info}>
                    <div>{res.code}</div>
                    <div>{res.name}</div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default React.memo(Search);
