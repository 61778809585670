import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './UploadProgress.module.scss';

export default function UploadProgress({ progress, color, text }) {
  const circumference = 58 * 2 * Math.PI;

  const getProgress = useMemo(() => {
    return circumference - (progress / 100) * circumference;
  }, [circumference, progress]);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <svg className={styles.progressRing} height="120" width="120">
          <circle
            className={styles.circle}
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset: `${getProgress}`
            }}
            strokeWidth="4"
            stroke={color}
            fill="transparent"
            r="58"
            cx="60"
            cy="60"
          />
        </svg>
        <span className={styles.progress}>{progress} %</span>
        {text && <p>{text}</p>}
      </div>
    </div>
  );
}

UploadProgress.defaultProps = {
  progress: 100,
  color: '#00c4c5',
  text: ''
};

UploadProgress.propTypes = {
  progress: PropTypes.number,
  color: PropTypes.string,
  text: PropTypes.string
};
