import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { ChromePicker as ColorPicker } from 'react-color';

import styles from './PrintCustomization.module.scss';

import translate from '../libs/i18n';
import Api from '../libs/Api';

function PrintCustomization() {
  const language = useSelector(state => state.settings.language);

  const params = useParams();

  const [customizationInfo, setCustomizationInfo] = useState();
  const dollRef = useRef(null);

  useEffect(() => {
    Api.getCustomization(params.id).then(res => {
      setCustomizationInfo(res);
    });
  }, [params.id]);

  const editConfig = useMemo(() => {
    if (customizationInfo) {
      return {
        transform: `translateX(-50%) translateY(-50%) rotate(${
          customizationInfo.rotate
        }deg) scale(${1 + customizationInfo.scale / 100})`,
        filter: ` contrast(${1 +
          customizationInfo.contrast / 100}) hue-rotate(${
          customizationInfo.color
        }deg) saturate(${1 + customizationInfo.saturation / 10})`
      };
    }
    return {};
  }, [customizationInfo]);

  const renderDollMask = useMemo(() => {
    if (customizationInfo && customizationInfo.dolly) {
      return (
        <div className={styles.doll}>
          <div
            className={styles.dollBackgroundContainer}
            style={{
              backgroundColor: customizationInfo.flat_background
                ? customizationInfo.flat_background_color
                : ''
            }}
          >
            <div
              style={{
                backgroundImage: `url(${customizationInfo.origin_image})`,
                ...editConfig
              }}
              className={styles.fabric}
              alt="print"
            />
            <div
              style={{
                backgroundColor:
                  customizationInfo.brightness > 0 ? 'white' : 'black',
                opacity:
                  customizationInfo.brightness < 0
                    ? -customizationInfo.brightness
                    : customizationInfo.brightness
              }}
              className={styles.brightnessMask}
            />
          </div>

          <img
            className={styles.object}
            src={customizationInfo.dolly}
            alt="boneca"
            ref={dollRef}
          />

          <img
            src={customizationInfo.dolly_shadow}
            className={styles.fabricShadow}
            alt="vestido"
          />
          <div className={styles.adjustment} />
        </div>
      );
    }
    return null;
  }, [customizationInfo, editConfig]);

  if (!customizationInfo) {
    return false;
  }

  return (
    <>
      <div className={`${styles.content}`}>
        <div className={styles.print}>
          <h2 className={styles.printCode}>
            {customizationInfo.print_code}
            <br />
            Original: {customizationInfo.origin_code}
          </h2>
          <div
            className={styles.filtersContainer}
            style={{
              backgroundColor: customizationInfo.flat_background
                ? customizationInfo.flat_background_color
                : ''
            }}
          >
            <img src={customizationInfo.origin_image} alt="estampa" />
            <div
              style={{
                backgroundImage: `url(${customizationInfo.origin_image})`,
                ...editConfig
              }}
              className={styles.fabric}
              alt="print"
            />
            <div
              style={{
                backgroundColor:
                  customizationInfo.brightness > 0 ? 'white' : 'black',
                opacity:
                  customizationInfo.brightness < 0
                    ? -customizationInfo.brightness
                    : customizationInfo.brightness
              }}
              className={styles.brightnessMask}
            />
          </div>
        </div>
        <div className={`${styles.dollContainer} `}>{renderDollMask}</div>
        <section className={styles.color}>
          <Link to={`/print/${customizationInfo.print_id}`}>
            <h2>{customizationInfo.print_code}</h2>
          </Link>
          <Link to={`/print/${customizationInfo.origin}`}>
            <h3>Original: {customizationInfo.origin_code}</h3>
          </Link>

          <div className={styles.client}>
            {translate('client', language)}: {customizationInfo.client_name}
          </div>

          <div className={styles.rangeArea}>
            <div className={styles.rangeContainer}>
              <div className={styles.label}>
                <span>{translate('scale', language).toUpperCase()}</span>{' '}
                <span>
                  {customizationInfo.scale <= 0
                    ? customizationInfo.scale
                    : `+${customizationInfo.scale}`}
                </span>
              </div>

              <input
                type="range"
                min="-90"
                max="100"
                step="10"
                value={customizationInfo.scale}
              />
            </div>
            <div className={styles.rangeContainer}>
              <div className={styles.label}>
                <span>{translate('rotate', language).toUpperCase()}</span>
                <span>
                  {customizationInfo.rotate <= 0
                    ? customizationInfo.rotate
                    : `+${customizationInfo.rotate}`}
                </span>
              </div>

              <input
                type="range"
                min="-345"
                max="345"
                step="15"
                value={customizationInfo.rotate}
              />
            </div>

            <div className={styles.rangeContainer}>
              <div className={styles.label}>
                <span>{translate('luminosity', language).toUpperCase()}</span>{' '}
                <span>
                  {customizationInfo.brightness <= 0
                    ? parseInt(Number(customizationInfo.brightness * 100), 10)
                    : `+${parseInt(
                        Number(customizationInfo.brightness * 100),
                        10
                      )}`}
                </span>
              </div>

              <input
                type="range"
                min="-1"
                max="1"
                step="0.05"
                value={customizationInfo.brightness}
              />
            </div>
            <div className={styles.rangeContainer}>
              <div className={styles.label}>
                <span>{translate('contrast', language).toUpperCase()}</span>{' '}
                <span>
                  {customizationInfo.contrast <= 0
                    ? customizationInfo.contrast
                    : `+${customizationInfo.contrast}`}
                </span>
              </div>

              <input
                type="range"
                min="-100"
                max="100"
                value={customizationInfo.contrast}
              />
            </div>
            <div className={styles.rangeContainer}>
              <div className={styles.label}>
                <span>{translate('saturation', language).toUpperCase()}</span>
                <span>{customizationInfo.saturation}</span>
              </div>

              <input
                type="range"
                min="-100"
                max="100"
                value={customizationInfo.saturation}
              />
            </div>
            <div className={styles.rangeContainer}>
              <div className={styles.label}>
                <span>{translate('color', language).toUpperCase()}</span>{' '}
                <span>
                  {customizationInfo.color <= 0
                    ? customizationInfo.color
                    : `+${customizationInfo.color}`}
                </span>
              </div>
              <input
                type="range"
                min={-180}
                max={180}
                step="1"
                value={parseInt(customizationInfo.color, 10)}
              />
            </div>
            {customizationInfo.flat_background && (
              <>
                <div className={` ${styles.backgroundColorLabel}`}>
                  <span>
                    {translate('backgroundColor', language).toUpperCase()}
                  </span>
                </div>
                <ColorPicker
                  color={customizationInfo.flat_background_color}
                  disableAlpha
                />
              </>
            )}
            {customizationInfo.text && (
              <>
                <div className={styles.observation}>
                  <div className={styles.label}>
                    {translate('observation', language).toUpperCase()}
                  </div>
                </div>
                <p>{customizationInfo.text}</p>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default PrintCustomization;
