import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import ListSelector from './ListSelector';
import Api from '../libs/Api';
import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';

import Loading from './Loading';
import ArrowIcon from '../assets/icons/Arrow';
import { registerUser } from '../store/actions/user';

import styles from './StaffForm.module.scss';
import { uploadNewFile } from '../libs/s3Upload';

export default function StaffForm({ close }) {
  const dispatch = useDispatch();
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const countrySelectorRef = useRef();
  const regionsSelectorRef = useRef();

  const [name, setName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedRegions, setSelectedRegions] = useState();
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [personalVariantCode, setPersonalVariantCode] = useState('');
  const [employeeFunction, setEmployeeFunction] = useState('');
  // const [vat, setVat] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [profileImage, setProfileImage] = useState([]);
  const [filePreview, setFilePreview] = useState();
  const [profileImageFile, setProfileImageFile] = useState();
  const [countries, setCountries] = useState([]);
  const [countrySelector, setCountrySelector] = useState(false);
  const [regions, setRegions] = useState([]);
  const [regionsSelector, setRegionsSelector] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      Api.getUser().then(res => {
        dispatch(registerUser(res));
      });
    };
  }, [dispatch]);

  useEffect(() => {
    Api.getCountries().then(res => {
      const formattedCountries = res.map(country => ({
        id: country.id,
        name: country.name,
        value: country.id
      }));
      setCountries(formattedCountries);
    });
    Api.getRegions().then(res => {
      const formattedRegions = res.map(region => ({
        id: region.id,
        name: region.name,
        value: region.id
      }));
      setRegions(formattedRegions);
    });
  }, []);

  useEffect(() => {
    setCountrySelector(false);
  }, [selectedCountry]);

  useOutsideClick(regionsSelectorRef, () => {
    setRegionsSelector(!regionsSelector);
  });

  useEffect(() => {
    const userData = user;

    if (userData) {
      setLoading(true);

      if (userData.name && userData.name.length) {
        setName(userData.name);
      }

      if (userData.role.length) {
        setEmployeeFunction(
          userData.role.map(role => translate(role, language).toString())
        );
      }

      if (userData.country && userData.country.length) {
        const country = countries.find(c => c.id === userData.country);

        if (country) {
          setSelectedCountry(country);
        }
      }

      if (userData.state && userData.state && userData.state.length) {
        const region = regions.find(r => r.id === userData.state);

        if (region) {
          setSelectedRegions(region);
        }
      }

      if (userData.address && userData.address.length) {
        setAddress(userData.address);
      }

      if (userData.email && userData.email.length) {
        setEmail(userData.email);
      }

      if (userData.phone && userData.phone.length) {
        setPhoneNumber(userData.phone);
      }

      if (userData.image && user.image.length) {
        setProfileImage(user.image);
      }

      if (userData.code && user.code.length) {
        setPersonalVariantCode(user.code)
      }

      setLoading(false);
    }
  }, [user, countries, regions, language]);

  const onSelectFile = event => {
    const file = event.currentTarget.files[0];
    let newFilePreview;

    const loadImagePreview = async f => {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(f);
        reader.onloadend = () => {
          newFilePreview = { name: f.name, src: reader.result };

          setFilePreview(newFilePreview);
        };
        // name - file - path
        await uploadNewFile(file.name, file, 'user')
        await Api.psdFileSuccess({
          user_id: user.id,
          path: `user/${file.name}`,
          file_field: 'avatar_image'
        });
      } catch (err) {
        // console.error(err);
      }
    };

    loadImagePreview(file);
    setProfileImageFile(file);
  };

  const onSubmitClientForm = () => {
    const userData = {};

    if (name && name.length) {
      userData.name = name;
    }

    if (selectedCountry && selectedCountry.id && selectedCountry.id.length) {
      userData.country = selectedCountry.id;
    }

    if (selectedRegions && selectedRegions.id && selectedRegions.id.length) {
      userData.state = selectedRegions.id;
    }

    if (address && address.length) {
      userData.address = address;
    }

    if (email && email.length) {
      userData.email = email;
    }

    if (phone && phone.length) {
      userData.phone = phone;
    }

    if(personalVariantCode && personalVariantCode.length) {
      userData.code = personalVariantCode;
    }

    if (personalVariantCode.length > 4) {
      toast(translate('personalCodeWarning', language), {
        type: 'warning'
      });
      return
    }
    setLoading(true);

    if (user.id) {
      userData.id = user.id;

      Api.updateUser(
        user.id,
        userData
      )
        .then(() => {
          if (!profileImageFile) {
            toast(translate('successUpdatingUser', language), {
              type: 'success'
            });
          } else {
            const formData = new FormData();

            formData.append('id', user.id);
            Api.updateUser(user.id, userData)
              .then(res => {
                toast(translate('successUpdatingUser', language), {
                  type: 'success'
                });
                dispatch(registerUser(res));
              })
              .catch(() => {
                toast(translate('failUpdatingUser', language), {
                  type: 'error'
                });
              })
              .finally(() => setLoading(false));
          }
        })
        .catch(() => {
          toast(translate('failUpdatingUser', language), {
            type: 'error'
          });
        })
        .finally(() => {
          if (!profileImageFile) {
            setLoading(false);
          }
        });
    }
  };

  const renderProfileImage = useCallback(() => {
    if (filePreview) {
      return <img src={filePreview.src} alt={filePreview.name} />;
    }

    if (profileImage && profileImage.length) {
      return <img src={profileImage} alt="Client Avatar" />;
    }

    if (name && name.length) {
      return (
        <span>
          {name
            .split(' ')
            .map(word => (word && word[0] ? word[0].toUpperCase() : ''))}
        </span>
      );
    }

    return null;
  }, [filePreview, profileImage, name]);

  return (
    <div className={styles.staffFormModal}>
      <header>
        <button className={styles.closeButton} type="button" onClick={close}>
          <div className={styles.backIcon}>
            <ArrowIcon color="white" />
          </div>
          {translate('myProfile', language)}
        </button>
      </header>
      <div className={styles.clientForm}>
        <div className={styles.clientFormHead}>
          <div className={styles.clientName}>{user.name}</div>
        </div>
        <hr className={styles.formHeaderSeparator} />
        <form>
          <section>
            <label htmlFor="nameField">
              {translate('name', language)}
              <input
                id="nameField"
                name="nameField"
                type="text"
                value={name}
                onChange={event => setName(event.currentTarget.value)}
              />
            </label>
            <label htmlFor="functionField">
              {translate('function', language)}
              <input
                id="functionField"
                name="functionField"
                type="function"
                value={employeeFunction}
                disabled
              />
            </label>
            <label htmlFor="emailField">
              {translate('email', language)}
              <input
                id="emailField"
                name="emailField"
                type="email"
                value={email}
                onChange={event => setEmail(event.currentTarget.value)}
              />
            </label>

            <label htmlFor="emailField">
              Codigo de variacao{/* {translate('email', language)} */}
              <input
                id="variantCode"
                name="variantCode"
                type="text"
                value={personalVariantCode}
                onChange={event => setPersonalVariantCode(event.currentTarget.value)}
              />
            </label>
          </section>
          <section>
            <label htmlFor="phoneField">
              {translate('phone', language)}
              <input
                id="phoneField"
                name="phoneField"
                type="text"
                value={phone}
                onChange={event => setPhoneNumber(event.currentTarget.value)}
              />
            </label>
            <label htmlFor="countryField">
              {translate('country', language)}
            </label>
            <div className={styles.selectorFieldContainer}>
              <div className={styles.locationSelectors}>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                  onClick={() => setCountrySelector(true)}
                >
                  {selectedCountry
                    ? selectedCountry.name
                    : translate('chooseCountry', language)}
                </button>
                {countrySelector && (
                  <span ref={countrySelectorRef}>
                    <ListSelector
                      items={countries}
                      selectLabel={translate('choose', language)}
                      value={selectedCountry}
                      search
                      onSelect={setSelectedCountry}
                    />
                  </span>
                )}
              </div>
              {selectedCountry && selectedCountry.value === 'BRA' && (
                <>
                  <label htmlFor="stateField" className={styles.sectionLabel}>
                    {translate('state', language)}
                  </label>
                  <div className={styles.selectorFieldContainer}>
                    <button
                      type="button"
                      className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                      onClick={() => setRegionsSelector(true)}
                    >
                      {selectedRegions
                        ? selectedRegions.name
                        : translate('all', language)}
                    </button>
                    {regionsSelector && (
                      <span ref={regionsSelectorRef}>
                        <ListSelector
                          items={regions}
                          selectLabel={translate('choose', language)}
                          value={selectedRegions}
                          search
                          onSelect={setSelectedRegions}
                        />
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>

            <label htmlFor="addressField">
              {translate('address', language)}
              <textarea
                id="addressField"
                name="addressField"
                value={address}
                onChange={event => setAddress(event.currentTarget.value)}
              />
            </label>
          </section>
          <section>
            <label htmlFor="imagemPerfilField">
              {translate('profileImage', language)}
              <div className={styles.profileImageContainer}>
                <div className={styles.imageContainer}>
                  <div className={styles.imagePlaceholder}>
                    {renderProfileImage()}
                  </div>
                  <input
                    id="imagemPerfilField"
                    name="imagemPerfilField"
                    type="file"
                    onChange={onSelectFile}
                  />
                </div>
                <div>
                  <span className={styles.imageInputInstructions}>
                    {translate('profileImageAdvice', language)}{' '}
                    {name
                      .split(' ')
                      .map(word =>
                        word && word[0] ? word[0].toUpperCase() : ''
                      )}
                  </span>
                  <button
                    className={styles.deleteImage}
                    type="button"
                    onClick={() => {
                      setProfileImage(null);
                      setFilePreview(null);
                    }}
                  >
                    {translate('delete', language)}
                  </button>
                </div>
              </div>
            </label>
          </section>
        </form>
        <button
          type="button"
          className={styles.defaultActionButton}
          onClick={onSubmitClientForm}
        >
          {translate('updateUser', language)}
        </button>
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading fixed />
        </div>
      )}
    </div>
  );
}

StaffForm.propTypes = {
  close: PropTypes.func.isRequired
};
