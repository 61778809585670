/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import PropTypes, { bool } from 'prop-types';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';

import styles from './AttendanceForm.module.scss';

import ListSelector from './ListSelector';
import SectionDivisor from './SectionDivisor';
import FormModal from './FormModal';
import CreateClientForm from './CreateClientForm';
import FormPresentations from './FormPresentations';
import FormPrints from './FormPrints';
import Loading from './Loading';
import FileSelector from './FileSelector';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


function AttendanceForm({ initialData }) {
  const history = useHistory();

  const [language] = useSelector(state => [state.settings.language]);
  const [user] = useSelector(state => [state.user]);

  const clientsSelectorRef = useRef();
  const typeSelectorRef = useRef();
  const countrySelectorRef = useRef();
  const regionsSelectorRef = useRef();
  const clientProfileSelectorRef = useRef();

  const [name, setName] = useState('');
  const [requiredName, setRequiredName] = useState(false);

  const [clientsSelector, setClientsSelector] = useState('closed');
  const [createClientModal, setCreateClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [requiredClient, setRequiredClient] = useState(false);

  const [typeSelector, setTypeSelector] = useState('closed');
  const [selectedType, setSelectedType] = useState();
  const [requiredType, setRequiredType] = useState(false);

  const [selectedDate, setSelectedDate] = useState();

  const [countrySelector, setCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [countries, setCountries] = useState([]);

  const [regionsSelector, setRegionsSelector] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [regions, setRegions] = useState([]);

  const [locations, setLocations] = useState([]);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [clientProfileSelector, setClientProfileSelector] = useState('closed');
  const [selectedClientProfiles, setSelectedClientProfiles] = useState([]);
  const [requiredClientProfiles, setRequiredClientProfiles] = useState(false);
  const [clientProfileOptions, setClientProfileOptions] = useState([]);

  const [selectedPresentations, setSelectedPresentations] = useState([]);
  const [selectedPrints, setSelectedPrints] = useState([]);
  const [presentationsModal, setPresentationsModal] = useState(false);
  const [printsModal, setPrintsModal] = useState(false);

  const [clients, setClients] = useState([]);
  const [attendanceCover, setAttendanceCover] = useState([]);

  const types = useMemo(
    () => [
      { id: 1, name: 'Colleción', value: 'COL' },
      { id: 2, name: 'ID', value: 'ID' },
      { id: 3, name: 'Le Collection', value: 'LE' },
      { id: 4, name: 'Matteria', value: 'MAT' }
    ],
    []
  );

  useEffect(() => {
    if (
      initialData &&
      !loaded &&
      clientProfileOptions.length &&
      countries.length &&
      regions.length &&
      clientProfileOptions.length &&
      clients.length
    ) {
      setName(initialData.name);
      setSelectedClient(clients.find(c => c.id === initialData.client));
      setSelectedType(types.find(t => t.value === initialData.type));

      let initialCountries = [];
      const initialRegions = [];
      const initialLocations = [];
      let selectableCountries = [...countries];

      const initialClientProfiles = [];
      if (initialData.customer_profile) {
        initialData.customer_profile.forEach(cp => {
          const profile = clientProfileOptions.find(opt => opt.id === cp);
          if (profile) {
            initialClientProfiles.push(profile);
          }
        });
      }
      setSelectedClientProfiles(initialClientProfiles);

      const nonUnique = initialData.selected_meetings.filter(
        sm => !sm.only_child
      );
      setSelectedPresentations(nonUnique.map(sm => sm.meeting));

      if (initialData.selected_prints && initialData.selected_prints.length) {
        const initialPrintsData = initialData.selected_prints.map(sp => ({
          name: sp.print_name,
          code: sp.print_code,
          id: sp.print_id,
          image_url: sp.print_image_url,
          flat_background_color: sp.flat_background_color
        }));

        const initialPrints = [];
        initialPrintsData.forEach(ipd => {
          if (!initialPrints.find(ip => ip.id === ipd.id)) {
            initialPrints.push(ipd);
          }
        });
        setSelectedPrints(initialPrints);
      }

      if (initialData.meeting_id) {
        setSelectedPresentations([
          {
            id: initialData.meeting_id,
            name: initialData.meeting_name,
            image: initialData.cover_url
          }
        ]);
      }

      if (initialData.meeting_box_location) {
        initialData.meeting_box_location.forEach(loc => {
          const currentCountry = countries.find(
            country => loc === country.value
          );
          if (currentCountry) {
            initialCountries.push(currentCountry);
          }
          const currentRegion = regions.find(region => loc === region.value);
          if (currentRegion) {
            initialRegions.push(currentRegion);
          }
        });
      }

      if (initialRegions.length) {
        initialCountries = initialCountries.filter(
          country => country.value !== 'BRA'
        );
        initialLocations.push({
          country: { id: 'BRA', name: 'Brasil', value: 'BRA' },
          regions: initialRegions
        });
      }

      initialCountries.forEach(country => {
        initialLocations.push({
          country,
          regions: []
        });

        selectableCountries = countries.filter(c => c.value !== country);
      });
      setLocations(initialLocations);
      setCountries(selectableCountries);
      if (initialData.meeting_box_date) {
        setSelectedDate(new Date(moment(initialData.meeting_box_date)));
      }

      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, clientProfileOptions, clients, types, countries, regions]);

  useEffect(() => {
    setPresentationsModal(false);
  }, [selectedPresentations]);

  useEffect(() => {
    setPrintsModal(false);
  }, [selectedPrints]);

  const changeClientProfileSelector = state => {
    if (state === 'closed') {
      setClientProfileSelector('opened');
    } else {
      setClientProfileSelector('closed');
    }
  };
  useOutsideClick(clientProfileSelectorRef, () => {
    changeClientProfileSelector(clientProfileSelector);
  });

  useEffect(() => {
    setRequiredName(false);
  }, [name]);

  useEffect(() => {
    setRequiredType(false);
  }, [selectedType]);

  useEffect(() => {
    setRequiredClientProfiles(false);
  }, [selectedClientProfiles]);

  useEffect(() => {
    setClientsSelector('closed');
    setCountrySelector(false);
  }, [selectedClient, selectedCountry]);

  useEffect(() => {
    Api.getCustomerProfiles().then(profiles => {
      setClientProfileOptions(profiles);
    });
    Api.getCountries().then(res => {
      const formattedCountries = res.map(country => ({
        id: country.id,
        name: country.name,
        value: country.id
      }));
      setCountries(formattedCountries);
    });
    Api.getRegions().then(res => {
      const formattedRegions = res.map(region => ({
        id: region.id,
        name: region.name,
        value: region.id
      }));
      setRegions(formattedRegions);
    });
  }, []);

  useEffect(() => {
    (async function loadClients() {
      try {
        const getClients = await Api.getClients();
        setClients(getClients);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, []);

  const addLocation = () => {
    const newLocation = {
      country: selectedCountry,
      regions: selectedRegions
    };
    const updatedLocations = [...locations];
    updatedLocations.push(newLocation);

    const updatedCountries = countries.filter(
      country => country.value !== selectedCountry.value
    );

    setLocations(updatedLocations);
    setCountries(updatedCountries);
    setSelectedCountry();
    setSelectedRegions([]);
  };

  const removeLocation = countryId => {
    const updatedCountries = [...countries];
    const removedLocation = locations.find(
      location => location.country.value === countryId
    ).country;

    updatedCountries.push(removedLocation);

    const updatedLocations = locations.filter(
      location => location.country.id !== countryId
    );

    setLocations(updatedLocations);

    const compare = (a, b) => {
      const countryA = a.value.toUpperCase();
      const countryB = b.value.toUpperCase();

      let comparison = 0;
      if (countryA > countryB) {
        comparison = 1;
      } else if (countryA < countryB) {
        comparison = -1;
      }
      return comparison;
    };

    updatedCountries.sort(compare);

    setCountries(updatedCountries);
  };

  const changeClientsSelector = state => {
    if (state === 'closed') {
      setClientsSelector('opened');
    } else {
      setClientsSelector('closed');
    }
  };

  useOutsideClick(clientsSelectorRef, () => {
    changeClientsSelector(clientsSelector);
  });

  useOutsideClick(countrySelectorRef, () => {
    setCountrySelector(!countrySelector);
  });

  useOutsideClick(regionsSelectorRef, () => {
    setRegionsSelector(!regionsSelector);
  });

  const changeTypeSelector = state => {
    if (state === 'closed') {
      setTypeSelector('opened');
    } else {
      setTypeSelector('closed');
    }
  };

  useOutsideClick(typeSelectorRef, () => {
    changeTypeSelector(typeSelector);
  });

  const openCreateClient = () => {
    setCreateClientModal(true);
    setClientsSelector('closed');
  };

  const createdClient = async cli => {
    setCreateClientModal(false);
    setLoading(true);
    const getClients = await Api.getClients();
    setClients(getClients);
    setSelectedClient(cli);
    setLoading(false);
  };

  const isFormValid = () => {
    let valid = true;

    if (!name) {
      valid = false;
      setRequiredName(true);
    }

    if (!selectedType) {
      valid = false;
      setRequiredType(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        type: 'warning'
      });
    }

    return valid;
  };

  const createAttendance = e => {
    e.preventDefault();
    if (isFormValid()) {
      setSaving(true);
      let newCountries = [];
      let newRegions = [];

      const clientProfileValues = selectedClientProfiles.map(
        profile => profile.id
      );

      const updatedLocations = [...locations];
      if (selectedCountry) {
        const newLocation = {
          country: selectedCountry,
          regions: selectedRegions
        };
        updatedLocations.push(newLocation);
      }

      if (updatedLocations) {
        let formattedCountries = updatedLocations.map(loc => loc.country.value);
        if (formattedCountries.find(country => country === 'BRA')) {
          formattedCountries = formattedCountries.filter(
            country => country !== 'BRA'
          );
          formattedCountries = ['BRA', ...formattedCountries];
        }
        newCountries = formattedCountries;

        const formattedRegions = [];
        updatedLocations.forEach(loc => {
          formattedRegions.push(...loc.regions.map(reg => reg.value));
        });

        newRegions = formattedRegions;
      }
      let currentSelectedPresentations = [];
      if (
        selectedPresentations.length &&
        initialData &&
        initialData.selected_meetings === 1 &&
        initialData.selected_meetings[0].only_child
      ) {
        currentSelectedPresentations = selectedPresentations.map(p => p.id);

        currentSelectedPresentations.push(
          initialData.selected_meetings[0].meeting.id
        );
      } else {
        currentSelectedPresentations = selectedPresentations.map(p => p.id);
      }

      const currentSelectedPrints = selectedPrints.map(p => p.id);

      if (initialData) {
        const formData = new FormData()
        formData.append('name', name);
        if(attendanceCover.length) {
          formData.append('cover', attendanceCover[0]);
        }
        if(selectedClient) {
          formData.append('client', selectedClient ? selectedClient.id : null);
        }
        formData.append('type', selectedType.value);
        if(selectedDate) {
          formData.append('meeting_box_date', moment(selectedDate).format('YYYY-MM-DD'));
        }
        if(selectedCountry) {
          formData.append('meeting_box_location', [...newCountries, ...newRegions]);
        }
        clientProfileValues.map(profile => formData.append('customer_profile', profile))
        formData.append('meetings_ids', currentSelectedPresentations);
        formData.append('print_ids', currentSelectedPrints.length ? currentSelectedPrints : null);
        Api.updateAttendance(initialData.id, formData)
          .then(() => {
            toast(translate('successUpdatingAttendance', language), {
              type: 'success'
            });
            history.push(`/attendances/${initialData.id}`);
          })
          .catch(() => {
            toast(translate('failUpdatingAttendance', language), {
              type: 'error'
            });
          })
          .finally(() => {
            setSaving(false);
          });
      } else {
        const formData = new FormData()
        formData.append('name', name);
        if(attendanceCover.length) {
          formData.append('cover', attendanceCover[0]);
        }
        if(selectedClient) {
          formData.append('client', selectedClient ? selectedClient.id : null);
        }
        formData.append('type', selectedType.value);
        if(selectedDate) {
          formData.append('meeting_box_date', moment(selectedDate).format('YYYY-MM-DD'));
        }
        if(selectedCountry) {
          formData.append('meeting_box_location', [...newCountries, ...newRegions]);
        }
        if(clientProfileValues) {
          clientProfileValues.map(profile => formData.append('customer_profile', profile))
        }
        
        formData.append('meetings_ids', currentSelectedPresentations);
        formData.append('print_ids', currentSelectedPrints.length ? currentSelectedPrints : null);
        Api.createAttendance(formData)
          .then(res => {
            toast(translate('successCreatingAttendance', language), {
              type: 'success'
            });
            history.push(`/attendances/${res.id}`);
          })
          .catch(() => {
            toast(translate('failCreatingAttendance', language), {
              type: 'error'
            });
          })
          .finally(() => {
            setSaving(false);
          });
      }
    }
  };

  
  
  const selectedProfiles = useMemo(() => {
    if (!selectedClientProfiles.length) {
      return translate('chooseClientProfile', language);
    }
    return selectedClientProfiles.map((profile, index) => {
      if (index === selectedClientProfiles.length - 1) {
        return profile.name;
      }
      return `${profile.name}, `;
    });
  }, [language, selectedClientProfiles]);

  const removePresentation = useCallback(
    presentationId => {
      const updatedPresentations = selectedPresentations.filter(
        p => p.id !== presentationId
      );
      setSelectedPresentations(updatedPresentations);
    },
    [selectedPresentations]
  );

  const addSelectedPresentations = presentations => {
    const currentSelectedPresentation = [...selectedPresentations];

    presentations.forEach(p => {
      if (!currentSelectedPresentation.find(pres => pres.id === p.id)) {
        currentSelectedPresentation.push(p);
      }
    });
    setSelectedPresentations(currentSelectedPresentation);
  };

  const removePrint = useCallback(
    printId => {
      const updatedPrints = selectedPrints.filter(p => p.id !== printId);
      setSelectedPrints(updatedPrints);
    },
    [selectedPrints]
  );

  const addSelectedPrints = prints => {
    const currentSelectedPrint = [...selectedPrints];

    prints.forEach(p => {
      if (!currentSelectedPrint.find(pres => pres.id === p.id)) {
        currentSelectedPrint.push(p);
      }
    });
    setSelectedPrints(currentSelectedPrint);
  };

  const handleOnDragPresentation = (result) => {
    const updatedPresentations = [...selectedPresentations]
    const [reorderedPresentations] = updatedPresentations.splice(result.source.index, 1);
    updatedPresentations.splice(result.destination.index, 0, reorderedPresentations);
    setSelectedPresentations(updatedPresentations);
  }

  const handleOnDragPrints = (result) => {
    const updatedPrints = [...selectedPrints]
    const [reorderedPrints] = updatedPrints.splice(result.source.index, 1);
    updatedPrints.splice(result.destination.index, 0, reorderedPrints);
    setSelectedPrints(updatedPrints);
  }

  return (
    <form className={styles.form} onSubmit={createAttendance}>
      <div className={styles.simpleInputContainer}>
        <input
          className={`${styles.defaultTextInput} ${
            requiredName ? styles.error : ''
          }`}
          placeholder={translate('attendanceName', language)}
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          name="name"
        />
        {requiredName && (
          <span className={styles.errorMessage}>
            {translate('requiredField', language)}
          </span>
        )}
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <p className={styles.sectionLabel}>{translate('client', language)}</p>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={`${styles.defaultSelectInput}`}
              onClick={() => setClientsSelector('opened')}
            >
              {selectedClient
                ? selectedClient.name
                : translate('chooseClient', language)}
            </button>
            {clientsSelector === 'opened' && (
              <span ref={clientsSelectorRef}>
                <ListSelector
                  label={translate('clients', language)}
                  selectLabel={translate('choose', language)}
                  items={clients}
                  onSelect={setSelectedClient}
                  value={selectedClient}
                  action={openCreateClient}
                  actionText={translate('createClient', language)}
                  search
                />
              </span>
            )}
            {requiredClient && (
              <span className={styles.errorMessage}>
                {translate('requiredClient', language)}
              </span>
            )}
          </div>
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <p className={styles.sectionLabel}>{translate('type', language)}</p>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={`${styles.defaultSelectInput} ${
                requiredType ? styles.error : ''
              }`}
              onClick={() => setTypeSelector('opened')}
            >
              {selectedType
                ? selectedType.name
                : translate('chooseType', language)}
            </button>
            {typeSelector === 'opened' && (
              <span ref={typeSelectorRef}>
                <ListSelector
                  label={translate('type', language)}
                  selectLabel={translate('choose', language)}
                  items={types}
                  onSelect={setSelectedType}
                  value={selectedType}
                />
              </span>
            )}
            {requiredType && (
              <span className={styles.errorMessage}>
                {translate('requiredField', language)}
              </span>
            )}
          </div>
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <p className={styles.sectionLabel}>
            {translate('clientProfile', language)}
          </p>
          <div className={styles.selectorContainer}>
            <button
              type="button"
              className={`${styles.defaultSelectInput} ${
                requiredClientProfiles ? styles.error : ''
              }`}
              onClick={() => setClientProfileSelector('opened')}
            >
              {selectedProfiles}
            </button>
            {clientProfileSelector === 'opened' && (
              <span ref={clientProfileSelectorRef}>
                <ListSelector
                  items={clientProfileOptions}
                  onSelect={setSelectedClientProfiles}
                  selectLabel={translate('choose', language)}
                  multiple
                  values={selectedClientProfiles}
                  search
                />
              </span>
            )}
          </div>
          {requiredClientProfiles && (
            <span className={styles.errorMessage}>
              {translate('requiredField', language)}
            </span>
          )}
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <FileSelector 
        files={attendanceCover}
        label={translate('uploadAttendenceCover', language)}
        onSelect={setAttendanceCover}
        jpg
      />
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <button
            className={styles.addPresentations}
            type="button"
            onClick={() => setPresentationsModal(true)}
            disabled={
              (selectedPrints.length && !initialData.meeting_id) ||
              (!initialData && selectedPrints.length)
            }
          >
            {translate('addPresentations', language)}
          </button>
        </div>

        <div className={styles.rightInputGroup}>
          <button
            disabled={
              selectedPresentations.length > 1 ||
              (selectedPresentations === 1 &&
                initialData &&
                initialData.meeting_id) ||
              (!initialData && selectedPresentations.length)
            }
            className={styles.addPrints}
            type="button"
            onClick={() => setPrintsModal(true)}
          >
            {translate('addPrints', language)}
          </button>
        </div>
      </div>
      <div className={styles.simpleInputContainer}>
        <DragDropContext onDragEnd={handleOnDragPresentation}>
          <Droppable droppableId='Presentations'>
            {(provided) => (
            <ul className={styles.selectedPresentations} {...provided.droppableProps} ref={provided.innerRef}>
              {selectedPresentations.length > 1 &&
                initialData &&
                initialData.meeting_id && (
                  <Draggable>
                    <li>
                      <div
                        className={styles.image}
                        style={{
                          backgroundColor:
                            initialData.cover_flat_background_color || '#4b5768'
                        }}
                      >
                        {initialData.cover_url && (
                          <img src={initialData.cover_url} alt="print" />
                        )}
                      </div>

                      <div>
                        <strong>{initialData.meeting_name}</strong>
                        {/* <button
                          type="button"
                          className={styles.delete}
                          onClick={() => removePresentation(initialData.meeting_id)}
                        >
                          {translate('remove', language)}
                        </button> */}
                      </div>
                    </li>
                  </Draggable>
                )}
              {selectedPresentations.map((sp, index )=> {
                if (
                  (initialData && initialData.meeting_id !== sp.id) ||
                  !initialData
                ) {
                  return (
                    <Draggable key={sp.id} draggableId={String(sp.id)} index={index}>
                      {(provided) => (
                      <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <div
                          className={styles.image}
                          style={{
                            backgroundColor:
                              sp.cover_flat_background_color || '#4b5768'
                          }}
                        >
                          {sp.image && <img src={sp.image} alt="print" />}
                        </div>
                        <div>
                          <strong>{sp.name}</strong>
                          <button
                            type="button"
                            className={styles.delete}
                            onClick={() => removePresentation(sp.id)}
                          >
                            {translate('remove', language)}
                          </button>
                        </div>
                      </li>
                      )}
                    </Draggable>
                  );
                }
                return null;
              })}
              {provided.placeholder}
            </ul>
            )}
          </Droppable>
        </DragDropContext>
        <DragDropContext onDragEnd={handleOnDragPrints}>
          <Droppable droppableId='Prints'>
            {(provided) => (
            <ul className={styles.selectedPrints} {...provided.droppableProps} ref={provided.innerRef}>
              {((initialData.meeting_id && selectedPresentations.length === 1) ||
                !initialData) &&
                selectedPrints.map((sp, index) => (
                  <Draggable key={sp.id} draggableId={String(sp.id)} index={index}>
                    {(provided) => (
                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}> 
                      <div
                        className={styles.image}
                        style={{
                          backgroundColor: sp.flat_background_color || '#4b5768'
                        }}
                      >
                        {sp.image_url && <img src={sp.image_url} alt="print" />}
                      </div>
                      <div>
                        <strong>{sp.code}</strong>
                        <button
                          type="button"
                          className={styles.delete}
                          onClick={() => removePrint(sp.id)}
                        >
                          {translate('remove', language)}
                        </button>
                      </div>
                    </li>)}
                  </Draggable>
                ))}
                {provided.placeholder}
            </ul>)}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={styles.doubleInputContainer}>
        <div className={styles.leftInputGroup}>
          <div className={styles.locations}>
            {locations.map(location => (
              <div key={location.country.value}>
                <div className={styles.location}>
                  <div>{location.country.name}</div>
                  {location.regions.length > 0 && (
                    <div>
                      {location.regions.map(region => `${region.name}; `)}
                    </div>
                  )}
                </div>

                <button
                  type="button"
                  className={styles.delete}
                  onClick={() => removeLocation(location.country.id)}
                >
                  {translate('remove', language)}
                </button>
              </div>
            ))}
          </div>
          <p className={styles.sectionLabel}>
            {translate('country', language)}
          </p>
          <div className={styles.locationSelectors}>
            <div className={styles.selectorContainer}>
              <button
                type="button"
                className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                onClick={() => setCountrySelector(true)}
              >
                {selectedCountry
                  ? selectedCountry.name
                  : translate('chooseCountry', language)}
              </button>

              {countrySelector && (
                <span ref={countrySelectorRef}>
                  <ListSelector
                    items={countries}
                    onSelect={setSelectedCountry}
                    selectLabel={translate('choose', language)}
                    value={selectedCountry}
                    search
                  />
                </span>
              )}
            </div>

            {selectedCountry && selectedCountry.value === 'BRA' && (
              <div className={styles.selectorContainer}>
                <p className={styles.sectionLabel}>
                  {translate('clientRegion', language)}
                </p>
                <button
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.regionInput}`}
                  onClick={() => setRegionsSelector(true)}
                >
                  {selectedRegions.length
                    ? selectedRegions.map(region => `${region.name}; `)
                    : translate('all', language)}
                </button>

                {regionsSelector && (
                  <span ref={regionsSelectorRef}>
                    <ListSelector
                      items={regions}
                      onSelect={setSelectedRegions}
                      selectLabel={translate('choose', language)}
                      multiple
                      values={selectedRegions}
                      search
                    />
                  </span>
                )}
              </div>
            )}
            {selectedCountry && (
              <button
                type="button"
                className={styles.addLocation}
                onClick={addLocation}
              >
                {translate('addLocation', language)}
              </button>
            )}
          </div>
        </div>
        <div className={styles.rightInputGroup} />
      </div>
      <div className={styles.simpleInputContainer}>
        <p className={styles.sectionLabel}>
          {translate('attendanceDate', language)}
        </p>

        <div className={styles.attendanceDate}>
          <DatePicker
            className={`${styles.dateInput}`}
            value={selectedDate}
            onChange={setSelectedDate}
            format="dd/MM/y"
            clearIcon={null}
            calendarIcon={null}
          />
          <div className={styles.group}>
            <div className={styles.numbers}>
              <span>
                {selectedDate ? moment(selectedDate).format('DD')[0] : 'D'}
              </span>
              <span>
                {selectedDate ? moment(selectedDate).format('DD')[1] : 'D'}
              </span>
            </div>
            <div>{translate('day', language)}</div>
          </div>
          <div className={styles.group}>
            <div className={styles.numbers}>
              <span>
                {selectedDate ? moment(selectedDate).format('MM')[0] : 'M'}
              </span>
              <span>
                {selectedDate ? moment(selectedDate).format('MM')[1] : 'M'}
              </span>
            </div>
            <div>{translate('month', language)}</div>
          </div>
          <div className={styles.group}>
            <div className={styles.numbers}>
              <span>
                {selectedDate
                  ? moment(selectedDate).format('Y')[2]
                  : `${language === 'en' ? 'Y' : 'A'} `}
              </span>
              <span>
                {selectedDate
                  ? moment(selectedDate).format('Y')[3]
                  : `${language === 'en' ? 'Y' : 'A'} `}
              </span>
            </div>
            <div>{translate('year', language)}</div>
          </div>
        </div>
      </div>
      <SectionDivisor />
      <button
        className={`${styles.defaultActionButton} ${styles.save}`}
        type="submit"
        disabled={saving}
      >
        {translate('saveChanges', language)}
      </button>
      {createClientModal && (
        <FormModal onCancel={() => setCreateClientModal(false)}>
          <CreateClientForm
            onSuccess={createdClient}
            title={translate('createClientTitle', language)}
          />
        </FormModal>
      )}
      {(loading || saving) && <Loading />}
      {presentationsModal && (
        <FormModal onCancel={() => setPresentationsModal(false)} spaced>
          <FormPresentations applyPresentations={addSelectedPresentations} />
        </FormModal>
      )}
      {printsModal && (
        <FormModal onCancel={() => setPrintsModal(false)} spaced>
          <FormPrints applyPrints={addSelectedPrints} />
        </FormModal>
      )}
    </form>
  );
}

AttendanceForm.propTypes = {
  initialData: PropTypes.oneOfType([bool, PropTypes.shape])
};

AttendanceForm.defaultProps = {
  initialData: false
};

export default AttendanceForm;
