import React from "react";
import Masonry from 'react-masonry-css';
import './styles.css'
export function MasonryGrid( { children }: any ) {
    const breakpointColumnsObj = {
        default: 4,
        1200: 3,
        992: 3,
        768: 2,
        576: 1,
    };
        return(
            <Masonry
                className="masonryGrid" 
                columnClassName="masonryGridColumn" 
                breakpointCols={breakpointColumnsObj}
            >
                {children}
            </Masonry>
        )
}