import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ChromePicker as ColorPicker } from 'react-color';

import translate from '../libs/i18n';

import Reset from '../assets/icons/Reset';
import Save from '../assets/icons/Save';
import ArrowIcon from '../assets/icons/Arrow';

import styles from './CustomizePrintAndDoll.module.scss';

export default function CustomizePrintAndDoll({
  print,
  scale,
  setScale,
  rotate,
  setRotate,
  brightness,
  setBrightness,
  contrast,
  setContrast,
  saturation,
  setSaturation,
  color,
  setColor,
  backgroundColor,
  setBackgroundColor,
  handleResetPrint,
  customizePrint,
  dollMask,
  dollPosXAdjustment,
  handleChangePosXAdjustments,
  dollPosYAdjustment,
  handleChangePosYAdjustments,
  dollRotateAdjustment,
  handleChangeRotateAdjustments,
  handleSavePrintCopy,
  handleSavePrint,
  isCustomized,
  closeCustomize,
  customizeMask,
  // handleClickStopEdit,
  handleSubmitDollyAdjustment,
  handleResetMask
}) {
  const language = useSelector(state => state.settings.language);

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [printName, setPrintName] = useState('');
  const [printCode, setPrintCode] = useState('');

  useEffect(() => {
    setPrintName(print.print_name || print.name || '');
    setPrintCode(print.print_code || print.code || '');
  }, [print]);

  return (
    <div className={styles.CustomizePrintAndDoll}>
      <button className={styles.goBack} type="button" onClick={closeCustomize}>
        <div className={styles.backIcon}>
          <ArrowIcon color="black" />
        </div>
        {translate('goBack', language)}
      </button>
      <h3 className={styles.printName}>
        <b>{customizePrint ? 'Customização' : 'Localização'}</b>
        {printName && printName.length ? ` - ${printName}` : ''}
      </h3>
      {printCode && setPrintCode.length ? (
        <h4 className={styles.printCode}>{printCode}</h4>
      ) : null}
      <div className={styles.actionsWrapper}>
        {isCustomized && !customizeMask && (
          <button
            className={styles.savePrint}
            type="button"
            onClick={handleSavePrint}
          >
            <span className={styles.savePrintIcon}>
              <Save />
            </span>
            Salvar
          </button>
        )}
        {customizeMask && (
          <button
            className={styles.savePrint}
            type="button"
            onClick={handleSubmitDollyAdjustment}
          >
            <span className={styles.savePrintIcon}>
              <Save />
            </span>
            Salvar
          </button>
        )}
        {isCustomized && !customizeMask && (
          <button
            className={styles.savePrint}
            type="button"
            onClick={handleSavePrintCopy}
          >
            <span className={styles.savePrintIcon}>
              <Save />
            </span>
            Salvar cópia
          </button>
        )}
        {isCustomized && customizePrint && (
          <button
            className={styles.resetPrint}
            type="button"
            onClick={handleResetPrint}
          >
            <span className={styles.resetPrintIcon}>
              <Reset />
            </span>
            Resetar
          </button>
        )}
        {customizeMask && (
          <button
            className={styles.resetPrint}
            type="button"
            onClick={handleResetMask}
          >
            <span className={styles.resetPrintIcon}>
              <Reset />
            </span>
            Resetar
          </button>
        )}
      </div>
      {customizePrint ? (
        <div className={styles.customizePrint}>
          <div className={styles.rangeList}>
            <div className={styles.rangeElement}>
              <div className={styles.rangeElementLabel}>
                <span className={styles.rangeElementLabelName}>
                  {translate('scale', language)}
                </span>{' '}
                <span>{`${scale}%`}</span>
              </div>
              <input
                type="range"
                min={-90}
                max={100}
                step={10}
                value={scale}
                onChange={event => setScale(event.currentTarget.value)}
              />
            </div>
            <div className={styles.rangeElement}>
              <div className={styles.rangeElementLabel}>
                <span className={styles.rangeElementLabelName}>
                  {translate('rotate', language)}
                </span>{' '}
                <span>{`${rotate}`}&deg;</span>
              </div>
              <input
                type="range"
                min={-270}
                max={270}
                step={90}
                value={rotate}
                onChange={event => setRotate(event.currentTarget.value)}
              />
            </div>
            <div className={styles.rangeElement}>
              <div className={styles.rangeElementLabel}>
                <span className={styles.rangeElementLabelName}>
                  {translate('luminosity', language)}
                </span>{' '}
                <span>{`${parseInt(Number(brightness * 100), 10)}%`}</span>
              </div>
              <input
                type="range"
                min={-1}
                max={1}
                step={0.05}
                value={brightness}
                onChange={event => setBrightness(event.currentTarget.value)}
              />
            </div>
            <div className={styles.rangeElement}>
              <div className={styles.rangeElementLabel}>
                <span className={styles.rangeElementLabelName}>
                  {translate('contrast', language)}
                </span>{' '}
                <span>{`${contrast}%`}</span>
              </div>
              <input
                type="range"
                min={-100}
                max={100}
                step={1}
                value={contrast}
                onChange={event => setContrast(event.currentTarget.value)}
              />
            </div>
            <div className={styles.rangeElement}>
              <div className={styles.rangeElementLabel}>
                <span className={styles.rangeElementLabelName}>
                  {translate('saturation', language)}
                </span>{' '}
                <span>{`${saturation}%`}</span>
              </div>
              <input
                type="range"
                min={-100}
                max={100}
                step={1}
                value={saturation}
                onChange={event => setSaturation(event.currentTarget.value)}
              />
            </div>
            <div className={styles.rangeElement}>
              <div className={styles.rangeElementLabel}>
                <span className={styles.rangeElementLabelName}>
                  {translate('color', language)}
                </span>{' '}
                <span>{`${color}%`}</span>
              </div>
              <input
                type="range"
                min={-100}
                max={100}
                step={1}
                value={color}
                onChange={event => setColor(event.currentTarget.value)}
              />
            </div>
          </div>
          {print && print.flat_background ? (
            <div className={styles.printBackgroundColor}>
              <div className={` ${styles.printBackgroundColorLabel}`}>
                <span>{translate('backgroundColor', language)}</span>
              </div>
              <div className={styles.inputColorWrapper}>
                <input
                  className={styles.inputColor}
                  type="text"
                  value={backgroundColor || print.flat_background_color}
                  onChange={event => {
                    setBackgroundColor(event.currentTarget.value);
                  }}
                />
                <button
                  type="button"
                  className={styles.colorBox}
                  style={{
                    background:
                      backgroundColor ||
                      print.flat_background_color ||
                      '#ffffff'
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                />
              </div>
              {showColorPicker ? (
                <div className={styles.colorPickerWrapper}>
                  <ColorPicker
                    disableAlpha
                    color={backgroundColor || print.flat_background_color}
                    onChange={newColor => setBackgroundColor(newColor.hex)}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {!customizePrint && customizeMask && dollMask.length >= 1 ? (
        <div className={styles.customizeMask}>
          <div className={styles.rangeList}>
            {dollMask.map((mask, index) => (
              <>
                <div className={styles.adjustmentsLabel}>
                  {`MÁSCARA ${index + 1}`}
                </div>
                <div className={styles.rangeElement}>
                  <div className={styles.rangeElementLabel}>
                    <span className={styles.rangeElementLabelName}>
                      Mover na horizontal
                    </span>{' '}
                    <span>{`${dollPosXAdjustment[index]}%`}</span>
                  </div>
                  <input
                    type="range"
                    min={-500}
                    max={500}
                    step={1}
                    value={parseInt(dollPosXAdjustment[index], 10)}
                    onChange={event =>
                      handleChangePosXAdjustments(
                        event.currentTarget.value,
                        index
                      )
                    }
                  />
                </div>
                <div className={styles.rangeElement}>
                  <div className={styles.rangeElementLabel}>
                    <span className={styles.rangeElementLabelName}>
                      Mover na vertical
                    </span>{' '}
                    <span>{`${dollPosYAdjustment[index]}%`}</span>
                  </div>
                  <input
                    type="range"
                    min={-500}
                    max={500}
                    step={1}
                    value={parseInt(dollPosYAdjustment[index], 10)}
                    onChange={event =>
                      handleChangePosYAdjustments(
                        event.currentTarget.value,
                        index
                      )
                    }
                  />
                </div>
                <div className={styles.rangeElement}>
                  <div className={styles.rangeElementLabel}>
                    <span className={styles.rangeElementLabelName}>
                      Rotacionar
                    </span>{' '}
                    <span>{`${dollRotateAdjustment[index]}°`}</span>
                  </div>
                  <input
                    type="range"
                    min={-180}
                    max={180}
                    step={1}
                    value={parseInt(dollRotateAdjustment[index], 10)}
                    onChange={event =>
                      handleChangeRotateAdjustments(
                        event.currentTarget.value,
                        index
                      )
                    }
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      ) : null}
      {!customizePrint && customizeMask && dollMask.length < 1 ? (
        <div className={styles.customizeMask}>
          <div className={styles.rangeList}>
            <>
              <div className={styles.adjustmentsLabel}>MÁSCARA 1</div>
              <div className={styles.rangeElement}>
                <div className={styles.rangeElementLabel}>
                  <span className={styles.rangeElementLabelName}>
                    Mover na horizontal
                  </span>{' '}
                  <span>{`${dollPosXAdjustment[0]}%`}</span>
                </div>
                <input
                  type="range"
                  min={-500}
                  max={500}
                  step={1}
                  value={parseInt(dollPosXAdjustment[0], 10)}
                  onChange={event =>
                    handleChangePosXAdjustments(event.currentTarget.value, 0)
                  }
                />
              </div>
              <div className={styles.rangeElement}>
                <div className={styles.rangeElementLabel}>
                  <span className={styles.rangeElementLabelName}>
                    Mover na vertical
                  </span>{' '}
                  <span>{`${dollPosYAdjustment[0]}%`}</span>
                </div>
                <input
                  type="range"
                  min={-500}
                  max={500}
                  step={1}
                  value={parseInt(dollPosYAdjustment[0], 10)}
                  onChange={event =>
                    handleChangePosYAdjustments(event.currentTarget.value, 0)
                  }
                />
              </div>
            </>
          </div>
        </div>
      ) : null}
    </div>
  );
}

CustomizePrintAndDoll.propTypes = {
  print: PropTypes.shape.isRequired,
  scale: PropTypes.number.isRequired,
  setScale: PropTypes.func.isRequired,
  rotate: PropTypes.number.isRequired,
  setRotate: PropTypes.func.isRequired,
  brightness: PropTypes.number.isRequired,
  setBrightness: PropTypes.func.isRequired,
  contrast: PropTypes.number.isRequired,
  setContrast: PropTypes.func.isRequired,
  saturation: PropTypes.number.isRequired,
  setSaturation: PropTypes.func.isRequired,
  color: PropTypes.number.isRequired,
  setColor: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  setBackgroundColor: PropTypes.func.isRequired,
  handleResetPrint: PropTypes.func.isRequired,
  customizePrint: PropTypes.bool,
  dollMask: PropTypes.arrayOf(PropTypes.shape),
  dollPosXAdjustment: PropTypes.arrayOf(PropTypes.shape),
  handleChangePosXAdjustments: PropTypes.func.isRequired,
  dollPosYAdjustment: PropTypes.arrayOf(PropTypes.shape),
  handleChangePosYAdjustments: PropTypes.func.isRequired,
  dollRotateAdjustment: PropTypes.arrayOf(PropTypes.shape),
  handleChangeRotateAdjustments: PropTypes.func.isRequired,
  handleSavePrintCopy: PropTypes.func.isRequired,
  handleSavePrint: PropTypes.func.isRequired,
  closeCustomize: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  customizeMask: PropTypes.bool,
  isCustomized: PropTypes.bool,
  // handleClickStopEdit: PropTypes.func.isRequired,
  handleSubmitDollyAdjustment: PropTypes.func.isRequired,
  handleResetMask: PropTypes.func.isRequired
};

CustomizePrintAndDoll.defaultProps = {
  backgroundColor: '',
  customizePrint: true,
  dollMask: [],
  dollPosXAdjustment: [],
  dollPosYAdjustment: [],
  dollRotateAdjustment: [],
  isCustomized: false,
  closeCustomize: false,
  customizeMask: false
};
