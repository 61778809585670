import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import styles from './Attendance.module.scss';

import PresentationGridItem from '../components/PresentationGridItem';

function Attendance() {
  const language = useSelector(state => state.settings.language);
  const attendance = useSelector(state => state.loggedClient);

  const history = useHistory();

  const { attendanceId } = useParams();

  const filteredPresentations = useMemo(() => {
    if (attendance && attendance.selected_meetings) {
      return attendance.selected_meetings;
    }
    return [];
  }, [attendance]);

  const renderMissingCardSpaces = useMemo(() => {
    if (filteredPresentations.length) {
      const numberOfEmptyCards = filteredPresentations.length % 4;
      const emptyCards = [];

      for (let i = 1; i <= numberOfEmptyCards; i += 1) {
        emptyCards.push(i);
      }

      return emptyCards.map(i => <div key={i} className={styles.emptyCard} />);
    }
    return false;
  }, [filteredPresentations]);

  useEffect(() => {
    if (
      attendance &&
      attendance.main_meeting &&
      attendance.meeting_box_id === Number(attendanceId)
    ) {
      Api.getConcept(attendance.main_meeting.meeting_id).then(res => {
        if (res.results) {
          history.push(`/client/${attendance.main_meeting.meeting_id}/concept`);
        } else {
          history.push(`/client/${attendance.main_meeting.meeting_id}/prints`);
        }
      });
    }
  }, [attendance, attendanceId, history]);

  const renderContent = useMemo(() => {
    return (
      <div className={styles.content}>
        {attendance && (
          <div className={styles.mainInfo}>
            <h2 className={styles.attendanceTitle}>
              {translate('attendance', language)}
            </h2>
            <h1 className={styles.title}>{attendance.meeting_box_name}</h1>
          </div>
        )}

        <div className={styles.attendancesGrid}>
          {filteredPresentations.map(pres => (
            <PresentationGridItem
              name={pres.meeting && pres.meeting.name}
              image={pres.meeting && pres.meeting.print_image_url}
              id={pres.meeting && pres.meeting.id}
              type={pres.meeting && pres.meeting.type}
              small
              fromAttendance={attendance.meeting_box_id}
              client
            />
          ))}
          {renderMissingCardSpaces}
        </div>
      </div>
    );
  }, [
    attendance,
    language,
    filteredPresentations,
    renderMissingCardSpaces,
    history
  ]);

  return (
    <div className={styles.attendance}>
      <div className={styles.header}>
        <div className={styles.topHeader}>
          <h2 className={styles.sectionTitle}>
            <span className={styles.title}>
              {translate('attendance', language).toUpperCase()}
            </span>
            {attendance && (
              <span className={styles.attendanceName}>
                {attendance.meeting_box_name
                  ? attendance.meeting_box_name.toUpperCase()
                  : ''}
              </span>
            )}
          </h2>
        </div>
      </div>
      {renderContent}
    </div>
  );
}

export default Attendance;
