/* eslint-disable react/destructuring-assignment */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';

import translate from '../libs/i18n';
import styles from './Approvals.module.scss';
import Api from '../libs/Api';

import Loading from './Loading';
import PrintGridItem from './PrintGridItem';
import FiltersModal from './FiltersModal';
import ConfirmationModal from './ConfirmationModal';
import DateRange from './DateRange';
import CheckInput from './CheckInput';
import TagsSelectors from './TagsSelectors';
import PrintApproval from './PrintApproval';
import SearchIcon from '../assets/icons/Search';

function Approvals() {
  const language = useSelector(state => state.settings.language);
  const [prints, setPrints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filtersModal, setFiltersModal] = useState(false);
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [updatedPage, setUpdatedPage] = useState();
  const [reloadPrints, setReloadPrints] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredCounter, setFilteredCounter] = useState();
  const [counter, setCounter] = useState();

  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [designers, setDesigner] = useState([]);
  const [artFinalists, setArtFinalists] = useState([]);
  const [tags, setTags] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDesigners, setSelectedDesigners] = useState([]);
  const [selectedArtFinalists, setSelectedArtFinalists] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProveniences, setSelectedProveniences] = useState([]);
  const [reserved, setReserved] = useState(false);
  const [exclusive, setExclusive] = useState(false);
  const [noExclusive, setNoExclusive] = useState(false);
  const [digital, setDigital] = useState(false);
  const [cylinder, setCylinder] = useState(false);
  const [drafts, setDrafts] = useState(false);
  const [originals, setOriginals] = useState(false);
  const [pendingReview, setPendingReview] = useState(false);
  const [approved, setApproved] = useState(false);
  const [nonApproved, setNonApproved] = useState(false);
  const [printApprovalId, setPrintApprovalId] = useState();
  const [approvalsDirection, setApprovalsDirection] = useState('next');
  const [updating] = useState(false);
  const [selectedPrints, setSelectedPrints] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const filterTimer = useRef();

  useEffect(() => {
    Api.getCollections().then(res => {
      setCollections(res);
    });
    Api.getClients().then(res => {
      setClients(res);
    });
    Api.getDesigners().then(res => {
      setDesigner(res);
    });
    Api.getArtFinalists().then(res => {
      setArtFinalists(res);
    });
    Api.getTags().then(res => {
      setTags(res);
    });
  }, []);

  const getPrintsWithoutFilter = () => {
    setLoading(true);
    Api.getMyPrints(`?page=${page}`)
      .then(res => {
        setCounter(res.count);
        let numberOfPages = parseInt(res.count / 30, 10);
        if (res.count % 30) {
          numberOfPages += 1;
        }
        setLastPage(numberOfPages);
        if (res && res.results) {
          setPrints(res.results);
          if (printApprovalId) {
            if (approvalsDirection === 'previous') {
              setPrintApprovalId(res.results[res.results.length - 1].id);
            } else {
              setPrintApprovalId(res.results[0].id);
            }
          }
        }

        setLoading(false);
      })
      .catch(() => {
        setPrints([]);
        setPage(1);
        setReloadPrints(true);
      })
      .finally(() => {
        setReloadPrints(false);
        setLoading(false);
      });
  };

  const getFilteredPrints = () => {
    setLoading(true);
    Api.getMyPrints(`?page=${page}${filter}`)
      .then(res => {
        setFilteredCounter(res.count);

        if (res && res.results) {
          setPrints(res.results);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
          if (printApprovalId) {
            if (approvalsDirection === 'previous') {
              setPrintApprovalId(res.results[res.results.length - 1].id);
            } else {
              setPrintApprovalId(res.results[0].id);
            }
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setPrints([]);
      })
      .finally(() => {
        setLoading(false);
        setReloadPrints(false);
      });
  };

  useEffect(() => {
    if (filterTimer.current) {
      clearTimeout(filterTimer.current);
    }
    filterTimer.current = setTimeout(() => {
      setReloadPrints(true);
    }, 1000);
  }, [searchQuery, initialDate, finalDate]);

  useEffect(() => {
    if (reloadPrints) {
      if (!filter) {
        getPrintsWithoutFilter();
      } else {
        getFilteredPrints();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadPrints]);

  useEffect(() => {
    setPage(1);
    setUpdatedPage();
  }, [filter]);

  useEffect(() => {
    let query = '';
    function concat(filters) {
      query += `&${filters}`;
    }

    if (reserved) {
      concat('reserve=true');
    }

    if (approved) { 
      concat('approve=true');
    }

    if(nonApproved) {
      concat('approve=false');
    }

    if (exclusive) {
      concat('exclusivity=true');
    }

    if (noExclusive) {
      concat('exclusivity=false');
    }

    if (originals) {
      concat('color=false');
    }

    if (digital && cylinder) {
      concat('type=BOT');
    } else if (digital) {
      concat('type=DIG');
    } else if (cylinder) {
      concat('type=CYL');
    }

    if (drafts) {
      concat('status=SKE');
    }

    if (pendingReview) {
      concat('already_reviewed=false');
    }

    if (searchQuery) {
      concat(`search=${searchQuery}`);
    }

    if (selectedCollections && selectedCollections.length) {
      let collectionsQuery = 'collection=';
      for (let i = 0; i < selectedCollections.length; i += 1) {
        if (i === selectedCollections.length - 1) {
          collectionsQuery += selectedCollections[i].id;
        } else {
          collectionsQuery += `${selectedCollections[i].id},`;
        }
      }
      concat(collectionsQuery);
    }
    if (selectedClients && selectedClients.length) {
      let clientsQuery = 'owner=';
      for (let i = 0; i < selectedClients.length; i += 1) {
        if (i === selectedClients.length - 1) {
          clientsQuery += selectedClients[i].id;
        } else {
          clientsQuery += `${selectedClients[i].id},`;
        }
      }
      concat(clientsQuery);
    }

    if (selectedArtFinalists && selectedArtFinalists.length) {
      let artFinalistsQuery = 'art_finisher=';
      for (let i = 0; i < selectedArtFinalists.length; i += 1) {
        if (i === selectedArtFinalists.length - 1) {
          artFinalistsQuery += selectedArtFinalists[i].id;
        } else {
          artFinalistsQuery += `${selectedArtFinalists[i].id},`;
        }
      }
      concat(artFinalistsQuery);
    }

    if (selectedDesigners && selectedDesigners.length) {
      let designersQuery = 'designer=';
      for (let i = 0; i < selectedDesigners.length; i += 1) {
        if (i === selectedDesigners.length - 1) {
          designersQuery += selectedDesigners[i].id;
        } else {
          designersQuery += `${selectedDesigners[i].id},`;
        }
      }
      concat(designersQuery);
    }

    if (selectedProveniences && selectedProveniences.length) {
      let proveniencesQuery = 'provenience=';
      for (let i = 0; i < selectedProveniences.length; i += 1) {
        if (i === selectedProveniences.length - 1) {
          proveniencesQuery += selectedProveniences[i].value;
        } else {
          proveniencesQuery += `${selectedProveniences[i].value},`;
        }
      }
      concat(proveniencesQuery);
    }

    if (selectedTags && selectedTags.length) {
      let tagsQuery = 'tags=';
      for (let i = 0; i < selectedTags.length; i += 1) {
        if (i === selectedTags.length - 1) {
          tagsQuery += selectedTags[i].id;
        } else {
          tagsQuery += `${selectedTags[i].id},`;
        }
      }
      concat(tagsQuery);
    }
    if (initialDate) {
      concat(`init_date=${moment(initialDate).format('MM-DD-YYYY')}`);
    }
    if (finalDate) {
      concat(`final_date=${moment(finalDate).format('MM-DD-YYYY')}`);
    }
    if (query === '?') {
      setFilter('');
    } else {
      setFilter(query);
    }
  }, [
    reserved,
    exclusive,
    noExclusive,
    digital,
    cylinder,
    drafts,
    selectedTags,
    selectedCollections,
    selectedClients,
    selectedArtFinalists,
    selectedProveniences,
    initialDate,
    finalDate,
    originals,
    selectedDesigners,
    pendingReview,
    searchQuery,
    approved,
    nonApproved
  ]);

  const handleClearFilters = () => {
    setLoading(true);
    setClearAllFilters(true);
    setReserved(false);
    setApproved(false);
    setNonApproved(false);

    setExclusive(false);

    setNoExclusive(false);

    setDigital(false);

    setCylinder(false);

    setDrafts(false);

    setOriginals(false);

    setPendingReview(false);

    setSelectedTags([]);
    setSelectedCollections([]);
    setSelectedClients([]);
    setSelectedArtFinalists([]);
    setSelectedDesigners([]);
    setSelectedProveniences([]);

    setSearchQuery('');

    setTimeout(() => {
      setFiltersModal(false);
      setReloadPrints(true);
    }, 2000);
  };

  const searchPrints = () => {
    setFiltersModal(false);
    setReloadPrints(true);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  const onSelectPrint = (print, selected) => {
    let currentSelectedPrints = [...selectedPrints];
    const containsPrint = currentSelectedPrints.find(p => p === print.id);
    if (!selected && containsPrint) {
      currentSelectedPrints = currentSelectedPrints.filter(p => p !== print.id);
    } else if (selected && !containsPrint) {
      currentSelectedPrints = [...selectedPrints, print.id];
    }
    setSelectedPrints(currentSelectedPrints);
  };

  const renderedGrid = () => {
    const printsArray = prints;
    const renderPrintGridItem = print => (
      <div className={styles.printContainer}>
        <PrintGridItem
          key={`${print.id}-${print.code}`}
          id={print.id}
          image={print.image_url || print.image}
          code={print.code}
          name={print.name}
          exclusivity={print.exclusivity}
          reserved={print.reserved}
          pendingReview={'already_reviewed' in print && !print.already_reviewed}
          type={print.type}
          backgroundColor={
            print.flat_background && print.flat_background_color
              ? print.flat_background_color
              : false
          }
          pantone={print.is_pantone ? print.pantone_color : false}
          doNothing
          customizableBackground={print.flat_background}
          selectable
          onSelect={onSelectPrint}
          printApproval={() => setPrintApprovalId(print.id)}
          newDesignPending={print.new_design_pending}
        />
      </div>
    );
    return (
      <>
        {printsArray && printsArray?.length > 0 ? (
          <div className={styles.printsGrid}>
            <div>
              {printsArray &&
                printsArray.map((print, index) => {
                  if (index % 4 === 0) {
                    return renderPrintGridItem(print);
                  }
                  return null;
                })}
            </div>
            <div>
              {printsArray &&
                printsArray.map((print, index) => {
                  if (index % 4 === 1) {
                    return renderPrintGridItem(print);
                  }
                  return null;
                })}
            </div>
            <div>
              {printsArray &&
                printsArray.map((print, index) => {
                  if (index % 4 === 2) {
                    return renderPrintGridItem(print);
                  }
                  return null;
                })}
            </div>
            <div>
              {printsArray &&
                printsArray.map((print, index) => {
                  if (index % 4 === 3) {
                    return renderPrintGridItem(print);
                  }
                  return null;
                })}
            </div>
          </div>
        ) : (
          <div className={styles.emptyPrintList}>
            {translate('printNotFound', language)}
          </div>
        )}
      </>
    );
  };

  const nextApprovalPrint = () => {
    const currentIndex = prints.findIndex(p => p.id === printApprovalId);
    if (currentIndex === prints.length - 1) {
      if (page < lastPage) {
        setPage(page + 1);
      } else {
        setPage(1);
      }
      setReloadPrints(true);
    } else {
      setPrintApprovalId(prints[currentIndex + 1].id);
    }
    setApprovalsDirection('next');
  };

  const previousApprovalPrint = () => {
    const currentIndex = prints.findIndex(p => p.id === printApprovalId);
    if (currentIndex === 0) {
      if (page > 1) {
        setPage(page - 1);
      } else {
        setPage(lastPage);
      }
      setReloadPrints(true);
    } else {
      setPrintApprovalId(prints[currentIndex - 1].id);
    }
    setApprovalsDirection('previous');
  };

  const getPrintPosition = useMemo(() => {
    const currentIndex = prints.findIndex(p => p.id === printApprovalId);
    return (page - 1) * 30 + currentIndex + 1;
  }, [prints, printApprovalId]);

  const deletePrints = () => {
    setLoading(true);
    setConfirmationModal(false);
    const deleteRequests = [];

    selectedPrints.forEach(p => {
      const deleteRequest = Api.deletePrint(p);
      deleteRequests.push(deleteRequest);
    });
    axios
      .all(deleteRequests)
      .then(() => {
        setReloadPrints(true);
        setSelectedPrints([]);
      })
      .catch(() => {
        toast(translate('failDeletingPrints', language), {
          type: 'error'
        });
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <div className={styles.leftFilters}>
          <div className={styles.searchInputContainer}>
            <SearchIcon color="#a3b3c7" />
            <input
              type="search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.currentTarget.value)}
              className={`${styles.searchInput} ${styles.search}`}
              placeholder={translate('search', language)}
            />
          </div>
          <div className={styles.filters}>
            <div className={styles.dateFilter}>
              <DateRange
                selectInitialDate={setInitialDate}
                selectFinalDate={setFinalDate}
                clearDateRange={clearAllFilters}
                setClearDateRange={setClearAllFilters}
              />
            </div>
            <button
              type="button"
              className={styles.filtersButton}
              onClick={() => setFiltersModal(true)}
            >
              {translate('filters', language)}
            </button>
            <div className={styles.filtersList}>
              {selectedTags.map(s => `${s.name}; `)}
              {selectedCollections.map(s => `${s.name}; `)}
              {selectedClients.map(s => `${s.name}; `)}
              {selectedDesigners.map(s => `${s.name}; `)}
              {selectedArtFinalists.map(s => `${s.name}; `)}
              {selectedProveniences.map(s => `${s.name}; `)}
              {reserved && `${translate('reserved', language)}; `}
              {approved && `${translate('approved', language)}; `}
              {nonApproved && `${translate('nonApproved', language)}; `}
              {exclusive && `${translate('exclusive', language)}; `}
              {noExclusive && `${translate('notExclusive', language)}; `}
              {digital && `${translate('digital', language)}; `}
              {cylinder && `${translate('cylinder', language)}; `}
              {drafts && `${translate('drafts', language)}; `}
              {originals && `${translate('seeOnlyOriginals', language)}; `}
              {pendingReview &&
                `${translate('seeOnlyPendingReview', language)}; `}
            </div>
            {filter && (
              <button
                className={styles.clearFilters}
                type="button"
                onClick={handleClearFilters}
              >
                {translate('clearFilters', language)}
              </button>
            )}
          </div>
        </div>
        <Link
          type="button"
          className={`${styles.defaultActionButton} ${styles.createPrint}`}
          to="/create-print"
        >
          {translate('createPrint', language)}
        </Link>
      </div>
      {renderedGrid()}
      {filtersModal && (
        <FiltersModal title={translate('filters', language)}>
          <TagsSelectors
            selectLabel={translate('add', language)}
            collections={collections}
            tags={tags}
            clients={clients}
            designers={designers}
            artFinalists={artFinalists}
            startSelectedCollections={selectedCollections}
            startSelectedClients={selectedClients}
            startSelectedDesigners={selectedDesigners}
            startSelectedArtFinalists={selectedArtFinalists}
            startSelectedProveniences={selectedProveniences}
            startSelectedTags={selectedTags}
            selectTags={setSelectedTags}
            selectCollections={setSelectedCollections}
            selectClients={setSelectedClients}
            selectArtFinalists={setSelectedArtFinalists}
            selectDesigners={setSelectedDesigners}
            selectProveniences={setSelectedProveniences}
            setClearFilters={setClearAllFilters}
            complete
          />
          <div className={styles.checks}>
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('reserved', language)}
                onChange={setReserved}
                value={reserved}
              />
              <CheckInput
                topMenu
                text={translate('exclusive', language)}
                onChange={setExclusive}
                value={exclusive}
              />
              <CheckInput
                topMenu
                text={translate('notExclusive', language)}
                onChange={setNoExclusive}
                value={noExclusive}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('digital', language)}
                onChange={setDigital}
                value={digital}
              />
              <CheckInput
                topMenu
                text={translate('cylinder', language)}
                onChange={setCylinder}
                value={cylinder}
              />
              <div />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('drafts', language)}
                onChange={setDrafts}
                value={drafts}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('seeOnlyOriginals', language)}
                onChange={setOriginals}
                value={originals}
              />
            </div>
            <span className={styles.separator} />
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('seeOnlyPendingReview', language)}
                onChange={setPendingReview}
                value={pendingReview}
              />
            </div>
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('approved', language)}
                onChange={setApproved}
                value={approved}
              />
            </div>
            <div className={styles.checkGroup}>
              <CheckInput
                topMenu
                text={translate('nonApproved', language)}
                onChange={setNonApproved}
                value={nonApproved}
            />
            </div>
            <div className={styles.filterActions}>
              <button
                className={styles.clearPrintsFilters}
                type="button"
                onClick={handleClearFilters}
              >
                {translate('clearFilters', language)}
              </button>
              <button
                className={`${styles.defaultActionButton} ${styles.showPrints}`}
                type="button"
                onClick={searchPrints}
              >
                {translate('showPrints', language)}
              </button>
            </div>
          </div>
        </FiltersModal>
      )}
      {(counter !== 0 || filteredCounter !== 0) && lastPage !== 0 && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setReloadPrints(true);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setReloadPrints(true);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
          <div
            className={`${styles.actionsMenuContainer} ${selectedPrints.length >
              0 && styles.actionsMenuActive}`}
          >
            <div className={styles.actionsMenu}>
              <div className={styles.left}>
                <div>
                  {`${selectedPrints.length} ${
                    selectedPrints.length > 1
                      ? translate('selectedMultiple', language)
                      : translate('selectedOne', language)
                  }`}
                </div>
              </div>
              <button
                onClick={() => setConfirmationModal(true)}
                disabled={!selectedPrints.length}
                type="button"
                className={styles.delete}
              >
                {translate('delete', language)}
              </button>
            </div>
          </div>
        </div>
      )}
      {printApprovalId && (
        <PrintApproval
          id={printApprovalId}
          nextPrint={nextApprovalPrint}
          previousPrint={previousApprovalPrint}
          close={() => setPrintApprovalId(null)}
          printCounter={counter}
          printPosition={getPrintPosition}
          handleReloadPrints={setReloadPrints}
        />
      )}
      {(loading || updating) && <Loading />}
      {confirmationModal && (
        <ConfirmationModal
          message={translate('deletePrintsConfirmation')}
          confirmText={translate('yes', language)}
          cancelText={translate('no', language)}
          onConfirm={deletePrints}
          onCancel={() => setConfirmationModal(false)}
          deleteWarning
        />
      )}
    </div>
  );
}

export default Approvals;
