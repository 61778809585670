import React from 'react';
import styles from './styles.module.scss';

interface TooltipProps {
    titles: string | Array<string>;
}

const Tooltip: React.FC<TooltipProps> = ({titles, children}) => {
    return (
        <div className={styles.container}>
            {children}
            {Array.isArray(titles) && titles.length > 0 && (
                <span>
                    {titles.map(title => <p>{title}</p>)}
                </span>
            )}
        </div>
    )
}

export default Tooltip;