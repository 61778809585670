import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import axios from 'axios';

import styles from './ClientPresentationConcept.module.scss';

import PlayIcon from '../assets/icons/Play';
import Loading from '../components/Loading';
import PresentationPlayer from '../components/PresentationPlayer';
import Api from '../libs/Api';
import translate from '../libs/i18n';

function ClientPresentationConcept() {
  const { id, attendanceId } = useParams();
  const history = useHistory();

  const token = useSelector(state => state.loggedClient.token);
  const language = useSelector(state => state.settings.language);
  const client = useSelector(state => state.loggedClient);

  const [loading, setLoading] = useState(true);
  const [concept, setConcept] = useState();
  const [presentation, setPresentation] = useState();
  const [prints, setPrints] = useState();

  useEffect(() => {
    (async function onLoad() {
      setLoading(true);
      const getConcept = Api.getConcept(id);
      const getPresentation = Api.getClientPresentation(token, id);
      await axios
        .all([getConcept, getPresentation])
        .then(res => {
          setConcept(res[0].results);
          if (!res[0].results.length) {
            if (attendanceId) {
              history.push(
                `/client/${client.meeting_box_id}/presentations/${id}/prints`
              );
            } else {
              history.push(`/client/${id}/prints`);
            }
          }
          setPresentation(res[1]);
          setPrints(res[1].selected_prints);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [id, token]);

  const [showPresentation, setShowPresentation] = useState(false);

  const renderedCard = useCallback((itemId, image, description) => {
    return (
      <div key={`${itemId}`} className={styles.cardImage}>
        <img key={image} id={itemId} src={image} alt={itemId} />
        <p>{description}</p>
      </div>
    );
  }, []);

  const renderedGrid = useMemo(() => {
    if (concept) {
      const conceptToRender = concept.sort((a, b) => a.position - b.position);

      return (
        <div className={styles.photoGrid}>
          <div>
            {conceptToRender.map((item, index) => {
              if (index % 4 === 0) {
                return renderedCard(item.id, item.image, item.description);
              }
              return null;
            })}
          </div>
          <div>
            {conceptToRender.map((item, index) => {
              if (index % 4 === 1) {
                return renderedCard(item.id, item.image, item.description);
              }
              return null;
            })}
          </div>
          <div>
            {conceptToRender.map((item, index) => {
              if (index % 4 === 2) {
                return renderedCard(item.id, item.image, item.description);
              }
              return null;
            })}
          </div>
          <div>
            {conceptToRender.map((item, index) => {
              if (index % 4 === 3) {
                return renderedCard(item.id, item.image, item.description);
              }
              return null;
            })}
          </div>
        </div>
      );
    }
    return [];
  }, [concept, renderedCard]);

  return (
    <>
      <div
        className={`${styles.clientPresentationConcept} ${
          loading ? styles.loading : ''
        }`}
      >
        <div className={styles.header}>
          {concept && (
            <ul className={styles.sectionsMenu}>
              <li>
                <NavLink
                  to={
                    attendanceId
                      ? `/client/${attendanceId}/presentations/${id}/concept`
                      : `/client/${id}/concept`
                  }
                  activeClassName={styles.activeLink}
                >
                  {translate('concept', language)}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    attendanceId
                      ? `/client/${attendanceId}/presentations/${id}/prints`
                      : `/client/${id}/prints`
                  }
                  activeClassName={styles.activeLink}
                >
                  {translate('prints', language)}
                </NavLink>
              </li>
            </ul>
          )}

          <div className={styles.actions} />
        </div>
        <div className={styles.mainInfo}>
          <button type="button">
            <h1>{presentation && presentation.meeting_name}</h1>
          </button>

          <button type="button">
            <p>{presentation && presentation.meeting_briefing}</p>
          </button>
        </div>
        <button
          className={styles.play}
          type="button"
          onClick={() => setShowPresentation(true)}
        >
          <PlayIcon />
          Play
        </button>
        {concept && concept.length > 0 && renderedGrid}
      </div>
      {loading && (
        <div className={styles.loadingConcept}>
          <Loading />
        </div>
      )}

      {showPresentation && (
        <PresentationPlayer
          concept={concept || []}
          prints={prints || []}
          presentation={presentation}
          close={() => setShowPresentation(false)}
          section="concept"
        />
      )}
    </>
  );
}

export default ClientPresentationConcept;
