export function setPrints(prints) {
  return {
    type: '@prints/SET',
    prints
  };
}
export function addPrints(prints) {
  return {
    type: '@prints/ADD',
    prints
  };
}
export function removePrint(id) {
  return {
    type: '@prints/REMOVE',
    id
  };
}

export function updatePrint(print) {
  return {
    type: '@prints/UPDATE',
    print
  };
}

export function clearPrints() {
  return {
    type: '@prints/CLEAR'
  };
}

export default setPrints;
