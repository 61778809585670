import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import styles from './Works.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';

import Approvals from '../components/Approvals';
import MyPrints from '../components/MyPrints';
import MyOrders from '../components/MyOrders';
import ApprovedList from '../components/ApprovedList';
import Clients from '../components/Clients';
import { Agents } from '../components/Agents';
import { ListUsersClient } from '../components/ListUsersClient';
import setInitialDateFilter from '../store/actions/libraryFilters';
import { SupplierItens } from '../components/SupplierItens';

function Works() {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const location = useLocation();

  const [profile, setProfile] = useState();
  const [clients, setClients] = useState([]);
  const [section, setSection] = useState(user && user.role && user.role.includes('ext-designer') ? 'myPrints' : 'approvals');
  const [mySketches, setMySketches] = useState([]);
  const [loadingMyPrints, setLoadingMyPrints] = useState(false);
  const [myOrders, setMyOrders] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [ordersPage, setOrdersPage] = useState(1);
  const [ordersClient, setOrdersClient] = useState();
  const [inputOrdersPage, setInputOrdersPage] = useState();
  const [ordersStatus, setOrdersStatus] = useState('OPEN');
  const [ordersInitialDate, setOrdersInitialDate] = useState();
  const [ordersFinalDate, setOrdersFinalDate] = useState();
  const [ordersFilter, setOrdersFilter] = useState('');
  const [hasNextPage, setHasNextPage] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [loadingMoreOrders, setLoadingMoreOrders] = useState(false);
  const [ordersProductionType, setOrdersProductionType] = useState('');
  const [ce, setCE] = useState('')
  const [orderNumber, setOrderNumber] = useState('')

  useEffect(() => {
    if (location.search.replace('?session=', '')) {
      setSection(location.search.replace('?session=', ''));
    }
  }, [location]);
  useEffect(() => {
    Api.getSupplier('type=s,b').then((res) => {
      if(res?.results) {
        const formattedData = res?.results?.map((supplier) => ({
          name: supplier.name || '',
          value: supplier.id || '',
          id: supplier.id || ''
        }));
        setSuppliers(formattedData);
      }
    })
  }, [])
  useEffect(() => {
    if (user && user.role) {
      if (user.role.includes('coordinator')) {
        setProfile('coordinator');
      } else if (user.role.includes('coord-style')) {
        setProfile('coord-style');
        setSection('approved')
      } else if (user.role.includes('art-finisher')) {
        setProfile('art-finisher');
        setSection('approved');
      } else if (user.role.includes('designer')) {
        setProfile('designer');
      } else if (user.role.includes('style')) {
        setProfile('style');
      } else if (user.role.includes('manager-lec')) {
        setProfile('manager-lec');
        setSection('orders');
      } else if (user.role.includes('agent-lec')) {
        setProfile('agent-lec');
        setSection('orders');
      }

      setLoadingMyPrints(true);
      Api.getMyPrints()
        .then(prints => {
          const skt = prints.results.filter(p => p.status === 'SKE');
          setMySketches(skt);
        })
        .catch(() => {
          toast(translate('failGettingMyPrints', language), {
            type: 'error'
          });
        })
        .finally(() => {
          setLoadingMyPrints(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, user.id]);

  useEffect(() => {
    setInputOrdersPage(1);
    setOrdersPage(1);
    let filter = '';

    if (ordersClient) {
      filter = `${filter}&client_id=${ordersClient.id}`;
    }

    if (ordersInitialDate) {
      filter = `${filter}&init_date=${moment(ordersInitialDate).format(
        'MM-DD-YYYY'
      )}`;
    }

    if (ordersFinalDate) {
      filter = `${filter}&final_date=${moment(ordersFinalDate).format(
        'MM-DD-YYYY'
      )}`;
    }

    if (ordersStatus === 'OPEN') {
      filter = `${filter}&status=OPEN,REP`;
    }
    if (ordersStatus === 'PROD') {
      filter = `${filter}&status=APP,PROD,DISP`;
    }
    if (ordersStatus === 'REV') {
      filter = `${filter}&status=REV`;
    }
    if (ordersStatus === 'FINA') {
      filter = `${filter}&status=FINA`;
    }
    if(selectedSupplier !== undefined) {
      filter = `${filter}&supplier=${selectedSupplier?.name}`
    }
    if(orderNumber) {
      filter = `${filter}&order_number=${orderNumber}`
    }
    if(ce) {
      filter = `${filter}&supplier_number=${ce}`
    }
    if (ordersProductionType) {
      if (ordersProductionType === "PRIORIDADE"){
        filter = `${filter}&priority=true`;
      } else {
        filter = `${filter}&po_type=${ordersProductionType}`;
      }
    }

    setOrdersFilter(filter);
  }, [
    selectedSupplier,
    ordersFilter,
    setOrdersFilter,
    ordersClient,
    ordersInitialDate,
    ordersFinalDate,
    ordersStatus,
    ordersProductionType,
    ce,
    orderNumber
  ]);


  const updateOrders = () => {
    Api.getFilteredSellersOrders(ordersPage, ordersFilter)
      .then(orders => {
        if (ordersPage > 1) {
          const updatedOrders = [...myOrders, ...orders.results];
          setMyOrders(updatedOrders);
        } else {
          setMyOrders(orders.results);
        }
        let numberOfPages = parseInt(orders.count / 30, 10);
        if (orders.count % 30) {
          numberOfPages += 1;
        }
        setLastPage(numberOfPages);

        setHasNextPage(orders && orders.next);
      })
      .catch(() => {
        setLoadingMoreOrders(false);
      })
      .finally(() => {
        setLoadingMoreOrders(false);
      });
  };

  useEffect(() => {
    if (
      user &&
      user.role &&
      (user.role.includes('seller') || user.role.includes('coord-seller'))
    ) {
      setLoadingMoreOrders(true);
      Api.getClients().then(res => {
        setClients(res);
      });
      Api.getFilteredSellersOrders(ordersPage, ordersFilter)
        .then(orders => {
          if (ordersPage > 1) {
            const updatedOrders = [...myOrders, ...orders.results];
            setMyOrders(updatedOrders);
          } else {
            setMyOrders(orders.results);
          }
          let numberOfPages = parseInt(orders.count / 30, 10);
          if (orders.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);

          setHasNextPage(orders && orders.next);
        })
        .catch(() => {
          setLoadingMoreOrders(false);
        })
        .finally(() => {
          setLoadingMoreOrders(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersPage, ordersFilter]);

  const handleKeyDown = e => {
    if (e.key === 'Enter' && inputOrdersPage > 0) {
      setOrdersPage(inputOrdersPage);
      setInputOrdersPage();
    }
  };
  const looseFocus = () => {
    if (inputOrdersPage > 0) {
      setOrdersPage(inputOrdersPage);
      setInputOrdersPage();
    }
  };
  const clearFilters = () => {
    setCE('');
    setOrderNumber('');
    setOrdersInitialDate(null);
    setOrdersFinalDate(null);
    setSelectedSupplier(undefined);
    setOrdersClient(null);
    setOrdersProductionType(null);
    setOrdersFilter('');
  }

  return (
    <div className={styles.home}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          <span className={styles.title}>
            {translate('myWork', language).toUpperCase()}
          </span>
        </h2>
        <nav className={styles.sectionsMenuContainer}>
          <ul className={styles.sectionsMenu}>
            <div className={styles.sectionActive}>
              {translate(section, language)}
              <span className={styles.arrowDown} />
            </div>
            {user &&
              user.role &&
              (user.role.includes('seller') ||
                user.role.includes('coord-seller') || user.role.includes('manager-lec') || user.role.includes('agent-lec')) && (
                <li>
                  <Link
                    to="/my-work?session=orders"
                    className={section === 'orders' ? styles.active : ''}
                  >
                    {translate('orders', language)}
                  </Link>
                </li>
              )}
            {user &&
            user.role &&
            (user.role.includes('manager-lec') && (
              <li>
                <Link 
                  to="/my-work?session=agents"
                  className={section === 'agents' ? styles.active : ''}
                > 
                  {translate('agents', language)}
                </Link>
              </li>
            ))}
            {user &&
              user.role &&
              (user.role.includes('designer') ||
                user.role.includes('coordinator') ||
                user.role.includes('seller') ||
                user.role.includes('coord-seller') ||
                user.role.includes('style') ||
                user.role.includes('coord-style')) && (
                <li>
                  <Link
                    to="/my-work?session=approvals"
                    className={section === 'approvals' ? styles.active : ''}
                  >
                    {translate('approvals', language)}
                  </Link>
                </li>
              )}
            {user &&
              user.role &&
              (user.role.includes('designer') ||
                user.role.includes('coordinator') ||
                user.role.includes('style')) && (
                <li>
                  <Link
                    to="/my-work?session=myPrints"
                    className={section === 'myPrints' ? styles.active : ''}
                  >
                    {translate('myPrints', language)}
                  </Link>
                </li>
              )}
            {user &&
              user.role &&
              (user.role.includes('coordinator') ||
                user.role.includes('coord-style') ||
                user.role.includes('art-finisher')) && (
                <li>
                  <Link
                    to="/my-work?session=approved"
                    className={section === 'approved' ? styles.active : ''}
                  >
                    {translate('approved', language)}
                  </Link>
                </li>
              )}
            {user &&
              user.role &&
              !user.role.includes('ext-designer') && (
                <li>
                  <Link
                    to="/my-work?session=clients"
                    className={section === 'clients' ? styles.active : ''}
                  >
                    {translate('clients', language)}
                  </Link>
                </li>
              )}
              {user && 
                  user.role && 
                  (!user.role.includes('ext-designer') && (
                    <li>
                      <Link to="/my-work?session=userClient">
                        {translate('userClient', language)}
                      </Link>
                    </li>
                  ))  
                }
                {user &&
                  user.role &&
                  (user.role.includes('designer') ||
                    user.role.includes('coordinator') ||
                    user.role.includes('seller') ||
                    user.role.includes('coord-seller') ||
                    user.role.includes('style') ||
                    user.role.includes('coord-style')) && (
                    <li>
                      <Link to="/my-work?session=supplierItens">
                        {translate('supplierItens', language)}
                      </Link>
                    </li>
                  )}
          </ul>
        </nav>
      </div>
      {user &&
        user.role &&
        (user.role.includes('designer') ||
          user.role.includes('coordinator') ||
          user.role.includes('style') ||
          user.role.includes('coord-style') ||
          user.role.includes('seller') ||
          user.role.includes('coord-seller')) &&
        section === 'approvals' && (
          <div className={styles.designerContent}>
            <Approvals
              prints={mySketches}
              coordinator={
                profile === 'coordinator' ||
                profile === 'seller' ||
                profile === 'coord-seller'
              }
              loading={loadingMyPrints}
            />
          </div>
        )}

      {user &&
        user.role &&
        (user.role.includes('designer') ||
        user.role.includes('ext-designer') ||
          user.role.includes('coordinator') ||
          user.role.includes('style')) &&
        section === 'myPrints' && (
          <div className={styles.designerContent}>
            <MyPrints />
          </div>
        )}

      {user &&
        user.role &&
        (user.role.includes('art-finisher') ||
          user.role.includes('coordinator') ||
          user.role.includes('coord-style')) &&
        section === 'approved' && (
          <div className={styles.artFinalistContent}>
            <ApprovedList />
          </div>
        )}
      {user &&
        user.role &&
        (user.role.includes('manager-lec') && section === 'agents' && (
          <Agents />
        ))
      
      }
      {user &&
        user.role &&
        (user.role.includes('seller') || user.role.includes('coord-seller') || user.role.includes('manager-lec') || user.role.includes('agent-lec')) &&
        section === 'orders' &&
        myOrders && (
          <>
            <MyOrders
              orders={myOrders}
              suppliers={suppliers}
              supplier={selectedSupplier}
              setSupplier={setSelectedSupplier}
              setClient={setOrdersClient}
              initialDate={ordersInitialDate}
              finalDate={ordersFinalDate}
              setInitialDate={setOrdersInitialDate}
              setFinalDate={setOrdersFinalDate}
              client={ordersClient}
              status={setOrdersStatus}
              productionType={setOrdersProductionType}
              hasNextPage={hasNextPage}
              loadingMoreOrders={loadingMoreOrders}
              clients={clients}
              updateOrders={updateOrders}
              isManagerLec={user.role.includes('manager-lec') ?? false}
              isAgentLec={user.role.includes('agent-lec') ?? false}
              setCEFilter={setCE}
              setOrderNumberFilter={setOrderNumber}
              clearFilters={clearFilters}
            />
            {myOrders && myOrders.length > 0 && (
              <div className={`${styles.footer}`}>
                <div className={`${styles.pagination}`}>
                  <button
                    disabled={ordersPage === 1}
                    type="button"
                    onClick={() => {
                      setOrdersPage(ordersPage - 1);
                      setInputOrdersPage(ordersPage - 1);
                    }}
                  >
                    <span className={styles.backArrow} />
                  </button>
                  <input
                    type="number"
                    placeholder={ordersPage}
                    onKeyDown={handleKeyDown}
                    value={inputOrdersPage}
                    onChange={e =>
                      setInputOrdersPage(parseInt(e.currentTarget.value, 10))
                    }
                    onBlur={looseFocus}
                  />
                  <span>de {lastPage}</span>
                  <button
                    disabled={!hasNextPage}
                    type="button"
                    onClick={() => {
                      setOrdersPage(ordersPage + 1);
                      setInputOrdersPage(ordersPage + 1);
                    }}
                  >
                    <span className={styles.nextArrow} />
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      {section === 'clients' && <Clients />}
      {section === 'userClient' && <ListUsersClient />}
      {section === 'supplierItens' && <SupplierItens />}
    </div>
  );
}

export default Works;
