import React from 'react';
import styles from './styles.module.scss';
import SearchIcon from '../../assets/icons/Search';

export function Search({placeholder, value, onChange}) {
    return(
        <div className={styles.container}>
                <SearchIcon color="#a3b3c7" />
                <input
                  type="search"
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                />
        </div>
    )
}