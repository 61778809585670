import React from "react";
import { useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { formatDate } from "../../utils/formatDate";
import { getStatusColor } from "../../utils/getStatusColor";
import styles from "./styles.module.scss"
import { SupplierTag } from "./EtiquetaPrint/Etiqueta";
import { FiPrinter, FiX } from "react-icons/fi";
import { AiFillFilePdf } from "react-icons/ai";
import { PDFTag } from "./EtiquetaPrint";
import { usePDFTag } from "../../hooks/usePDFTag";
// import { LightBox } from "../LightBox";
// import { Pagination } from "../Pagination";

interface PurchaseOrderTableProps  {
  data: Array<any>;
  filteredData: Array<any>;
  totalItens?: number;
}

type TimerFormData = {
  date: string;
}

type ColumType = {
  id: number;
  print_image_url: string;
  order: number;
  supplier_number: string;
  date_request: string;
  type: string;
  print_code: string;
  meters: number;
  fabric_name: string;
  service: string;
  technique: string;
  date_delivery: string;
  psd_final?: string;
  psd_original?: string;
  status: string;
  is_pantone?: boolean;
  pantone_color?: string;
  print_name?: string;
  flat_background_color?: string;
  attendance_fabric_supplier_name?: string;
  service_name?: string[];
  status_delivery?: string;
  agreed_date?: string;
}

export function PurchaseOrderTable({
  data,
  ...rest
}: PurchaseOrderTableProps) {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [selectedProductOrder, setSelectedProductOrder] = useState({} as ColumType);
  const [selectedsPrints, setSelectedPrints] = useState<any[]>([]);
  const [tagPrintModalOpen, setTagPrintModalOpen] = useState(false);
  //@ts-ignore
  const { setSelectedPrintsContext } = usePDFTag();
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ColumType[]) => {
      console.log('selectedRows: ', selectedRows);
      setSelectedPrints(selectedRows);
      setSelectedPrintsContext(selectedRows);
    },
    getCheckboxProps: (record: ColumType) => ({
      name: `${record.id}`,
    }),
  };
  const newColumns: ColumnsType<ColumType> = [
    {
      key: "status_delivery",
      title: "STATUS",
      dataIndex: "status_delivery",
      render: (text, record) => (
        <div
          style={{
            fontSize: "1rem",
            width: "35px",
            height: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "gray",
            borderRadius: "50%",
            backgroundColor: record?.status_delivery?.toLocaleLowerCase() ?? "transparent"
          }}
        />
      )
    },
    {
      key: "print_image_url",
      title: "",
      dataIndex: "print_image_url",
      render: (text, record) => (
        <>
          {record?.is_pantone ? (
            <div 
              onClick={() => {
                setIsLightBoxOpen(true);
                setSelectedProductOrder(record);
              }}
              style={{
                width: 45,
                height: 45,
                backgroundColor: record?.pantone_color,
              }}
            />
          ) : (
            <img
              width={45}
              height={45}
              alt="order print image url"
              src={record?.print_image_url}
              onClick={() => {
                setIsLightBoxOpen(true);
                setSelectedProductOrder(record);
              }}
            />
          )}
        </>
      )
    },
    {
      key: "supplier",
      title: "SUPPLIER",
      dataIndex: "supplier",
      render: (text, record) => {
        //@ts-ignore
        return (record?.supplier_name ? record?.supplier_name : "-")
      }
    },
    {
      key: "order",
      title: "MATCH",
      dataIndex: "order",
      render: (text, record) => (record?.order)
      
    },
    {
      key: "po",
      title: "PO",
      dataIndex: "po",
      render: (text, record) => {
        //@ts-ignore
        return (record?.po ? record?.po : "-")
      }
    },
    {
      key: "supplier_number",
      title: "CE",
      dataIndex: "supplier_number",
      render: (text, record) => (record?.supplier_number)
    },
    {
      key: "date_request",
      title: "ORDER DATE",
      dataIndex: "date_request",
      render: (text, record) => (formatDate(record?.date_request, false, true))
      
    },
    {
      key: "type",
      title: "TYPE",
      dataIndex: "type",
      render: (text, record) => (record?.type)
    },
    {
      key: "print_code",
      title: "DESIGN",
      dataIndex: "print_code",
      render: (text, record) => (record?.print_code)
    },
    {
      key: "meters",
      title: "METERS",
      dataIndex: "meters",
      render: (text, record) => (record?.meters)
    },
    {
      key: "fabric_name",
      title: "FABRIC",
      dataIndex: "fabric_name",
      render: (text, record) => (`${record?.fabric_name} - ${record?.attendance_fabric_supplier_name}`)
    },
    {
      key: "service_name",
      title: "SERVICE",
      dataIndex: "service_name",
      render: (text, record) => (`${record?.service_name}`)
    },
    {
      key: "technique",
      title: "TECHNIQUE",
      dataIndex: "technique",
      render: (text, record) => (record?.technique)
    },
    {
      key: "date_delivery",
      title: "AGREED DELIVERY DATE",
      dataIndex: "date_delivery",
      render: (text, record) => (
        <div 
            style={{
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <div 
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                }}
            >
              <p>
                { record?.agreed_date ? formatDate(record?.agreed_date!!, false, true) : "-"}
              </p>
            </div>
        </div>
      )
    },
    {
      key: "psd_final",
      title: "DOWNLOAD PSD",
      dataIndex: "psd_final",
      render: (text, record) => (
        <>
        {console.log("TEST:", record)}
          {record?.is_pantone ? (
            <p>
              {record?.print_name}
            </p>
          ) : (
            <a href={record?.psd_final ?? record?.psd_original} target="_blank">
              <p
                
                style={{
                    textDecoration: "underline",
                    color: "blue"
                }}
              >
                {(record?.psd_final || record?.psd_original) ? record?.print_code : 'No psd to download'}
              </p>
            </a>
          )}
        </>
      )
    }
  ]

//   const handleEditOrder: SubmitHandler<TimerFormData> = async ({ date }) => {

//     try {
//       const DTO = {
//         id: orderOnEdit,
//         date_delivery: date
//       }
//       await patchBestExpectedDeliveryDate(DTO);
//       toast({
//         title: 'Atualização de data entrega',
//         description: `Nova data de entrega: ${formatDate(date)}`,
//         status: "success",
//         duration: 5000,
//         isClosable: true,
//       });
//       setOrderOnEdit(null);
//       handleGetNewData();
//     } catch (err: any) {
//       toast({
//         title: 'Erro na atualização',
//         description: err.message === "Unauthorized" ? 'Usuário não autorizado!' :
//           'Falha ao atualizar a data de entrega',
//         status: "error",
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   }

//   function orderOnEditMode() {
//     return (
//       <Flex
//         as="form"
//         justify="space-between"
//         align="center"
//         gap="20px"
//         onSubmit={handleSubmit(handleEditOrder)}
//       >
//         <Input
//           placeholder="Select Date and Time"
//           size="sm"
//           type="date"
//           {...register("date")}
//         />
//         <Button
//           type="submit"
//           variant="outline"
//           borderColor="primary"
//           color="primary"
//           _hover={{
//             bg: "primary",
//             color: "white"
//           }}
//         >
//           Save
//         </Button>
//       </Flex>
//     )
//   }

  return (
    <div 
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}
    >
      {/* <LightBox
          image={selectedProductOrder?.print_image_url}
          background_color={selectedProductOrder?.is_pantone ? selectedProductOrder?.pantone_color : selectedProductOrder?.flat_background_color} 
          isOpen={isLightBoxOpen}
          is_pantone={selectedProductOrder?.is_pantone}
          onClose={() => {
            setIsLightBoxOpen(!isLightBoxOpen)
            setSelectedProductOrder({} as ColumType)
          }}
      /> */}
      <div 
        style={{
            width: '100%',
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
        }}
      >
        <section
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <button
            className={styles.defaultRoundedActionButton}
            type="button"
            onClick={() => setTagPrintModalOpen(!tagPrintModalOpen)}
            disabled={selectedsPrints?.length === 0}
            style={{
              maxWidth: '120px',
            }}
          >
            <FiPrinter />
          </button>
          <button
            className={styles.defaultRoundedActionButton}
            type="button"
            onClick={() => window.open(`/supplier/pdf`)}
            disabled={selectedsPrints?.length === 0}
            style={{
              maxWidth: '120px',
            }}
          >
            <AiFillFilePdf />
          </button>
        </section>
        {tagPrintModalOpen && (
          <div
            className={styles.modalContainer}
          >
            <button
              type="button"
              onClick={() => setTagPrintModalOpen(false)}
            >
              <FiX />
            </button>
            <SupplierTag 
              data={selectedsPrints} 
            />
          </div>
        )}
        <Table<ColumType>
          columns={newColumns}
          dataSource={data}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey={(record) => record.id}
          locale={{ emptyText: "Sem dados de orders" }}
          pagination={false}
          scroll={{ x: 1800 }}
        />
      </div>
    </div>
  );
}
