import React, { useRef, useState } from "react";
import useOutsideClick from "../../libs/useOutsideClick";
import styles from "./styles.module.scss";

export function Popover({ children, icon }) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const ref = useRef();
    useOutsideClick(ref, () => {
        setIsPopoverOpen(false);
    });
    
    return (
        <button
            type="button"
            className={styles.seeOptions}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            ref={ref}
        >
            {icon}
            {isPopoverOpen && (
                <section>
                    {children}
                </section>
            )}
        </button>
    )
}