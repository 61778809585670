import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

import { SearchComponent } from '../../components/SearchComponent/index.jsx';
import { useBriefing } from '../../hooks/useBriefing';
import { BriefingCardItem } from '../../components/BriefingCardItem';
import translate from '../../libs/i18n';
import Loading from '../../components/Loading';
import TagsSelectors from '../../components/TagsSelectors';
import DateRange from '../../components/DateRange';

export function Briefings() {
    const language = useSelector(state => state.settings.language);
    const { 
        briefings, 
        page, 
        nextPage, 
        prevPage, 
        lastPage, 
        counter, 
        filteredCounter, 
        goTop, 
        loading, 
        updatedPageInput,
        updatedPage, 
        clients, 
        selectedClients, 
        setSelectedClients, 
        setClearAllFilters,
        setInitialDate,
        setFinalDate,
        clearAllFilters,
        handleClearFilters,
        initialDate,
        finalDate
    } = useBriefing();
    const history = useHistory();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.sectionTitle}>
                    <span className={styles.title}>
                        {translate('briefings', language).toUpperCase()}
                    </span>
                </h2>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <div className={styles.searchContainer}>
                                <SearchComponent language={language}/>
                                
                                <DateRange
                                selectInitialDate={setInitialDate}
                                selectFinalDate={setFinalDate}
                                clearDateRange={clearAllFilters}
                                setClearDateRange={setClearAllFilters}
                                startInitialDate={initialDate}
                                startFinalDate={finalDate}
                                />

                                <TagsSelectors 
                                selectLabel={translate('add', language)}
                                clients={clients}
                                selectClients={setSelectedClients}
                                startSelectedClients={selectedClients}
                                setClearFilters={setClearAllFilters}
                                />

                                {(selectedClients || initialDate || finalDate)  && (
                                    <a onClick={() => { handleClearFilters() }}>
                                        {translate('clearFilters', language)}
                                    </a>
                                )}

                                <button
                                type="button"
                                onClick={() => { history.push('/create-briefing') }}
                                className={styles.createBriefing}
                                >
                                    {translate('createBriefing', language)}
                                </button>
                    </div>
                    <div className={styles.grid}>
                        <BriefingCardItem newBriefingCard/>
                        {briefings.map(briefing => (
                            <BriefingCardItem 
                                key={briefing.id}
                                id={briefing.id} 
                                isNew={briefing.new} 
                                cover_url={briefing.cover_url} 
                                client_name={briefing.client_name} 
                                text={briefing.text}
                                language={language}
                                />    
                            ))}
                    </div>
                {(counter !== 0 || filteredCounter !== 0) && lastPage !== 0 && (
                    <div className={`${styles.footer}`}>
                        <div className={`${styles.pagination}`}>
                        <button
                            disabled={page === 1}
                            type="button"
                            onClick={prevPage}
                        >
                            <span className={styles.backArrow} />
                        </button>
                        <input
                            type="number"
                            placeholder={page}
                            value={String(updatedPage)}
                            onChange={(e) => {updatedPageInput(parseInt(e.target.value, 10))}}
                        />
                        <span>de {lastPage}</span>
                        <button
                            disabled={page === lastPage}
                            type="button"
                            onClick={nextPage}
                        >
                            <span className={styles.nextArrow} />
                        </button>
                        </div>
                        <button type="button" className={styles.upButton} onClick={goTop}>
                        <span className={styles.upArrow} />
                        </button>
                    </div>
                )}
                </div>
            </div>
            {loading && (
                <div className={styles.loadingContainer}>
                <Loading fixed />
                </div>
            )}
        </div>
    )
}