import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import styles from './AttendancePresentation.module.scss';
import useOutsideClick from '../libs/useOutsideClick';

import PresentationConcept from '../components/PresentationConcept';
import PresentationPrints from '../components/PresentationPrints';
import FavoritePresentationPrints from '../components/FavoritePresentationPrints';
import OtherIdeasPresentationPrints from '../components/OtherIdeasPresentationPrints';
import Loading from '../components/Loading';
import PresentationPlayer from '../components/PresentationPlayer';
import ListSelector from '../components/ListSelector';
import ConfirmationModal from '../components/ConfirmationModal';
import PlusIcon from '../assets/icons/Plus';
import ShareIcon from '../assets/icons/Share';
import ArrowIcon from '../assets/icons/Arrow';
import { FiUser } from 'react-icons/fi';

function AttendancePresentation() {
  const dispatch = useDispatch();
  const language = useSelector(state => state.settings.language);
  const currentUser = useSelector(state => state.user);

  const { id, attendanceId } = useParams();
  const history = useHistory();

  const usersSelectorRef = useRef();

  const [presentation, setPresentation] = useState();
  const [prints, setPrints] = useState([]);
  const [concept, setConcept] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentSection, setCurrentSection] = useState('concept');
  const [loading, setLoading] = useState(false);
  const [slider, setSlider] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersSelector, setUsersSelector] = useState('closed');
  const [invites, setInvites] = useState([]);
  const [deletingInvite, setDeletingInvite] = useState(false);
  const [dolls, setDolls] = useState([]);
  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [artFinalists, setArtFinalists] = useState([]);

  const changeUsersSelector = state => {
    if (state === 'closed') {
      setUsersSelector('opened');
    } else {
      setUsersSelector('closed');
    }
  };

  useOutsideClick(usersSelectorRef, () => {
    changeUsersSelector(usersSelector);
  });

  useEffect(() => {
    (async function loadData() {
      setLoading(true);
      try {
        Api.getPresentation(id).then(res => {
          setPresentation(res);
          if (res.client) {
            Api.getPresentationSelectedPrintsByClient(id, res.client).then(
              psp => {
                setPrints(psp);
              }
            );
          } else {
            Api.getPresentationSelectedPrints(id).then(psp => {
              setPrints(psp);
            });
          }
          Api.getDollsByProfile(res.customer_profile).then(dbp => {
            setDolls(dbp);
          });
        });
        const getConcept = Api.getConcept(id);
        const getTags = Api.getTags();
        const getUsers = Api.getUsers();
        const getInvites = Api.getPresentationInvites(id);
        const getFabrics = Api.getFabrics();

        await axios
          .all([getTags, getUsers, getInvites, getConcept, getFabrics])
          .then(
            axios.spread(async (...responses) => {
              setTags(responses[0]);
              setUsers(responses[1]);
              setInvites(responses[2]);
              setConcept(responses[3].results);
              setFabrics(responses[4].results);
            })
          );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setLoading(false);
      }
      Api.getCollections().then(res => {
        setCollections(res);
      });
      Api.getClients().then(res => {
        setClients(res);
      });
      Api.getArtFinalists().then(res => {
        setArtFinalists(res);
      });
    })();
  }, [dispatch, id]);

  const showSlider = () => {
    setSlider(true);
  };

  const hideSlider = () => {
    setSlider(false);
  };

  const inviteUsers = () => {
    setUsersSelector('closed');
    setSelectedUsers([]);
    const invitePosts = [];
    selectedUsers.forEach(user => {
      const invite = Api.invite({ meeting: id, staff: user.id });
      invitePosts.push(invite);
    });
    axios
      .all(invitePosts)
      .then(() => {
        Api.getPresentationInvites(id).then(res => {
          setInvites(res);
        });
        toast(translate('successSendingInvites', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('failSendingInvites', language), {
          type: 'error'
        });
      });
  };

  const getInvitedUser = userId => {
    return users.find(user => user.id === userId);
  };

  const getNonInvitedUsers = useMemo(() => {
    let nonInvitedUsers = [...users];
    invites.forEach(invite => {
      nonInvitedUsers = nonInvitedUsers.filter(
        user => user.id !== invite.staff
      );
    });
    nonInvitedUsers = nonInvitedUsers.filter(
      user => user.id !== currentUser.id
    );

    return nonInvitedUsers;
  }, [currentUser, invites, users]);

  const updateConcept = () => {
    Api.getConcept(id).then(res => {
      setConcept(res.results);
    });
  };

  const updatePrints = () => {
    setLoading(true);
    Api.getPresentationSelectedPrints(id)
      .then(res => {
        setPrints(res);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteInvite = () => {
    setLoading(true);
    Api.deleteInvite(deletingInvite)
      .then(() => {
        Api.getPresentationInvites(id).then(res => {
          setInvites(res);
        });
      })
      .catch(() => {
        toast(translate('failDeletingInvite', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setDeletingInvite(false);
        setLoading(false);
      });
  };

  const getSlidePrints = useMemo(() => {
    const unifiedPrints = [];
    prints
      .filter(p => p.intentional_inserted)
      .forEach(print => {
        if (!unifiedPrints.find(uP => uP.print_id === print.print_id)) {
          unifiedPrints.push(print);
        }
      });

    return unifiedPrints;
  }, [prints]);

  return (
    <div className={styles.attendancePresentation}>
      <div className={styles.header}>
        <div className={styles.topHeader}>
          <h2 className={styles.sectionTitle}>
            <Link className={styles.return} to={`/attendances/${attendanceId}`}>
              <div className={styles.returnIconContainer}>
                <ArrowIcon color="#FFF" />
              </div>
              {translate('presentations', language)}
            </Link>
          </h2>
          <div className={styles.social}>
            <button
              type="button"
              className={styles.share}
              onClick={() =>
                history.push(`/attendances/${attendanceId}/link-creation`)
              }
            >
              {translate('share', language)} <ShareIcon />
            </button>
            <ul className={styles.invitedUsers}>
              {invites.map(invite => (
                <li key={invite.id}>
                  <button
                    className={styles.delete}
                    type="button"
                    onClick={() => setDeletingInvite(invite.id)}
                  >
                    -
                  </button>
                  <div className={styles.imageContainer}>
                  {getInvitedUser(invite.staff).image ? (
                        <img
                          src={getInvitedUser(invite.staff).image}
                          alt={getInvitedUser(invite.staff).name}
                          title={getInvitedUser(invite.staff).name}
                        />) : <FiUser size={25} title={getInvitedUser(invite.staff).name}/>}
                  </div>
                </li>
              ))}
            </ul>
            <div className={styles.userSelectorContainer}>
              <button
                type="button"
                className={styles.invite}
                onClick={() => setUsersSelector('opened')}
              >
                <PlusIcon color="#9BA7B7" />
              </button>
              {usersSelector === 'opened' && (
                <div className={styles.userSelector} ref={usersSelectorRef}>
                  <ListSelector
                    items={getNonInvitedUsers}
                    onSelect={setSelectedUsers}
                    selectLabel={translate('choose', language)}
                    multiple
                    values={selectedUsers}
                    search
                    action={inviteUsers}
                    actionText={translate('invite', language)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className={styles.sectionsMenu}>
        <li>
          <button
            className={`${currentSection === 'concept' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('concept')}
          >
            {translate('concept', language)}
          </button>
        </li>
        <li>
          <button
            className={`${currentSection === 'prints' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('prints')}
          >
            {translate('prints', language)}
          </button>
        </li>
        <li className={styles.break}>
          <button
            className={`${currentSection === 'favorites' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('favorites')}
          >
            {translate('favorites', language)}
          </button>
        </li>
        <li>
          <button
            className={`${currentSection === 'other-ideas' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('other-ideas')}
          >
            {translate('otherIdeas', language)}
          </button>
        </li>
      </ul>
      <div className={styles.content}>
        {presentation && (
          <>
            {currentSection === 'concept' && (
              <PresentationConcept
                presentation={presentation}
                playSlider={showSlider}
                concept={concept.sort((a, b) => a.position - b.position)}
                onUpdate={updateConcept}
              />
            )}
            {currentSection === 'prints' && (
              <PresentationPrints
                presentation={presentation}
                prints={prints}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {currentSection === 'favorites' && (
              <FavoritePresentationPrints
                presentation={presentation}
                prints={prints}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {currentSection === 'other-ideas' && (
              <OtherIdeasPresentationPrints
                presentation={presentation}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
          </>
        )}
        {loading && <Loading fixed />}
      </div>
      {slider && (
        <PresentationPlayer
          concept={concept}
          presentation={presentation}
          prints={getSlidePrints}
          close={hideSlider}
          section={currentSection}
        />
      )}
      {deletingInvite && (
        <ConfirmationModal
          title={translate('inviteDelete', language)}
          message={translate('inviteDeletingMessage', language)}
          confirmText={translate('yes', language)}
          cancelText={translate('no', language)}
          onConfirm={deleteInvite}
          onCancel={() => setDeletingInvite(false)}
          deleteWarning
        />
      )}
    </div>
  );
}

export default AttendancePresentation;
