export function addProductsOnCart(products) {
  return {
    type: '@carts/ADD_PRODUCTS',
    products
  };
}
export function removeProductOnCart(id, client) {
  return {
    type: '@carts/REMOVE_PRODUCT',
    id,
    client
  };
}

export function updateProductOnCart(product, client) {
  return {
    type: '@carts/UPDATE_PRODUCT',
    product,
    client
  };
}

export function clearClientCart(client) {
  return {
    type: '@carts/CLEAR_CART',
    client
  };
}