import styles from "./styles.module.scss";
import React, { useState } from "react";
import CloseIcon from '../../assets/icons/Close';
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap
} from "react-grid-drag";
import translate from '../../libs/i18n';
import { useSelector } from "react-redux";
import Api from "../../libs/Api";
import { toast } from "react-toastify";

export function ChangePositionModal({ prints, onClose, attPrints }) {
    const [filteredPrints, setFilteredPrints] = useState(prints);
    const [filteredDefaultPrints,] = useState(prints);
    const [printsWasChanged, setPrintsWasChanged] = useState(false);
    const language = useSelector(state => state.settings.language);
    async function handleSavePosition() {
        try {
            filteredPrints.map(async (print, index) => {
                if(print.position === index) return
                await Api.updatePrintPosition(print.id, {
                    position: index
                });
            })
            attPrints(filteredPrints);
            toast(translate("successSavePosition", language), {
                type: 'success'
            });
        } catch {   
            toast(translate("errorSavePosition", language), {
                type: 'error'
            });
        } finally {
            onClose();
        }
    }

    function onChange(sourceId, sourceIndex, targetIndex, targetId) {
        if(sourceIndex === targetIndex) return
        const nextState = swap(filteredPrints, sourceIndex, targetIndex);
        setFilteredPrints(nextState);
        setPrintsWasChanged(true);
    }
    
    return(
        <div className={styles.container}>
            {printsWasChanged && (
                <>
                    <button 
                    onClick={handleSavePosition} 
                    className={styles.defaultActionButton}
                    >
                    {translate("savePosition", language)}
                    </button>

                    <button 
                    onClick={() => {
                        setFilteredPrints(filteredDefaultPrints)
                        setPrintsWasChanged(!printsWasChanged)
                    }} 
                    className={styles.defaultCancelButton}
                    >
                    {translate("cancelText", language)}
                    </button>
                </>)}
             <button className={styles.closeButton} type="button" onClick={onClose}>
                <CloseIcon />
            </button>
            <GridContextProvider onChange={onChange}>
                <GridDropZone
                    id="items"
                    boxesPerRow={4}
                    rowHeight={280}
                    style={{ display:"flex", height: "4000px", width: "100%" }}
                >
                        {filteredPrints.map((print, index) => (
                            <GridItem key={print.print_code}>
                                <div className={styles.card}>
                                    {print.print_image_url ? (
                                        <div
                                            className={styles.imageContainer}
                                            style={
                                                print.flat_background
                                                ? { backgroundColor: print.flat_background_color }
                                                : {}
                                        }
                                        >
                                        <img
                                            className={styles.image}
                                            src={print.print_image_url}
                                            alt={print.id}
                                        />
                                        </div>
                                    ) : (
                                        <div className={styles.imageReplace} />
                                    )}
                                    <div className={styles.info}>
                                        <div className={styles.name}>{print.print_code} {`#${index + 1}`}</div>
                                        <hr />
                                    </div>
                                </div>
                            </GridItem>
                        ))}   
                </GridDropZone>
            </GridContextProvider>
        </div>
    )
}