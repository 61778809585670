export function setInitialDateFilter(initialDate) {
  return {
    type: '@libraryFilters/SET_INITIAL_DATE',
    initialDate
  };
}

export function setFinalDateFilter(finalDate) {
  return {
    type: '@libraryFilters/SET_FINAL_DATE',
    finalDate
  };
}

export function setReservedFilter(reserved) {
  return {
    type: '@libraryFilters/SET_RESERVED',
    reserved
  };
}

export function setStrikedFilter(striked) {
  return {
    type: '@libraryFilters/SET_STRIKED',
    striked
  };
}

export function setIsOriginFilter(isOrigin) {
  return {
    type: '@libraryFilters/SET_ISORIGIN',
    isOrigin
  };
}

export function setIsTwinFilter(isTwin) {
  return {
    type: '@libraryFilters/SET_ISTWIN',
    isTwin
  };
}

export function setIsColorVariantFilter(isColorVariant) {
  return {
    type: '@libraryFilters/SET_ISCOLORVARIANT',
    isColorVariant
  };
}

export function setIsPantoneFilter(isPantone) {
  return {
    type: '@libraryFilters/SET_ISPANTONE',
    isPantone
  };
}

export function setExclusiveFilter(exclusive) {
  return {
    type: '@libraryFilters/SET_EXCLUSIVE',
    exclusive
  };
}

export function setNoExclusiveFilter(noExclusive) {
  return {
    type: '@libraryFilters/SET_NO_EXCLUSIVE',
    noExclusive
  };
}

export function setDigitalFilter(digital) {
  return {
    type: '@libraryFilters/SET_DIGITAL',
    digital
  };
}

export function setCylinderFilter(cylinder) {
  return {
    type: '@libraryFilters/SET_CYLINDER',
    cylinder
  };
}

export function setDraftsFilter(drafts) {
  return {
    type: '@libraryFilters/SET_DRAFTS',
    drafts
  };
}

export function setColorVariationsFilter(colorVariations) {
  return {
    type: '@libraryFilters/SET_COLOR_VARIATIONS',
    colorVariations
  };
}

export function setPendingReviewFilter(pendingReview) {
  return {
    type: '@libraryFilters/SET_PENDING_REVIEW',
    pendingReview
  };
}

export function setApprovedFilter(approved) {
  return {
    type: '@libraryFilters/APPROVED',
    approved
  };
}

export function setNonApprovedFilter(nonApproved) {
  return {
    type: '@libraryFilters/NON_APPROVED',
    nonApproved
  };
}

export function setSelectedCollectionsFilter(selectedCollections) {
  return {
    type: '@libraryFilters/SET_SELECTED_COLLECTIONS',
    selectedCollections
  };
}

export function setSelectedClientsFilter(selectedClients) {
  return {
    type: '@libraryFilters/SET_SELECTED_CLIENTS',
    selectedClients
  };
}

export function setSelectedDesignersFilter(selectedDesigners) {
  return {
    type: '@libraryFilters/SET_SELECTED_DESIGNERS',
    selectedDesigners
  };
}

export function setSelectedArtFinalistsFilter(selectedArtFinalists) {
  return {
    type: '@libraryFilters/SET_SELECTED_ART_FINALISTS',
    selectedArtFinalists
  };
}

export function setSelectedProveniencesFilter(selectedProveniences) {
  return {
    type: '@libraryFilters/SET_SELECTED_PROVENIENCES',
    selectedProveniences
  };
}

export function setSelectedTagsFilter(selectedTags) {
  return {
    type: '@libraryFilters/SET_SELECTED_TAGS',
    selectedTags
  };
}

export function setFilter(filter) {
  return {
    type: '@libraryFilters/SET_FILTER',
    filter
  };
}

export default setInitialDateFilter;
