import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './EditAttendance.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';

import AttendanceForm from '../components/AttendanceForm';

function EditAttendance() {
  const language = useSelector(state => state.settings.language);
  const [attendance, setAttendance] = useState();
  const { id } = useParams();

  useEffect(() => {
    (async function loadPresentationData() {
      Api.getFullAttendance(id).then(res => {
        setAttendance(res);
      });
    })();
  }, [id]);

  return (
    <div className={styles.editAttendance}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          {translate('editAttendance', language).toUpperCase()}
        </h2>
      </div>
      {attendance && (
        <div className={styles.form}>
          <AttendanceForm initialData={attendance} />
        </div>
      )}
    </div>
  );
}

export default EditAttendance;
