import React, { useState } from 'react';
import styles from './styles.module.scss'
import SearchIcon from '../../assets/icons/Search';
import translate from '../../libs/i18n';
import { useBriefing } from '../../hooks/useBriefing';

export function SearchComponent( { language } ) {
    const { searchQueryInput, searchQuery } = useBriefing();

    return(
        <div className={styles.container}>
              <SearchIcon color="#a3b3c7" />
              <input
                type="search"
                value={searchQuery}
                onChange={(e) => {searchQueryInput(e.target.value)}}
                placeholder={translate('search', language)}
              />
        </div>
    )
}