const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case '@tags/SET_TAGS':
      return [...action.tags];
    default:
      return state;
  }
};

export default reducer;
