import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import CurrencyInput from 'react-currency-input';

import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';
import Api from '../libs/Api';
import {
  updateProductOnCart,
  removeProductOnCart,
  clearClientCart,
  addProductsOnCart
} from '../store/actions/carts';

import styles from './SellerCart.module.scss';

import logoMatch from '../assets/images/logo_match_1.svg';
import CloseIcon from '../assets/icons/Close';
import ListSelector from './ListSelector';
import TrashCanIcon from '../assets/icons/TrashCan';
import CheckInput from './CheckInput';
import PurchaseSetExclusivity from './PurchaseSetExclusivity';
import Sale from './Sale';
import Loading from './Loading';

function SellerCart({ close, initialClient }) {
  const language = useSelector(state => state.settings.language);
  const clientCarts = useSelector(state => state.carts);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [clientsSelector, setClientsSelector] = useState(false);
  const [usersSelector, setUsersSelector] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedUsersTrackingLink, setSelectedUsersTrackingLink] = useState([])
  const [clients, setClients] = useState([]);
  const [fabrics, setFabrics] = useState([]);

  const [clientOrders, setClientOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedOrderNumber, setSelectedOrderNumber] = useState();

  const [productsSelector, setProductsSelector] = useState(false);
  const [selectedProduct] = useState();

  const [currencySelector, setCurrencySelector] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [clientAreas, setClientAreas] = useState([]);
  const [selectedClientArea, setSelectedClientArea] = useState("");

  const [
    productionProductsCheckboxes,
    setProductionProductsCheckboxes
  ] = useState([]);
  const [reserveProductsCheckboxes, setReserveProductsCheckboxes] = useState(
    []
  );
  const [hangerProductsCheckboxes, setHangerProductsCheckboxes] = useState([]);
  const [strikeProductsCheckboxes, setStrikeProductsCheckboxes] = useState([]);
  const [
    noClassifiedProductsCheckboxes,
    setNoClassifiedProductsCheckboxes
  ] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedProductionType, setSelectedProductionType] = useState();
  const [meters, setMeters] = useState();
  const [currentMeters, setCurrentMeters] = useState();
  const [repetition, setRepetition] = useState(false);
  const [simple, setSimple] = useState(false);
  const [clientProducts, setClientProducts] = useState([]);

  const [exclusivityModal, setExclusivityModal] = useState(false);
  const [fabricsModal, setFabricsModal] = useState(false);
  const [checkedFabricsModal, setCheckedFabricsModal] = useState(false);
  const [fabricQuery, setFabricQuery] = useState();
  const [fabricOptions, setFabricOptions] = useState([]);

  const clientSelectorRef = useRef();
  const usersSelectorRef = useRef();
  const productsSelectorRef = useRef();
  const currencySelectorRef = useRef();
  const [users, setUsers] = useState([]);
  const [editingProduct, setEditingProduct] = useState();
  const [editingSingleMeters, setEditingSingleMeters] = useState(false);
  const [editingSinglePrice, setEditingSinglePrice] = useState(false);
  const [currentPrice, setCurrentPrice] = useState();
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [discount, setDiscount] = useState();

  const [loading, setLoading] = useState(false);

  const [saleModal, setSaleModal] = useState(false);
  const [saleInfo, setSaleInfo] = useState();

  const defaultCurrencyValue = 'brl';
  const filteredUsers = users?.filter(user => user?.client_user === selectedClient?.id)
  const productionTypeOptions = [
    {
      id: 1,
      value: 'PRODUCAO',
      name: 'production'
    },
    {
      id: 2,
      value: 'PILOTO',
      name: 'strike'
    },
    {
      id: 3,
      value: 'HANGER',
      name: 'hanger'
    },
    {
      id: 4,
      value: 'RESERVA',
      name: 'reserve'
    }
  ];
  useEffect(() => {
    if (initialClient) {
      setLoading(true);
    }
    const getClients = Api.getClients().then(res => {
      setClients(res);
      if (initialClient) {
        const client = res.find(c => c.id === initialClient);
        if (client) {
          setSelectedClient(client);
        }
      }
    });
    const getFabrics = Api.getFabrics().then(res => {
      setFabrics(res.results);
    });
    const getRegion = Api.getRegion().then(res => {
      setRegions(res);
    });
    const getCountries = Api.getCountries().then(res => {
      setCountries(res);
    });
    axios.all([getClients, getFabrics, getRegion, getCountries]).finally(() => {
      setLoading(false);
    });
  }, [initialClient]);

  useEffect(() => {
    setClientsSelector(false);
  }, [selectedClient]);

  useOutsideClick(clientSelectorRef, () => {
    setClientsSelector(false);
  });

  useOutsideClick(usersSelectorRef, () => {
    setUsersSelector(false);
  });

  useEffect(() => {
    setProductsSelector(false);
  }, [selectedProduct]);

  useOutsideClick(productsSelectorRef, () => {
    setProductsSelector(false);
  });

  useEffect(() => {
    setCurrencySelector(false);
  }, [selectedCurrency]);

  useOutsideClick(currencySelectorRef, () => {
    setCurrencySelector(false);
  });

  useEffect(() => {
    Api.getUsers()
    .then(response => setUsers(response))
  }, [])
  useEffect(() => {
    if(selectedClient) {
      Api.getClientAreas(selectedClient.id)
      .then(response => setClientAreas(response.results))
      .catch(() => setClientAreas([]));
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient) {
      setLoading(true);
      setSelectedOrder();
      Api.getClientOrders(selectedClient.id)
        .then(res => {
          setClientOrders(res.results);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient) {
      const allProducts = [...clientCarts];
      const filteredClientProducts = allProducts.filter(
        product => product.client === selectedClient.id
      );
      const updatedProds = [];
      filteredClientProducts.forEach((prod, index) => {
        const getPrint = Api.getMiniPrint(prod.print_id).then(print => {
          if (print && print.image) {
            filteredClientProducts[index].print_image_url = print.image;
          }
        });
        updatedProds.push(getPrint);
      });
      axios.all(updatedProds).then(() => {
        setClientProducts(filteredClientProducts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const getProductionProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => product.type === 'PRODUCAO'
    );

    return filtered;
  }, [clientProducts]);

  const getReserveProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => product.type === 'RESERVA'
    );

    return filtered;
  }, [clientProducts]);

  const getHangerProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => product.type === 'HANGER'
    );

    return filtered;
  }, [clientProducts]);

  const getStrikeProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => (product.type === 'PILOTO' || product.type === 'PILOTO_CLIENT')
    );

    return filtered;
  }, [clientProducts]);

  const getNoClassifiedProducts = useMemo(() => {
    const filtered = clientProducts.filter(product => !product.type);

    return filtered;
  }, [clientProducts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCheckedProducts = useCallback(() => {
    const checkedList = [];

    productionProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getProductionProducts[index]);
      }
    });

    hangerProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getHangerProducts[index]);
      }
    });

    strikeProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getStrikeProducts[index]);
      }
    });

    reserveProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getReserveProducts[index]);
      }
    });

    noClassifiedProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getNoClassifiedProducts[index]);
      }
    });
    return checkedList;
  });

  const clearCheckboxes = () => {
    let checks = [...noClassifiedProductsCheckboxes];
    getNoClassifiedProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setNoClassifiedProductsCheckboxes(checks);

    checks = [...productionProductsCheckboxes];
    getProductionProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setProductionProductsCheckboxes(checks);

    checks = [...reserveProductsCheckboxes];
    getReserveProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setReserveProductsCheckboxes(checks);

    checks = [...hangerProductsCheckboxes];
    getHangerProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setHangerProductsCheckboxes(checks);

    checks = [...strikeProductsCheckboxes];
    getStrikeProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setStrikeProductsCheckboxes(checks);
  };

  const duplicateItems = async() => {
    const checkedItens = getCheckedProducts();
    if(checkedItens.length === 0) {
      toast(translate('selectPrintsToContinue', language), {
        type: 'warning'
      });
      return
    }
    if(!selectedOrder) {
      checkedItens.forEach((checkedItem) => {
        const updatedCheckedItem = { 
          ...checkedItem,
          fabric_code: null
        }
        dispatch(addProductsOnCart([updatedCheckedItem]));
      });
      return
    }
    const allProducts = [...clientProducts];
    checkedItens.map((checkedItem) => {
      let updatedCheckedItem = {
        ...checkedItem,
        cart_id: allProducts.length,
        fabric_code: null
      }
      allProducts.push(updatedCheckedItem);
    })
    setClientProducts(allProducts);
  }

  const createProduct = async(checkedItem) => {
    const response = await Api.createProduct({
      fabric: null,
      print: checkedItem.print_id
    });
    return response
  }

  useEffect(() => {
    if (selectedProductionType) {
      let checked = getCheckedProducts();
      checked = checked.map(product => ({
        ...product,
        type: selectedProductionType.value
      }));

      const allProducts = [...clientProducts];
      allProducts.forEach((prod, i) => {
        checked.forEach(ch => {
          if (prod.cart_id === ch.cart_id) {
            allProducts[i] = { ...ch };
            dispatch(updateProductOnCart(allProducts[i], selectedClient?.id));
          }
        });
      });
      setClientProducts(allProducts);
      setSelectedProductionType();
      setProductsSelector(false);
      clearCheckboxes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientProducts,
    dispatch,
    getCheckedProducts,
    selectedClient,
    selectedProductionType
  ]);

  const modifyRepetitionSimple = (repetitionValue, simpleValue) => {
    setRepetition(repetitionValue);
    setSimple(simpleValue);

    let category = '';
    if (repetitionValue && simpleValue) {
      category = 'BOT';
    } else if (repetitionValue) {
      category = 'REP';
    } else if (simpleValue) {
      category = 'SIM';
    }

    let checked = getCheckedProducts();
    checked = checked.map(product => ({
      ...product,
      category
    }));

    const allProducts = [...clientProducts];
    allProducts.forEach((prod, i) => {
      checked.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          allProducts[i] = { ...ch };
        }
      });
    });
    setClientProducts(allProducts);
    setSelectedProductionType();
    setProductsSelector(false);
  };

  const modifyMeters = value => {
    setMeters(value);
    let checked = getCheckedProducts();
    checked = checked.map(product => ({
      ...product,
      meters: value
    }));

    const allProducts = [...clientProducts];
    allProducts.forEach((prod, i) => {
      checked.forEach(ch => {
        if (prod.cart_id === ch.cart_id) {
          allProducts[i] = { ...ch };
        }
      });
    });
    setClientProducts(allProducts);
    setSelectedProductionType();
    setProductsSelector(false);
  };

  const finishModifyingMeters = () => {
    const checked = getCheckedProducts();
    checked.forEach(prod => {
      dispatch(updateProductOnCart(prod, selectedClient?.id));
    });
  };

  const deleteProducts = () => {
    const selectedProducts = getCheckedProducts();
    let updatedProducts = [...clientProducts];
    selectedProducts.forEach(product => {
      if (product) {
        updatedProducts = updatedProducts.filter(
          p => p.cart_id !== product.cart_id
        );
        dispatch(removeProductOnCart(product.cart_id, product.client));
      }
    });
    clearCheckboxes();
    setClientProducts(updatedProducts);
  };
  const deleteProductOrders = () => {
    const selectedProducts = getCheckedProducts();
    let updatedProducts = [...clientProducts];
    selectedProducts.forEach(product => {
      if (product) {
        updatedProducts = updatedProducts.filter(
          p => p.cart_id !== product.cart_id
        );
        setLoading(true);
        Api.deleteProductOrder(product.id).finally(() => {
          setLoading(false);
        });
      }
    });
    clearCheckboxes();
    setClientProducts(updatedProducts);
  };

  useEffect(() => {
    let checks = [...noClassifiedProductsCheckboxes];
    getNoClassifiedProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setNoClassifiedProductsCheckboxes(checks);

    checks = [...productionProductsCheckboxes];
    getProductionProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setProductionProductsCheckboxes(checks);

    checks = [...reserveProductsCheckboxes];
    getReserveProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setReserveProductsCheckboxes(checks);

    checks = [...hangerProductsCheckboxes];
    getHangerProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setHangerProductsCheckboxes(checks);

    checks = [...strikeProductsCheckboxes];
    getStrikeProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setStrikeProductsCheckboxes(checks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const getExclusivityVarValue = value => {
    switch (value) {
      case 'COR':
        return translate('color', language);
      case 'DES':
        return translate('drawing', language);
      default:
        return '';
    }
  };

  useEffect(() => {
    const getExclusivityName = value => {
      switch (value) {
        case 'INF':
          return translate('eternal', language);

        case 'MON':
          return translate('worldwide', language);

        case 'CON':
          return translate('continental', language);

        case 'NAT':
          return translate('national', language);

        case 'REG':
          return translate('regional', language);

        case 'MKT':
          return translate('laEstampaMarketing', language);

        default:
          return '';
      }
    };

    const getExclusivityInt = value => {
      const int = countries.find(c => c.id === value);
      if (int) {
        return int.name;
      }
      return value;
    };

    const getExclusivityCont = value => {
      switch (value) {
        case 'AF':
          return translate('africa', language);
        case 'AM':
          return translate('america', language);
        case 'AN':
          return translate('antarctica', language);
        case 'EU':
          return translate('europe', language);
        case 'AS':
          return translate('asia', language);
        case 'OC':
          return translate('oceania', language);
        default:
          return '';
      }
    };

    const getExclusivityReg = value => {
      const reg = regions.find(r => r.id === value);
      if (reg) {
        return reg.name;
      }
      return value;
    };
    if (selectedOrder) {
      setLoading(true);
      Api.getOrder(selectedOrder)
        .then(res => {
          const updatedOrders = [...clientOrders];
          const orderIndex = updatedOrders.findIndex(
            order => order.id === selectedOrder
          );
          setSelectedOrderNumber(res.order_number)
          updatedOrders[orderIndex] = { ...res };

          const productOrderSet = [...res.productorder_set];

          if (res.discount) {
            setDiscount(parseFloat(res.discount));
          }

          const getExRequest = [];
          productOrderSet.forEach((po, index) => {
            if (po.print_id) {
              po.cart_id = index;
              const exRequest = Api.getExclusivity(po.print_id).then(r => {
                productOrderSet[index].print_exclusivity = [];
                productOrderSet[index].print_exclusivity_int = [];
                productOrderSet[index].print_exclusivity_cont = [];
                productOrderSet[index].print_exclusivity_reg = [];
                r.results.forEach(ex => {
                  if (ex.exclusivity) {
                    productOrderSet[index].print_exclusivity.push({
                      value: ex.exclusivity,
                      name: getExclusivityName(ex.exclusivity)
                    });
                  }
                  if (ex.exclusivity_var) {
                    productOrderSet[index].print_exclusivity_var =
                      ex.exclusivity_var;
                  }
                  ex.exclusivity_int.forEach(int => {
                    productOrderSet[index].print_exclusivity_int.push({
                      value: int,
                      name: getExclusivityInt(int)
                    });
                  });
                  ex.exclusivity_cont.forEach(cont => {
                    productOrderSet[index].print_exclusivity_cont.push({
                      value: cont,
                      name: getExclusivityCont(cont)
                    });
                  });
                  ex.exclusivity_reg.forEach(reg => {
                    productOrderSet[index].print_exclusivity_int.push({
                      value: reg,
                      name: getExclusivityReg(reg)
                    });
                  });
                });
              });
              getExRequest.push(exRequest);
            }
          });
          axios.all(getExRequest).then(() => {
            setClientProducts([...productOrderSet]);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientOrders, countries, language, regions, selectedOrder]);

  useEffect(() => {
    if (!selectedOrder && selectedClient) {
      const allProducts = [...clientCarts];
      const filteredClientProducts = allProducts.filter(
        product => product.client === selectedClient.id
      );
      const updatedProds = [];
      filteredClientProducts.forEach((prod, index) => {
        const getPrint = Api.getMiniPrint(prod.print_id).then(print => {
          if (print && print.image_url) {
            filteredClientProducts[index].print_image_url = print.image;
          }
        });
        updatedProds.push(getPrint);
      });
      axios.all(updatedProds).then(() => {
        setClientProducts(filteredClientProducts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, clientCarts, selectedOrder]);

  const getSectionTotal = products => {
    let total = 0;
    products.forEach(prod => {
      if (prod.price_per_meter && prod.meters) {
        total += parseFloat(
          prod.type === 'HANGER'
            ? prod.price_per_meter
            : prod.price_per_meter * prod.meters
        );
      }
    });
    return total.toFixed(2);
  };

  const getSubtotal = () => {
    const productionTotal = parseFloat(getSectionTotal(getProductionProducts));
    const strikeTotal = parseFloat(getSectionTotal(getStrikeProducts));
    const hangerTotal = parseFloat(getSectionTotal(getHangerProducts));

    const total = productionTotal + strikeTotal + hangerTotal;

    return total;
  };

  const getDiscountedSubtotal = () => {
    const productionTotal = parseFloat(getSectionTotal(getProductionProducts));
    const strikeTotal = parseFloat(getSectionTotal(getStrikeProducts));
    const hangerTotal = parseFloat(getSectionTotal(getHangerProducts));

    const total =
      productionTotal + strikeTotal + hangerTotal - parseFloat(discount || 0);

    return total;
  };

  const updateOrder = status => {
    let updatedProductOrders;
    if (status === 'OPEN') {
      updatedProductOrders = [...clientProducts];
    } else {
      updatedProductOrders = [...getCheckedProducts()];
    }

    if (updatedProductOrders.length) {
      setLoading(true);
      let allRequiredFields = true;

      getCheckedProducts().forEach(product => {
        if (product.type === 'PRODUCAO') {
          if (!product.meters) {
            toast(translate('setProductionMeters', language), {
              type: 'warning'
            });
            allRequiredFields = false;
            setLoading(false);
          }
          if (!product.fabric_id) {
            toast(translate('setProductionFabric', language), {
              type: 'warning'
            });
            setLoading(false);
            allRequiredFields = false;
          }
        }
        if (product.type === 'PILOTO') {
          if (!product.meters) {
            toast(translate('setStrikeMeters', language), {
              type: 'warning'
            });
            setLoading(false);
            allRequiredFields = false;
          }
          if (!product.fabric_id) {
            toast(translate('setStrikeFabric', language), {
              type: 'warning'
            });
            setLoading(false);
            allRequiredFields = false;
          }
        }
      });
      if (allRequiredFields) {
        const updateRequests = [];
        updatedProductOrders.forEach(productOrder => {
          const request = Api.createProduct({
            fabric: productOrder.fabric_id ? productOrder.fabric_id : null,
            print: productOrder.print_id
          }).then(async res => {
            await Api.updateProductOrder(productOrder.id, {
              meters: productOrder.meters || 0,
              product: res.id,
              type: productOrder.type,
              price_per_meter: productOrder.price_per_meter || 0,
              category: productOrder.category || ''
            }).then(() => {
              setLoading(false);
            });
            const printExclusivities = await Api.getExclusivity(
              productOrder.print_id
            ).then(exc => {
              return exc.results;
            });
            const deleteExclusivities = [];

            printExclusivities.forEach(ex => {
              const deleteReq = Api.deleteExclusivity(ex.id);
              deleteExclusivities.push(deleteReq);
            });

            await axios.all(deleteExclusivities).then(() => {
              const exclusivities = [];
              const newProducts = [...getCheckedProducts()];
              if (selectedCurrency) {
                const total = getDiscountedSubtotal();
                Api.updateOrder(selectedOrder, {
                  status,
                  currency: selectedCurrency.value,
                  total_price: total || 0,
                  seller: user.id,
                  discount: parseFloat(discount || 0)
                }).catch(() => {
                  setLoading(false);
                });
              } else {
                Api.updateOrder(selectedOrder, {
                  status,
                  seller: user.id
                }).catch(() => {
                  setLoading(false);
                });
              }

              newProducts.forEach(p => {
                if (!exclusivities.find(e => e.print === p.print_id)) {
                  if (
                    p.type === 'PRODUCAO' &&
                    Array.isArray(p.print_exclusivity)
                  ) {
                    p.print_exclusivity.forEach(ex => {
                      if (ex.value === 'NAT') {
                        exclusivities.push({
                          print: p.print_id,
                          exclusivity: ex.value,
                          exclusivity_var: p.print_exclusivity_var,
                          exclusivity_int: p.print_exclusivity_int.map(
                            item => item.id
                          ),
                          buyer: selectedClient.id,
                          print_order_id: selectedOrderNumber
                        });
                      }
                      if (ex.value === 'REG') {
                        exclusivities.push({
                          print: p.print_id,
                          exclusivity: ex.value,
                          exclusivity_var: p.print_exclusivity_var,
                          exclusivity_reg: p.print_exclusivity_reg.map(
                            item => item.id
                          ),
                          buyer: selectedClient.id,
                          print_order_id: selectedOrderNumber
                        });
                      }
                      if (ex.value === 'CON') {
                        exclusivities.push({
                          print: p.print_id,
                          exclusivity: ex.value,
                          exclusivity_var: p.print_exclusivity_var,
                          exclusivity_cont: p.print_exclusivity_cont.map(
                            item => item.value
                          ),
                          buyer: selectedClient.id,
                          print_order_id: selectedOrderNumber
                        });
                      }
                      if (
                        ex.value !== 'NAT' &&
                        ex.value !== 'REG' &&
                        ex.value !== 'CON'
                      ) {
                        exclusivities.push({
                          print: p.print_id,
                          exclusivity_var: p.print_exclusivity_var,
                          exclusivity: ex.value,
                          buyer: selectedClient.id,
                          print_order_id: selectedOrderNumber
                        });
                      }
                    });
                  }
                }
                const createExclusivitiesReq = [];
                exclusivities.forEach(item => {
                  const exclusivityReq =  Api.createExclusivity(item);
                  createExclusivitiesReq.push(exclusivityReq);
                });
                if (createExclusivitiesReq.length) {
                  setLoading(true);
                  axios.all(createExclusivitiesReq).then(() => {
                    setLoading(false);
                    if (status === 'PROD') {
                      // setPurchaseSuccess(true);
                      const total = getSubtotal();
                      setSaleInfo({
                        client: selectedClient,
                        order: selectedOrder,
                        total,
                        currency: selectedCurrency
                          ? selectedCurrency.value
                          : defaultCurrencyValue
                      });
                      setSaleModal(true);
                    } else {
                      setLoading();
                      Api.getClientOrders(selectedClient.id)
                        .then(r => {
                          setClientOrders(r.results);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }
                    dispatch(clearClientCart(clientProducts[0].client));
                  });
                } else {
                  const total = getDiscountedSubtotal();
                  setSaleInfo({
                    client: selectedClient,
                    order: selectedOrder,
                    total,
                    currency: selectedCurrency
                      ? selectedCurrency.value
                      : defaultCurrencyValue
                  });
                  setSaleModal(true);
                  dispatch(clearClientCart(clientProducts[0].client));
                }
              });
            });
          });
          updateRequests.push(request);
        });
        axios.all(updateRequests).then(() => {});
      }
    } else {
      setLoading(false);
    }
  };

  const saveDraft = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const createOrder = status => {
    const exclusivities = [];
    const updatedProducts = [...getCheckedProducts()];
    if (updatedProducts.length) {
      let allRequiredFields = true;

      updatedProducts.forEach(product => {
        if (product.type === 'PRODUCAO') {
          if (!product.meters) {
            toast(translate('setProductionMeters', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
          if (!product.fabric_id) {
            toast(translate('setProductionFabric', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
        }
        if (product.type === 'PILOTO') {
          if (!product.meters) {
            toast(translate('setStrikeMeters', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
          if (!product.fabric_id) {
            toast(translate('setStrikeFabric', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
        }
      });
      if (allRequiredFields) {
        setLoading(true);
        updatedProducts.forEach(p => {
          if (!exclusivities.find(e => e.print === p.print_id)) {
            if (p.type === 'PRODUCAO' && Array.isArray(p.print_exclusivity)) {
              p.print_exclusivity.forEach(ex => {
                if (ex.value === 'NAT') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_int: p.print_exclusivity_int.map(
                      item => item.id
                    ),
                    buyer: selectedClient.id,
                    print_order_id: selectedOrderNumber
                  });
                }
                if (ex.value === 'REG') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_reg: p.print_exclusivity_reg.map(
                      item => item.id
                    ),
                    buyer: selectedClient.id,
                    print_order_id: selectedOrderNumber
                  });
                }
                if (ex.value === 'CON') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_cont: p.print_exclusivity_cont.map(
                      item => item.value
                    ),
                    buyer: selectedClient.id,
                    print_order_id: selectedOrderNumber
                  });
                }
                if (
                  ex.value !== 'NAT' &&
                  ex.value !== 'REG' &&
                  ex.value !== 'CON'
                ) {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity: ex.value,
                    buyer: selectedClient.id,
                    print_order_id: selectedOrderNumber
                  });
                }
              });
            }
          }
        });

        const updateProductsRequests = [];
        updatedProducts.forEach((cp, index) => {
          const formData = new FormData();
          if (cp.fabric_id) {
            formData.append('fabric', cp.fabric_id);
          }

          formData.append('print', cp.print_id);
          const update = Api.createProduct(formData)
            .then(res => {
              updatedProducts[index] = {
                ...updatedProducts[index],
                product_id: res.id
              };
            })
            .catch(() => {
              setLoading(false);
            });
          updateProductsRequests.push(update);
        });

        axios.all(updateProductsRequests).then(() => {
          const usersIdsNotification = selectedUsersTrackingLink?.map(user => user?.id);
          Api.createOrder({
            client: clientProducts[0].client,
            customer_meeting: clientProducts[0].presentation,
            seller: user.id,
            status,
            currency: selectedCurrency
              ? selectedCurrency.value
              : defaultCurrencyValue,
            total_price: getDiscountedSubtotal() || 0,
            discount: parseFloat(discount || 0),
            area: selectedClientArea,
            user_notification: usersIdsNotification
          })
            .then(res => {
              const orderId = res.id;
              const order_number = res.order_number
              const orders = [];
              updatedProducts.forEach(product => {
                if (product.type) {
                  const order = Api.createProductOrder({
                    type: product.type,
                    meters: product.meters || 0,
                    order: orderId,
                    product: product.product_id,
                    category: product.category || '',
                    price_per_meter: product.price_per_meter
                  }).catch(() => {
                    setLoading(false);
                  });
                  orders.push(order);
                }
              });
              if (orders.length) {
                axios
                  .all(orders)
                  .then(() => {
                    const createExclusivitiesReq = [];
                    exclusivities.forEach(item => {
                      item.print_order_id = order_number;
                      const exclusivityReq = Api.createExclusivity(item);
                      createExclusivitiesReq.push(exclusivityReq);
                    });
                    if (createExclusivitiesReq.length) {
                      axios.all(createExclusivitiesReq).then(() => {
                        toast(translate('createdOrdersSuccess', language), {
                          type: 'success'
                        });
                        if (status === 'PROD') {
                          setSaleInfo({
                            client: selectedClient,
                            order: selectedOrder,
                            total: getDiscountedSubtotal() || 0,
                            currency: selectedCurrency
                              ? selectedCurrency.value
                              : defaultCurrencyValue
                          });
                          setSaleModal(true);
                        }
                        dispatch(clearClientCart(clientProducts[0].client));
                      });
                    } else {
                      setSaleInfo({
                        client: selectedClient,
                        order: selectedOrder,
                        total: getDiscountedSubtotal() || 0,
                        currency: selectedCurrency
                          ? selectedCurrency.value
                          : defaultCurrencyValue
                      });
                      setSaleModal(true);
                      dispatch(clearClientCart(clientProducts[0].client));
                    }
                  })
                  .catch(() => {
                    toast(translate('failCreatingOrders', language), {
                      type: 'error'
                    });
                    setLoading(false);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              } else {
                toast(translate('selectProductionType', language), {
                  type: 'error'
                });
                setLoading(false);
              }
            })
            .catch(() => {
              setLoading(false);
            });
        });
      }
    }
  };

  const manageOrder = status => {
    if (selectedOrder) {
      if (clientOrders.find(co => co.id === selectedOrder).status === 'PROD') {
        updateOrder('PROD');
      } else {
        updateOrder(status);
      }
    } else {
      createOrder(status);
    }
  };

  const openExclusivityModal = () => {
    if (getCheckedProducts().length) {
      setExclusivityModal(true);
    }
  };

  const applyExclusivity = exclusivityPurchase => {
    const exclusivity = [];
    const exclusivityInt = [];
    const exclusivityReg = [];
    const exclusivityCont = [];

    exclusivityPurchase.forEach(ep => {
      if (ep.exclusivity) {
        exclusivity.push(ep.exclusivity);
      }
      if (ep.exclusivity_int) {
        ep.exclusivity_int.forEach(e => {
          exclusivityInt.push(e);
        });
      }
      if (ep.exclusivity_reg) {
        ep.exclusivity_reg.forEach(e => {
          exclusivityReg.push(e);
        });
      }
      if (ep.exclusivity_cont) {
        ep.exclusivity_cont.forEach(e => {
          exclusivityCont.push(e);
        });
      }
    });
    const updatedProducts = [...clientProducts];
    if (editingProduct) {
      const productIndex = updatedProducts.findIndex(
        prod => prod.cart_id === editingProduct
      );
      updatedProducts[productIndex] = {
        ...updatedProducts[productIndex],
        print_exclusivity: exclusivity,
        print_exclusivity_int: exclusivityInt,
        print_exclusivity_reg: exclusivityReg,
        print_exclusivity_cont: exclusivityCont,
        print_exclusivity_var: exclusivityPurchase[0]
          ? exclusivityPurchase[0].exclusivity_var
          : ''
      };
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient?.id)
      );
    } else {
      const checkedProducts = getCheckedProducts();
      checkedProducts.forEach((cp, index) => {
        checkedProducts[index] = {
          ...checkedProducts[index],
          print_exclusivity: exclusivity,
          print_exclusivity_int: exclusivityInt,
          print_exclusivity_reg: exclusivityReg,
          print_exclusivity_cont: exclusivityCont,
          print_exclusivity_var: exclusivityPurchase[0]
            ? exclusivityPurchase[0].exclusivity_var
            : ''
        };
        dispatch(updateProductOnCart(checkedProducts[index], selectedClient?.id));
      });
      updatedProducts.forEach((prod, i) => {
        checkedProducts.forEach(ch => {
          if (prod.cart_id === ch.cart_id) {
            updatedProducts[i] = { ...ch };
          }
        });
      });
    }
    setClientProducts(updatedProducts);
    setExclusivityModal(false);
    setEditingProduct();
  };

  const applyAllFabric = fabric => {
    console.log(fabric)
    const checkedProducts = getCheckedProducts();
    const filteredProducts = checkedProducts.filter(cp => cp.type === "PRODUCAO");
    const updatedProducts = filteredProducts.map(fp => {
      return {
        ...fp,
        fabric_id: fabric.id,
        fabric_name: fabric.name
      }
    })
    const allProducts = [...clientProducts];
    allProducts.forEach((prod, i) => {
      updatedProducts.forEach(ch => {
        if (prod.cart_id === ch.cart_id) {
          allProducts[i] = { ...ch };
        }
        dispatch(
          updateProductOnCart(allProducts[i], selectedClient?.id)
        );
      });
    });   
    setClientProducts(allProducts);
    setCheckedFabricsModal(false);
    setEditingProduct();
  };
  console.log("oi3", clientProducts)
  const applyFabric = fabric => {
    const updatedProducts = [...clientProducts];
    const getProductIndex = updatedProducts.findIndex(up => up.cart_id === editingProduct)
    updatedProducts[getProductIndex] = {
      ...updatedProducts[getProductIndex],
      fabric_id: fabric.id,
      fabric_name: fabric.name
    };
    dispatch(
      updateProductOnCart(updatedProducts[getProductIndex], selectedClient?.id)
    );
    setClientProducts(updatedProducts);
    setFabricsModal(false);
    setEditingProduct();
  };

  const handleChangePrice = (event, value) => {
    setCurrentPrice(value);
  };

  const finishEditingMeters = () => {
    const updatedProducts = [...clientProducts];
    const getProductIndex = updatedProducts.findIndex(up => up.cart_id === editingProduct)
    updatedProducts[getProductIndex] = {
      ...updatedProducts[getProductIndex],
      meters: currentMeters
    };
    if (!selectedOrder) {
      dispatch(
        updateProductOnCart(updatedProducts[getProductIndex], selectedClient?.id)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentMeters();
    setEditingSingleMeters(false);
  };

  const finishEditingPrice = () => {
    const updatedProducts = [...clientProducts];
    const getProductIndex = updatedProducts.findIndex(up => up.cart_id === editingProduct)
    updatedProducts[getProductIndex] = {
      ...updatedProducts[getProductIndex],
      price_per_meter: currentPrice
    };
    if (!selectedOrder) {
      dispatch(
        updateProductOnCart(updatedProducts[getProductIndex], selectedClient?.id)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
  };
  const getCurrencySymbol = useMemo(() => {
    if (selectedCurrency) {
      switch (selectedCurrency.value) {
        case 'brl':
          return 'R$';
        case 'usd':
          return '$';
        case 'eur':
          return '€';
        default:
          return '';
      }
    }
    return '';
  }, [selectedCurrency]);

  const formatedPrice = value => {
    if (selectedCurrency) {
      if (selectedCurrency) {
        switch (selectedCurrency.value) {
          case 'brl':
            return value.replace('.', ',');
          case 'eur':
            return value.replace('.', ',');
          case 'usd':
            return value;
          default:
            return value;
        }
      }
    }
    return value;
  };

  const closeSaleModal = () => {
    setSaleModal(false);
    setSaleInfo();
  };

  useEffect(() => {
    if (selectedOrder) {
      const order = clientOrders.find(co => co.id === selectedOrder);
      switch (order.currency) {
        case 'brl':
          setSelectedCurrency({
            id: 1,
            name: 'BRL - Real',
            value: 'brl'
          });
          break;
        case 'eur':
          setSelectedCurrency({
            id: 2,
            name: 'EUR - Euro',
            value: 'eur'
          });
          break;
        case 'usd':
          setSelectedCurrency({
            id: 3,
            name: 'USD - Dollar',
            value: 'usd'
          });
          break;
        default:
          break;
      }
    } else {
      setSelectedCurrency({
        id: 1,
        name: 'BRL - Real',
        value: 'brl'
      });
    }
  }, [clientOrders, selectedOrder]);

  const renderMetersArea = product => (
    <div className={styles.metersArea}>
      {!editingSingleMeters && (
        <button
          type="button"
          onClick={() => {
            setEditingProduct(product.cart_id);
            setEditingSingleMeters(true);
            setCurrentMeters(product.meters);
            setEditingSinglePrice(false);
          }}
        >
          <span>{product.meters ? `${product.meters}m` : '0m'}</span>
        </button>
      )}
      {(editingProduct === product.cart_id) && editingSingleMeters && (
          <div className={styles.singleMetersInput}>
            <div>
              <input
                type="number"
                className={styles.meters}
                value={currentMeters}
                onChange={e => setCurrentMeters(e.currentTarget.value)}
                onKeyDown={e =>
                  e.key === 'Enter' ? finishEditingMeters : false
                }
              />
              <span>{translate('meters', language)} </span>
            </div>
            <button type="button" onClick={finishEditingMeters}>
              {translate('save', language)}
            </button>
          </div>
        )}
    </div>
  );

  const renderPriceArea = product => (
    <div
      className={`${styles.priceArea} ${product.type === 'HANGER' &&
        styles.hangerPrice}`}
    >
      {!editingSinglePrice && (
        <button
          type="button"
          onClick={() => {
            setEditingProduct(product.cart_id);
            setCurrentPrice(product.price_per_meter);
            setEditingSinglePrice(true);
            setEditingSingleMeters(false);
          }}
        >
          <span>
            <div>
              {product.type === 'HANGER'
                ? translate('price', language)
                : translate('pricePerMeter', language)}
              :
            </div>
            {product.price_per_meter && product.price_per_meter !== '0.00'
              ? `${getCurrencySymbol}${formatedPrice(
                  parseFloat(product.price_per_meter).toFixed(2)
                )}`
              : ''}
          </span>
        </button>
      )}
      {(editingProduct === product.cart_id) &&
        editingSinglePrice && (
          <div>
            <div>
              {product.type === 'HANGER'
                ? translate('price', language)
                : translate('pricePerMeter', language)}
              :
            </div>
            {getCurrencySymbol}
            <CurrencyInput
              value={currentPrice}
              onChange={handleChangePrice}
              decimalSeparator={
                selectedCurrency && selectedCurrency.value === 'usd' ? '.' : ','
              }
              thousandSeparator={
                selectedCurrency && selectedCurrency.value === 'usd' ? ',' : '.'
              }
            />
            <button type="button" onClick={finishEditingPrice}>
              {translate('save', language)}
            </button>
          </div>
        )}
      {product.type !== 'HANGER' && (
        <div>
          <div className={styles.itemPrice}>
            {product.price_per_meter &&
            product.price_per_meter !== '0.00' &&
            product.meters
              ? `${getCurrencySymbol}${formatedPrice(
                  parseFloat(product.price_per_meter * product.meters).toFixed(
                    2
                  )
                )}`
              : ''}
          </div>
        </div>
      )}
    </div>
  );

  const defineFabric = (cart_id, initialFabrics) => {
    setEditingProduct(cart_id);
    if (initialFabrics && initialFabrics.length) {
      const filtered = [];
      initialFabrics.forEach(f => {
        const fab = fabrics.find(item => item.id === f.id);
        if (fab) {
          filtered.push(fab);
        }
      });
      setFabricOptions([...filtered]);
    } else {
      setFabricOptions([...fabrics]);
    }

    setFabricsModal(true);
  };

  const defineAllFabric = (initialFabrics) => {
    if (initialFabrics && initialFabrics.length) {
      const filtered = [];
      initialFabrics.forEach(f => {
        const fab = fabrics.find(item => item.id === f.id);
        if (fab) {
          filtered.push(fab);
        }
      });
      setFabricOptions([...filtered]);
    } else {
      setFabricOptions([...fabrics]);
    }

    setCheckedFabricsModal(true);
  };

  const defineExclusivity = cart_id => {
    setEditingProduct(cart_id);
    setExclusivityModal(true);
  };

  const returnToPresentation = () => {
    close();
  };

  const saleSuccess = () => {
    setSaleModal(false);
    setSaleInfo();
    setPurchaseSuccess(true);
  };

  const getClientOrders = useMemo(() => {
    return (
      <>
        {selectedOrder && (
          <li>
            <button
              type="button"
              onClick={() => setSelectedOrder()}
              className={styles.draft}
            >
              {translate('draft', language)}
            </button>
          </li>
        )}

        {clientOrders.map(order => (
          <li
            key={order.id}
            className={selectedOrder === order.id ? styles.hide : ''}
          >
            <button type="button" onClick={() => setSelectedOrder(order.id)}>
              {language === 'en'
                ? moment(order.date).format('MM/DD/YYYY')
                : moment(order.date).format('DD/MM/YYYY')}{' '}
              - {translate(order.status, language)}
            </button>
          </li>
        ))}
      </>
    );
  }, [clientOrders, language, selectedOrder]);

  const filteredFabrics = useMemo(() => {
    if (fabricQuery) {
      return fabricOptions.filter(f => {
        let t = '';

        if (language === 'en') {
          if (f && f.description_en && f.description_en.length) {
            t = `${t} ${f.description_en}`;
          }

          if (f && f.mixture_en && f.mixture_en.length) {
            t = `${t} ${f.mixture_en}`;
          }

          if (f && f.name_en && f.name_en.length) {
            t = `${t} ${f.name_en}`;
          }

          if (f && f.small_description_en && f.small_description_en.length) {
            t = `${t} ${f.small_description_en}`;
          }
        } else {
          if (f && f.description && f.description.length) {
            t = `${t} ${f.description}`;
          }

          if (f && f.mixture && f.mixture.length) {
            t = `${t} ${f.mixture}`;
          }

          if (f && f.name && f.name.length) {
            t = `${t} ${f.name}`;
          }

          if (f && f.small_description && f.small_description.length) {
            t = `${t} ${f.small_description}`;
          }
        }

        if (f.grammage) {
          t = `${t} ${f.grammage}`;
        }

        if (f.throughput) {
          t = `${t} ${f.throughput}`;
        }

        if (f.width) {
          t = `${t} ${f.width}`;
        }

        if (
          t
            .trim()
            .toLowerCase()
            .includes(fabricQuery.toLowerCase())
        ) {
          return f;
        }

        return null;
      });
    }

    return fabricOptions;
  }, [fabricOptions, fabricQuery, language]);

  const getTotalsItems = () => {
    return (getProductionProducts.length + getHangerProducts.length + getStrikeProducts.length + getNoClassifiedProducts.length)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={logoMatch} alt="blend." className={styles.logo} />
        <button type="button" className={styles.close} onClick={close}>
          <CloseIcon />
        </button>
      </div>
      <hr />
      <div className={styles.clientFilter}>
        <p>{translate('reserveProductionSummary')}</p>
        <div className={styles.clientSelectorContainer} ref={clientSelectorRef}>
          <button
            id=""
            type="button"
            className={`${styles.defaultSelectInput} ${styles.selectInput}`}
            onClick={() => setClientsSelector('opened')}
          >
            {selectedClient
              ? selectedClient.name
              : translate('chooseClient', language)}
          </button>
          {clientsSelector === 'opened' && (
            <span>
              <ListSelector
                label={translate('clients', language)}
                selectLabel={translate('choose', language)}
                items={clients}
                onSelect={setSelectedClient}
                value={selectedClient}
                search
              />
            </span>
          )}
          {(selectedClient && clientAreas && clientAreas.length > 0) && (
            <select
              onChange={(e) => { setSelectedClientArea(e.target.value) }}
            >
              <option value="">{translate('selectClientArea', language)}</option>
              {clientAreas.map(clientArea => <option value={clientArea.id}>{clientArea.alias}</option>)}
            </select>
          )}
        </div>
        {(filteredUsers?.length > 0) && (
          <div className={styles.userSelectorContainer} ref={usersSelectorRef}>
            <p>{'Delivery tracking list'}</p>
            <button
              id=""
              type="button"
              className={`${styles.defaultSelectInput} ${styles.selectInput}`}
              onClick={() => setUsersSelector('opened')}
            >
              {selectedUsersTrackingLink?.length > 0 ? selectedUsersTrackingLink?.map(user => `${user?.name}, `) : 'Select a user'}
            </button>
            {usersSelector === 'opened' && (
              <span className={styles.containerUser}>
                <ListSelector
                  multiple
                  label={translate('clients', language)}
                  selectLabel={translate('choose', language)}
                  items={filteredUsers}
                  onSelect={setSelectedUsersTrackingLink}
                  remove
                  values={selectedUsersTrackingLink}
                  search
                />
              </span>
            )}
          </div>
        )}
      </div>
      
      <div className={styles.draftText}>
        {selectedOrder ? (
          <>
            {clientOrders.find(co => co.id === selectedOrder) ? (
              <>
                {translate(
                  clientOrders.find(co => co.id === selectedOrder).status,
                  language
                )}{' '}
                -{' '}
                {language === 'en'
                  ? moment(
                      clientOrders.find(co => co.id === selectedOrder).date
                    ).format('MM/DD/YYYY')
                  : moment(
                      clientOrders.find(co => co.id === selectedOrder).date
                    ).format('DD/MM/YYYY')}
              </>
            ) : (
              ''
            )}{' '}
          </>
        ) : (
          selectedClient && (
            <>
              {translate('OPEN', language)}{' '}
              <span>({translate('draft', language)})</span>
            </>
          )
        )}
      </div>

      {selectedClient ? (
        <>
          <div className={styles.deleteContainer}>
            <CheckInput
              id="selecteAll"
              onChange={setSelectAll}
              value={selectAll}
            />
            <span className={styles.separator} />
            <button
              className={styles.deleteProducts}
              type="button"
              onClick={selectedOrder ? deleteProductOrders : deleteProducts}
            >
              <TrashCanIcon color="#202730" />
            </button>
            <span className={styles.separator} />
            <div className={styles.productModifiers}>
              <div className={styles.modifiers}>
                {user.role.includes('coord-seller') && (
                  <button
                    type="button"
                    className={styles.exclusivity}
                    onClick={openExclusivityModal}
                  >
                    {translate('exclusivity', language)}
                  </button>
                )}

                <div
                  className={styles.productionSelectorContainer}
                  ref={productsSelectorRef}
                >
                  <button
                    id=""
                    type="button"
                    className={`${styles.defaultSelectInput} ${styles.production}`}
                    onClick={() => setProductsSelector(true)}
                  >
                    {selectedProduct
                      ? selectedProduct.name
                      : translate('typeOfProduction', language)}
                  </button>
                  {productsSelector && (
                    <span>
                      <ListSelector
                        selectLabel={translate('choose', language)}
                        items={productionTypeOptions}
                        onSelect={setSelectedProductionType}
                        value={selectedProductionType}
                      />
                    </span>
                  )}
                  
                </div>
                <div className={styles.metersInputContainer}>
                  <input
                    type="number"
                    className={styles.meters}
                    value={meters}
                    onChange={e => modifyMeters(e.currentTarget.value)}
                    onKeyDown={e =>
                      e.key === 'Enter' ? finishModifyingMeters() : false
                    }
                    onBlur={finishModifyingMeters}
                  />
                  <span>{translate('meters', language)}</span>
                </div>
                <div className={styles.checks}>
                  <CheckInput
                    id="repetition"
                    onChange={val => modifyRepetitionSimple(val, simple)}
                    value={repetition}
                    text={translate('repetition', language)}
                  />
                  <CheckInput
                    id="simple"
                    onChange={val => modifyRepetitionSimple(repetition, val)}
                    value={simple}
                    text={translate('simple', language)}
                  />
                </div>
                <div>
                  <button
                    className={styles.exclusivity}
                    onClick={duplicateItems}
                  >
                    {translate('duplicate', language)}
                  </button>
                </div>
                <div>
                  <button
                    className={styles.exclusivity}
                    onClick={defineAllFabric}
                  >
                    {translate('defineFabric', language)}
                  </button>
                </div>
              </div>
              <div
                className={styles.currencySelectorContainer}
                ref={currencySelectorRef}
              >
                <button
                  id=""
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.production}`}
                  onClick={() => setCurrencySelector(true)}
                >
                  {selectedCurrency
                    ? selectedCurrency.name
                    : translate('currency', language)}
                </button>

                {currencySelector && (
                  <span>
                    <ListSelector
                      selectLabel={translate('choose', language)}
                      items={[
                        {
                          id: 1,
                          name: 'BRL - Real',
                          value: 'brl'
                        },
                        {
                          id: 2,
                          name: 'EUR - Euro',
                          value: 'eur'
                        },
                        {
                          id: 3,
                          name: 'USD - Dollar',
                          value: 'usd'
                        }
                      ]}
                      onSelect={setSelectedCurrency}
                      value={selectedCurrency}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.selectClientWarning}>
          {translate('selectClientToShowSummary', language)}
        </div>
      )}

      {getNoClassifiedProducts.length > 0 && (
        <div className={styles.products}>
          <ul className={styles.productsList}>
            {getNoClassifiedProducts.map((product, index) => (
              <li className={styles.product}>
                <div className={styles.productCheck}>
                  <CheckInput
                    id={`noClassified${index}`}
                    value={noClassifiedProductsCheckboxes[index]}
                    onChange={val => {
                      const checkboxes = [...noClassifiedProductsCheckboxes];
                      checkboxes[index] = val;
                      setNoClassifiedProductsCheckboxes(checkboxes);
                    }}
                  />
                </div>
                <div
                  className={styles.imageContainer}
                  style={{
                    backgroundColor: product.pantone_color
                      ? product.pantone_color
                      : '#808d9e'
                  }}
                >
                  {product.print_image_url && (
                    <img src={product.print_image_url} alt="print" />
                  )}
                </div>
                <div className={styles.info}>
                  <div className={styles.code}>{product.print_code}</div>
                  {product.color_variant && (
                    <div>
                      {translate('colorVariant', language)}:{' '}
                      {product.color_variant}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.sectionGroup}>
        {getProductionProducts.length > 0 && (
          <>
            <div className={styles.section}>
            No. {translate('production', language)}: {getProductionProducts.length}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getProductionProducts.map((product, index) => (
                  <li className={styles.product}>
                    <div className={styles.productCheck}>
                      <CheckInput
                        id={`production${index}`}
                        value={productionProductsCheckboxes[index]}
                        onChange={val => {
                          const checkboxes = [...productionProductsCheckboxes];
                          checkboxes[index] = val;
                          setProductionProductsCheckboxes(checkboxes);
                        }}
                      />
                    </div>
                    <div
                      className={styles.imageContainer}
                      style={{
                        backgroundColor: product.pantone_color
                          ? product.pantone_color
                          : '#808d9e'
                      }}
                    >
                      {product.print_image_url && (
                        <img src={product.print_image_url} alt="print" />
                      )}
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                      {product.color_variant && (
                        <div>
                          {translate('colorVariant', language)}:{' '}
                          {product.color_variant}
                        </div>
                      )}
                      <div>
                        {product.fabric_name && !selectedOrder && (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.cart_id,
                                product.initialFabrics
                              )
                            }
                          >
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </button>
                        )}
                        {product.fabric_name && selectedOrder && (
                          <>
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </>
                        )}
                        {!selectedOrder && !product.fabric_id && (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.cart_id,
                                product.initialFabrics
                              )
                            }
                          >
                            {translate('defineFabric', language)}
                          </button>
                        )}
                        {product.fabric_name && product.meters ? ' - ' : ''}
                        {renderMetersArea(product)}
                        {(product.fabric_name || product.meters) &&
                        product.category
                          ? ' - '
                          : ''}
                        {product.category === 'SIM' &&
                          translate('simple', language)}
                        {product.category === 'REP' &&
                          translate('repetition', language)}
                        {product.category === 'BOT' &&
                          translate('simpleRepetition', language)}
                      </div>
                      <div className={styles.exclusivityInfo}>
                        {!Array.isArray(product.print_exclusivity) &&
                          user.role.includes('coord-seller') && (
                            <button
                              className={styles.defineExclusivity}
                              type="button"
                              onClick={() =>
                                defineExclusivity(product.cart_id)
                              }
                            >
                              {translate('defineExclusivity', language)}
                            </button>
                          )}
                        <div>
                          {getExclusivityVarValue(
                            product.print_exclusivity_var ||
                              product.exclusivity_var
                          )}
                        </div>
                        {product.print_exclusivity &&
                          Array.isArray(product.print_exclusivity) &&
                          product.print_exclusivity.length > 0 && (
                            <div>
                              {translate('exclusivity', language)} :{' '}
                              {product.print_exclusivity.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                        {product.print_exclusivity_int &&
                          product.print_exclusivity_int.length > 0 && (
                            <div>
                              {product.print_exclusivity_int.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                        {product.print_exclusivity_reg &&
                          product.print_exclusivity_reg.length > 0 && (
                            <div>
                              {product.print_exclusivity_reg.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                        {product.print_exclusivity_cont &&
                          product.print_exclusivity_cont.length > 0 && (
                            <div>
                              {product.print_exclusivity_cont.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    {renderPriceArea(product)}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {getProductionProducts.length > 0 && (
          <div className={styles.sectionTotals}>
            {translate('productionValue', language)}:{' '}
            <span>{`${getCurrencySymbol}${getSectionTotal(
              getProductionProducts
            )}`}</span>
          </div>
        )}
      </div>
      <div className={styles.sectionGroup}>
        {getStrikeProducts.length > 0 && (
          <>
            <div className={styles.section}>
            No. {translate('strike', language)}: {getStrikeProducts.length}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getStrikeProducts.map((product, index) => (
                  <li className={styles.product}>
                    <div className={styles.productCheck}>
                      <CheckInput
                        id={`strike${index}`}
                        value={strikeProductsCheckboxes[index]}
                        onChange={val => {
                          const checkboxes = [...strikeProductsCheckboxes];
                          checkboxes[index] = val;
                          setStrikeProductsCheckboxes(checkboxes);
                        }}
                      />
                    </div>
                    <div
                      className={styles.imageContainer}
                      style={{
                        backgroundColor: product.pantone_color
                          ? product.pantone_color
                          : '#808d9e'
                      }}
                    >
                      {product.print_image_url && (
                        <img src={product.print_image_url} alt="print" />
                      )}
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                      {product.color_variant && (
                        <div>
                          {translate('colorVariant', language)}:{' '}
                          {product.color_variant}
                        </div>
                      )}
                      <div>
                        {product.fabric_name && !selectedOrder ? (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.cart_id,
                                product.initialFabrics
                              )
                            }
                          >
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </button>
                        ) : (
                          <>
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </>
                        )}
                        {!product.fabric_id && !selectedOrder && (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.cart_id,
                                product.initialFabrics
                              )
                            }
                          >
                            {translate('defineFabric', language)}
                          </button>
                        )}
                        {product.fabric_name && product.meters ? ' - ' : ''}
                        {renderMetersArea(product)}
                        {(product.fabric_name || product.meters) &&
                        product.category
                          ? ' - '
                          : ''}
                        {product.category === 'SIM' &&
                          translate('simple', language)}
                        {product.category === 'REP' &&
                          translate('repetition', language)}
                        {product.category === 'BOT' &&
                          translate('simpleRepetition', language)}
                      </div>
                      <div>{product.idCode ? `ID ${product.idCode}` : ''}</div>
                    </div>
                    {renderPriceArea(product)}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {getStrikeProducts.length > 0 && (
          <div className={styles.sectionTotals}>
            {translate('strikeValue', language)}:{' '}
            <span>{`${getCurrencySymbol}${getSectionTotal(
              getStrikeProducts
            )}`}</span>
          </div>
        )}
      </div>
      <div className={styles.sectionGroup}>
        {getReserveProducts.length > 0 && (
          <>
            <div className={styles.section}>
              No. {translate('reserve', language)}: {getReserveProducts.length}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getReserveProducts.map((product, index) => (
                  <li className={styles.product}>
                    <div className={styles.productCheck}>
                      <CheckInput
                        id={`reserve${index}`}
                        value={reserveProductsCheckboxes[index]}
                        onChange={val => {
                          const checkboxes = [...reserveProductsCheckboxes];
                          checkboxes[index] = val;
                          setReserveProductsCheckboxes(checkboxes);
                        }}
                      />
                    </div>
                    <div
                      className={styles.imageContainer}
                      style={{
                        backgroundColor: product.pantone_color
                          ? product.pantone_color
                          : '#808d9e'
                      }}
                    >
                      {product.print_image_url && (
                        <img src={product.print_image_url} alt="print" />
                      )}
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                      <div className={styles.exclusivityInfo} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
      <div className={styles.sectionGroup}>
        {getHangerProducts.length > 0 && (
          <>
            <div className={styles.section}>
              No. {translate('hanger', language)}: {getHangerProducts.length}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getHangerProducts.map((product, index) => (
                  <li className={styles.product}>
                    <div className={styles.productCheck}>
                      <CheckInput
                        id={`hanger${index}`}
                        value={hangerProductsCheckboxes[index]}
                        onChange={val => {
                          const checkboxes = [...hangerProductsCheckboxes];
                          checkboxes[index] = val;
                          setHangerProductsCheckboxes(checkboxes);
                        }}
                      />
                    </div>
                    <div
                      className={styles.imageContainer}
                      style={{
                        backgroundColor: product.pantone_color
                          ? product.pantone_color
                          : '#808d9e'
                      }}
                    >
                      {product.print_image_url && (
                        <img src={product.print_image_url} alt="print" />
                      )}
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                    </div>
                    {renderPriceArea(product)}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {getHangerProducts.length > 0 && (
          <div className={styles.sectionTotals}>
            {translate('hangerValue', language)}:{' '}
            <span>{`${getCurrencySymbol}${getSectionTotal(
              getHangerProducts
            )}`}</span>
          </div>
        )}
      </div>
      {getProductionProducts.length > 0 && (
        <div className={`${styles.sectionTotals} ${styles.subTotal}`}>
          <span>Subtotal: {`${getCurrencySymbol}${getSubtotal()}`}</span>
        </div>
      )}
      {selectedOrder && (
        <div className={`${styles.sectionTotals} `}>
          <span className={styles.discount}>
            {translate('discount', language)}: {getCurrencySymbol}
            <CurrencyInput
              value={discount}
              onChange={(e, val) => setDiscount(val)}
              decimalSeparator={
                selectedCurrency && selectedCurrency.value === 'usd' ? '.' : ','
              }
              thousandSeparator={
                selectedCurrency && selectedCurrency.value === 'usd' ? ',' : '.'
              }
            />
          </span>
        </div>
      )}
      {selectedOrder && (
        <div className={`${styles.sectionTotals}`}>
          <div className={styles.finalTotal}>
            <div>{translate('totalValue', language)}</div>
            <div>{`${getCurrencySymbol}${getDiscountedSubtotal()}`}</div>
          </div>
        </div>
      )}
      {clientProducts.length > 0 && (
        <>
          <div className={styles.actionsContainer}>
            <strong>No. Total Items: {getTotalsItems()}</strong>
            <button
              className={`${styles.defaultActionButton} ${styles.saveAsDraft}`}
              type="button"
              onClick={selectedOrder ? () => manageOrder('OPEN') : saveDraft}
            >
              {selectedOrder
                ? translate('save', language)
                : translate('saveAsDraft', language)}
            </button>
            <button
              className={styles.defaultActionButton}
              type="button"
              onClick={() => manageOrder('REV')}
            >
              {translate('continue', language)}*
            </button>
            
          </div>
          <div className={styles.selectPrintsWarning}>
            {translate('selectPrintsToContinue', language)}*
          </div>
        </>
      )}

      {selectedClient && clientOrders.length > 0 && (
        <ul className={styles.clientOrders}>{getClientOrders}</ul>
      )}
      {selectedClient && clientOrders.length === 0 && (
        <div className={styles.selectClientWarning}>
          {translate('emptyClientSummary', language)}
        </div>
      )}

      {exclusivityModal && (
        <div className={styles.exclusivityModal}>
          <PurchaseSetExclusivity
            exclusivityPurchase={applyExclusivity}
            onClose={() => setExclusivityModal(false)}
          />
        </div>
      )}
      {fabricsModal && (
        <>
          <div className={styles.fabricsModalContainer}>
            <div className={styles.modal}>
              <h1>{translate('chooseAFabric', language)}</h1>
              <button
                type="button"
                className={styles.close}
                onClick={() => setFabricsModal(false)}
              >
                <CloseIcon />
              </button>
              <input
                type="search"
                placeholder="Search"
                value={fabricQuery}
                onChange={e => setFabricQuery(e.currentTarget.value)}
              />
              <ul className={styles.fabricsList}>
                {filteredFabrics.map(fab => (
                  <li>
                    <button type="button" onClick={() => applyFabric(fab)}>
                      <img src={fab.image} alt={fab.name} />
                      <div className={styles.fabricInfoContainer}>
                        <div className={styles.fabricName}>{fab.name}</div>
                        <div className={styles.fabricInfo}>
                          <div className={styles.item}>
                            <span>{translate('grammage', language)}</span>:{' '}
                            {fab.grammage} g/m<sup>2</sup>
                          </div>
                          <div className={styles.item}>
                            <span>{translate('width', language)}</span>:{' '}
                            {fab.width} cm
                          </div>
                          <div className={styles.item}>
                            <span>{translate('composition', language)}</span>:{' '}
                            {fab.mixture}
                          </div>
                          <div className={styles.item}>
                            <span>{translate('yield', language)}</span>:{' '}
                            {fab.throughput} M/KG
                          </div>
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}

      {checkedFabricsModal && (
        <>
          <div className={styles.fabricsModalContainer}>
            <div className={styles.modal}>
              <h1>{translate('chooseAFabric', language)}</h1>
              <button
                type="button"
                className={styles.close}
                onClick={() => setFabricsModal(false)}
              >
                <CloseIcon />
              </button>
              <input
                type="search"
                placeholder="Search"
                value={fabricQuery}
                onChange={e => setFabricQuery(e.currentTarget.value)}
              />
              <ul className={styles.fabricsList}>
                {filteredFabrics.map(fab => (
                  <li>
                    <button type="button" onClick={() => applyAllFabric(fab)}>
                      <img src={fab.image} alt={fab.name} />
                      <div className={styles.fabricInfoContainer}>
                        <div className={styles.fabricName}>{fab.name}</div>
                        <div className={styles.fabricInfo}>
                          <div className={styles.item}>
                            <span>{translate('grammage', language)}</span>:{' '}
                            {fab.grammage} g/m<sup>2</sup>
                          </div>
                          <div className={styles.item}>
                            <span>{translate('width', language)}</span>:{' '}
                            {fab.width} cm
                          </div>
                          <div className={styles.item}>
                            <span>{translate('composition', language)}</span>:{' '}
                            {fab.mixture}
                          </div>
                          <div className={styles.item}>
                            <span>{translate('yield', language)}</span>:{' '}
                            {fab.throughput} M/KG
                          </div>
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}  

      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
      {purchaseSuccess && (
        <button
          type="button"
          className={styles.purchaseSuccess}
          onClick={returnToPresentation}
        >
          <h1>{translate('processedOrderSuccess', language)}</h1>
          <p>{translate('goToPresentation', language)}</p>
        </button>
      )}
      {saleModal && (
        <Sale
          close={closeSaleModal}
          order={saleInfo.order}
          client={saleInfo.client}
          initialTotal={saleInfo.total}
          currency={saleInfo.currency}
          saleSuccess={saleSuccess}
        />
      )}
    </div>
  );
}

export default SellerCart;