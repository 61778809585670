import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import styles from './ClientPresentationPrints.module.scss';
import translate from '../libs/i18n';
import { addProductsOnClientCart } from '../store/actions/clientCart';

import Api from '../libs/Api';
import PlayIcon from '../assets/icons/Play';
import CheckInput from '../components/CheckInput';
import PrintGridItem from '../components/PrintGridItem';
import Loading from '../components/Loading';
import ClientPurchasePrint from '../components/ClientPurchasePrint';
import PresentationPlayer from '../components/PresentationPlayer';

function ClientPresentationPrints() {
  const { id, attendanceId } = useParams();
  const language = useSelector(state => state.settings.language);
  const clientCarts = useSelector(state => state.carts);
  const token = useSelector(state => state.loggedClient.token);

  const dispatch = useDispatch();

  const [selectedArtFinalists] = useState([]);
  const [selectedProveniences] = useState([]);
  const [seeColorVariations, setSeeColorVariations] = useState(true);
  const [purchasingPrintId, setPurchasingPrintId] = useState();
  const [purchasingPrints, setPurchasingPrints] = useState([]);
  const [showPresentation, setShowPresentation] = useState(false);
  const [dragOrigin, setDragOrigin] = useState();
  const [dragDestiny, setDragDestiny] = useState();
  const [draggablePrints, setDraggablePrints] = useState([]);
  const [prints, setPrints] = useState([]);
  const [pantones, setPantones] = useState([]);
  const [presentation, setPresentation] = useState();

  const [concept, setConcept] = useState();
  const [dolls, setDolls] = useState([]);
  const [fabrics, setFabrics] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setDraggablePrints(prints);
  }, [prints]);

  useEffect(() => {
    (async function onLoad() {
      setLoading(true);
      const getConcept = Api.getConcept(id);
      const getDolls = Api.getPresentationDolls(id);
      const getFabrics = Api.getFabrics();
      const getPresentation = Api.getClientPresentation(token, id);
      await axios
        .all([getConcept, getDolls, getFabrics, getPresentation])
        .then(res => {
          setConcept(res[0].results);
          setDolls(res[1]);
          setFabrics(res[2].results);
          setPresentation(res[3]);
          setPrints(res[3].selected_prints);
          setPantones(res[3].selected_prints?.filter(ndp => ndp.is_pantone === true))
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [id, token]);
  const renderCard = (print, index) => {
    const addToCart = selectedPrintId => {
      const products = prints.filter(p => p.print_id === selectedPrintId);
      const initialFabrics = [];
      products.forEach(dfp => {
        if (dfp.fabric_id) {
          initialFabrics.push({
            id: dfp.fabric_id,
            name: dfp.fabric_name
          });
        }
      });
      let angraFabric;
      if (!initialFabrics.length) {
        angraFabric = fabrics.find(fab => fab.name === 'ANGRA');
      } else {
        angraFabric = initialFabrics.find(fab => fab.name === 'ANGRA');
      }

      products.forEach((prod, i) => {
        products[i] = {
          ...prod,
          cart_id: clientCarts.length,
          initialFabrics: [...initialFabrics],
          client: presentation.client,
          customer_meeting: presentation.id,
          action: null,
          meters: '',
          product: prod.product_id,
          fabric_id: angraFabric ? angraFabric.id : prod.fabric_id,
          fabric_name: angraFabric ? angraFabric.name : prod.fabric_name
        };
      });
      dispatch(addProductsOnClientCart([products[0]]));
      toast(translate('printAddedToCart', language), {
        type: 'success'
      });
    };

    const onDragStart = (e, i) => {
      setDragOrigin(i);
    };

    const onDragOver = (e, i) => {
      if (i !== setDragDestiny) {
        setDragDestiny(i);
      }
    };

    const onDragEnd = () => {
      const updatedDraggablePrints = [...draggablePrints];
      const draggadedPrint = updatedDraggablePrints[dragOrigin];
      updatedDraggablePrints[dragOrigin] = updatedDraggablePrints[dragDestiny];
      updatedDraggablePrints[dragDestiny] = draggadedPrint;
      setDraggablePrints(updatedDraggablePrints);
      setDragOrigin();
      setDragDestiny();
    };

    return (
      <div
        key={`${print.id}`}
        className={`${styles.printCard} ${
          index === dragDestiny ? styles.dragOver : ''
        }`}
        draggable
        onDragStart={e => onDragStart(e, index)}
        onDragOver={e => onDragOver(e, index)}
        onDragEnd={e => onDragEnd(e, index)}
      >
        <PrintGridItem
          presentation
          id={print.print_id}
          image={print.print_image_url || print.image_url}
          code={print.print_code || print.code}
          tags={print.tags}
          name={print.print_name}
          purchaseSelect={() => setPurchasingPrintId(print.print_id)}
          addToCart={addToCart}
          doll={{
            image: presentation.dolly,
            shadow: presentation.dolly_shadow,
            rapport_propor: presentation.rapport_propor
          }}
          printJpegWidth={print.print_jpeg_width}
          printJpegHeight={print.print_jpeg_height}
          printApplication={print.print_application}
          backgroundColor={
            print.flat_background && print.flat_background_color
              ? print.flat_background_color
              : false
          }
          pantone={print.is_pantone ? print.pantone_color : false}
          dollyMasks={presentation.dolly_masks}
        />
      </div>
    );
  };

  const filteredPrints = useMemo(() => {
    if (prints) {
      let finalPrints = [...prints];
      if ((selectedArtFinalists, selectedProveniences)) {
        selectedArtFinalists.forEach(artFinalist => {
          finalPrints = finalPrints.filter(
            print => print.art_finisher_id === artFinalist.id
          );
        });
        selectedProveniences.forEach(provenience => {
          finalPrints = finalPrints.filter(
            print => print.provenience === provenience.value
          );
        });
      }
      if (!seeColorVariations) {
        const updatedFinalPrints = [];
        finalPrints.forEach(p => {
          if (p.print_is_origin || !p.print_color_variant) {
            updatedFinalPrints.push(p);
          }
        });

        finalPrints.forEach(p => {
          if (
            p.print_color_variant &&
            !finalPrints.find(
              fP =>
                fP.print_code.split(' ')[0] === p.print_code.split(' ')[0] &&
                p.print_code.split(' ')[1] &&
                ((fP.print_code.split(' ')[1] &&
                  fP.print_code.split(' ')[1] < p.print_code.split(' ')[1]) ||
                  fP.print_is_origin)
            )
          ) {
            updatedFinalPrints.push(p);
          }
        });

        finalPrints = [...updatedFinalPrints];
      }

      return finalPrints;
    }

    return [];
  }, [seeColorVariations, prints, selectedArtFinalists, selectedProveniences]);

  useEffect(() => {
    const printCards = [];
    filteredPrints.forEach(p => {
      if (!printCards.find(pc => pc.print_id === p.print_id)) {
        printCards.push(p);
      }
    });

    const printCardsSort = printCards.sort((a, b) => a.position - b.position);

    setDraggablePrints(printCardsSort);
  }, [filteredPrints]);

  const renderedGrid = () => {
    return (
      <div className={styles.printsGrid}>
        <div>
          {draggablePrints.map((print, index) => {
            if (index % 4 === 0) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
        <div>
          {draggablePrints.map((print, index) => {
            if (index % 4 === 1) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
        <div>
          {draggablePrints.map((print, index) => {
            if (index % 4 === 2) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
        <div>
          {draggablePrints.map((print, index) => {
            if (index % 4 === 3) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (purchasingPrintId) {
      const purchasing = [];

      prints.forEach(p => {
        if (p.print_id === purchasingPrintId) {
          purchasing.push(p);
        }
      });

      const variations = [];

      prints.forEach(p => {
        if (p.print_id !== purchasingPrintId) {
          if (
            (p.print_code &&
              p.print_code.includes(purchasing[0].print_code.split(' ')[0])) ||
            purchasing[0].print_code.includes(p.print_code)
          ) {
            variations.push(p);
          }
        }
      });
      setPurchasingPrints([...purchasing, ...variations]);
    }
  }, [prints, purchasingPrintId]);

  const nextPrint = () => {
    const currentIndex = draggablePrints.findIndex(
      print => print.print_id === purchasingPrintId
    );

    if (currentIndex === draggablePrints.length - 1) {
      setPurchasingPrintId(draggablePrints[0].print_id);
    } else {
      setPurchasingPrintId(draggablePrints[currentIndex + 1].print_id);
    }
  };

  const previousPrint = () => {
    const currentIndex = draggablePrints.findIndex(
      print => print.print_id === purchasingPrintId
    );

    if (currentIndex > 0) {
      setPurchasingPrintId(draggablePrints[currentIndex - 1].print_id);
    } else {
      setPurchasingPrintId(
        draggablePrints[draggablePrints.length - 1].print_id
      );
    }
  };

  return (
    <>
      <div className={styles.presentationConcept}>
        <div className={styles.header}>
          <ul className={styles.sectionsMenu}>
            {concept && concept.length > 0 && (
              <li>
                <NavLink
                  to={
                    attendanceId
                      ? `/client/${attendanceId}/presentations/${id}/concept`
                      : `/client/${id}/concept`
                  }
                  activeClassName={styles.activeLink}
                >
                  {translate('concept', language)}
                </NavLink>
              </li>
            )}

            <li>
              <NavLink
                to={
                  attendanceId
                    ? `/client/${attendanceId}/presentations/${id}/prints`
                    : `/client/${id}/prints`
                }
                activeClassName={styles.activeLink}
              >
                {translate('prints', language)}
              </NavLink>
            </li>
          </ul>
          <div className={styles.checkArea}>
            <CheckInput
              text={translate('seeColorVariations', language)}
              value={seeColorVariations}
              onChange={setSeeColorVariations}
            />
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.actions} />
        </div>
        <div className={styles.mainInfo}>
          <button type="button">
            <h1>{presentation && presentation.meeting_name}</h1>
          </button>

          <button type="button">
            <p>{presentation && presentation.meeting_briefing}</p>
          </button>
        </div>
        <button
          className={styles.play}
          type="button"
          onClick={() => setShowPresentation(true)}
        >
          <PlayIcon />
          Play
        </button>
        {renderedGrid()}
      </div>
      {loading && <Loading fixed />}
      {purchasingPrintId && (
        <ClientPurchasePrint
          onClose={() => setPurchasingPrintId()}
          prints={purchasingPrints}
          pantones={pantones}
          presentation={presentation}
          dolls={dolls}
          client={presentation.client}
          nextPrint={nextPrint}
          previousPrint={previousPrint}
        />
      )}
      {showPresentation && (
        <PresentationPlayer
          concept={concept || []}
          prints={draggablePrints || []}
          presentation={presentation}
          close={() => setShowPresentation(false)}
          section="prints"
        />
      )}
    </>
  );
}

ClientPresentationPrints.propTypes = {
  // playSlider: PropTypes.func.isRequired
};

export default ClientPresentationPrints;
