import React from "react"
import DatePicker from 'react-date-picker';

export function SingleDatePicker({selectedDate, setSelectedDate, isDisabled = false, minDate}) {
    return(
        <DatePicker
            value={selectedDate}
            onChange={setSelectedDate}
            format="dd/MM/y"
            clearIcon={null}
            calendarIcon={null}
            disabled={isDisabled}
            minDate={minDate}
        />
    )
}