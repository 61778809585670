import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss"
import Api from "../../libs/Api";
import { PurchaseOrderTable } from "./PurchaseOrderTable";
import { FilterComponent } from "./FilterComponent";
import translate from '../../libs/i18n';
import { useSelector } from "react-redux";
import { Pagination } from 'antd';
export function SupplierItens() {
    const [supplierItens, setSupplierItens] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState('');
    //@ts-ignore
    const language = useSelector(state => state.settings.language);
    async function getInitialData(page: number, filter: any) {
        try {
            const response = await Api.getOrderSupplierItens(page, filter);
            setSupplierItens(response?.results);
            setCount(response?.count);
        } catch {

        } finally {

        }
    }
    useEffect(() => {
        getInitialData(page, filter);
    }, [page])

    return(
        <div className={styles.container}>
            <h1>{translate("supplierItens", language)?.toLocaleUpperCase()}</h1>
            <FilterComponent 
                filter={filter}
                setFilter={setFilter} 
                handleFilterData={() => {
                    setPage(1);
                    getInitialData(1, filter)
                }} 
                handleClearFilterData={() => {
                    setPage(1);
                    getInitialData(1, "")
                }}
            />
            <PurchaseOrderTable 
                data={supplierItens} 
                filteredData={supplierItens} 
            />
            <Pagination 
                total={count}
                onChange={(page) => setPage(page)}
                current={page}
                pageSize={30}
                showSizeChanger={false}
            />
        </div>
    )
}