import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import styles from './CreatePantone.module.scss';

import Api from '../libs/Api';
import translate from '../libs/i18n';
import Loading from '../components/Loading';

function CreatePantone() {
  const language = useSelector(state => state.settings.language);

  const [name, setName] = useState('');
  const [requiredName, setRequiredName] = useState(false);
  const [hexColor, setHexColor] = useState('');
  const [requiredValidHexColor, setRequiredValidHexColor] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRequiredName(false);
  }, [name]);

  useEffect(() => {
    setRequiredValidHexColor(false);
  }, [hexColor]);

  const isFormValid = () => {
    let valid = true;

    if (!name) {
      valid = false;
      setRequiredName(true);
    }

    if (!/^([0-9a-f]{6}|[0-9a-f]{3})$/i.test(hexColor)) {
      valid = false;
      setRequiredValidHexColor(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        type: 'warning'
      });
    }

    return valid;
  };

  const createPantone = e => {
    e.preventDefault();
    if (isFormValid()) {
      setLoading(true);
      Api.createPantone({
        name,
        is_pantone: true,
        pantone_color: `#${hexColor}`,
        status: 'APP'
      })
        .then(() => {
          toast(translate('successCreatingPantone', language), {
            type: 'success'
          });
          setName('');
          setHexColor('');
        })
        .catch(() => {
          toast(translate('failCreatingPantone', language), {
            type: 'error'
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.createPantone}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          {translate('registerPantone', language).toUpperCase()}
        </h2>
      </div>
      <div className={styles.form}>
        <div className={styles.simpleInputContainer}>
          <input
            className={`${styles.defaultTextInput} ${
              requiredName ? styles.error : ''
            }`}
            placeholder={translate('pantoneName', language)}
            value={name}
            onChange={e => setName(e.currentTarget.value)}
            name="name"
          />
          {requiredName && (
            <span className={styles.errorMessage}>
              {translate('requiredField', language)}
            </span>
          )}
        </div>
        <div className={styles.simpleInputContainer}>
          <span className={styles.inputLabel}>
            {translate('hexColorCode', language)}
          </span>
          <div className={styles.colorContainer}>
            <input
              className={`${styles.defaultTextInput} ${
                requiredValidHexColor ? styles.error : ''
              }`}
              placeholder="FFFFFF"
              value={hexColor}
              onChange={e => setHexColor(e.currentTarget.value)}
              name="hexColor"
            />
            <div
              className={styles.colorVisualizer}
              style={{
                backgroundColor:
                  hexColor.length === 6 ? `#${hexColor}` : '#ffffff'
              }}
            />
          </div>

          {requiredValidHexColor && (
            <span className={styles.errorMessage}>
              {translate('invalidHexColor', language)}
            </span>
          )}
        </div>
        <hr />
        <button
          type="button"
          className={styles.defaultRoundedActionButton}
          onClick={createPantone}
        >
          {translate('savePantone', language)}
        </button>
      </div>
      {loading && <Loading fixed />}
    </div>
  );
}

export default CreatePantone;
