export function registerLoggedClient(loggedClient) {
  return {
    type: '@loggedClient/REGISTER',
    loggedClient
  };
}

export function unregisterLoggedClient() {
  return {
    type: '@loggedClient/UNREGISTER'
  };
}

export function setLoggedClientPresentation(presentation) {
  return {
    type: '@loggedClient/SET_PRESENTATION',
    presentation
  };
}

export function setLoggedClientPrints(prints) {
  return {
    type: '@loggedClient/SET_PRINTS',
    prints
  };
}

export function setLoggedClientConcept(concept) {
  return {
    type: '@loggedClient/SET_CONCEPT',
    concept
  };
}

export function setLoggedClientTags(tags) {
  return {
    type: '@loggedClient/SET_TAGS',
    tags
  };
}

export function setLoggedClientDolls(dolls) {
  return {
    type: '@loggedClient/SET_DOLLS',
    dolls
  };
}
export function setLoggedClientFabrics(fabrics) {
  return {
    type: '@loggedClient/SET_FABRICS',
    fabrics
  };
}

export function setProducts(products) {
  return {
    type: '@loggedClient/SET_PRODUCTS',
    products
  };
}
export function addProduct(product) {
  return {
    type: '@loggedClient/SET_PRODUCTS',
    product
  };
}
export function removeProduct(id) {
  return {
    type: '@prints/REMOVE',
    id
  };
}
