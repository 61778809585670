import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Api from '../libs/Api';
import { registerLoggedClient } from '../store/actions/loggedClient';
import { setLanguage } from '../store/actions/settings';
import styles from './ClientLogin.module.scss';
import text from '../libs/i18n';

import Loading from '../components/Loading';

export default function ClientLogin() {
  const language = useSelector(state => state.settings.language);
  const client = useSelector(state => state.loggedClient);

  const dispatch = useDispatch();
  const { attendanceId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (client && client.token) {
      history.push(`/client/${attendanceId}`);
    }
  }, [client, history, attendanceId]);

  const [password, setPassword] = useState('');
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSalutation = () => {
    const hour = new Date().getHours();
    if (hour < 5) {
      return text('goodNight', language);
    }
    if (hour < 8) {
      return text('goodMorning', language);
    }
    if (hour < 12) {
      return text('goodMorning', language);
    }
    if (hour < 18) {
      return text('goodAfternoon', language);
    }
    return text('goodNight', language);
  };

  const verifyPassword = value => {
    if (value) {
      return true;
    }
    setPasswordRequired(true);
    return false;
  };

  const tryClientLogin = async e => {
    e.preventDefault();
    setLoading(true);
    if (verifyPassword(password)) {
      const res = await Api.authClient(password);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.language) {
          dispatch(setLanguage(data.language === 'pt' ? 'pt-br' : 'en'));
        }
        dispatch(
          registerLoggedClient({
            ...data,
            token: password
          })
        );
        history.push(`/client/${res.meeting_box_id}/`);
      } else {
        setWrongCredentials(true);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.login}>
        <form onSubmit={tryClientLogin}>
          <h2>{getSalutation()}</h2>
          <div className={styles.passwordContainer}>
            <label htmlFor="password">{text('password', language)}</label>
            <input
              id="password"
              type="password"
              placeholder={text('passwordPlaceholder', language)}
              autoComplete="off"
              onChange={e => {
                setPassword(e.target.value);
                setPasswordRequired(false);
                setWrongCredentials(false);
              }}
              value={password}
            />
            {passwordRequired && (
              <span className={styles.error}>
                {text('passwordRequired', language)}
              </span>
            )}
          </div>
          <div className={styles.sendContainer}>
            <button type="submit">{text('enter', language)}</button>
            {wrongCredentials && (
              <span className={styles.error}>
                {text('invalidPassword', language)}
              </span>
            )}
          </div>
        </form>
      </div>
      {loading && <Loading />}
    </>
  );
}
