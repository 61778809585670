import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import translate from '../libs/i18n';
import Api from '../libs/Api';
import styles from './Attendance.module.scss';
import useOutsideClick from '../libs/useOutsideClick';
import { FiUser } from 'react-icons/fi';
import ShareIcon from '../assets/icons/Share';
import SearchIcon from '../assets/icons/Search';
import PlusIcon from '../assets/icons/Plus';
import Loading from '../components/Loading';
import ListSelector from '../components/ListSelector';
import PresentationGridItem from '../components/PresentationGridItem';
import PresentationConcept from '../components/PresentationConcept';
import PresentationPrints from '../components/PresentationPrints';
import FavoritePresentationPrints from '../components/FavoritePresentationPrints';
import OtherIdeasPresentationPrints from '../components/OtherIdeasPresentationPrints';
import PresentationPlayer from '../components/PresentationPlayer';
import ConfirmationModal from '../components/ConfirmationModal';
import FileSelector from '../components/FileSelector';
import { uploadNewFile } from '../libs/s3Upload';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-drag";
import ClientSelectorModal from '../components/ClientSelectorModal/ClientSelectorModal';
import { addProductsOnCart } from '../store/actions/carts';

function Attendance() {
  const dispatch = useDispatch();
  const language = useSelector(state => state.settings.language);
  const currentUser = useSelector(state => state.user);

  const { id } = useParams();
  const history = useHistory();

  const usersSelectorRef = useRef();
  const usersSelectorNotClientRef = useRef();
  const searchRef = useRef();

  const [attendance, setAttendance] = useState();
  const [invites, setInvites] = useState([]);
  const [invitesNotClient, setInvitesNotClients] = useState([]);
  const [invitesClient, setInvitesClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersClient, setUsersIsClient] = useState([]);
  const [usersNotClient, setUsersIsNotClient] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [presentation, setPresentation] = useState();
  const [concept, setConcept] = useState();
  const [loading, setLoading] = useState(false);
  const [usersSelector, setUsersSelector] = useState('closed');
  const [usersSelectorNotClient, setUsersSelectorNotClient] = useState('closed');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [uniquePresentation, setUniquePresentation] = useState(false);
  const [currentSection, setCurrentSection] = useState('concept');
  const [tags, setTags] = useState([]);
  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [artFinalists, setArtFinalists] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [dolls, setDolls] = useState([]);
  const [prints, setPrints] = useState([]);
  const [slider, setSlider] = useState(false);
  const [selectedPresentations, setSelectedPresentations] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deletingInvite, setDeletingInvite] = useState(false);
  const [attendanceCover, setAttendanceCover] = useState([]);
  const [filteredPresentations, setFilteredPresentations] = useState([]);
  const [filteredDefaultPresentations, setDefaultFilteredPresentations] = useState([]);
  const [printsWasChanged, setPrintsWasChanged] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [isLoading2, setIsLoading2] = useState(false);
  const user = useSelector(state => state.user);
  const [idModal, setIdModal] = useState();
  const [clientSelector, setClientSelector] = useState(false);
  const clientSelectorRef = useRef();

  const changeUsersSelector = useCallback(state => {
    if (state === 'closed') {
      setUsersSelector('opened');
    } else {
      setUsersSelector('closed');
    }
  }, []);
  const changeUsersSelectorNotClient = useCallback(state => {
    if (state === 'closed') {
      setUsersSelectorNotClient('opened');
    } else {
      setUsersSelectorNotClient('closed');
    }
  }, []);

  useOutsideClick(usersSelectorRef, () => {
    changeUsersSelector(usersSelector);
  });
  useOutsideClick(usersSelectorNotClientRef, () => {
    changeUsersSelectorNotClient(usersSelectorNotClient);
  });


  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      Api.getAttendance(id, `?search=${searchQuery}`).then(async res => {
        Api.getFavoritePresentations().then(favoritePresentations => {
          const updatedAttendance = res;
          updatedAttendance.selected_meetings = res.selected_meetings.map(p =>
            favoritePresentations.includes(p.meeting.id)
              ? { ...p, meeting: { ...p.meeting, isFavorite: true } }
              : { ...p, meeting: { ...p.meeting, isFavorite: false } }
          );
          setAttendance(updatedAttendance);
        });
        if (res.selected_prints && res.selected_prints.length) {
          setUniquePresentation(true);
          setPrints(res.selected_prints);
          if (res.customer_profile && res.customer_profile.length) {
            Api.getDollsByProfile(res.customer_profile).then(dbp => {
              setDolls(dbp);
            });
          }
          Api.getPresentation(res.meeting_id).then(pres => {
            setPresentation(pres);
            setLoading(false);
          });
          const getConcept = Api.getConcept(res.meeting_id);
          const getTags = Api.getTags();
          const getFabrics = Api.getFabrics();

          await axios.all([getTags, getConcept, getFabrics]).then(
            axios.spread(async (...responses) => {
              setTags(responses[0]);
              setConcept(responses[1].results);
              if (!responses[1].results.length) {
                setCurrentSection('prints');
              }
              setFabrics(responses[2].results);
            })
          );
        } else {
          setLoading(false);
        }
      });
      Api.getCollections().then(res => {
        setCollections(res);
      });
      Api.getClients().then(res => {
        setClients(res);
      });
      Api.getArtFinalists().then(res => {
        setArtFinalists(res);
      });
      Api.getAttendanceInvites(id).then(res => {
        setInvites(res);
      });
      Api.getUserIsNotClient().then(res => {
        setUsersIsNotClient(res);
      });
      Api.getUserIsClient().then(res => {
        setUsersIsClient(res);
      });
      Api.getUsers().then(res => {
        setUsers(res);
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [id, searchQuery]);

  useEffect(() => {
    loadData();
  }, [dispatch, id, loadData]);

  const getSlidePrints = useMemo(() => {
    const unifiedPrints = [];
    prints
      .filter(p => p.intentional_inserted)
      .forEach(print => {
        if (!unifiedPrints.find(uP => uP.print_id === print.print_id)) {
          unifiedPrints.push(print);
        }
      });
    return unifiedPrints;
  }, [prints]);
  useMemo(() => {
    if (attendance) {
      // if (searchQuery) {
      //   const selectedMeetings = attendance.selected_meetings.filter(pres => {
      //     return pres?.meeting?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      //   }
          
      //   );
      //   setFilteredPresentations(selectedMeetings);
      //   setDefaultFilteredPresentations(selectedMeetings);
      // } else {
        setFilteredPresentations(attendance.selected_meetings)
        setDefaultFilteredPresentations(attendance.selected_meetings);
      // }
    }
    return [];
  }, [attendance, searchQuery]);

  const showSlider = useCallback(() => {
    setSlider(true);
  }, []);

  const loadPresentationPrints = useCallback(() => {
    Api.getPresentationSelectedPrints(presentation.id)
      .then(res => {
        setPrints(res);
      })
      .catch();
  }, [presentation]);

  const updateConcept = useCallback(() => {
    Api.getConcept(presentation.id).then(res => {
      setConcept(res.results);
    });
  }, [presentation]);

  const updatePrints = useCallback(() => {
    setLoading(true);
    Api.getPresentation(presentation.id)
      .then(async ans => {
        const returnedPresentation = { ...ans };
        loadPresentationPrints(returnedPresentation.id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadPresentationPrints, presentation]);

  const renderMissingCardSpaces = useMemo(() => {
    if (filteredPresentations.length) {
      const numberOfEmptyCards = filteredPresentations.length % 4;
      const emptyCards = [];

      for (let i = 1; i <= numberOfEmptyCards; i += 1) {
        emptyCards.push(i);
      }

      return emptyCards.map(i => <div key={i} className={styles.emptyCard} />);
    }
    return false;
  }, [filteredPresentations]);

  const onSelectPresentation = (presentationId, selected) => {
    let currentSelectedPresentations = [...selectedPresentations];
    const containsPresentation = currentSelectedPresentations.find(
      pres => pres === presentationId
    );

    if (!selected && containsPresentation) {
      currentSelectedPresentations = currentSelectedPresentations.filter(
        pres => pres !== presentationId
      );
    } else if (selected && !containsPresentation) {
      currentSelectedPresentations = [...selectedPresentations, presentationId];
    }
    setSelectedPresentations(currentSelectedPresentations);
  };

  const inviteUsers = () => {
    setUsersSelector('closed');
    setUsersSelectorNotClient('closed');
    setSelectedUsers([]);
    const invitePosts = [];
    selectedUsers.forEach(user => {
      const invite = Api.invite({ meeting_box: id, staff: user.id });
      invitePosts.push(invite);
    });
    axios
      .all(invitePosts)
      .then(() => {
        Api.getAttendanceInvites(id).then(res => {
          setInvites(res);
        });
        toast(translate('successSendingInvites', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('failSendingInvites', language), {
          type: 'error'
        });
      });
  };

  const deleteInvite = () => {
    setLoading(true);
    Api.deleteInvite(deletingInvite)
      .then(() => {
        Api.getAttendanceInvites(id).then(res => {
          setInvites(res);
        });
      })
      .catch(() => {
        toast(translate('failDeletingInvite', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setDeletingInvite(false);
        setLoading(false);
      });
  };

  function onSelectAttendanceCover(files){
    if (
      !files[0].name.toUpperCase().includes('.JPG') &&
      !files[0].name.toUpperCase().includes('.JPEG') &&
      !files[0].name.toUpperCase().includes('.PNG')
    ) {
      toast(translate('invalidFileFormat', language), {
        type: 'error'
      });
    } else {
    setAttendanceCover(files)
  }
  }

  const getInvitedUser = userId => {
    return users.find(user => user.id === userId);
  };

  const invitesNotClientFunc = invitesClient => {
    let invitedClients = [...usersNotClient];
    let trueInvite = []
    invitedClients.forEach(user => {
      invitesClient.find(invite => invite.staff === user.id && trueInvite.push(invite))

    });
    return trueInvite;
  };

  const invitesClientFunc = invitesClient => {
    let invitedClients = [...usersClient];
    let trueInvite = []
    invitedClients.forEach(user => {
      invitesClient.find(invite => invite.staff === user.id && trueInvite.push(invite))

    });
    return trueInvite;
  };

  const getNonInvitedUsersNotClient = useMemo(() => {
    let nonInvitedUsersClients = [...users];
    invites.forEach(invite => {
      nonInvitedUsersClients = nonInvitedUsersClients.filter(
        user => user.id !== invite.staff
      );
    });
    usersClient.forEach(client => {
      nonInvitedUsersClients = nonInvitedUsersClients.filter(
        user => user.name !== client.name
      );
    });
    nonInvitedUsersClients = nonInvitedUsersClients.filter(
      user => user.id !== currentUser.id
    );

    return nonInvitedUsersClients;
  }, [currentUser, invites, users]);

  const getNonInvitedUsersClient = useMemo(() => {
    let nonInvitedUsersNotClients = [...users];
    invites.forEach(invite => {
      nonInvitedUsersNotClients = nonInvitedUsersNotClients.filter(
        user => user.id !== invite.staff
      );
    });
    usersNotClient.forEach(client => {
      nonInvitedUsersNotClients = nonInvitedUsersNotClients.filter(
        user => user.name !== client.name
      );
    });
    nonInvitedUsersNotClients = nonInvitedUsersNotClients.filter(
      user => user.id !== currentUser.id
    );

    return nonInvitedUsersNotClients;
  }, [currentUser, invites, users]);

  const favoritePresentation = presentationId => {
    const updatedAttendance = { ...attendance };
    const updatedPresentations = [...attendance.selected_meetings];
    const presentationIndex = updatedPresentations.findIndex(
      up => up.meeting.id === presentationId
    );
    updatedPresentations[presentationIndex].meeting.isFavorite = true;
    updatedAttendance.selected_meetings = updatedPresentations;
    setAttendance(updatedAttendance);

    Api.staffFavoritePresentation(presentationId, currentUser.id)
      .then(() => {
        toast(translate('successFavoritingPresentation', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorFavoritingPresentation', language), {
          type: 'error'
        });
        updatedPresentations[presentationIndex].meeting.isFavorite = false;
        updatedAttendance.selected_meetings = updatedPresentations;
        setAttendance(updatedAttendance);
      });
  };

  const removeFavoritePresentation = presentationId => {
    const updatedAttendance = { ...attendance };
    const updatedPresentations = [...attendance.selected_meetings];
    const presentationIndex = updatedPresentations.findIndex(
      up => up.meeting.id === presentationId
    );
    updatedPresentations[presentationIndex].meeting.isFavorite = false;
    updatedAttendance.selected_meetings = updatedPresentations;
    setAttendance(updatedAttendance);

    Api.removeFavoritePresentation(presentationId)
      .then(() => {
        toast(translate('successRemovingFavoritePresentation', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegPresentation', language), {
          type: 'error'
        });
        updatedPresentations[presentationIndex].meeting.isFavorite = true;
        updatedAttendance.selected_meetings = updatedPresentations;
        setAttendance(updatedAttendance);
      });
  };
  async function handleSavePosition() {
    try {
      setLoading(true);
      filteredPresentations.map(async (presentation, index) => {
        if(presentation.id === index) return
        await Api.patchAttendancePresentationPosition(presentation.id, index)
      })
      toast(translate("successSavePosition", language), {
        type: 'success'
      });
    } catch (err) {
      toast(translate("errorSavePosition", language), {
        type: 'error'
      });
    } finally {
      setIsDragging(false);
      setLoading(false);
      setPrintsWasChanged(false);
    }
  }

  async function handleSetAttendanceCover() {
    try {
      //NAME - FILE - PATH
      setLoading(true)
      await uploadNewFile(
        `${attendanceCover[0].name}`,                
        attendanceCover[0],
        `print/small`,
      );
      await Api.psdFileSuccess({
        attendance_id: id,

        path: `print/small/${attendanceCover[0].name}`,
        file_field: 'attendance_cover'
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setLoading(false)
      setAttendanceCover([]);
      toast(translate('failSavingPresentationCover', language), {
        type: 'error'
      });
    }
    toast(translate('successSavingPresentationCover', language), {
      type: 'success'
    });
    setLoading(false)
    setAttendanceCover([]);
  }

  useOutsideClick(clientSelectorRef, () => {
    changeClientSelector(clientSelector);
  })

  const changeClientSelector = state => {
    if(!state) {
      setClientSelector(true);
    } else {
      setClientSelector(false);
    }
  }
  async function addPresentationToCart(client_id, presentation_id) {
    const formData = new FormData();
    const getPrints = await Api.getExclusiveMeetingPrints(presentation_id);
    try {
      getPrints.map(presentationPrint => {
          formData.append('print', presentationPrint.print_id);
          Api.createProduct(formData).then(async product => {
            const newProduct = {
              ...product,
              client: client_id,
              customer_meeting: id,
              seller: user.id,
              action: null,
              meters: '',
              product_id: presentationPrint.product_id,
              print_id: presentationPrint.print_id,
              print_code: presentationPrint.print_code,
              print_color_variant: presentationPrint.print_color_variant,
              initialFabrics: []
              };
              dispatch(addProductsOnCart([newProduct]));
          })  
      });
      setClientModal(false);
      toast(translate('presentationAddedToCart', language), {
        type: 'success'
      });
    } catch {
      toast(translate('failedPresentationAddedToCart', language), {
        type: 'error'
      });
    } finally {
      setIsLoading2(false);
    }
  }

  const renderContent = () => {
    const hideSlider = () => {
      setSlider(false);
    };

    if (uniquePresentation) {
      return (
        <>
          <ul className={styles.sectionsMenu}>
            <li>
              <button
                className={`${currentSection === 'concept' && styles.active}`}
                type="button"
                onClick={() => setCurrentSection('concept')}
              >
                {translate('concept', language)}
              </button>
            </li>
            <li>
              <button
                className={`${currentSection === 'prints' && styles.active}`}
                type="button"
                onClick={() => setCurrentSection('prints')}
              >
                {translate('prints', language)}
              </button>
            </li>
            <li className={styles.break}>
              <button
                className={`${currentSection === 'favorites' && styles.active}`}
                type="button"
                onClick={() => setCurrentSection('favorites')}
              >
                {translate('favorites', language)}
              </button>
            </li>
            <li>
              <button
                className={`${currentSection === 'other-ideas' &&
                  styles.active}`}
                type="button"
                onClick={() => setCurrentSection('other-ideas')}
              >
                {translate('otherIdeas', language)}
              </button>
            </li>
            <li>
                <FileSelector
                  files={attendanceCover}
                  onSelect={onSelectAttendanceCover}
                  label={translate('uploadPresentationCover', language)}
                />
            </li>
            {attendanceCover.length > 0 && (
              <button 
              className={styles.defaultRoundedActionButton}
              onClick={() => handleSetAttendanceCover()}>
                Salvar
              </button>
            )}
          </ul>
          <div className={styles.content}>
            {presentation && currentSection === 'concept' && (
              <PresentationConcept
                presentation={presentation}
                playSlider={showSlider}
                concept={concept}
                onUpdate={updateConcept}
              />
            )}

            {presentation && currentSection === 'prints' && (
              <PresentationPrints
                presentation={presentation}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {presentation && currentSection === 'favorites' && (
              <FavoritePresentationPrints
                presentation={presentation}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {presentation && currentSection === 'other-ideas' && (
              <OtherIdeasPresentationPrints
                presentation={presentation}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {loading && <Loading fixed />}
          </div>
          {slider && (
            <PresentationPlayer
              concept={concept}
              presentation={presentation}
              prints={getSlidePrints}
              close={hideSlider}
              section={currentSection}
            />
          )}
        </>
      );
    }
    const finishEditingText = () => {
      setEditingTitle(false);

      const formData = new FormData();

      if (updatedTitle && updatedTitle !== attendance.name) {
        formData.append('name', updatedTitle);
        Api.updateAttendance(attendance.id, formData)
          .then()
          .catch(() => {
            setUpdatedTitle('');
          });
      }
    };

    function onChange(sourceId, sourceIndex, targetIndex, targetId) {
      if(sourceIndex === targetIndex) return
      const nextState = swap(filteredPresentations, sourceIndex, targetIndex);
      setFilteredPresentations(nextState);
      setPrintsWasChanged(true);
    }

    const openClientSelectModal = (presentation_id) => {
      setIdModal(presentation_id);
      setClientModal(true);
    }

    return (
      <>
      {clientModal && (
        <ClientSelectorModal
          title={translate('modalClientTitle', language)}
          confirmText={isLoading2 ? <div className={styles.isLoading} /> : translate('confirmText', language)}
          onCancel={() => {
            setClientModal(false)
            setSelectedClient()
          }}
          onConfirm={() => {
            if(!selectedClient) {
              alert('Favor selecionar um cliente')
              return
            }
            setIsLoading2(true);
            addPresentationToCart(selectedClient.id, idModal);
        }}
        >
          <div className={styles.clientSelector}>
            <button
                type="button"
                className={`${styles.defaultSelectInput}`}
                onClick={e => {
                  e.stopPropagation();
                  setClientSelector(true);
                }}
            >
              {selectedClient ? selectedClient.name : translate('modalClientTitle', language)}
            </button>
            {clientSelector && 
              (
                <div 
                  ref={clientSelectorRef} 
                  className={styles.selectorClientPosition}
                >
                  <ListSelector
                    selectLabel={translate('choose', language)}
                    items={clients}
                    onSelect={setSelectedClient}
                    value={selectedClient}
                    search
                  />
                </div>
              )}
          </div>
        </ClientSelectorModal>
      )}
      <div className={styles.content}>
        <div className={styles.headerMeeting}>
          <div className={styles.searchInputContainer}>
            <SearchIcon color="#a3b3c7" />
            <form onSubmit={(e) => {
                e.preventDefault();
                setSearchQuery(searchRef?.current?.value);
              }
            }>
              <input
                ref={searchRef}
                type="search"
                placeholder={translate('searchPresentation', language)}
              />              
            </form>
          </div>
          {printsWasChanged && (
          <>
            <button 
              onClick={handleSavePosition} 
              className={styles.defaultActionButton}
            >
              {translate("savePosition", language)}
            </button>

            <button 
              onClick={() => {
                setFilteredPresentations(filteredDefaultPresentations)
                setPrintsWasChanged(!printsWasChanged)
                setIsDragging(false);
              }} 
              className={styles.defaultCancelButton}
            >
              {translate("cancelText", language)}
            </button>
          </>
          )}
        </div>
        {attendance && (
          <div className={styles.mainInfo}>
            <h2 className={styles.attendanceTitle}>
              {translate('attendance', language)}
            </h2>
            {editingTitle ? (
              <textarea
                type="text"
                value={updatedTitle || attendance.name}
                onChange={e => setUpdatedTitle(e.currentTarget.value)}
                onBlur={finishEditingText}
                className={styles.title}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            ) : (
              <button
                type="button"
                onClick={() => {
                  setEditingTitle(true);
                }}
              >
                <h1 className={styles.title}>
                  {updatedTitle || attendance.name}
                </h1>
              </button>
            )}
          </div>
        )}
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={4}
            rowHeight={500}
            style={{ minHeight: "3200px", width: "100%" }}
          >
                {attendance &&
                  filteredPresentations.map((pres, index) => (
                    <GridItem
                      key={pres.meeting && pres.meeting.id}
                    >
                        <PresentationGridItem
                          name={pres.meeting && pres.meeting.name}
                          image={pres.meeting && !isDragging && pres.meeting.print_image_url}
                          onSelect={onSelectPresentation}
                          id={pres.meeting && pres.meeting.id}
                          type={pres.meeting && pres.meeting.type}
                          clients={clients}
                          small
                          fromAttendance={attendance.id}
                          favorite={
                            pres.meeting.isFavorite
                              ? () => removeFavoritePresentation(pres.meeting.id)
                              : () => favoritePresentation(pres.meeting.id)
                          }
                          isFavorite={pres.meeting.isFavorite}
                          whenDragging={setIsDragging}
                          onClickCart={() => openClientSelectModal(pres?.meeting?.id)}
                        />
                    </GridItem>
                  ))}
                {renderMissingCardSpaces}
          </GridDropZone>
        </GridContextProvider>
        {loading && <Loading fixed />}
      </div>
      </>
    );
  };

  const removePresentations = useCallback(() => {
    setConfirmationModal(false);
    let updatedPresentations = [...attendance.selected_meetings];

    selectedPresentations.forEach(sp => {
      if (
        attendance.selected_meetings.find(
          sm => sm.meeting && sm.meeting.id === sp
        )
      ) {
        updatedPresentations = updatedPresentations.filter(
          up => up.meeting && up.meeting.id !== sp
        );
      }
    });
    updatedPresentations = updatedPresentations.map(up => up.meeting.id);
    Api.removeAttendancePresentation(id, {
      meetings_ids: updatedPresentations
    }).then(() => {
      loadData();
    });
    setSelectedPresentations([]);
  }, [attendance, id, loadData, selectedPresentations]);

  return (
    <div className={styles.attendance}>
      <div className={styles.header}>
        <div className={styles.topHeader}>
          <h2 className={styles.sectionTitle}>
            <span className={styles.title}>
              {translate('attendance', language).toUpperCase()}
            </span>
            {attendance && (
              <span className={styles.attendanceName}>
                {attendance.name ? attendance.name.toUpperCase() : ''}
              </span>
            )}
          </h2>
          <div>
            <div className={styles.shareButton}>
              <button
                type="button"
                className={styles.share}
                onClick={() => history.push(`/attendances/${id}/link-creation`)}
              >
                {translate('share', language)} <ShareIcon />
              </button>
            </div>
            {(attendance?.type === 'LE' || attendance?.type === 'MAT') && (
              <div className={styles.social}>
                <h3 style={{color:"white"}}>CLIENT</h3>
                  {users.length && invites.length > 0 && (
                    <ul className={styles.invitedUsers}>
                      {invitesClientFunc(invites).map((invite, index) => (
                        index <=18 && (
                          <li key={invite.id}>
                            <button
                              className={styles.delete}
                              type="button"
                              onClick={() => setDeletingInvite(invite.id)}
                            >
                              -
                            </button>
                            <div className={styles.imageContainer}>
                              {getInvitedUser(invite.staff).image ? (
                                <img
                                src={getInvitedUser(invite.staff).image}
                                alt={getInvitedUser(invite.staff).name}
                                title={getInvitedUser(invite.staff).name}
                              />) : <FiUser size={25} title={getInvitedUser(invite.staff).name}/>}
                            </div>
                          </li>
                        )
                      ))}
                    </ul>
                  )}
    
                  <div className={styles.userSelectorContainer}>
                    <button
                      type="button"
                      className={styles.invite}
                      onClick={() => setUsersSelectorNotClient('opened')}
                    >
                      <PlusIcon color="#9BA7B7" />
                    </button>
                    {usersSelectorNotClient === 'opened' && (
                      <div className={styles.userSelector} ref={usersSelectorNotClientRef}>
                        <ListSelector
                          items={getNonInvitedUsersClient}
                          onSelect={setSelectedUsers}
                          selectLabel={translate('choose', language)}
                          multiple
                          values={selectedUsers}
                          search
                          action={inviteUsers}
                          actionText={translate('invite', language)}
                        />
                      </div>
                    )}
                  </div>
              </div>)}
            
          </div>
        </div>
      </div>
      {renderContent()}
      <div
        className={`${
          styles.actionsMenuContainer
        } ${selectedPresentations.length > 0 && styles.actionsMenuActive}`}
      >
        <div className={styles.actionsMenu}>
          <div className={styles.left}>
            <div>
              {`${selectedPresentations.length} ${
                selectedPresentations.length > 1
                  ? translate('selectedMultiple', language)
                  : translate('selectedOne', language)
              }`}
            </div>
          </div>
          <button
            onClick={() => setConfirmationModal(true)}
            disabled={!selectedPresentations.length}
            type="button"
            className={styles.delete}
          >
            {translate('delete', language)}
          </button>
        </div>
      </div>
      {confirmationModal && (
        <ConfirmationModal
          message={translate('confirmRemoveAttendancePresentation', language)}
          confirmText={translate('yes', language)}
          cancelText={translate('no', language)}
          onConfirm={removePresentations}
          onCancel={() => setConfirmationModal(false)}
          deleteWarning
        />
      )}
      {deletingInvite && (
        <ConfirmationModal
          title={translate('inviteDelete', language)}
          message={translate('inviteDeletingMessage', language)}
          confirmText={translate('yes', language)}
          cancelText={translate('no', language)}
          onConfirm={deleteInvite}
          onCancel={() => setDeletingInvite(false)}
          deleteWarning
        />
      )}
    </div>
  );
}

export default Attendance;
