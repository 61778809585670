const initialState = [];

const reducer = (state = initialState, action) => {
  let newProducts;

  switch (action.type) {
    case '@clientCart/ADD_PRODUCTS':
      newProducts = [...state];
      action.products.forEach(p => {
        const updatedProducts = {
          ...p,
          cart_id: newProducts?.length > 0 ? newProducts[newProducts.length - 1].cart_id + 1 : 0,
        }
        newProducts.push(updatedProducts);
      });
      return [...newProducts];

    case '@clientCart/REMOVE_PRODUCT':
      newProducts = [...state];
      newProducts = newProducts.filter(
        product =>
          !(
            product.cart_id === action.id && product.client === action.client
          )
      );
      return [...newProducts];

    case '@clientCart/UPDATE_PRODUCT':
      newProducts = [...state];
      const getProductIndex = newProducts.findIndex(np => np.cart_id === action.product.cart_id)
      newProducts[getProductIndex] = {
        ...action.product
      };
      return [...newProducts];

    case '@clientCart/CLEAR_CART':
      newProducts = [...state];
      newProducts = newProducts.filter(
        product => product.client !== action.client
      );
      return [...newProducts];
    default:
      return state;
  }
};

export default reducer;