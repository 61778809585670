import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DatePicker from 'react-date-picker';
import CurrencyInput from 'react-currency-input';
import { toast } from 'react-toastify';
import Loading from './Loading';

import translate from '../libs/i18n';
import Api from '../libs/Api';

import CheckInput from './CheckInput';
import logoMatch from '../assets/images/logo_match_1.svg';
import CloseIcon from '../assets/icons/Close';

import styles from './Sale.module.scss';

function Sale({ close, client, order, initialTotal, currency, saleSuccess }) {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const [paymentCondition, setPaymentCondition] = useState('');
  const [paymentWay, setPaymentWay] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [deliveryWay, setDeliveryWay] = useState('');
  const [deliveryStart, setDeliveryStart] = useState('');
  const [deliveryEnd, setDeliveryEnd] = useState('');
  const [observations, setObservations] = useState('');
  const [email, setEmail] = useState('');
  const [total, setTotal] = useState('');
  const [selectedClientArea, setSelectedClientArea] = useState("");
  const [clientAreas, setClientAreas] = useState([]);
  const [CIF, setCIF] = useState(true);
  const [FOC, setFOC] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setTotal(initialTotal);
  }, [initialTotal]);

  useEffect(() => {
    if(client) {
      Api.getClientAreas(client.id)
      .then(response => setClientAreas(response.results))
      .catch(() => setClientAreas([]));
    }
  }, []);

  const createSale = () => {
    setLoading(true);
    Api.createSale({
      total_price: total,
      currency,
      payment_condition: paymentCondition,
      payment_method: paymentWay,
      payment_obs: observations,
      shipping: CIF ? 'CIF' : 'FOC',
      shipping_method: deliveryWay,
      shipping_deadline_init: deliveryStart,
      shipping_deadline_final: deliveryEnd,
      cep: zipCode,
      country,
      state,
      city,
      address,
      order,
      seller: user.id,
      dealer_email: email,
      status: 'FATURADO'
    })
      .then(() => {
        saleSuccess();
      })
      .catch(() => {
        toast(translate('createSaleError', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeTotal = (event, value) => {
    setTotal(value);
  };

  const getCurrencySymbol = useMemo(() => {
    if (currency) {
      switch (currency) {
        case 'brl':
          return 'R$';
        case 'usd':
          return '$';
        case 'eur':
          return '€';
        default:
          return '';
      }
    }
    return '';
  }, [currency]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={logoMatch} alt="blend." className={styles.logo} />
        <button type="button" className={styles.close} onClick={close}>
          <CloseIcon />
        </button>
      </div>
      <hr />
      <div className={styles.title}>
        <h2>{translate('saleTitle', language)}</h2>
        <section>
          <p>
            {translate('client', language)}: {client.name}
          </p>
        </section>
      </div>
      <form onSubmit={e => e.preventDefault()}>
        <div className={styles.row}>
          <div className={styles.field}>
            <p>{translate('paymentCondition', language)}</p>
            <input
              value={paymentCondition}
              onChange={e => setPaymentCondition(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
          <div className={styles.field}>
            <p>{translate('paymentWay', language)}</p>
            <input
              value={paymentWay}
              onChange={e => setPaymentWay(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <p>{translate('address', language)}</p>
            <input
              value={address}
              onChange={e => setAddress(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <p>{translate('city', language)}</p>
            <input
              value={city}
              onChange={e => setCity(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
          <div className={styles.field}>
            <p>{translate('state', language)}</p>
            <input
              value={state}
              onChange={e => setState(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
          <div className={styles.field}>
            <p>{translate('country', language)}</p>
            <input
              value={country}
              onChange={e => setCountry(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
          <div className={styles.field}>
            <p>{translate('zipCode', language)}</p>
            <input
              value={zipCode}
              onChange={e => setZipCode(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <p>{translate('freight', language)}</p>
            <div className={styles.checkInputs}>
              <CheckInput
                text="CIF"
                value={CIF}
                onChange={() => {
                  setCIF(!CIF);
                  setFOC(!FOC);
                }}
              />
              <CheckInput
                text="FOC"
                value={FOC}
                onChange={() => {
                  setCIF(!CIF);
                  setFOC(!FOC);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <p>{translate('deliveryWay', language)}</p>
            <input
              value={deliveryWay}
              onChange={e => setDeliveryWay(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
          <div className={styles.field}>
            <p>{translate('deliveryStart', language)}</p>
            <DatePicker
              className={`${styles.defaultTextInput}`}
              value={deliveryStart}
              onChange={setDeliveryStart}
              format="dd/MM/y"
              clearIcon={deliveryStart ? undefined : null}
              calendarIcon={null}
            />
          </div>
          <div className={styles.field}>
            <p>{translate('deliveryEnd', language)}</p>
            <DatePicker
              className={`${styles.defaultTextInput}`}
              value={deliveryEnd}
              onChange={setDeliveryEnd}
              format="dd/MM/y"
              clearIcon={deliveryEnd ? undefined : null}
              calendarIcon={null}
            />
          </div>
        </div>
        <hr className={styles.formDivisor} />
        <div className={`${styles.row} ${styles.total}`}>
          <span>
            {translate('total', language)}: {getCurrencySymbol}
          </span>

          <div className={styles.field}>
            <CurrencyInput
              className={styles.defaultTextInput}
              value={total}
              onChange={handleChangeTotal}
              decimalSeparator={currency && currency === 'usd' ? '.' : ','}
              thousandSeparator={currency && currency === 'usd' ? ',' : '.'}
            />
          </div>
        </div>
        <div className={`${styles.row}`}>
          <div className={styles.field}>
            <p>{translate('observations', language)}</p>
            <textarea
              value={observations}
              onChange={e => setObservations(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextArea}
            />
          </div>
        </div>
        <div className={`${styles.row}`}>
          <div className={styles.field}>
            <p>{translate('sendEmailCopy', language)}</p>
            <input
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              type="text"
              className={styles.defaultTextInput}
            />
          </div>
        </div>
        <div className={`${styles.row} ${styles.actionContainer}`}>
          <button
            type="button"
            className={styles.defaultActionButton}
            onClick={createSale}
          >
            {translate('send', language)}
          </button>
        </div>
      </form>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </div>
  );
}

export default Sale;

Sale.propTypes = {
  close: PropTypes.func.isRequired,
  client: PropTypes.shape.isRequired,
  order: PropTypes.number.isRequired,
  initialTotal: PropTypes.number,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  saleSuccess: PropTypes.func.isRequired
};

Sale.defaultProps = {
  initialTotal: 0,
  currency: ''
};
