const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case '@artFinalists/SET_ART_FINALISTS':
      return [...action.artFinalists];
    default:
      return state;
  }
};

export default reducer;
