import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ArrowIcon from '../assets/icons/Arrow';
import styles from './CreatePresentation.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';
import PresentationForm from '../components/PresentationForm';

function CreatePresentation() {
  const language = useSelector(state => state.settings.language);
  const [presentation, setPresentation] = useState();
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    (async function loadPresentationData() {
      Api.getPresentation(id).then(res => {
        setPresentation(res);
      });
    })();
  }, []);

  return (
    <div className={styles.createPresentation}>
      <div className={styles.header}>
        <section>
          <button 
            className={styles.return}
            onClick={() => history.goBack()}
          >
            <div className={styles.returnIconContainer}>
              <ArrowIcon color="#FFF" />
            </div>
            <p>{translate('goBack', language).toUpperCase()}</p>
          </button>
          <h2 className={styles.sectionTitle}>
            {translate('editPresentation', language).toUpperCase()}
          </h2>
        </section>
      </div>
      {presentation && (
        <div className={styles.form}>
          <PresentationForm initialData={presentation} />
        </div>
      )}
    </div>
  );
}

export default CreatePresentation;
