import React from "react";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom"
import Api from "../../libs/Api";
import OrderCart from "../../components/OrderCart";
import Loading from "../../components/Loading";


export function Order() {
    const { id } = useParams();
    const [order, setOrder] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    async function getInitialData() {
        try {
            setIsLoading(true);
            const response = await Api.getOrder(id);
            setOrder(response);
        } catch {

        } finally {
            setIsLoading(false);
        }
        
    }

    useEffect(() => {
        if (id === undefined) return
        getInitialData();
    }, [id]);


    return(
        <>
            {isLoading ? <Loading /> : order && <OrderCart currentOrder={order} close={() => history.goBack()}/>}
        </>
    )
}