import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import FileSelector from './FileSelector';
import ConfirmationModal from './ConfirmationModal';
import Feedback from './Feedback';
import Loading from './Loading';
import CustomizePrintAndDoll from './CustomizePrintAndDoll';

import Api from '../libs/Api';
import uploadFile from '../libs/dropboxUpload';
import translate from '../libs/i18n';

import calcDollRapport from '../utils/calcDollRapport';

import CloseIcon from '../assets/icons/Close';
import DownloadIcon from '../assets/icons/Download';
import EditFilter from '../assets/icons/EditFilter';
import EditMask from '../assets/icons/EditMask';
import customizedPrint from '../assets/images/customized-print.jpg';
import estampaCustomizada from '../assets/images/estampa-customizada.jpg';

import styles from './PrintApproval.module.scss';
import { uploadNewFile } from '../libs/s3Upload';

function PrintApproval({
  id,
  nextPrint,
  previousPrint,
  close,
  printPosition,
  printCounter,
  handleReloadPrints
}) {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const [showingPrint, setShowingPrint] = useState();
  const [printDoll, setPrintDoll] = useState();
  const [psdOriginal, setPsdOriginal] = useState([]);
  const [jpg, setJpg] = useState([]);
  const [briefings, setBriefings] = useState([]);
  const [singleConfigList, setSingleConfigList] = useState([]);
  const [showingDollMask, setShowingDollMask] = useState([]);
  const [showingDollAdjustment, setShowingDollAdjustment] = useState([]);
  const [savingProgress, setSavingProgress] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [feedbackMessages, setFeedbackMessages] = useState(false);
  const [newFeedback, setNewFeedback] = useState(false);
  const [editingPrint, setEditingPrint] = useState(false);
  const [showingPrintImage, setShowingPrintImage] = useState('');
  const [rotate, setRotate] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [color, setColor] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [scale, setScale] = useState(0);
  const [brightness, setBrightness] = useState(0);
  const [dollPosXAdjustment, setDollPosXAdjustment] = useState([]);
  const [dollPosYAdjustment, setDollPosYAdjustment] = useState([]);
  const [dollRotateAdjustment, setDollRotateAdjustment] = useState([]);
  const [showingPrintHeight, setShowingPrintHeight] = useState(0);
  const [showingPrintWidth, setShowingPrintWidth] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [printBackgroundSize, setPrintBackgroundSize] = useState('auto');
  const [printBackgroundPosition, setPrintBackgroundPosition] = useState('50%');
  const [reloadPrints, setReloadPrints] = useState(false);
  const [editingMask, setEditingMask] = useState(false);
  const [dollMainAdjustment, setDollMainAdjustment] = useState();
  const [loading, setLoading] = useState(false);

  const imgElement = useRef(null);
  const dollRef = useRef(null);

  const createMaskConfig = (r, x, y) => {
    const s = 1 + scale / 100;

    return {
      transform: `translateX(-50%) translateY(-50%) rotate(${r}deg) scale(${s})`,
      backgroundPosition: `${x / 100}% ${y / 100}%`
    };
  };

  useEffect(() => {
    setSavingProgress(true);
    Api.getPrint(id)
      .then(res => {
        setShowingPrint(res);
      })
      .finally(() => {
        setSavingProgress(false);
      });
  }, [id]);

  useEffect(() => {
    Api.getAllPrintBriefings(id).then(res => {
      setBriefings(res.result);
    });

    Api.getPrintDoll(id).then(res => {
      setPrintDoll(res);
    });
  }, [id]);

  useEffect(() => {
    if (showingPrint) {
      if (
        showingPrint.is_customization ||
        showingPrint.is_twin ||
        showingPrint.new_design_pending
      ) {
        Api.getCustomizationByPrintId(showingPrint.id).then(res => {
          const custom = res.results[0];
          if (custom) {
            setShowingPrintImage(custom.origin_image);
            setRotate(custom.rotate);
            setContrast(custom.contrast);
            setBrightness(custom.brightness)
            setColor(custom.color);
            setSaturation(custom.saturation);
            setScale(custom.scale);
          }
          else{
            setShowingPrintImage(showingPrint.image_url);
          }
        });
      } else {
        setShowingPrintImage(showingPrint.image_url);
      }
    }
  }, [showingPrint]);


  useEffect(() => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (showingPrintHeight && showingPrintWidth) {
      const maxWidth = windowWidth * 0.2625;
      const maxHeight = windowHeight * 0.7;

      let fabricBackgroundWidth = showingPrintWidth;
      let fabricBackgroundHeight = showingPrintHeight;
      let backgroundSize = `${fabricBackgroundWidth}px auto`;
      let backgroundPostion = '50%';

      if (showingPrintHeight > showingPrintWidth) {
        if (fabricBackgroundHeight > maxHeight) {
          fabricBackgroundHeight = maxHeight;
          fabricBackgroundWidth *= maxHeight / fabricBackgroundHeight;
        }

        backgroundSize = `auto ${fabricBackgroundHeight}px`;
        backgroundPostion = `calc(50% - ${(maxWidth -
          (fabricBackgroundWidth * fabricBackgroundHeight) /
            showingPrintHeight) /
          2}px) 50%`;
      } else if (showingPrintHeight < showingPrintWidth) {
        if (showingPrintWidth > maxWidth) {
          fabricBackgroundWidth = maxWidth;
          fabricBackgroundHeight *= maxWidth / showingPrintWidth;
        }

        backgroundSize = `${fabricBackgroundWidth}px auto`;
      } else {
        if (showingPrintWidth > maxWidth) {
          fabricBackgroundWidth = maxWidth;
        }

        backgroundSize = `${fabricBackgroundWidth}px auto`;
      }

      setPrintBackgroundSize(backgroundSize);
      setPrintBackgroundPosition(backgroundPostion);
    }
  }, [showingPrintHeight, showingPrintWidth]);

  useEffect(() => {
    const newPosXAdjustment = [];
    const newPosYAdjustment = [];
    const newRotateAdjustment = [];
    const configList = [];

    if (showingDollAdjustment && showingDollAdjustment.length) {
      showingDollAdjustment.forEach(adjustment => {
        newPosXAdjustment.push(adjustment.pos_x || 0);
        newPosYAdjustment.push(adjustment.pos_y || 0);
        newRotateAdjustment.push(adjustment.rotate || 0);
        configList.push(
          createMaskConfig(
            adjustment.rotate || 0,
            adjustment.pos_x || 0,
            adjustment.pos_y || 0
          )
        );
      });
    } else if (showingDollMask && showingDollMask.length) {
      showingDollMask.forEach(() => {
        newPosXAdjustment.push(0);
        newPosYAdjustment.push(0);
        newRotateAdjustment.push(0);
        configList.push(createMaskConfig(0, 0, 0));
      });
    }

    if (newPosXAdjustment.length) {
      setDollPosXAdjustment(newPosXAdjustment);
    }

    if (newPosYAdjustment.length) {
      setDollPosYAdjustment(newPosYAdjustment);
    }

    if (newRotateAdjustment.length) {
      setDollRotateAdjustment(newRotateAdjustment);
    }

    if (configList.length) {
      setSingleConfigList(configList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showingDollAdjustment, showingDollMask]);

  useEffect(() => {
    if (
      id &&
      printDoll &&
      printDoll.id &&
      showingDollMask &&
      showingDollMask.length
    ) {
      Api.getDollyAdjustment(id, printDoll.id).then(res => {
        setShowingDollAdjustment(res.result);
      });
    }
  }, [id, showingPrint, printDoll, showingDollMask]);

  useEffect(() => {
    if (printDoll && printDoll.masks && printDoll.masks.length) {
      setShowingDollMask(printDoll.masks);
    }
  }, [printDoll]);

  const alertNewFeedback = () => {
    setNewFeedback(true);
    setTimeout(() => {
      setNewFeedback(false);
    }, 500);
  };

  const calcProgress = progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setSavingProgress(percentCompleted);
  };

  const progressConfig = {
    onUploadProgress(progressEvent) {
      calcProgress(progressEvent);
    }
  };

  useEffect(() => {
    (async function changePrintFiles() {
      if (jpg.length) {
        const formData = new FormData();

        if (
          !jpg[0].name.toUpperCase().includes('.JPG') &&
          !jpg[0].name.toUpperCase().includes('.JPEG') &&
          !jpg[0].name.toUpperCase().includes('.PNG')
        ) {
          setJpg([]);
          toast(translate('invalidFileFormat', language), {
            type: 'error'
          });
        } else {
          await uploadNewFile(
            `${showingPrint.code}.${jpg[0].name.split('.')[1]}`,
            jpg[0],
            `${process.env.REACT_APP_PRINT_JPG_PATH}/${showingPrint.code}`,
            
          );
          await Api.psdFileSuccess({
            print_id: showingPrint.id,
            path: `${process.env.REACT_APP_PRINT_JPG_PATH}/${showingPrint.code}/${showingPrint.code}.${jpg[0].name.split('.')[1]}`,
            file_field: 'print_jpg'
          });
          setJpg([]);
          let res;
          try {
            res = await Api.updatePrint(
              showingPrint.id,
              formData,
              progressConfig
            );
          } catch {
            res = showingPrint;
            toast(translate('failUpdatingPrint', language), {
              type: 'error'
            });
          } finally {
            setShowingPrint(res);
            setSavingProgress(false);
          }
        }
      }
    })();
  }, [jpg, language, progressConfig, showingPrint]);

  useEffect(() => {
    (async function changePrintFiles() {
      if (psdOriginal.length) {
        setSavingProgress(true);

        const file = psdOriginal[0];
        setPsdOriginal([]);
        try {
          await uploadNewFile(
            `${showingPrint.code}.${file.name.split('.')[1]}`,
            file,
            `${process.env.REACT_APP_PSD_ORIGINAL_PATH}/${showingPrint.code}`,
          );
          await Api.psdFileSuccess({
            print_id: showingPrint.id,
            path: `${process.env.REACT_APP_PSD_ORIGINAL_PATH}/${showingPrint.code}.${file.name.split('.')[1]}`,
            file_field: 'psd_original'
          });
          await Api.getPrint(id).then(res => {
            setShowingPrint(res);
          });
        } catch {
          toast(translate('failUpdatingPrint', language), {
            type: 'error'
          });
        } finally {
          setSavingProgress(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressConfig, psdOriginal, psdOriginal.length]);

  const approvePrint = async () => {
    setConfirmationModal(false);
    const formData = new FormData();
    formData.append('status', 'APP');
    setSavingProgress(true);

    try {
      const res = await Api.updatePrint(showingPrint.id, formData);
      //Updata o campo de origin id na tabela family
      const formIdData = new FormData();
      formIdData.append('print_origin_id', showingPrint.id)
      if(showingPrint.family_id) {
        await Api.updateFamilyId(showingPrint.family_id, formIdData);
      }

      setShowingPrint(res);
    } catch {
      toast(translate('failUpdatingPrint', language), {
        type: 'error'
      });
    } finally {
      setSavingProgress(false);
    }
  };

  const editConfigNoScale = useMemo(() => {
    return {
      transform: `translateX(-50%) translateY(-50%) rotate(${rotate}deg)`,
      filter: ` contrast(${1 +
        contrast / 100}) hue-rotate(${color}deg) saturate(${1 +
        saturation / 100})`
    };
  }, [color, contrast, rotate, saturation]);

  const editConfig = useMemo(() => {
    return {
      transform: `translateX(-50%) translateY(-50%) rotate(${rotate}deg) scale(${1 +
        scale / 100})`,
      filter: ` contrast(${1 +
        contrast / 100}) hue-rotate(${color}deg) saturate(${1 +
        saturation / 100})`
    };
  }, [scale, color, contrast, rotate, saturation]);

  const onClickEditPrint = () => {
    setEditingMask(false);
    setEditingPrint(true);
  };

  const onClickReset = () => {
    setScale(0);
    setRotate(0);
    setBrightness(0);
    setContrast(0);
    setSaturation(0);
    setColor(0);
    setBackgroundColor('');
  };

  const onChangePosXAdjustments = (value, index) => {
    const newDollPosXAdjustment = [...dollPosXAdjustment];

    newDollPosXAdjustment.splice(index, 1, parseFloat(value));
    setDollPosXAdjustment(newDollPosXAdjustment);
  };

  const onChangePosYAdjustments = (value, index) => {
    const newDollPosYAdjustment = [...dollPosYAdjustment];

    newDollPosYAdjustment.splice(index, 1, parseFloat(value));
    setDollPosYAdjustment(newDollPosYAdjustment);
  };

  const onChangeRotateAdjustments = (value, index) => {
    const newDollRotateAdjustment = [...dollRotateAdjustment];

    newDollRotateAdjustment.splice(index, 1, parseFloat(value));
    setDollRotateAdjustment(newDollRotateAdjustment);
  };

  const renderPrintElements = useMemo(() => {
    if (showingPrint && showingPrintImage) {
      const fabricConfig =
        showingPrint.tags && showingPrint.tags.some(t => t.id === 65)
          ? editConfigNoScale
          : editConfig;

      return (
        <div className={styles.printContainer}>
          {showingPrint.tags && showingPrint.tags.some(t => t.id === 65) ? (
            <div
              className={`${styles.filtersContainer} ${styles.filtersContainerLocated}`}
            >
              <div className={styles.LocatedPrintWrapper}>
                <img
                  ref={imgElement}
                  src={showingPrintImage}
                  onLoad={() => {
                    setShowingPrintHeight(imgElement.current.naturalHeight);
                    setShowingPrintWidth(imgElement.current.naturalWidth);
                  }}
                  alt="estampa"
                />
                <div
                  className={styles.fabric}
                  style={{
                    backgroundColor: showingPrint.flat_background
                      ? backgroundColor || showingPrint.flat_background_color
                      : '#fff',
                    backgroundImage: `url(${showingPrintImage})`,
                    backgroundSize: printBackgroundSize,
                    backgroundPosition: printBackgroundPosition,
                    ...fabricConfig
                  }}
                  alt="print"
                />
                <div
                  className={styles.brightnessMask}
                  style={{
                    backgroundColor: brightness > 0 ? 'white' : 'black',
                    opacity: brightness < 0 ? -brightness : brightness
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              className={styles.filtersContainer}
              style={{
                backgroundColor: showingPrint.flat_background
                  ? backgroundColor || showingPrint.flat_background_color
                  : '#fff'
              }}
            >
              <img
                ref={imgElement}
                src={showingPrintImage}
                onLoad={() => {
                  setShowingPrintHeight(imgElement.current.naturalHeight);
                  setShowingPrintWidth(imgElement.current.naturalWidth);
                }}
                alt="estampa"
              />
              <div
                className={styles.fabric}
                style={{
                  backgroundImage: `url(${showingPrintImage})`,
                  backgroundSize: printBackgroundSize || 'auto',
                  backgroundPosition: printBackgroundPosition || 'auto',
                  ...fabricConfig
                }}
                alt="print"
              />
              <div
                className={styles.brightnessMask}
                style={{
                  backgroundColor: brightness > 0 ? 'white' : 'black',
                  opacity: brightness < 0 ? -brightness : brightness
                }}
              />
            </div>
          )}
          {showingPrint &&
          showingPrint.jpeg_height &&
          showingPrint.jpeg_width ? (
            <p className={styles.rapport}>
              rapport:{' '}
              <b>{`${showingPrint.jpeg_width}cm x ${showingPrint.jpeg_height}cm`}</b>
            </p>
          ) : null}
        </div>
      );
    }

    return null;
  }, [
    backgroundColor,
    brightness,
    editConfig,
    showingPrint,
    printBackgroundSize,
    printBackgroundPosition,
    editConfigNoScale,
    showingPrintImage
  ]);

  const isCustomized = useMemo(() => {
    if (
      scale === 0 &&
      rotate === 0 &&
      brightness === 0 &&
      contrast === 0 &&
      saturation === 0 &&
      color === 0 &&
      backgroundColor === ''
    ) {
      return false;
    }
    return true;
  }, [scale, rotate, brightness, contrast, saturation, color, backgroundColor]);

  const savePrintCopy = async () => {
    setSavingProgress(true);

    const srcToFile = (src, fileName, mimeType) => {
      return fetch(src)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], fileName, { type: mimeType }));
    };
    const originalPrint = await Api.getPrint(showingPrint.id);
    const formData = new FormData();

    formData.append('name', `${originalPrint.code} CUSTOMIZADA`);
    formData.append('status', `APP`);
    formData.append('is_origin', false);
    formData.append('is_customization', true);

    if (originalPrint.coordinator_id) {
      formData.append('coordinator', originalPrint.coordinator_id);
    }

    if (scale !== 0 || rotate !== 0) {
      formData.append('origin', originalPrint.id);
      formData.append('status', 'APP');
    } else {
      formData.append('code', originalPrint.code.split(' ')[0]);
      formData.append('origin', originalPrint.id);
    }

    if (
      scale !== 0 ||
      rotate !== 0 ||
      brightness !== 0 ||
      contrast !== 0 ||
      saturation !== 0 ||
      color !== 0
    ) {
      formData.append('is_twin', true);
    } else {
      formData.append('is_twin', false);
    }

    if (showingPrint.tags.length) {
      formData.append('tag_ids', showingPrint.tags.map(t => t.id).join(','));
    }

    const createdPrint = await Api.createPrint(formData);

    if (language === 'en') {
      const imgFile = await srcToFile(
         customizedPrint,
          'customizedPrint.jpg',
         'image/jpg'
        );
 
        await uploadNewFile(
         `${createdPrint.code}.${imgFile.name.split('.')[1]}`,
         imgFile,
         `${process.env.REACT_APP_PRINT_JPG_PATH}`,
       );
 
       await Api.psdFileSuccess({
         print_id: createdPrint.id,
         path: `${process.env.REACT_APP_PRINT_JPG_PATH}/${createdPrint.code}.${imgFile.name.split('.')[1]}`,
         file_field: 'print_jpg'
       });
         
     } else {
       const imgFile = await srcToFile(
         estampaCustomizada,
         'estampaCustomizada.jpg',
         'image/jpg'
     );
       await uploadNewFile(
         `${createdPrint.code}.${imgFile.name.split('.')[1]}`,
         imgFile,
         `${process.env.REACT_APP_PRINT_JPG_PATH}`,
         
       );
       await Api.psdFileSuccess({
         print_id: createdPrint.id,
         path: `${process.env.REACT_APP_PRINT_JPG_PATH}/${createdPrint.code}.${imgFile.name.split('.')[1]}`,
         file_field: 'print_jpg'
       });
     }

    const getBackgroundColor = () => {
      if (backgroundColor) {
        return backgroundColor;
      }

      if (showingPrint.flat_background && showingPrint.flat_background_color) {
        return showingPrint.flat_background_color;
      }

      return '';
    };
    const getFlatBackground = () => {
      if (backgroundColor) {
        return true;
      }

      if (originalPrint) {
        return originalPrint.flat_background;
      }

      return false;
    };

    const customizationData = {
      print: createdPrint.id,
      sender: user ? user.id : '',
      rotate,
      scale,
      contrast,
      color,
      saturation,
      brightness,
      flat_background: getFlatBackground(),
      flat_background_color: getBackgroundColor()
    };

    await Api.createCustomization(customizationData)
      .then(() => {
        setReloadPrints(true);
        toast(translate('successCopyPrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorCopyPrint', language), {
          type: 'error'
        });
      })
      .finally(() => setSavingProgress(false));
  };

  const renderDollElements = useMemo(() => {
    const currentDoll = printDoll;
    const isNewDoll =
      currentDoll &&
      currentDoll.masks &&
      currentDoll.masks.length &&
      currentDoll.masks.length > 0;

    if (currentDoll) {
      if (isNewDoll && showingPrint) {
        return (
          <div className={styles.dollContainerWrapper}>
            <div className={styles.dollContainer}>
              <div className={styles.dollBackgroundContainer}>
                {currentDoll.masks.map((mask, index) => (
                  <div
                    className={styles.dollMask}
                    style={{
                      WebkitMaskImage: `url(${mask.mask})`,
                      maskImage: `url(${mask.mask})`
                    }}
                  >
                    <div
                      className={styles.fabric}
                      style={{
                        backgroundImage: `url(${showingPrintImage})`,
                        backgroundColor: showingPrint.flat_background
                          ? backgroundColor ||
                            showingPrint.flat_background_color
                          : '#fff',
                        ...editConfig,
                        backgroundSize: showingPrint.jpeg_width
                          ? `auto ${calcDollRapport(
                              showingPrint.jpeg_width,
                              showingPrint.jpeg_height,
                              currentDoll.rapport_propor
                            ) / 32}%`
                          : '3.125%',
                        ...singleConfigList[index]
                      }}
                      alt="print"
                    />
                  </div>
                ))}
                <div
                  className={styles.brightnessMask}
                  style={{
                    backgroundColor: brightness > 0 ? 'white' : 'black',
                    opacity: brightness < 0 ? -brightness : brightness
                  }}
                />
              </div>
              <img
                className={styles.object}
                ref={dollRef}
                src={currentDoll.image_dolly || currentDoll.image_dolly_url}
                alt="boneca"
              />
              <img
                src={currentDoll.image_shadow || currentDoll.image_shadow_url}
                className={styles.fabricShadow}
                alt="vestido"
              />
            </div>
          </div>
        );
      }

      return (
        printDoll && (
          <div className={styles.dollContainerWrapper}>
            <div className={styles.dollContainer}>
              <div
                className={styles.dollBackgroundContainer}
                style={{
                  backgroundColor:
                    showingPrint && showingPrint.flat_background
                      ? backgroundColor || showingPrint.flat_background_color
                      : '#fff'
                }}
              >
                {showingPrint && (
                  <div
                    style={{
                      backgroundImage: `url(${showingPrintImage})`,
                      ...singleConfigList[0],
                      ...editConfig,
                      backgroundSize: showingPrint.jpeg_width
                        ? `auto ${calcDollRapport(
                            showingPrint.jpeg_width,
                            showingPrint.jpeg_height,
                            currentDoll.rapport_propor
                          ) / 32}%`
                        : '3.125%',
                      top: `calc(50% - ${currentDoll.rapport_propor || 0}%)`
                    }}
                    className={styles.fabric}
                    alt="print"
                  />
                )}
                <div
                  style={{
                    backgroundColor: brightness > 0 ? 'white' : 'black',
                    opacity: brightness < 0 ? -brightness : brightness
                  }}
                  className={styles.brightnessMask}
                />
              </div>
              <img
                ref={dollRef}
                className={styles.object}
                src={currentDoll.image_dolly || currentDoll.image_dolly_url}
                alt="boneca"
              />
              <img
                src={currentDoll.image_shadow || currentDoll.image_shadow_url}
                className={styles.fabricShadow}
                alt="vestido"
              />
              <div className={styles.adjustment} />
            </div>
          </div>
        )
      );
    }

    return null;
  }, [
    showingPrint,
    editConfig,
    brightness,
    singleConfigList,
    backgroundColor,
    printDoll,
    showingPrintImage
  ]);
  const savePrint = () => {
    setSavingProgress(true);

    const getBackgroundColor = () => {
      if (backgroundColor) {
        return backgroundColor;
      }

      if (showingPrint.flat_background && showingPrint.flat_background_color) {
        return showingPrint.flat_background_color;
      }

      return '';
    };

    const getFlatBackground = () => {
      if (backgroundColor) {
        return true;
      }

      if (showingPrint) {
        return showingPrint.flat_background;
      }

      return false;
    };

    const customizationData = {
      print: showingPrint.id,
      sender: user ? user.id : '',
      rotate,
      scale,
      contrast,
      color,
      saturation,
      brightness,
      flat_background: getFlatBackground(),
      flat_background_color: getBackgroundColor()
    };

    Api.createCustomization(customizationData)
      .then(() => {
        setReloadPrints(true);
        toast(translate('successSavingPrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorSavingPrint', language), {
          type: 'error'
        });
      })
      .finally(() => setSavingProgress(false));
  };

  const onClickEditMask = () => {
    setEditingPrint(false);
    setEditingMask(true);
  };

  const handleClickStopEdit = () => {
    setEditingPrint(false);
    setEditingMask(false);
  };

  useEffect(() => {
    if (
      showingDollMask &&
      !showingDollMask.length &&
      printDoll &&
      printDoll.id &&
      showingPrint &&
      showingPrint.id
    ) {
      setLoading(true);

      Api.getMainDollyAdjustment(showingPrint.id, printDoll.id)
        .then(res => {
          if (res.count) {
            setDollMainAdjustment(res.result[0]);
            setDollPosXAdjustment([res.result[0].pos_x]);
            setDollPosYAdjustment([res.result[0].pos_y]);
          } else {
            setDollPosXAdjustment([0]);
            setDollPosYAdjustment([0]);
            setDollMainAdjustment({
              pos_x: 0,
              pos_y: 0,
              dolly: printDoll.id,
              print: showingPrint.id
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [printDoll, showingDollMask, showingPrint]);

  useEffect(() => {
    const configList = [];

    if (showingDollMask && showingDollMask.length) {
      showingDollMask.forEach((mask, index) => {
        let x = 0;
        let y = 0;
        let r = parseFloat(rotate) || 0;

        if (dollPosXAdjustment[index]) {
          x = dollPosXAdjustment[index];
        } else if (
          showingDollAdjustment &&
          showingDollAdjustment.length &&
          showingDollAdjustment[index] &&
          showingDollAdjustment[index].pos_x
        ) {
          x = showingDollAdjustment[index].pos_x;
        }

        if (dollPosYAdjustment[index]) {
          y = dollPosYAdjustment[index];
        } else if (
          showingDollAdjustment &&
          showingDollAdjustment.length &&
          showingDollAdjustment[index] &&
          showingDollAdjustment[index].pos_y
        ) {
          y = showingDollAdjustment[index].pos_y;
        }

        if (dollRotateAdjustment[index]) {
          r += dollRotateAdjustment[index];
        } else if (
          showingDollAdjustment &&
          showingDollAdjustment.length &&
          showingDollAdjustment[index] &&
          showingDollAdjustment[index].rotate
        ) {
          r += showingDollAdjustment[index].rotate;
        }

        configList.push(createMaskConfig(r, x, y));
      });
    } else {
      let x = 0;
      let y = 0;
      const r = parseFloat(rotate) || 0;

      if (dollPosXAdjustment[0]) {
        [x] = dollPosXAdjustment;
      } else if (dollMainAdjustment && dollMainAdjustment.pos_x) {
        x = dollMainAdjustment.pos_x;
      }

      if (dollPosYAdjustment[0]) {
        [y] = dollPosYAdjustment;
      } else if (dollMainAdjustment && dollMainAdjustment.pos_y) {
        y = dollMainAdjustment.pos_y;
      }

      configList.push(createMaskConfig(r, x, y));
    }

    setSingleConfigList(configList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dollPosXAdjustment,
    dollPosYAdjustment,
    dollRotateAdjustment,
    setSingleConfigList,
    showingDollAdjustment,
    showingDollMask,
    scale,
    rotate,
    dollRotateAdjustment,
    dollMainAdjustment
  ]);

  const handleSubmitDollyAdjustment = () => {
    if (
      showingPrint &&
      showingPrint.id &&
      showingDollMask &&
      showingDollMask.length
    ) {
      showingDollMask.forEach((mask, index) => {
        setLoading(true);

        const newAdjustment = {
          rotate: dollRotateAdjustment[index] || 0,
          scale: 0,
          pos_x: dollPosXAdjustment[index] || 0,
          pos_y: dollPosYAdjustment[index] || 0,
          mask: mask.id,
          print: showingPrint.id
        };

        const adjustmentId =
          showingDollAdjustment[index] && showingDollAdjustment[index].id
            ? showingDollAdjustment[index].id
            : null;

        if (adjustmentId) {
          Api.updateDollyAdjustment(adjustmentId, newAdjustment)
            .then(() => {})
            .finally(() => setLoading(false));
        } else {
          Api.addDollyAdjustment(newAdjustment)
            .then(() => {})
            .finally(() => setLoading(false));
        }
      });
    } else if (dollMainAdjustment && dollMainAdjustment.id) {
      setLoading(true);

      Api.updateMainDollyAdjustment(dollMainAdjustment.id, {
        pos_x: dollPosXAdjustment[0] || dollMainAdjustment.pos_x || 0,
        pos_y: dollPosYAdjustment[0] || dollMainAdjustment.pos_y || 0
      })
        .then(() => {})
        .finally(() => setLoading(false));
    } else if (dollMainAdjustment) {
      setLoading(true);

      Api.addMainDollyAdjustment({
        ...dollMainAdjustment,
        pos_x: dollPosXAdjustment[0] || dollMainAdjustment.pos_x || 0,
        pos_y: dollPosYAdjustment[0] || dollMainAdjustment.pos_y || 0
      })
        .then(() => {})
        .finally(() => setLoading(false));
    }
  };

  const cancelEditing = () => {
    setEditingMask(false);
    setEditingPrint(false);
  };

  const handleResetMask = () => {
    if (showingDollMask && showingDollMask.length) {
      const newDollPosXAdjustment = [];
      const newDollPosYAdjustment = [];
      const newDollRotateAdjustment = [];

      showingDollMask.forEach(() => {
        newDollPosXAdjustment.push(0);
        newDollPosYAdjustment.push(0);
        newDollRotateAdjustment.push(0);
      });

      setDollPosXAdjustment(newDollPosXAdjustment);
      setDollPosYAdjustment(newDollPosYAdjustment);
      setDollRotateAdjustment(newDollRotateAdjustment);
    } else {
      setDollPosXAdjustment([0]);
      setDollPosYAdjustment([0]);
      setDollRotateAdjustment([0]);
    }
  };

  return (
    <div className={styles.PrintApproval}>
      <div className={styles.visualization}>
        {renderPrintElements}
        {renderDollElements}
      </div>
      {showingPrint && (
        <div className={styles.actions}>
          <div>
            <button
              type="button"
              onClick={() => {
                previousPrint()
                handleResetMask()
                onClickReset()
              }}
              className={styles.previousButton}
            >
              <span className={styles.backArrow} />
            </button>
            <button
              type="button"
              onClick={() => {
                nextPrint()
                handleResetMask()
                onClickReset()
              }}
              className={styles.nextButton}
            >
              <span className={styles.nextArrow} />
            </button>
            <div className={styles.counter}>
              {printPosition}/{printCounter}
            </div>
            <button
              className={styles.editPrint}
              type="button"
              onClick={onClickEditPrint}
            >
              <EditFilter width="22px" height="22px" />
            </button>
            <button
              className={styles.editPrint}
              type="button"
              onClick={onClickEditMask}
            >
              <EditMask width="39px" height="39px" />
            </button>
          </div>
          <div className={styles.filesManagement}>
            <div className={styles.selectorContainer}>
              <a
                href={showingPrint.psd_original_url}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.download} ${
                  showingPrint.psd_original_url ? '' : styles.disabled
                }`}
              >
                <DownloadIcon />
              </a>
              <div className={styles.label}>PSD</div>
            </div>
            {showingPrint.status !== 'APP' && (
              <div className={styles.uploads}>
                <div className={styles.selectorContainer}>
                  <FileSelector mini files={jpg} onSelect={setJpg} icon />
                  <div className={styles.label}>JPG</div>
                </div>
                <div className={styles.selectorContainer}>
                  <FileSelector
                    mini
                    files={psdOriginal}
                    onSelect={setPsdOriginal}
                    icon
                  />
                  <div className={styles.label}>PSD</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <sidebar className={styles.menu}>
        <button
          className={styles.close}
          type="button"
          onClick={() => {
            if (reloadPrints) {
              handleReloadPrints(true);
            }
            close();
          }}
        >
          <CloseIcon color="black" />
        </button>
        {showingPrint && !editingPrint && !editingMask && (
          <>
            <div className={styles.printName}>{showingPrint.name}</div>
            <div className={styles.printCode}>{showingPrint.code}</div>
            <div className={styles.info}>
              <div>Designer: {showingPrint.designer_name}</div>
              <div>
                Rapport: {showingPrint.jpeg_width}cm x{' '}
                {showingPrint.jpeg_height}cm
              </div>
            </div>
            <button
              type="button"
              className={`${styles.defaultRoundedActionButton} ${styles.approve}`}
              onClick={() => setConfirmationModal(true)}
              disabled={showingPrint.status === 'APP'}
            >
              {showingPrint.status !== 'APP'
                ? translate('approve', language)
                : translate('printApproved', language)}
            </button>
            {briefings.length > 0 && (
              <div className={styles.briefings}>
                <div className={styles.sectionTitle}>Briefings vinculados</div>
                <div className={styles.briefingList}>
                  {briefings.map(briefing => (
                    <Link
                      to={`/briefings/?id=${briefing.id}`}
                      key={briefing.id}
                      className={styles.briefingCard}
                      type="button"
                    >
                      {!!briefing.cover_url && (
                        <img src={briefing.cover_url} alt={briefing.text} />
                      )}
                      <div className={styles.info}>
                        {briefing.new && (
                          <span className={styles.new}>
                            {translate('new', language)}
                          </span>
                        )}
                        <span className={styles.label}>
                          {translate('briefing', language)}
                        </span>
                        <span className={styles.title}>{briefing.text}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.lastVersion}>
              <div className={styles.sectionTitle}>
                {translate('previousVersions', language)}
              </div>
              <div className={styles.versions}>
                {showingPrint.historic_one_url && (
                  <img
                    src={showingPrint.historic_one_url}
                    className={styles.lastVersionImage}
                    alt="historic one"
                  />
                )}
                {showingPrint.historic_two_url && (
                  <img
                    src={showingPrint.historic_two_url}
                    className={styles.lastVersionImage}
                    alt="historic two"
                  />
                )}
                {showingPrint.historic_three_url && (
                  <img
                    src={showingPrint.historic_three_url}
                    className={styles.lastVersionImage}
                    alt="historic three"
                  />
                )}
              </div>
            </div>
          </>
        )}
        {showingPrint && (editingPrint || editingMask) ? (
          <section className={styles.customizeWrapper}>
            <CustomizePrintAndDoll
              print={showingPrint}
              scale={scale}
              setScale={setScale}
              rotate={rotate}
              setRotate={setRotate}
              brightness={brightness}
              setBrightness={setBrightness}
              contrast={contrast}
              setContrast={setContrast}
              saturation={saturation}
              setSaturation={setSaturation}
              color={color}
              setColor={setColor}
              backgroundColor={backgroundColor}
              setBackgroundColor={setBackgroundColor}
              handleResetPrint={onClickReset}
              customizePrint={editingPrint}
              dollMask={showingDollMask}
              dollPosXAdjustment={dollPosXAdjustment}
              handleChangePosXAdjustments={onChangePosXAdjustments}
              dollPosYAdjustment={dollPosYAdjustment}
              handleChangePosYAdjustments={onChangePosYAdjustments}
              dollRotateAdjustment={dollRotateAdjustment}
              handleChangeRotateAdjustments={onChangeRotateAdjustments}
              isCustomized={isCustomized}
              handleSavePrintCopy={savePrintCopy}
              handleSavePrint={savePrint}
              closeCustomize={cancelEditing}
              customizeMask={editingMask}
              handleClickStopEdit={handleClickStopEdit}
              handleSubmitDollyAdjustment={handleSubmitDollyAdjustment}
              handleResetMask={handleResetMask}
            />
          </section>
        ) : null}
      </sidebar>
      {showingPrint && (
        <div className={styles.feedbackContainer}>
          <Feedback
            mini
            printId={showingPrint.id}
            openChat={() => setFeedbackMessages(true)}
            update={newFeedback}
          />
        </div>
      )}
      {(savingProgress || loading) && <Loading fixed />}
      {confirmationModal && (
        <ConfirmationModal
          title={translate('approvementConfirmationTitle', language)}
          message={translate('approvementConfirmationMessage', language)}
          confirmText={translate('approvementConfirmationOption', language)}
          cancelText={translate('approvementCancelOption', language)}
          onConfirm={approvePrint}
          onCancel={() => setConfirmationModal(false)}
        />
      )}
      {feedbackMessages && (
        <Feedback
          close={() => setFeedbackMessages(false)}
          printId={showingPrint.id}
          newFeedback={alertNewFeedback}
          editable={showingPrint.status === 'SKE'}
        />
      )}
    </div>
  );
}

export default PrintApproval;

PrintApproval.propTypes = {
  id: PropTypes.number.isRequired,
  nextPrint: PropTypes.func.isRequired,
  previousPrint: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  printPosition: PropTypes.number.isRequired,
  printCounter: PropTypes.number.isRequired,
  handleReloadPrints: PropTypes.func.isRequired
};
