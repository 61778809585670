export const formatDate = (date, shortDate, notHour) => {
    if (shortDate) { 
      return new Date(date).toLocaleDateString("pt-BR", {
        timeZone: "America/Sao_Paulo",
        day: "2-digit",
        month: "2-digit"
    })}
    if (notHour) {
      return new Date(date).toLocaleDateString("pt-BR", {
        timeZone: "America/Sao_Paulo",   
    });
    }
    return new Date(date).toLocaleDateString("pt-BR", {
        timeZone: "America/Sao_Paulo",
        hour: "2-digit",
        minute: "2-digit"    
    });
  }
  
  