import React from 'react';
import styles from './ClientSelectorModal.module.scss';
import CloseIcon from '../../assets/icons/Close';

export default function ClientSelectorModal({title, onCancel, children, onConfirm, confirmText}) {

    return(
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                <button className={styles.close} type="button" onClick={onCancel}>
                    <CloseIcon color="#00c4c5" />
                </button>

                <h3 className={styles.title}>{title}</h3>
                <section className={styles.content}>
                    {children}    
                </section>
                
                
                <button className={styles.confirmButton} onClick={onConfirm}>
                        {confirmText}
                </button>

                
            </div>
        </div>
    )
}