/* eslint-disable no-console */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import fileDownload from 'js-file-download'

class Api {
  constructor() {
    this.authURL = process.env.REACT_APP_API_AUTH_URL;

    axios.defaults.timeout = 3600000;
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    const accessToken = localStorage.getItem('leatkn');
    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    createAuthRefreshInterceptor(axios, this.refreshAuth);
  }

  auth = async (email, password) => {
    return axios({
      method: 'post',
      url: this.authURL,
      data: `grant_type=password&username=${email}&password=${password}&client_id=${process.env.REACT_APP_API_CLIENT_KEY}&client_secret=${process.env.REACT_APP_API_CLIENT_SECRET}`
    })
      .then(res => {
        localStorage.setItem('leatkn', res.data.access_token);
        localStorage.setItem('lertkn', res.data.refresh_token);
        axios.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
        return res;
      })
      .catch(() => {
        return false;
      });
  };

  refreshAuth = failedRequest => {
    const refreshToken = localStorage.getItem('lertkn');
    if (refreshToken) {
      return axios({
        method: 'post',
        url: this.authURL,
        data: `grant_type=refresh_token&client_id=${process.env.REACT_APP_API_CLIENT_KEY}&client_secret=${process.env.REACT_APP_API_CLIENT_SECRET}&refresh_token=${refreshToken}`
      })
        .then(res => {
          localStorage.setItem('leatkn', res.data.access_token);
          localStorage.setItem('lertkn', res.data.refresh_token);
          // eslint-disable-next-line no-param-reassign
          failedRequest.response.config.headers.Authorization = `Bearer ${res.data.access_token}`;
          return Promise.resolve();
        })
        .catch(() => {
          localStorage.clear('leatkn');
          localStorage.clear('lertkn');
          window.location.href = '/';
        });
    }
    return false;
  };

  authClient = async password => {
    return axios
      .get(`/meeting_client/?token=${password}`)
      .then(res => {
        return res;
      })
      .catch(() => {
        return false;
      });
  };

  getUser = () => {
    return axios
      .get('/user/')
      .then(res => {
        return res.data[0];
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getPrints = (filters, config) => {
    return axios
      .get(`/print/${filters}`, { ...config })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getMiniPrint = id => {
    return axios
      .get(`/mini_print/?print_id=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getLibrary = (filters, config) => {
    return axios
      .get(`/library/${filters}`, { ...config })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getPrint = id => {
    return axios
      .get(`/print/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createPrint = (formData, progressConfig) => {
    return axios
      .post('/print/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        ...progressConfig
      })
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create Print');
      });
  };

  updatePrint = (id, formData, progressConfig = null) => {
    if (progressConfig) {
      return axios
        .patch(`/print/${id}/`, formData, {
          ...progressConfig
        })
        .then(res => {
          return res.data;
        })
        .catch(() => {
          throw new Error('Failed to update Print');
        });
    }
    return axios
      .patch(`/print/${id}/`, formData)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update Print');
      });
  };

  deletePrint = id => {
    return axios
      .delete(`/print/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete Print');
      });
  };

  deleteFamily = id => {
    return axios
      .delete(`/family/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete Family');
      });
  };

  updateFamilyId = id => {
    return axios
    .put(`/family/${id}/`)
    .then(res => {
      return res.data
    })
    .catch(() => {
      throw new Error('Failed to update Family');
    });
  }

  createFamilyPrint = name => {
    return axios.post(`/family/`, name)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create family print');
    })
  }

  getMyPrints = (filter = '') => {
    return axios
      .get(`/home/${filter}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createBriefing = (formData, progressConfig) => {
    return axios
      .post('/briefing/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        ...progressConfig
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to create Briefing');
      });
  };

  deleteBriefing = id => {
    return axios.delete(`/briefing/${id}`).then(res => {
      return res
    }).catch(err => {
      console.error(err);
      return false;
    })
  }

  updatePrintBriefing = (id, formData) => {
    return axios
      .patch(`/print_briefing/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to update Briefing');
      });
  };

  updateBriefing = (id, data) => {
    return axios
      .patch(`/briefing/${id}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to update Briefing');
      });
  };

  deletePrintBriefing = id => {
    return axios
      .delete(`/print_briefing/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to delete Briefing');
      });
  };

  deleteRerenceBriefings = id => {
    return axios.delete(`/visual_reference/${id}`)
      .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error('Failed to delete Briefing');
    });
  }

  getCollections = () => {
    return axios
      .get('/collection/')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getClients = () => {
    return axios
      .get('/client/?all=true')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getClientById = id => {
    return axios
      .get(`/client/${id}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);

        return false;
      });
  };

  getCoordinators = () => {
    return axios
      .get('/user/?role=coordinator')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getArtFinalists = () => {
    return axios
      .get('/user/?role=art-finisher')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getDesigners = () => {
    return axios
      .get('/user/?role=designer')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getExtDesigners = () => {
    return axios
      .get('/user/?role=ext-designer')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getSellers = () => {
    return axios
      .get('/user/?role=seller')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createVisualReference = formData => {
    return axios
      .post('/visual_reference/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createPrintBriefing = data => {
    return axios
      .post('/print_briefing/', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createBriefing = data => {
    return axios
      .post('/briefing/', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getPrintBriefings = () => {
    return axios
      .get('/print_briefing/')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getBriefings = filter => {
    return axios
      .get(`/briefing/${filter || ''}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getUsers = () => {
    return axios
      .get('/user/?role=all')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createClientUser = (data) => {
    return axios
    .post('/user/', data)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      return false;
    });
  }

  updateUser = (userId, data) => {
    return axios
      .patch(`/user/${userId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getAllUsersIsNotClient = () => {
    return axios
      .get('/user/?is_client=false&role=seller')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getUserIsNotClient = () => {
    return axios
      .get('/user/?client=False')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getUserIsClient = () => {
    return axios
      .get('/user/?client=True')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getTags = () => {
    return axios
      .get('/tag')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getChromaticVariations = id => {
    return axios
      .get(`/color/?print=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getTwins = id => {
    return axios
      .get(`/twin/?print=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getPrintBriefing = id => {
    return axios
      .get(`/print_briefing/?print=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getBriefing = id => {
    return axios
      .get(`/briefing/${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getReserves = id => {
    return axios
      .get(`/reserve/?print=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getRegion = () => {
    return axios
      .get('/region/')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  createCollection = data => {
    return axios
      .post('/collection/', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to create Collection');
      });
  };

  createClient = data => {
    return axios
      .post('/client/', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to create Client');
      });
  };

  updateClient = (clientId, data) => {
    return axios
      .patch(`/client/${clientId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to update Client');
      });
  };

  getFeedbacks = printId => {
    return axios
      .get(`/feedback/?print=${printId}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to get feedbacks');
      });
  };

  sendFeedback = (print, sender, text) => {
    return axios
      .post(`/feedback/`, { print, sender, text })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        throw new Error('Failed to get feedbacks');
      });
  };

  readFeedback = (feedbackId, data) => {
    return axios
      .patch(`/feedback/${feedbackId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to read feedback');
      });
  };

  deleteFeedback = feedbackId => {
    return axios
      .delete(`/feedback/${feedbackId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete feedback');
      });
  };

  createAttendance = data => {
    return axios
      .post(`/meeting_box/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create attendance');
      });
  };

  getAttendances = () => {
    return axios
      .get(`/meeting_box/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get the attendances');
      });
  };

  createPresentation = data => {
    return axios
      .post(`/meeting/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create presentation');
      });
  };

  includeAttendancePresentation = data => {
    return axios
      .post(`/selected_meeting/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to add presentation to attendance');
      });
  };

  patchAttendancePresentationPosition = (presentationId, position) => {
    return axios.patch(`/selected_meeting/${presentationId}/`, {
      position,
    }).then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to change presentation position');
    });
  }

  removeAttendancePresentation = (attendanceId, data) => {
    return axios
      .patch(`/meeting_box/${attendanceId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to remove attendance presentation');
      });
  };

  getPresentations = () => {
    return axios
      .get(`/meeting/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentations');
      });
  };

  getPresentationsMini = () => {
    return axios
      .get(`/mini_meetings/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentations');
      });
  };

  getPresentationInvites = presentationId => {
    return axios
      .get(`/sharedwith/?meeting=${presentationId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation invites');
      });
  };

  getBriefingInvites = briefingId => {
    return axios
      .get(`/briefing_sharedwith/?briefing=${briefingId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get briefing invites');
      });
  };

  invite = data => {
    return axios
      .post(`/sharedwith/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create invite');
      });
  };

  inviteBriefing = data => {
    return axios
      .post(`/briefing_sharedwith/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create invite');
      });
  };

  deleteInvite = inviteId => {
    return axios
      .delete(`/sharedwith/${inviteId}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete invite');
      });
  };

  deleteBriefingInvite = inviteId => {
    return axios
      .delete(`/briefing_sharedwith/${inviteId}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete invite');
      });
  };

  createClientArea = (clientId, data) => {
    return axios
    .post(`/client_area/`, {
      ...data,
      client: clientId
    })
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed create client area');
    });
  }

  getClientAreas = (clientId) => {
    return axios
    .get(`/client_area/?client=${clientId}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed get client area');
    });
  }

  getAttendanceInvites = attendanceId => {
    return axios
      .get(`/sharedwith/?meeting_box=${attendanceId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get attendance invites');
      });
  };

  attendanceInvite = data => {
    return axios
      .post(`/sharedwith/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create attendance invite');
      });
  };

  getFabrics = () => {
    return axios
      .get(`/fabric/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get fabrics');
      });
  };

  getSpecificFabric = id => {
    return axios
    .get(`/fabric/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get specific fabric');
    });
  };
  

  getSpecificFabric = id => {
    return axios
    .get(`/fabric/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get specific fabric');
    });
  };
  getSpecificSupplier = id => {
    return axios
    .get(`/supplier/${id}/`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get specific supplier');
    });
  };
  getSupplier = filter => {
    return axios
    .get(`/supplier/?${filter}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get the suppliers');
    });
  }

  sendEmailSupplierToken = id => {
    return axios
    .get(`/send_token_supplier/?supplier_id=${id}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get the suppliers');
    });
  }

  getFabricImages = id => {
    return axios
      .get(`/fabric_image/?fabric=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get fabric images');
      });
  };

  createProduct = data => {
    return axios
      .post(`/product/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create the product');
      });
  };

  updateProduct = (id, data) => {
    return axios
      .patch(`/product/${id}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update the product');
      });
  };

  deleteSelectedProduct = (presentationId, selectedPrintId) => {
    return axios
      .post(`/remove_selected_print/`, {
        meeting_id: presentationId,
        selected_print_id: selectedPrintId
      })
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete selected print');
      });
  };

  addSelectedPrintToPresentation = (presentation, data) => {
    return axios
      .post(`/selected_print/?meeting=${presentation}`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create the product');
      });
  };

  getPresentationSelectedPrints = presentation => {
    return axios
      .get(`/selected_print/?meeting=${presentation}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation selected prints');
      });
  };

  getPresentationSelectedPrintsByClient = (presentation, client) => {
    return axios
      .get(`/selected_print/?meeting=${presentation}&client=${client}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation selected prints');
      });
  };

  getMyPresentations = filter => {
    return axios
      .get(filter ? `/my_meetings/?${filter}` : `/my_meetings/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get my presentations');
      });
  };

  deletePresentation = presentationId => {
    return axios
      .delete(`/meeting/${presentationId}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete presentation');
      });
  };

  duplicatePresentation = presentationId => {
    return axios
      .get(`/clone_meeting/?meeting_id=${presentationId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to duplicate presentation');
      });
  };

  duplicateAttendance = attendanceId => {
    return axios
      .get(`/clone_meeting_box/?meeting_box_id=${attendanceId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to duplicate presentation');
      });
  };

  deleteAttendance = attendanceId => {
    return axios
      .delete(`/meeting_box/${attendanceId}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete attendance');
      });
  };

  getAttendances = filter => {
    return axios
      .get(filter ? `/meeting_box/?${filter}` : `/meeting_box/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get the attendances');
      });
  };

  getAttendance = (attendanceId, filter = "") => {
    return axios
      .get(`/meeting_box/${attendanceId}/${filter}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get attendance');
      });
  };

  getFullAttendance = attendanceId => {
    return axios
      .get(`/meeting_box/${attendanceId}/?full=true`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get attendance');
      });
  };

  getPresentation = presentationId => {
    return axios
      .get(`/meeting/${presentationId}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation');
      });
  };

  getConcept = presentationId => {
    return axios
      .get(`/concept/?attendance=${presentationId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation concepts');
      });
  };

  createConcept = data => {
    return axios
      .post(`/concept/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create new concept');
      });
  };

  updateConcept = (conceptId, data) => {
    return axios
      .patch(`/concept/${conceptId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update concept');
      });
  };

  deleteConcept = id => {
    return axios
      .delete(`/concept/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create new concept');
      });
  };

  updatePresentation = (presentationId, data) => {
    return axios
      .patch(`/meeting/${presentationId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation');
      });
  };

  updateAttendance = (attendanceId, data) => {
    return axios
      .patch(`/meeting_box/${attendanceId}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get attendance');
      });
  };

  getSelectedPrints = presentationId => {
    return axios
      .get(`/selected_print/?meeting=${presentationId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get selected print');
      });
  };

  getCountries = () => {
    return axios
      .get(`/country/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get countries');
      });
  };

  getRegions = () => {
    return axios
      .get(`/region/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get regions');
      });
  };

  createAttendanceLink = data => {
    return axios
      .post(`/meeting_token/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create attendance link');
      });
  };

  createFabric = data => {
    return axios.post('/fabric/', data)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create fabric')
    })
  }

  editFabric = (data, id) => {
    return axios.patch(`/fabric/${id}/`, data)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create fabric')
    })
  }

  createSupplierFabric = (data) => {
    return axios.post('/supplier_fabric/', data)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create supplier fabric')
    })
  }

  createCharacteristicsFabric = (data) => {
    return axios.post('/fabric_characteristic/', data)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create fabric characteristic')
    })
  }

  createSupplier = (data) => {
    return axios.post('/supplier/', data);
  }
  editSupplier = (id, data) => {
    return axios.put(`/supplier/${id}/`, data)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create supplier')
    })
  }

  getSupplierFabric = () => {
    return axios.get('/supplier_fabric/')
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create fabric')
    })
  }

  getSpecificSupplierFabric = (id) => {
    return axios.get(`/supplier_fabric/?fabric_id=${id}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create fabric')
    })
  }

  getSpecificFabricCharacteristics = (id) => {
    return axios.get(`/fabric_characteristic/?fabric_id=${id}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get fabric characteristics')
    })
  }

  deleteSupplierFabric = (id) => {
    return axios.delete(`/supplier_fabric/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to create fabric')
    })
  }

  getPresentationDolls = presentationId => {
    return axios
      .get(`/dolly/?meeting_id=${presentationId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get presentation dolls');
      });
  };

  getCustomerProfiles = () => {
    return axios
      .get(`/customer_profile/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get customer profiles');
      });
  };

  getDollsByProfile = profiles => {
    return axios
      .get(`/dolly/?customer_profile=${profiles}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get dolls profiles');
      });
  };

  getMiniDollsByProfile = profiles => {
    return axios
      .get(`/dolly/?customer_profile=${profiles}&mini=true`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get dolls profiles');
      });
  };

  getDollyMasks = dollId => {
    return axios
      .get(`/dolly_mask/?dolly=${dollId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get dolls masks');
      });
  };

  getDollyAdjustment = (printId, dollId) => {
    return axios
      .get(`/dolly_adjustment/?print=${printId}&dolly=${dollId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get dolls adjustment');
      });
  };

  addDollyAdjustment = data => {
    return axios
      .post('/dolly_adjustment/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to add doll adjustment');
      });
  };

  updateDollyAdjustment = (adjustmentId, data) => {
    return axios
      .patch(`/dolly_adjustment/${adjustmentId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update doll adjustment');
      });
  };

  getMainDollyAdjustment = (printId, dollId) => {
    return axios
      .get(`/main_dolly_adjustment/?print=${printId}&dolly=${dollId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get main dolls adjustment');
      });
  };

  addMainDollyAdjustment = data => {
    return axios
      .post('/main_dolly_adjustment/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to add main doll adjustment');
      });
  };

  updateMainDollyAdjustment = (adjustmentId, data) => {
    return axios
      .patch(`/main_dolly_adjustment/${adjustmentId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update main doll adjustment');
      });
  };

  getManualApplication = printId => {
    return axios
      .get(`/manual_application/?print_id=${printId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get manual application');
      });
  };

  getExclusiveMeetingPrints = (
    meeting,
    regsQuery,
    countriesQuery,
    clientId
  ) => {
    return axios
      .get(
        `/selected_print/?meeting=${meeting}${
          regsQuery && regsQuery.length ? `&regs=${regsQuery}` : ''
        }${
          countriesQuery && countriesQuery.length
            ? `&countries=${countriesQuery}`
            : ''
        }${clientId ? `&client=${clientId}` : ''}`
      )
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get exclusive selected prints');
      });
  };

  getFavoriteExclusiveMeetingPrints = (clientId, meeting) => {
    return axios
      .get(`/favorite_selected_prints/?client=${clientId}&meeting=${meeting}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get favorite selected prints');
      });
  };

  updatePrintPosition = (printId, data) => {
    return axios
      .patch(`/selected_print/${printId}`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update print position');
      });
  };

  updateConceptImagePosition = (conceptId, data) => {
    return axios
      .patch(`/concept/${conceptId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update print position');
      });
  };

  createOrder = data => {
    return axios
      .post('/order/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create order');
      });
  };

  updateOrder = (orderId, data) => {
    return axios
      .patch(`/order/${orderId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update order');
      });
  };

  deleteOrder = id => {
    return axios
      .delete(`/order/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete order');
      });
  };

  sendOrderEmail = orderId => {
    return axios
      .get(`/send_order_email/?order_id=${orderId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to send order email');
      });
  };

  sendOrderTrakingLinkEmail = orderId => {
    return axios
      .get(`/order/send_tracking_url/?order_id=${orderId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to send order email');
      });
  };

  createClientOrder = (token, data) => {
    return axios
      .post(`/order_client/?token=${token}`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create order');
      });
  };

  getClientPresentation = (token, id) => {
    return axios
      .get(`/meeting_client/?token=${token}&meeting=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get client presentation');
      });
  };

  getMyOrders = token => {
    return axios
      .get(`/order_client/?token=${token}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get client orders');
      });
  };

  getMyOrder = (token, order) => {
    return axios
      .get(`/order_client/${order}?token=${token}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get client orders');
      });
  };

  getClientOrders = clientId => {
    return axios
      .get(`/order/?client_id=${clientId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get client orders');
      });
  };

  createProductOrder = data => {
    return axios
      .post('/product_order/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to add the product order');
      });
  };

  updateProductOrder = (id, data) => {
    return axios
      .patch(`/product_order/${id}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to update the product order');
      });
  };

  deleteProductOrder = id => {
    return axios
      .delete(`/product_order/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete the product order');
      });
  };

  getOrder = (orderId, filter = "") => {
    return axios
      .get(`/order/${orderId}/${filter}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get order');
      });
  };

  getOrderSupplierItens = (page, filter = "") => {
    return axios
      .get(`/product_order/?page=${page}${filter}&order_by=asc`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get order');
      });
  };

  getExcel = orderId => {
    return axios
    .get(`/order/?excel_order_id=${orderId}`, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res.data, `Order - ${orderId}.zip`);
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get order');
    });
  }

  getRefreshExcel = orderId => {
    return axios
    .get(`/order/?zip_order_id=${orderId}&zip_delete=true`, {
      responseType: 'blob',
    })
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get order');
    });
  }

  getZipPsdDownload = orderId => {
    return axios
    .get(`/order/?zip_order_id=${orderId}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to get order');
    });
  }

  createSale = data => {
    return axios
      .post(`/sale/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create sale');
      });
  };

  createExclusivity = data => {
    return axios
      .post('/exclusivity_print/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create exclusivity');
      });
  };
  getServices = () => {
    return axios
    .get(`/service/`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      throw new Error('Failed to retrieve services');
    });
  };
  getExclusivity = printId => {
    return axios
      .get(`/exclusivity_print/?print_id=${printId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create exclusivity');
      });
  };

  deleteExclusivity = id => {
    return axios
      .delete(`/exclusivity_print/${id}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to delete exclusivity');
      });
  };

  createExclusivity = data => {
    return axios
      .post('/exclusivity_print/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to create exclusivity');
      });
  };

  search = (query, config) => {
    return axios
      .get(`/library/?search=${query}`, { ...config })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  };

  getNotifications = () => {
    return axios
      .get('/notification/')
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err, 'Failed to get notifications');
      });
  };

  viewAllNotifications = notification => {
    return axios
      .patch(`/notification/${notification.id}/`, { view_all: true })
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to view all feedback');
      });
  };

  createCustomization = data => {
    return axios
      .post('/customization/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to save customization');
      });
  };

  getCustomization = id => {
    return axios
      .get(`/customization/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get customization');
      });
  };

  getCustomizationByPrintId = id => {
    return axios
      .get(`/customization/?print=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get customization by print id');
      });
  };

  psdFileSuccess = data => {
    return axios
      .post('/psd_file_success/', data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to set psd file success');
      });
  };

  psdFileSuccessUpdate = (id, data) => {
    return axios
      .patch(`/psd_file_success/${id}`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to set psd file success');
      });
  };

  getSellerOrders = () => {
    return axios
      .get(`/order/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get seller orders');
      });
  };

  getFilteredSellersOrders = (page, filter) => {
    return axios
      .get(`/order/?page=${page}${filter}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get filtered seller orders');
      });
  };

  getResetPassword = email => {
    return axios
      .get(`/reset_password/?email=${email}`)
      .then(res => {
        return res;
      })
      .catch(() => {
        throw new Error('Failed to get reset password');
      });
  };

  postResetPassword = data => {
    return axios
      .post('/update_password/', data)
      .then(res => {
        return res;
      })
      .catch(() => {
        throw new Error('Failed to reset password');
      });
  };

  getClientProfile = () => {
    return axios
      .get(`/client_profile`)
      .then(res => {
        return res;
      })
      .catch(() => {
        throw new Error('Failed to get client profile');
      });
  };

  searchClient = (page, query, isAscending) => {
    return axios
      .get(
        `/client/?page=${page}&order=date&order_by=${
          isAscending ? 'asc' : 'des'
        }${query && query.length ? `&search=${query}` : ''}`
      )
      .then(res => res.data)
      .catch(err => {
        console.error(err);

        return false;
      });
  };

  clientSendInvite = data => {
    return axios
      .post('/client_register_token/', data)
      .then(res => {
        return res;
      })
      .catch(() => {
        throw new Error('Failed to send invite to client');
      });
  };

  getPrintBriefings = id => {
    return axios
      .get(`/briefing/?mini=true&print=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get print briefings');
      });
  };

  getAllBriefings = () => {
    return axios
      .get('/briefing/?mini=true')
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get all briefings');
      });
  };

  addBriefingPrints = data => {
    return axios
      .post(`/add_print_to_briefings/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed add print briefings');
      });
  };

  printToMeeting = data => {
    return axios
      .post(`/print_to_meeting/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed add prints to meeting');
      });
  };

  briefingToMeeting = data => {
    return axios
      .post(`/briefing_to_meeting/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed add briefings to meeting');
      });
  };

  getAllPrintBriefings = printId => {
    return axios
      .get(`/briefing/?print=${printId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get print briefings');
      });
  };

  getPrintDoll = printId => {
    return axios
      .get(`/dolly/?print=${printId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get print doll');
      });
  };

  createPantone = data => {
    return axios
      .post('/print/', data)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to create pantone');
      });
  };

  getPrintOrders = printId => {
    return axios
      .get(`/order/?print=${printId}&full=true`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get print orders');
      });
  };

  deleteHistoricPrint = (printId, data) => {
    return axios
      .patch(`/print/${printId}/`, data)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get print orders');
      });
  };

  getFavoritePrints = (filters) => {
    return axios
      .get(`/favorite_prints/${filters}`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get favorite prints');
      });
  };

  getFavoritePresentations = () => {
    return axios
      .get(`/favorite_meetings/`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get favorite presentations');
      });
  };

  staffFavoritePrint = (print, staff) => {
    return axios
      .post('/favorite/', {
        print,
        staff
      })
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to favorite print');
      });
  };

  removeFavoritePrint = printId => {
    return axios
      .get(`/remove_favorite/?print=${printId}`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to remove favorite print');
      });
  };

  getFavoritePrints = (filters) => {
    return axios
      .get(`/favorite_prints/${filters}`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get favorite prints');
      });
  };

  staffFavoritePresentation = (presentation, staff) => {
    return axios
      .post('/favorite/', {
        meeting: presentation,
        staff
      })
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to favorite presentation');
      });
  };

  favoriteSelectedPrint = (selectedPrintId, meetingId, clientId) => {
    return axios
      .post('/favorite/', {
        meeting: meetingId,
        client: clientId,
        selected_print: selectedPrintId
      })
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to favorite presentation');
      });
  };

  removeFavoritePresentation = presentationId => {
    return axios
      .get(`/remove_favorite/?meeting=${presentationId}`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to remove favorite presentation');
      });
  };

  getFavoriteAttendances = () => {
    return axios
      .get(`/favorite_meeting_boxes/`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get favorite attendances');
      });
  };

  staffFavoriteAttendance = (attendanceId, staff) => {
    return axios
      .post('/favorite/', {
        meeting_box: attendanceId,
        staff
      })
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to favorite attendance');
      });
  };

  removeFavoriteAttendance = attendanceId => {
    return axios
      .get(`/remove_favorite/?meeting_box=${attendanceId}`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to remove favorite attendance');
      });
  };

  getOtherIdeas = presentationId => {
    return axios
      .get(`/idea/?meeting=${presentationId}`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get other ideas');
      });
  };

  getAttendanceClients = attendanceId => {
    return axios
      .get(`/meeting_token/?meeting_box=${attendanceId}&shared_with=true`)
      .then(res => res.data)
      .catch(() => {
        throw new Error('Failed to get attendance clients');
      });
  };

  getUnreadMessages = () => {
    return axios
      .get(`/chat_list/`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get unread messages');
      });
  };

  getChat = clientId => {
    return axios
      .get(`/chat/?client=${clientId}`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get chat');
      });
  };

  sendMessage = (text, client, staff) => {
    return axios
      .post('/chat/', {
        sent_by_client: false,
        text,
        client,
        staff
      })
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to get chat');
      });
  };

  readMessage = (messageId, data) => {
    return axios
      .patch(`/chat/${messageId}/`, data)
      .then(res => {
        return res.data;
      })
      .catch(() => {
        throw new Error('Failed to read feedback');
      });
  };
}
export default new Api();
