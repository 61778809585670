/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Api from '../libs/Api';
import translate from '../libs/i18n';

import styles from './MyPrints.module.scss';

import PrintGridItem from './PrintGridItem';
import CheckInput from './CheckInput';
import Loading from './Loading';

function MyPrints() {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const [prints, setPrints] = useState([]);
  const [myWork, setMyWork] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState();
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [updatedPage, setUpdatedPage] = useState();

  useEffect(() => {
    setLoading(true);
    Api.getMyPrints(`?page=${page}${myWork ? '&just_mine=true' : ''}`)
      .then(myPrints => {
        setCounter(myPrints.count);

        const printsPerPage = 30;
        let pagesAmount = parseInt(myPrints.count / printsPerPage, 10);

        if (myPrints.count % printsPerPage) {
          pagesAmount += 1;
        }

        setLastPage(pagesAmount);

        if (myPrints && myPrints.results) {
          const myPrintsApp = myPrints.results.filter(p => p.status === 'APP');
          setPrints(myPrintsApp);
        }
        Api.getFavoritePrints().then(favoritePrints => {
          const favoritedPrints = myPrints.results.map(p =>
            favoritePrints.includes(p.id)
              ? { ...p, isFavorite: true }
              : { ...p, isFavorite: false }
          );
          setPrints(favoritedPrints);
        });
      })
      .catch(() => {
        toast(translate('failGettingMyPrints', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language, myWork, page]);

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  const favoritePrint = printId => {
    const updatedPrints = [...prints];
    const printIndex = updatedPrints.findIndex(up => up.id === printId);
    updatedPrints[printIndex].isFavorite = true;
    setPrints(updatedPrints);

    Api.staffFavoritePrint(printId, user.id)
      .then(() => {
        toast(translate('successFavoritingPrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorFavoritingPrint', language), {
          type: 'error'
        });
        updatedPrints[printIndex].isFavorite = false;
        setPrints(updatedPrints);
      });
  };

  const removeFavoritePrint = printId => {
    const updatedPrints = [...prints];
    const printIndex = updatedPrints.findIndex(up => up.id === printId);
    updatedPrints[printIndex].isFavorite = false;
    setPrints(updatedPrints);

    Api.removeFavoritePrint(printId)
      .then(() => {
        toast(translate('successRemovingFavoritePrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegPrint', language), {
          type: 'error'
        });
        updatedPrints[printIndex].isFavorite = true;
        setPrints(updatedPrints);
      });
  };

  return (
    <>
      <div className={styles.filter}>
        <CheckInput
          text={translate('myWork', language)}
          value={myWork}
          onChange={setMyWork}
        />
      </div>
      <div className={styles.printsGrid}>
        {prints &&
          prints.map(print => (
            <PrintGridItem
              key={`${print.id}-${print.code}`}
              id={print.id}
              image={print.image}
              code={print.code}
              tags={print.tags}
              name={print.name}
              backgroundColor={
                print.flat_background && print.flat_background_color
                  ? print.flat_background_color
                  : false
              }
              pantone={print.is_pantone ? print.pantone_color : false}
              customizableBackground={print?.flat_background}
              newDesignPending={print?.new_design_pending}
              favorite={
                print?.isFavorite
                  ? () => removeFavoritePrint(print.id)
                  : () => favoritePrint(print.id)
              }
              isFavorite={print?.isFavorite}
            />
          ))}
      </div>
      {counter && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
      {loading && <Loading />}
    </>
  );
}

export default MyPrints;
