const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case '@clients/SET_CLIENTS':
      return [...action.clients];
    default:
      return state;
  }
};

export default reducer;
