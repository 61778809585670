import React, { useState, useEffect, useRef } from 'react';
// import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import { useHistory } from "react-router-dom";
import Api from '../../libs/Api';
import SearchIcon from '../../assets/icons/Search';

function Qualities() {
    const loadingMoreOrders = false;
    const history = useHistory();
    const language = useSelector(state => state.settings.language);
    const [qualities, setQualities] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredQualities = qualities?.filter(quality => quality?.name?.toLocaleLowerCase()?.includes(searchQuery?.toLocaleLowerCase()))
    const getInitialData = async () => {
        try {
            const response = await Api.getFabrics();
            setQualities(response.results);
        } catch {

        }
        
    }
    useEffect(() => {
        getInitialData()
    }, [])
    return (
        <>
        <div className={styles.titleSection}>
            <div className={styles.flexContainer}>
                <h1>{translate("qualities", language)}</h1>
                <div className={styles.searchInputContainer}>
                    <SearchIcon color="#a3b3c7" />
                    <input
                        type="search"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.currentTarget.value)}
                        className={`${styles.searchInput} ${styles.search}`}
                        placeholder={translate('search', language)}
                    />
                </div>
            </div>
            <button 
                className={styles.defaultRoundedActionButton}
                onClick={() => history.push('/register-fabric')}
            >
                {translate("newQuality", language)}
            </button>
        </div>
        <div className={styles.myOrders}>
            {qualities && qualities.length ? (
            filteredQualities.map((quality, index) => {
                const qualityKey = `quality-${quality.id}-${index}`;
                return (
                    <div key={qualityKey} className={styles.orderItem}>
                        <div className={styles.clientName}>
                            {quality.name}
                        </div>
                        <div className={styles.actionSection}>
                            <button
                                type="button"
                                className={styles.seeDetails}
                                onClick={() => history.push(`quality/${quality.id}`)}
                            >
                                <span>{translate('seeDetails', language)}</span>
                            </button>
                        </div>
                    </div>
                );
            })
            ) : (
            <>
                {!loadingMoreOrders && (
                <div className={styles.noOrders}>
                    {translate('noOrders', language)}
                </div>
                )}
            </>
            )}
        </div>
        {loadingMoreOrders && <Loading line />}
        </>
    );
}

export default Qualities;