import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';

import styles from './PresentationPrints.module.scss';
import Api from '../libs/Api';
import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';
import { addProductsOnCart } from '../store/actions/carts';

import PlayIcon from '../assets/icons/Play';
import TrashCanIcon from '../assets/icons/TrashCan';
import CheckInput from './CheckInput';
import PrintGridItem from './PrintGridItem';
import TagsSelectors from './TagsSelectors';
import FormModal from './FormModal';
import ListSelector from './ListSelector';
import EditIcon from '../assets/icons/Edit';
import Loading from './Loading';
import ClientPurchasePrint from './ClientPurchasePrint';
import SearchIcon from '../assets/icons/Search';
import CloseIcon from '../assets/icons/Close';
import { ChangePositionModal } from './ChangePositionModal';

function PresentationPrints({
  presentation,
  playSlider,
  tags,
  fabrics,
  dolls,
  clients
}) {
  const language = useSelector(state => state.settings.language);
  const clientCarts = useSelector(state => state.carts);
  const dispatch = useDispatch();

  const [checkedPrints, setCheckedPrints] = useState([]);
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [editingDescription, setEditingDescription] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedArtFinalists] = useState([]);
  const [selectedProveniences] = useState([]);
  const [seeColorVariations, setSeeColorVariations] = useState(true);
  const [editingPrintFabrics, setEditingPrintFabrics] = useState(false);
  const [selectedFabrics, setSelectedFabrics] = useState([]);
  const [fabricsSelector, setFabricsSelector] = useState('closed');
  const [purchasingPrintId, setPurchasingPrintId] = useState();
  const [purchasingPrints, setPurchasingPrints] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [exclusivePrints, setExclusivePrints] = useState([]);
  const [loadingExclusivity, setLoadingExclusivity] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [clientsSelector, setClientsSelector] = useState(false);
  const [dragOrigin, setDragOrigin] = useState();
  const [dragDestiny, setDragDestiny] = useState();
  const [nonDuplicatedPrints, setNonDuplicatedPrints] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isChangePositionModalOpen, setIsChangePositionModalOpen] = useState(false);

  const clientSelectorRef = useRef();
  const [loading, setLoading] = useState(false);
  const fabricsSelectorRef = useRef();
  const locationInputRef = useRef();
  const pantones = nonDuplicatedPrints.filter(ndp => ndp.is_pantone === true);
  useOutsideClick(fabricsSelectorRef, () => {
    if (fabricsSelector === 'opened') {
      setFabricsSelector('closed');
    }
  });

  const closeEditingPrintFabrics = () => {
    setEditingPrintFabrics(false);
    setSelectedFabrics([]);
  };

  useOutsideClick(locationInputRef, () => {
    setSearchQuery('');
  });

  useEffect(() => {
    setClientsSelector(false);
  }, [selectedClient]);

  useOutsideClick(clientSelectorRef, () => {
    setClientsSelector(false);
  });

  useEffect(() => {
    (async function onLoad() {
      setLoading(true);
      const getRegions = Api.getRegions();
      const getCountries = Api.getCountries();

      axios
        .all([getRegions, getCountries])
        .then(([returnedRegions, returnedCountries]) => {
          const formattedRegions = returnedRegions.map(reg => ({
            ...reg,
            type: 'regs'
          }));
          const formattedCountries = returnedCountries.map(cou => ({
            ...cou,
            type: 'countries'
          }));

          setRegions(formattedRegions);
          setCountries(formattedCountries);

          if (presentation.client) {
            setSelectedClient({
              id: presentation.client,
              name: presentation.client_name
            });
          }
          if (
            presentation.meeting_location &&
            presentation.meeting_location.length &&
            formattedCountries.length &&
            formattedRegions.length
          ) {
            const initialLocations = [];
            presentation.meeting_location.forEach(value => {
              const country = formattedCountries.find(c => c.id === value);
              const region = formattedRegions.find(r => r.id === value);

              if (country) {
                initialLocations.push({ ...country, type: 'countries' });
              }
              if (region) {
                initialLocations.push({ ...region, type: 'regs' });
              }
            });
            setSelectedLocations(initialLocations);
          }
          setLoaded(true);
        });
    })();
  }, [presentation]);

  const updatePrints = useCallback(() => {
    setLoading(true);
    setLoadingExclusivity(true);
    let regs;
    let count;
    let regQuery;
    let countriesQuery;
    if (selectedLocations.length) {
      regs = selectedLocations.filter(s => s.type === 'regs');
      count = selectedLocations.filter(s => s.type === 'countries');
      countriesQuery = count.map(l => `${l.id}`);
      regQuery = regs.map(l => `${l.id}`);
    }

    Api.getExclusiveMeetingPrints(
      presentation.id,
      regQuery,
      countriesQuery,
      selectedClient ? selectedClient.id : presentation.client
    )
      .then(res => {
        setExclusivePrints(res);
      })
      .finally(() => {
        setLoadingExclusivity(false);
        setLoading(false);
      });
  }, [presentation, selectedClient, selectedLocations]);

  useEffect(() => {
    if (loaded) {
      updatePrints();
    }
  }, [selectedLocations, selectedClient, presentation, loaded, updatePrints]);

  const getFilteredLocations = () => {
    if (searchQuery) {
      const filteredRegions = regions.filter(r =>
        r.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const filteredCountries = countries.filter(c =>
        c.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      return [...filteredRegions, ...filteredCountries];
    }

    return [];
  };

  const addLocation = location => {
    const updatedLocations = [...selectedLocations];
    if (!updatedLocations.find(l => l.id === location.id)) {
      updatedLocations.push(location);
      setSelectedLocations(updatedLocations);
    }
  };

  const removeLocation = location => {
    const updatedLocations = selectedLocations.filter(
      l => l.id !== location.id
    );

    setSelectedLocations(updatedLocations);
  };

  const filteredPrints = useMemo(() => {
    let finalPrints = [...exclusivePrints];
    selectedTags.forEach(tag => {
      finalPrints = finalPrints.filter(print => {
        return print.tag_ids.some(t => t === tag.id);
      });
    });
    selectedArtFinalists.forEach(artFinalist => {
      finalPrints = finalPrints.filter(
        print => print.art_finisher_id === artFinalist.id
      );
    });
    selectedProveniences.forEach(provenience => {
      finalPrints = finalPrints.filter(
        print => print.provenience === provenience.value
      );
    });

    if (!seeColorVariations) {
      const updatedFinalPrints = [];
      finalPrints.forEach(p => {
        if (p.print_is_origin) {
          updatedFinalPrints.push(p);
        } else if (
          !finalPrints.find(
            fP =>
              fP.print_code.split(' ')[0] === p.print_code.split(' ')[0] &&
              p.print_code.split(' ')[1] &&
              ((fP.print_code.split(' ')[1] &&
                fP.print_code.split(' ')[1] < p.print_code.split(' ')[1]) ||
                fP.print_is_origin)
          )
        ) {
          updatedFinalPrints.push(p);
        }
      });

      finalPrints = [...updatedFinalPrints];
    }

    return finalPrints;
  }, [
    exclusivePrints,
    seeColorVariations,
    selectedArtFinalists,
    selectedProveniences,
    selectedTags
  ]);

  useEffect(() => {
    const printCards = [];
    filteredPrints.forEach(p => {
      if (!printCards.find(pc => pc.print_id === p.print_id)) {
        printCards.push(p);
      }
    });

    if (printCards.length) {
      printCards.sort((a, b) => a.position - b.position);
    }

    setNonDuplicatedPrints(printCards);
  }, [filteredPrints]);

  const getDolly = useMemo(() => {
    return {
      dolly_id: presentation.dolly_id,
      image: presentation.dolly,
      shadow: presentation.dolly_shadow,
      rapport_propor: presentation.rapport_propor,
      dolly_masks: presentation.dolly_masks,
      is_new_doll:
        presentation.dolly_masks &&
        presentation.dolly_masks.length &&
        presentation.dolly_masks.length > 0
    };
  }, [presentation]);

  const renderedGrid = () => {
    const openEditingPrintFabrics = id => {
      const foundPrint = exclusivePrints.filter(p => p.print_id === id);
      if (foundPrint[0]) {
        const print = foundPrint[0];
        setEditingPrintFabrics(print.print_id);
        const initialFabrics = [];
        const differentFabricsPrint = exclusivePrints.filter(
          p => p.print_id === id
        );
        differentFabricsPrint.forEach(dfp => {
          initialFabrics.push({
            id: dfp.fabric_id,
            name: dfp.fabric_name,
            selected_print: dfp.selected_print_id,
            product_id: dfp.product_id
          });
        });
        setSelectedFabrics(initialFabrics);
      }
    };
    const changeCheckedPrint = id => {
      let updatedCheckedImages;
      if (!checkedPrints.includes(id)) {
        updatedCheckedImages = [...checkedPrints, id];
      } else {
        updatedCheckedImages = checkedPrints.filter(
          selectedPrintId => selectedPrintId !== id
        );
      }
      setCheckedPrints(updatedCheckedImages);
    };
    const addToCart = selectedPrintId => {
      if (!selectedClient) {
        toast(translate('selectedClientRequired', language), {
          type: 'warning'
        });
      } else {
        const products = exclusivePrints.filter(
          p => p.print_id === selectedPrintId
        );
        products.forEach((prod, index) => {
          products[index] = {
            ...prod,
            client: selectedClient.id,
            customer_meeting: presentation.id,
            seller: presentation.created_by,
            action: null,
            meters: '',
            product: prod.product_id,
          };
        });
        dispatch(addProductsOnCart(products));
        toast(translate('printAddedToCart', language), {
          type: 'success'
        });
      }
    };

    const onDragStart = (e, index) => {
      setDragOrigin(index);
    };

    const onDragOver = (e, index) => {
      if (index !== setDragDestiny) {
        setDragDestiny(index);
      }
    };

    const onDragEnd = () => {
      setLoading(true);

      const updatedDraggablePrints = [...nonDuplicatedPrints];
      const draggadedPrint = updatedDraggablePrints[dragOrigin];
      updatedDraggablePrints[dragOrigin] = updatedDraggablePrints[dragDestiny];
      updatedDraggablePrints[dragDestiny] = draggadedPrint;

      Api.updatePrintPosition(updatedDraggablePrints[dragOrigin].id, {
        position: updatedDraggablePrints[dragDestiny].position
      })
        .then(response => {
          const elementIndex = updatedDraggablePrints.findIndex(
            element => element.id === updatedDraggablePrints[dragOrigin].id
          );

          updatedDraggablePrints[elementIndex] = response;
        })
        .catch()
        .finally(() => {
          setLoading(false);
        });
      Api.updatePrintPosition(updatedDraggablePrints[dragDestiny].id, {
        position: updatedDraggablePrints[dragOrigin].position
      })
        .then(response => {
          const elementIndex = updatedDraggablePrints.findIndex(
            element => element.id === updatedDraggablePrints[dragDestiny].id
          );

          updatedDraggablePrints[elementIndex] = response;
        })
        .catch()
        .finally(() => {
          setLoading(false);
        });

      setNonDuplicatedPrints(updatedDraggablePrints);
      setDragOrigin();
      setDragDestiny();
    };

    const selectLocationAlert = () => {
      toast(translate('selectLocation', language), {
        type: 'warning'
      });
    };

    const renderCard = (print, index) => {
      return (
        <div
          key={`${print.id}`}
          className={`${styles.printCard} ${index === dragDestiny ? styles.dragOver : ''}`}
          draggable
          onDragStart={e => onDragStart(e, index)}
          onDragOver={e => onDragOver(e, index)}
          onDragEnd={e => onDragEnd(e, index)}
        >
          <div className={styles.checkInputArea}>
            <CheckInput
              card
              value={checkedPrints.includes(print.id)}
              onChange={() => changeCheckedPrint(print.id)}
              id={print.id}
            />
          </div>
          <button
            type="button"
            className={styles.editPrint}
            onClick={() => openEditingPrintFabrics(print.print_id)}
          >
            <EditIcon />
          </button>
          <PrintGridItem 
            isFavorite={print.isFavorite}
            id={print.print_id}
            image={print.print_image_url || print.image_url}
            code={print.print_code || print.code}
            tags={print.tags}
            name={print.print_name}
            type={print.print_type}
            printJpegWidth={print.print_jpeg_width}
            printJpegHeight={print.print_jpeg_height}
            purchaseSelect={
              selectedLocations.length
                ? () => setPurchasingPrintId(print.print_id)
                : selectLocationAlert
            }
            addToCart={
              selectedLocations.length ? addToCart : selectLocationAlert
            }
            doll={getDolly}
            printApplication={print.print_application}
            backgroundColor={
              print.flat_background && print.flat_background_color
                ? print.flat_background_color
                : false
            }
            pantone={print.is_pantone ? print.pantone_color : false}
            newDesignPending={print.new_design_pending}
          />
        </div>
      );
    };

    return (
      <div className={styles.printsGrid}>
        <div>
          {nonDuplicatedPrints.map((print, index) => {
            if (index % 4 === 0) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
        <div>
          {nonDuplicatedPrints.map((print, index) => {
            if (index % 4 === 1) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
        <div>
          {nonDuplicatedPrints.map((print, index) => {
            if (index % 4 === 2) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
        <div>
          {nonDuplicatedPrints.map((print, index) => {
            if (index % 4 === 3) {
              return renderCard(print, index);
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  const finishEditingText = () => {
    setEditingTitle(false);
    setEditingDescription(false);
    const formData = new FormData();
    if (updatedTitle && updatedTitle !== presentation.name) {
      formData.append('name', updatedTitle);
      Api.updatePresentation(presentation.id, formData)
        .then()
        .catch(() => {
          setUpdatedTitle('');
        });
    }
    if (
      updatedDescription &&
      updatedDescription !== presentation.meeting_briefing
    ) {
      formData.append('meeting_briefing', updatedDescription);
      Api.updatePresentation(presentation.id, formData)
        .then()
        .catch(() => {
          setUpdatedDescription('');
        });
    }
  };

  const deleteSelectedPrints = () => {
    setLoading(true);
    const deleteRequests = [];
    const selectedPrints = exclusivePrints.filter(print =>
      checkedPrints.includes(print.id)
    );
    selectedPrints.forEach(selectedPrint => {
      exclusivePrints.forEach(print => {
        if (selectedPrint.print_id === print.print_id) {
          const deleteRequest = Api.deleteSelectedProduct(
            presentation.id,
            print.id
          );
          deleteRequests.push(deleteRequest);
        }
      });
    });
    axios
      .all(deleteRequests)
      .then(() => {
        updatePrints();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const changeSelectedPrints = () => {
    setLoading(true);
    const printsWithFabric = exclusivePrints.filter(
      p => p.print_id === editingPrintFabrics
    );
    const addedSelectedFabrics = [];
    const removedSelectedPrints = [];

    printsWithFabric.forEach(pwf => {
      if (!selectedFabrics.find(sf => sf.id === pwf.fabric_id)) {
        removedSelectedPrints.push(pwf.id);
      }
    });

    selectedFabrics.forEach(fabric => {
      if (!printsWithFabric.some(pwf => pwf.fabric_id === fabric.id)) {
        addedSelectedFabrics.push(fabric.id);
      }
    });

    const removeRequests = [];
    removedSelectedPrints.forEach(p => {
      const request = Api.deleteSelectedProduct(presentation.id, p);
      removeRequests.push(request);
    });

    const addRequests = [];
    addedSelectedFabrics.forEach(f => {
      const formData = new FormData();
      formData.append('fabric', f);
      formData.append('print', editingPrintFabrics);
      const request = Api.createProduct(formData).then(async product => {
        await Api.addSelectedPrintToPresentation(presentation.id, {
          product_id: product.id
        });
      });
      addRequests.push(request);
    });

    axios
      .all(addRequests, removeRequests)
      .then(() => {
        updatePrints();
      })
      .finally(() => {
        setLoading(false);
        closeEditingPrintFabrics();
      });
  };

  useEffect(() => {
    if (!selectedClient && purchasingPrintId) {
      toast(translate('selectedClientRequired', language), {
        type: 'warning'
      });
      setPurchasingPrintId();
    }
  }, [purchasingPrintId, selectedClient, language]);

  useEffect(() => {
    if (purchasingPrintId) {
      const initialFabrics = [];
      const differentFabricsPrint = exclusivePrints.filter(
        print => print.print_id === purchasingPrintId
      );
      differentFabricsPrint.forEach(dfp => {
        initialFabrics.push({
          id: dfp.fabric_id,
          name: dfp.fabric_name,
          selectedPrint: dfp.print_id
        });
      });
      setSelectedFabrics(initialFabrics);
    } else {
      setSelectedFabrics([]);
    }
  }, [exclusivePrints, purchasingPrintId]);

  useEffect(() => {
    if (purchasingPrintId) {
      const purchasing = [];

      exclusivePrints.forEach(p => {
        if (p.print_id === purchasingPrintId) {
          purchasing.push(p);
        }
      });

      const variations = [];

      exclusivePrints.forEach(p => {
        if (p.print_id !== purchasingPrintId) {
          if (
            (p.print_code &&
              p.print_code.includes(purchasing[0].print_code.split(' ')[0])) ||
            purchasing[0].print_code.includes(p.print_code)
          ) {
            variations.push(p);
          }
        }
      });
      setPurchasingPrints([...purchasing, ...variations]);
    }
  }, [exclusivePrints, purchasingPrintId]);

  const nextPrint = () => {
    const currentIndex = nonDuplicatedPrints.findIndex(
      print => print.print_id === purchasingPrintId
    );

    if (currentIndex === nonDuplicatedPrints.length - 1) {
      setPurchasingPrintId(nonDuplicatedPrints[0].print_id);
    } else {
      setPurchasingPrintId(nonDuplicatedPrints[currentIndex + 1].print_id);
    }
  };

  const previousPrint = () => {
    const currentIndex = nonDuplicatedPrints.findIndex(
      print => print.print_id === purchasingPrintId
    );

    if (currentIndex > 0) {
      setPurchasingPrintId(nonDuplicatedPrints[currentIndex - 1].print_id);
    } else {
      setPurchasingPrintId(
        nonDuplicatedPrints[nonDuplicatedPrints.length - 1].print_id
      );
    }
  };

  return (
    <>
      <div className={styles.presentationConcept}>
        <div className={styles.header}>
          <div className={styles.clientSelector} ref={clientSelectorRef}>
            <button
              id=""
              type="button"
              className={`${styles.defaultSelectInput} ${styles.selectInput}`}
              onClick={() => setClientsSelector('opened')}
            >
              {selectedClient
                ? selectedClient.name
                : translate('chooseClient', language)}
            </button>
            {clientsSelector === 'opened' && (
              <span>
                <ListSelector
                  label={translate('clients', language)}
                  selectLabel={translate('choose', language)}
                  items={clients}
                  onSelect={setSelectedClient}
                  value={selectedClient}
                  search
                />
              </span>
            )}
          </div>
          <div className={styles.checkArea}>
            <CheckInput
              text={translate('seeColorVariations', language)}
              value={seeColorVariations}
              onChange={setSeeColorVariations}
            />
          </div>
        </div>

        <div className={styles.header}>
          <div className={styles.actions}>
            <button
              className={styles.delete}
              type="button"
              onClick={deleteSelectedPrints}
              disabled={!checkedPrints.length}
            >
              <TrashCanIcon />
            </button>
            <div
              className={styles.exclusivityFilterContainer}
              ref={locationInputRef}
            >
              <div className={styles.inputContainer}>
                <SearchIcon color="#a3b3c7" />
                <input
                  type="search"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.currentTarget.value)}
                  className={styles.exclusivityFilter}
                />
              </div>

              <ul className={styles.selectedLocations}>
                {selectedLocations.map(location => (
                  <li key={location.value}>
                    {location.name}{' '}
                    <button
                      type="button"
                      onClick={() => removeLocation(location)}
                    >
                      <CloseIcon color="white" width="20" height="20" />
                    </button>
                  </li>
                ))}
              </ul>
              <ul className={styles.locations}>
                {getFilteredLocations().map(location => (
                  <li key={location.value}>
                    <button type="button" onClick={() => addLocation(location)}>
                      {location.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={styles.tagsArea}>
            <TagsSelectors
              tags={tags}
              selectLabel={translate('add', language)}
              selectTags={setSelectedTags}
            />
          </div>
        </div>
        <div className={styles.mainInfo}>
          {editingTitle ? (
            <textarea
              type="text"
              value={updatedTitle || presentation.name}
              onChange={e => setUpdatedTitle(e.currentTarget.value)}
              onBlur={finishEditingText}
              className={styles.title}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          ) : (
            <button
              type="button"
              onClick={() => {
                setEditingTitle(true);
                setEditingDescription(false);
              }}
            >
              <h1>{updatedTitle || presentation.name}</h1>
            </button>
          )}
          {editingDescription ? (
            <textarea
              type="text"
              value={updatedDescription || presentation.meeting_briefing}
              onChange={e => setUpdatedDescription(e.currentTarget.value)}
              onBlur={finishEditingText}
              className={styles.description}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          ) : (
            <button
              type="button"
              onClick={() => {
                setEditingTitle(false);
                setEditingDescription(true);
              }}
            >
              <p>{updatedDescription || presentation.meeting_briefing}</p>
            </button>
          )}
        </div>
        
        <button className={styles.play} type="button" onClick={playSlider}>
          <PlayIcon />
          Play
        </button>
        <button className={styles.play} type="button" onClick={() => setIsChangePositionModalOpen(!isChangePositionModalOpen)}>
          {translate("changePositionText", language)}
        </button>
        {loadingExclusivity ? <Loading line /> : renderedGrid()}
      </div>
      {loading && <Loading fixed />}
      {editingPrintFabrics && (
        <FormModal
          title={translate('edit', language)}
          onCancel={closeEditingPrintFabrics}
        >
          <div className={styles.editingProductModal}>
            <div className={styles.content} ref={fabricsSelectorRef}>
              <button
                type="button"
                className={styles.defaultSelectInput}
                onClick={() => setFabricsSelector('opened')}
              >
                {selectedFabrics && selectedFabrics.length
                  ? selectedFabrics.map(fabric =>
                      fabric.name ? (
                        <span className={styles.fabric}>{fabric.name}</span>
                      ) : (
                        ''
                      )
                    )
                  : translate('addFabrics', language)}
              </button>
              {fabricsSelector === 'opened' && (
                <span className={styles.listContainer}>
                  <ListSelector
                    label={translate('applyFixedFabric', language)}
                    selectLabel={translate('choose', language)}
                    items={fabrics}
                    onSelect={setSelectedFabrics}
                    values={selectedFabrics}
                    multiple
                    search
                  />
                </span>
              )}
            </div>
            <span className={styles.separator} />
            <div className={styles.actionButtons}>
              <button
                type="button"
                className={styles.defaultActionButton}
                onClick={changeSelectedPrints}
              >
                {translate('save', language)}
              </button>
            </div>
          </div>
        </FormModal>
      )}
      {purchasingPrintId && selectedClient && (
        <ClientPurchasePrint
          onClose={() => setPurchasingPrintId()}
          prints={purchasingPrints}
          pantones={pantones}
          presentation={presentation}
          dolls={dolls}
          selectedClient={selectedClient.id}
          nextPrint={nextPrint}
          previousPrint={previousPrint}
        />
      )}
      {isChangePositionModalOpen && (
        <ChangePositionModal 
          prints={nonDuplicatedPrints}
          onClose={() => setIsChangePositionModalOpen(!isChangePositionModalOpen)}
          attPrints={setNonDuplicatedPrints}
        />
      )}
    </>
  );
}

PresentationPrints.propTypes = {
  presentation: PropTypes.shape().isRequired,
  playSlider: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape),
  fabrics: PropTypes.arrayOf(PropTypes.shape),
  dolls: PropTypes.arrayOf(PropTypes.shape).isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape)
};

PresentationPrints.defaultProps = {
  tags: [],
  fabrics: [],
  clients: []
};

export default PresentationPrints;