import React, { useState } from 'react';
import FileSelector from '../../components/FileSelector';
import { uploadNewFile } from '../../libs/s3Upload';

export function UploadTest() {
    const [jpg, setJpg] = useState([]);

    async function handleSubmit() {
            try {
                await uploadNewFile(
                `${'L'}.${jpg[0].name.split('.')[1]}`,
                    jpg[0],
                );
    } catch(err) {

    }
}
        return(
            <form action="" onSubmit={(e) => {
                e.preventDefault();
                handleSubmit()
            }}>
                <FileSelector 
                files={jpg}
                onSelect={setJpg}
                fullWidth
                />

            <button type='submit'> ENVIAR </button>
            </form>
        )
    }
