import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './Search.module.scss';
import Api from '../libs/Api';
import translate from '../libs/i18n';

import PrintGridItem from '../components/PrintGridItem';
import Loading from '../components/Loading';

function Search() {
  const language = useSelector(state => state.settings.language);

  const params = useParams();

  const [foundPrint, setFoundPrint] = useState();
  const [chromaticVariations, setChromaticVariations] = useState([]);
  const [twins, setTwins] = useState([]);
  const [originPrint, setOriginPrint] = useState();
  const [presentations, setPresentations] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function loadData() {
      setLoading(true);
      try {
        const getPrint = await Api.getPrint(params.id);
        if (getPrint.id) {
          setFoundPrint(getPrint);
          if (getPrint.is_origin) {
            let getVariations = [];
            let getChromaticVariations = [];
            let getTwins = [];

            try {
              getVariations = await Api.getChromaticVariations(getPrint.id);
              getChromaticVariations = getVariations.filter(
                variation => !variation.is_twin
              );
              getTwins = getVariations.filter(variation => variation.is_twin);
            } finally {
              setChromaticVariations(getChromaticVariations);
              setTwins(getTwins);
            }
          } else if (getPrint.origin_id) {
            let getOriginPrint;
            try {
              getOriginPrint = await Api.getPrint(getPrint.origin_id);
            } finally {
              setOriginPrint(getOriginPrint);
            }
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setLoading(false);
      }
      Api.getPresentationsMini()
        .then(res => {
          setPresentations(res);
        })
        .catch();
      Api.getFabrics()
        .then(res => {
          setFabrics(res.results);
        })
        .catch();
    })();
  }, [params.id]);

  const getSection = (prints, sectionName) => (
    <>
      <h2 className={styles.sectionTitle}>
        {translate(sectionName, language)}
      </h2>
      <div className={styles.printsGrid}>
        <div>
          {prints.map((print, index) => {
            if (index % 4 === 0) {
              return (
                <PrintGridItem
                  key={`${print.id}-${print.code}`}
                  id={print.id}
                  image={print.image_url}
                  code={print.code}
                  tags={print.tags}
                  name={print.name}
                  presentations={presentations}
                  fabrics={fabrics}
                  backgroundColor={
                    print.flat_background && print.flat_background_color
                      ? print.flat_background_color
                      : false
                  }
                  exclusivity={print.has_exclusivity}
                  reserved={print.reserved}
                  type={print.type}
                  pantone={print.is_pantone ? print.pantone_color : false}
                  customizableBackground={print.flat_background}
                  newDesignPending={print.new_design_pending}
                />
              );
            }
            return null;
          })}
        </div>
        <div>
          {prints.map((print, index) => {
            if (index % 4 === 1) {
              return (
                <PrintGridItem
                  key={`${print.id}-${print.code}`}
                  id={print.id}
                  image={print.image_url}
                  code={print.code}
                  tags={print.tags}
                  name={print.name}
                  presentations={presentations}
                  fabrics={fabrics}
                  backgroundColor={
                    print.flat_background && print.flat_background_color
                      ? print.flat_background_color
                      : false
                  }
                  exclusivity={print.has_exclusivity}
                  reserved={print.reserved}
                  type={print.type}
                  pantone={print.is_pantone ? print.pantone_color : false}
                  newDesignPending={print.new_design_pending}
                />
              );
            }
            return null;
          })}
        </div>
        <div>
          {prints.map((print, index) => {
            if (index % 4 === 2) {
              return (
                <PrintGridItem
                  key={`${print.id}-${print.code}`}
                  id={print.id}
                  image={print.image_url}
                  code={print.code}
                  tags={print.tags}
                  name={print.name}
                  presentations={presentations}
                  fabrics={fabrics}
                  backgroundColor={
                    print.flat_background && print.flat_background_color
                      ? print.flat_background_color
                      : false
                  }
                  exclusivity={print.has_exclusivity}
                  reserved={print.reserved}
                  type={print.type}
                  pantone={print.is_pantone ? print.pantone_color : false}
                  newDesignPending={print.new_design_pending}
                />
              );
            }
            return null;
          })}
        </div>
        <div>
          {prints.map((print, index) => {
            if (index % 4 === 3) {
              return (
                <PrintGridItem
                  key={`${print.id}-${print.code}`}
                  id={print.id}
                  image={print.image_url}
                  code={print.code}
                  tags={print.tags}
                  name={print.name}
                  backgroundColor={
                    print.flat_background && print.flat_background_color
                      ? print.flat_background_color
                      : false
                  }
                  exclusivity={print.has_exclusivity}
                  reserved={print.reserved}
                  type={print.type}
                  pantone={print.is_pantone ? print.pantone_color : false}
                  newDesignPending={print.new_design_pending}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.search}>
      <div className={styles.header}>
        <h2 className={styles.pageTitle}>
          <span className={styles.title}>
            {translate('search', language).toUpperCase()}
          </span>
        </h2>
      </div>
      <div className={styles.content}>
        {foundPrint && (
          <>
            <h1 className={styles.printCode}>
              {foundPrint.code}
              {!foundPrint.is_origin && foundPrint.is_twin && (
                <h2>{translate('twin', language)}</h2>
              )}
              {!foundPrint.is_origin && !foundPrint.is_twin && (
                <h2>{translate('chromaticVariation', language)}</h2>
              )}
            </h1>
            <div className={styles.printsGrid}>
              <div>
                <PrintGridItem
                  key={`${foundPrint.id}-${foundPrint.code}`}
                  id={foundPrint.id}
                  image={foundPrint.image_url}
                  code={foundPrint.code}
                  tags={foundPrint.tags}
                  name={foundPrint.name}
                  presentations={presentations}
                  fabrics={fabrics}
                  backgroundColor={
                    foundPrint.flat_background &&
                    foundPrint.flat_background_color
                      ? foundPrint.flat_background_color
                      : false
                  }
                  exclusivity={foundPrint.has_exclusivity}
                  reserved={foundPrint.reserved}
                  type={foundPrint.type}
                  pantone={
                    foundPrint.is_pantone ? foundPrint.pantone_color : false
                  }
                  newDesignPending={foundPrint.new_design_pending}
                />
              </div>
            </div>
          </>
        )}
        {chromaticVariations.length > 0 &&
          getSection(chromaticVariations, 'chromaticVariations')}
        {twins.length > 0 && getSection(twins, 'twins')}
        {originPrint && getSection([originPrint], 'original')}
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Search;
