import S3 from 'react-aws-s3';


export const uploadNewFile = async (name, file, path) => {
    /*path EX. print/png */
    const config = {
        bucketName: `${process.env.REACT_APP_BUCKET_NAME}`,
        dirName: path,
        region: 'us-east-1',
        accessKeyId: `${process.env.REACT_APP_S3_ACESS_KEY}`,
        secretAccessKey: `${process.env.REACT_APP_S3_SECRET_ACESS_KEY}`,
        s3Url: `${process.env.REACT_APP_S3_URL}`,
    }
    
    const ReactS3Client = new S3(config);


    const data = await ReactS3Client.uploadFile(file, name);    
}