import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './FormBriefings.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';

import SearchIcon from '../assets/icons/Search';
import CheckInput from './CheckInput';

import Loading from './Loading';

function FormBriefings({ applyBriefings, currentBriefings }) {
  const language = useSelector(state => state.settings.language);

  const [selectedBriefings, setSelectedBriefings] = useState([]);
  const [briefings, setBriefings] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryTimeout, setSearchQueryTimeout] = useState(null);
  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [updatedPage, setUpdatedPage] = useState();
  const [counter, setCounter] = useState();
  const [filteredCounter, setFilteredCounter] = useState();

  useEffect(() => {
    setLoading(true);
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (filter) {
      setInitialLoad(true);
      setLoading(true);
      Api.getBriefings(`?${filter}&page=${page - 1}`)
        .then(res => {
          setFilteredCounter(res.count);
          setBriefings(res.result);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
        })
        .finally(() => {
          setLoading(false);
          setInitialLoad(false);
        });
    } else {
      setInitialLoad(true);
      Api.getBriefings(`?page=${page - 1}`)
        .then(res => {
          setBriefings(res.result);
          setCounter(res.count);
          let numberOfPages = parseInt(res.count / 30, 10);
          if (res.count % 30) {
            numberOfPages += 1;
          }
          setLastPage(numberOfPages);
        })
        .catch()
        .finally(() => {
          setLoading(false);
          setInitialLoad(false);
        });
    }
  }, [filter, page]);

  useEffect(() => {
    if (searchQueryTimeout) {
      clearTimeout(searchQueryTimeout);
    }

    let query = '';
    function concat(filters) {
      if (query) {
        query += `&${filters}`;
      } else {
        query += filters;
      }
    }

    if (searchQuery.length) {
      concat(`search=${searchQuery}`);
    }

    if (query === '?') {
      setFilter('');
    } else if (query !== '?' && searchQuery.length) {
      setSearchQueryTimeout(setTimeout(() => setFilter(query), 1000));
    } else {
      setFilter(query);
    }
  }, [searchQuery]);

  const onSelectBriefing = (briefing, selected) => {
    let currentSelectedBriefings = [...selectedBriefings];
    const containsBriefing = currentSelectedBriefings.find(
      b => b.id === briefing.id
    );
    if (!selected && containsBriefing) {
      currentSelectedBriefings = currentSelectedBriefings.filter(
        b => b.id !== briefing.id
      );
    } else if (selected && !containsBriefing) {
      currentSelectedBriefings = [...selectedBriefings, briefing];
    }
    setSelectedBriefings(currentSelectedBriefings);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  return (
    <div className={styles.briefings}>
      <h2>{translate('selectOneOrMoreBriefings', language)}</h2>
      <div className={styles.content}>
        <div className={styles.filters}>
          <div className={styles.left}>
            <div className={styles.inputContainer}>
              <SearchIcon color="#a3b3c7" />
              <input
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className={styles.exclusivityFilter}
                placeholder={translate('searchBriefing', language)}
              />
            </div>
          </div>
          <div className={styles.right}>
            <button
              type="button"
              className={styles.defaultActionButton}
              onClick={() => applyBriefings(selectedBriefings)}
            >
              {translate('apply', language)}
            </button>
          </div>
        </div>
        <div className={styles.briefingsGrid}>
          {briefings.map(briefing => {
            if (!currentBriefings.find(cb => cb.id === briefing.id)) {
              return (
                <div key={briefing.id} className={styles.briefingCard}>
                  <div className={styles.checkInput}>
                    <CheckInput
                      value={selectedBriefings.find(
                        sb => sb.id === briefing.id
                      )}
                      onChange={value => onSelectBriefing(briefing, value)}
                    />
                  </div>

                  {!!briefing.cover_url && (
                    <img src={briefing.cover_url} alt={briefing.text} />
                  )}
                  <div className={styles.info}>
                    {briefing.new && (
                      <span className={styles.new}>
                        {translate('new', language)}
                      </span>
                    )}
                    <span className={styles.label}>
                      {translate('briefing', language)}
                    </span>
                    <span className={styles.title}>{briefing.text}</span>
                    <span className={styles.client}>
                      {briefing.client_name}
                    </span>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
        {loading && <Loading fixed={!initialLoad} />}
      </div>
      {(counter || filteredCounter) && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
    </div>
  );
}

export default FormBriefings;

FormBriefings.propTypes = {
  applyBriefings: PropTypes.func.isRequired,
  currentBriefings: PropTypes.arrayOf(PropTypes.shape).isRequired
};
