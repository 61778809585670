import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';

import styles from './CreateCollectionForm.module.scss';
import translate from '../libs/i18n';
import { setClients } from '../store/actions/clients';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';

import ListSelector from './ListSelector';
import Loading from './Loading';

function CreateCollectionModal({ onSuccess }) {
  const language = useSelector(state => state.settings.language);
  const clients = useSelector(state => state.clients);

  const dispatch = useDispatch();

  const [collectionName, setCollectionName] = useState();
  const [requiredCollectionName, setRequiredCollectionName] = useState(false);
  const [id, setId] = useState(true);
  const [colession, setColession] = useState(false);
  const [leCollection, setLeCollection] = useState(false);
  const [requiredClient, setRequiredClient] = useState(false);
  const [clientsSelector, setClientsSelector] = useState('closed');
  const [selectedClient, setSelectedClient] = useState();
  const [cnpjSelector, setCnpjSelector] = useState('closed');
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [stateSelector, setStateSelector] = useState('closed');
  const [otherCountry, setOtherCountry] = useState(false);
  const [loading, setLoading] = useState(false);

  const clientSelectorRef = useRef();
  const cnpjSelectorRef = useRef();
  const statesSelectorRef = useRef();

  useEffect(() => {
    (async function loadCollections() {
      try {
        const getClients = Api.getClients();
        const getRegion = Api.getRegion();
        const getCountries = Api.getCountries();

        await axios.all([getClients, getRegion, getCountries]).then(
          axios.spread((...responses) => {
            dispatch(setClients(responses[0]));
            setStates(responses[1]);
            setCountries(responses[2]);
          })
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    setSelectedState();
  }, [otherCountry]);

  useEffect(() => {
    setRequiredCollectionName(false);
  }, [collectionName]);

  useEffect(() => {
    setRequiredClient(false);
  }, [selectedClient]);

  useOutsideClick(clientSelectorRef, () => {
    setClientsSelector('closed');
  });

  useOutsideClick(cnpjSelectorRef, () => {
    setCnpjSelector('closed');
  });

  useEffect(() => {
    setStateSelector(false);
  }, [selectedState]);

  useOutsideClick(statesSelectorRef, () => {
    setStateSelector('closed');
  });

  const isFormValid = () => {
    let valid = true;

    if (!collectionName) {
      valid = false;
      setRequiredCollectionName(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        type: 'warning'
      });
    }

    return valid;
  };

  const createCollection = async e => {
    const getType = () => {
      if (colession) {
        return 'COL';
      }
      if (id) {
        return 'ID';
      }
      if (leCollection) {
        return 'LE';
      }
      return '';
    };
    e.preventDefault();
    if (isFormValid()) {
      let res;
      setLoading(true);
      try {
        res = await Api.createCollection({
          name: collectionName,
          briefing: null,
          collection_type: getType(),
          ps: null,
          national: !otherCountry,
          country: otherCountry && selectedState ? selectedState.id : null,
          region_br: !otherCountry && selectedState ? selectedState.id : null,
          owner: null
        });
        toast(translate('successCreatingCollection', language), {
          type: 'success'
        });
      } catch {
        toast(translate('failCreatingCollection', language), {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          type: 'error'
        });
      } finally {
        setLoading(false);
        if (res && res.id) {
          onSuccess(res);
        }
      }
    }
  };

  return (
    <>
      <form className={styles.createCollectionForm}>
        <input
          className={`${styles.defaultTextInput} ${
            requiredCollectionName ? styles.error : ''
          }`}
          placeholder={translate('collectionName', language)}
          value={collectionName}
          onChange={e => setCollectionName(e.currentTarget.value)}
          name="name"
        />
        {requiredCollectionName && (
          <span className={styles.errorMessage}>
            {translate('requiredField', language)}
          </span>
        )}
        <div className={styles.checkboxContainer}>
          <label htmlFor="id" className={styles.checkmarkContainer}>
            <input
              type="checkbox"
              id="id"
              checked={id}
              onChange={() => {
                setId(!id);
                setColession(false);
                setLeCollection(false);
              }}
            />
            <span className={styles.checkmark} />
            {translate('id', language)}
          </label>
          <label htmlFor="colession" className={styles.checkmarkContainer}>
            <input
              type="checkbox"
              id="colession"
              checked={colession}
              onChange={() => {
                setId(false);
                setColession(!colession);
                setLeCollection(false);
              }}
            />
            <span className={styles.checkmark} />
            {translate('colession', language)}
          </label>
          <label htmlFor="leCollection" className={styles.checkmarkContainer}>
            <input
              type="checkbox"
              id="leCollection"
              checked={leCollection}
              onChange={() => {
                setId(false);
                setColession(false);
                setLeCollection(!leCollection);
              }}
            />
            <span className={styles.checkmark} />
            {translate('leCollection', language)}
          </label>
        </div>
        {id && (
          <>
            <div className={styles.clientNameSection} ref={clientSelectorRef}>
              <h3 className={styles.subtitle}>Cliente</h3>
              <button
                id=""
                type="button"
                className={`${styles.defaultSelectInput} ${
                  styles.selectInput
                } ${requiredClient ? styles.error : ''}`}
                onClick={() => setClientsSelector('opened')}
              >
                {selectedClient
                  ? selectedClient.name
                  : translate('chooseClient', language)}
              </button>
              {requiredClient && (
                <span className={styles.errorMessage}>
                  {translate('requiredField', language)}
                </span>
              )}
              {clientsSelector === 'opened' && (
                <span>
                  <ListSelector
                    label={translate('clients', language)}
                    selectLabel={translate('choose', language)}
                    items={clients}
                    onSelect={setSelectedClient}
                    value={selectedClient}
                    search
                  />
                </span>
              )}
            </div>
            <div className={styles.clientNameSection} ref={cnpjSelectorRef}>
              <button
                id=""
                type="button"
                className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                onClick={() => setCnpjSelector('opened')}
              >
                {selectedClient && selectedClient.cnpj
                  ? selectedClient.cnpj
                  : translate('cnpj', language)}
              </button>
              {cnpjSelector === 'opened' && (
                <span>
                  <ListSelector
                    label={translate('clients', language)}
                    selectLabel={translate('choose', language)}
                    items={clients}
                    onSelect={setSelectedClient}
                    value={selectedClient}
                    customValue="cnpj"
                    search
                  />
                </span>
              )}
            </div>

            <p className={styles.label}>{translate('state', language)}</p>
            <div className={styles.statesSection} ref={statesSelectorRef}>
              <button
                id="collection"
                type="button"
                className={styles.defaultSelectInput}
                onClick={() => setStateSelector('opened')}
              >
                {selectedState && selectedState.name}
                {!selectedState &&
                  otherCountry &&
                  translate('otherCountry', language)}
                {!selectedState &&
                  !otherCountry &&
                  translate('selectState', language)}
              </button>

              {stateSelector === 'opened' && (
                <span>
                  <ListSelector
                    label={translate('states', language)}
                    selectLabel={translate('choose', language)}
                    items={otherCountry ? countries : states}
                    onSelect={setSelectedState}
                    value={selectedState}
                    search
                  />
                </span>
              )}
            </div>
            <div className={styles.checkboxContainer}>
              <label htmlFor="country" className={styles.checkmarkContainer}>
                <input
                  type="checkbox"
                  id="country"
                  checked={otherCountry}
                  onChange={() => setOtherCountry(!otherCountry)}
                />
                <span className={styles.checkmark} />
                {translate('otherCountry', language)}
              </label>
            </div>
          </>
        )}
        <hr />
        <button
          type="button"
          onClick={createCollection}
          className={`${styles.defaultActionButton} ${styles.actionButton} `}
        >
          {translate('save', language)}
        </button>
      </form>
      {loading && <Loading />}
    </>
  );
}

CreateCollectionModal.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default CreateCollectionModal;
