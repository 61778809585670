/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import translate from '../libs/i18n';
import Api from '../libs/Api';

import styles from './CreateClientForm.module.scss';

function CreateClientForm({ onSuccess }) {
  const language = useSelector(state => state.settings.language);

  const [clientName, setClientName] = useState('');
  const [clientCnpj, setClientCnpj] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [requiredClientName, setRequiredClientName] = useState(false);
  const [requiredClientEmail, setRequiredClientEmail] = useState(false);

  useEffect(() => {
    setRequiredClientName(false);
  }, [clientName]);

  useEffect(() => {
    setRequiredClientEmail(false);
  }, [clientEmail]);

  const isFormValid = () => {
    let valid = true;

    if (!clientName) {
      valid = false;
      setRequiredClientName(true);
    }
    if (!clientEmail) {
      valid = false;
      setRequiredClientEmail(true);
    }

    if (!valid) {
      toast(translate('requiredFields', language), {
        type: 'warning'
      });
    }

    return valid;
  };

  const createClient = e => {
    e.preventDefault();
    if (isFormValid()) {
      Api.createClient({
        name: clientName,
        cnpj: clientCnpj,
        email: clientEmail,
      })
        .then(res => {
          toast(translate('successCreatingClient', language), {
            type: 'success'
          });
          setClientCnpj('');
          setClientName('');
          setClientEmail('');
          if (onSuccess) {
            onSuccess(res);
          }
        })
        .catch(() => {
          toast(translate('failCreatingClient', language), {
            type: 'warning'
          });
        });
    }
  };

  return (
    <form className={styles.form}>
      <div id="printNameSection" className={styles.simpleInputContainer}>
        <h1 className={styles.title}>
          {translate('registerClient', language)}
        </h1>
        <label className={styles.sectionLabel} htmlFor="clientName">
          {translate('clientName', language)}
          <input
            className={`${styles.defaultTextInput} ${
              requiredClientName ? styles.error : ''
            }`}
            value={clientName}
            onChange={e => setClientName(e.currentTarget.value)}
            name="name"
            id="clientName"
          />
        </label>
        {requiredClientName && (
          <span className={styles.errorMessage}>
            {translate('requiredField', language)}
          </span>
        )}
      </div>
      <div id="printNameSection" className={styles.simpleInputContainer}>
        <label className={styles.sectionLabel} htmlFor="clientCnpj">
          {translate('cnpj', language)}
          <input
            className={`${styles.defaultTextInput}`}
            value={clientCnpj}
            onChange={e => setClientCnpj(e.currentTarget.value)}
            name="clientCnpj"
            id="clientCnpj"
          />
        </label>
      </div>
      <div id="printNameSection" className={styles.simpleInputContainer}>
        <label className={styles.sectionLabel} htmlFor="clientEmail">
          {translate('email', language)}
          <input
            className={`${styles.defaultTextInput} ${
              requiredClientEmail ? styles.error : ''
            }`}
            value={clientEmail}
            onChange={e => setClientEmail(e.currentTarget.value)}
            name="clientEmail"
            id="clientEmail"
            type="email"
          />
        </label>
        {requiredClientEmail && (
          <span className={styles.errorMessage}>
            {translate('requiredField', language)}
          </span>
        )}
      </div>

      <hr />
      <button
        className={`${styles.defaultActionButton} ${styles.save}`}
        type="button"
        onClick={createClient}
      >
        {translate('save', language)}
      </button>
    </form>
  );
}

export default CreateClientForm;

CreateClientForm.propTypes = {
  onSuccess: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

CreateClientForm.defaultProps = {
  onSuccess: false
};
