import React, { useEffect, useRef, useState } from 'react';
import FileSelector from '../../components/FileSelector';
import ListSelector from '../../components/ListSelector';
import Loading from '../../components/Loading';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import { useSelector } from 'react-redux';
import Api from '../../libs/Api';
import { toast } from 'react-toastify';
import { uploadNewFile } from '../../libs/s3Upload';
import { useParams } from 'react-router-dom';
import useOutsideClick from '../../libs/useOutsideClick';


export function CreateFabric() {
    const language = useSelector(state => state.settings.language);
    const { id } = useParams();
    const [name, setName] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState("BRL");
    const [supplierFabricName, setSupplierFabricName] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [width, setWidth] = useState('');
    const [suggPrice, setSuggPrice] = useState();
    const [description, setDescription] = useState('');
    const [grammage, setGrammage] = useState('');
    const [throughput, setThroughput] = useState('');
    const [saleProductionCode, setSaleProductionCode] = useState('');
    const [saleSampleCode, setSaleSampleCode] = useState('');
    const [requiredField, setRequiredField] = useState(false);
    const [supplierRegister, setSupplierRegisters] = useState([]);
    const [initialRegister, setInitialRegister] = useState([]);
    const [fabricImage, setFabricImage] = useState([]);
    const [fabricVideo, setFabricVideo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [construction, setConstruction] = useState('');
    const [certification, setCertification] = useState('');
    const [supplierPrice, setSupplierPrice] = useState('');
    const [mixture, setMixture] = useState('');
    const [fabricCharacteristicsSelector, setFabricCharacteristicsSelector] = useState('closed');
    const [selectedFabricCharacteristics, setSelectedFabricCharacteristics] = useState([]);
    const fabricCharacteristicsSelectorRef = useRef();
    const currency = [
        {
            label: 'Real',
            value: 'BRL'
        },
        {
            label: 'Euro',
            value: 'EUR'
        },
    ]
    const fabricCharacteristics = [
        {
            id: 1,
            name: 'Airflow',
            value: 1
        },
        {
            id: 2,
            name: 'Bambula',
            value: 2
        },
        {
            id: 3,
            name: 'Crepe',
            value: 3
        },
        {
            id: 4,
            name: 'Jacquard',
            value: 4
        },
        {
            id: 5,
            name: 'Matte',
            value: 5
        },
        {
            id: 6,
            name: 'Popeline',
            value: 6
        },
        {
            id: 7,
            name: 'Rustic',
            value: 7
        },
        {
            id: 8,
            name: 'Satin',
            value: 8
        },
        {
            id: 9,
            name: 'Transparent',
            value: 9
        },
        {
            id: 10,
            name: 'Twill',
            value: 10
        },
        {
            id: 11,
            name: 'Velvet',
            value: 11
        },
        {
            id: 12,
            name: 'Voile',
            value: 12
        },
    ]
    function clearInputValues() {
        setName('');
        setSelectedSupplier();
        setSupplierFabricName('');
        setWidth('');
        setSuggPrice('');
        setDescription('');
        setThroughput('');
        setGrammage('');
        setConstruction('');
        setCertification('');
        setSupplierPrice('');
        setSaleProductionCode('');
        setSaleSampleCode('');
        setSupplierPrice('');
        setMixture('');
        setFabricImage([]);
        setFabricVideo([]); 
        setSupplierRegisters([]);
        setSelectedFabricCharacteristics([]);
    }
    const changeFabricCharacteristicsSelector = state => {
        if (state === 'closed') {
            setFabricCharacteristicsSelector('opened');
        } else {
            setFabricCharacteristicsSelector('closed');
        }
      };
    useOutsideClick(fabricCharacteristicsSelectorRef, () => {
        changeFabricCharacteristicsSelector(fabricCharacteristicsSelector);
    });
    useEffect(() => {
        if(id) {
            Api.getSpecificFabric(id)
            .then(res => {
                setName(res?.name);
                setWidth(res?.width);
                setSuggPrice(res?.suggested_price);
                setDescription(res?.description);
                setThroughput(res?.throughput);
                setGrammage(res?.grammage);
                setSaleProductionCode(res?.sale_production_code);
                setSaleSampleCode(res?.sale_sample_code);
                setConstruction(res?.construction);
                setCertification(res?.certification);
                setSelectedCurrency(res?.currency)
                setMixture(res?.mixture)
                setFabricImage([]);
                setFabricVideo([]); 
            })
            .catch(() =>{})
            Api.getSpecificSupplierFabric(id)
            .then(res => setInitialRegister(res))
            Api.getSpecificFabricCharacteristics(id)
            .then(res => setSelectedFabricCharacteristics(res.results?.map(res => {
                const characteristic = fabricCharacteristics.find(fabric => fabric.value === res.characteristic);
                return {
                    id: res?.id,
                    value: res?.characteristic,
                    name: characteristic ? characteristic.name : null // Caso encontre o nome
                };
            })))
        }
        
        Api.getSupplier('type=f,b')
        .then(res => {
            setSuppliers(res.results)
            setSelectedSupplier("")
        })
        .catch(() =>{})
    }, []);

    const addRegistration = () => {
        const updatedRegistrations = [...supplierRegister];
        updatedRegistrations.push({
          supplier: selectedSupplier,
          name: supplierFabricName,
          supplier_price: Number(supplierPrice),
        });
        setSupplierRegisters(updatedRegistrations);
        setSelectedSupplier("");
        setSupplierFabricName('');
    };

    const deleteSupplierRegisterApi = async (index) => {
        const updatedRegistrations = [...initialRegister];
        try {
            await Api.deleteSupplierFabric(updatedRegistrations[index].id)
            updatedRegistrations.splice(index, 1);
            setInitialRegister(updatedRegistrations);
        } catch {

        }
    }

    const deleteSupplierRegister = async (index) => {
        const updatedRegistrations = [...supplierRegister];
        updatedRegistrations.splice(index, 1);
        setSupplierRegisters(updatedRegistrations);
    }
    async function handleCreateFabric(e) {
        
        e.preventDefault();

        const formData = new FormData();

        formData.append('name', name)
        formData.append('width', width)
        formData.append('suggested_price', suggPrice ?? 0)
        formData.append('grammage', grammage ?? 0)
        formData.append('throughput', throughput ?? 0)
        formData.append('description', description)
        formData.append('supplier', [selectedSupplier])
        formData.append('supplier_fabric_name', supplierFabricName)
        formData.append('sale_production_code', saleProductionCode)
        formData.append('sale_sample_code', saleSampleCode)
        formData.append('certification', certification)
        formData.append('construction', construction)
        formData.append('currency', selectedCurrency)
        formData.append('mixture', mixture)
        formData.append('is_active', true)
        if(width === "") {
            setRequiredField(!requiredField);
            return
        }

        try {
            setLoading(true);
            const response = id ? await Api.editFabric(formData, id) : await Api.createFabric(formData);
            if(supplierRegister.length > 0) {
                try {
                    supplierRegister.map(spr => Api.createSupplierFabric({
                        supplier: spr.supplier,
                        fabric: response.id,
                        name: spr.name,
                        supplier_price: spr.supplier_price
                    }))
                } catch(err) {
                    return toast(translate('errorCreatingFabric', language), {
                        type: 'error'
                    });
                }
            }
            if(selectedFabricCharacteristics.length > 0) {
                try {
                    selectedFabricCharacteristics.map(fc => Api.createCharacteristicsFabric({
                        fabric: response.id,
                        characteristic: fc.value
                    }))
                } catch(err) {
                    return toast(translate('errorCreatingFabric', language), {
                        type: 'error'
                    });
                }
            }
            if(fabricImage.length > 0) {
                try {
                    await uploadNewFile(
                        `${fabricImage[0].name}`,                
                            fabricImage[0],
                            `fabric`,
                        )
                        await Api.psdFileSuccess({
                            fabric_id: response.id,
                            path: `fabric/${fabricImage[0].name}`,
                            file_field: 'fabric_cover'
                          });
                } catch(err) {
                    return toast(translate('errorCreatingFabric', language), {
                        type: 'error'
                    });
                }

            }
            if(fabricVideo.length > 0) {
                try {
                    await uploadNewFile(
                        `${fabricVideo[0].name}`,                
                            fabricVideo[0],
                            `fabric/video`,
                    )
            
                    await Api.psdFileSuccess(
                        {
                            fabric_id: response.id,
                            path: `fabric/video/${fabricVideo[0].name}`,
                            file_field: 'fabric_video'
                        }
                    )
                } catch(err) {
                    return toast(translate('errorCreatingFabric', language), {
                        type: 'error'
                    });
                }

            }
        } catch(err) {
            setLoading(false)
            return toast(translate('errorCreatingFabric', language), {
                type: 'error'
            });
        }

        toast(translate('successCreatingFabric', language), {
                type: 'success'
        });
        setLoading(false)
        if(!id) {
            clearInputValues();
        }
    }


    return(
        <>
            <div className={styles.header}>
                <h2 className={styles.sectionTitle}>
                <span className={styles.title}>
                    {'REGISTRAR QUALIDADE'}
                </span>
                </h2>
            </div>
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <form onSubmit={handleCreateFabric}>
                        <div className={styles.textInput}>
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("fabricName", language)}
                            </strong>
                            <input 
                                placeholder={translate("fabricName", language)}
                                value={name}
                                onChange={(e) => {setName(e.target.value)}}
                            />    
                            {requiredField && <p>{translate('requiredField', language)}</p>}
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("fabricWidth", language)}
                            </strong>
                            <input 
                                placeholder={translate("fabricWidth", language)}
                                value={width}
                                className={requiredField ? styles.requiredField : ''}
                                onChange={(e) => { setWidth(e.target.value) 
                                                   setRequiredField(false)
                                }}
                            />
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("suggPrice", language)} 
                            </strong>
                            <div className={styles.doubleInput}>
                                <input 
                                    placeholder={translate("suggPrice", language)} 
                                    type="number"
                                    value={suggPrice}
                                    onChange={(e) => setSuggPrice(e.target.value)}
                                />
                                <select 
                                    value={selectedCurrency}
                                    onChange={(e) => {setSelectedCurrency(e.target.value)}}
                                    className={requiredField ? styles.requiredField : ''}
                                >
                                    {currency.map(currency => <option value={currency.value}>{currency.label}</option>)}
                                </select>
                            </div>
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("grammage", language)}
                            </strong>
                            <input 
                                placeholder={translate("grammage", language)}
                                value={grammage}
                                onChange={(e) => {setGrammage(e.target.value)}}
                                type="number"
                            />
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("composition", language)}
                            </strong>
                            <input 
                                placeholder={translate("composition", language)}
                                value={mixture}
                                onChange={(e) => {setMixture(e.target.value)}}
                                type="text"
                            />
                            {/* <input 
                                placeholder={'Nome do fornecedor'}
                                value={supplier}
                                onChange={(e) => {setSupplier(e.target.value)}}
                            /> */}
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("fabricConstruction", language)}
                            </strong>
                            <input 
                                placeholder={translate("fabricConstruction", language)}
                                value={construction}
                                onChange={(e) => {setConstruction(e.target.value)}}
                            />
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("fabricCertification", language)}
                            </strong>
                             <input 
                                placeholder={translate("fabricCertification", language)}
                                value={certification}
                                onChange={(e) => {setCertification(e.target.value)}}
                            />
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("fabricDescription", language)}
                            </strong>
                            <textarea
                                className={styles.description}
                                placeholder={translate("fabricDescription", language)}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <strong style={{
                                marginBottom: '1rem'
                            }}
                            >
                                {translate("fabricSupplier", language)}
                            </strong>
                            <select 
                                placeholder={translate("selectSupplier", language)}
                                value={selectedSupplier}
                                onChange={(e) => {setSelectedSupplier(e.target.value)}}
                                className={requiredField ? styles.requiredField : ''}
                            >
                                <option value=""> {translate("selectSupplier", language)} </option>
                                {suppliers.map(supplier => <option value={supplier.id}>{supplier.name}</option>)}
                            </select>
                            <div className={styles.addFabricNameContainer}>
                                <input 
                                    placeholder={translate("supplierFabricName", language)}
                                    value={supplierFabricName}
                                    onChange={(e) => {setSupplierFabricName(e.target.value)}}
                                />
                                <input 
                                    placeholder={translate("supplierFabricPrice", language)}
                                    value={supplierPrice}
                                    onChange={(e) => {setSupplierPrice(e.target.value)}}
                                    type="number"
                                />
                            </div>
                            <button className={styles.button} type="button" onClick={addRegistration}>ADD</button>
                            {(initialRegister?.length > 0) && (
                                initialRegister?.map((supplier, index) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '1rem',
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <p>{suppliers.filter(sp => sp.id === Number(supplier.supplier))[0]?.name}</p>
                                        <p>{supplier.name}</p>
                                        <p>{Number(supplier.supplier_price).toFixed(2)}</p>
                                        <button type="button" onClick={() => deleteSupplierRegisterApi(index)}>delete</button>
                                    </div>
                                ))
                            )}
                            {(supplierRegister?.length > 0) && (
                                supplierRegister?.map((supplier, index) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '1rem',
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        <p>{suppliers.filter(sp => sp.id === Number(supplier.supplier))[0]?.name}</p>
                                        <p>{supplier.name}</p>
                                        <p>{Number(supplier.supplier_price).toFixed(2)}</p>
                                        <button type="button" onClick={() => deleteSupplierRegister(index)}>delete</button>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className={styles.doubleFileInput}>
                            <div className={styles.leftInputGroup}>
                                <FileSelector 
                                    files={fabricImage}
                                    label={translate("uploadImageFabric", language)}
                                    onSelect={setFabricImage}
                                    fullWidth
                                />
                            </div>
                            <div className={styles.rightInputGroup}>
                                <FileSelector 
                                files={fabricVideo}
                                label={translate("uploadVideoFabric", language)}
                                onSelect={setFabricVideo}
                                fullWidth
                                />
                            </div>
                        </div>
                        <div className={styles.doubleInput}>
                            <div className={styles.textInput}>
                                <strong style={{
                                    marginBottom: '1rem'
                                }}
                                >
                                    {translate("sampleCode", language)}
                                </strong>
                                <input 
                                    placeholder={translate("sampleCode", language)}
                                    value={saleSampleCode}
                                    onChange={(e) => {setSaleSampleCode(e.target.value)}}
                                    maxLength={30}
                                />
                            </div>
                            <div className={styles.textInput}>
                                <strong style={{
                                    marginBottom: '1rem'
                                }}
                                >
                                    {translate("productionCode", language)}
                                </strong>
                                <input 
                                    placeholder={translate("productionCode", language)}
                                    value={saleProductionCode}
                                    onChange={(e) => {setSaleProductionCode(e.target.value)}}
                                    maxLength={30}
                                />
                            </div>
                        </div>
                        <div className={styles.selectorContainer}>
                            <strong style={{
                                    marginBottom: '1rem'
                            }}
                            >
                                {translate("characteristics", language)}
                            </strong>
                            <button
                                type="button"
                                className={`${styles.defaultSelectInput}`}
                                onClick={() => setFabricCharacteristicsSelector('opened')}
                            >
                                {selectedFabricCharacteristics?.length > 0 ? selectedFabricCharacteristics?.map(value => ` ${value.name}, `) : 'Selecione as caracteristicas.'}
                            </button>
                            {fabricCharacteristicsSelector === 'opened' && (
                                <span ref={fabricCharacteristicsSelectorRef}>
                                    <ListSelector
                                        items={fabricCharacteristics}
                                        onSelect={setSelectedFabricCharacteristics}
                                        selectLabel={translate('choose', language)}
                                        multiple
                                        values={selectedFabricCharacteristics}
                                        search
                                    />
                                </span>
                            )}
                        </div>
                        <button 
                            className={styles.defaultActionButton}
                            type="submit"
                        >
                            {id ? translate("editFabric", language) : translate("saveFabric", language)}
                        </button>
                    </form>

                    {loading &&
                        (
                            <div className={styles.loadingContainer}>
                            <Loading />
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}