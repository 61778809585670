import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { FiUser } from 'react-icons/fi';
import translate from '../libs/i18n';
import Api from '../libs/Api';
import styles from './Presentation.module.scss';
import useOutsideClick from '../libs/useOutsideClick';

import PresentationConcept from '../components/PresentationConcept';
import PresentationPrints from '../components/PresentationPrints';
import FavoritePresentationPrints from '../components/FavoritePresentationPrints';
import OtherIdeasPresentationPrints from '../components/OtherIdeasPresentationPrints';
import PlusIcon from '../assets/icons/Plus';
import Loading from '../components/Loading';
import PresentationPlayer from '../components/PresentationPlayer';
import ListSelector from '../components/ListSelector';
import ConfirmationModal from '../components/ConfirmationModal';
import FileSelector from '../components/FileSelector';
import { uploadNewFile } from '../libs/s3Upload';

export default function Presentation({ debug }) {
  const dispatch = useDispatch();
  const language = useSelector(state => state.settings.language);
  const currentUser = useSelector(state => state.user);

  const { id } = useParams();

  const usersSelectorRef = useRef();

  const [presentation, setPresentation] = useState();
  const [prints, setPrints] = useState([]);
  const [concept, setConcept] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentSection, setCurrentSection] = useState('prints');
  const [loading, setLoading] = useState(false);
  const [slider, setSlider] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersSelector, setUsersSelector] = useState('closed');
  const [invites, setInvites] = useState([]);
  const [deletingInvite, setDeletingInvite] = useState(false);
  const [dolls, setDolls] = useState([]);
  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [artFinalists, setArtFinalists] = useState([]);

  const [presentationCover, setPresentationCover] = useState([]);

  const changeUsersSelector = state => {
    if (state === 'closed') {
      setUsersSelector('opened');
    } else {
      setUsersSelector('closed');
    }
  };

  useOutsideClick(usersSelectorRef, () => {
    changeUsersSelector(usersSelector);
  });

  const loadPresentationPrints = () => {
    Api.getPresentationSelectedPrints(id)
      .then(res => {
        setPrints(res);
      })
      .catch();
  };

  // Favorites
  const favoritePrint = (printId, meetingId, clientId) => {
    const updatedPrints = [...prints];
    const printIndex = updatedPrints.findIndex(up => up.id === printId);
    updatedPrints[printIndex].isFavorite = true;
    setPrints(updatedPrints);

    Api.favoriteSelectedPrint(printId, meetingId, clientId)
      .then(() => {
        toast(translate('successFavoritingPrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorFavoritingPrint', language), {
          type: 'error'
        });
        updatedPrints[printIndex].isFavorite = false;
        setPrints(updatedPrints);
      });
  };

  const removeFavoritePrint = printId => {
    const updatedPrints = [...prints];
    const printIndex = updatedPrints.findIndex(up => up.id === printId);
    updatedPrints[printIndex].isFavorite = false;
    setPrints(updatedPrints);

    Api.removeFavoritePrint(printId)
      .then(() => {
        toast(translate('successRemovingFavoritePrint', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegPrint', language), {
          type: 'error'
        });
        updatedPrints[printIndex].isFavorite = true;
        setPrints(updatedPrints);
      });
  };

  useEffect(() => {
    (async function loadData() {
      setLoading(true);
      try {
        Api.getPresentation(id).then(res => {
          setPresentation(res);
          if (res.client) {
            Api.getPresentationSelectedPrintsByClient(id, res.client).then(
              psp => {
                setPrints(psp);
              }
            );
          } else {
            Api.getPresentationSelectedPrints(id).then(psp => {
              setPrints(psp);
            });
          }
          Api.getDollsByProfile(res.customer_profile).then(dbp => {
            setDolls(dbp);
          });
        });
        const getConcept = Api.getConcept(id);
        const getTags = Api.getTags();
        const getUsers = Api.getUsers();
        const getInvites = Api.getPresentationInvites(id);
        const getFabrics = Api.getFabrics();

        await axios
          .all([getTags, getUsers, getInvites, getConcept, getFabrics])
          .then(
            axios.spread(async (...responses) => {
              setTags(responses[0]);
              setUsers(responses[1]);
              setInvites(responses[2]);
              setConcept(responses[3].results);
              setFabrics(responses[4].results);
            })
          );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setLoading(false);
      }
      Api.getCollections().then(res => {
        setCollections(res);
      });
      Api.getClients().then(res => {
        setClients(res);
      });
      Api.getArtFinalists().then(res => {
        setArtFinalists(res);
      });
    })();
  }, [dispatch, id]);

  const showSlider = () => {
    setSlider(true);
  };

  const hideSlider = () => {
    setSlider(false);
  };

  const inviteUsers = () => {
    setUsersSelector('closed');
    setSelectedUsers([]);
    const invitePosts = [];
    selectedUsers.forEach(user => {
      const invite = Api.invite({ meeting: id, staff: user.id });
      invitePosts.push(invite);
    });
    axios
      .all(invitePosts)
      .then(() => {
        Api.getPresentationInvites(id).then(res => {
          setInvites(res);
        });
        toast(translate('successSendingInvites', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('failSendingInvites', language), {
          type: 'error'
        });
      });
  };

  const getInvitedUser = userId => {
    return users.find(user => user.id === userId);
  };

  const getNonInvitedUsers = useMemo(() => {
    let nonInvitedUsers = [...users];
    invites.forEach(invite => {
      nonInvitedUsers = nonInvitedUsers.filter(
        user => user.id !== invite.staff
      );
    });
    nonInvitedUsers = nonInvitedUsers.filter(
      user => user.id !== currentUser.id
    );

    return nonInvitedUsers;
  }, [currentUser, invites, users]);

  const updateConcept = () => {
    Api.getConcept(id).then(res => {
      setConcept(res.results);
    });
  };

  const updatePrints = () => {
    setLoading(true);
    Api.getPresentation(id)
      .then(async ans => {
        const returnedPresentation = { ...ans };
        loadPresentationPrints(returnedPresentation.id);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteInvite = () => {
    setLoading(true);
    Api.deleteInvite(deletingInvite)
      .then(() => {
        Api.getPresentationInvites(id).then(res => {
          setInvites(res);
        });
      })
      .catch(() => {
        toast(translate('failDeletingInvite', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setDeletingInvite(false);
        setLoading(false);
      });
  };

  function onSelectPresentationCover(files) {
    if (
      !files[0].name.toUpperCase().includes('.JPG') &&
      !files[0].name.toUpperCase().includes('.JPEG') &&
      !files[0].name.toUpperCase().includes('.PNG')
    ) {
      toast(translate('invalidFileFormat', language), {
        type: 'error'
      });
    } else {
      setPresentationCover(files);
    }
  }

  async function handleSetPresentationCover() {
    try {
      // NAME - FILE - PATH
      setLoading(true);
      await uploadNewFile(
        `${presentationCover[0].name}`,
        presentationCover[0],
        `print/small`
      );
      await Api.psdFileSuccess({
        presentation_id: id,

        path: `print/small/${presentationCover[0].name}`,
        file_field: 'presentation_cover'
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setLoading(false);
      setPresentationCover([]);
      toast(translate('failSavingPresentationCover', language), {
        type: 'error'
      });
    }
    toast(translate('successSavingPresentationCover', language), {
      type: 'success'
    });
    setLoading(false);
    setPresentationCover([]);
  }

  const getSlidePrints = useMemo(() => {
    const unifiedPrints = [];
    prints
      .filter(p => p.intentional_inserted)
      .forEach(print => {
        if (!unifiedPrints.find(uP => uP.print_id === print.print_id)) {
          unifiedPrints.push(print);
        }
      });

    return unifiedPrints;
  }, [prints]);

  return (
    <div className={styles.presentation}>
      <div className={styles.header}>
        <div className={styles.topHeader}>
          <h2 className={styles.sectionTitle}>
            <span className={styles.title}>
              {translate('presentation', language).toUpperCase()}
            </span>
            {presentation && (
              <span className={styles.presentationName}>
                {presentation.name ? presentation.name.toUpperCase() : ''}
              </span>
            )}
          </h2>
          <div className={styles.social}>
            {/* <ul className={styles.invitedUsers}>
              {invites.map(invite => (
                <li key={invite.id}>
                  <button
                    className={styles.delete}
                    type="button"
                    onClick={() => setDeletingInvite(invite.id)}
                  >
                    -
                  </button>
                  <div className={styles.imageContainer}>
                      {getInvitedUser(invite.staff).image ? (
                        <img
                          src={getInvitedUser(invite.staff).image}
                          alt={getInvitedUser(invite.staff).name}
                          title={getInvitedUser(invite.staff).name}
                        />) : <FiUser size={25} title={getInvitedUser(invite.staff).name}/>}
                  </div>
                </li>
              ))}
            </ul> */}
            <div className={styles.userSelectorContainer}>
              {/* <button
                type="button"
                className={styles.invite}
                onClick={() => setUsersSelector('opened')}
              >
                <PlusIcon color="#9BA7B7" />
              </button> */}
              {usersSelector === 'opened' && (
                <div className={styles.userSelector} ref={usersSelectorRef}>
                  <ListSelector
                    items={getNonInvitedUsers}
                    onSelect={setSelectedUsers}
                    selectLabel={translate('choose', language)}
                    multiple
                    values={selectedUsers}
                    search
                    action={inviteUsers}
                    actionText={translate('invite', language)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className={styles.sectionsMenu}>
        <li>
          <button
            className={`${currentSection === 'concept' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('concept')}
          >
            {translate('concept', language)}
          </button>
        </li>
        <li>
          <button
            className={`${currentSection === 'prints' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('prints')}
          >
            {translate('prints', language)}
          </button>
        </li>
        <li className={styles.break}>
          <button
            className={`${currentSection === 'favorites' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('favorites')}
          >
            {translate('favorites', language)}
          </button>
        </li>
        <li>
          <button
            className={`${currentSection === 'otherIdeas' && styles.active}`}
            type="button"
            onClick={() => setCurrentSection('otherIdeas')}
          >
            {translate('otherIdeas', language)}
          </button>
        </li>
        <li>
          <FileSelector
            files={presentationCover}
            onSelect={onSelectPresentationCover}
            label={translate('uploadPresentationCover', language)}
          />
        </li>

        {presentationCover.length > 0 && (
          <button
            className={styles.defaultRoundedActionButton}
            onClick={() => handleSetPresentationCover()}
          >
            Salvar
          </button>
        )}
      </ul>
      <div className={styles.content}>
        {presentation && (
          <>
            {currentSection === 'concept' && (
              <PresentationConcept
                presentation={presentation}
                playSlider={showSlider}
                concept={concept.sort((a, b) => a.position - b.position)}
                onUpdate={updateConcept}
              />
            )}
            {currentSection === 'prints' && (
              <PresentationPrints
                presentation={presentation}
                prints={prints}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {currentSection === 'favorites' && (
              <FavoritePresentationPrints
                presentation={presentation}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
            {currentSection === 'otherIdeas' && (
              <OtherIdeasPresentationPrints
                presentation={presentation}
                playSlider={showSlider}
                onUpdate={updatePrints}
                tags={tags}
                collections={collections}
                clients={clients}
                artFinalists={artFinalists}
                fabrics={fabrics}
                dolls={dolls}
              />
            )}
          </>
        )}
        {loading && <Loading fixed />}
      </div>
      {slider && (
        <PresentationPlayer
          concept={concept}
          presentation={presentation}
          prints={getSlidePrints}
          close={hideSlider}
          section={currentSection}
          debug={debug}
        />
      )}
      {deletingInvite && (
        <ConfirmationModal
          title={translate('inviteDelete', language)}
          message={translate('inviteDeletingMessage', language)}
          confirmText={translate('yes', language)}
          cancelText={translate('no', language)}
          onConfirm={deleteInvite}
          onCancel={() => setDeletingInvite(false)}
          deleteWarning
        />
      )}
    </div>
  );
}

Presentation.propTypes = {
  debug: PropTypes.bool
};

Presentation.defaultProps = {
  debug: false
};
