import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import isLoggedIn from './libs/AuthCheck';

import HeaderComponent from './components/Header';
import ClientHeaderComponent from './components/ClientHeader';
import Login from './pages/Login';
import Works from './pages/Works';
import Presentations from './pages/Presentations';
import Presentation from './pages/Presentation';
import Attendances from './pages/Attendances';
import Attendance from './pages/Attendance';
import Library from './pages/Library';
import CreatePrint from './pages/CreatePrint';
import Print from './pages/Print';
import CreatePresentation from './pages/CreatePresentation';
import EditPresentation from './pages/EditPresentation';
import CreateAttendance from './pages/CreateAttendance';
import EditAttendance from './pages/EditAttendance';
import AttendancePresentation from './pages/AttendancePresentation';
import RegisterClient from './pages/RegisterClient';
import Search from './pages/Search';
import CreatePantone from './pages/CreatePantone';
import LinkCreation from './pages/LinkCreation';
import ClientLogin from './pages/ClientLogin';
import ClientPresentationConcept from './pages/ClientPresentationConcept';
import ClientPresentationPrints from './pages/ClientPresentationPrints';
import ClientCart from './pages/ClientCart';
import ClientAttendance from './pages/ClientAttendance';
import PrintCustomization from './pages/PrintCustomization';
import ClientLogout from './pages/ClientLogout';
import ForgotPassword from './pages/ForgotPassword';
import FavoritePrints from './pages/FavoritePrints';
import FavoritePresentations from './pages/FavoritePresentations';
import FavoriteAttendances from './pages/FavoriteAttendances';
import { Briefings } from './pages/Briefings/index.jsx';
import { BriefingProvider } from './hooks/useBriefing';
import { CreateBriefing } from './pages/CreateBriefing';
import { Briefing } from './pages/Briefing';
import { UploadTest } from './pages/PaginaTestUpload/UploadTest';
import Clients from './components/Clients';
import { CreateFabric } from './pages/CreateFabric/index';
import { CreateExternalDesigner } from './pages/CreateExternalDesigner';
import { EtiquetaPrint } from './pages/EtiquetaPrint';
import { Order } from './pages/Order';
import { CreateUserClient } from './pages/CreateUserClient';
import { Etiqueta } from './pages/EtiquetaPrint/Etiqueta';
import Qualities from './pages/Qualities';
import { CreateSupplier } from './pages/CreateSupplier';
import Suppliers from './pages/Suppliers';
import { PdfTagProvider } from './hooks/usePDFTag';
import { PDFTag } from './components/SupplierItens/EtiquetaPrint';

function App() {
  toast.configure();
  const updateInternetConnectionStatus = status => {
    // eslint-disable-next-line no-console

  };

  window.addEventListener('online', updateInternetConnectionStatus);
  window.addEventListener('offline', updateInternetConnectionStatus);

  return (
    <PdfTagProvider>
      <Router>
        {!window.location.pathname.includes('/client') &&
          !window.location.pathname.includes('/reset_password') &&
          !isLoggedIn() && (<Redirect to="/login" />)}
        <Switch>
          <Route
            path="/login"
            render={() => (isLoggedIn() ? <Redirect to="/" /> : <Login />)}
          />
          <Route path="/reset_password/:forgotToken?">
            <ForgotPassword />
          </Route>
          <Route path="/(my-work|)">
            <HeaderComponent />
            <Works />
          </Route> 
          <Route path="/clients">
            <HeaderComponent />
            <Clients />
          </Route> 
          <Route path="/attendances/:attendanceId/presentations/:id">
            <HeaderComponent />
            <AttendancePresentation />
          </Route>
          <Route path="/attendances/:id/link-creation">
            <HeaderComponent />
            <LinkCreation />
          </Route>
          <Route path="/attendances/:id">
            <HeaderComponent />
            <Attendance />
          </Route>
          <Route path="/uploadarquivo">
            <HeaderComponent />
            <UploadTest />
          </Route>
          <Route path="/attendances">
            <HeaderComponent />
            <Attendances />
          </Route>
          <Route path="/presentations/:id">
            <HeaderComponent />
            <Presentation />
          </Route>
          <Route path="/presentations-debug/:id">
            <HeaderComponent />
            <Presentation debug />
          </Route>
          <Route path="/order/:id">
            <Order />
          </Route>
          <Route path="/presentations">
            <HeaderComponent />
            <Presentations />
          </Route>
          <Route path="/create-print">
            <HeaderComponent />
            <CreatePrint />
          </Route>
          <Route path="/print/:id">
            <HeaderComponent />
            <Print />
          </Route>

          <Route path="/library">
            <HeaderComponent />
            <Library />
          </Route>
          <Route path="/create-presentation">
            <HeaderComponent />
            <CreatePresentation />
          </Route>
          <Route path="/edit-presentation/:id">
            <HeaderComponent />
            <EditPresentation />
          </Route>
          <Route path="/create-attendance">
            <HeaderComponent />
            <CreateAttendance />
          </Route>
          <Route path="/edit-attendance/:id">
            <HeaderComponent />
            <EditAttendance />
          </Route>
          {/* <Route exact path="/briefings">
            <HeaderComponent />
            <Briefings />
          </Route> */}
          <Route exact path="/pdf/:id">
            <EtiquetaPrint />
          </Route>
          <Route exact path="/supplier/pdf">
            <PDFTag />
          </Route>
          <Route exact path="/etiqueta/:id">
            <Etiqueta />
          </Route>
          <Route exact path="/briefings">
            <BriefingProvider>
              <HeaderComponent />
              <Briefings />
            </BriefingProvider>
          </Route>

          <Route path="/create-briefing">
            <HeaderComponent />
            <CreateBriefing />
          </Route>
          

          <Route path="/briefings/:id">
            <HeaderComponent />
            <Briefing />
          </Route>
          <Route path="/register-client">
            <HeaderComponent />
            <RegisterClient />
          </Route>
          <Route path="/register-fabric">
            <HeaderComponent />
            <CreateFabric />
          </Route>

          <Route path="/register-supplier">
            <HeaderComponent />
            <CreateSupplier />
          </Route>

          <Route path="/quality/:id">
            <HeaderComponent />
            <CreateFabric />
          </Route>
          <Route path="/qualities">
            <HeaderComponent />
            <Qualities />
          </Route>
          <Route path="/supplier/:id">
            <HeaderComponent />
            <CreateSupplier />
          </Route>
          <Route path="/suppliers">
            <HeaderComponent />
            <Suppliers />
          </Route>
          <Route path="/register-designer">
            <HeaderComponent />
            <CreateExternalDesigner />
          </Route>
          <Route path="/register-user-client">
            <HeaderComponent />
            <CreateUserClient />
          </Route>
          <Route path="/search/:id">
            <HeaderComponent />
            <Search />
          </Route>
          <Route path="/print-customization/:id">
            <HeaderComponent />
            <PrintCustomization />
          </Route>
          <Route path="/favorite-prints">
            <HeaderComponent />
            <FavoritePrints />
          </Route>
          <Route path="/favorite-presentations">
            <HeaderComponent />
            <FavoritePresentations />
          </Route>
          <Route path="/favorite-attendances">
            <HeaderComponent />
            <FavoriteAttendances />
          </Route>
          <Route path="/create-pantone">
            <HeaderComponent />
            <CreatePantone />
          </Route>
          <Route path="/client/logout">
            <ClientLogout />
          </Route>

          <Route path="/client/:attendanceId/login">
            <ClientLogin />
          </Route>

          <Route path="/client/:attendanceId/presentations/:id/prints">
            <ClientHeaderComponent />
            <ClientPresentationPrints />
          </Route>

          <Route path="/client/:attendanceId/presentations/:id/concept">
            <ClientHeaderComponent />
            <ClientPresentationConcept />
          </Route>

          <Route path="/client/:id/prints">
            <ClientHeaderComponent />
            <ClientPresentationPrints />
          </Route>

          <Route path="/client/:id/concept">
            <ClientHeaderComponent />
            <ClientPresentationConcept />
          </Route>

          <Route path="/client/:id/cart">
            <ClientHeaderComponent />
            <ClientCart />
          </Route>

          <Route path="/client/:attendanceId">
            <ClientHeaderComponent />
            <ClientAttendance />
          </Route>
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </PdfTagProvider>
  );
}

export default App;
