import React from "react";
import customizedPrint from "../../../assets/images/estampa-customizada.jpg"
import { formatProductionType } from "../../../utils/formatText";
import styles from "./styles.module.scss"
import { formatDate } from "../../../utils/formatDate";
import { AiFillPrinter } from "react-icons/ai";
import { usePDFTag } from "../../../hooks/usePDFTag";

  export function PDFTag() {
    const { selectedPrintsContext } = usePDFTag();
    console.log(selectedPrintsContext)
    return (
      <main className={styles.container}>
        <section>
          {/* <h1>Order: {initialData?.order_number}</h1>
          <h1>Order Date: {formatDate(initialData?.date, true)}</h1>
          <h1>Client:{initialData?.client_alias}</h1>
          <h1>Area: {initialData?.area_alias}</h1> */}
          <button
            onClick={() => window.print()}
          >
            <AiFillPrinter size={30}/>
          </button>
        </section>
        {(selectedPrintsContext?.length === 0 || selectedPrintsContext === undefined) && (
          <p>Select prints before to generate the pdf.</p>
        )}
        {selectedPrintsContext && selectedPrintsContext?.map(po => (
            <section className={styles.productSection}  key={po?.product_id}>
              <img 
                src={po?.print_image_url ?? customizedPrint} 
                style={{
                  backgroundColor:po?.flat_background && po?.flat_background_color ? po?.flat_background_color : ""
                }}
              />
              <span>
                  <p className={styles.heading}>L CODE</p>
                  <p>{po?.print_code}</p>
              </span>
              <span>
                  <p className={styles.heading}>QUALITY</p>
                  <p>{po?.fabric_name ?? "N/A"}</p>
              </span>
              <span>
                  <p className={styles.heading}>METERS</p>
                  <p>{`${po?.meters}m`}</p>
              </span>
              <span>
                  <p className={styles.heading}>TYPE</p>
                  <p>{formatProductionType(po?.type)}</p>
              </span>
            </section>
        ))}
      </main>
    );
  }