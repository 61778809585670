import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import translate from '../../libs/i18n';
import { Search } from '../Search/index.jsx';
import { ListItems } from '../ListItems';
import { ModalCreateUser } from '../ModalCreateUser';
import { useHistory } from 'react-router-dom';
import Api from '../../libs/Api';
import Arrow from '../../assets/icons/Arrow';

export function ListUsersClient() {
    const [searchAgent, setSearchAgent] = useState('');
    const language = useSelector(state => state.settings.language);
    const [agentsFormModal, setAgentsFormModal] = useState(false);
    const history = useHistory();
    const [clientUsers, setClientUsers] = useState([]);
    const [filteredClientUsers, setFilteredClientUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentClientId, setCurrentClientId] = useState();
    
    useEffect(() => {
        if(currentClientId) {

        } else {
            Api.getUserIsClient().then(res => {
                const formattedClients = res.map(client => ({
                  id: client.id,
                  name: client.name,
                  client: client.client_name,
                  value: client.id
                }));
                
                setClientUsers(formattedClients);
                setFilteredClientUsers(formattedClients);
            });
        }
       
    }, []);

    useEffect(() => {
        const updatedClientUsers = [...clientUsers]
        if(searchAgent === "") {
            setFilteredClientUsers(updatedClientUsers);
        } else {
            const updatedFilteredClientUsers = updatedClientUsers?.filter(clientUser => clientUser?.name?.toLowerCase()?.includes(searchAgent?.toLowerCase()));
            setFilteredClientUsers(updatedFilteredClientUsers);
        }
    }, [searchAgent])
 
    return (
        <div className={styles.container}>
            <div className={styles.filterContainer}>
                <div className={styles.searchAgentContainer}>
                    <Search 
                        value={searchAgent}
                        placeholder={translate('searchFor', language)}
                        onChange={event => setSearchAgent(event.currentTarget.value)}
                    />
                </div>
                <button
                    className={styles.defaultRoundedActionButton}
                    type="button"
                    onClick={() => { setAgentsFormModal(!agentsFormModal) }}>
                    {translate('addNewUserClient', language)}
                </button>
            </div>
            <section className={styles.clientsList}>
                {filteredClientUsers.map(client => (
                    <button
                        key={client.id}
                        className={styles.clientItem}
                        type="button"
                        onClick={() => {
                            setCurrentClientId(client.id);
                            setAgentsFormModal(!agentsFormModal);
                        }}
                    >
                        {client.name}
                        <span>
                        {client.client}
                        </span>
                        <span className={styles.options}>
                        <span>
                            <Arrow />
                        </span>
                        </span>
                    </button>
                ))}
            </section>



            {/* {clientUsers.map(agente => (
                <ListItems id={agente.id} name={agente.name} />
            ))} */}
     
            {agentsFormModal &&
            (
                <ModalCreateUser closeForm={() => { setAgentsFormModal(!agentsFormModal) }}/>
            )
            }
        </div>
    )
}