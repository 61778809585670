import React from 'react';
import PropTypes from 'prop-types';

function List({ width, height, color, active, activeColor }) {
  return (
    <svg width={width} height={height} viewBox="0 0 51 51">
      <rect
        width="51"
        height="51"
        fill="#FFF"
        stroke={active ? activeColor : color}
        rx={5}
      />
      <path
        fill={active ? activeColor : color}
        d="M35 32a1 1 0 010 2H16a1 1 0 010-2h19zm0-5a1 1 0 010 2H16a1 1 0 010-2h19zm0-5a1 1 0 010 2H16a1 1 0 010-2h19zm0-5a1 1 0 010 2H16a1 1 0 010-2h19z"
      />
    </svg>
  );
}

List.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  activeColor: PropTypes.string,
  active: PropTypes.bool
};

List.defaultProps = {
  width: 41,
  height: 41,
  color: '#9BA7B7',
  activeColor: '#00C4C5',
  active: false
};

export default React.memo(List);
