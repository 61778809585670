import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';
import imagePlaceholder from '../assets/images/image_placeholder.png';
import whiteImagePlaceholder from '../assets/images/image_placeholder_white.png';
import ArrowIcon from '../assets/icons/Arrow';
import FormModal from './FormModal';
import FormPrints from './FormPrints';
import PrintGridItem from './PrintGridItem';
import ListSelector from './ListSelector';
import Loading from './Loading';
import EditIcon from '../assets/icons/Edit';
import PlusIcon from '../assets/icons/Plus';
import ConfirmationModal from './ConfirmationModal';
import PrintApproval from './PrintApproval';

import styles from './BriefingForm.module.scss';
import { FiUser } from 'react-icons/fi';
import { uploadNewFile } from '../libs/s3Upload';

function BriefingForm({ id, close }) {
  const dispatch = useDispatch();
  const [language] = useSelector(state => [state.settings.language]);
  const currentUser = useSelector(state => state.user);
  const referencesInput = useRef();
  const clientSelectorRef = useRef();
  const usersSelectorRef = useRef();
  const [clients, setClients] = useState([]);
  const [clientsSelector, setClientsSelector] = useState(false);
  const [briefingTitle, setBriefingTitle] = useState();
  const [briefingDescription, setBriefingDescription] = useState();
  const [briefingTechDescription, setBriefingTechDescription] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [references, setReferences] = useState([]);
  const [referencePreviews, setReferencePreviews] = useState([]);
  const [selectedPrints, setSelectedPrints] = useState([]);
  const [printsModal, setPrintsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [briefingCover, setBriefingCover] = useState();
  const [editingReferenceTitle, setEditingReferenceTitle] = useState();
  const [editingTitle, setEditingTitle] = useState('');

  const [invites, setInvites] = useState([]);
  const [deletingInvite, setDeletingInvite] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersSelector, setUsersSelector] = useState('closed');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [printApprovalId, setPrintApprovalId] = useState();
  const [referencesId, setReferencesId] = useState([]);

  const changeUsersSelector = state => {
    if (state === 'closed') {
      setUsersSelector('opened');
    } else {
      setUsersSelector('closed');
    }
  };

  useOutsideClick(usersSelectorRef, () => {
    changeUsersSelector(usersSelector);
  });

  useEffect(() => {
    setClientsSelector(false);
  }, [selectedClient]);

  useOutsideClick(clientSelectorRef, () => {
    setClientsSelector(false);
  });

  useEffect(() => {
    (async function loadClients() {
      try {
        const getClients = await Api.getClients();

        setClients(getClients);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();

    if (id) {
      setLoading(true);

      (async function loadBriefing() {
        try {
          const getBriefing = Api.getBriefing(id);
          const getUsers = Api.getUsers();
          const getInvites = Api.getBriefingInvites(id);

          await axios.all([getBriefing, getUsers, getInvites]).then(
            axios.spread(async (...responses) => {
              setSelectedClient(
                responses[0].client
                  ? { name: responses[0].client_name, id: responses[0].client }
                  : null
              );
              setBriefingTitle(responses[0].text || '');
              setBriefingDescription(responses[0].content || '');
              setBriefingTechDescription(responses[0].tech_desc || '');
              setSelectedPrints(responses[0].prints);
              setReferencePreviews(responses[0].visual_references);
              setBriefingCover(responses[0].cover_id);
              setUsers(responses[1]);
              setInvites(responses[2].results);
            })
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [dispatch, id]);


  const onSelectReferences = e => {
    const fileArray = Array.from(e.currentTarget.files);
    const newFilePreviews = [...referencePreviews];
    const numberOfFiles = Array.from(e.currentTarget.files).length;
    let counter = 0;

    const loadImagePreview = f => {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(f);

        reader.onloadend = () => {
          newFilePreviews.push({ name: f.name, src: reader.result });
          counter += 1;
          if (counter < numberOfFiles) {
            loadImagePreview(fileArray[counter]);
          } else {
            setReferencePreviews(newFilePreviews);
            const updatedFiles = [...references];
            setReferences([...updatedFiles, ...fileArray]);
          }
        };
      } catch (err) {
        // console.error(err);
      }
    };
    loadImagePreview(fileArray[counter]);
  };


  const onRemoveReference = (fileName, reference_id) => {
    setReferencePreviews(referencePreviews.filter(f => f.name !== fileName));
    setReferences(references.filter(f => f.name !== fileName));
    referencesInput.current.value = '';
  };

  const onRemoveReferenceById = refId => {
    setReferencePreviews(referencePreviews.filter(f => f.id !== refId));
    Api.deleteRerenceBriefings(refId);
  };

  const addSelectedPrints = prints => {
    const currentSelectedPrint = [...selectedPrints];

    prints.forEach(p => {
      if (!currentSelectedPrint.find(pres => pres.id === p.id)) {
        currentSelectedPrint.push(p);
      }
    });
    setSelectedPrints(currentSelectedPrint);
    setPrintsModal(false);
  };

  const removePrint = useCallback(
    printId => {
      const updatedPrints = selectedPrints.filter(p => p.id !== printId);
      setSelectedPrints(updatedPrints);
    },
    [selectedPrints]
  );

  const clearClient = useCallback(() => {
    setSelectedClient();
    setClientsSelector(false);
  }, []);

  const registerBriefing = useCallback(() => {
    setLoading(true);
    const creatingReferences = [];
    references.forEach((ref, index) => {
      const formData = new FormData();
      formData.append('image', ref);
      formData.append(
        'text',
        referencePreviews[index].name || referencePreviews[index].text || ''
      );

      const createVisualRefRequest = Api.createVisualReference(formData);
      creatingReferences.push(createVisualRefRequest);
    });

    const previousCreatedRefs = referencePreviews
      .filter(ref => ref.id)
      .map(ref => ref.id);

    axios
      .all(creatingReferences)
      .then(res => {
        const createdReferenceIds = res.map(ref => ref.id);
        let briefingCoverId = briefingCover;

        if (briefingCover && (!id || !Number.isInteger(briefingCover))) {
          const referenceCoverIndex = referencePreviews.findIndex(
            ref => ref.src === briefingCover
          );

          briefingCoverId = createdReferenceIds[referenceCoverIndex];
        }
        if (id) {
          Api.updateBriefing(id, {
            client: selectedClient ? selectedClient.id : null,
            text: briefingTitle,
            content: briefingDescription,
            tech_desc: briefingTechDescription,
            prints: selectedPrints.map(print => print.id),
            visual_references: [...createdReferenceIds, ...previousCreatedRefs],
            cover_id: briefingCoverId || null
          })
            .then(() => {
              setLoading(false);
              toast(translate('successUpdatingBriefing', language), {
                type: 'success'
              });
              close();
            })
            .catch(() => {
              setLoading(false);
              toast(translate('failUpdatingBriefing', language), {
                type: 'error'
              });
            });
        } else {
          Api.createBriefing({
            client: selectedClient ? selectedClient.id : null,
            text: briefingTitle,
            content: briefingDescription,
            tech_desc: briefingTechDescription,
            prints: selectedPrints.map(print => print.id),
            visual_references: createdReferenceIds,
            cover_id: briefingCoverId || null
          })
            .then(res => {
              setLoading(false);
              referencesId.map(reference_id => {
                Api.psdFileSuccessUpdate(reference_id, {
                  briefing_id: res.id
                })
              })
              toast(translate('successCreatingBriefing', language), {
                type: 'success'
              });
              close();
            })
            .catch(() => {
              setLoading(false);
              toast(translate('failCreatingBriefing', language), {
                type: 'error'
              });
            });
        }
      })
      .catch(() => {
        setLoading(false);
        toast(translate('failCreatingBriefing', language), {
          type: 'error'
        });
      });
  }, [
    selectedClient,
    briefingTitle,
    briefingDescription,
    briefingTechDescription,
    references,
    selectedPrints,
    language,
    briefingCover,
    referencePreviews,
    close,
    id
  ]);

  const setReferenceTitle = index => {
    const updatedReferencePreviews = [...referencePreviews];

    if (updatedReferencePreviews[index] && editingTitle) {
      if (updatedReferencePreviews[index].name) {
        updatedReferencePreviews[index].name = editingTitle;
      } else {
        updatedReferencePreviews[index].text = editingTitle;
      }
    }

    setEditingTitle('');
    setEditingReferenceTitle(null);
    setReferencePreviews(updatedReferencePreviews);
  };

  const getInvitedUser = userId => {
    try {
       return users.find(user => user.id == userId);
    } catch(err) {
      return ''
    }
  }

  const getNonInvitedUsers = useMemo(() => {
    let nonInvitedUsers = [...users];

    invites.forEach(invite => {
      nonInvitedUsers = nonInvitedUsers.filter(
        user => user.id !== invite.staff
      );
    });

    nonInvitedUsers = nonInvitedUsers.filter(
      user => user.id !== currentUser.id
    );

    return nonInvitedUsers;
  }, [currentUser, invites, users]);

  const inviteUsers = () => {
    const invitePosts = [];

    setUsersSelector('closed');
    setSelectedUsers([]);

    selectedUsers.forEach(user => {
      const invite = Api.inviteBriefing({ briefing: id, staff: user.id });

      invitePosts.push(invite);
    });

    axios
      .all(invitePosts)
      .then(() => {
        Api.getBriefingInvites(id).then(res => setInvites(res.results));
        toast(translate('successSendingInvites', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('failSendingInvites', language), {
          type: 'error'
        });
      });
  };

  const deleteInvite = () => {
    setLoading(true);

    Api.deleteBriefingInvite(deletingInvite)
      .then(() => {
        Api.getBriefingInvites(id).then(res => setInvites(res.results));
      })
      .catch(() => {
        toast(translate('failDeletingInvite', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setDeletingInvite(false);
        setLoading(false);
      });
  };

  return (
    <>
      <div className={styles.briefingFormModal}>
        <header>
          <button className={styles.closeButton} type="button" onClick={close}>
            <span className={styles.backIcon}>
              <ArrowIcon color="white" />
            </span>

            {translate('briefings', language)}
          </button>
          <div className={styles.social}>
            <ul className={styles.invitedUsers}>
              {invites.map(invite => (
                <li key={invite.id}>
                  <button
                    className={styles.delete}
                    type="button"
                    onClick={() => setDeletingInvite(invite.id)}
                  >
                    -
                  </button>
                  <div className={styles.imageContainer}>
                    {getInvitedUser(invite.staff).image ? (
                        <img
                        src={getInvitedUser(invite.staff).image}
                        alt={getInvitedUser(invite.staff).name}
                        title={getInvitedUser(invite.staff).name}
                      />) : <FiUser size={25} title={getInvitedUser(invite.staff).name}/>
                    }
                  </div>
                </li>
                
              ))}
              
            </ul>
            <div className={styles.userSelectorContainer}>
              <button
                type="button"
                className={styles.invite}
                onClick={() => setUsersSelector('opened')}
              >
                <PlusIcon color="#9BA7B7" />
              </button>
              {usersSelector === 'opened' && (
                <div className={styles.userSelector} ref={usersSelectorRef}>
                  <ListSelector
                    items={getNonInvitedUsers}
                    onSelect={setSelectedUsers}
                    selectLabel={translate('choose', language)}
                    multiple
                    values={selectedUsers}
                    search
                    action={inviteUsers}
                    actionText={translate('invite', language)}
                  />
                </div>
              )}
            </div>
          </div>
        </header>
        <div className={styles.briefingForm}>
          <form>
            <div className={styles.mainInfo}>
              <div className={styles.clientNameField} ref={clientSelectorRef}>
                <h3 className={styles.subtitle}>
                  {translate('client', language)}
                </h3>
                <div>
                  <button
                    id=""
                    type="button"
                    className={`${styles.defaultSelectInput} ${styles.selectInput}`}
                    onClick={() => setClientsSelector(true)}
                  >
                    {selectedClient
                      ? selectedClient.name
                      : translate('chooseClient', language)}
                  </button>

                  {clientsSelector === true && (
                    <span>
                      <ListSelector
                        label={translate('clients', language)}
                        selectLabel={translate('choose', language)}
                        items={clients}
                        onSelect={setSelectedClient}
                        value={selectedClient}
                        search
                        action={selectedClient ? clearClient : false}
                        actionText={translate('withoutClient', language)}
                      />
                    </span>
                  )}
                </div>
              </div>

              <input
                className={styles.briefingName}
                type="text"
                placeholder={translate('writeBriefingTitle', language)}
                value={briefingTitle}
                onChange={e => setBriefingTitle(e.currentTarget.value)}
              />
              <textarea
                className={styles.description}
                placeholder={translate('writeBriefingDescription', language)}
                value={briefingDescription}
                onChange={e => setBriefingDescription(e.currentTarget.value)}
              />

              <textarea
                className={styles.description}
                placeholder={translate('writeBriefingTechDescription', language)}
                value={briefingTechDescription}
                onChange={e => setBriefingTechDescription(e.currentTarget.value)}
              />
            <div className={styles.fileArea}>
                <div className={styles.fileInstructions}>
                  <div>
                    <div className={styles.instructionsTitle}>
                      {translate('addVisualReferences', language)}
                    </div>
                    <div>{translate('dragImages', language)}</div>
                  </div>
                  <div className={styles.uploadButton}>
                    {translate('uploadImages', language)}
                  </div>
                </div>
                <input
                  type="file"
                  ref={referencesInput}
                  multiple
                  onChange={onSelectReferences}
                />
                <hr />
                {referencePreviews.length === 0 && (
                  <div className={styles.referenceItem}>
                    <div className={styles.imageContainer}>
                      <img src={imagePlaceholder} alt="placeholder" />
                    </div>
                    <div className={styles.infoPlaceholder}>
                      <span className={styles.imageNamePlaceholder} />
                      <span className={styles.imageDetailsPlaceholder} />
                    </div>
                  </div>
                )}
              </div>

              {referencePreviews.map((ref, index) => (
                <div key={ref.name || ref.id} className={styles.referenceItem}>
                  <img
                    src={ref.src || ref.image_url}
                    alt={ref.name || ref.text}
                    className={styles.referenceImage}
                  />
                  <div className={styles.info}>
                    <span className={styles.imageName}>
                      {id && ref.id && (
                        <span className={styles.editingReferenceTitle}>
                          {ref.name || ref.text}
                        </span>
                      )}
                      {(!id || !ref.id) &&
                        (editingReferenceTitle === index ? (
                          <input
                            placeholder={ref.name || ref.text}
                            type="text"
                            value={editingTitle}
                            onChange={e =>
                              setEditingTitle(e.currentTarget.value)
                            }
                            onBlur={() => setReferenceTitle(index)}
                            onKeyUp={event => {
                              if (event.keyCode === 13) {
                                setReferenceTitle(index);
                              }
                            }}
                          />
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              setEditingReferenceTitle(index);
                              setEditingTitle(ref.name || ref.text);
                            }}
                            className={styles.editingReferenceTitle}
                          >
                            {ref.name || ref.text}
                            <span className={styles.iconContainer}>
                              <EditIcon />
                            </span>
                          </button>
                        ))}

                      {briefingCover &&
                      (briefingCover === ref.id ||
                        briefingCover === ref.src) ? (
                        <span className={styles.cover}>
                          {translate('cover', language)}
                        </span>
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            ref.id
                              ? setBriefingCover(ref.id)
                              : setBriefingCover(ref.src)
                          }
                          className={styles.selectCover}
                        >
                          {translate('defineAsCover', language)}
                        </button>
                      )}
                    </span>
                    <button
                      onClick={() =>
                        ref.id
                          ? onRemoveReferenceById(ref.id)
                          : onRemoveReference(ref.name)
                      }
                      type="button"
                      className={styles.delete}
                    >
                      {translate('delete', language)}
                    </button>
                  </div>
                </div>
              ))}
              <hr />
            </div>
            <div className={styles.printsContainer}>
              <div className={styles.containerHeader}>
                <span className={styles.printsTitle}>
                  {translate('prints', language)}
                </span>
                <button
                  className={styles.addPrints}
                  type="button"
                  onClick={() => setPrintsModal(true)}
                >
                  {translate('addPrints', language)}
                </button>
              </div>
              <div className={styles.printsGrid}>
                {selectedPrints && selectedPrints.length > 0 && (
                  <>
                    <div className={styles.column}>
                      {selectedPrints &&
                        selectedPrints.map((print, index) => {
                          if (index % 4 === 0) {
                            return (
                              <PrintGridItem
                                key={`${print.id}-${print.code}`}
                                id={print.id}
                                image={print.image_url || print.image}
                                code={print.code}
                                name={print.name}
                                deletePrint={removePrint}
                                newDesignPending={print.new_design_pending}
                                printApproval={() => setPrintApprovalId(print.id)}
                                
                              />
                            );
                          }
                          return null;
                        })}
                    </div>
                    <div className={styles.column}>
                      {selectedPrints &&
                        selectedPrints.map((print, index) => {
                          if (index % 4 === 1) {
                            return (
                              <PrintGridItem
                                key={`${print.id}-${print.code}`}
                                id={print.id}
                                image={print.image_url || print.image}
                                code={print.code}
                                name={print.name}
                                deletePrint={removePrint}
                                newDesignPending={print.new_design_pending}
                                printApproval={() => setPrintApprovalId(print.id)}
                              />
                            );
                          }
                          return null;
                        })}
                    </div>
                    
                    <div className={styles.column}>
                      {selectedPrints &&
                        selectedPrints.map((print, index) => {
                          if (index % 4 === 2) {
                            return (
                              <PrintGridItem
                                key={`${print.id}-${print.code}`}
                                id={print.id}
                                image={print.image_url || print.image}
                                code={print.code}
                                name={print.name}
                                deletePrint={removePrint}
                                newDesignPending={print.new_design_pending}
                                printApproval={() => setPrintApprovalId(print.id)}
                              />
                            );
                          }
                          return null;
                        })}
                    </div>
                    <div className={styles.column}>
                      {selectedPrints &&
                        selectedPrints.map((print, index) => {
                          if (index % 4 === 3) {
                            return (
                              <PrintGridItem
                                key={`${print.id}-${print.code}`}
                                id={print.id}
                                image={print.image_url || print.image}
                                code={print.code}
                                name={print.name}
                                deletePrint={removePrint}
                                newDesignPending={print.new_design_pending}
                                printApproval={() => setPrintApprovalId(print.id)}
                              />
                            );
                          }
                          return null;
                        })}
                    </div>
                    {printApprovalId && (
                      <PrintApproval
                        id={printApprovalId}
                        close={() => setPrintApprovalId(null)}
                      />
                    )}
                  </>
                )}
                {selectedPrints.length === 0 && (
                  <>
                    <div className={styles.cardPlaceholder}>
                      <div className={styles.imageContainer}>
                        <img src={whiteImagePlaceholder} alt="placeholder" />
                      </div>
                      <span className={styles.title} />
                    </div>
                    <div className={styles.cardPlaceholder}>
                      <div className={styles.imageContainer}>
                        <img src={whiteImagePlaceholder} alt="placeholder" />
                      </div>
                      <span className={styles.title} />
                    </div>
                    <div className={styles.cardPlaceholder}>
                      <div className={styles.imageContainer}>
                        <img src={whiteImagePlaceholder} alt="placeholder" />
                      </div>
                      <span className={styles.title} />
                    </div>
                    <div className={styles.cardPlaceholder}>
                      <div className={styles.imageContainer}>
                        <img src={whiteImagePlaceholder} alt="placeholder" />
                      </div>
                      <span className={styles.title} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
          <div className={styles.saveContainer}>
            <button
              type="button"
              className={`${styles.defaultRoundedActionButton} ${styles.save}`}
              onClick={registerBriefing}
              disabled={!briefingTitle}
            >
              {id
                ? translate('updateBriefing', language)
                : translate('sendBriefing', language)}
            </button>
          </div>
        </div>
        {deletingInvite && (
          <ConfirmationModal
            title={translate('inviteDelete', language)}
            message={translate('inviteDeletingBriefingMessage', language)}
            confirmText={translate('yes', language)}
            cancelText={translate('no', language)}
            onConfirm={deleteInvite}
            onCancel={() => setDeletingInvite(false)}
            deleteWarning
          />
        )}
      </div>
      {printsModal && (
        <FormModal onCancel={() => setPrintsModal(false)} spaced>
          <FormPrints applyPrints={addSelectedPrints} />
        </FormModal>
      )}
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </>
  );
}

BriefingForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
};

export default BriefingForm;
