import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import axios from 'axios';

import translate from '../libs/i18n';
import styles from './Feedback.module.scss';
import useOutsideClick from '../libs/useOutsideClick';

import Api from '../libs/Api';

import MessageIcon from '../assets/icons/Message';
import ClockIcon from '../assets/icons/Clock';
import CloseIcon from '../assets/icons/Close';
import Loading from './Loading';

function Feedback({
  mini,
  close,
  openChat,
  printId,
  update,
  newFeedback,
  editable
}) {
  const chatRef = useRef();

  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);

  const [feedbacks, setFeedBacks] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);

  useOutsideClick(chatRef, () => {
    close();
  });

  useEffect(() => {
    if (printId) {
      setLoading(true);
      Api.getFeedbacks(printId)
        .then(res => {
          const orderedFeedbacks = res.results.sort((a, b) => a.date - b.date);
          setFeedBacks(orderedFeedbacks);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [printId]);

  useEffect(() => {
    if (mini && update && printId) {
      Api.getFeedbacks(printId).then(res => {
        const orderedFeedbacks = res.results.sort((a, b) => a.date - b.date);
        setFeedBacks(orderedFeedbacks);
      });
    }
  }, [mini, printId, update]);

  const numberUnreadMessages = useMemo(() => {
    return feedbacks.filter(
      feedback => feedback.sender !== user.id && feedback.unread
    ).length;
  }, [feedbacks, user.id]);

  const readMessages = async () => {
    const unreadFeedbacks = feedbacks.filter(
      feedback => feedback.sender !== user.id && feedback.unread
    );
    const formData = new FormData();
    formData.append('unread', false);
    const feedbackRequests = [];

    unreadFeedbacks.forEach(feedback => {
      const request = Api.readFeedback(feedback.id, formData);
      feedbackRequests.push(request);
    });

    await axios.all(feedbackRequests).then(() => {
      Api.getFeedbacks(printId).then(res => {
        const orderedFeedbacks = res.results.sort((a, b) => a.date - b.date);
        setFeedBacks(orderedFeedbacks);
      });
    });
    openChat();
  };

  const deleteFeedback = id => {
    Api.deleteFeedback(id).then(() => {
      Api.getFeedbacks(printId).then(res => {
        const orderedFeedbacks = res.results.sort((a, b) => a.date - b.date);
        setFeedBacks(orderedFeedbacks);
      });
    });
  };

  const getMessage = (feedback, direction, lastOne) => (
    <div>
      <div
        className={`${styles.info} ${direction === 'right' && styles.inverse}`}
      >
        <div className={styles.name}>{feedback.sender_name}</div>
        <div className={styles.time}>
          <ClockIcon />
          <Moment
            filter={d => d.charAt(0).toUpperCase() + d.slice(1)}
            styles={styles.textTime}
            fromNow
            locale={language}
          >
            {feedback.date}
          </Moment>
        </div>
      </div>
      <p
        className={`${styles.message} ${direction === 'right' &&
          styles.inverse}`}
      >
        {feedback.text}
      </p>
      {direction === 'right' && lastOne && editable && (
        <div className={styles.deleteContainer}>
          <button type="button" onClick={() => deleteFeedback(feedback.id)}>
            {translate('delete', language)}
          </button>
        </div>
      )}
    </div>
  );

  const sendMessage = () => {
    if (newMessage) {
      setSending(true);
      Api.sendFeedback(printId, user.id, newMessage)
        .then(() => {
          setNewMessage('');
        })
        .catch(() => {})
        .finally(() => {
          Api.getFeedbacks(printId).then(res => {
            const orderedFeedbacks = res.results.sort(
              (a, b) => a.date - b.date
            );
            setFeedBacks(orderedFeedbacks);
            setSending(false);
          });
          if (newFeedback) {
            newFeedback();
          }
        });
    }
  };

  const getMinified = () => (
    <div className={styles.minifiedContainer}>
      <div className={styles.header}>
        <button
          className={styles.messageButton}
          type="button"
          onClick={readMessages}
        >
          <MessageIcon />
          {numberUnreadMessages > 0 && (
            <span className={styles.messageIndicator}>
              {numberUnreadMessages}
            </span>
          )}
        </button>
      </div>
    </div>
  );

  const getChat = () => (
    <div className={styles.overlay}>
      <div className={styles.chatContainer} ref={chatRef}>
        <button className={styles.closeButton} type="button" onClick={close}>
          <CloseIcon color="black" />
        </button>
        <div className={styles.title}>Feedback</div>
        {editable && (
          <div
            className={`${styles.inputContainer} ${
              sending ? styles.disabled : ''
            }`}
          >
            <textarea
              value={newMessage}
              onChange={e => setNewMessage(e.currentTarget.value)}
              type="text"
              disabled={sending}
            />
            <button onClick={sendMessage} type="button">
              {sending ? 'Enviando' : 'Enviar'}
            </button>
          </div>
        )}

        <div className={styles.dialog}>
          {feedbacks.map((feedback, index) =>
            getMessage(
              feedback,
              feedback.sender === user.id ? 'right' : 'left',
              index === 0
            )
          )}
        </div>
      </div>
      {loading && <Loading fixed />}
    </div>
  );

  return mini ? getMinified() : getChat();
}

Feedback.propTypes = {
  mini: PropTypes.bool,
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  openChat: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  printId: PropTypes.number.isRequired,
  editable: PropTypes.bool
};

Feedback.defaultProps = {
  mini: false,
  close: false,
  openChat: false,
  editable: false
};

export default Feedback;
