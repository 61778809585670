import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for we
import { Provider } from 'react-redux';
import user from './store/reducers/user';
import settings from './store/reducers/settings';
import prints from './store/reducers/prints';
import tags from './store/reducers/tags';
import clients from './store/reducers/clients';
import collections from './store/reducers/collections';
import artFinalists from './store/reducers/artFinalists';
import presentations from './store/reducers/presentations';
import loggedClient from './store/reducers/loggedClient';
import carts from './store/reducers/carts';
import clientCart from './store/reducers/clientCart';
import searchLibrary from './store/reducers/searchLibrary';
import libraryFilters from './store/reducers/libraryFilters';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  user,
  settings,
  prints,
  tags,
  collections,
  clients,
  artFinalists,
  presentations,
  loggedClient,
  carts,
  clientCart,
  searchLibrary,
  libraryFilters
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
