/* eslint-disable no-param-reassign */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import CurrencyInput from 'react-currency-input';
import { FiDownload } from 'react-icons/fi';

import translate from '../libs/i18n';
import useOutsideClick from '../libs/useOutsideClick';
import Api from '../libs/Api';
import {
  updateProductOnCart,
  removeProductOnCart,
  clearClientCart
} from '../store/actions/carts';
import styles from './OrderCart.module.scss';
import CloseIcon from '../assets/icons/Close';
import ListSelector from './ListSelector';
import TrashCanIcon from '../assets/icons/TrashCan';
import ArrowIcon from '../assets/icons/Arrow';
import CheckInput from './CheckInput';
import PurchaseSetExclusivity from './PurchaseSetExclusivity';
import Sale from './Sale';
import Loading from './Loading';
import { SingleDatePicker } from './DatePicker';
import { formatISO, parseISO } from 'date-fns'
import { AiOutlineLink } from 'react-icons/ai';
import ConfirmationModal from './ConfirmationModal';

function OrderCart({ close, currentOrder }) {
  const language = useSelector(state => state.settings.language);
  const clientCarts = useSelector(state => state.carts);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [fabrics, setFabrics] = useState([]);
  const [clientOrders, setClientOrders] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [productsSelector, setProductsSelector] = useState(false);
  const [selectedProduct] = useState();
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [currencySelector, setCurrencySelector] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedAgreedDate, setSelectedAgreedDate] = useState();
  const [
    productionProductsCheckboxes,
    setProductionProductsCheckboxes
  ] = useState([]);
  const [reserveProductsCheckboxes, setReserveProductsCheckboxes] = useState([]);
  const [hangerProductsCheckboxes, setHangerProductsCheckboxes] = useState([]);
  const [strikeProductsCheckboxes, setStrikeProductsCheckboxes] = useState([]);
  const [noClassifiedProductsCheckboxes, setNoClassifiedProductsCheckboxes] = useState([]);
  const [productionProductsSelectRevision, setProductionProductsSelectRevision] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedProductionType, setSelectedProductionType] = useState();
  const [meters, setMeters] = useState();
  const [supplierNumber, setSupplierNumber] = useState();
  const [currentMeters, setCurrentMeters] = useState();
  const [repetition, setRepetition] = useState(false);
  const [simple, setSimple] = useState(false);
  const [priority, setPriority] = useState(false);
  const [clientProducts, setClientProducts] = useState([]);
  const [exclusivityModal, setExclusivityModal] = useState(false);
  const [fabricsModal, setFabricsModal] = useState(false);
  const [fabricQuery, setFabricQuery] = useState();
  const [fabricOptions, setFabricOptions] = useState([]);
  const productsSelectorRef = useRef();
  const currencySelectorRef = useRef();
  const singleSupplierSelectorRef = useRef();
  const singleTechniqueSelectorRef = useRef();
  const singleServiceSelectorRef = useRef();
  const singleFabricSupplierSelectorRef = useRef();
  const suppliersSelectorRef = useRef();
  const serviceSelectorRef = useRef();
  const techniqueSelectorRef = useRef();
  const supplierFabricsSelectorRef = useRef();
  const [currentTrackingLink, setCurrentTrackingLink] = useState("")
  const [editingProduct, setEditingProduct] = useState();
  const [editingSingleMeters, setEditingSingleMeters] = useState(false);
  const [editingSingleTrackingLink, setEditingSingleTrackingLink] = useState(false);
  const [editingSingleSupplier, setEditingSingleSupplier] = useState(false);
  const [editingSingleService, setEditingSingleService] = useState(false);
  const [editingSingleSupplierFabric, setEditingSingleSupplierFabric] = useState(false);
  const [editingSingleTechnique, setEditingSingleTechnique] = useState(false);
  const [editingSuppliers, setEditingSuppliers] = useState(false);
  const [editingServices, setEditingServices] = useState(false);
  const [editingTechniques, setEditingTechniques] = useState(false);
  const [editingSuppliersFabrics, setEditingSuppliersFabrics] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [selectedTechnique, setSelectedTechnique] = useState();
  const [editingTrackingLink, setEditingTrackingLink] = useState(false);
  const [editingSinglePrice, setEditingSinglePrice] = useState(false);
  const [currentPrice, setCurrentPrice] = useState();
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [seeLinks, setSeeLinks] = useState(false);
  const [countries, setCountries] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [supplierFabrics, setSupplierFabrics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saleModal, setSaleModal] = useState(false);
  const [saleInfo, setSaleInfo] = useState();
  const [discount, setDiscount] = useState();
  const [serviceOptions, setServiceOptions] = useState([]);
  const [servicePage, setServicePage] = useState(1);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const defaultCurrencyValue = 'brl';
  const productionTypeOptions = [
    {
      id: 1,
      value: 'PRODUCAO',
      name: 'production'
    },
    {
      id: 2,
      value: 'PILOTO',
      name: 'strike'
    },
    {
      id: 3,
      value: 'HANGER',
      name: 'hanger'
    },
    {
      id: 4,
      value: 'RESERVA',
      name: 'reserve'
    }
  ];
  const techniqueOptions = [
    {
      id: 1,
      value: 'A',
      name: 'Crinkle'
    },
    {
      id: 2,
      value: 'B',
      name: 'Devore'
    },
    {
      id: 3,
      value: 'C',
      name: 'Flock'
    },
    {
      id: 4,
      value: 'C',
      name: 'Fluor'
    },
    {
      id: 5,
      value: 'C',
      name: 'Foil'
    },
    {
      id: 6,
      value: 'C',
      name: 'Laca'
    },
    {
      id: 7,
      value: 'C',
      name: 'Metallic ink'
    },
    {
      id: 8,
      value: 'C',
      name: 'Pleated'
    },
    {
      id: 9,
      value: 'C',
      name: 'Puff'
    },
  ]

  useEffect(() => {
    if(currentOrder.id === undefined || currentOrder.id === "") return
    setSelectedOrderId(currentOrder.id);
    setPriority(currentOrder.is_priority);
    Api.getClients().then(res => {
      const currentClient = res.find(c => c.id === currentOrder.client);
      setSelectedClient(currentClient);
    });
    Api.getServices().then(res => {
      const formattedArr = res?.map(service => {
        return {
          id: service.id,
          value: service.code,
          name: service.name,
        }
      })
      setServiceOptions(formattedArr)
    })

    Api.getOrder(currentOrder.id)
      .then(ord => {
        const productSetFormatted = ord?.productorder_set?.map(po => {
          return {
            ...po,
            supplier: po?.supplier ?? null
          }
        })

        const ordObject = {
          ...ord,
          productorder_set: productSetFormatted,
        }
        setClientOrders([ordObject]);
      })
      .finally(() => {
        setLoading(false);
      });
      
  }, [currentOrder]);
  useEffect(() => {
    const getFabrics = Api.getFabrics().then(res => {
      setFabrics(res.results);
    });
    const getRegion = Api.getRegion().then(res => {
      setRegions(res);
    });
    const getCountries = Api.getCountries().then(res => {
      setCountries(res);
    });
    const getSuppliers = Api.getSupplier('type=s,b').then((res) => {
      if(res?.results) {
        const formattedData = res?.results?.map((supplier) => ({
          name: supplier.name || '',
          value: supplier.id || '',
          id: supplier.id || ''
        }));
        setSuppliers(formattedData);
      }
    })
    const getSuppliersFabrics = Api.getSupplierFabric().then((res) => {
      if(res) {
        const formattedData = res?.map((supplier) => ({
          name: supplier.name || '',
          value: supplier.id || '',
          id: supplier.supplier || '',
          fabric_id: supplier.fabric || ''
        }));
        setSupplierFabrics(formattedData);
      }
    })
    axios.all([getFabrics, getRegion, getCountries, getSuppliers, getSuppliersFabrics]).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setProductsSelector(false);
  }, [selectedProduct]);

  useOutsideClick(productsSelectorRef, () => {
    setProductsSelector(false);
  });

  useOutsideClick(suppliersSelectorRef, () => {
    setEditingSuppliers(false);
  });

  useOutsideClick(singleFabricSupplierSelectorRef, () => {
    setEditingSingleSupplierFabric(false);
  });

  useOutsideClick(singleServiceSelectorRef, () => {
    setEditingSingleService(false);
  })
  useOutsideClick(singleTechniqueSelectorRef, () => {
    setEditingSingleTechnique(false);
  })
  useOutsideClick(serviceSelectorRef, () => {
    setEditingServices(false);
  });

  useOutsideClick(techniqueSelectorRef, () => {
    setEditingTechniques(false);
  });

  useOutsideClick(supplierFabricsSelectorRef, () => {
    setEditingSuppliersFabrics(false);
  });

  useOutsideClick(singleSupplierSelectorRef, () => {
    setEditingSingleSupplier(false);
  });

  useEffect(() => {
    setCurrencySelector(false);
  }, [selectedCurrency]);

  useOutsideClick(currencySelectorRef, () => {
    setCurrencySelector(false);
  });

  // useEffect(() => {
  //   if (selectedClient) {
  //     const allProducts = [...clientCarts];
  //     const filteredClientProducts = allProducts.filter(
  //       product => product.client === selectedClient.id
  //     );
  //     const updatedProds = [];
  //     filteredClientProducts.forEach((prod, index) => {
  //       const getPrint = Api.getMiniPrint(prod.print_id).then(print => {
  //         if (print && print.image) {
  //           filteredClientProducts[index].print_image_url = print.image;
  //         }
  //       });
  //       updatedProds.push(getPrint);
  //     });
  //     axios.all(updatedProds).then(() => {
  //       setClientProducts(filteredClientProducts);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedClient]);
  function compareFn(a, b) {
    if (a?.tracking_group > b?.tracking_group) {
      return 1
    } 
    if (a?.tracking_group < b?.tracking_group) {
      return -1;
    }
      return 0;
  }
  const getProductionProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => product.type === 'PRODUCAO'
    ).map(product => {
      return {
        ...product,
        tracking_url: product.tracking_url === null ? "": product.tracking_url
      }
    });
    const sorted = filtered.sort(compareFn)
    return sorted;
  }, [clientProducts]);

  const getReserveProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => product.type === 'RESERVA'
    ).map(product => {
      return {
        ...product,
        tracking_url: product.tracking_url === null ? "": product.tracking_url
      }
    });
    const sorted = filtered.sort(compareFn)
    return sorted;
  }, [clientProducts]);

  const getHangerProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => product.type === 'HANGER'
    ).map(product => {
      return {
        ...product,
        tracking_url: product.tracking_url === null ? "": product.tracking_url
      }
    });
    const sorted = filtered.sort(compareFn)
    return sorted;
  }, [clientProducts]);


  const getStrikeProducts = useMemo(() => {
    const filtered = clientProducts.filter(
      product => (product.type === 'PILOTO' || product.type === 'PILOTO_CLIENT')
    ).map(product => {
      return {
        ...product,
        tracking_url: product.tracking_url === null ? "": product.tracking_url
      }
    });
    const sorted = filtered.sort(compareFn)
      return sorted;
  }, [clientProducts]);

  const getNoClassifiedProducts = useMemo(() => {
    const filtered = clientProducts.filter(product => !product.type);
    const sorted = filtered.sort(compareFn)
    return sorted;
  }, [clientProducts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCheckedProducts = useCallback(() => {
    const checkedList = [];

    productionProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getProductionProducts[index]);
      }
    });

    hangerProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getHangerProducts[index]);
      }
    });

    strikeProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getStrikeProducts[index]);
      }
    });

    reserveProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getReserveProducts[index]);
      }
    });

    noClassifiedProductsCheckboxes.forEach((val, index) => {
      if (val) {
        checkedList.push(getNoClassifiedProducts[index]);
      }
    });
    return checkedList;
  });

  const clearCheckboxes = () => {
    let checks = [...noClassifiedProductsCheckboxes];
    getNoClassifiedProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setNoClassifiedProductsCheckboxes(checks);

    checks = [...productionProductsCheckboxes];
    getProductionProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setProductionProductsCheckboxes(checks);

    checks = [...reserveProductsCheckboxes];
    getReserveProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setReserveProductsCheckboxes(checks);

    checks = [...hangerProductsCheckboxes];
    getHangerProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setHangerProductsCheckboxes(checks);

    checks = [...strikeProductsCheckboxes];
    getStrikeProducts.forEach((check, index) => {
      checks[index] = false;
    });
    setStrikeProductsCheckboxes(checks);
  };

  useEffect(() => {
    if (selectedProductionType) {
      let checked = getCheckedProducts();
      checked = checked.map(product => ({
        ...product,
        type: selectedProductionType.value
      }));

      const allProducts = [...clientProducts];
      allProducts.forEach((prod, i) => {
        checked.forEach(ch => {
          if (prod.product_id === ch.product_id) {
            allProducts[i] = { ...ch };
            dispatch(updateProductOnCart(allProducts[i], selectedClient));
          }
        });
      });
      setClientProducts(allProducts);
      setSelectedProductionType();
      setProductsSelector(false);
      clearCheckboxes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientProducts,
    dispatch,
    getCheckedProducts,
    selectedClient,
    selectedProductionType
  ]);

  const modifyRepetitionSimple = (repetitionValue, simpleValue) => {
    setRepetition(repetitionValue);
    setSimple(simpleValue);

    let category = '';
    if (repetitionValue && simpleValue) {
      category = 'BOT';
    } else if (repetitionValue) {
      category = 'REP';
    } else if (simpleValue) {
      category = 'SIM';
    }

    let checked = getCheckedProducts();
    checked = checked.map(product => ({
      ...product,
      category
    }));

    const allProducts = [...clientProducts];
    allProducts.forEach((prod, i) => {
      checked.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          allProducts[i] = { ...ch };
        }
      });
    });
    setClientProducts(allProducts);
    setSelectedProductionType();
    setProductsSelector(false);
  };

  const modifyMeters = value => {
    setMeters(value);
    let checked = getCheckedProducts();
    checked = checked.map(product => ({
      ...product,
      meters: value
    }));

    const allProducts = [...clientProducts];
    allProducts.forEach((prod, i) => {
      checked.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          allProducts[i] = { ...ch };
        }
      });
    });
    setClientProducts(allProducts);
    setSelectedProductionType();
    setProductsSelector(false);
  };

  const finishModifyingMeters = () => {
    const checked = getCheckedProducts();
    checked.forEach(prod => {
      dispatch(updateProductOnCart(prod, selectedClient.id));
    });
  };

  const modifySupplierNumber = value => {
    setSupplierNumber(value);
    let checked = getCheckedProducts();
    const updatedProducts = [...clientProducts]
    updatedProducts.forEach((prod, i) => {
      checked.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          updatedProducts[i] = { ...ch,  supplier_number: value};
        }
      });
    });
    setClientProducts(updatedProducts);
  };

  const finishModifyingSupplierNumber = () => {
    const updatedProducts = [...clientProducts];
    updatedProducts.forEach(prod => {
      dispatch(updateProductOnCart(prod, selectedClient.id));
    });
  };

  const deleteProducts = () => {
    const selectedProducts = getCheckedProducts();
    let updatedProducts = [...clientProducts];
    selectedProducts.forEach(product => {
      if (product) {
        updatedProducts = updatedProducts.filter(
          p => p.product_id !== product.product_id
        );
        dispatch(removeProductOnCart(product.product_id, product.client));
      }
    });
    clearCheckboxes();
    setClientProducts(updatedProducts);
  };

  const deleteProductOrders = () => {
    const selectedProducts = getCheckedProducts();
    let updatedProducts = [...clientProducts];
    selectedProducts.forEach(product => {
      if (product) {
        updatedProducts = updatedProducts.filter(
          p => p.id !== product.id
        );
        setLoading(true);
        Api.deleteProductOrder(product.id).finally(() => {
          setLoading(false);
        });
      }
    });
    clearCheckboxes();
    setClientProducts(updatedProducts);
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    let checks = [...noClassifiedProductsCheckboxes];
    getNoClassifiedProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setNoClassifiedProductsCheckboxes(checks);

    checks = [...productionProductsCheckboxes];
    getProductionProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setProductionProductsCheckboxes(checks);

    checks = [...reserveProductsCheckboxes];
    getReserveProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setReserveProductsCheckboxes(checks);

    checks = [...hangerProductsCheckboxes];
    getHangerProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setHangerProductsCheckboxes(checks);

    checks = [...strikeProductsCheckboxes];
    getStrikeProducts.forEach((check, index) => {
      checks[index] = selectAll;
    });
    setStrikeProductsCheckboxes(checks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const getExclusivityVarValue = value => {
    switch (value) {
      case 'COR':
        return translate('color', language);
      case 'DES':
        return translate('drawing', language);
      default:
        return '';
    }
  };

  useEffect(() => {
    const getExclusivityName = value => {
      switch (value) {
        case 'INF':
          return translate('eternal', language);

        case 'MON':
          return translate('worldwide', language);

        case 'CON':
          return translate('continental', language);

        case 'NAT':
          return translate('national', language);

        case 'REG':
          return translate('regional', language);

        case 'MKT':
          return translate('laEstampaMarketing', language);

        default:
          return '';
      }
    };

    const getExclusivityInt = value => {
      const int = countries.find(c => c.id === value);
      if (int) {
        return int.name;
      }
      return value;
    };

    const getExclusivityCont = value => {
      switch (value) {
        case 'AF':
          return translate('africa', language);
        case 'AM':
          return translate('america', language);
        case 'AN':
          return translate('antarctica', language);
        case 'EU':
          return translate('europe', language);
        case 'AS':
          return translate('asia', language);
        case 'OC':
          return translate('oceania', language);
        default:
          return '';
      }
    };

    const getExclusivityReg = value => {
      const reg = regions.find(r => r.id === value);
      if (reg) {
        return reg.name;
      }
      return value;
    };
    if (selectedOrderId) {
      setLoading(true);
      Api.getOrder(selectedOrderId)
        .then(res => {
          const updatedOrders = [...clientOrders];
          const orderIndex = updatedOrders.findIndex(
            order => order.id === selectedOrderId
          );
          updatedOrders[orderIndex] = { ...res };

          const productOrderSet = res.productorder_set?.map(po => {
            return {
              ...po,
              supplier: po?.supplier ?? null
            }
          });

          if (res.discount) {
            setDiscount(parseFloat(res.discount));
          }

          const getExRequest = [];
          productOrderSet.forEach((po, index) => {
            if (po.print_id) {
              const exRequest = Api.getExclusivity(po.print_id).then(r => {
                productOrderSet[index].print_exclusivity = [];
                productOrderSet[index].print_exclusivity_int = [];
                productOrderSet[index].print_exclusivity_cont = [];
                productOrderSet[index].print_exclusivity_reg = [];
                r.results.forEach(ex => {
                  if (ex.exclusivity) {
                    productOrderSet[index].print_exclusivity.push({
                      value: ex.exclusivity,
                      name: getExclusivityName(ex.exclusivity)
                    });
                  }
                  if (ex.exclusivity_var) {
                    productOrderSet[index].print_exclusivity_var =
                      ex.exclusivity_var;
                  }
                  ex.exclusivity_int.forEach(int => {
                    productOrderSet[index].print_exclusivity_int.push({
                      value: int,
                      name: getExclusivityInt(int)
                    });
                  });
                  ex.exclusivity_cont.forEach(cont => {
                    productOrderSet[index].print_exclusivity_cont.push({
                      value: cont,
                      name: getExclusivityCont(cont)
                    });
                  });
                  ex.exclusivity_reg.forEach(reg => {
                    productOrderSet[index].print_exclusivity_int.push({
                      value: reg,
                      name: getExclusivityReg(reg)
                    });
                  });
                });
              });
              getExRequest.push(exRequest);
            }
          });
          axios.all(getExRequest).then(() => {
            setClientProducts([...productOrderSet]);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientOrders, countries, language, regions, selectedOrderId]);

  useEffect(() => {
    if (!selectedOrderId && selectedClient) {
      const allProducts = [...clientCarts];
      const filteredClientProducts = allProducts.filter(
        product => product.client === selectedClient.id
      );
      const updatedProds = [];
      filteredClientProducts.forEach((prod, index) => {
        const getPrint = Api.getMiniPrint(prod.print_id).then(print => {
          if (print && print.image_url) {
            filteredClientProducts[index].print_image_url = print.image;
          }
        });
        updatedProds.push(getPrint);
      });
      axios.all(updatedProds).then(() => {
        setClientProducts(filteredClientProducts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, clientCarts, selectedOrderId]);

  const getSectionTotal = products => {
    let total = 0;
    products.forEach(prod => {
      if (prod.price_per_meter && prod.meters) {
        total += parseFloat(prod.type === 'HANGER' ? prod.price_per_meter : (prod.price_per_meter * prod.meters));
      }
    });
    return total.toFixed(2);
  };

  const getSubtotal = () => {
    const productionTotal = parseFloat(getSectionTotal(getProductionProducts));
    const strikeTotal = parseFloat(getSectionTotal(getStrikeProducts));
    const hangerTotal = parseFloat(getSectionTotal(getHangerProducts));

    const total = productionTotal + strikeTotal + hangerTotal;

    return total;
  };

  const getIfAllAreReviewed = () => {
    const updatedProductOrders = [...clientProducts];
    const getReviewedValues = updatedProductOrders.filter(productOrder => productOrder.is_reviewed === true);
    if (getReviewedValues.length === updatedProductOrders.length) return true;
    return false;
  }

  const selectAllReviewed = ( value ) => {
    let checkedProducts = getCheckedProducts();
    const updatedProductOrders = [...clientProducts];

    updatedProductOrders.forEach((prod, i) => {
      checkedProducts.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          updatedProductOrders[i] = { ...ch,  is_reviewed: value};
          dispatch(updateProductOnCart(updatedProductOrders[i], selectedClient));
        }
      });
    });
    setClientProducts(updatedProductOrders)
  }
  
  const changeAllDate = ( date ) => {
    setSelectedDate(date);
    let checkedProducts = getCheckedProducts();
      checkedProducts = checkedProducts.map(product => ({
        ...product,
        date_delivery: formatISO(date , { representation: "date" })
      }));

      const allProducts = [...clientProducts];
      allProducts.forEach((prod, i) => {
        checkedProducts.forEach(ch => {
          if (prod.product_id === ch.product_id) {
            allProducts[i] = { ...ch };
            dispatch(updateProductOnCart(allProducts[i], selectedClient));
          }
        });
      });
    setClientProducts(allProducts)
  }

  const changeAllAgreedDate = ( date ) => {
    setSelectedAgreedDate(date);
    let checkedProducts = getCheckedProducts();
      checkedProducts = checkedProducts.map(product => ({
        ...product,
        agreed_date: formatISO(date , { representation: "complete" })
      }));

      const allProducts = [...clientProducts];
      allProducts.forEach((prod, i) => {
        checkedProducts.forEach(ch => {
          if (prod.product_id === ch.product_id) {
            allProducts[i] = { ...ch };
            dispatch(updateProductOnCart(allProducts[i], selectedClient));
          }
        });
      });
    setClientProducts(allProducts)
  }

  const getDiscountedSubtotal = () => {
    const productionTotal = parseFloat(getSectionTotal(getProductionProducts));
    const strikeTotal = parseFloat(getSectionTotal(getStrikeProducts));
    const hangerTotal = parseFloat(getSectionTotal(getHangerProducts));

    const total =
      productionTotal + strikeTotal + hangerTotal - parseFloat(discount || 0);

    return total;
  };

  const updateOrder = async(status) => {
    if (status === 'REP') {
      try {
        setLoading(true);
        const response = await Api.updateOrder(selectedOrderId, { status });
        setClientOrders([response]);
      } catch {

      } finally  {
        setLoading(false);
      }
      return
    }
    let updatedProductOrders;
    if (status === 'PROD') {
      updatedProductOrders = [...getCheckedProducts()];
    } else {
      updatedProductOrders = [...clientProducts];
    }
    if (updatedProductOrders.length > 0) {
      setLoading(true);
      let allRequiredFields = true;
      getCheckedProducts().forEach(product => {
        if (product?.type === 'PRODUCAO') {
          if (!product.meters) {
            toast(translate('setProductionMeters', language), {
              type: 'warning'
            });
            allRequiredFields = false;
            setLoading(false);
          }
          if (!product.fabric_id) {
            toast(translate('setProductionFabric', language), {
              type: 'warning'
            });
            setLoading(false);
            allRequiredFields = false;
          }
        }
        if (product?.type === 'PILOTO') {
          if (!product.meters) {
            toast(translate('setStrikeMeters', language), {
              type: 'warning'
            });
            setLoading(false);
            allRequiredFields = false;
          }
          if (!product.fabric_id) {
            toast(translate('setStrikeFabric', language), {
              type: 'warning'
            });
            setLoading(false);
            allRequiredFields = false;
          }
        }
      });

      if(allRequiredFields) {
        for (const updateProductOrder of updatedProductOrders) {
          const response = await Api.createProduct({
            ...updateProductOrder,
            fabric: updateProductOrder?.fabric_id ? updateProductOrder?.fabric_id : null,
            print: updateProductOrder.print_id
          })
          let newProducts;
          if(updateProductOrder.date_delivery) {
            newProducts = {
              ...updateProductOrder,
              meters: updateProductOrder.meters || 0,
              product: response.id,
              type: updateProductOrder.type,
              date_delivery: moment(updateProductOrder.date_delivery).format('YYYY-MM-DD'),
              price_per_meter: updateProductOrder.price_per_meter || 0,
              category: updateProductOrder.category || ''
            }
          } else {
            newProducts = {
              ...updateProductOrder,
              meters: updateProductOrder.meters || 0,
              product: response.id,
              type: updateProductOrder.type,
              price_per_meter: updateProductOrder.price_per_meter || 0,
              category: updateProductOrder.category || ''
            }
          }
          await Api.updateProductOrder(updateProductOrder.id, newProducts);
          const printExclusivities = await Api.getExclusivity(updateProductOrder.print_id).then(exc => { return exc.results });
          const deleteExclusivities = [];
          for (const ex of printExclusivities) {
            const deleteReq = await Api.deleteExclusivity(ex.id);
            deleteExclusivities.push(deleteReq);
          }
        }
        const exclusivities = [];
        const newProducts = [...getCheckedProducts()];
        if (selectedCurrency) {
          try {
            const total = getDiscountedSubtotal();
            await Api.updateOrder(selectedOrderId, {
              status,
              currency: selectedCurrency.value,
              total_price: total || 0,
              seller: user.id,
              discount: parseFloat(discount || 0),
              is_priority: priority,
            })
          } catch {
            setLoading(false);
          } finally {
            if(currentOrder?.status === "PROD") {
              Api.sendOrderTrakingLinkEmail(selectedOrderId)
            }
          }
        } else {
          try {
            await Api.updateOrder(selectedOrderId, {
              status,
              seller: user.id,
              is_priority: priority,
            })
          } catch {
            setLoading(false);
          } finally {
            if(currentOrder?.status === "PROD") {
              Api.sendOrderTrakingLinkEmail(selectedOrderId)
            }
          }
        }
        newProducts.forEach(p => {
          if (!exclusivities.find(e => e.print === p.print_id)) {
            if (
              p.type === 'PRODUCAO' &&
              Array.isArray(p.print_exclusivity)
            ) {
              p.print_exclusivity.forEach(ex => {
                if (ex.value === 'NAT') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_int: p.print_exclusivity_int.map(
                      item => item.id
                    ),
                    buyer: selectedClient.id
                  });
                }
                if (ex.value === 'REG') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_reg: p.print_exclusivity_reg.map(
                      item => item.id
                    ),
                    buyer: selectedClient.id
                  });
                }
                if (ex.value === 'CON') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_cont: p.print_exclusivity_cont.map(
                      item => item.value
                    ),
                    buyer: selectedClient.id
                  });
                }
                if (
                  ex.value !== 'NAT' &&
                  ex.value !== 'REG' &&
                  ex.value !== 'CON'
                ) {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity: ex.value,
                    buyer: selectedClient.id
                  });
                }
              });
            }
          }
          const createExclusivitiesReq = [];
          exclusivities.forEach(item => {
            const exclusivityReq = Api.createExclusivity(item);
            createExclusivitiesReq.push(exclusivityReq);
          });
          if (createExclusivitiesReq.length) {
            setLoading(true);
            axios.all(createExclusivitiesReq).then(() => {
              setLoading(false);
              if (status === 'PROD') {
                const total = getDiscountedSubtotal();
                setSaleInfo({
                  client: selectedClient,
                  order: selectedOrderId,
                  total,
                  currency: selectedCurrency
                    ? selectedCurrency.value
                    : defaultCurrencyValue
                });
                setSaleModal(true);
              } else {
                setLoading();
                Api.getOrder(selectedOrderId)
                  .then(ord => {
                    setClientOrders([ord]);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }
              dispatch(clearClientCart(clientProducts[0].client));
            });
          } else {
            const total = getDiscountedSubtotal();
            setSaleInfo({
              client: selectedClient,
              order: selectedOrderId,
              total,
              currency: selectedCurrency
                ? selectedCurrency.value
                : defaultCurrencyValue
            });
            dispatch(clearClientCart(clientProducts[0].client));
          }
        });
      }
      Api.getZipPsdDownload(selectedOrderId);
      window.location.reload();
    } else {
      setLoading(false)
    }
  }


  const updateOrder2 = status => {
    if (status === 'REP') {
      setLoading(true);
      Api.updateOrder(selectedOrderId, {
        status
      })
        .then(ord => {
          setClientOrders([ord]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let updatedProductOrders;
      if (status === 'PROD') {
        updatedProductOrders = [...getCheckedProducts()];
      } else {
        updatedProductOrders = [...clientProducts];
      }

      if (updatedProductOrders.length) {
        setLoading(true);
        let allRequiredFields = true;

        getCheckedProducts().forEach(product => {
          if (product?.type === 'PRODUCAO') {
            if (!product.meters) {
              toast(translate('setProductionMeters', language), {
                type: 'warning'
              });
              allRequiredFields = false;
              setLoading(false);
            }
            if (!product.fabric_id) {
              toast(translate('setProductionFabric', language), {
                type: 'warning'
              });
              setLoading(false);
              allRequiredFields = false;
            }
          }
          if (product?.type === 'PILOTO') {
            if (!product.meters) {
              toast(translate('setStrikeMeters', language), {
                type: 'warning'
              });
              setLoading(false);
              allRequiredFields = false;
            }
            if (!product.fabric_id) {
              toast(translate('setStrikeFabric', language), {
                type: 'warning'
              });
              setLoading(false);
              allRequiredFields = false;
            }
          }
        });
        if (allRequiredFields) {
          const updateRequests = [];
          updatedProductOrders.forEach(async (productOrder) => {
            const request = Api.createProduct({
              ...productOrder,
              fabric: productOrder.fabric_id ? productOrder.fabric_id : null,
              print: productOrder.print_id
            }).then(async res => {
              let newProducts;
              if(productOrder.date_delivery) {
                newProducts = {
                  ...productOrder,
                  meters: productOrder.meters || 0,
                  product: res.id,
                  type: productOrder.type,
                  date_delivery: moment(productOrder.date_delivery).format('YYYY-MM-DD'),
                  price_per_meter: productOrder.price_per_meter || 0,
                  category: productOrder.category || ''
                }
              } else {
                newProducts = {
                  ...productOrder,
                  meters: productOrder.meters || 0,
                  product: res.id,
                  type: productOrder.type,
                  price_per_meter: productOrder.price_per_meter || 0,
                  category: productOrder.category || ''
                }
              }
              await new Promise(resolve => resolve(Api.updateProductOrder(productOrder.id, newProducts)))
              setLoading(false)
              const printExclusivities = await Api.getExclusivity(
                productOrder.print_id
              ).then(exc => {
                return exc.results;
              });
              const deleteExclusivities = [];

              printExclusivities.forEach(ex => {
                const deleteReq = Api.deleteExclusivity(ex.id);
                deleteExclusivities.push(deleteReq);
              });

              await axios.all(deleteExclusivities).then(() => {
                const exclusivities = [];
                const newProducts = [...getCheckedProducts()];
                if (selectedCurrency) {
                  const total = getDiscountedSubtotal();
                  Api.updateOrder(selectedOrderId, {
                    status,
                    currency: selectedCurrency.value,
                    total_price: total || 0,
                    seller: user.id,
                    discount: parseFloat(discount || 0),
                    is_priority: priority,
                  }).catch(() => {
                    setLoading(false);
                  }).finally(() => {
                    if(status === "PROD") {
                      Api.sendOrderTrakingLinkEmail(selectedOrderId)
                    }
                  });
                } else {
                  Api.updateOrder(selectedOrderId, {
                    status,
                    seller: user.id,
                    is_priority: priority,
                  }).catch(() => {
                    setLoading(false);
                  }).finally(() => {
                    if(status === "PROD") {
                      Api.sendOrderTrakingLinkEmail(selectedOrderId)
                    }
                  });
                }

                newProducts.forEach(p => {
                  if (!exclusivities.find(e => e.print === p.print_id)) {
                    if (
                      p.type === 'PRODUCAO' &&
                      Array.isArray(p.print_exclusivity)
                    ) {
                      p.print_exclusivity.forEach(ex => {
                        if (ex.value === 'NAT') {
                          exclusivities.push({
                            print: p.print_id,
                            exclusivity: ex.value,
                            exclusivity_var: p.print_exclusivity_var,
                            exclusivity_int: p.print_exclusivity_int.map(
                              item => item.id
                            ),
                            buyer: selectedClient.id
                          });
                        }
                        if (ex.value === 'REG') {
                          exclusivities.push({
                            print: p.print_id,
                            exclusivity: ex.value,
                            exclusivity_var: p.print_exclusivity_var,
                            exclusivity_reg: p.print_exclusivity_reg.map(
                              item => item.id
                            ),
                            buyer: selectedClient.id
                          });
                        }
                        if (ex.value === 'CON') {
                          exclusivities.push({
                            print: p.print_id,
                            exclusivity: ex.value,
                            exclusivity_var: p.print_exclusivity_var,
                            exclusivity_cont: p.print_exclusivity_cont.map(
                              item => item.value
                            ),
                            buyer: selectedClient.id
                          });
                        }
                        if (
                          ex.value !== 'NAT' &&
                          ex.value !== 'REG' &&
                          ex.value !== 'CON'
                        ) {
                          exclusivities.push({
                            print: p.print_id,
                            exclusivity_var: p.print_exclusivity_var,
                            exclusivity: ex.value,
                            buyer: selectedClient.id
                          });
                        }
                      });
                    }
                    // if (p.type === 'PILOTO') {
                    //   exclusivities.push({
                    //     print: p.print_id,
                    //     exclusivity_var: 'BOT',
                    //     exclusivity: 'PIL',
                    //     buyer: selectedClient.id
                    //   });
                    // }
                  }
                  const createExclusivitiesReq = [];
                  exclusivities.forEach(item => {
                    const exclusivityReq = Api.createExclusivity(item);
                    createExclusivitiesReq.push(exclusivityReq);
                  });
                  if (createExclusivitiesReq.length) {
                    setLoading(true);
                    axios.all(createExclusivitiesReq).then(() => {
                      setLoading(false);
                      if (status === 'PROD') {
                        // setPurchaseSuccess(true);
                        const total = getDiscountedSubtotal();
                        setSaleInfo({
                          client: selectedClient,
                          order: selectedOrderId,
                          total,
                          currency: selectedCurrency
                            ? selectedCurrency.value
                            : defaultCurrencyValue
                        });
                        setSaleModal(true);
                      } else {
                        setLoading();
                        Api.getOrder(selectedOrderId)
                          .then(ord => {
                            setClientOrders([ord]);
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      }
                      dispatch(clearClientCart(clientProducts[0].client));
                    });
                  } else {
                    const total = getDiscountedSubtotal();
                    setSaleInfo({
                      client: selectedClient,
                      order: selectedOrderId,
                      total,
                      currency: selectedCurrency
                        ? selectedCurrency.value
                        : defaultCurrencyValue
                    });
                    // setSaleModal(true);
                    dispatch(clearClientCart(clientProducts[0].client));
                  }
                });
              });
            });
            updateRequests.push(request);
          });
          // axios.all(updateRequests).then(() => {
          
          // });
        }
        Api.getZipPsdDownload(selectedOrderId);
        if(currentOrder?.status === "OPEN") {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        setLoading(false);
      }
    }
  };
  // const saveDraft = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // };

  const totalItems = () => {
    return (getHangerProducts.length + getNoClassifiedProducts.length + getReserveProducts.length + getStrikeProducts.length + getProductionProducts.length)
  }

  const createOrder = status => {
    const exclusivities = [];
    const updatedProducts = [...getCheckedProducts()];
    if (updatedProducts.length) {
      let allRequiredFields = true;

      updatedProducts.forEach(product => {
        if (product.type === 'PRODUCAO') {
          if (!product.meters) {
            toast(translate('setProductionMeters', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
          if (!product.fabric_id) {
            toast(translate('setProductionFabric', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
        }
        if (product.type === 'PILOTO') {
          if (!product.meters) {
            toast(translate('setStrikeMeters', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
          if (!product.fabric_id) {
            toast(translate('setStrikeFabric', language), {
              type: 'warning'
            });
            allRequiredFields = false;
          }
        }
      });
      if (allRequiredFields) {
        setLoading(true);
        updatedProducts.forEach(p => {
          if (!exclusivities.find(e => e.print === p.print_id)) {
            if (p.type === 'PRODUCAO' && Array.isArray(p.print_exclusivity)) {
              p.print_exclusivity.forEach(ex => {
                if (ex.value === 'NAT') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_int: p.print_exclusivity_int.map(
                      item => item.id
                    ),
                    buyer: selectedClient.id
                  });
                }
                if (ex.value === 'REG') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_reg: p.print_exclusivity_reg.map(
                      item => item.id
                    ),
                    buyer: selectedClient.id
                  });
                }
                if (ex.value === 'CON') {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity: ex.value,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity_cont: p.print_exclusivity_cont.map(
                      item => item.value
                    ),
                    buyer: selectedClient.id
                  });
                }
                if (
                  ex.value !== 'NAT' &&
                  ex.value !== 'REG' &&
                  ex.value !== 'CON'
                ) {
                  exclusivities.push({
                    print: p.print_id,
                    exclusivity_var: p.print_exclusivity_var,
                    exclusivity: ex.value,
                    buyer: selectedClient.id
                  });
                }
              });
            }
            // if (p.type === 'PILOTO') {
            //   exclusivities.push({
            //     print: p.print_id,
            //     exclusivity_var: 'BOT',
            //     exclusivity: 'PIL',
            //     buyer: selectedClient.id
            //   });
            // }
          }
        });

        const updateProductsRequests = [];
        updatedProducts.forEach((cp, index) => {
          const formData = new FormData();
          if (cp.fabric_id) {
            formData.append('fabric', cp.fabric_id);
          }

          formData.append('print', cp.print_id);
          const update = Api.createProduct(formData)
            .then(res => {
              updatedProducts[index] = {
                ...updatedProducts[index],
                product_id: res.id
              };
            })
            .catch(() => {
              setLoading(false);
            });
          updateProductsRequests.push(update);
        });
        axios.all(updateProductsRequests).then(() => {
          Api.createOrder({
            client: clientProducts[0].client,
            customer_meeting: clientProducts[0].presentation,
            seller: user.id,
            status,
            currency: selectedCurrency
              ? selectedCurrency.value
              : defaultCurrencyValue,
            total_price: getDiscountedSubtotal() || 0,
            discount: parseFloat(discount || 0)
          })
            .then(res => {
              const orderId = res.id;
              const orders = [];
              updatedProducts.forEach(async (product) => {
                if (product.type) {
                  let newProducts;
                  if (product.date_delivery) {
                    newProducts = {
                      type: product.type,
                      meters: product.meters || 0,
                      order: orderId,
                      product: product.product_id,
                      category: product.category || '',
                      date_delivery: moment(product.date_delivery).format('YYYY-MM-DD'),
                      price_per_meter: clientProducts[0].price_per_meter
                    }
                  } else {
                    newProducts = {
                      type: product.type,
                      meters: product.meters || 0,
                      order: orderId,
                      product: product.product_id,
                      category: product.category || '',
                      price_per_meter: clientProducts[0].price_per_meter
                    }
                  }
                  const order = Api.createProductOrder(newProducts).catch(() => {
                    setLoading(false);
                  });
                  orders.push(order);
                  await new Promise(resolve => setTimeout(resolve, 2000))
                }
              });
              if (orders.length) {
                axios
                  .all(orders)
                  .then(() => {
                    const createExclusivitiesReq = [];
                    exclusivities.forEach(item => {
                      const exclusivityReq = Api.createExclusivity(item);
                      createExclusivitiesReq.push(exclusivityReq);
                    });
                    if (createExclusivitiesReq.length) {
                      axios.all(createExclusivitiesReq).then(() => {
                        toast(translate('createdOrdersSuccess', language), {
                          type: 'success'
                        });
                        if (status === 'PROD') {
                          setSaleInfo({
                            client: selectedClient,
                            order: selectedOrderId,
                            total: getDiscountedSubtotal() || 0,
                            currency: selectedCurrency
                              ? selectedCurrency.value
                              : defaultCurrencyValue
                          });
                          setSaleModal(true);
                          // setPurchaseSuccess(true);
                        }
                        dispatch(clearClientCart(clientProducts[0].client));
                      });
                    } else {
                      setSaleInfo({
                        client: selectedClient,
                        order: selectedOrderId,
                        total: getDiscountedSubtotal() || 0,
                        currency: selectedCurrency
                          ? selectedCurrency.value
                          : defaultCurrencyValue
                      });
                      setSaleModal(true);
                      dispatch(clearClientCart(clientProducts[0].client));
                    }
                  })
                  .catch(() => {
                    toast(translate('failCreatingOrders', language), {
                      type: 'error'
                    });
                    setLoading(false);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              } else {
                toast(translate('selectProductionType', language), {
                  type: 'error'
                });
                setLoading(false);
              }
            })
            .catch(() => {
              setLoading(false);
            });
        });
      }
    }
  };

  const manageOrder = status => {
    if (selectedOrderId) {
      updateOrder(status);
    } else {
      createOrder(status);
    }
  };

  const checkedList = getCheckedProducts();

  const openExclusivityModal = () => {
    if (getCheckedProducts().length) {
      setExclusivityModal(true);
    }
  };

  const applyExclusivity = exclusivityPurchase => {
    const exclusivity = [];
    const exclusivityInt = [];
    const exclusivityReg = [];
    const exclusivityCont = [];

    exclusivityPurchase.forEach(ep => {
      if (ep.exclusivity) {
        exclusivity.push(ep.exclusivity);
      }
      if (ep.exclusivity_int) {
        ep.exclusivity_int.forEach(e => {
          exclusivityInt.push(e);
        });
      }
      if (ep.exclusivity_reg) {
        ep.exclusivity_reg.forEach(e => {
          exclusivityReg.push(e);
        });
      }
      if (ep.exclusivity_cont) {
        ep.exclusivity_cont.forEach(e => {
          exclusivityCont.push(e);
        });
      }
    });
    const updatedProducts = [...clientProducts];
    if (editingProduct) {
      const productIndex = updatedProducts.findIndex(
        prod => prod.product_id === editingProduct
      );
      updatedProducts[productIndex] = {
        ...updatedProducts[productIndex],
        print_exclusivity: exclusivity,
        print_exclusivity_int: exclusivityInt,
        print_exclusivity_reg: exclusivityReg,
        print_exclusivity_cont: exclusivityCont,
        print_exclusivity_var: exclusivityPurchase[0]
          ? exclusivityPurchase[0].exclusivity_var
          : ''
      };
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    } else {
      const checkedProducts = getCheckedProducts();
      checkedProducts.forEach((cp, index) => {
        checkedProducts[index] = {
          ...checkedProducts[index],
          print_exclusivity: exclusivity,
          print_exclusivity_int: exclusivityInt,
          print_exclusivity_reg: exclusivityReg,
          print_exclusivity_cont: exclusivityCont,
          print_exclusivity_var: exclusivityPurchase[0]
            ? exclusivityPurchase[0].exclusivity_var
            : ''
        };
        dispatch(updateProductOnCart(checkedProducts[index], selectedClient));
      });
      updatedProducts.forEach((prod, i) => {
        checkedProducts.forEach(ch => {
          if (prod.product_id === ch.product_id) {
            updatedProducts[i] = { ...ch };
          }
        });
      });
    }
    setClientProducts(updatedProducts);
    setExclusivityModal(false);
    setEditingProduct();
  };

  const applyFabric = fabric => {
    const updatedProducts = [...clientProducts];
    const productIndex = updatedProducts.findIndex(
      prod => prod.product_id === editingProduct
    );

    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      fabric_id: fabric.id,
      fabric_name: fabric.name
    };

    dispatch(
      updateProductOnCart(updatedProducts[productIndex], selectedClient)
    );

    setClientProducts(updatedProducts);
    setFabricsModal(false);
    setEditingProduct();
  };

  const handleChangePrice = (event, value) => {
    setCurrentPrice(value);
  };

  const finishEditingTrackingLink= () => {
    const updatedProducts = [...clientProducts];

    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product_id === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      tracking_url: currentTrackingLink,
      is_sent_tracking_email: false
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentTrackingLink("");
    setEditingSingleTrackingLink(false);
  };

  const finishEditingMeters = () => {
    const updatedProducts = [...clientProducts];

    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product_id === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      meters: currentMeters
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentMeters();
    setEditingSingleMeters(false);
  };

  const finishEditingPrice = () => {
    const updatedProducts = [...clientProducts];

    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product_id === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      price_per_meter: currentPrice
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
  };

  const finishEditingSingleSupplierFabric = item => {
    const updatedProducts = [...clientProducts];
    
    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.id === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      attendance_fabric_supplier: item?.value
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingSingleService(false);
    setEditingSingleTechnique(false);
  };

  const finishEditingSingleService = item => {
    const updatedProducts = [...clientProducts];
    
    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.id === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      service: [item?.id]
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingSingleService(false);
    setEditingSingleTechnique(false);
  };

  const finishEditingSingleTechnique = item => {
    const updatedProducts = [...clientProducts];
    
    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.id === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      technique: item?.name
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingSingleService(false);
    setEditingSingleTechnique(false);
  };

  const finishEditingSingleSupplier = item => {
    const updatedProducts = [...clientProducts];
    
    let productIndex;
    if (!selectedOrderId) {
      productIndex = updatedProducts.findIndex(
        prod => prod.product === editingProduct
      );
    } else {
      productIndex = updatedProducts.findIndex(
        prod => prod.id === editingProduct
      );
    }
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      supplier: item?.id,
      supplier_name: item?.name
    };
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts[productIndex], selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingSingleService(false);
    setEditingSingleTechnique(false);
  };

  const finishEditingServices = (item) => {
    if (checkedList?.length === 0) return
    const updatedProducts = [...clientProducts];
    setSelectedService(item)
    const checkedProducts = checkedList?.map(product => ({
      ...product,
      service: [item.id]
    }));
    updatedProducts.forEach((prod, i) => {
      checkedProducts.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          updatedProducts[i] = { ...ch };
        }
      });
    });
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts, selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingServices(false);
  };

  const finishEditingTechniques = (item) => {
    if (checkedList?.length === 0) return
    const updatedProducts = [...clientProducts];
    setSelectedTechnique(item)
    const checkedProducts = checkedList?.map(product => ({
      ...product,
      technique: item?.name
    }));
    updatedProducts.forEach((prod, i) => {
      checkedProducts.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          updatedProducts[i] = { ...ch };
        }
      });
    });
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts, selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingTechniques(false);
  };

  const finishEditingMultipleTrackingLink = () => {
    if (checkedList?.length === 0) {
      setCurrentTrackingLink("");
      setEditingTrackingLink(false);
      return
    }
    const updatedProducts = [...clientProducts];
    const checkedProducts = checkedList?.map(product => ({
      ...product,
      tracking_url: currentTrackingLink,
      is_sent_tracking_email: false,
    }));
    updatedProducts.forEach((prod, i) => {
      checkedProducts.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          updatedProducts[i] = { ...ch };
        }
      });
    });
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts, selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingSuppliers(false);
    setEditingTrackingLink(false);
    setCurrentTrackingLink("");
  };

  const finishEditingSuppliers = (item) => {
    if (checkedList?.length === 0) return
    const updatedProducts = [...clientProducts];
    setSelectedSupplier(item)
    const checkedProducts = checkedList?.map(product => ({
      ...product,
      supplier_name: item?.name,
      supplier: item?.id
    }));
    updatedProducts.forEach((prod, i) => {
      checkedProducts.forEach(ch => {
        if (prod.product_id === ch.product_id) {
          updatedProducts[i] = { ...ch };
        }
      });
    });
    if (!selectedOrderId) {
      dispatch(
        updateProductOnCart(updatedProducts, selectedClient)
      );
    }
    setClientProducts(updatedProducts);
    setEditingProduct();
    setCurrentPrice();
    setEditingSinglePrice(false);
    setEditingSingleSupplier(false);
    setEditingSuppliers(false)
  };

  const getCurrencySymbol = useMemo(() => {
    if (selectedCurrency) {
      switch (selectedCurrency.value) {
        case 'brl':
          return 'R$';
        case 'usd':
          return '$';
        case 'eur':
          return '€';
        default:
          return '';
      }
    }
    return '';
  }, [selectedCurrency]);

  const formatedPrice = value => {
    if (selectedCurrency) {
      if (selectedCurrency) {
        switch (selectedCurrency.value) {
          case 'brl':
            return value.replace('.', ',');
          case 'eur':
            return value.replace('.', ',');
          case 'usd':
            return value;
          default:
            return value;
        }
      }
    }
    return value;
  };

  const closeSaleModal = () => {
    setSaleModal(false);
    setSaleInfo();
  };

  useEffect(() => {
    if (selectedOrderId) {
      const order = clientOrders.find(co => co.id === selectedOrderId);
      if (order) {
        // switch (order.currency) {
        //   case 'brl':
        //     setSelectedCurrency({
        //       id: 1,
        //       name: 'BRL - Real',
        //       value: 'brl'
        //     });
        //     break;
        //   case 'eur':
        //     setSelectedCurrency({
        //       id: 2,
        //       name: 'EUR - Euro',
        //       value: 'eur'
        //     });
        //     break;
        //   case 'usd':
        //     setSelectedCurrency({
        //       id: 3,
        //       name: 'USD - Dollar',
        //       value: 'usd'
        //     });
        //     break;
        //   default:
        //     break;
        // }
        setSelectedCurrency({
          id: 2,
          name: 'EUR - Euro',
          value: 'eur'
        });
      }
    } else {
      setSelectedCurrency({
        id: 2,
        name: 'EUR - Euro',
        value: 'eur'
      });
    }
  }, [clientOrders, selectedOrderId]);
  const renderMultipleTrackingLinkArea = () => (
    <div className={styles.linkTracking}>
      {!editingTrackingLink && (
        <button
          type="button"
          disabled={currentOrder.status === "FINA"}
          onClick={() => { setEditingTrackingLink(true) }}
        >
          <span>Link Tracking</span>
        </button>
      )}
      {editingTrackingLink && (
          <div className={styles.singleMetersInput}>
            <div>
              <input
                disabled={currentOrder.status === "FINA"}
                value={currentTrackingLink}
                onChange={e => setCurrentTrackingLink(e.currentTarget.value)}
                onKeyDown={e =>
                  e.key === 'Enter' ? finishEditingMultipleTrackingLink : false
                }
              />
              <span>Link Tracking</span>
            </div>
            <button type="button" onClick={finishEditingMultipleTrackingLink}>
              {translate('save', language)}
            </button>
          </div>
        )}
    </div>
  )

  const renderTrackingLinkArea = product => (
    <div className={styles.metersArea}>
      {!editingSingleTrackingLink && (
        <div>
          <button
            type="button"
            disabled={currentOrder.status === "FINA"}
            onClick={() => {
              if (product.product_id) {
                setEditingProduct(product.product_id);
              } else {
                setEditingProduct(product.product);
              }
              setEditingSingleTrackingLink(true);
              setCurrentTrackingLink(product.tracking_url);
            }}
          >
            <span>{product.tracking_url ? `${product.tracking_url}` : 'Link Tracking'}</span>
          </button>
          <a
            href={product.tracking_url?.includes('http') ? product.tracking_url : 'http://' + product.tracking_url}
            isDisabled={product.tracking_url === "" || product.tracking_url === null}
            target='_blank'
          >
            <AiOutlineLink />
          </a>
        </div>
      )}
      {((product.product_id && editingProduct === product.product_id) ||
        editingProduct === product.product) &&
        editingSingleTrackingLink && (
          <div className={styles.singleMetersInput}>
            <div>
              <input
                className={styles.meters}
                disabled={currentOrder.status === "FINA"}
                value={currentTrackingLink}
                onChange={e => setCurrentTrackingLink(e.currentTarget.value)}
                onKeyDown={e =>
                  e.key === 'Enter' ? finishEditingTrackingLink : false
                }
              />
              <span>Link Tracking</span>
            </div>
            <button type="button" onClick={finishEditingTrackingLink}>
              {translate('save', language)}
            </button>
          </div>
        )}
    </div>
  )
  const renderMetersArea = product => (
    <div className={styles.metersArea}>
      {!editingSingleMeters && (
        <button
          type="button"
          disabled={currentOrder.status === "FINA"}
          onClick={() => {
            if (product.product_id) {
              setEditingProduct(product.product_id);
            } else {
              setEditingProduct(product.product);
            }
            setEditingSingleMeters(true);
            setCurrentMeters(product.meters);
            setEditingSinglePrice(false);
          }}
        >
          <span>{product.meters ? `${product.meters}m` : '0m'}</span>
        </button>
      )}
      {((product.product_id && editingProduct === product.product_id) ||
        editingProduct === product.product) &&
        editingSingleMeters && (
          <div className={styles.singleMetersInput}>
            <div>
              <input
                type="number"
                className={styles.meters}
                disabled={currentOrder.status === "FINA"}
                value={currentMeters}
                onChange={e => setCurrentMeters(e.currentTarget.value)}
                onKeyDown={e =>
                  e.key === 'Enter' ? finishEditingMeters : false
                }
              />
              <span>{translate('meters', language)} </span>
            </div>
            <button type="button" onClick={finishEditingMeters}>
              {translate('save', language)}
            </button>
          </div>
        )}
    </div>
  );
  const renderEstimatedSupplierDateArea = product => (
    <div className={styles.supplierSection}>
      <div className={styles.estimatedSupplierDate}>
        <span>{`${translate('estimatedDateDelivery', language)}:`}</span>
        <SingleDatePicker 
          selectedDate={product.date_delivery ? parseISO(product.date_delivery) : null}
          setSelectedDate={(date) => {
              const updatedProducts = [...clientProducts];
              let productIndex;
              if (!selectedOrderId) {
                productIndex = updatedProducts.findIndex(
                  prod => prod.product_id === product.product_id
                );
              } else {
                productIndex = updatedProducts.findIndex(
                  prod => prod.product === product.product
                );
              }
              updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                date_delivery: formatISO(date , { representation: "date" })
              };
              setClientProducts(updatedProducts);
          }}
          minDate={new Date()}
          disabled={currentOrder.status === "FINA"}
        />
      </div>
      <div className={styles.estimatedSupplierDate}>
        <span>{`${translate('agreedDateDelivery', language)}:`}</span>
        <SingleDatePicker 
          selectedDate={product.agreed_date ? parseISO(product.agreed_date) : null}
          setSelectedDate={(date) => {
              const updatedProducts = [...clientProducts];
              let productIndex;
              if (!selectedOrderId) {
                productIndex = updatedProducts.findIndex(
                  prod => prod.product_id === product.product_id
                );
              } else {
                productIndex = updatedProducts.findIndex(
                  prod => prod.product === product.product
                );
              }
              updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                agreed_date: formatISO(date , { representation: "complete" })
              };
              setClientProducts(updatedProducts);
          }}
          minDate={new Date()}
          disabled={currentOrder.status === "FINA"}
        />
      </div>
      
      <div className={styles.estimatedSupplierDate}>
        <span>{`${translate('supplierNumber', language)}:`}</span>
        <input 
          value={product?.supplier_number}
          onChange={(event) => {
            const updatedProducts = [...clientProducts];
            let productIndex;
            if (!selectedOrderId) {
              productIndex = updatedProducts.findIndex(
                prod => prod.product_id === product.product_id
              );
            } else {
              productIndex = updatedProducts.findIndex(
                prod => prod.product === product.product
              );
            }
            updatedProducts[productIndex] = {
              ...updatedProducts[productIndex],
              supplier_number: event.currentTarget.value
            };
            setClientProducts(updatedProducts);
        }}
        disabled={currentOrder.status === "FINA"}
        />
      </div>
    </div>
  )

  const renderSelectFabricSupplier = product => {
    const supplierFabricsOptions = supplierFabrics.filter(sp => sp.fabric_id === product?.fabric_id) 
    return(
      <div
        className={styles.productionSelectorContainer}
        ref={singleFabricSupplierSelectorRef}
      >
        <span>{translate("selectedSupplierFabric", language)}</span>
        <button
          id=""
          type="button"
          disabled={currentOrder.status === "FINA" || supplierFabricsOptions?.length === 0}
          className={`${styles.defaultSelectInput} ${styles.production}`}
          onClick={() => {
            if (product.id) {
              setEditingProduct(product.id);
            } else {
              setEditingProduct(product.product);
            }
            setEditingSingleSupplierFabric(true)
          }}
        >
          {supplierFabricsOptions?.length > 0 ? supplierFabricsOptions?.filter(sp => sp?.value === product?.attendance_fabric_supplier)[0]?.name ?? translate('selectedSupplierFabric', language) : 'No Fabric options'}
        </button>
        {((product.product_id && editingProduct === product.id) ||
          editingProduct === product.product) && editingSingleSupplierFabric && (
          <span>
            <ListSelector
              selectLabel={translate('choose', language)}
              items={supplierFabricsOptions}
              onSelect={finishEditingSingleSupplierFabric}
              value={{name: supplierFabricsOptions?.filter(sp => sp?.value === product?.attendance_fabric_supplier)[0]?.name, id: 0, value: ''}}
            />
          </span>
        )}
      </div>
    )
  }

  const renderSelectService = product => (
    <div
      className={styles.productionSelectorContainer}
      ref={singleServiceSelectorRef}
    >
      <span>{translate("selectedService", language)}</span>
      <button
        id=""
        type="button"
        disabled={currentOrder.status === "FINA"}
        className={`${styles.defaultSelectInput} ${styles.production}`}
        onClick={() => {
          if (product.id) {
            setEditingProduct(product.id);
          } else {
            setEditingProduct(product.product);
          }
          setEditingSingleService(true)
        }}
      >
        {serviceOptions?.filter(service => service?.id === product?.service[0])[0]?.name ?? translate('selectedService', language)}
      </button>
      {((product.product_id && editingProduct === product.id) ||
        editingProduct === product.product) && editingSingleService && (
        <span>
          <ListSelector
            selectLabel={translate('choose', language)}
            items={serviceOptions}
            onSelect={finishEditingSingleService}
            value={{name: serviceOptions?.filter(service => service?.id === product?.service[0])[0]?.name, id: serviceOptions?.filter(service => service?.id === product?.service[0])[0]?.id, value: serviceOptions?.filter(service => service?.id === product?.service[0])[0]?.code}}
            lowWidth
          />
        </span>
      )}
    </div>
  )

  const renderSelectTechnique = (product) => (
    <div
      className={styles.productionSelectorContainer}
      ref={singleTechniqueSelectorRef}
    >
      <span>{translate("selectedTechnique", language)}</span>
      <button
        id=""
        type="button"
        disabled={currentOrder.status === "FINA"}
        className={`${styles.defaultSelectInput} ${styles.production}`}
        onClick={() => {
          if (product.id) {
            setEditingProduct(product.id);
          } else {
            setEditingProduct(product);
          }
          setEditingSingleTechnique(true)
        }}
      >
        {techniqueOptions?.filter(technique => technique?.name === product?.technique)[0]?.name ?? translate('selectedTechnique', language)}
      </button>
      {((product.id && editingProduct === product.id) ||
        editingProduct === product) && editingSingleTechnique && (
        <span>
          <ListSelector
            selectLabel={translate('choose', language)}
            items={techniqueOptions}
            onSelect={finishEditingSingleTechnique}
            value={{name: techniqueOptions?.filter(technique => technique?.name === product?.technique)[0]?.name, id: techniqueOptions?.filter(technique => technique?.name === product?.technique)[0]?.id, value: techniqueOptions?.filter(technique => technique?.name === product?.technique)[0]?.value}}
            remove
          />
        </span>
      )}
    </div>
  )

  const renderSelectSupplier = product => (
    <div
      className={styles.productionSelectorContainer}
      ref={singleSupplierSelectorRef}
    >
      <span>{translate("supplier", language)}</span>
      <button
        id=""
        type="button"
        disabled={currentOrder.status === "FINA"}
        className={`${styles.defaultSelectInput} ${styles.production}`}
        onClick={() => {
          if (product.id) {
            setEditingProduct(product.id);
          } else {
            setEditingProduct(product.product);
          }
          setEditingSingleSupplier(true)
        }}
      >
        {suppliers?.filter(supplier => supplier?.id === product?.supplier)[0]?.name ?? translate('selectedSupplier', language)}
      </button>
      {((product.id && editingProduct === product.id) ||
        editingProduct === product.product) && editingSingleSupplier && (
        <span>
          <ListSelector
            selectLabel={translate('choose', language)}
            items={suppliers}
            onSelect={finishEditingSingleSupplier}
            value={{name: suppliers?.filter(supplier => supplier?.id === product?.supplier)[0]?.name, id: product?.supplier, value: product?.supplier}}
          />
        </span>
      )}
    </div>
  )

  const renderObservation = product => (
    <div className={styles.productionSelectorContainer}>
      <span>Observações</span>
      <input
        className={`${styles.defaultSelectInput} ${styles.production}`}
        onBlur={finishModifyingSupplierNumber}
        disabled={currentOrder.status === "FINA"}
        value={product?.observation}
        onChange={(event) => {
          const updatedProducts = [...clientProducts];
          let productIndex;
          if (!selectedOrderId) {
            productIndex = updatedProducts.findIndex(
              prod => prod.product_id === product.product_id
            );
          } else {
            productIndex = updatedProducts.findIndex(
              prod => prod.product === product.product
            );
          }
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            observation: event.currentTarget.value
          };
          setClientProducts(updatedProducts);
        }}
      />
    </div>
  )

  const renderFabricFinishing = product => (
    <div className={styles.productionSelectorContainer}>
      <span>Acabamento</span>
      <input
        className={`${styles.defaultSelectInput} ${styles.production}`}
        onBlur={finishModifyingSupplierNumber}
        disabled={currentOrder.status === "FINA"}
        value={product?.fabric_finishing}
        onChange={(event) => {
          const updatedProducts = [...clientProducts];
          let productIndex;
          if (!selectedOrderId) {
            productIndex = updatedProducts.findIndex(
              prod => prod.product_id === product.product_id
            );
          } else {
            productIndex = updatedProducts.findIndex(
              prod => prod.product === product.product
            );
          }
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            fabric_finishing: event.currentTarget.value
          };
          setClientProducts(updatedProducts);
        }}
      />
    </div>
  )
 
  const renderPriceArea = product => (
    <div
      className={`${styles.priceArea} ${product.type === 'HANGER' &&
        styles.hangerPrice}`}
    >
      {!editingSinglePrice && (
        <button
          type="button"
          disabled={currentOrder.status === "FINA"}
          onClick={() => {
            if (product.product_id) {
              setEditingProduct(product.product_id);
            } else {
              setEditingProduct(product.product);
            }
            setCurrentPrice(product.price_per_meter);
            setEditingSinglePrice(true);
            setEditingSingleMeters(false);
          }}
        >
          <span>
            <div>
              {product.type === 'HANGER'
                ? translate('price', language)
                : translate('pricePerMeter', language)}
            </div>
            <div>
              {product.price_per_meter && product.price_per_meter !== '0.00'
                ? `${getCurrencySymbol}${formatedPrice(
                    parseFloat(product.price_per_meter).toFixed(2)
                  )}`
                : ''}
            </div>
          </span>
        </button>
      )}
      {((product.product_id && editingProduct === product.product_id) ||
        editingProduct === product.product) &&
        editingSinglePrice && (
          <div>
            <div>
              {product.type === 'HANGER'
                ? translate('price', language)
                : translate('pricePerMeter', language)}
              :
            </div>
            <div>
              {getCurrencySymbol}
              <CurrencyInput
                disabled={currentOrder.status === "FINA"}
                value={currentPrice}
                onChange={handleChangePrice}
                decimalSeparator={
                  selectedCurrency && selectedCurrency.value === 'usd'
                    ? '.'
                    : ','
                }
                thousandSeparator={
                  selectedCurrency && selectedCurrency.value === 'usd'
                    ? ','
                    : '.'
                }
              />
            </div>
            <button type="button" onClick={finishEditingPrice}>
              {translate('save', language)}
            </button>
          </div>
        )}
      {product.type !== 'HANGER' && (
        <div>
          <div>{translate('price', language)}:</div>
          <div>
            {product.price_per_meter &&
            product.price_per_meter !== '0.00' &&
            product.meters
              ? `${getCurrencySymbol}${formatedPrice(
                  parseFloat(product.price_per_meter * product.meters).toFixed(
                    2
                  )
                )}`
              : ''}
          </div>
        </div>
      )}
    </div>
  );

  const defineFabric = (productId, initialFabrics) => {
    setEditingProduct(productId);
    if (initialFabrics && initialFabrics.length) {
      const filtered = [];
      initialFabrics.forEach(f => {
        const fab = fabrics.find(item => item.id === f.id);
        if (fab) {
          filtered.push(fab);
        }
      });
      setFabricOptions([...filtered]);
    } else {
      setFabricOptions([...fabrics]);
    }

    setFabricsModal(true);
  };

  const defineExclusivity = productId => {
    setEditingProduct(productId);
    setExclusivityModal(true);
  };

  const returnToPresentation = () => {
    close();
  };

  const saleSuccess = () => {
    setSaleModal(false);
    setSaleInfo();
    setPurchaseSuccess(true);
  };

  const duplicateItems = async () => {
    const checkedItems = getCheckedProducts();
    if (checkedItems.length === 0) {
        toast(translate('selectPrintsToContinue', language), {
            type: 'warning'
        });
        return;
    }
    const allProducts = [...clientProducts];
    const promises = [];
    checkedItems.forEach((checkedItem) => {
        let updatedCheckedItem = {
            ...checkedItem,
            cart_id: allProducts.length,
            fabric_code: null
        };
        promises.push(Api.createProductOrder(updatedCheckedItem));
    });
    await Promise.all(promises);
    window.location.reload();
    clearCheckboxes();
}

  const filteredFabrics = useMemo(() => {
    if (fabricQuery) {
      return fabricOptions.filter(f => {
        let t = '';

        if (language === 'en') {
          if (f && f.description_en && f.description_en.length) {
            t = `${t} ${f.description_en}`;
          }

          if (f && f.mixture_en && f.mixture_en.length) {
            t = `${t} ${f.mixture_en}`;
          }

          if (f && f.name_en && f.name_en.length) {
            t = `${t} ${f.name_en}`;
          }

          if (f && f.small_description_en && f.small_description_en.length) {
            t = `${t} ${f.small_description_en}`;
          }
        } else {
          if (f && f.description && f.description.length) {
            t = `${t} ${f.description}`;
          }

          if (f && f.mixture && f.mixture.length) {
            t = `${t} ${f.mixture}`;
          }

          if (f && f.name && f.name.length) {
            t = `${t} ${f.name}`;
          }

          if (f && f.small_description && f.small_description.length) {
            t = `${t} ${f.small_description}`;
          }
        }

        if (f.grammage) {
          t = `${t} ${f.grammage}`;
        }

        if (f.throughput) {
          t = `${t} ${f.throughput}`;
        }

        if (f.width) {
          t = `${t} ${f.width}`;
        }

        if (
          t
            .trim()
            .toLowerCase()
            .includes(fabricQuery.toLowerCase())
        ) {
          return f;
        }

        return null;
      });
    }

    return fabricOptions;
  }, [fabricOptions, fabricQuery, language]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button type="button" className={styles.close} onClick={close}>
          <span>
            <ArrowIcon />
          </span>
          {translate('orders', language)}
        </button>
      </div>
      <div className={styles.orderContent}>
        <div className={styles.draftText}>
          {selectedOrderId && clientOrders.length > 0 && (
            <div>
              Nº {selectedOrderId}-
              {new Date(
                clientOrders.find(co => co.id === selectedOrderId).date
              ).getTime()}
            </div>
          )}
          {selectedClient && (
            <div>
              <span>{translate('client', language)}: </span>{' '}
              {selectedClient.name}
            </div>
          )}
          <div>
            {selectedOrderId ? (
              <>
                {clientOrders.find(co => co.id === selectedOrderId) ? (
                  <>
                    {translate(
                      clientOrders.find(co => co.id === selectedOrderId).status,
                      language
                    )}{' '}
                    -{' '}
                    {language === 'en'
                      ? moment(
                          clientOrders.find(co => co.id === selectedOrderId)
                            .date
                        ).format('MM/DD/YYYY')
                      : moment(
                          clientOrders.find(co => co.id === selectedOrderId)
                            .date
                        ).format('DD/MM/YYYY')}
                  </>
                ) : (
                  ''
                )}{' '}
              </>
            ) : (
              selectedClient && (
                <>
                  {translate('OPEN', language)}{' '}
                  <span className={styles.draf}>
                    ({translate('draft', language)})
                  </span>
                </>
              )
            )}
          </div>
        </div>

        {selectedClient && (
          <>
            <div className={styles.deleteContainer}>
              <CheckInput
                id="selecteAll"
                onChange={setSelectAll}
                value={selectAll}
                disabled={currentOrder.status === "FINA"}
              />
              <span className={styles.separator} />
              <button
                className={styles.deleteProducts}
                type="button"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <TrashCanIcon color="#202730" />
              </button>
              <span className={styles.separator} />
              <div className={styles.reviewed}>
                  <span>{translate('allReviewed', language)}</span>
                  <select 
                    name="revision"
                    id={`allReviewed`}
                    onChange={val => { selectAllReviewed(val.target.value === "true" ? true : false) } }
                    disabled={currentOrder.status === "FINA"}
                  >
                    <option value={false} selected>{translate('no', language)}</option>
                    <option value={true}>{translate('yes', language)}</option>
                  </select>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <div className={styles.changeAllDate}>
                      <span>{`${translate('estimatedDateDelivery', language)}:`}</span>
                      <SingleDatePicker
                        selectedDate={selectedDate}
                        setSelectedDate={changeAllDate}
                        isDisabled={checkedList.length === 0 || currentOrder.status === "FINA"}
                        minDate={new Date()}
                      />
                    </div>
                    <div className={styles.changeAllDate}>
                      <span>{`${translate('agreedDateDelivery', language)}:`}</span>
                      <SingleDatePicker
                        selectedDate={selectedAgreedDate}
                        setSelectedDate={changeAllAgreedDate}
                        isDisabled={checkedList.length === 0 || currentOrder.status === "FINA"}
                        minDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className={styles.suppliersSelectorContainer} ref={suppliersSelectorRef}>
                    <button
                        id=""
                        type="button"
                        className={`${styles.defaultSelectInput} ${styles.production}`}
                        onClick={() => {
                          setEditingSuppliers(true)
                        }}
                        disabled={currentOrder.status === "FINA"}
                      >
                        {selectedSupplier?.name ?? translate('selectedSupplier', language)}
                    </button>
                    {editingSuppliers && (
                      <span>
                        <ListSelector
                          selectLabel={translate('choose', language)}
                          items={suppliers}
                          onSelect={finishEditingSuppliers}
                          value={selectedSupplier}
                        />
                      </span>
                    )}
                  </div>

                  <div className={styles.supplierNumberContainer}>
                    <span>{translate('supplierNumber', language)}</span>
                    <input
                      className={styles.supplierNumber}
                      value={supplierNumber}
                      onChange={e => modifySupplierNumber(e.currentTarget.value)}
                      onKeyDown={e =>
                        e.key === 'Enter' ? finishModifyingSupplierNumber() : false
                      }
                      onBlur={finishModifyingSupplierNumber}
                      disabled={currentOrder.status === "FINA"}
                    />
                  </div>

                  <div className={styles.suppliersSelectorContainer} ref={serviceSelectorRef}>
                    <button
                        id=""
                        type="button"
                        className={`${styles.defaultSelectInput} ${styles.production}`}
                        onClick={() => {
                          setEditingServices(true)
                        }}
                        disabled={currentOrder.status === "FINA"}
                      >
                        {selectedService?.name ?? translate('selectedService', language)}
                    </button>
                    {editingServices && (
                      <span>
                        <ListSelector
                          selectLabel={translate('choose', language)}
                          items={serviceOptions}
                          onSelect={finishEditingServices}
                          value={selectedService}
                          lowWidth
                        />
                      </span>
                    )}
                  </div>

                  <div className={styles.suppliersSelectorContainer} ref={techniqueSelectorRef}>
                    <button
                        id=""
                        type="button"
                        className={`${styles.defaultSelectInput} ${styles.production}`}
                        onClick={() => {
                          setEditingTechniques(true)
                        }}
                        disabled={currentOrder.status === "FINA"}
                      >
                        {selectedTechnique?.name ?? translate('selectedTechnique', language)}
                    </button>
                    {editingTechniques && (
                      <span>
                        <ListSelector
                          selectLabel={translate('choose', language)}
                          items={techniqueOptions}
                          onSelect={finishEditingTechniques}
                          value={selectedTechnique}
                          remove
                        />
                      </span>
                    )}
                  </div>
              </div>
            </div>
            <div className={styles.productModifiers}>
              <div className={styles.modifiers}>
                {currentOrder.status === "REV" && (
                  <button
                    type="button"
                    className={styles.exclusivity}
                    onClick={() => setIsDuplicateModalOpen(true)}
                  >
                    {translate('duplicate', language)}
                  </button>
                )}
                {user.role.includes('coord-seller') && (
                  <button
                    type="button"
                    className={styles.exclusivity}
                    onClick={openExclusivityModal}
                    disabled={currentOrder.status === "FINA"}
                  >
                    {translate('exclusivity', language)}
                  </button>
                )}

                <div
                  className={styles.productionSelectorContainer}
                  ref={productsSelectorRef}
                >
                  <button
                    id=""
                    type="button"
                    className={`${styles.defaultSelectInput} ${styles.production}`}
                    onClick={() => setProductsSelector(true)}
                    disabled={currentOrder.status === "FINA"}
                  >
                    {selectedProduct
                      ? selectedProduct.name
                      : translate('typeOfProduction', language)}
                  </button>
                  {productsSelector && (
                    <span>
                      <ListSelector
                        selectLabel={translate('choose', language)}
                        items={productionTypeOptions}
                        onSelect={setSelectedProductionType}
                        value={selectedProductionType}
                      />
                    </span>
                  )}
                </div>
                <div className={styles.metersInputContainer}>
                  <input
                    type="number"
                    className={styles.meters}
                    value={meters}
                    onChange={e => modifyMeters(e.currentTarget.value)}
                    onKeyDown={e =>
                      e.key === 'Enter' ? finishModifyingMeters() : false
                    }
                    onBlur={finishModifyingMeters}
                    disabled={currentOrder.status === "FINA"}
                  />
                  <span>{translate('meters', language)}</span>
                </div>
                <div className={styles.checks}>
                  <CheckInput
                    id="repetition"
                    onChange={val => modifyRepetitionSimple(val, simple)}
                    value={repetition}
                    text={translate('repetition', language)}
                    disabled={currentOrder.status === "FINA"}
                  />
                  <CheckInput
                    id="simple"
                    onChange={val => modifyRepetitionSimple(repetition, val)}
                    value={simple}
                    text={translate('simple', language)}
                    disabled={currentOrder.status === "FINA"}
                  />
                  <CheckInput
                    id="priority"
                    onChange={val => {
                      setPriority(val) 
                    }}
                    value={priority}
                    text={translate('priority', language)}
                    disabled={currentOrder.status === "FINA"}
                  />
                </div>
                {currentOrder.status === "PROD" && renderMultipleTrackingLinkArea()}
                
              </div>
              
              
              <div
                className={styles.currencySelectorContainer}
                ref={currencySelectorRef}
              >
                <button
                  id=""
                  type="button"
                  className={`${styles.defaultSelectInput} ${styles.production}`}
                  onClick={() => setCurrencySelector(true)}
                  disabled={currentOrder.status === "FINA"}
                >
                  {selectedCurrency
                    ? selectedCurrency.name
                    : translate('currency', language)}
                </button>

                {currencySelector && (
                  <span>
                    <ListSelector
                      selectLabel={translate('choose', language)}
                      items={[
                        {
                          id: 1,
                          name: 'BRL - Real',
                          value: 'brl'
                        },
                        {
                          id: 2,
                          name: 'EUR - Euro',
                          value: 'eur'
                        },
                        {
                          id: 3,
                          name: 'USD - Dollar',
                          value: 'usd'
                        }
                      ]}
                      onSelect={setSelectedCurrency}
                      value={selectedCurrency}
                    />
                  </span>
                )}
              </div>
            </div>
          </>
        )}

        {getNoClassifiedProducts.length > 0 && (
          <div className={styles.products}>
            <ul className={styles.productsList}>
              {getNoClassifiedProducts.map((product, index) => (
                <>
                <div className={styles.section}>
                  {(product?.tracking_url !== "" && product?.tracking_url !== null && product?.tracking_group !== 0) ? `Tracking ${product?.tracking_group}` : 'No track information'}
                </div>
                <li className={styles.product}>
                  <div className={styles.checkAlign}>
                    <div className={styles.productCheck}>
                      <CheckInput
                        id={`noClassified${index}`}
                        value={noClassifiedProductsCheckboxes[index]}
                        onChange={val => {
                          const checkboxes = [...noClassifiedProductsCheckboxes];
                          checkboxes[index] = val;
                          setNoClassifiedProductsCheckboxes(checkboxes);
                        }}
                        disabled={currentOrder.status === "FINA"}
                      />
                    </div>
                    <div
                      className={styles.imageContainer}
                      style={{
                        backgroundColor: product.pantone_color
                          ? product.pantone_color
                          : '#808d9e'
                      }}
                    >
                      {product.print_image_url && (
                        <img src={product.print_image_url} alt="print" />
                      )}
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.code}>{product.print_code}</div>
                    {product.color_variant && (
                      <div>
                        {translate('colorVariant', language)}:{' '}
                        {product.color_variant}
                      </div>
                    )}
                    <div className={styles.download}> 
                        <a 
                          className={!product.psd_original ? styles.isDisabled : ""}
                          title='Faça download do psd!' 
                          href={product.psd_original ?? ""}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Designer
                        </a>

                        <a 
                          title='Faça download do psd!' 
                          className={!product.psd_flirted ? styles.isDisabled : ""}
                          href={product.psd_flirted}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Arte Final
                        </a>
                    </div>
                      <div className={styles.reviewed}>
                        <span>{translate('reviewedProduct', language)}</span>
                        <select 
                          name="revision"
                          id={`noClassified${index}`}
                          onChange={val => { 
                            product.is_reviewed = val.target.value === "true" ? true : false;
                          }}
                          disabled={((product.psd_final === null && product.psd_original === null && product.is_pantone === false) || currentOrder.status === "FINA")}
                        >
                          <option value={false} selected={!product.is_reviewed}>{translate('no', language)}</option>
                          <option value={true} selected={product.is_reviewed}>{translate('yes', language)}</option>
                        </select>
                      </div>
                    </div>
                </li>
                </>
              ))}
            </ul>
          </div>
        )}
        {getProductionProducts.length > 0 && (
          <>
            <div className={styles.section}>
              {translate('production', language)}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getProductionProducts.map((product, index) => (
                  <>
                  <div className={styles.section}>
                    {(product?.tracking_url !== "" && product?.tracking_url !== null && product?.tracking_group !== 0 ) ? `Tracking ${product?.tracking_group}` : 'No track information'}
                  </div>
                  <li className={styles.product} key={index}>
                    <div className={styles.checkAlign}>
                      <div className={styles.productCheck}>
                        <CheckInput
                          id={`production${index}`}
                          value={productionProductsCheckboxes[index]}
                          onChange={val => {
                            const checkboxes = [...productionProductsCheckboxes];
                            checkboxes[index] = val;
                            setProductionProductsCheckboxes(checkboxes);
                          }}
                          disabled={currentOrder.status === "FINA"}
                        />
                      </div>
                      <div
                        className={styles.imageContainer}
                        style={{
                          backgroundColor: product.pantone_color
                            ? product.pantone_color
                            : '#808d9e'
                        }}
                      >
                        {product.print_image_url && (
                          <img src={product.print_image_url} alt="print" />
                        )}
                      </div>
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                      {product.color_variant && (
                        <div>
                          {translate('colorVariant', language)}:{' '}
                          {product.color_variant}
                        </div>
                      )}
                      <div>
                        {product.fabric_name && !selectedOrderId && (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.product_id,
                                product.initialFabrics
                              )
                            }
                            disabled={currentOrder.status === "FINA"}
                          >
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </button>
                        )}
                        {product.fabric_name && selectedOrderId && (
                          <>
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </>
                        )}
                        {!selectedOrderId && !product.fabric_id && (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.product_id,
                                product.initialFabrics
                              )
                            }
                            disabled={currentOrder.status === "FINA"}
                          >
                            {translate('defineFabric', language)}
                          </button>
                        )}
                        {product.fabric_name && product.meters ? ' - ' : ''}
                        {renderMetersArea(product)}
                        {(product.fabric_name || product.meters) &&
                        product.category
                          ? ' - '
                          : ''}
                        {product.category === 'SIM' &&
                          translate('simple', language)}
                        {product.category === 'REP' &&
                          translate('repetition', language)}
                        {product.category === 'BOT' &&
                          translate('simpleRepetition', language)}
                      </div>
                      <div className={styles.exclusivityInfo}>
                        {!Array.isArray(product.print_exclusivity) &&
                          user.role.includes('coord-seller') && (
                            <button
                              className={styles.defineExclusivity}
                              type="button"
                              onClick={() =>
                                defineExclusivity(product.product_id)
                              }
                              disabled={currentOrder.status === "FINA"}
                            >
                              {translate('defineExclusivity', language)}
                            </button>
                          )}
                        <div>
                          {getExclusivityVarValue(
                            product.print_exclusivity_var ||
                              product.exclusivity_var
                          )}
                        </div>
                        {product.print_exclusivity &&
                          Array.isArray(product.print_exclusivity) &&
                          product.print_exclusivity.length > 0 && (
                            <div>
                              {translate('exclusivity', language)} :{' '}
                              {product.print_exclusivity.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                        {product.print_exclusivity_int &&
                          product.print_exclusivity_int.length > 0 && (
                            <div>
                              {product.print_exclusivity_int.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                        {product.print_exclusivity_reg &&
                          product.print_exclusivity_reg.length > 0 && (
                            <div>
                              {product.print_exclusivity_reg.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                        {product.print_exclusivity_cont &&
                          product.print_exclusivity_cont.length > 0 && (
                            <div>
                              {product.print_exclusivity_cont.map(
                                ex => `${ex.name ? ex.name : ex.value}; `
                              )}
                            </div>
                          )}
                      </div>
                      <div className={styles.download}> 
                        <a 
                          className={!product.psd_original ? styles.isDisabled : ""}
                          title='Faça download do psd!' 
                          href={product.psd_original ?? ""}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Designer
                        </a>

                        <a 
                          title='Faça download do psd!' 
                          className={(!product.psd_final && !product.psd_flirted) ? styles.isDisabled : ""}
                          href={product.psd_final ?? product.psd_flirted}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Arte Final
                        </a>
                      </div>
                      <div className={styles.reviewed}>
                        <span>{translate('reviewedProduct', language)}</span>
                        <select 
                          name="revision"
                          id={`production${index}`}
                          onChange={val => { 
                            product.is_reviewed = val.target.value === "true" ? true : false;
                          }}
                          disabled={((product.psd_final === null && product.psd_original === null && product.is_pantone === false) || currentOrder.status === "FINA")}
                        >
                          <option value={false} selected={!product.is_reviewed}>{translate('no', language)}</option>
                          <option value={true} selected={product.is_reviewed}>{translate('yes', language)}</option>
                        </select>
                      </div>

                     {(currentOrder?.status === "PROD" || currentOrder?.status === "REV") && renderEstimatedSupplierDateArea(product)}
                     {currentOrder.status === "PROD" && renderTrackingLinkArea(product)}
                    </div>
                    <div
                      className={styles.detailSection}
                    >
                      {renderObservation(product)}
                      {renderSelectSupplier(product)}
                      {renderSelectService(product)}
                      {renderSelectTechnique(product)}
                      {renderSelectFabricSupplier(product)}
                      {renderFabricFinishing(product)}
                    </div>
                    {renderPriceArea(product)}
                  </li>
                  </>
                ))}
              </ul>
            </div>
          </>
        )}
        {getProductionProducts.length > 0 && (
          <div className={styles.sectionTotals}>
            {translate('productionValue', language)}:{' '}
            <span>{`${getCurrencySymbol}${getSectionTotal(
              getProductionProducts
            )}`}</span>
            {translate('numberOfProduction', language)}:<span>{getProductionProducts.length}</span>
          </div>
        )}

        {getStrikeProducts.length > 0 && (
          <>
            <div className={styles.section}>
              {translate('strike', language)}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getStrikeProducts.map((product, index) => (
                  <>
                  <div className={styles.section}>
                    {(product?.tracking_url !== "" && product?.tracking_url !== null && product?.tracking_group !== 0 ) ? `Tracking ${product?.tracking_group}` : 'No track information'}
                  </div>
                  <li className={styles.product}>
                    <div className={styles.checkAlign}>
                      <div className={styles.productCheck}>
                        <CheckInput
                          id={`strike${index}`}
                          value={strikeProductsCheckboxes[index]}
                          onChange={val => {
                            const checkboxes = [...strikeProductsCheckboxes];
                            checkboxes[index] = val;
                            setStrikeProductsCheckboxes(checkboxes);
                          }}
                          disabled={currentOrder.status === "FINA"}
                        />
                      </div>
                      <div
                        className={styles.imageContainer}
                        style={{
                          backgroundColor: product.pantone_color
                            ? product.pantone_color
                            : '#808d9e'
                        }}
                      >
                        {product.print_image_url && (
                          <img src={product.print_image_url} alt="print" />
                        )}
                      </div>
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                      {product.color_variant && (
                        <div>
                          {translate('colorVariant', language)}:{' '}
                          {product.color_variant}
                        </div>
                      )}
                      <div>
                        {product.fabric_name && !selectedOrderId ? (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.product_id,
                                product.initialFabrics
                              )
                            }
                            disabled={currentOrder.status === "FINA"}
                          >
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </button>
                        ) : (
                          <>
                            {translate('fabric', language)}{' '}
                            {product.fabric_name}
                          </>
                        )}
                        {!product.fabric_id && !selectedOrderId && (
                          <button
                            className={styles.defineFabric}
                            type="button"
                            onClick={() =>
                              defineFabric(
                                product.product_id,
                                product.initialFabrics
                              )
                            }
                            disabled={currentOrder.status === "FINA"}
                          >
                            {translate('defineFabric', language)}
                          </button>
                        )}
                        {product.fabric_name && product.meters ? ' - ' : ''}
                        {renderMetersArea(product)}
                        {(product.fabric_name || product.meters) &&
                        product.category
                          ? ' - '
                          : ''}
                        {product.category === 'SIM' &&
                          translate('simple', language)}
                        {product.category === 'REP' &&
                          translate('repetition', language)}
                        {product.category === 'BOT' &&
                          translate('simpleRepetition', language)}
                      </div>
                      <div>{product.idCode ? `ID ${product.idCode}` : ''}</div>
                      <div className={styles.download}> 
                        <a 
                          className={!product.psd_original ? styles.isDisabled : ""}
                          title='Faça download do psd!' 
                          href={product.psd_original ?? ""}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Designer
                        </a>

                        <a 
                          title='Faça download do psd!' 
                          className={(!product.psd_final && !product.psd_flirted) ? styles.isDisabled : ""}
                          href={product.psd_final ?? product.psd_flirted}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Arte Final
                        </a>
                      </div>
                      <div className={styles.reviewed}>
                        <span>{translate('reviewedProduct', language)}</span>
                        <select 
                          name="revision"
                          id={`strike${index}`}
                          onChange={val => {
                            product.is_reviewed = val.target.value === "true" ? true : false;
                          }}
                          disabled={((product.psd_final === null && product.psd_original === null && product.is_pantone === false) || currentOrder.status === "FINA")}
                        >
                          <option value={false} selected={!product.is_reviewed}>{translate('no', language)}</option>
                          <option value={true} selected={product.is_reviewed}>{translate('yes', language)}</option>
                        </select>
                      </div>
                      {(currentOrder?.status === "PROD" || currentOrder?.status === "REV") && renderEstimatedSupplierDateArea(product)}
                      {currentOrder.status === "PROD" && renderTrackingLinkArea(product)}
                    </div>
                    <div
                      className={styles.detailSection}
                    >
                      {renderObservation(product)}
                      {renderSelectSupplier(product)}
                      {renderSelectService(product)}
                      {renderSelectTechnique(product)}
                      {renderSelectFabricSupplier(product)}
                      {renderFabricFinishing(product)}
                    </div>
                    {renderPriceArea(product)}
                  </li>
                  </>
                ))}
              </ul>
              
            </div>
          </>
        )}
        {getStrikeProducts.length > 0 && (
          <div className={styles.sectionTotals}>
            {translate('strikeValue', language)}:
            <span>{`${getCurrencySymbol}${getSectionTotal(
              getStrikeProducts
            )}`}</span>
          {translate('numberOfStrike', language)}:<span>{getStrikeProducts.length}</span>
          </div>
        )}
        {getReserveProducts.length > 0 && (
          <>
            <div className={styles.section}>
              {translate('reserve', language)}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getReserveProducts.map((product, index) => (
                  <>
                  <div className={styles.section}>
                    {(product?.tracking_url !== "" && product?.tracking_url !== null && product?.tracking_group !== 0 ) ? `Tracking ${product?.tracking_group}` : 'No track information'}
                  </div>
                  <li className={styles.product}>
                    <div className={styles.checkAlign}>
                      <div className={styles.productCheck}>
                        <CheckInput
                          id={`reserve${index}`}
                          value={reserveProductsCheckboxes[index]}
                          onChange={val => {
                            const checkboxes = [...reserveProductsCheckboxes];
                            checkboxes[index] = val;
                            setReserveProductsCheckboxes(checkboxes);
                          }}
                          disabled={currentOrder.status === "FINA"}
                        />
                      </div>
                      <div
                        className={styles.imageContainer}
                        style={{
                          backgroundColor: product.pantone_color
                            ? product.pantone_color
                            : '#808d9e'
                        }}
                      >
                        {product.print_image_url && (
                          <img src={product.print_image_url} alt="print" />
                        )}
                      </div>
                    </div>
                    <div className={styles.info}>
                      <div className={styles.code}>{product.print_code}</div>
                      <div className={styles.exclusivityInfo} />
                      <div className={styles.download}> 
                        <a 
                          className={!product.psd_original ? styles.isDisabled : ""}
                          title='Faça download do psd!' 
                          href={product.psd_original ?? ""}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Designer
                        </a>

                        <a 
                          title='Faça download do psd!' 
                          className={(!product.psd_final && !product.psd_flirted) ? styles.isDisabled : ""}
                          href={product.psd_final ?? product.psd_flirted}
                          onClick={(e) => {
                            e.stopPropagation();
                        }}>
                              <FiDownload size={20} />
                              PSD Arte Final
                        </a>
                      </div>
                      <div className={styles.reviewed}>
                        <span>{translate('reviewedProduct', language)}</span>
                        <select 
                          name="revision"
                          id={`reserve${index}`}
                          onChange={val => {product.is_reviewed = val.target.value === "true" ? true : false;}}
                          disabled={((product.psd_final === null && product.psd_original === null && product.is_pantone === false) || currentOrder.status === "FINA")}
                        >
                          <option value={false} selected={!product.is_reviewed}>{translate('no', language)}</option>
                          <option value={true} selected={product.is_reviewed}>{translate('yes', language)}</option>
                        </select>
                      </div>
                      {(currentOrder?.status === "PROD" || currentOrder?.status === "REV") && renderEstimatedSupplierDateArea(product)}
                      {currentOrder.status === "PROD" && renderTrackingLinkArea(product)}
                    </div>
                  </li>
                  </>
                ))}
              </ul>
            </div>
          </>
        )}

        {getHangerProducts.length > 0 && (
          <>
            <div className={styles.section}>
              {translate('hanger', language)}
            </div>
            <div className={styles.products}>
              <ul className={styles.productsList}>
                {getHangerProducts.map((product, index) => (
                  <>
                    <div className={styles.section}>
                      {(product?.tracking_url !== "" && product?.tracking_url !== null && product?.tracking_group !== 0 ) ? `Tracking ${product?.tracking_group}` : 'No track information'}
                    </div>
                    <li className={styles.product}>
                      <div className={styles.checkAlign}>
                        <div className={styles.productCheck}>
                          <CheckInput
                            id={`hanger${index}`}
                            value={hangerProductsCheckboxes[index]}
                            onChange={val => {
                              const checkboxes = [...hangerProductsCheckboxes];
                              checkboxes[index] = val;
                              setHangerProductsCheckboxes(checkboxes);
                            }}
                            disabled={currentOrder.status === "FINA"}
                          />
                        </div>
                        <div
                          className={styles.imageContainer}
                          style={{
                            backgroundColor: product.pantone_color
                              ? product.pantone_color
                              : '#808d9e'
                          }}
                        >
                          {product.print_image_url && (
                            <img src={product.print_image_url} alt="print" />
                          )}
                        </div>
                      </div>
                      <div className={styles.info}>
                        <div className={styles.code}>{product.print_code}</div>
                        <div className={styles.download}> 
                          <a 
                            className={!product.psd_original ? styles.isDisabled : ""}
                            title='Faça download do psd!' 
                            href={product.psd_original ?? ""}
                            onClick={(e) => {
                              e.stopPropagation();
                          }}>
                                <FiDownload size={20} />
                                PSD Designer
                          </a>

                          <a 
                            title='Faça download do psd!' 
                            className={(!product.psd_final && !product.psd_flirted) ? styles.isDisabled : ""}
                            href={product.psd_final ?? product.psd_flirted}
                            onClick={(e) => {
                              e.stopPropagation();
                          }}>
                                <FiDownload size={20} />
                                PSD Arte Final
                          </a>
                        </div>
                        <div className={styles.reviewed}>
                          <span>{translate('reviewedProduct', language)}</span>
                          <select 
                            name="revision"
                            id={`hanger${index}`}
                            onChange={val => {product.is_reviewed = val.target.value === "true" ? true : false;}}
                            disabled={((product.psd_final === null && product.psd_original === null && product.is_pantone === false) || currentOrder.status === "FINA")}
                          >
                            <option value={false} selected={!product.is_reviewed}>{translate('no', language)}</option>
                            <option value={true} selected={product.is_reviewed}>{translate('yes', language)}</option>
                          </select>
                        </div>
                        {(currentOrder?.status === "PROD" || currentOrder?.status === "REV") && renderEstimatedSupplierDateArea(product)}
                        {currentOrder.status === "PROD" && renderTrackingLinkArea(product)}
                      </div>
                      <div
                        className={styles.detailSection}
                      >
                        {renderObservation(product)}
                        {renderSelectSupplier(product)}
                        {renderSelectService(product)}
                        {renderSelectTechnique(product)}
                        {renderSelectFabricSupplier(product)}
                        {renderFabricFinishing(product)}
                      </div>
                        {renderPriceArea(product)}
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </>
        )}
        {getHangerProducts.length > 0 && (
          <div className={styles.sectionTotals}>
            {translate('hangerValue', language)}:
            <span>{`${getCurrencySymbol}${getSectionTotal(
              getHangerProducts
            )}`}</span>
              {translate('numberOfHanger', language)}:<span>{getHangerProducts.length}</span>
          </div>
        )}

        <div className={`${styles.sectionTotals} ${styles.subTotal}`}>
          <span>No. Total Items: {totalItems()} </span>
          <span>Subtotal: {`${getCurrencySymbol}${getSubtotal()}`}</span>
        </div>

        <div className={`${styles.sectionTotals} `}>
          <span className={styles.discount}>
            {translate('discount', language)}: {getCurrencySymbol}
            <CurrencyInput
              value={discount}
              onChange={(e, val) => setDiscount(val)}
              decimalSeparator={
                selectedCurrency && selectedCurrency.value === 'usd' ? '.' : ','
              }
              thousandSeparator={
                selectedCurrency && selectedCurrency.value === 'usd' ? ',' : '.'
              }
            />
          </span>
        </div>

        <div className={`${styles.sectionTotals}`}>
          <span>
            Subtotal: {`${getCurrencySymbol}${getDiscountedSubtotal()}`}
          </span>
        </div>

        {clientProducts.length > 0 && (
          <>
            <div className={styles.actionsContainer}>
              {/* {clientOrders.find(co => co.id === selectedOrderId).status !==
              'REP' ? (
                <button
                  className={`${styles.defaultRoundedDeleteButton}`}
                  type="button"
                  onClick={() => manageOrder('REP')}
                >
                  {clientOrders.find(co => co.id === selectedOrderId).status ===
                  'OPEN'
                    ? translate('dontApprove', language)
                    : translate('cancel', language)}
                </button>
              ) : (
                <div />
              )} */}

              {(clientOrders.find(co => co.id === selectedOrderId).status ===
                'OPEN' ||
                clientOrders.find(co => co.id === selectedOrderId).status ===
                  'REP') && (
                <button
                  className={styles.defaultRoundedActionButton}
                  type="button"
                  onClick={() => manageOrder('REV')}
                >
                  {translate('approve', language)}*
                </button>
              )}

              {(clientOrders.find(co => co.id === selectedOrderId).status === 'REV' || clientOrders.find(co => co.id === selectedOrderId).status === 'PROD' ) && (
                <button
                  className={styles.defaultRoundedActionButton}
                  type="button"
                  onClick={() => {
                    if(getIfAllAreReviewed() === true) {
                      manageOrder('PROD')
                    } else {
                      manageOrder('REV')
                    }
                  }}
                >
                  {translate('save', language)}*
                </button>
              )}
            </div>
            <div className={styles.selectPrintsWarning}>
              {translate('selectPrintsToContinue', language)}*
            </div>
          </>
        )}
      </div>

      {exclusivityModal && (
        <div className={styles.exclusivityModal}>
          <PurchaseSetExclusivity
            exclusivityPurchase={applyExclusivity}
            onClose={() => setExclusivityModal(false)}
          />
        </div>
      )}
      {isDuplicateModalOpen && (
          <ConfirmationModal
            title='Deseja duplicar estes itens do pedido?'
            message='Ao confirmar, os itens do pedido seram duplicados'
            confirmText='Sim'
            cancelText='Não'
            onConfirm={duplicateItems}
            onCancel={() => setIsDuplicateModalOpen(false)}
            onClose={() => setIsDuplicateModalOpen(false)}
          />
      )}
      {isDeleteModalOpen && (
          <ConfirmationModal 
            title='Deseja deletar os itens do pedido?'
            message='Ao confirmar, os itens do pedido seram deletados!'
            confirmText='Sim'
            cancelText='Não'
            onConfirm={deleteProductOrders}
            onCancel={() => setIsDeleteModalOpen(false)}
            onClose={() => setIsDeleteModalOpen(false)}
          />
      )}
      {fabricsModal && (
        <>
          <div className={styles.fabricsModalContainer}>
            <div className={styles.modal}>
              <h1>{translate('chooseAFabric', language)}</h1>
              <button
                type="button"
                className={styles.close}
                onClick={() => setFabricsModal(false)}
              >
                <CloseIcon />
              </button>
              <input
                type="search"
                placeholder="Search"
                value={fabricQuery}
                onChange={e => setFabricQuery(e.currentTarget.value)}
              />
              <ul className={styles.fabricsList}>
                {filteredFabrics.map((fab, index) => (
                  <li key={index}>
                    <button type="button" onClick={() => applyFabric(fab)}>
                      <img src={fab.image} alt={fab.name} />
                      <div className={styles.fabricInfoContainer}>
                        <div className={styles.fabricName}>{fab.name}</div>
                        <div className={styles.fabricInfo}>
                          <div className={styles.item}>
                            <span>{translate('grammage', language)}</span>:{' '}
                            {fab.grammage} g/m<sup>2</sup>
                          </div>
                          <div className={styles.item}>
                            <span>{translate('width', language)}</span>:{' '}
                            {fab.width} cm
                          </div>
                          <div className={styles.item}>
                            <span>{translate('composition', language)}</span>:{' '}
                            {fab.mixture}
                          </div>
                          <div className={styles.item}>
                            <span>{translate('yield', language)}</span>:{' '}
                            {fab.throughput} M/KG
                          </div>
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}

      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
      {purchaseSuccess && (
        <button
          type="button"
          className={styles.purchaseSuccess}
          onClick={returnToPresentation}
        >
          <h1>{translate('processedOrderSuccess', language)}</h1>
          <p>{translate('goToPresentation', language)}</p>
        </button>
      )}
      {saleModal && (
        <Sale
          close={closeSaleModal}
          order={saleInfo.order}
          client={saleInfo.client}
          initialTotal={saleInfo.total}
          currency={saleInfo.currency}
          saleSuccess={saleSuccess}
        />
      )}
    </div>
  );
}

export default OrderCart;
