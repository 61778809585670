import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import styles from './FavoritePresentations.module.scss';
import translate from '../libs/i18n';
import Api from '../libs/Api';
// import { setPresentations } from '../store/actions/presentations';

// import TrashCanIcon from '../assets/icons/TrashCan';
// import DuplicateItens from '../assets/icons/DuplicateItens';
import SearchIcon from '../assets/icons/Search';
import PresentationGridItem from '../components/PresentationGridItem';
import ConfirmationModal from '../components/ConfirmationModal';
import TagsSelectors from '../components/TagsSelectors';

import Loading from '../components/Loading';

function FavoritePresentations() {
  const language = useSelector(state => state.settings.language);

  const [selectedPresentations, setSelectedPresentations] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedProveniences, setSelectedProveniences] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [myPresentations, setMyPresentations] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryTimeout, setSearchQueryTimeout] = useState(null);
  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [tags, setTags] = useState([]);
  const [filter, setFilter] = useState('');
  const [clientProfiles, setClientProfiles] = useState([]);
  const [attendances, setAttendances] = useState([]);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [updatedPage, setUpdatedPage] = useState();
  const [counter, setCounter] = useState();
  const [filteredCounter, setFilteredCounter] = useState();

  useEffect(() => {
    setLoading(true);
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    (async function loadData() {
      setLoading(true);
      try {
        const getCollections = Api.getCollections();
        const getClients = Api.getClients();
        const getTags = Api.getTags();
        const getClientProfiles = Api.getCustomerProfiles();
        const getAttendances = Api.getAttendances();

        await axios
          .all([
            getCollections,
            getClients,
            getTags,
            getClientProfiles,
            getAttendances
          ])
          .then(
            axios.spread(async (...responses) => {
              setCollections(responses[0]);
              setClients(responses[1]);
              setTags(responses[2]);
              setClientProfiles(responses[3]);
              setAttendances(responses[4].result);
            })
          );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (filter) {
      setInitialLoad(true);
      setLoading(true);
      Api.getFavoritePresentations().then(favoritePresentations => {
        if (favoritePresentations.length) {
          Api.getMyPresentations(
            `ids=${favoritePresentations.toString()}&${filter}&page=${page - 1}`
          )
            .then(res => {
              setMyPresentations(res.result);
              setFilteredCounter(res.count);
              let numberOfPages = parseInt(res.count / 30, 10);
              if (res.count % 30) {
                numberOfPages += 1;
              }
              setLastPage(numberOfPages);

              if (res && res.result) {
                setMyPresentations(
                  res.result.map(p => ({ ...p, isFavorite: true }))
                );
              }
            })
            .finally(() => {
              setLoading(false);
              setInitialLoad(false);
            });
        } else {
          setLoading(false);
        }
      });
    } else {
      setInitialLoad(true);
      Api.getFavoritePresentations().then(favoritePresentations => {
        if (favoritePresentations.length) {
          Api.getMyPresentations(
            `ids=${favoritePresentations.toString()}&page=${page - 1}`
          )
            .then(res => {
              setMyPresentations(res.result);
              setCounter(res.count);
              let numberOfPages = parseInt(res.count / 30, 10);
              if (res.count % 30) {
                numberOfPages += 1;
              }
              setLastPage(numberOfPages);

              if (res && res.result) {
                setMyPresentations(
                  res.result.map(p => ({ ...p, isFavorite: true }))
                );
              }
            })
            .finally(() => {
              setLoading(false);
              setInitialLoad(false);
            });
        } else {
          setLoading(false);
        }
      });
    }
  }, [filter, page]);

  useEffect(() => {
    if (searchQueryTimeout) {
      clearTimeout(searchQueryTimeout);
    }

    let query = '';
    function concat(filters) {
      if (query) {
        query += `&${filters}`;
      } else {
        query += filters;
      }
    }
    if (selectedCollections.length) {
      let collectionsQuery = 'collection=';
      for (let i = 0; i < selectedCollections.length; i += 1) {
        if (i === selectedCollections.length - 1) {
          collectionsQuery += selectedCollections[i].id;
        } else {
          collectionsQuery += `${selectedCollections[i].id},`;
        }
      }
      concat(collectionsQuery);
    }

    if (selectedClient) {
      const clientsQuery = `client=${selectedClient.id}`;

      concat(clientsQuery);
    }
    if (selectedProveniences.length) {
      let proveniencesQuery = 'provenience=';
      for (let i = 0; i < selectedProveniences.length; i += 1) {
        if (i === selectedProveniences.length - 1) {
          proveniencesQuery += selectedProveniences[i].value;
        } else {
          proveniencesQuery += `${selectedProveniences[i].value},`;
        }
      }
      concat(proveniencesQuery);
    }

    if (selectedTags.length) {
      let tagsQuery = 'tags=';
      for (let i = 0; i < selectedTags.length; i += 1) {
        if (i === selectedTags.length - 1) {
          tagsQuery += selectedTags[i].id;
        } else {
          tagsQuery += `${selectedTags[i].id},`;
        }
      }
      concat(tagsQuery);
    }

    if (selectedProfiles.length) {
      let tagsQuery = 'profile=';
      for (let i = 0; i < selectedProfiles.length; i += 1) {
        if (i === selectedProfiles.length - 1) {
          tagsQuery += selectedProfiles[i].id;
        } else {
          tagsQuery += `${selectedProfiles[i].id},`;
        }
      }
      concat(tagsQuery);
    }

    if (searchQuery.length) {
      concat(`name=${searchQuery}`);
    }

    if (query === '?') {
      setFilter('');
    } else if (query !== '?' && searchQuery.length) {
      setSearchQueryTimeout(setTimeout(() => setFilter(query), 1000));
    } else {
      setFilter(query);
    }
  }, [
    selectedTags,
    selectedCollections,
    selectedClient,
    selectedProveniences,
    searchQuery,
    selectedProfiles
  ]);

  const onSelectPresentation = (id, selected) => {
    let currentSelectedPresentations = [...selectedPresentations];
    const containsPresentation = currentSelectedPresentations.find(
      presentation => presentation === id
    );
    if (!selected && containsPresentation) {
      currentSelectedPresentations = currentSelectedPresentations.filter(
        presentation => presentation !== id
      );
    } else if (selected && !containsPresentation) {
      currentSelectedPresentations = [...selectedPresentations, id];
    }
    setSelectedPresentations(currentSelectedPresentations);
  };

  const deletePresentations = () => {
    setLoading(true);
    setConfirmationModal(false);
    const deleteRequests = [];

    selectedPresentations.forEach(presentation => {
      const deleteRequest = Api.deletePresentation(presentation);
      deleteRequests.push(deleteRequest);
    });
    axios
      .all(deleteRequests)
      .then(() => {
        Api.getFavoritePresentations().then(favoritePresentations => {
          if (favoritePresentations.length) {
            Api.getMyPresentations(
              `ids=${favoritePresentations.toString()}&page=${page - 1}`
            )
              .then(res => {
                setMyPresentations(res.result);
                setCounter(res.count);
                let numberOfPages = parseInt(res.count / 30, 10);
                if (res.count % 30) {
                  numberOfPages += 1;
                }
                setLastPage(numberOfPages);

                if (res && res.results) {
                  setMyPresentations(
                    res.results.map(p => ({ ...p, isFavorite: true }))
                  );
                }
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        });
        setSelectedPresentations([]);
      })
      .catch(() => {
        toast(translate('failDeletingPresentations', language), {
          type: 'error'
        });
      });
  };

  const duplicatePresentations = () => {
    setLoading(true);
    const duplicateRequests = [];

    selectedPresentations.forEach(presentation => {
      const deleteRequest = Api.duplicatePresentation(presentation);
      duplicateRequests.push(deleteRequest);
    });
    axios
      .all(duplicateRequests)
      .then(() => {
        Api.getFavoritePresentations().then(favoritePresentations => {
          if (favoritePresentations.length) {
            Api.getMyPresentations(
              `ids=${favoritePresentations.toString()}&page=${page - 1}`
            )
              .then(res => {
                setMyPresentations(res.result);
                setCounter(res.count);
                let numberOfPages = parseInt(res.count / 30, 10);
                if (res.count % 30) {
                  numberOfPages += 1;
                }
                setLastPage(numberOfPages);

                if (res && res.results) {
                  setMyPresentations(
                    res.results.map(p => ({ ...p, isFavorite: true }))
                  );
                }
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        });
        setSelectedPresentations([]);
      })
      .catch(() => {
        toast(translate('failDuplicatePresentations', language), {
          type: 'error'
        });
      });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };
  const looseFocus = () => {
    if (updatedPage > 0 && updatedPage <= lastPage) {
      setPage(updatedPage);
      setUpdatedPage();
    }
  };

  const goTop = () => {
    window.scrollTo(0, 200);
  };

  const removeFavoritePresentation = presentationId => {
    const updatedPresentations = [...myPresentations];
    const printIndex = updatedPresentations.findIndex(
      up => up.id === presentationId
    );
    const currentPrint = updatedPresentations[printIndex];
    updatedPresentations.splice(printIndex, 1);
    setMyPresentations(updatedPresentations);

    Api.removeFavoritePresentation(presentationId)
      .then(() => {
        toast(translate('successRemovingFavoritePresentation', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorRemovingFavoritegPresentation', language), {
          type: 'error'
        });
        updatedPresentations.splice(printIndex, 0, currentPrint);
        setMyPresentations(updatedPresentations);
      });
  };

  return (
    <div className={styles.favoritePresentations}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          <span className={styles.title}>
            {translate('favoritePresentations', language).toUpperCase()}
          </span>
        </h2>
      </div>
      <div className={styles.content}>
        <div className={styles.filters}>
          <div className={styles.left}>
            <div className={styles.inputContainer}>
              <SearchIcon color="#a3b3c7" />
              <input
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.currentTarget.value)}
                className={styles.exclusivityFilter}
                placeholder={translate('searchPresentation', language)}
              />
            </div>
          </div>
          <div className={styles.right}>
            <TagsSelectors
              selectLabel={translate('add', language)}
              tags={tags.filter(tag => tag.category === 'Tema')}
              selectTags={setSelectedTags}
              collections={collections}
              selectCollections={setSelectedCollections}
              clients={clients}
              selectClients={setSelectedClient}
              selectProveniences={setSelectedProveniences}
              profiles={clientProfiles}
              selectProfiles={setSelectedProfiles}
              uniqueClient
              complete
            />
          </div>
        </div>

        <div className={styles.presentationsGrid}>
          {myPresentations.map(presentation => (
            <PresentationGridItem
              name={presentation.name}
              image={presentation.print_image_url}
              onSelect={onSelectPresentation}
              id={presentation.id}
              type={presentation.type}
              attendances={attendances}
              favorite={() => removeFavoritePresentation(presentation.id)}
              isFavorite={presentation.isFavorite}
            />
          ))}
        </div>
        {loading && <Loading fixed={!initialLoad} />}
      </div>
      {confirmationModal && (
        <ConfirmationModal
          message="Tem certeza que deseja excluir as apresentações selecionadas?"
          confirmText="Sim"
          cancelText="Não"
          onConfirm={deletePresentations}
          onCancel={() => setConfirmationModal(false)}
          deleteWarning
        />
      )}
      {(counter || filteredCounter) && lastPage && (
        <div className={`${styles.footer}`}>
          <div className={`${styles.pagination}`}>
            <button
              disabled={page === 1}
              type="button"
              onClick={() => {
                setPage(page - 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.backArrow} />
            </button>
            <input
              type="number"
              placeholder={page}
              onKeyDown={handleKeyDown}
              value={updatedPage}
              onChange={e =>
                setUpdatedPage(parseInt(e.currentTarget.value, 10))
              }
              onBlur={looseFocus}
            />
            <span>de {lastPage}</span>
            <button
              disabled={page === lastPage}
              type="button"
              onClick={() => {
                setPage(page + 1);
                setUpdatedPage('');
              }}
            >
              <span className={styles.nextArrow} />
            </button>
          </div>
          <button type="button" className={styles.upButton} onClick={goTop}>
            <span className={styles.upArrow} />
          </button>
        </div>
      )}
      <div
        className={`${
          styles.actionsMenuContainer
        } ${selectedPresentations.length > 0 && styles.actionsMenuActive}`}
      >
        <div className={styles.actionsMenu}>
          <div className={styles.left}>
            <div>
              {`${selectedPresentations.length} ${
                selectedPresentations.length > 1
                  ? translate('selectedMultiple', language)
                  : translate('selectedOne', language)
              }`}
            </div>
            <span className={styles.separator}>|</span>
            <button
              onClick={duplicatePresentations}
              disabled={!selectedPresentations.length}
              type="button"
              className={styles.duplicate}
            >
              {translate('duplicate', language)}
            </button>
          </div>
          <button
            onClick={() => setConfirmationModal(true)}
            disabled={!selectedPresentations.length}
            type="button"
            className={styles.delete}
          >
            {translate('delete', language)}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FavoritePresentations;
