import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import { ChromePicker as ColorPicker } from 'react-color';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import moment from 'moment';
import { toast } from 'react-toastify';

import translate from '../libs/i18n';
import Api from '../libs/Api';
import useOutsideClick from '../libs/useOutsideClick';
import styles from './Print.module.scss';

import { addProductsOnCart } from '../store/actions/carts';

import SectionDivisor from '../components/SectionDivisor';
import ListSelector from '../components/ListSelector';
import FileSelector from '../components/FileSelector';
import DownloadIcon from '../assets/icons/Download';
import ShoppingCartIcon from '../assets/icons/ShoppingCart';
import Loading from '../components/Loading';
import CreatePrintForm from '../components/CreatePrintForm';
import ConfirmationModal from '../components/ConfirmationModal';
import FormModal from '../components/FormModal';
import CreateCollectionForm from '../components/CreateCollectionForm';
import CloseIcon from '../assets/icons/Close';
import UploadProgress from '../components/UploadProgress';
import LightBox from '../components/LightBox';
import Feedback from '../components/Feedback';
import CheckInput from '../components/CheckInput';
import SellerCart from '../components/SellerCart';
import FileIcon from '../assets/images/file.png';
import calcDollRapport from '../utils/calcDollRapport';
import CustomizedPrint from '../assets/images/customized-print.jpg';
import EstampaCustomizada from '../assets/images/estampa-customizada.jpg';
import { uploadNewFile } from '../libs/s3Upload';

function Print() {
  const language = useSelector(state => state.settings.language);
  const user = useSelector(state => state.user);
  const clientCarts = useSelector(state => state.carts);

  const dispatch = useDispatch();

  const { id } = useParams();
  const location = useLocation();

  const history = useHistory();

  const [print, setPrint] = useState();
  const [collections, setCollections] = useState([]);

  const [updatedPrintName, setUpdatedPrintName] = useState();

  const [jpgPrint, setJpgPrint] = useState([]);
  const [psdDesigner, setPsdDesigner] = useState([]);
  const [psdFinalistArt, setPsdFinalistArt] = useState([]);
  const [psdFinalistArtFlirted, setPsdFinalistArtFlirted] = useState([]);

  const [selectedCollection, setSelectedCollection] = useState();
  const [collectionsSelector, setCollectionsSelector] = useState('closed');

  const [selectedDesigner, setSelectedDesigner] = useState();
  const [designers, setDesigners] = useState([]);
  const [extDesigners, setExtDesigners] = useState([]);
  const [designerSelector, setDesignerSelector] = useState('closed');

  const [selectedCoordinator, setSelectedCoordinator] = useState();
  const [coordinators, setCoordinators] = useState([]);
  const [coordinatorSelector, setCoordinatorSelector] = useState('closed');

  const [selectedArtFinalist, setSelectedArtFinalist] = useState();
  const [artFinalists, setArtFinalists] = useState([]);
  const [artFinalistSelector, setArtFinalistSelector] = useState('closed');

  const [clients, setClients] = useState([]);

  const [selectedType, setSelectedType] = useState();
  const [typeSelector, setTypeSelector] = useState('closed');

  const [selectedReview, setSelectedReview] = useState();
  const [reviewSelector, setReviewSelector] = useState('closed');

  const [selectedProvenience, setSelectedProvenience] = useState();
  const [provenienceSelector, setProvenienceSelector] = useState('closed');

  const [updatedRapportWidth, setUpdatedRapportWidth] = useState();
  const [updatedlCode, setUpdatedlCode] = useState();
  const [updatedRapportHeight, setUpdatedRapportHeight] = useState();

  const [tagsSelector, setTagsSelector] = useState('closed');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(null);
  const tagsSelectorRef = useRef();

  const [chromaticVariations, setChromaticVariations] = useState([]);
  const [twins, setTwins] = useState([]);

  const [updatedVisualRefBriefing, setUpdatedVisualRefBriefing] = useState([]);

  const [usedDrawingsBriefing, setUsedDrawingsBriefing] = useState();
  const [
    updatedUsedDrawingsBriefing,
    setUpdatedUsedDrawingsBriefing
  ] = useState([]);

  const [newChromaticVariation, setNewChromaticVariation] = useState(false);
  const [widthVariation, setWidthVariation] = useState(false);
  const [newTwin, setNewTwin] = useState(false);
  const [originPrint, setOriginPrint] = useState();

  const [updating, setUpdating] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteBriefingIntent, setDeleteBriefingIntent] = useState();
  const [confirmationModalTitle, setConfirmationModalTitle] = useState();
  const [confirmationModalMessage, setConfirmationModalMessage] = useState();
  const [
    confirmationModalConfirmText,
    setConfirmationModalConfirmText
  ] = useState();
  const [
    confirmationModalCancelText,
    setConfirmationModalCancelText
  ] = useState();

  const [createCollectionModal, setCreateCollectionModal] = useState(false);
  const [savingMessage] = useState('Fazendo upload do arquivo');

  const [showChat, setShowChat] = useState(false);

  const [savingProgress, setSavingProgress] = useState(false);

  const [lightBoxImage, setLightBoxImage] = useState();

  const [reserves, setReserves] = useState([]);

  const [presentations, setPresentations] = useState([]);
  const [presentationSelector, setPresentationSelector] = useState(false);
  const [selectedPresentation, setSelectedPresentation] = useState([]);
  const presentationSelectorRef = useRef();
  const [presentationSection, setPresentationSection] = useState(
    'presentation'
  );
  const [briefings, setBriefings] = useState([]);
  const [briefingSelector, setBriefingSelector] = useState(false);
  const [selectedBriefings, setSelectedBriefings] = useState([]);
  const briefingSelectorRef = useRef();

  const [briefingsList, setBriefingsList] = useState([]);

  const [fabrics, setFabrics] = useState([]);
  const [selectedFabrics, setSelectedFabrics] = useState([]);
  const [lastPresentation, setLastPresentation] = useState();

  const [hasBackground, setHasBackground] = useState(false);
  const [initialHasBackground, setInitialHasBackground] = useState(false);
  const [initialBackgroundColor, setInitialBackgroundColor] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [printRatioWarning, setPrintRatioWarning] = useState(false);
  const [printNaturalHeight, setPrintNaturalHeight] = useState(0);
  const [printNaturalWidth, setPrintNaturalWidth] = useState(0);

  const [orders, setOrders] = useState([]);
  const [cart, setCart] = useState();
  const [printDoll, setPrintDoll] = useState();
  const [customization, setCustomization] = useState();
  const [ordersIndex, setOrdersIndex] = useState();
  const [exclusivities, setExclusivities] =useState([]); 


  const dollRef = useRef(null);

  const isExternalDesigner = user && user.role.includes('ext-designer');

  useEffect(() => {
    Api.getCollections().then(res => {
      setCollections(res);
    });

    Api.getTags().then(res => {
      setTags(res);
    });

    Api.getDesigners().then(res => {
      setDesigners(res)
    });

    Api.getExtDesigners().then(res => {
      setExtDesigners(res)
    })

    Api.getCoordinators().then(res => {
      setCoordinators(res);
    });

    Api.getArtFinalists().then(res => {
      setArtFinalists(res);
    });

    Api.getClients().then(res => {
      setClients(res);
    });

    Api.getPresentationsMini().then(res => {
      setPresentations(res);
    });

    Api.getFabrics().then(res => {
      setFabrics(res.results);
    });

    Api.getAllPrintBriefings(id).then(res => {
      const currentBriefings = res.result;
      setBriefings(currentBriefings);
      Api.getBriefings().then(briefs => {
        if (briefs && briefs.result) {
          const availableBriefings = briefs.result.map(b => ({
            ...b,
            name: b.text
          }));
          setSelectedBriefings(
            briefs.result
              .map(b => ({ ...b, name: b.text }))
              .filter(b => currentBriefings.find(cb => cb.id === b.id))
          );

          setBriefingsList(availableBriefings);
        }
      });
    });

    Api.getPrintOrders(id).then(res => {
      if (res && res.result) {
        setOrders(res.result);
      }
    });

    Api.getPrintDoll(id).then(res => {
      setPrintDoll(res);
    });
    Api.getCustomizationByPrintId(id).then(res => {
      if (res.results.length) {
        setCustomization(res.results[0]);
      }
    });
    
    Api.getExclusivity(id).then(res => {
      if(res.results.length) {
        setExclusivities(res.results);
      }
    })
  }, []);

  const closeAllSelectors = () => {
    setCoordinatorSelector('closed');
    setCollectionsSelector('closed');
    setDesignerSelector('closed');
    setArtFinalistSelector('closed');
    setTypeSelector('closed');
    setReviewSelector('closed');
    setProvenienceSelector('closed');
    setBriefingSelector(false);
    setTagsSelector('closed');
  };

  useEffect(() => {
    setCoordinatorSelector('closed');
  }, [selectedCoordinator]);
  const coordinatorSelectorRef = useRef();

  useEffect(() => {
    setCollectionsSelector('closed');
  }, [selectedCollection]);
  const collectionsSelectorRef = useRef();

  useEffect(() => {
    setDesignerSelector('closed');
  }, [selectedDesigner]);
  const designerSelectorRef = useRef();

  useEffect(() => {
    setArtFinalistSelector('closed');
  }, [selectedArtFinalist]);
  const artFinalistSelectorRef = useRef();

  useEffect(() => {
    setTypeSelector('closed');
  }, [selectedType]);
  const typeSelectorRef = useRef();

  useEffect(() => {
    setReviewSelector('closed');
  }, [selectedReview]);
  const reviewSelectorRef = useRef();

  useEffect(() => {
    setProvenienceSelector('closed');
  }, [selectedProvenience]);
  const provenienceSelectorRef = useRef();

  useOutsideClick(briefingSelectorRef, () => {
    if (!briefingSelector) {
      setBriefingSelector(true);
    } else {
      setBriefingSelector(false);
    }
  });

  useOutsideClick(reviewSelectorRef, () => {
    if (reviewSelector === 'closed') {
      setReviewSelector('opened');
    } else {
      setReviewSelector('closed');
    }
  });

  useOutsideClick(collectionsSelectorRef, () => {
    if (collectionsSelector === 'closed') {
      setCollectionsSelector('opened');
    } else {
      setCollectionsSelector('closed');
    }
  });

  useOutsideClick(designerSelectorRef, () => {
    if (designerSelector === 'closed') {
      setDesignerSelector('opened');
    } else {
      setDesignerSelector('closed');
    }
  });
  useOutsideClick(coordinatorSelectorRef, () => {
    if (coordinatorSelector === 'closed') {
      setCoordinatorSelector('opened');
    } else {
      setCoordinatorSelector('closed');
    }
  });
  useOutsideClick(artFinalistSelectorRef, () => {
    if (artFinalistSelector === 'closed') {
      setArtFinalistSelector('opened');
    } else {
      setArtFinalistSelector('closed');
    }
  });

  useOutsideClick(typeSelectorRef, () => {
    if (typeSelector === 'closed') {
      setTypeSelector('opened');
    } else {
      setTypeSelector('closed');
    }
  });

  useOutsideClick(provenienceSelectorRef, () => {
    if (provenienceSelector === 'closed') {
      setProvenienceSelector('opened');
    } else {
      setProvenienceSelector('closed');
    }
  });

  useOutsideClick(tagsSelectorRef, () => {
    if (tagsSelector === 'opened') {
      setTagsSelector('closed');
    }
  });

  const showErrorToast = useCallback(
    error => {
      toast(translate(error, language), {
        type: 'error'
      });
    },
    [language]
  );

  useEffect(() => {
    (async function loadData() {
      setUpdating(true);
      try {
        const getPrint = Api.getPrint(id);
        const getBriefing = await Api.getPrintBriefing(id);
        const getReserves = await Api.getReserves(id);

        await axios.all([getPrint, getBriefing, getReserves]).then(
          axios.spread(async (...responses) => {
            console.log("test print:", responses[0])
            setPrint(responses[0]);
            setWidthVariation(responses[0].is_widhth_variation);
            if (responses[0].flat_background) {
              setInitialHasBackground(true);
              setHasBackground(true);
              setInitialBackgroundColor(responses[0].flat_background_color);
            }
            const briefing = responses[1].results;
            const usedDrawings = briefing.filter(
              bf => !bf.is_a_reference && bf.images
            );
            setReserves(responses[2].results);
            setUsedDrawingsBriefing(usedDrawings);

            let getChromaticVariations = [];
            let getTwins = [];

            try {
              getChromaticVariations = await Api.getChromaticVariations(id);
              getTwins = await Api.getTwins(id);
            } finally {

              //remove after
              console.log("getTwins:", getTwins);
              setChromaticVariations(getChromaticVariations);
              setTwins(getTwins);
              setUpdating(false);
            }
            if (responses[0].origin_id) {
              let getOriginPrint;
              try {
                getOriginPrint = await Api.getPrint(responses[0].origin_id);
              } finally {
                setOriginPrint(getOriginPrint);
              }
            } else {
              setOriginPrint();
            }
          })
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, [id]);

  const getPrintImage = useMemo(() => {
    if (print) {
      if (print.new_design_pending) {
        return language === 'en' ? CustomizedPrint : EstampaCustomizada;
      }
      return print.image_url;
    }
    return '';
  }, [language, print]);

  const sendPrintUpdate = async (field, data, errorMessage) => {
    let res;
    setUpdating(true);
    const formData = new FormData();
    formData.append(field, data);
    try {
      res = await Api.updatePrint(print.id, formData);
    } catch {
      res = print;
      showErrorToast(errorMessage);
    } finally {
      setPrint(res);
      setUpdating(false);
    }
  };

  const changePrintName = async () => {
    sendPrintUpdate('name', updatedPrintName, 'failUpdatingPrintName').then(
      () => {
        setUpdatedPrintName('');
      }
    );
  };

  const changeBackgroundColor = async () => {
    let res;
    setUpdating(true);
    const formData = new FormData();
    if (hasBackground) {
      formData.append('flat_background_color', backgroundColor);
      formData.append('flat_background', true);
    } else {
      formData.append('flat_background', false);
    }
    try {
      res = await Api.updatePrint(print.id, formData);
      setInitialBackgroundColor(backgroundColor);
      setBackgroundColor();
      setInitialHasBackground(hasBackground);
    } catch {
      res = print;
      showErrorToast('failUpdatingBackgroundColor');
    } finally {
      setPrint(res);
      setUpdating(false);
    }
  };

  const changeWidthVariation = async () => {
    let res;
    setUpdating(true);
    const formData = new FormData();
    // if (widthVariation) {
    //   formData.append('is_widhth_variation', true);
    // } else {
    //   formData.append('is_widhth_variation', false);
    // }
    formData.append('is_widhth_variation', !widthVariation);
    try {
      res = await Api.updatePrint(print.id, formData)
    } catch {
      res = print;
      showErrorToast('failUpdatingBackgroundColor');
    } finally {
      setPrint(res);
      setUpdating(false);
    }
  };

  const changeCollection = async () => {
    sendPrintUpdate(
      'collection',
      selectedCollection.id,
      'failUpdatingCollection'
    ).then(() => {
      setSelectedCollection(null);
    });
  };

  const changeDesigner = async () => {
    sendPrintUpdate(
      'designer',
      selectedDesigner.id,
      'failUpdatingDesigner'
    ).then(() => {
      setSelectedDesigner(null);
    });
  };

  const changeCoordinator = async () => {
    sendPrintUpdate(
      'coordinator',
      selectedCoordinator.id,
      'failUpdatingCoordinator'
    ).then(() => {
      setSelectedCoordinator(null);
    });
  };

  const changeArtFinalist = async () => {
    sendPrintUpdate(
      'art_finisher',
      selectedArtFinalist.id,
      'failUpdatingArtFinalist'
    ).then(() => {
      setSelectedArtFinalist(null);
    });
  };

  const changeType = async () => {
    sendPrintUpdate('type', selectedType.id, 'failUpdatingType').then(() => {
      setSelectedType(null);
    });
  };

  const changeColorVariant = async () => {
    let res;
    setUpdating(true);
    const formData = new FormData();
    if (updatedlCode) {
      formData.append('color_variant', updatedlCode);
    }
    try {
      res = await Api.updatePrint(print.id, formData);
      setUpdatedlCode('');
    } catch {
      res = print;
      showErrorToast('failUpdatingRapport');
    } finally {
      setPrint(res);
      setUpdating(false);
    }
  };

  const changeRapport = async () => {
    let res;
    setUpdating(true);
    const formData = new FormData();
    if (updatedlCode) {
      formData.append('color_variant', updatedlCode);
    }
    if (updatedRapportWidth) {
      formData.append('jpeg_width', updatedRapportWidth);
    }
    if (updatedRapportHeight) {
      formData.append('jpeg_height', updatedRapportHeight);
    }
    try {
      res = await Api.updatePrint(print.id, formData);
      setUpdatedRapportWidth('');
      setUpdatedRapportHeight('');
      setUpdatedlCode('');
    } catch {
      res = print;
      showErrorToast('failUpdatingRapport');
    } finally {
      setPrint(res);
      setUpdating(false);
    }
  };

  const changeReview = async () => {
    sendPrintUpdate(
      'already_reviewed',
      selectedReview.value,
      'failUpdatingReview'
    ).then(() => {
      setSelectedReview();
    });
  };

  const changeProvenience = async () => {
    sendPrintUpdate(
      'provenience',
      selectedProvenience.id,
      'failUpdatingProvenience'
    ).then(() => {
      setSelectedProvenience();
    });
  };

  const changeTags = async () => {
    sendPrintUpdate(
      'tag_ids',
      selectedTags.map(t => t.id).join(','),
      'failUpdatingTags'
    ).then(() => {
      setSelectedTags(null);
    });
  };

  const calcProgress = progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setSavingProgress(percentCompleted);
  };

  const progressConfig = {
    onUploadProgress(progressEvent) {
      calcProgress(progressEvent);
    }
  };

  async function changePrintFiles() {
    if (
      jpgPrint.length ||
      psdDesigner.length ||
      psdFinalistArt.length ||
      psdFinalistArtFlirted.length
    ) {
      const formData = new FormData();
      if (jpgPrint.length > 0) {
        if (
          !jpgPrint[0].name.toUpperCase().includes('.JPG') &&
          !jpgPrint[0].name.toUpperCase().includes('.JPEG') &&
          !jpgPrint[0].name.toUpperCase().includes('.PNG')
        ) {
          setJpgPrint([]);
          toast(translate('invalidFileFormat', language), {
            type: 'error'
          });
        } else {
          formData.append('image', jpgPrint[0]);
          setJpgPrint([]);
          let res;
          try {
            res = await Api.updatePrint(print.id, formData, progressConfig);
          } catch {
            res = print;
            showErrorToast('failUpdatingPrint');
          } finally {
            setPrint(res);
            setSavingProgress(false);
            setJpgPrint([]);
          }
        }
      }

      if (psdDesigner.length > 0) {
        const file = psdDesigner[0];
        formData.append('psd_original', file);
        setPsdDesigner([]);
        let res;
        try {
          res = await Api.updatePrint(print.id, formData, progressConfig);
        } catch {
          res = print;
          showErrorToast('failUpdatingPrint');
        } finally {
          // const updatedPrint = await Api.getPrint(print.id);
          // setPrint(updatedPrint);
          setPsdDesigner([]);
          setPrint(res);
          setSavingProgress(false);
        }
      }
      if (psdFinalistArt.length > 0) {
        const file = psdFinalistArt[0];
        formData.append('psd_final', file);
        setPsdFinalistArt([]);
        let res;
        try {
          res = await Api.updatePrint(print.id, formData, progressConfig);
        } catch {
          res = print;
          showErrorToast('failUpdatingPrint');
        } finally {
          setPsdFinalistArt([]);
          setPrint(res);
          setSavingProgress(false);
        }
      }
      if (psdFinalistArtFlirted.length > 0) {
        const file = psdFinalistArtFlirted[0];
        formData.append('psd_flirted', file);
        setPsdFinalistArtFlirted([]);
        let res;
        try {
          res = await Api.updatePrint(print.id, formData, progressConfig);
        } catch {
          res = print;
          showErrorToast('failUpdatingPrint');
        } finally {
          setPsdFinalistArtFlirted([]);
          setPrint(res);
          setSavingProgress(false);
        }
      }
    }
  }
  useEffect(() => {
    changePrintFiles();
  }, [
    jpgPrint,
    language,
    print,
    progressConfig,
    psdDesigner,
    psdFinalistArt,
    psdFinalistArtFlirted,
    showErrorToast
  ]);

  const getProvenience = useMemo(() => {
    if (print && print.provenience) {
      switch (print.provenience) {
        case 'HOM':
          return 'La Estampa Home';
        case 'ART':
          return 'La Estampa/Arts';
        case 'INT':
          return 'Internacional';
        case 'EXC':
          return 'Desenvolvimento Exclusivo';
        case 'CLI':
          return 'Estampa Cliente';
        case 'LE':
          return 'LE Collection';
        case 'MAT':
          return 'Matteria';
        case 'STD': 
          return 'Studio';
        default:
          return '';
      }
    }
    return '';
  }, [print]);

  const loadBriefing = useCallback(async () => {
    setUpdating(true);
    try {
      const getBriefing = await Api.getPrintBriefing(id);

      const briefing = getBriefing.results;

      const usedDrawings = briefing.filter(
        bf => !bf.is_a_reference && bf.images
      );

      setUsedDrawingsBriefing([...usedDrawings]);
    } finally {
      setUpdating(false);
    }
  }, [id]);

  useEffect(() => {
    (async function updateVisualRefBriefing() {
      const requestArray = [];
      if (updatedVisualRefBriefing.length) {
        let exceded = false;
        updatedVisualRefBriefing.forEach(visualRef => {
          if ((visualRef.size / (1024 * 1024)).toFixed(2) > 50) {
            exceded = true;
            setUpdatedVisualRefBriefing([]);
          }
        });
        if (exceded) {
          toast(translate('fileSizeExceded', language), {
            type: 'error'
          });
        } else {
          updatedVisualRefBriefing.forEach((visualRef, index) => {
            const briefingFormData = new FormData();
            briefingFormData.append('print', print.id);
            briefingFormData.append('text', 'Visual References');
            briefingFormData.append('is_a_reference', true);
            briefingFormData.append('images', visualRef);
            if (index === updatedVisualRefBriefing.length - 1) {
              briefingFormData.append('last_briefing', true);
            }
            const res = Api.createPrintBriefing(briefingFormData);

            requestArray.push(res);
          });
          setUpdating(true);
          try {
            await axios.all(requestArray).then(
              axios.spread((...responses) => {
                return responses;
              })
            );
          } finally {
            setUpdatedVisualRefBriefing([]);
            loadBriefing();
            setUpdating(false);
          }
        }
      }
    })();
  }, [language, loadBriefing, print, showErrorToast, updatedVisualRefBriefing]);

  useEffect(() => {
    (async function updateUsedDrawingsBriefing() {
      const requestArray = [];
      if (updatedUsedDrawingsBriefing.length) {
        let exceded = false;
        updatedUsedDrawingsBriefing.forEach(visualRef => {
          if ((visualRef.size / (1024 * 1024)).toFixed(2) > 50) {
            exceded = true;
            setUpdatedUsedDrawingsBriefing([]);
          }
        });
        if (exceded) {
          toast(translate('fileSizeExceded', language), {
            type: 'error'
          });
        } else {
          updatedUsedDrawingsBriefing.forEach(async (visualRef, index) => {
            const briefingFormData = new FormData();
            briefingFormData.append('print', print.id);
            briefingFormData.append('text', 'Used Drawings');
            briefingFormData.append('is_a_reference', false);
            //briefingFormData.append('images', visualRef);
            // if (index === updatedUsedDrawingsBriefing.length - 1) {
            //   briefingFormData.append('last_briefing', true);
            // }
            const res = await Api.createPrintBriefing(briefingFormData)
              try {
                 setUpdating(true);
                 await uploadNewFile(
                    `${visualRef.name}`,
                    visualRef,
                    `briefing`,
                  )
                  await Api.psdFileSuccess({
                    usedDraw_id: res.id,
                    path: `briefing/${visualRef.name}`,
                    file_field: 'print_briefing_image'
                  })
              } catch(err) {
                toast(translate('failSavingUsedDrawings', language), {
                  type: 'error'
                });
              } finally {
                setUpdatedUsedDrawingsBriefing([]);
                loadBriefing();
                setUpdating(false);
              }
              });
          
          // try {
          //   await axios.all(requestArray).then(
          //     axios.spread((...responses) => {
          //       return responses;
          //     })
          //   );
          // } catch {
          //   showErrorToast('failSavingUsedDrawings');
          
        }
      }
    })();
  }, [
    language,
    loadBriefing,
    print,
    showErrorToast,
    updatedUsedDrawingsBriefing,
    updatedVisualRefBriefing
  ]);

  const deleteVisualRefBriefing = () => {
    setConfirmationModal(false);
    setUpdating(true);
    try {
      Api.deletePrintBriefing(deleteBriefingIntent).then(() => {
        loadBriefing();
      });
    } catch {
      showErrorToast('failDeletingImage');
    } finally {
      setUpdating(false);
    }
  };

  const closeCreateVariationModal = async () => {
    setNewChromaticVariation(false);
    setNewTwin(false);
    try {
      const getChromaticVariations = await Api.getChromaticVariations(id);
      const getTwins = await Api.getTwins(id);

      setChromaticVariations(getChromaticVariations);
      setTwins(getTwins);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const deletePrint = async () => {
    setConfirmationModal(false);
    setUpdating(true);
    try {
      await Api.deletePrint(id);
      if(print.is_origin == true) {
        await Api.deleteFamily(print.family_id);
      }
      history.push('/library');
      toast(translate('successDeletingPrint', language), {
        type: 'success'
      });
    } catch {
      showErrorToast('failDeletingPrint');
    } finally {
      setUpdating(false);
    }
  };

  const approvePrint = () => {
    setUpdating(true);
    const formData = new FormData();
    formData.append('status', 'APP');
    Api.updatePrint(id, formData)
      .then(() => {
        Api.getPrint(id).then(res => {
          setPrint(res);
        });
      })
      .catch(() => {
        toast(translate('failUpdatingPrint', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const openDeleteImageModal = briefingId => {
    setDeleteBriefingIntent(briefingId);
    setConfirmationModalTitle(translate('deleteImage', language));
    setConfirmationModalMessage(translate('deleteImageMessage', language));
    setConfirmationModalConfirmText(translate('deleteConfirmText', language));
    setConfirmationModalCancelText(translate('deleteCancelText', language));
    setConfirmationModal(true);
  };

  const openDeletePrintModal = () => {
    setDeleteBriefingIntent();
    setConfirmationModalTitle(translate('deletePrint', language));
    setConfirmationModalMessage(
      `${translate('deletePrintMessage', language)} ${print.code} ?`
    );
    setConfirmationModalConfirmText(translate('deleteConfirmText', language));
    setConfirmationModalCancelText(translate('deleteCancelText', language));
    setConfirmationModal(true);
  };

  const openCreateCollection = () => {
    setCreateCollectionModal(true);
    setCollectionsSelector('closed');
  };

  const createdCollection = async col => {
    setCreateCollectionModal(false);
    setUpdating(true);
    const getCollections = await Api.getCollections();
    setCollections(getCollections);
    setSelectedCollection(col);
    setUpdating(false);
  };

  const getCollectionType = useCallback(() => {
    if (print && print.collection_id) {
      const col = collections.find(c => c.id === print.collection_id);
      if (col && col.collection_type) {
        return col.collection_type;
      }
    }
    return null;
  }, [collections, print]);

  const getCurrentReservations = useMemo(() => {
    const currentReservations = [];

    reserves.forEach(r => {
      if (
        r.date_request &&
        r.date_end &&
        moment().isAfter(r.date_request) &&
        moment().isBefore(r.date_end)
      ) {
        currentReservations.push({
          date_request: r.date_request,
          date_end: r.date_end,
          client:
            r.buyer_name ||
            r.client_name ||
            translate('unreportedCustomer', language)
        });
      }
    });
    return currentReservations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, reserves]);

  const saveBriefings = () => {
    setBriefingSelector(false);
    setUpdating(true);
    Api.addBriefingPrints({
      print: parseInt(id, 10),
      briefings: selectedBriefings.map(b => b.id)
    })
      .then(() => {
        toast(translate('successAddingPrintToBriefings', language), {
          type: 'success'
        });
      })
      .catch(() => {
        toast(translate('errorAddingPrintToBriefings', language), {
          type: 'error'
        });
      })
      .finally(() => {
        setUpdating(true);
        Api.getAllPrintBriefings(id).then(updatedBriefings => {
          setBriefings(updatedBriefings.result);
          Api.getBriefings()
            .then(briefs => {
              if (briefs && briefs.result) {
                const availableBriefings = briefs.result.map(b => ({
                  ...b,
                  name: b.text
                }));
                setSelectedBriefings(
                  briefs.result
                    .map(b => ({ ...b, name: b.text }))
                    .filter(b =>
                      updatedBriefings.result.find(cb => cb.id === b.id)
                    )
                );

                setBriefingsList(availableBriefings);
              }
            })
            .finally(() => {
              setUpdating(false);
            });
        });
      });
  };

  const includePrintPresentation = () => {
    setPresentationSection('presentation');
    setPresentationSelector(false);

    if (selectedFabrics.length) {
      selectedFabrics.forEach(fabric => {
        const formData = new FormData();
        formData.append('fabric', fabric.id);
        formData.append('print', id);
        Api.createProduct(formData).then(product => {
          Api.addSelectedPrintToPresentation(selectedPresentation[0].id, {
            product_id: product.id,
            print_id: id
          })
            .then(() => {
              toast(
                `${translate(
                  'successAddingProductToPresentation',
                  language
                )} : ${print.code}-${fabric.name}`,
                {
                  type: 'success'
                }
              );
            })
            .catch(() => {
              toast(
                `${translate('failAddingProductToPresentation', language)}: ${
                  print.code
                }-${fabric.name}`,
                {
                  type: 'error'
                }
              );
            })
            .finally(() => {
              setSelectedPresentation([]);
              setSelectedFabrics([]);
            });
        });
      });
    } else {
      const formData = new FormData();

      formData.append('print', id);
      Api.createProduct(formData).then(product => {
        Api.addSelectedPrintToPresentation(selectedPresentation[0].id, {
          product_id: product.id,
          print_id: id
        })
          .then(() => {
            toast(
              `${translate('successAddingProductToPresentation', language)} : ${
                print.code
              }`,
              {
                type: 'success'
              }
            );
          })
          .catch(() => {
            toast(
              `${translate('failAddingProductToPresentation', language)}: ${
                print.code
              }`,
              {
                type: 'error'
              }
            );
          })
          .finally(() => {
            setSelectedPresentation([]);
            setSelectedFabrics([]);
          });
      });
    }
  };

  const addPreviousVersion = async file => {
    let res;
    setUpdating(true);
    const formData = new FormData();
    formData.append('print', id);
    formData.append('historic_one', file);
    try {
      res = await Api.updatePrint(print.id, formData);
    } catch {
      res = print;
      showErrorToast('failAddingPreviousVersion');
    } finally {
      setPrint(res);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (
      selectedPresentation.length &&
      !selectedPresentation.includes(
        presentation => presentation.id === lastPresentation
      )
    ) {
      if (selectedPresentation.length === 1) {
        setLastPresentation(selectedPresentation[0]);
      } else {
        const newPresentation = selectedPresentation.filter(
          presentation => presentation.id !== lastPresentation.id
        );
        setSelectedPresentation(newPresentation);
      }
    }

    if (selectedPresentation.length) {
      setPresentationSection('fabric');
    }
  }, [lastPresentation, selectedPresentation]);

  useEffect(() => {
    const jpgHeight = print && print.jpeg_height ? print.jpeg_height : 0;
    const jpgWidth = print && print.jpeg_width ? print.jpeg_width : 0;
    const jpgRatio = jpgHeight && jpgWidth ? jpgHeight / jpgWidth : 0;
    const printRatio =
      printNaturalHeight && printNaturalWidth
        ? printNaturalHeight / printNaturalWidth
        : 0;

    if (jpgRatio < printRatio * 0.95 || jpgRatio > printRatio * 1.05) {
      setPrintRatioWarning(true);
    } else {
      setPrintRatioWarning(false);
    }
  }, [print, printNaturalHeight, printNaturalWidth]);

  const seeCustomization = () => {
    const currentUrl = window.location.href;
    const mainUrl = currentUrl.split(location.pathname)[0];
    window.open(`${mainUrl}/print-customization/${customization.id}`, '_blank');
  };

  const deleteHistoricOne = () => {
    setUpdating(true);
    Api.deleteHistoricPrint(print.id, { historic_one: null })
      .then(res => {
        setPrint(res);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const deleteHistoricTwo = () => {
    setUpdating(true);
    Api.deleteHistoricPrint(print.id, { historic_two: null })
      .then(res => {
        setPrint(res);
      })
      .finally(() => {
        setUpdating(false);
      });
  };
  const deleteHistoricThree = () => {
    setUpdating(true);
    Api.deleteHistoricPrint(print.id, { historic_three: null })
      .then(res => {
        setPrint(res);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const addToCart = () => {
    const formData = new FormData();
    formData.append('print', id);
    setUpdating(true);

    Api.createProduct(formData)
      .then(async product => {
        const newProduct = {
          ...product,
          client: orders[0].client,
          seller: user.id,
          action: null,
          meters: '',
          product_id: product.id,
          print_id: id,
          initialFabrics: []
        };
        dispatch(addProductsOnCart([newProduct]));
        toast(translate('printAddedToCart', language), {
          type: 'success'
        });
        setCart(orders[0].client);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const renderDollElements = useMemo(() => {
    const currentDoll = printDoll;

    const isNewDoll =
      currentDoll &&
      currentDoll.masks &&
      currentDoll.masks.length &&
      currentDoll.masks.length > 0;

    if (currentDoll) {
      if (isNewDoll && print) {
        return (
          <div className={styles.dollContainerWrapper}>
            <div className={styles.dollContainer}>
              <div className={styles.dollBackgroundContainer}>
                {currentDoll.masks.map(mask => (
                  <div
                    className={styles.dollMask}
                    style={{
                      WebkitMaskImage: `url(${mask.mask})`,
                      maskImage: `url(${mask.mask})`
                    }}
                  >
                    {print?.jpeg_width && (
                      <div
                        className={styles.fabric}
                        style={{
                          backgroundImage: `url(${getPrintImage})`,
                          backgroundColor: print.flat_background
                            ? backgroundColor || print.flat_background_color
                            : '#fff',
                          backgroundSize: print?.jpeg_width
                            ? `auto ${calcDollRapport(
                                print?.jpeg_width,
                                print?.jpeg_height,
                                currentDoll?.rapport_propor
                              ) / 32}%`
                            : '3.125%'
                        }}
                        alt="print"
                      />
                    )}
                  </div>
                ))}
              </div>
              <img
                className={styles.object}
                ref={dollRef}
                src={currentDoll.image_dolly || currentDoll.image_dolly_url}
                alt="boneca"
              />
              <img
                src={currentDoll.image_shadow || currentDoll.image_shadow_url}
                className={styles.fabricShadow}
                alt="vestido"
              />
            </div>
          </div>
        );
      }

      return (
        printDoll && (
          <div className={styles.dollContainerWrapper}>
            <div className={styles.dollContainer}>
              <div
                className={styles.dollBackgroundContainer}
                style={{
                  backgroundColor:
                    print && print.flat_background
                      ? backgroundColor || print.flat_background_color
                      : '#fff'
                }}
              >
                {print && print?.jpeg_width && (
                  <div
                    style={{
                      backgroundImage: `url(${getPrintImage})`,
                      backgroundSize: print?.jpeg_width
                        ? `auto ${calcDollRapport(
                            print?.jpeg_width,
                            print?.jpeg_height,
                            print?.rapport_propor
                          ) / 32}%`
                        : '3.125%',
                      top: `calc(50% - ${currentDoll.rapport_propor || 0}%)`
                    }}
                    className={styles.fabric}
                    alt="print"
                  />
                )}
              </div>
              <img
                ref={dollRef}
                className={styles.object}
                src={currentDoll.image_dolly || currentDoll.image_dolly_url}
                alt="boneca"
              />
              <img
                src={currentDoll.image_shadow || currentDoll.image_shadow_url}
                className={styles.fabricShadow}
                alt="vestido"
              />
              <div className={styles.adjustment} />
            </div>
          </div>
        )
      );
    }

    return null;
  }, [print, backgroundColor, printDoll]);

  return (
    <div className={styles.print}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>
          <span className={styles.title}>
            {translate('library', language).toUpperCase()}
          </span>
          {print && (
            <span className={styles.printName}>
              {print.name ? print.name.toUpperCase() : ''}
            </span>
          )}
        </h2>
        <ul className={styles.commentsMenu}>
          <li>{translate('register', language)}</li>
          <li>
            <button type="button" onClick={() => setShowChat(true)}>
              {translate('comments', language)}
            </button>
          </li>
        </ul>
      </div>

      <form className={styles.editPrint}>
        <>
          <section className={styles.editSection}>
            <div className={styles.editSubsection}>
              <label className={styles.inputLabel} htmlFor="print_name">
                <span className={`${styles.label} ${styles.dark}`}>
                  {translate('printName', language)}
                </span>
                <input
                  id="print_name"
                  type="text"
                  className={styles.defaultTextInput}
                  placeholder={print ? print.name : ''}
                  value={updatedPrintName}
                  onChange={e => setUpdatedPrintName(e.currentTarget.value)}
                  disabled={isExternalDesigner}
                />
                {updatedPrintName && (
                  <div className={styles.actions}>
                    <button
                      className={`${styles.defaultActionButton}`}
                      type="button"
                      onClick={changePrintName}
                    >
                      {translate('saveChange', language)}
                    </button>
                    <button
                      className={`${styles.defaultDeleteButton}`}
                      type="button"
                      onClick={() => setUpdatedPrintName('')}
                    >
                      {translate('cancel', language)}
                    </button>
                  </div>
                )}
              </label>
              <div className={`${styles.label} ${styles.dark}`}>
                <span className={`${styles.label} ${styles.dark}`}>
                  {' '}
                  {translate('lCode', language)}
                </span>
                {print && print.print_code ? (
                  <div>
                    <span className={styles.lCode}>{print.print_code}</span>
                  </div>
                ) : (
                  <span className={styles.lCode}>
                    {translate('requiredFinalistArt', language)}
                  </span>
                )}
                <span className={`${styles.label} ${styles.dark}`}>
                  {' '}
                  {translate('print_color_variant', language)}
                </span>
                {print && print.print_color_variant ? (
                  <input
                    id="print_code"
                    type="string"
                    placeholder={print.print_color_variant}
                    className={styles.defaultTextInput}
                    value={updatedlCode}
                    disabled={isExternalDesigner}
                    onChange={e =>
                      setUpdatedlCode(e.currentTarget.value)
                    }
                  />
                ) : (
                  <span className={styles.lCode}>
                    
                  </span>
                )}
              </div>
              {(updatedlCode) && (
                <div style={{position: "relative", marginBottom: "15px"}} className={styles.actions}>
                  <button
                    className={`${styles.defaultActionButton}`}
                    type="button"
                    onClick={changeColorVariant}
                  >
                    {translate('saveChange', language)}
                  </button>
                  <button
                    className={`${styles.defaultDeleteButton}`}
                    type="button"
                    onClick={() => {
                      setUpdatedlCode('');
                    }}
                  >
                    {translate('cancel', language)}
                  </button>
                </div>
              )}
              {printRatioWarning ? (
                <p className={styles.printRatioWarning}>
                  {translate('printRatioWarning', language)}
                </p>
              ) : null}
              {presentations && presentations.length > 0 && (
                <div className={`${styles.label} ${styles.dark}`}>
                  <span className={`${styles.label} ${styles.dark}`}>
                    {' '}
                    {translate('choosePresentation', language)}
                  </span>
                  <div className={styles.presentationWrapper}>
                    <div className={styles.presentationSelector}>
                      <button
                        type="button"
                        className={`${styles.defaultSelectInput}`}
                        disabled={isExternalDesigner}
                        onClick={e => {
                          e.stopPropagation();
                          setPresentationSelector(!presentationSelector);
                        }}
                      >
                        {!selectedPresentation.length
                          ? translate('choosePresentation', language)
                          : selectedPresentation[0].name}
                      </button>
                      {presentationSelector && (
                        <span
                          className={styles.selectorPosition}
                          ref={presentationSelectorRef}
                        >
                          <div className={styles.steps}>
                            <button
                              type="button"
                              className={
                                presentationSection === 'presentation' &&
                                styles.active
                              }
                              onClick={() =>
                                setPresentationSection('presentation')
                              }
                            >
                              {translate('presentation', language)}
                            </button>{' '}
                            <span className={styles.separator}>{'>'}</span>
                            <button
                              type="button"
                              className={
                                presentationSection === 'fabric' &&
                                styles.active
                              }
                              onClick={() => setPresentationSection('fabric')}
                            >
                              {translate('fabric', language)}
                            </button>
                          </div>
                          <div className={styles.list}>
                            {presentationSection === 'presentation' ? (
                              <ListSelector
                                label={translate('mainPresentations', language)}
                                selectLabel={translate('choose', language)}
                                items={presentations}
                                onSelect={setSelectedPresentation}
                                values={selectedPresentation}
                                action={() =>
                                  history.push('/create-presentation')
                                }
                                actionText={translate(
                                  'createPresentation',
                                  language
                                )}
                                multiple
                                fullWidth
                                search
                              />
                            ) : (
                              <ListSelector
                                label={translate('applyFixedFabric', language)}
                                selectLabel={translate('choose', language)}
                                items={fabrics}
                                onSelect={setSelectedFabrics}
                                values={selectedFabrics}
                                action={includePrintPresentation}
                                actionText={translate('save', language)}
                                multiple
                                fullWidth
                                search
                              />
                            )}
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <br />
              {briefingsList && briefingsList.length > 0 && (
                <div className={`${styles.label} ${styles.dark}`}>
                  <span className={`${styles.label} ${styles.dark}`}>
                    {' '}
                    {translate('chooseBriefings', language)}
                  </span>
                  <div className={styles.presentationWrapper}>
                    <div className={styles.briefingSelector}>
                      <button
                        type="button"
                        className={`${styles.defaultSelectInput}`}
                        disabled={isExternalDesigner}
                        onClick={e => {
                          e.stopPropagation();
                          closeAllSelectors();
                          setBriefingSelector(!briefingSelector);
                        }}
                      >
                        {translate('chooseBriefings', language)}
                      </button>
                      {briefingSelector && (
                        <span
                          className={styles.selectorPosition}
                          ref={briefingSelectorRef}
                        >
                          <div className={styles.list}>
                            <ListSelector
                              selectLabel={translate('choose', language)}
                              items={briefingsList}
                              onSelect={setSelectedBriefings}
                              values={selectedBriefings}
                              multiple
                              fullWidth
                              search
                              actionText={translate('save', language)}
                              action={saveBriefings}
                            />
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <SectionDivisor title={translate('archives', language)} big />
              <label className={styles.inputLabel} htmlFor="print_name">
                <span className={`${styles.label} ${styles.strong}`}>
                  {' '}
                  {translate('jpg', language)}
                </span>
                <div className={styles.inlineOptions}>
                  <input
                    id="print_name"
                    type="text"
                    className={styles.defaultTextInput}
                    placeholder={print && print.code ? `${print.code}.jpg` : ''}
                    disabled={isExternalDesigner}
                  />
                  <span className={styles.fileSelectorContainer}>
                    <FileSelector
                      files={jpgPrint}
                      onSelect={setJpgPrint}
                      disabled={isExternalDesigner}
                      icon
                    />
                  </span>
                  <a
                    href={getPrintImage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.download}
                  >
                    <DownloadIcon />
                  </a>
                </div>
                {print && (
                  <div className={styles.lastUpdate}>
                    <span className={styles.label}>
                      {translate('lastUpdate', language)}
                    </span>
                    <span>
                      <span className={styles.name}>
                        {print.image_last_updated_by || print.designer_name}
                      </span>{' '}
                      |{' '}
                      <span className={styles.time}>
                        <Moment
                          filter={d => d.charAt(0).toUpperCase() + d.slice(1)}
                          styles={styles.time}
                          fromNow
                          locale={language}
                        >
                          {print.image_last_update}
                        </Moment>
                      </span>
                    </span>
                  </div>
                )}
              </label>
              <label className={styles.inputLabel} htmlFor="print_name">
                <span className={`${styles.label} ${styles.strong}`}>
                  {translate('psdDesigner', language)}
                </span>
                <div className={styles.inlineOptions}>
                  <input
                    id="print_name"
                    type="text"
                    className={styles.defaultTextInput}
                    placeholder={print && print.psd_original_file_name ? `${print.psd_original_file_name}`: ''}
                    disabled={isExternalDesigner}
                  />
                  <span className={styles.fileSelectorContainer}>
                    <FileSelector
                      files={psdDesigner}
                      onSelect={setPsdDesigner}
                      disabled={isExternalDesigner}
                      icon
                    />
                  </span>
                  <a
                    href={print ? print.psd_original_url : ''}
                    rel="noopener noreferrer"
                    className={styles.download}
                  >
                    <DownloadIcon />
                  </a>
                </div>
                {print && print.psd_original_url && (
                  <div className={styles.lastUpdate}>
                    <span className={styles.label}>
                      {translate('lastUpdate', language)}
                    </span>
                    <span>
                      <span className={styles.name}>
                        {print.psd_original_last_updated_by ||
                          print.designer_name}
                      </span>{' '}
                      |{' '}
                      <span className={styles.time}>
                        <Moment
                          fromNow
                          locale={language}
                          filter={d => d.charAt(0).toUpperCase() + d.slice(1)}
                        >
                          {print.psd_original_last_update}
                        </Moment>
                      </span>
                    </span>
                  </div>
                )}
              </label>
              <label className={styles.inputLabel} htmlFor="print_name">
                <span className={`${styles.label} ${styles.strong}`}>
                  {translate('psdFinalistArt', language)}
                </span>
                <div className={styles.inlineOptions}>
                  <input
                    id="print_name"
                    type="text"
                    className={styles.defaultTextInput}
                    placeholder={print && print.psd_final_file_name ? `${print.psd_final_file_name}`: ''}
                    disabled={isExternalDesigner}
                  />
                  <span className={styles.fileSelectorContainer}>
                    <FileSelector
                      files={psdFinalistArt}
                      onSelect={setPsdFinalistArt}
                      disabled={isExternalDesigner}
                      icon
                    />
                  </span>
                  <a
                    href={print ? print.psd_final_url : ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.download}
                  >
                    <DownloadIcon />
                  </a>
                </div>
                {print && print.psd_final_url && (
                  <div className={styles.lastUpdate}>
                    <span className={styles.label}>
                      {translate('lastUpdate', language)}
                    </span>
                    <span>
                      <span className={styles.name}>
                        {print.psd_final_last_updated_by}
                      </span>{' '}
                      |{' '}
                      <span className={styles.time}>
                        {print.psd_final_last_update ? (
                          <Moment
                            fromNow
                            filter={d => d.charAt(0).toUpperCase() + d.slice(1)}
                          >
                            {print.psd_final_last_update}
                          </Moment>
                        ) : (
                          ''
                        )}
                      </span>
                    </span>
                  </div>
                )}
              </label>
              <label className={styles.inputLabel} htmlFor="print_name">
                <span className={`${styles.label} ${styles.strong}`}>
                  {translate('psdFlirted', language)}
                </span>
                <div className={styles.inlineOptions}>
                  <input
                    id="print_name"
                    type="text"
                    className={styles.defaultTextInput}
                    placeholder={print && print.psd_flirted_file_name ? `${print.psd_flirted_file_name}` : ''}
                    disabled={isExternalDesigner}
                  />
                  <span className={styles.fileSelectorContainer}>
                    <FileSelector
                      files={psdFinalistArtFlirted}
                      onSelect={setPsdFinalistArtFlirted}
                      disabled={isExternalDesigner}
                      icon
                    />
                  </span>
                  <a
                    href={print ? print.psd_flirted_url : ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.download}
                  >
                    <DownloadIcon />
                  </a>
                </div>
                {print && print.psd_flirted_url && (
                  <div className={styles.lastUpdate}>
                    <span className={styles.label}>
                      {translate('lastUpdate', language)}
                    </span>
                    <span>
                      <span className={styles.name}>
                        {print.psd_flirted_last_updated_by}
                      </span>{' '}
                      |{' '}
                      <span className={styles.time}>
                        {print.psd_flirted_last_updated ? (
                          <Moment
                            fromNow
                            filter={d => d.charAt(0).toUpperCase() + d.slice(1)}
                          >
                            {print.psd_flirted_last_updated}
                          </Moment>
                        ) : (
                          ''
                        )}
                      </span>
                    </span>
                  </div>
                )}
              </label>
              {print && (print.new_design_pending || print.is_customization) && (
                <button
                  className={styles.defaultRoundedActionButton}
                  type="button"
                  onClick={seeCustomization}
                  disabled={isExternalDesigner}
                >
                  {translate('seeCustomization', language)}
                </button>
              )}
              <SectionDivisor title="Fundo Editável" big />
              <div className={styles.colorPickerContainer}>
                <CheckInput
                  value={hasBackground}
                  onChange={setHasBackground}
                  text={translate('editableBackground', language)}
                  disabled={isExternalDesigner}
                />
                {hasBackground && (
                  <>
                    <div className={styles.backgroundColorAdvice}>
                      <span className={styles.important}>
                        {translate('important', language)}
                      </span>{' '}
                      {translate('backgroundColoAdvice', language)}{' '}
                    </div>
                    <ColorPicker
                      disableAlpha
                      color={backgroundColor || initialBackgroundColor}
                      onChange={color => setBackgroundColor(color.hex)}
                    />
                  </>
                )}
                {((backgroundColor &&
                  initialBackgroundColor !== backgroundColor) ||
                  (initialHasBackground !== hasBackground &&
                    hasBackground &&
                    backgroundColor) ||
                  (initialHasBackground !== hasBackground &&
                    !hasBackground)) && (
                  <button
                    className={`${styles.defaultActionButton} ${styles.saveColorChange}`}
                    type="button"
                    onClick={changeBackgroundColor}
                  >
                    {translate('saveChange', language)}
                  </button>
                )}
              </div>
              {print?.is_twin && (
                <>
                  <SectionDivisor title={translate('widthVariation', language)} big />
                  <div>
                    <CheckInput
                      value={widthVariation}
                      onChange={() => { 

                        changeWidthVariation()
                        setWidthVariation(!widthVariation)
                      }}
                      disabled={isExternalDesigner}
                      text={translate('messageWidthVariation', language)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.editSubsection}>
              <button
                type="button"
                onClick={() => setLightBoxImage(getPrintImage)}
                style={{
                  backgroundColor:
                    hasBackground && initialBackgroundColor
                      ? backgroundColor || initialBackgroundColor
                      : '#FFFFFF'
                }}
              >
                {print && getPrintImage ? (
                  <img
                    className={styles.printImage}
                    src={getPrintImage}
                    alt={print ? print.name : ''}
                    onLoad={event => {
                      if (event && event.currentTarget) {
                        if (event.currentTarget.naturalHeight) {
                          setPrintNaturalHeight(
                            event.currentTarget.naturalHeight
                          );
                        }

                        if (event.currentTarget.naturalWidth) {
                          setPrintNaturalWidth(
                            event.currentTarget.naturalWidth
                          );
                        }
                      }
                    }}
                  />
                ) : (
                  ''
                )}
              </button>
              {renderDollElements}
            </div>
          </section>

          <section className={styles.editSection}>
            <div className={styles.editSubsection}>
              <SectionDivisor title={translate('datasheet', language)} big />
              <div className={styles.inlineInfo}>
                <div className={styles.label}>Coleção</div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      id="collection"
                      type="button"
                      className={styles.defaultSelectInput}
                      disabled={isExternalDesigner}
                      onClick={() => {
                        closeAllSelectors();
                        setCollectionsSelector('opened');
                      }}
                    >
                      {selectedCollection
                        ? selectedCollection.name
                        : print && print.collection_name}
                    </button>
                    {collectionsSelector === 'opened' && (
                      <span
                        className={styles.selector}
                        ref={collectionsSelectorRef}
                      >
                        <ListSelector
                          label={translate('mainCollections', language)}
                          selectLabel={translate('choose', language)}
                          items={collections}
                          onSelect={setSelectedCollection}
                          value={
                            selectedCollection || {
                              id: print.collection_id,
                              name: print.collection_name
                            }
                          }
                          action={openCreateCollection}
                          actionText={translate('createCollection', language)}
                          search
                        />
                      </span>
                    )}
                  </div>
                  {selectedCollection && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeCollection}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setSelectedCollection()}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('designer', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      type="button"
                      className={styles.defaultSelectInput}
                      disabled={isExternalDesigner}
                      onClick={() => {
                        closeAllSelectors();
                        setDesignerSelector('opened');
                      }}
                    >
                      {selectedDesigner
                        ? selectedDesigner.name
                        : print && print.designer_name}
                    </button>
                    {designerSelector === 'opened' && (
                      <span
                        className={styles.selector}
                        ref={designerSelectorRef}
                      >
                        <ListSelector
                          label={translate('designers', language)}
                          selectLabel={translate('choose', language)}
                          items={[...designers, ...extDesigners]}
                          onSelect={setSelectedDesigner}
                          value={
                            selectedDesigner || {
                              id: print.designer_id,
                              name: print.designer_name
                            }
                          }
                          search
                        />
                      </span>
                    )}
                  </div>
                  {selectedDesigner && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeDesigner}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setSelectedDesigner()}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('coordinator', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      type="button"
                      className={styles.defaultSelectInput}
                      disabled={isExternalDesigner}
                      onClick={() => {
                        closeAllSelectors();
                        setCoordinatorSelector('opened');
                      }}
                    >
                      {selectedCoordinator
                        ? selectedCoordinator.name
                        : print && print.coordinator_name}
                    </button>
                    {coordinatorSelector === 'opened' && (
                      <span
                        className={styles.selector}
                        ref={coordinatorSelectorRef}
                      >
                        <ListSelector
                          label={translate('coordinators', language)}
                          selectLabel={translate('choose', language)}
                          items={coordinators}
                          onSelect={setSelectedCoordinator}
                          value={
                            selectedCoordinator || {
                              id: print.coordinator_id,
                              name: print.coordinator_name
                            }
                          }
                          search
                        />
                      </span>
                    )}
                  </div>
                  {selectedCoordinator && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeCoordinator}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setSelectedCoordinator()}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('finalistArt', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      type="button"
                      className={styles.defaultSelectInput}
                      disabled={isExternalDesigner}
                      onClick={() => {
                        closeAllSelectors();
                        setArtFinalistSelector('opened');
                      }}
                    >
                      {selectedArtFinalist
                        ? selectedArtFinalist.name
                        : print && print.art_finisher_name}
                    </button>
                    {artFinalistSelector === 'opened' && (
                      <span
                        className={styles.selector}
                        ref={artFinalistSelectorRef}
                      >
                        <ListSelector
                          label={translate('artFinalists', language)}
                          selectLabel={translate('choose', language)}
                          items={artFinalists}
                          onSelect={setSelectedArtFinalist}
                          value={
                            selectedArtFinalist ||
                            (print.artFinalist
                              ? {
                                  id: print.artFinalist_id,
                                  name: print.artFinalist_name
                                }
                              : '')
                          }
                          search
                        />
                      </span>
                    )}
                  </div>
                  {selectedArtFinalist && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeArtFinalist}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setSelectedArtFinalist()}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('provenience', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      type="button"
                      className={styles.defaultSelectInput}
                      disabled={isExternalDesigner}
                      onClick={() => {
                        closeAllSelectors();
                        setProvenienceSelector('opened');
                      }}
                    >
                      {selectedProvenience
                        ? selectedProvenience.name
                        : print && (getProvenience || '')}
                    </button>
                    {provenienceSelector === 'opened' && (
                      <span
                        className={styles.selector}
                        ref={provenienceSelectorRef}
                      >
                        <ListSelector
                          selectLabel={translate('choose', language)}
                          items={[
                            {
                              name: 'La Estampa Home',
                              value: 'HOM',
                              id: 'HOM'
                            },
                            {
                              name: 'La Estampa/ARTS',
                              value: 'ART',
                              id: 'ART'
                            },
                            {
                              name: 'Internacional',
                              value: 'INT',
                              id: 'INT'
                            },
                            {
                              name: 'Desenvolvimento Exclusivo',
                              value: 'EXC',
                              id: 'EXC'
                            },
                            {
                              name: 'Estampa Cliente',
                              value: 'CLI',
                              id: 'CLI'
                            },
                            {
                              name: 'Le Collection',
                              value: 'LE',
                              id: 'LE'
                            },
                            {
                              name: 'Matteria',
                              value: 'MAT',
                              id: 'MAT'
                            },
                            { 
                              name: 'Studio', 
                              value: 'STD', 
                              id: 'STD' 
                            },
                          ]}
                          onSelect={setSelectedProvenience}
                          value={
                            selectedProvenience ||
                            (print.provenience
                              ? {
                                  id: print.provenience
                                }
                              : '')
                          }
                        />
                      </span>
                    )}
                  </div>
                  {selectedProvenience && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeProvenience}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setSelectedProvenience()}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.inlineInfo} ${styles.group}`}>
                <div className={styles.label}>
                  {translate('tags', language)}
                </div>
                <div className={styles.content} ref={tagsSelectorRef}>
                  <p className={styles.tagsList}>
                    {print &&
                      print.tags &&
                      print.tags.map(tag => `${tag.name}; `)}
                  </p>
                  <button
                    type="button"
                    className={styles.defaultSelectInput}
                    disabled={isExternalDesigner}
                    onClick={() => {
                      closeAllSelectors();
                      setTagsSelector('opened');
                    }}
                  >
                    {selectedTags && selectedTags.length
                      ? selectedTags.map(tag => `${tag.name}; `)
                      : translate('addOtherTags', language)}
                  </button>
                  {tagsSelector === 'opened' && (
                    <span className={styles.listContainer}>
                      <ListSelector
                        selectLabel={translate('add', language)}
                        items={tags}
                        onSelect={setSelectedTags}
                        values={selectedTags || print.tags}
                        multiple
                        search
                      />
                    </span>
                  )}
                  {selectedTags && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeTags}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setSelectedTags()}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className={`${styles.inlineInfo} ${styles.group}`}>
                <div className={styles.label}>
                  {translate('briefing', language)}
                </div>
                <div className={styles.content}>
                  <textArea
                    id="print_name"
                    type="text"
                    className={`${styles.defaultTextInput} ${styles.briefingField}`}
                    placeholder={textBriefing && textBriefing.text}
                    value={updatedTextBriefing}
                    onChange={e =>
                      setUpdatedTextBriefing(e.currentTarget.value)
                    }
                  />
                  {updatedTextBriefing && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeTextBriefing}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => setUpdatedTextBriefing('')}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div> */}

              <div className={`${styles.inlineInfo} ${styles.group}`}>
                <div className={styles.label}>
                  {translate('type', language)}
                </div>
                <div className={styles.content}>
                  {getCollectionType() === 'ID' && (
                    <span className={`${styles.tag} ${styles.idTag}`}>ID</span>
                  )}
                  {getCollectionType() === 'COL' && (
                    <span className={`${styles.tag} ${styles.colessionTag}`}>
                      Colession
                    </span>
                  )}
                  {getCollectionType() === 'LE' && (
                    <span className={`${styles.tag} ${styles.colessionTag}`}>
                      Le Collection
                    </span>
                  )}
                </div>
              </div>

              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('digitalCylinder', language)}
                </div>
                {print && (
                  <div className={styles.content}>
                    <div className={styles.inputContainer}>
                      <button
                        type="button"
                        className={styles.defaultSelectInput}
                        disabled={isExternalDesigner}
                        onClick={() => {
                          closeAllSelectors();
                          setTypeSelector('opened');
                        }}
                      >
                        {(selectedType && selectedType.name) ||
                          (print && print.type && (
                            <>
                              {print.type === 'CYL' &&
                                translate('cylinder', language)}
                              {print.type === 'DIG' &&
                                translate('digital', language)}
                              {print.type === 'BOT' &&
                                `${translate('cylinder', language)}/${translate(
                                  'digital',
                                  language
                                )}`}
                            </>
                          ))}
                      </button>
                      {typeSelector === 'opened' && (
                        <span className={styles.selector} ref={typeSelectorRef}>
                          <ListSelector
                            selectLabel={translate('choose', language)}
                            items={[
                              {
                                id: 'CYL',
                                name: translate('cylinder', language)
                              },
                              {
                                id: 'DIG',
                                name: translate('digital', language)
                              },
                              {
                                id: 'BOT',
                                name: `${translate(
                                  'cylinder',
                                  language
                                )}/${translate('digital', language)}`
                              }
                            ]}
                            onSelect={setSelectedType}
                            value={
                              selectedType ||
                              (print.type
                                ? {
                                    id: print.type
                                  }
                                : '')
                            }
                          />
                        </span>
                      )}
                    </div>
                    {selectedType && (
                      <div className={styles.actions}>
                        <button
                          className={`${styles.defaultActionButton}`}
                          type="button"
                          onClick={changeType}
                        >
                          {translate('saveChange', language)}
                        </button>
                        <button
                          className={`${styles.defaultDeleteButton}`}
                          type="button"
                          onClick={() => setSelectedType()}
                        >
                          {translate('cancel', language)}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('rapport', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.rapport}>
                    <div>
                      <span className={styles.rapportLabel}>
                        {translate('rapportWidth', language)}
                      </span>
                      <input
                        id="print_name"
                        type="number"
                        className={styles.defaultTextInput}
                        placeholder={print ? print.jpeg_width : ''}
                        disabled={isExternalDesigner}
                        value={updatedRapportWidth}
                        onChange={e =>
                          setUpdatedRapportWidth(e.currentTarget.value)
                        }
                      />
                    </div>

                    <span className={styles.x}>x</span>
                    <div>
                      <span className={styles.rapportLabel}>
                        {translate('rapportHeight', language)}
                      </span>
                      <input
                        id="print_name"
                        type="number"
                        className={styles.defaultTextInput}
                        placeholder={print ? print.jpeg_height : ''}
                        disabled={isExternalDesigner}
                        value={updatedRapportHeight}
                        onChange={e =>
                          setUpdatedRapportHeight(e.currentTarget.value)
                        }
                      />
                    </div>

                    <span className={styles.cm}>cm</span>
                  </div>

                  {printRatioWarning ? (
                    <p className={styles.printRatioWarning}>
                      {translate('printRatioWarning', language)}
                    </p>
                  ) : null}

                  {(updatedRapportWidth || updatedRapportHeight) && (
                    <div className={styles.actions}>
                      <button
                        className={`${styles.defaultActionButton}`}
                        type="button"
                        onClick={changeRapport}
                      >
                        {translate('saveChange', language)}
                      </button>
                      <button
                        className={`${styles.defaultDeleteButton}`}
                        type="button"
                        onClick={() => {
                          setUpdatedRapportWidth('');
                          setUpdatedRapportHeight('');
                        }}
                      >
                        {translate('cancel', language)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('exclusivityVar', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      type="button"
                      className={styles.defaultSelectInput}
                      onClick={() => setExclusivityVarSelector('opened')}
                    >
                      {(selectedExclusivityVar &&
                        selectedExclusivityVar.name) ||
                        (print &&
                          print.exclusivity_var &&
                          getExclusivityVarValue(print.exclusivity_var))}
                    </button>
                    {exclusivityVarSelector === 'opened' && (
                      <span
                        className={styles.selector}
                        ref={exclusivityVarSelectorRef}
                      >
                        <ListSelector
                          selectLabel={translate('choose', language)}
                          items={[
                            {
                              name: translate('color', language),
                              value: 'COR',
                              id: 1
                            },
                            {
                              name: translate('drawing', language),
                              value: 'DES',
                              id: 2
                            },
                            {
                              name: `${translate(
                                'color',
                                language
                              )}/${translate('drawing', language)}`,
                              value: 'BOT',
                              id: 3
                            }
                          ]}
                          onSelect={setSelectedExclusivityVar}
                          value={
                            selectedExclusivityVar ||
                            (print.exclusivity_var
                              ? {
                                  value: print.exclusivity_var
                                }
                              : '')
                          }
                        />
                      </span>
                    )}
                    {selectedExclusivityVar && (
                      <div className={styles.actions}>
                        <button
                          className={`${styles.defaultActionButton}`}
                          type="button"
                          onClick={changeExclusivityVar}
                        >
                          {translate('saveChange', language)}
                        </button>
                        <button
                          className={`${styles.defaultDeleteButton}`}
                          type="button"
                          onClick={() => setSelectedExclusivityVar()}
                        >
                          {translate('cancel', language)}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('review', language)}
                </div>
                <div className={styles.content}>
                  <div className={styles.inputContainer}>
                    <button
                      type="button"
                      className={styles.defaultSelectInput}
                      disabled={isExternalDesigner}
                      onClick={() => {
                        closeAllSelectors();
                        setReviewSelector('opened');
                      }}
                    >
                      {(selectedReview && selectedReview.name) ||
                        (print && print.already_reviewed
                          ? translate('reviewed', language)
                          : translate('nonReviewed', language))}
                    </button>
                    {reviewSelector === 'opened' && (
                      <span className={styles.selector} ref={reviewSelectorRef}>
                        <ListSelector
                          selectLabel={translate('choose', language)}
                          items={[
                            {
                              name: translate('reviewed', language),
                              value: true,
                              id: 1
                            },
                            {
                              name: translate('nonReviewed', language),
                              value: false,
                              id: 2
                            }
                          ]}
                          onSelect={setSelectedReview}
                          value={
                            selectedReview ||
                            (print.already_reviewed
                              ? {
                                  value: print.already_reviewed
                                }
                              : '')
                          }
                        />
                      </span>
                    )}
                    {selectedReview && (
                      <div className={styles.actions}>
                        <button
                          className={`${styles.defaultActionButton}`}
                          type="button"
                          onClick={changeReview}
                        >
                          {translate('saveChange', language)}
                        </button>
                        <button
                          className={`${styles.defaultDeleteButton}`}
                          type="button"
                          onClick={() => setSelectedReview()}
                        >
                          {translate('cancel', language)}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('currentReservation', language)}
                </div>
                {print && (
                  <div className={styles.historyList}>
                    {getCurrentReservations.map(r => (
                      <div className={styles.historyItem}>
                        <div>{r.client}</div>
                        <div>
                          {moment(r.date_request).format('DD/MM/YYYY')} -{' '}
                          {moment(r.date_end).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div className={styles.inlineInfo}>
                <div className={styles.label}>
                  {translate('historyExclusivity', language)}
                </div>
                
                {print && (
                  <div className={styles.historyList}>
                    {exclusivities.map(exclusivity => (
                      <section>
                        <p>
                          {translate(`${exclusivity.exclusivity}`, language)} 
                          {exclusivity.exclusivity_var && ' - ' + translate(`${exclusivity.exclusivity_var}`, language)}
                        </p>
                        <p>{translate('client', language)}: {exclusivity.buyer_name}</p>
                        <div>
                          {moment(exclusivity.date_request).format('DD/MM/YYYY')} - {' '}
                          {moment(exclusivity.date_end).format('DD/MM/YYYY')}
                        </div>
                      </section>
                      
                    ))}
                  </div>
                )}
              </div>
              <div className={`${styles.inlineInfo} ${styles.group}`}>
                <div className={styles.label}>
                  {translate('salesHistory', language)}
                </div>
                {print && (
                  <div className={styles.historyList}>
                    {orders.map(order => order.productorder_set.map(product => product.print_id == id && (
                      <div className={styles.historyItem}>
                        <div>
                          <span>{translate('orderClient', language)}</span>{order.client_name} - {product.type}
                        </div>
                        <div>
                          {product.meters !== '0.00' &&
                            `${product.meters} ${translate(
                              'meters',
                              language
                            )}`}
                          {product.category === 'SIM' &&
                            ` - ${translate('simple', language)}`}
                          {product.category === 'REP' &&
                            ` - ${translate('repetition', language)}`}
                          {product.category === 'BOT' &&
                            ` - ${translate('simpleRepetition', language)}`}
                        </div>
                        <div><span>{translate('orderNumber', language)}{order.order_number}</span></div>
                        <div>
                         
                          <span>{translate('orderDate', language)}{moment(order.date).format('DD/MM/YYYY')}</span>
                          
                        </div>
                          <span>{translate('fabric', language)}: {product.fabric_name}</span>
                      </div>
                    )))}
                  </div>
                )}
              </div>
              <SectionDivisor />
              <div className={styles.approveAddToCart}>
                <div className={styles.approvementActions}>
                  {print && print.status === 'SKE' && (
                    <button
                      className={`${styles.defaultRoundedActionButton}`}
                      type="button"
                      onClick={approvePrint}
                      disabled={isExternalDesigner}
                    >
                      {translate('approve', language)}
                    </button>
                  )}

                  <button
                    className={`${styles.defaultRoundedDeleteButton}`}
                    type="button"
                    onClick={openDeletePrintModal}
                    disabled={isExternalDesigner}
                  >
                    {translate('delete', language)}
                  </button>
                </div>
                {orders.length > 0 && (
                  <button
                    type="button"
                    className={styles.addToCart}
                    onClick={addToCart}
                  >
                    <ShoppingCartIcon />
                  </button>
                )}
              </div>
            </div>
            <div className={styles.editSubsection}>
              {print && originPrint && (
                <>
                  <SectionDivisor title={translate('original', language)} big />
                  <div className={styles.imageList}>
                    <Link
                      key={getPrintImage}
                      className={styles.imageItem}
                      to={`/print/${print.origin_id}`}
                    >
                      {originPrint && originPrint.image_url ? (
                        <img
                          src={originPrint.image_url}
                          alt={print.origin_code}
                        />
                      ) : (
                        <span className={styles.imagePreviewPlaceholder} />
                      )}
                      <span className={styles.imageName}>
                        {originPrint ? originPrint.code : print.origin_code}
                      </span>
                    </Link>
                  </div>
                </>
              )}

              {print && (
                <>
                  <SectionDivisor
                    title={translate('previousVersions', language)}
                    big
                    fileLoader={addPreviousVersion}
                  />
                  <div className={styles.imageList}>
                    <div className={styles.imageItem}>
                      {print.historic_one_url && (
                        <>
                          <img
                            src={print.historic_one_url}
                            alt="historic_one"
                          />
                          <button
                            type="button"
                            className={styles.delete}
                            onClick={deleteHistoricOne}
                          >
                            {translate('delete', language)}
                          </button>
                        </>
                      )}
                    </div>
                    <div className={styles.imageItem}>
                      {print.historic_two_url && (
                        <>
                          <img
                            src={print.historic_two_url}
                            alt="historic_two"
                          />
                          <button
                            type="button"
                            className={styles.delete}
                            onClick={deleteHistoricTwo}
                          >
                            {translate('delete', language)}
                          </button>
                        </>
                      )}
                    </div>
                    <div className={styles.imageItem}>
                      {print.historic_three_url && (
                        <>
                          <img
                            src={print.historic_three_url}
                            alt="historic_three"
                          />
                          <button
                            type="button"
                            className={styles.delete}
                            onClick={deleteHistoricThree}
                          >
                            {translate('delete', language)}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              
                <>
                  <SectionDivisor
                    title={translate('chromaticVariations', language)}
                    big
                    addAction={() => setNewChromaticVariation(true)}
                  />
                  <div className={styles.imageList}>
                    {chromaticVariations.map(chromaticVar => (
                      <Link
                        key={chromaticVar.image_url}
                        className={styles.imageItem}
                        to={`/print/${chromaticVar.id}`}
                      >
                        {chromaticVar.image_url ? (
                          <img
                            src={chromaticVar.image_url}
                            alt={chromaticVar.name}
                          />
                        ) : (
                          <span className={styles.imagePreviewPlaceholder} />
                        )}
                        <span className={styles.imageName}>
                          {chromaticVar.code} - {chromaticVar.color_variant}
                        </span>
                      </Link>
                    ))}
                  </div>
                </>
              
              {print && print.is_origin && !print.is_twin && (
                <>
                  <SectionDivisor
                    title={translate('twins', language)}
                    big
                    addAction={() => setNewTwin(true)}
                  />
                  <div className={styles.imageList}>
                    {print.is_origin ? (
                      twins.map(twin => (
                        <Link
                          key={twin.image_url}
                          className={styles.imageItem}
                          to={`/print/${twin.id}`}
                        >
                          {twin.image_url ? (
                            <img src={twin.image_url} alt={twin.name} />
                          ) : (
                            <span className={styles.imagePreviewPlaceholder} />
                          )}

                          <span className={styles.imageName}>
                            {twin.code} - {twin.color_variant}
                          </span>
                        </Link>
                      ))
                    ) : (
                      <Link
                        key={getPrintImage}
                        className={styles.imageItem}
                        to={`/print/${print.origin_id}`}
                      >
                        {originPrint && originPrint.image_url ? (
                          <img
                            src={originPrint.image_url}
                            alt={print.origin_code}
                          />
                        ) : (
                          <span className={styles.imagePreviewPlaceholder} />
                        )}
                        <span className={styles.imageName}>
                          {originPrint ? originPrint.name : print.origin_code}
                        </span>
                      </Link>
                    )}
                  </div>
                </>
              )}

              {briefings.length > 0 && (
                <>
                  <SectionDivisor
                    title={translate('briefings', language)}
                    big
                  />
                  <div className={styles.briefings}>
                    <div className={styles.briefingList}>
                      {briefings.map(briefing => (
                        <Link
                          to={`/briefings/?id=${briefing.id}`}
                          key={briefing.id}
                          className={styles.briefingCard}
                          type="button"
                        >
                          {!!briefing.cover_url && (
                            <img src={briefing.cover_url} alt={briefing.text} />
                          )}
                          <div className={styles.info}>
                            {briefing.new && (
                              <span className={styles.new}>
                                {translate('new', language)}
                              </span>
                            )}
                            <span className={styles.label}>
                              {translate('briefing', language)}
                            </span>
                            <span className={styles.title}>
                              {briefing.text}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {/* <div className={styles.imageList}>
                {visualRefBriefing &&
                  visualRefBriefing.map(briefingRef => (
                    <div key={briefingRef.images} className={styles.imageItem}>
                      <button
                        type="button"
                        onClick={() => setLightBoxImage(briefingRef.images)}
                      >
                        <img
                          src={briefingRef.images}
                          alt={briefingRef.text}
                          onError={e => {
                            e.currentTarget.src = FileIcon;
                          }}
                        />
                      </button>
                      <button
                        className={styles.delete}
                        type="button"
                        onClick={() => openDeleteImageModal(briefingRef.id)}
                      >
                        {translate('delete', language)}
                      </button>
                    </div>
                  ))}
              </div>
              <FileSelector
                onSelect={setUpdatedVisualRefBriefing}
                label={`${translate('addImages', language)} (max: 50MB)`}
                files={updatedVisualRefBriefing}
                multiple
                jpg
                button
              /> */}
              <SectionDivisor title={translate('usedDrawings', language)} big />
              <div className={styles.imageList}>
                {usedDrawingsBriefing &&
                  usedDrawingsBriefing.map(briefingRef => (
                    <div key={briefingRef.images} className={styles.imageItem}>
                      <button
                        type="button"
                        onClick={() => setLightBoxImage(briefingRef.images)}
                      >
                        <img
                          src={briefingRef.images}
                          alt={briefingRef.text}
                          onError={e => {
                            e.currentTarget.src = FileIcon;
                          }}
                        />
                      </button>

                      <button
                        className={styles.delete}
                        type="button"
                        onClick={() => openDeleteImageModal(briefingRef.id)}
                      >
                        {translate('delete', language)}
                      </button>
                    </div>
                  ))}
              </div>
              <FileSelector
                onSelect={setUpdatedUsedDrawingsBriefing}
                label={`${translate('addImages', language)} (max: 50MB)`}
                files={updatedUsedDrawingsBriefing}
                multiple
                jpg
                button
              />
            </div>
          </section>
        </>
      </form>
      {print && newChromaticVariation && (
        <div className={styles.modalBackground}>
          <div className={styles.modalContainer}>
            <h1>{translate('chromaticRegister', language)}</h1>
            <button
              type="button"
              className={styles.closeButton}
              onClick={closeCreateVariationModal}
            >
              <CloseIcon color="#4B5768" />
            </button>
            <CreatePrintForm
              original={print}
              chromatic
              chromaticLength={chromaticVariations?.length}
              closeModal={closeCreateVariationModal}
            />
          </div>
          <button
            type="button"
            className={styles.modalOverlay}
            onClick={closeCreateVariationModal}
          />
        </div>
      )}
      {print && newTwin && (
        <div className={styles.modalBackground}>
          <div className={styles.modalContainer}>
            <h1>{translate('twinRegister', language)}</h1>
            <button
              type="button"
              className={styles.closeButton}
              onClick={closeCreateVariationModal}
            >
              <CloseIcon color="#4B5768" />
            </button>
            <CreatePrintForm
              original={print}
              twin
              closeModal={closeCreateVariationModal}
            />
          </div>
          <button
            type="button"
            className={styles.modalOverlay}
            onClick={closeCreateVariationModal}
          />
        </div>
      )}
      {confirmationModal && (
        <ConfirmationModal
          title={confirmationModalTitle}
          message={confirmationModalMessage}
          confirmText={confirmationModalConfirmText}
          cancelText={confirmationModalCancelText}
          onConfirm={
            deleteBriefingIntent ? deleteVisualRefBriefing : deletePrint
          }
          onCancel={() => setConfirmationModal(false)}
          deleteWarning
        />
      )}
      {createCollectionModal && (
        <FormModal
          onCancel={() => setCreateCollectionModal(false)}
          title={translate('createCollectionTitle', language)}
        >
          <CreateCollectionForm onSuccess={createdCollection} />
        </FormModal>
      )}
      {(!print || updating) && <Loading fixed />}
      {savingProgress && (
        <UploadProgress progress={savingProgress} text={savingMessage} />
      )}
      {lightBoxImage && (
        <LightBox
          image={lightBoxImage}
          backgroundColor={initialBackgroundColor}
          close={() => setLightBoxImage()}
        />
      )}
      {showChat && (
        <Feedback close={() => setShowChat(false)} printId={print.id} />
      )}

      {cart && <SellerCart close={() => setCart(false)} initialClient={cart} />}
    </div>
  );
}

export default Print;
